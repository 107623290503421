const Error = () => {
	return (
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_7218_16907"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="21"
				height="20"
			>
				<rect x="0.306641" width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_7218_16907)">
				<path
					d="M20.207 10C20.207 15.5228 15.7299 20 10.207 20C4.68418 20 0.207031 15.5228 0.207031 10C0.207031 4.47715 4.68418 0 10.207 0C15.7299 0 20.207 4.47715 20.207 10ZM2.70703 10C2.70703 14.1421 6.0649 17.5 10.207 17.5C14.3492 17.5 17.707 14.1421 17.707 10C17.707 5.85786 14.3492 2.5 10.207 2.5C6.0649 2.5 2.70703 5.85786 2.70703 10Z"
					fill="#FF388C"
				/>
				<path
					d="M10.7337 14.0938C10.574 14.2535 10.3761 14.3333 10.14 14.3333C9.90386 14.3333 9.70595 14.2535 9.54622 14.0938C9.3865 13.934 9.30664 13.7361 9.30664 13.5C9.30664 13.2639 9.3865 13.066 9.54622 12.9063C9.70595 12.7465 9.90386 12.6667 10.14 12.6667C10.3761 12.6667 10.574 12.7465 10.7337 12.9063C10.8934 13.066 10.9733 13.2639 10.9733 13.5C10.9733 13.7361 10.8934 13.934 10.7337 14.0938Z"
					fill="#FF388C"
				/>
				<path d="M10.9733 11H9.30664V6H10.9733V11Z" fill="#FF388C" />
			</g>
		</svg>
	);
};

export default Error;
