import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
} from '@/components/ui/dialog';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

const SectionInfoDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'viewSectionInfo'
	);
	const section = useSelector((root: RootState) => root.page.dialog?.section);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[1100px] gap-3 p-6 bg-white rounded-xl text-black">
				<DialogHeader className="font-bold text-secondary dynamic-large">
					{section?.label}
				</DialogHeader>
				<DialogDescription>{section?.description}</DialogDescription>
				<h4 className="font-bold dynamic-medium">Expected Output</h4>
				<div className="flex items-center gap-8">{section?.expectedOutput}</div>
				<div className="rounded-xl border border-[#D3DDE2] p-4 text-black">
					<h4 className="font-bold dynamic-medium text-secondary">
						Section Details
					</h4>
					<div className="flex items-center gap-24 py-2">
						<div>
							<h5 className="font-bold ">Section Alias</h5>
							<p className="mt-1">{section?.alias}</p>
						</div>
						{/* <div>
							<h5 className="font-bold">Tags</h5>
							<div className="flex items-center gap-2 mt-1">
								<Badge className="font-normal bg-white border border-primary text-primary">
									Tag1
								</Badge>
								<Badge className="font-normal bg-white border border-primary text-primary">
									Tag2
								</Badge>
							</div>
						</div> */}
					</div>
					{/* <h5 className="font-bold">How the AI will write this section</h5>
					<p>
						Think of how you would teach a new member of your team on what to
						think about when writing this section. Be as clear as possible and
						clearly define the thinking process and approach to writing this.
					</p> */}
					<h5 className="mt-4 font-bold">
						The Question your user will be asked
					</h5>
					<p>{section?.question}</p>
					<h5 className="mt-4 font-bold">
						Description of the question the user will be asked
					</h5>
					<p>{section?.questionDescription}</p>
					<h5 className="mt-4 font-bold">A tip your user will be provided</h5>
					<p>{section?.questionTip}</p>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default SectionInfoDialog;
