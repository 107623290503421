const Clone = () => {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_6282_15472"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="21"
				height="21"
			>
				<rect x="0.332031" y="0.5" width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_6282_15472)">
				<path
					d="M16.1667 16.334H7C6.54167 16.334 6.14931 16.1708 5.82292 15.8444C5.49653 15.518 5.33333 15.1257 5.33333 14.6673V3.00065C5.33333 2.54232 5.49653 2.14996 5.82292 1.82357C6.14931 1.49718 6.54167 1.33398 7 1.33398H12.8333L17.8333 6.33398V14.6673C17.8333 15.1257 17.6701 15.518 17.3437 15.8444C17.0174 16.1708 16.625 16.334 16.1667 16.334ZM12 7.16732V3.00065H7V14.6673H16.1667V7.16732H12ZM3.66667 19.6673C3.20833 19.6673 2.81597 19.5041 2.48958 19.1777C2.16319 18.8513 2 18.459 2 18.0007V6.33398H3.66667V18.0007H12.8333V19.6673H3.66667Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Clone;
