import styles from './ProposalDashboard.module.css';
import { cn } from '@/lib/utils';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { motion, useAnimation } from 'framer-motion';
import DesignCard from '@/components/DesignCard/DesignCard';
// import SectionAccordion from '@/components/SectionAccordion/SectionAccordion';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useParams } from 'react-router';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
// import { Proposal } from '@/lib/types/apiTypes';
import { setLastSection } from '@/redux/slices/pageSlice';
import TopHeader from '@/components/TopHeader/TopHeader';
import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';
import EssentialDocumentsCard from '@/components/EssentialDocumentsCard/EssentialDocumentsCard';
import ComplianceMatrixCard from '@/components/ComplianceMatrixCard/ComplianceMatrixCard';
import ComplianceMatrixPanel from '@/components/Panels/ComplianceMatrixPanel/ComplianceMatrixPanel';
import { fetchComplianceMatrix } from '@/redux/requests/compliance';
import LogicalFrameCard from '@/components/LogicalFrameCard/LogicalFrameCard';
import useLogicalFrameEnabled from '@/lib/hooks/useLogicalFrameEnabled';

interface ProposalDashboardProps {
	exiting?: boolean;
}
const ProposalDashboard: FC<ProposalDashboardProps> = ({ exiting }) => {
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const dispatch = useAppDispatch();
	const lastSectionId = useSelector(
		(root: RootState) => root.page.lastSection?.sectionId
	);
	const isLogicalFrameEnabled = useLogicalFrameEnabled();

	const [openPanel, setOpenPanel] = useState(false);

	const tour = useSelector((root: RootState) => root.tour);

	useTour(Page.ProposalDashboard);

	const control = useAnimation();

	const { id } = useParams();

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (lastSectionId === null && proposal) {
			const firstSectionId = proposal?.sections[0]?.id;
			dispatch(
				setLastSection({
					proposalId: proposal?.id as number,
					sectionId: firstSectionId as number,
				})
			);
		}
	}, [lastSectionId, proposal, dispatch]);

	useEffect(() => {
		if (id) {
			dispatch(fetchProposalById(Number(id)));
			dispatch(fetchComplianceMatrix(Number(id)));
		}
	}, [id]);

	const slideInVariant = (delay) => ({
		initial: { opacity: 0, y: '50%' },
		animate: { opacity: 1, y: 0, transition: { duration: 0.5, delay: delay } },
	});

	useEffect(() => {
		if (exiting === true) {
			control.start({ opacity: 0, transition: { duration: 0.25 } });
		}
	}, [exiting, control]);

	return (
		<div className={cn(styles.wrapper)}>
			<motion.div
				ref={containerRef}
				className={`h-full ${!tour.run ? 'overflow-y-auto' : ''} hidden-scroll bg-[#f5f5f5]`}
				animate={control}
			>
				{proposal && (
					<>
						<TopHeader
							rfpLink={proposal.rfpLink}
							rfpName={proposal.rfpTitle}
							proposalName={proposal?.name || ''}
							containerRef={containerRef}
						/>
						<div className="px-8 ">
							<motion.div
								className="relative h-full bg-[#f5f5f5]"
								initial="initial"
								animate="animate"
								exit="exit"
							>
								<div className={cn('grid grid-cols-3 gap-4 pb-4')}>
									<motion.div
										className="col-span-1"
										variants={slideInVariant(0)}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<DesignCard
											title={'Summary'}
											text={proposal?.rfpSummary || ''}
											type={'rfp'}
											tourClass="summary"
										/>
									</motion.div>
									<motion.div
										className="col-span-1"
										variants={slideInVariant(0.2)}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<DesignCard
											title={'Your Value Proposition'}
											text={proposal?.questions[0].transformedAnswer || ''}
											type={'approach'}
											tourClass="revisit-approach"
										/>
									</motion.div>
									<motion.div
										className="col-span-1"
										variants={slideInVariant(0.4)}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<DesignCard
											title={'Proposal Settings'}
											text={null}
											type={'voice'}
											tourClass="tone-of-voice"
										/>
									</motion.div>
								</div>
								<div className={cn('grid grid-cols-12 gap-4')}>
									<motion.div
										className="col-span-8"
										variants={slideInVariant(0.6)}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<EssentialDocumentsCard />
									</motion.div>
									<motion.div
										className="flex flex-col col-span-4 gap-4"
										variants={slideInVariant(0.8)}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										{isLogicalFrameEnabled && <LogicalFrameCard />}
										<ComplianceMatrixCard setPanelOpen={setOpenPanel} />

										{/* <div
											className={cn(
												'flex flex-col p-6 rounded-3xl bg-white mb-6 shadow-main '
											)}
										>
											<h4 className="mb-1 font-bold text-black dynamic-large">
												Jump to designing a section
											</h4>
											<p className="mb-4 text-black dynamic-text">
												Click on a section to start the design process for that
												section
											</p>
											<div className="h-[272px] overflow-y-scroll pr-3">
												<SectionAccordion proposal={proposal as Proposal} />
											</div>
										</div> */}
									</motion.div>
								</div>
							</motion.div>
						</div>
					</>
				)}
			</motion.div>
			<ComplianceMatrixPanel open={openPanel} setOpen={setOpenPanel} />
		</div>
	);
};

export default ProposalDashboard;
