import customToast from '@/components/CustomToast/CustomToast';
import { Badge } from '@/components/ui/badge';
import {
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import AIWhiteNew from '@/components/ui/icons/new/AINew';
import Pencil from '@/components/ui/icons/Pencil';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { Multiselect } from '@/components/ui/shared/Multiselect/Multiselect';
import { Delimiter, TagInput } from '@/components/ui/shared/TagInput/tag-input';
import { Textarea } from '@/components/ui/textarea';
import {
	defaultAiSection,
	generalSolicitationDropdown,
} from '@/constants/templateBuilderData';
import { getWordCount } from '@/lib/functions/funcUtils';
import {
	charactersCountValidation,
	splitTagArray,
	tagComponentAssociativeArray,
	wordCountValidation,
} from '@/lib/functions/TemplateBuilder/templateUtils';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import {
	TemplateSectionStep1Props,
	TemplateSectionStep2Props,
	TemplateSectionStep3Props,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { cn } from '@/lib/utils';
import { closeDialog, openDialog } from '@/redux/slices/pageSlice';
import { setEditSectionData } from '@/redux/slices/TemplateBuilder/sectionSlice';
import { RootState } from '@/redux/store/store';
import { FC, useState } from 'react';

export const SectionStep1: FC<TemplateSectionStep1Props> = ({
	form,
	editSectionData,
	setTableHeadings,
	setTableHeadingPreview,
	showTableHeading,
	tableHeadings,
	aiSectionForm,
	sectionLoading,
}) => {
	const dispatch = useAppDispatch();
	const [expectedOutput, setExpectedOutput] = useState('text');
	const expectedOutputChange = (value: string | null | undefined) => {
		if (value && value === 'table') {
			setExpectedOutput('table');
		} else {
			setExpectedOutput('text');
		}

		setTableHeadings([]);
		setTableHeadingPreview(value && value === 'table' ? true : false);
		if (editSectionData?.tableHeadings) {
			const splitTableHeadings = tagComponentAssociativeArray(
				editSectionData.tableHeadings.split(',')
			);
			setTableHeadings(splitTableHeadings);
		}
	};
	const DialogClosed = () => {
		form.reset();
		aiSectionForm.reset();
		dispatch(closeDialog());
	};
	const wordCount = aiSectionForm.watch('wordCount');
	return (
		<div className="flex flex-col gap-5">
			{/* section name */}
			<div className="flex flex-col gap-3">
				<div className="flex flex-col justify-between">
					<FormField
						control={aiSectionForm.control}
						name="name"
						rules={{
							required: 'Name is required',
							maxLength: {
								value: 100,
								message: 'Name should be under 100 characters.',
							},
						}}
						render={({ field }) => {
							return (
								<FormItem>
									<div className="flex justify-between h-6">
										<Label className="font-bold text-black dynamic-large">
											Section Name
										</Label>
										<p
											className={cn(
												'text-base font-medium leading-normal',
												field?.value?.length > 100
													? 'text-[#FF82A0]'
													: 'text-black opacity-50'
											)}
										>
											{field?.value?.length || 0}/100 characters
										</p>
									</div>
									<FormControl>
										<Input
											{...field}
											onChange={field.onChange}
											placeholder="Enter the name of this section"
											className={`p-4 rounded-xl bg-white h-12 dynamic-text text-black`}
										/>
									</FormControl>

									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>
			</div>
			{/* section wordcount */}
			<div className="flex flex-col gap-3">
				<div className="flex flex-row items-center justify-between">
					<p className="font-bold text-black dynamic-large">Word Count</p>
					<span className="text-base font-medium leading-normal text-black opacity-50">
						Numeric Only
					</span>
				</div>
				<div className="flex flex-col justify-between">
					<FormField
						control={aiSectionForm.control}
						name="wordCount"
						render={({ field }) => {
							return (
								<FormItem>
									<FormControl>
										<Input
											onChange={field.onChange}
											type="number"
											maxLength={4}
											min={100}
											onKeyDown={(e) =>
												['e', 'E', '+', '-'].includes(e.key) &&
												e.preventDefault()
											}
											onInput={(event: any) => {
												event.target.value = event.target.value.replace(
													/^0+/,
													''
												);
												if (
													event.target.value.length > event.target.maxLength
												) {
													event.target.value = event.target.value.slice(
														0,
														event.target.maxLength
													);
												}
												if (event.target.value > 1000) {
													customToast.dismiss();
													customToast.error({
														title:
															'Word count limit exceed. Can not add value greater than 1000.',
													});
													event.target.value = event.target.value.slice(0, -1);
												}
											}}
											placeholder="Enter word count"
											className={`p-4 rounded-xl bg-white h-12 dynamic-text text-black`}
										/>
									</FormControl>
									{((field.value && Number(field.value) > 1000) ||
										(field.value && Number(field.value) < 100)) && (
										<p className="dynamic-text font-medium text-lightRed">
											Word count must be between 100 and 1000
										</p>
									)}
									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>
			</div>
			{/* section description */}
			<div className="flex flex-col gap-3 ">
				<FormField
					control={aiSectionForm.control}
					name="description"
					rules={{
						required: 'Description is required',
						validate: (value) => charactersCountValidation(value, 1000),
					}}
					render={({ field }) => {
						return (
							<FormItem>
								<div className="flex justify-between h-6">
									<Label className="font-bold text-black dynamic-large">
										Section Description
									</Label>
									<p
										className={cn(
											'text-base font-medium leading-normal',
											field?.value?.length > 1000
												? 'text-[#FF82A0]'
												: 'text-black opacity-50'
										)}
									>
										{field?.value?.length}/1000 characters
									</p>
								</div>
								<FormControl>
									<Textarea
										{...field}
										onChange={field.onChange}
										placeholder="Enter a short description for this section that illustrates how it works"
										className={`p-4 rounded-2xl resize-none dynamic-text hidden-scroll border-[#d3dde2] bg-white focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-offset-0 min-h-[150px]`}
									/>
								</FormControl>

								<FormMessage />
							</FormItem>
						);
					}}
				/>
			</div>
			{/* section expected output */}
			<div className="flex flex-col gap-3">
				<div className="flex flex-row items-center justify-between">
					<p className="font-bold text-black dynamic-large">Expected Output</p>
				</div>
				<p className="text-black dynamic-text">
					Please specify if the output will be <b>just text</b> or{' '}
					<b>contain a table</b>
				</p>
				<FormField
					control={aiSectionForm.control}
					name="expectedOutput"
					render={({ field }) => {
						return (
							<FormItem className="col-span-5">
								<FormControl>
									<RadioGroup
										value={field?.value || undefined}
										onValueChange={field.onChange}
										onClick={() => {
											expectedOutputChange(field.value);
										}}
										defaultValue={'text'}
									>
										<div className="flex flex-row items-center gap-4">
											<RadioGroupItem
												value="text"
												onClick={() => {
													field.onChange('table');
													expectedOutputChange('table');
												}}
											/>{' '}
											<p
												className="font-bold text-black dynamic-large"
												onClick={() => {
													field.onChange('text');
													expectedOutputChange('text');
												}}
											>
												Text
											</p>
											<RadioGroupItem
												value="table"
												onClick={() => {
													field.onChange('table');
													expectedOutputChange('table');
												}}
											/>
											<p
												className="font-bold text-black dynamic-large"
												onClick={() => {
													field.onChange('table');
													expectedOutputChange('table');
												}}
											>
												Table
											</p>
										</div>
									</RadioGroup>
								</FormControl>
								<FormMessage />
							</FormItem>
						);
					}}
				/>
			</div>
			{/* section table headings */}
			<div
				className={`flex flex-col col-span-6 ${
					showTableHeading ? 'visible' : 'hidden'
				}`}
			>
				<FormField
					control={aiSectionForm.control}
					name="tableHeadings"
					rules={{
						validate: () =>
							expectedOutput === 'table' && tableHeadings.length === 0
								? 'Table Headings is required'
								: true,
					}}
					render={({ field }) => {
						return (
							<FormItem className="col-span-6">
								<FormControl>
									<TagInput
										placeholder="Enter table headings"
										tags={tableHeadings}
										maxLength={50}
										inputFieldPostion="bottom"
										variant={'custom'}
										shape={'pill'}
										minTags={1}
										delimiter={Delimiter.Comma}
										delimiterList={[Delimiter.Enter, Delimiter.Comma]}
										allowDuplicates={false}
										className={`p-4 rounded-xl bg-[#fff] h-14 dynamic-text
														 text-black border border-[#D3DDE2]`}
										setTags={(newTags) => {
											const splitTags =
												newTags && newTags.length > 0
													? splitTagArray(newTags)
													: [];
											setTableHeadings(splitTags);
											field.onChange(splitTags);
										}}
									/>
								</FormControl>
								{expectedOutput === 'table' && tableHeadings.length === 0 && (
									<FormMessage />
								)}
							</FormItem>
						);
					}}
				/>
			</div>
			<div className="flex flex-row items-center justify-end col-span-12 gap-4 pt-4 mb-1">
				<SecondaryButton
					type="button"
					onClick={() => {
						dispatch(setEditSectionData(null));
						DialogClosed();
					}}
					disabled={sectionLoading}
				>
					Cancel
				</SecondaryButton>
				<PrimaryButton
					type="submit"
					className="uppercase"
					disabled={
						(wordCount && wordCount < 100) ||
						(wordCount && wordCount > 1000) ||
						sectionLoading
					}
				>
					<AIWhiteNew /> Create New Section
				</PrimaryButton>
			</div>
		</div>
	);
};

export const SectionStep2: FC<TemplateSectionStep2Props> = ({
	form,
	aiSectionData,
	setAiSectionData,
	aiSectionForm,
}) => {
	const dispatch = useAppDispatch();
	const DialogClosed = () => {
		form.reset();
		aiSectionForm.reset();
		dispatch(closeDialog());
	};
	return (
		<div className="flex flex-col gap-5 px-3">
			<div className="flex flex-row items-center justify-between border-b border-[#E9E9E9]">
				<h2 className="font-bold dynamic-xl-large text-secondary">
					{aiSectionData.name || ''}
				</h2>
				<TextButton
					type="button"
					className="uppercase"
					onClick={() => {
						dispatch(
							openDialog({
								type: 'createTemplateSection',
								editSectionID: null,
								sectionCreatedByUser: true,
								sectionDialogStep: '3',
							})
						);
					}}
				>
					<Pencil /> Edit this section
				</TextButton>
			</div>
			<div className="flex flex-row">
				<div
					className="text-black dynamic-text"
					dangerouslySetInnerHTML={{
						__html: aiSectionData.description
							.replace(/\n/g, '<br>')
							.replace(/["']/g, ''),
					}}
				/>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex flex-row items-center gap-1">
					<h2 className="font-bold text-black dynamic-large">
						Expected Ouput:
					</h2>
					<p className="text-black capitalize dynamic-medium">
						{aiSectionData.expectedOutput || ''}
					</p>
				</div>
				{aiSectionData.expectedOutput === 'table' &&
					aiSectionData.tableHeadings && (
						<div className="flex flex-row items-center gap-2">
							<p className="text-black dynamic-text">
								Table with the column headings:
							</p>
							{aiSectionData.tableHeadings.split(',').map((heading, k) => (
								<Badge
									key={k}
									className="text-sm font-medium uppercase bg-white border text-primary w-30 xl:text-base 2xl:text-lg border-primaryDark"
								>
									{heading}
								</Badge>
							))}
						</div>
					)}
			</div>
			<div className="flex flex-col border border-[#D3DDE2] gap-4 px-3 py-5 lg:h-[calc(100vh-480px)] 2xl:h-[calc(100vh-500px)] overflow-y-scroll rounded-lg">
				<div className="flex flex-row">
					<h2 className="font-bold dynamic-xl-large text-secondary">
						Section Details
					</h2>
				</div>
				<div className="grid grid-cols-12">
					{/* <div className="flex flex-col col-span-6">
						<h2 className="font-bold text-black dynamic-xl-large">
							Section Alias
						</h2>
						<p className="text-black dynamic-text">
							{aiSectionData.alias || ''}
						</p>
					</div> */}
					<div className="flex flex-col col-span-12 gap-1">
						<h2 className="font-bold text-black dynamic-large">Tags</h2>
						<div className="flex flex-row gap-2">
							{aiSectionData.tags &&
								aiSectionData.tags.map((tag, k) => (
									<Badge
										key={k}
										className="text-sm font-medium uppercase bg-white border text-primary w-30 xl:text-base 2xl:text-lg border-primaryDark"
									>
										<p className="line-clamp-1">{tag}</p>
									</Badge>
								))}
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="font-bold text-black dynamic-large">
						How will this section be composed
					</h2>
					<div
						className="text-black dynamic-text"
						dangerouslySetInnerHTML={{
							__html: aiSectionData.defaultContext
								.replace(/\n/g, '<br>')
								.replace(/["']/g, ''),
						}}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="font-bold text-black dynamic-large">
						The Question your user will be asked
					</h2>
					<p className="text-black dynamic-text">
						{(aiSectionData.questions &&
							aiSectionData.questions[0] &&
							'text' in aiSectionData.questions[0] &&
							aiSectionData.questions[0].text) ||
							''}
					</p>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="font-bold text-black dynamic-large">
						Description of the question the user will be asked
					</h2>

					<div
						className="text-black dynamic-text"
						dangerouslySetInnerHTML={{
							__html:
								(aiSectionData.questions &&
									aiSectionData.questions[0] &&
									'description' in aiSectionData.questions[0] &&
									aiSectionData.questions[0].description
										.replace(/\n/g, '<br>')
										.replace(/["']/g, '')) ||
								'',
						}}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="font-bold text-black dynamic-large">
						How this section will be structured
					</h2>
					<div
						className="text-black dynamic-text"
						dangerouslySetInnerHTML={{
							__html:
								(aiSectionData.specialContext &&
									aiSectionData.specialContext
										.replace(/\n/g, '<br>')
										.replace(/["']/g, '')) ||
								'',
						}}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="font-bold text-black dynamic-large">
						A tip your user will be provided
					</h2>
					<p className="text-black dynamic-text">
						{(aiSectionData.questions &&
							aiSectionData.questions[0] &&
							'proTip' in aiSectionData.questions[0] &&
							aiSectionData.questions[0].proTip) ||
							''}
					</p>
				</div>
			</div>
			<div className="flex flex-row items-center justify-between col-span-12 pt-4 mb-1">
				<TextButton
					type="button"
					className="uppercase"
					onClick={() => {
						setAiSectionData(defaultAiSection);
						DialogClosed();
					}}
				>
					Cancel
				</TextButton>
				<div className="flex flex-row items-center justify-end gap-4">
					<SecondaryButton
						type="button"
						className="uppercase"
						onClick={() => {
							setAiSectionData(defaultAiSection);
							dispatch(
								openDialog({
									type: 'createTemplateSection',
									editSectionID: null,
									sectionCreatedByUser: true,
									sectionDialogStep: '1',
								})
							);
						}}
					>
						Back To Previous Step
					</SecondaryButton>
					<PrimaryButton
						type="submit"
						className="uppercase"
						// onClick={() => {
						// 	dispatch(
						// 		openDialog({
						// 			type: 'createTemplateSection',
						// 			editSectionID: null,
						// 			sectionCreatedByUser: true,
						// 			sectionDialogStep: '3',
						// 		})
						// 	);
						// }}
					>
						Confirm Section Creation
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};
export const SectionStep3: FC<TemplateSectionStep3Props> = ({
	form,
	editSectionData,
	setTableHeadings,
	setTableHeadingPreview,
	showTableHeading,
	tableHeadings,
	setTags,
	tags,
	setSolicitations,
	solicitationsArray,
	aiSectionData,
}) => {
	const sectionTagOptions = useAppSelector(
		(root: RootState) => root.sections.sectionTagOptions
	);
	const duplicateSection = useAppSelector(
		(state: RootState) => state.page.dialog?.duplicateSection
	);

	const dispatch = useAppDispatch();
	const DialogClosed = () => {
		form.reset();
		dispatch(closeDialog());
	};
	const expectedOutputChange = (value: string | null) => {
		setTableHeadings([]);
		setTableHeadingPreview(value && value === 'table' ? true : false);
		if (editSectionData?.tableHeadings) {
			const splitTableHeadings = tagComponentAssociativeArray(
				editSectionData.tableHeadings.split(',')
			);
			setTableHeadings(splitTableHeadings);
		} else if (aiSectionData.tableHeadings) {
			const splitTableHeadings = tagComponentAssociativeArray(
				aiSectionData.tableHeadings.split(',')
			);
			setTableHeadings(splitTableHeadings);
		}
	};

	return (
		<>
			<div className="grid grid-cols-12 gap-4 mb-8">
				{/* section name and wordcount */}
				<div className="grid grid-cols-12 col-span-12 gap-4">
					<div className="flex flex-col col-span-6 gap-2">
						<FormField
							control={form.control}
							name="name"
							rules={{
								required: 'Name is required',
								maxLength: {
									value: 100,
									message: 'Name should be under 100 characters.',
								},
							}}
							render={({ field }) => {
								return (
									<FormItem>
										<div className="flex justify-between h-6">
											<Label className="font-bold text-black dynamic-large">
												Section Name
											</Label>
											<p
												className={cn(
													'text-base font-medium leading-normal',
													field?.value?.length > 100
														? 'text-[#FF82A0]'
														: 'text-black opacity-50'
												)}
											>
												{field?.value?.length || 0}/100 characters
											</p>
										</div>
										<FormControl>
											<Input
												{...field}
												placeholder="Enter the name of this section"
												className={`p-4 rounded-xl bg-[#fff] h-12 dynamic-text text-black`}
											/>
										</FormControl>

										<FormMessage />
									</FormItem>
								);
							}}
						/>
					</div>
					<div className="flex flex-col col-span-6 gap-2">
						<div className="flex flex-row items-center justify-between">
							<p className="font-bold text-black dynamic-large">Word Count</p>
						</div>
						<FormField
							control={form.control}
							name="wordCount"
							rules={{
								required: 'Word Count is required',
								validate: (value) =>
									value < 100 || value > 1000 ? false : true,
							}}
							render={({ field }) => {
								return (
									<FormItem>
										<FormControl>
											<Input
												{...field}
												type="number"
												placeholder="Enter word Count"
												maxLength={4}
												min={100}
												onKeyDown={(e) =>
													['e', 'E', '+', '-'].includes(e.key) &&
													e.preventDefault()
												}
												onInput={(event: any) => {
													event.target.value = event.target.value.replace(
														/^0+/,
														''
													);
													if (
														event.target.value.length > event.target.maxLength
													) {
														event.target.value = event.target.value.slice(
															0,
															event.target.maxLength
														);
													}
													if (event.target.value > 1000) {
														customToast.dismiss();
														customToast.error({
															title:
																'Word count limit exceed. Can not add value greater than 1000.',
														});
														event.target.value = event.target.value.slice(
															0,
															-1
														);
													}
												}}
												className={`p-4 rounded-xl bg-[#fff] h-12 dynamic-text text-black`}
											/>
										</FormControl>
										{((field.value && Number(field.value) > 1000) ||
											(field.value && Number(field.value) < 100)) && (
											<p className="dynamic-text font-medium text-lightRed">
												Word count must be between 100 and 1000
											</p>
										)}
										<FormMessage />
									</FormItem>
								);
							}}
						/>
					</div>
				</div>
				{/* expected output */}
				<div className="flex flex-col col-span-12 gap-2">
					<div className="flex flex-col gap-2">
						<div className="flex flex-row items-center justify-between">
							<p className="font-bold text-black dynamic-large">
								Expected Output
							</p>
						</div>
						<p className="text-black dynamic-text">
							Please specify if the output will be <b>just text</b> or{' '}
							<b>contain a table</b>
						</p>
						<FormField
							control={form.control}
							name="expectedOutput"
							rules={{
								required: 'Expected Output is required',
							}}
							render={({ field }) => {
								return (
									<FormItem className="col-span-5">
										<FormControl>
											<RadioGroup
												value={field?.value || undefined}
												onValueChange={field.onChange}
												onClick={() => {
													expectedOutputChange(field.value);
												}}
											>
												<div className="flex flex-row items-center gap-4">
													<RadioGroupItem
														value="text"
														onClick={() => {
															field.onChange('table');
															expectedOutputChange('table');
														}}
													/>{' '}
													<p
														className="font-bold text-black dynamic-large"
														onClick={() => {
															field.onChange('text');
															expectedOutputChange('text');
														}}
													>
														Text
													</p>
													<RadioGroupItem
														value="table"
														onClick={() => {
															field.onChange('table');
															expectedOutputChange('table');
														}}
													/>
													<p
														className="font-bold text-black dynamic-large"
														onClick={() => {
															field.onChange('table');
															expectedOutputChange('table');
														}}
													>
														Table
													</p>
												</div>
											</RadioGroup>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
					</div>
					<div
						className={`flex flex-col ${
							showTableHeading ? 'visible' : 'hidden'
						}`}
					>
						<FormField
							control={form.control}
							name="tableHeadings"
							render={({ field }) => {
								return (
									<FormItem className="col-span-6">
										<FormControl>
											<TagInput
												placeholder="Enter table headings"
												tags={tableHeadings}
												maxLength={50}
												inputFieldPostion="bottom"
												variant={'custom'}
												shape={'pill'}
												minTags={1}
												delimiter={Delimiter.Comma}
												delimiterList={[Delimiter.Enter, Delimiter.Comma]}
												allowDuplicates={false}
												className={`p-4 rounded-xl bg-[#fff] h-14 dynamic-text
														 text-black border border-[#D3DDE2]`}
												setTags={(newTags) => {
													const splitTags =
														newTags && newTags.length > 0
															? splitTagArray(newTags)
															: [];
													setTableHeadings(splitTags);
													field.onChange(splitTags);
												}}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
					</div>
				</div>
				{/* section description */}
				<div className="flex flex-col col-span-12 gap-2">
					<FormField
						control={form.control}
						name="description"
						rules={{
							required: 'Description is required',
							validate: (value) => wordCountValidation(value, 500),
						}}
						render={({ field }) => {
							return (
								<FormItem>
									<div className="flex justify-between h-6">
										<Label className="font-bold text-black dynamic-large">
											Description
										</Label>
										<p
											className={cn(
												'text-base font-medium leading-normal',
												getWordCount(field?.value) > 500
													? 'text-[#FF82A0]'
													: 'text-black opacity-50'
											)}
										>
											{getWordCount(field?.value)}/500 words
										</p>
									</div>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Enter a short description for this section that illustrates how it works"
											className={`p-4 rounded-2xl resize-none dynamic-text border-[#d3dde2] bg-white focus-visible:ring-0
											 focus-visible:shadow-none focus-visible:ring-offset-0 min-h-[150px] hidden-scroll`}
										/>
									</FormControl>

									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>
			</div>
			<div className="bg-[#E9E9E9] h-[1px] border-none"></div>
			<div className="grid grid-cols-12 gap-4 mt-5">
				<div className="flex flex-row items-center justify-between col-span-12">
					<h2 className="font-bold capitalize dynamic-xl-large text-secondary">
						Section Details
					</h2>
				</div>
				<div className="grid grid-cols-12 col-span-12 gap-4">
					<div className="flex flex-col col-span-6 gap-2">
						<div className="flex flex-row items-center justify-between">
							<p className="font-bold text-black dynamic-large">Tags</p>
						</div>

						<FormField
							control={form.control}
							name="tags"
							rules={{
								required: 'Tags are required',
							}}
							render={({ field }) => {
								return (
									<FormItem className="col-span-6">
										<FormControl>
											<Multiselect
												className="!h-12 !p-2"
												title="Select tags"
												options={sectionTagOptions}
												value={tags}
												canAddOption={true}
												isModal={true}
												onSelectedValuesChange={(values: any) => {
													setTags(values);
													field.onChange(values);
												}}
											></Multiselect>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
					</div>
				</div>
				{/* default context */}
				<div className="flex flex-col col-span-12 gap-2">
					<FormField
						control={form.control}
						name="defaultContext"
						rules={{
							required: 'Default context is required',
							validate: (value) => wordCountValidation(value, 500),
						}}
						render={({ field }) => {
							return (
								<FormItem>
									<div className="flex justify-between h-6">
										<Label className="font-bold text-black dynamic-large">
											How will this section be composed.
										</Label>
										<p
											className={cn(
												'text-base font-medium leading-normal',
												getWordCount(field?.value) > 500
													? 'text-[#FF82A0]'
													: 'text-black opacity-50'
											)}
										>
											{getWordCount(field?.value)}/500 words
										</p>
									</div>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Think of how you would teach a new member of your team on what to think about when writing this section. Be as clear as possible and clearly define the thinking process and approach to writing this."
											className={`p-4 rounded-2xl resize-none border-[#d3dde2] bg-white focus-visible:ring-0 
										ocus-visible:shadow-none focus-visible:ring-offset-0 min-h-[100px] hidden-scroll`}
										/>
									</FormControl>

									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>
				{/* question text */}
				<div className="flex flex-col col-span-12 gap-2">
					<FormField
						control={form.control}
						name="questions.0.text"
						rules={{
							required: 'Question for user is required',
							validate: (value) => wordCountValidation(value, 500),
						}}
						render={({ field }) => {
							return (
								<FormItem>
									<div className="flex justify-between h-6">
										<Label className="font-bold text-black dynamic-large">
											Question your user will be asked
										</Label>
										<p
											className={cn(
												'text-base font-medium leading-normal',
												getWordCount(field?.value) > 500
													? 'text-[#FF82A0]'
													: 'text-black opacity-50'
											)}
										>
											{getWordCount(field?.value)}/500 words
										</p>
									</div>
									<FormControl>
										<Textarea
											{...field}
											placeholder="In 4 to 5 succinct bullet points, illustrate exactly what needs to be the output for this section"
											className={`p-4 rounded-2xl resize-none border-[#d3dde2] bg-white focus-visible:ring-0 
									focus-visible:shadow-none focus-visible:ring-offset-0 min-h-[100px] hidden-scroll`}
										/>
									</FormControl>

									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>
				{/* question description */}
				<div className="flex flex-col col-span-12 gap-2">
					<FormField
						control={form.control}
						name="questions.0.description"
						rules={{
							required: 'Question Description is required',
							validate: (value) => wordCountValidation(value, 500),
						}}
						render={({ field }) => {
							return (
								<FormItem>
									<div className="flex justify-between h-6">
										<Label className="font-bold text-black dynamic-large">
											Description of the question the user will be asked
										</Label>
										<p
											className={cn(
												'text-base font-medium leading-normal',
												getWordCount(field?.value) > 500
													? 'text-[#FF82A0]'
													: 'text-black opacity-50'
											)}
										>
											{getWordCount(field?.value)}/500 words
										</p>
									</div>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Describe the question so that the user can effectively answer it"
											className={`p-4 rounded-2xl dynamic-text resize-none border-[#d3dde2] bg-white focus-visible:ring-0 
                                                            focus-visible:shadow-none focus-visible:ring-offset-0 min-h-[100px] hidden-scroll`}
										/>
									</FormControl>

									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>
				{/* section special context */}
				<div className="flex flex-col col-span-12 gap-2">
					<FormField
						control={form.control}
						name="specialContext"
						rules={{
							validate: (value) => wordCountValidation(value, 500),
						}}
						render={({ field }) => {
							return (
								<FormItem>
									<div className="flex justify-between h-6">
										<Label className="font-bold text-black dynamic-large">
											How this section will be structured
										</Label>
										<p
											className={cn(
												'text-base font-medium leading-normal',
												getWordCount(field?.value) > 500
													? 'text-[#FF82A0]'
													: 'text-black opacity-50'
											)}
										>
											{getWordCount(field?.value)}/500 words
										</p>
									</div>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Describe how structure you want this section to be"
											className={`p-4 rounded-2xl dynamic-text resize-none border-[#d3dde2] bg-white focus-visible:ring-0 
                                                            focus-visible:shadow-none focus-visible:ring-offset-0 min-h-[100px] hidden-scroll`}
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>
				{/* tip for the user */}
				<div className="flex flex-col col-span-12 gap-2">
					<FormField
						control={form.control}
						name="questions.0.proTip"
						rules={{
							required: 'Tip for user is required',
							validate: (value) => wordCountValidation(value, 500),
						}}
						render={({ field }) => {
							return (
								<FormItem>
									<div className="flex justify-between h-6">
										<Label className="font-bold text-black dynamic-large">
											A tip your user will be provided
										</Label>
										<p
											className={cn(
												'text-base font-medium leading-normal',
												getWordCount(field?.value) > 500
													? 'text-[#FF82A0]'
													: 'text-black opacity-50'
											)}
										>
											{getWordCount(field?.value)}/500 words
										</p>
									</div>
									<FormControl>
										<Input
											{...field}
											placeholder="Enter Tip"
											className={`p-4 rounded-xl bg-[#fff] h-12 dynamic-text text-black border border-[#D3DDE2]`}
										/>
									</FormControl>

									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>

				<div className="flex flex-col col-span-12 gap-2">
					<div className="flex flex-row items-center gap-4">
						<p className="font-bold text-black dynamic-large">
							Information required from the solicitation
						</p>
					</div>
					<FormField
						control={form.control}
						name="requiredRfpSections"
						rules={{
							required: 'Solicitations is required',
						}}
						render={({ field }) => {
							return (
								<FormItem className="col-span-6">
									<FormControl>
										<Multiselect
											title="Select Solicitations"
											options={generalSolicitationDropdown}
											value={solicitationsArray}
											canAddOption={false}
											isModal={true}
											onSelectedValuesChange={(values: any) => {
												if (values.length > 2) {
													customToast.dismiss();
													customToast.error({
														title: 'Max 2 Options can be selected',
													});
													values.pop();
													setSolicitations(values.slice(0, 2));
													field.onChange(values.slice(0, 2));
													return;
												} else {
													setSolicitations(values.slice(0, 2));
													field.onChange(values.slice(0, 2));
												}
											}}
										></Multiselect>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
				</div>

				<div className="flex flex-row items-center justify-end col-span-12 gap-4 pt-4 mb-1">
					<SecondaryButton
						type="button"
						onClick={() => {
							if (editSectionData) {
								setEditSectionData(null);
								DialogClosed();
							} else {
								dispatch(
									openDialog({
										type: 'createTemplateSection',
										editSectionID: null,
										sectionCreatedByUser: true,
										sectionDialogStep: '2',
									})
								);
							}
						}}
					>
						Cancel
					</SecondaryButton>
					<PrimaryButton type="submit" className="uppercase">
						{editSectionData && !duplicateSection
							? 'Update Section'
							: 'Confirm section creation'}
					</PrimaryButton>
				</div>
			</div>
		</>
	);
};
