// authSlice.ts
import { User } from '@/lib/types/apiTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
	token: string | null;
	error: string | null;
	isLoading: boolean;
	isAuthenticated: boolean;
	user: User | null;
	trialBannerDelayDate: string | null;
}

const initialState: AuthState = {
	isLoading: false,
	isAuthenticated: false,
	token: null,
	user: null,
	error: null,
	trialBannerDelayDate: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginRequest(state) {
			state.isLoading = true;
			state.error = null;
		},
		loginRequires2FA(state) {
			state.isLoading = false;
			state.error = null;
		},
		loginSuccess(state, action: PayloadAction<{ user: User; token: string }>) {
			state.isAuthenticated = true;
			state.user = action.payload.user;
			state.token = action.payload.token;
			state.isLoading = false;
		},
		loginFailure(state, action: PayloadAction<string>) {
			state.isAuthenticated = false;
			state.user = null;
			state.token = null;
			state.error = action.payload;
			state.isLoading = false;
		},
		logout(state) {
			state.isAuthenticated = false;
			state.user = null;
			state.token = null;
			state.error = null;
		},
		updateToken(state, action: PayloadAction<string>) {
			state.token = action.payload;
		},
		updateProfile(state, action: PayloadAction<{ user: User }>) {
			state.user = action.payload.user;
		},
		updateTrialBannerDelayDate(state, action: PayloadAction<string>) {
			state.trialBannerDelayDate = action.payload;
		},
	},
});

export const {
	loginRequest,
	loginRequires2FA,
	loginSuccess,
	loginFailure,
	logout,
	updateToken,
	updateProfile,
	updateTrialBannerDelayDate,
} = authSlice.actions;
export default authSlice.reducer;
