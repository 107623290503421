import { FC, useState } from 'react';
import styles from './CitationWrapper.module.css';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import CitationBox from '@/components/CitationBox/CitationBox';
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { Proposal, Section } from '@/lib/types/apiTypes';
import { v4 } from 'uuid';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';

interface CitationWrapperProps {
	proposal: Proposal;
}

const CitationWrapper: FC<CitationWrapperProps> = ({ proposal }) => {
	// State to manage accordion open/close
	const [isOpen, setIsOpen] = useState(true);

	const dispatch = useAppDispatch();
	// Helper function to determine if a section has references or any of its children have references
	const hasReferences = (section: Section): boolean => {
		if (section.references.length > 0) {
			return true;
		}
		return section.children.some((child) => hasReferences(child));
	};

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	const renderCitation = (
		sec: Section,
		level: number,
		prefix: string,
		index?: number
	) => {
		let headingPrefix = prefix;
		if (level === 0) {
			headingPrefix = String(index);
		}

		// Render the CitationBox only if the current section has references.
		const citationElement =
			sec.references.length > 0 ? (
				<CitationBox
					key={sec.id}
					title={sec.name}
					prefix={headingPrefix}
					citation={sec.references}
					section={sec}
				/>
			) : null;

		// Recursively render children sections that have references.
		const childrenCitations = sec.children
			.map((child, idx) =>
				renderCitation(child, level + 1, `${headingPrefix}.${idx + 1}`)
			)
			.filter(Boolean); // Filter out null values to avoid rendering empty divs

		// Only return a non-null result if the section or its children have citations.
		if (citationElement || childrenCitations.length > 0) {
			return (
				<div key={sec.id}>
					{citationElement}
					{childrenCitations}
				</div>
			);
		}

		return null; // Return null if the section and its children have no citations.
	};

	return (
		<Card id="citations" className={cn(styles.card, 'rounded-2xl')}>
			<CardHeader
				className={cn(
					'flex flex-row gap-6 items-center justify-between py-3 px-0',
					isOpen ? 'border-b  border-[#d3dde2]' : null
				)}
			>
				<div
					className="flex items-center font-bold text-black cursor-pointer dynamic-large"
					onClick={toggleAccordion}
				>
					Citations
				</div>
				<div className="flex items-center gap-2 cursor-pointer">
					<TextButton
						className="font-bold dynamic-medium text-primary"
						onClick={() => dispatch(openDialog({ type: 'addCitation' }))}
					>
						Add Citation
					</TextButton>
					<div onClick={toggleAccordion}>
						{isOpen ? (
							<ChevronDown className="text-primary" />
						) : (
							<ChevronUp className="text-primary" />
						)}
					</div>
				</div>
			</CardHeader>
			{isOpen && (
				<CardContent className="px-0 pt-8 pb-0">
					{proposal?.sections
						.filter(hasReferences)
						.map((section, index) => (
							<div key={v4()}>{renderCitation(section, 0, '', index + 1)}</div>
						))}
				</CardContent>
			)}
		</Card>
	);
};

export default CitationWrapper;
