import { SVGProps } from 'react';

const TemplateAssembler = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="185"
			height="95"
			viewBox="0 0 185 95"
			fill="none"
		>
			<g clipPath="url(#clip0_6690_12089)">
				<path
					d="M164.496 2H89.0664C79.1253 2 71.0664 10.0589 71.0664 20V27.73C71.0664 37.6711 79.1253 45.73 89.0664 45.73H164.496C174.438 45.73 182.496 37.6711 182.496 27.73V20C182.496 10.0589 174.438 2 164.496 2Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M95.9359 24.8105H20.5059C10.5647 24.8105 2.50586 32.8694 2.50586 42.8105V50.5405C2.50586 60.4817 10.5647 68.5406 20.5059 68.5406H95.9359C105.877 68.5406 113.936 60.4817 113.936 50.5405V42.8105C113.936 32.8694 105.877 24.8105 95.9359 24.8105Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M126.777 16.1895V31.5395L121.387 25.9795"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M126.777 31.5385L132.337 25.9785"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M99.4957 83.9607L92.5857 59.0407C92.2957 58.0007 93.2557 57.0407 94.2957 57.3207L119.326 64.1507C120.356 64.4307 120.696 65.7207 119.946 66.4807L116.346 70.0807C115.806 70.6207 115.806 71.5107 116.346 72.0507L128.446 84.1507C128.986 84.6907 128.986 85.5807 128.446 86.1207L122.036 92.5307C121.496 93.0707 120.606 93.0707 120.066 92.5307L107.906 80.3707C107.356 79.8207 106.466 79.8307 105.926 80.3707L101.826 84.5507C101.076 85.3207 99.7757 84.9807 99.4857 83.9507L99.4957 83.9607Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M68.416 92.9416L83.166 78.1816"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M82.3652 88.0516L92.2252 78.1816"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6690_12089">
					<rect
						width="183.99"
						height="94.94"
						fill="white"
						transform="translate(0.505859)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default TemplateAssembler;
