import { FC, useEffect, useState } from 'react';
import styles from './UserAvatarList.module.css';
import { Avatar, AvatarImage } from '../ui/avatar';
import { cn } from '@/lib/utils';
import { PopoverAlignment, Team, User } from '@/lib/types/apiTypes';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Input } from '../ui/input';
import { useQueryClient } from 'react-query';
import { manageProposalAssignment } from '@/lib/functions/apiCalls';
import useAuth from '@/lib/hooks/useAuth';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { colorClasses } from '@/lib/types/constants';
import UsersPlus from '../ui/icons/UsersPlus';
import FallbackAvatar from '../FallbackAvatar/FallbackAvatar';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';

interface UserAvatarListProps {
	additionalCount: number;
	users: User[];
	allUsers: User[];
	teams?: Team[];
	proposalId?: number;
	createdBy?: number;
	popoverAlignment?: PopoverAlignment;
	userAssignedAction?: () => void;
	showAssignPopover?: boolean;
}

const UserAvatarList: FC<UserAvatarListProps> = ({
	users,
	additionalCount,
	allUsers,
	teams,
	proposalId,
	createdBy,
	popoverAlignment,
	userAssignedAction,
	showAssignPopover = true,
}) => {
	const dispatch = useAppDispatch();

	const [filterUser, setFilterUser] = useState<User[]>(allUsers || []);

	const queryClient = useQueryClient();

	const { isAdmin, isMember } = useAuth();

	const user = useSelector((state: RootState) => state.auth.user);

	const [userColors, setUserColors] = useState(new Map());

	const [teamColors, setTeamColors] = useState(new Map());

	const [userListOpen, setUserListOpen] = useState(false);

	// Initialize the color map
	useEffect(() => {
		const newUserColors = new Map();
		users.forEach((user) => {
			newUserColors.set(user.id, colorClasses[user.id % colorClasses.length]);
		});
		setUserColors(newUserColors);
	}, [users]);

	useEffect(() => {
		const newTeamColors = new Map();
		teams?.forEach((team) => {
			newTeamColors.set(team.id, colorClasses[team.id % colorClasses.length]);
		});
		setTeamColors(newTeamColors);
	}, [teams]);

	useEffect(() => {
		if (userListOpen) {
			setFilterUser(allUsers || []);
		}
	}, [userListOpen]);

	const handleFilter = (event) => {
		const value = event.target.value;
		const filteredUsers = allUsers?.filter((user) => {
			return user.name.toLowerCase().includes(value.toLowerCase());
		});
		setFilterUser(filteredUsers || []);
	};

	const handleAssign = async (
		action: 'assign' | 'unassign',
		userId: number
	) => {
		try {
			const response = await manageProposalAssignment(
				proposalId as number,
				action,
				{
					userId,
				}
			);
			handleResponse(response, response.message);
			queryClient.invalidateQueries('proposals');
			userAssignedAction && userAssignedAction();
		} catch (error) {
			ErrorHandle(dispatch, error);
		}
	};

	const renderPopupContent = () => {
		if (allUsers.length === 0) return null;
		return (
			<PopoverContent
				className="p-3 bg-white rounded-xl w-[320px]"
				align={popoverAlignment}
				onInteractOutside={() => setUserListOpen(false)}
			>
				<p className="text-[#5d6f79] font-semibold">Assign Users</p>
				<Input
					placeholder="Search..."
					className="w-full mt-2 bg-white rounded-md border border-[#D3DDE2] text-[#5d6f79] font-semibold"
					onChange={handleFilter}
				/>
				<div className="max-h-[150px] cursor-pointer overflow-y-scroll overflow-x-hidden light-scroll2 mt-2 flex flex-col gap-1 pr-2">
					{filterUser.length > 0 ? (
						filterUser.map((user, index) => (
							<div
								key={index}
								className={
									'text-[#5d6f79] hover:bg-[#D3DDE2] px-2 py-2 rounded-lg flex items-center justify-between focus-visible:ring-0 focus-visible:ring-offset-0'
								}
								onClick={() => {
									handleAssign(
										user.isAssigned ? 'unassign' : 'assign',
										user.id
									);
									setUserListOpen(false);
								}}
							>
								<div className="flex items-center gap-2">
									{user.profilePicture ? (
										<Avatar key={index} className="w-[28px] h-[28px]">
											<AvatarImage src={user.profilePicture} alt={user.name} />
										</Avatar>
									) : (
										<FallbackAvatar
											user={user}
											className="w-7 h-7"
										></FallbackAvatar>
									)}

									<p className="text-sm">{user.name}</p>
								</div>
								<small className="text-[#5d6f79] uppercase">
									{user.isAssigned ? 'Assigned' : 'Unassigned'}
								</small>
							</div>
						))
					) : (
						<div className="text-center text-[#5d6f79] py-2">No user found</div>
					)}
				</div>
			</PopoverContent>
		);
	};

	return (
		<Popover open={showAssignPopover && userListOpen}>
			<PopoverTrigger
				asChild
				onMouseDown={(e) => e.stopPropagation()}
				onClick={() => setUserListOpen(!userListOpen)}
				className="focus-visible:ring-0 focus-visible:ring-offset-0 focus:outline-none users-avatar"
			>
				<div className={cn(styles.avatar_group)}>
					{users?.length || teams?.length ? (
						<>
							{teams?.map((team, index) => (
								<div
									key={index}
									className={cn(
										styles.avatar_name,
										'flex items-center justify-center text-white border border-white rounded-full w-[32px] h-[32px] uppercase',
										teamColors.get(team.id)
									)}
								>
									{team?.name?.[0] || 'T'}
								</div>
							))}
							{users?.map((user, index) => {
								if (user?.profilePicture) {
									return (
										<Avatar
											key={index}
											className={cn(styles.avatar, 'w-[32px] h-[32px]')}
										>
											<AvatarImage
												src={user.profilePicture as string}
												alt={user.name}
											/>
											<FallbackAvatar user={user}></FallbackAvatar>
										</Avatar>
									);
								} else {
									return (
										<div
											key={index}
											className={cn(
												styles.avatar_name,
												'flex items-center justify-center text-white border border-white rounded-full w-[32px] h-[32px] uppercase',
												userColors.get(user.id)
											)}
										>
											{user?.name ? user?.name[0] : user.email[0]}
										</div>
									);
								}
							})}
						</>
					) : (
						<div className={styles.avatar}>
							<UsersPlus />
						</div>
					)}
					{additionalCount > 0 && (
						<div
							className={cn(
								styles.more_indicator,
								'font-semibold text-primary ml-3 text-black base'
							)}
						>
							+{additionalCount}
						</div>
					)}
				</div>
			</PopoverTrigger>
			{isAdmin || (isMember && createdBy === user?.id) ? (
				renderPopupContent()
			) : (
				<></>
			)}
		</Popover>
	);
};

export default UserAvatarList;
