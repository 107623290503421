import { toast as showToast } from '@/components/ui/use-toast';

// Define a union type for toast types
type ToastType = 'success' | 'error' | 'warning' | 'info';

// Define an interface for toast configuration
interface ToastConfig {
	title: string;
	subTitle?: string;
	duration: number;
	type: ToastType;
}

// Define an interface for individual toast methods
interface ToastMethodConfig {
	title?: string;
	subTitle?: string;
	duration?: number;
}

// Define styles for the toast notifications
const toastStyle = {
	color: '#6d7d86',
	width: 'auto',
	minWidth: '30%',
	maxWidth: '100%',
	border: '1px solid #5d9bfd',
	paddingRight: '56px',
	borderRadius: '20px',
};

// Define toast types and default durations
const defaultSuccessMsgDuration = 2000;
const defaultMsgDuration = 4000;

// Maintain a list of active toasts
const toasts: any[] = [];

// Function to get background color based on toast type
const getBackgroundColor = (type: ToastType): string => {
	switch (type) {
		case 'success':
			return '#ecfdf3';
		case 'error':
			return '#ffd6f6';
		case 'warning':
			return '#fffaba';
		case 'info':
		default:
			return '#e2ecff';
	}
};

// Function to add a new toast
const addToast = ({ title, subTitle, duration, type }: ToastConfig) => {
	const backgroundColor = getBackgroundColor(type);
	const toast = showToast({
		title,
		description: subTitle,
		duration,
		style: {
			background: backgroundColor,
			...toastStyle,
		},
	});
	toasts.push(toast);
};

// Custom toast object with different methods for different toast types
const customToast = {
	success: ({
		title = '',
		subTitle = '',
		duration = defaultSuccessMsgDuration,
	}: ToastMethodConfig) => {
		addToast({ title, subTitle, duration: duration!, type: 'success' });
	},

	error: ({
		title = '',
		subTitle = '',
		duration = defaultMsgDuration,
	}: ToastMethodConfig) => {
		addToast({ title, subTitle, duration: duration!, type: 'error' });
	},

	warning: ({
		title = '',
		subTitle = '',
		duration = defaultMsgDuration,
	}: ToastMethodConfig) => {
		addToast({ title, subTitle, duration: duration!, type: 'warning' });
	},

	info: ({
		title = '',
		subTitle = '',
		duration = defaultMsgDuration,
	}: ToastMethodConfig) => {
		addToast({ title, subTitle, duration: duration!, type: 'info' });
	},

	dismiss: () => {
		toasts.forEach((toast) => toast.dismiss());
	},
};

export default customToast;
