import { createSelector } from '@reduxjs/toolkit'; // or 'reselect' if not using Redux Toolkit
import { RootState } from '@/redux/store/store';

// Selector to get the suggestions map
export const getSuggestionsMap = (state: RootState) =>
	state.suggestion.suggestions;

// Memoized selector to get the suggestions for the active section
export const memoizedSuggestionsForSection = createSelector(
	[getSuggestionsMap, (_, sectionId: number | null) => sectionId],
	(suggestionsMap, sectionId): any[] => {
		// Replace `any` with your suggestion type
		return sectionId !== null ? suggestionsMap[sectionId] || [] : [];
	}
);
