import { FC } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { RFP } from '@/lib/types/apiTypes';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { convertToISO8601 } from '@/lib/functions/funcUtils';

interface ProposalInfoCardProps {
	data: RFP;
}

const ProposalInfoCard: FC<ProposalInfoCardProps> = ({ data }) => {
	return (
		<Card className="p-6 h-[320px] bg-lightBackground rounded-xl border border-[rgba(185,212,255,0.40)]">
			<div className="flex flex-col h-full gap-4 pr-4 overflow-y-auto">
				<CardHeader className="p-0">
					<CardTitle className="text-black dynamic-medium">
						Additional Information
					</CardTitle>
				</CardHeader>
				<CardContent className="flex flex-col gap-4 p-0 text-sm text-black xl:text-base xl:p-0">
					<p className="text-sm text-left uppercase xl:text-base text-secondary line-clamp-1">
						<span className="mr-2 text-sm font-bold text-black capitalize xl:text-base">
							Agency:
						</span>{' '}
						{data?.metaData.agency || '--'}
					</p>
					<div className="inline-flex text-sm text-left uppercase xl:text-base text-secondary line-clamp-1">
						<span className="mr-2 text-sm font-bold text-black capitalize xl:text-base">
							Code:
						</span>{' '}
						<TooltipProvider delayDuration={50}>
							<Tooltip>
								<TooltipTrigger>
									<p className="text-sm text-left uppercase xl:text-base text-secondary line-clamp-1">
										{data?.metaData.code || '--'}
									</p>
								</TooltipTrigger>
								<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
									<p>{data?.metaData.code || '--'}</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</div>
					<div className="inline-flex text-sm text-left uppercase xl:text-base text-secondary line-clamp-1">
						<span className="mr-2 text-sm font-bold text-black capitalize xl:text-base">
							Title:
						</span>{' '}
						<TooltipProvider delayDuration={50}>
							<Tooltip>
								<TooltipTrigger>
									<p className="text-sm text-left uppercase xl:text-base text-secondary line-clamp-1">
										{data?.metaData.title || '--'}
									</p>
								</TooltipTrigger>
								<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
									<p>{data?.metaData.title || '--'}</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</div>

					<p className="text-sm text-left uppercase xl:text-base text-secondary line-clamp-1">
						<span className="mr-2 text-sm font-bold text-black capitalize xl:text-base">
							Deadline:
						</span>
						{data?.metaData.deadline
							? convertToISO8601(data?.metaData.deadline)
							: '--'}
					</p>
					<p className="text-sm text-left uppercase xl:text-base text-secondary line-clamp-1">
						<span className="mr-2 text-sm font-bold text-black capitalize xl:text-base">
							Funding:
						</span>
						{data?.metaData.funding || '--'}
					</p>
				</CardContent>
			</div>
		</Card>
	);
};

export default ProposalInfoCard;
