import customToast from '@/components/CustomToast/CustomToast';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { Multiselect } from '@/components/ui/shared/Multiselect/Multiselect';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { generalSolicitationDropdown } from '@/constants/templateBuilderData';
import { cropText } from '@/lib/functions/funcUtils';
import {
	Dropdown,
	SectionBuilder,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { cn } from '@/lib/utils';
import { ChevronRight, PencilIcon, UserCircle } from 'lucide-react';
import { FC, MutableRefObject, useEffect, useState } from 'react';
import SelectedTemplateMessage from '../../SelectedTemplateMessage/SelectedTemplateMessage';
import { Separator } from '@/components/ui/separator';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import useProposalCategory from '@/lib/hooks/useProposalCategory';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
	Delimiter,
	Tag,
	TagInput,
} from '@/components/ui/shared/TagInput/tag-input';
import {
	findParentBySystemName,
	splitTagArray,
} from '@/lib/functions/TemplateBuilder/templateUtils';
import { useAppSelector } from '@/lib/hooks/hooks';
import { RootState } from '@/redux/store/store';

interface TemplateSectionViewerProps {
	showSection: boolean;
	selectedSection: SectionBuilder | null | undefined;
	wordCountRef: MutableRefObject<HTMLInputElement | null>;
	updateSections: (wordCount: any) => void;
	tagOptions: Dropdown[];
	tags: Dropdown[];
	updateTemplatePrerequisites: (newTags: [Dropdown, ...Dropdown[]]) => void;
	solicitationSections: Dropdown[];
	setSolicitationSections: (value: React.SetStateAction<Dropdown[]>) => void;
	updateTemplateSolicitationSections: (
		newSolicitationSection: [Dropdown, ...Dropdown[]]
	) => void;
	updateSectionName: (sectionName: string) => void;
	expectedOutput: string;
	updateExpectedOutput: (expectedOutput: string) => void;
	tableHeadings: Tag[];
	updateTableHeadings: (tableHeadings: Tag[]) => void;
}

const TemplateSectionViewer: FC<TemplateSectionViewerProps> = ({
	showSection,
	selectedSection,
	wordCountRef,
	updateSections,
	tagOptions,
	tags,
	updateTemplatePrerequisites,
	solicitationSections,
	setSolicitationSections,
	updateTemplateSolicitationSections,
	updateSectionName,
	expectedOutput,
	updateExpectedOutput,
	tableHeadings,
	updateTableHeadings,
}) => {
	const proposalCategory = useProposalCategory();

	const sectionName = selectedSection?.alias;

	const [isEditing, setIsEditing] = useState(false);
	const [editName, setEditName] = useState<string | undefined>(sectionName);
	const isTemplateMode = location.pathname.includes('template');

	const templateBuilderData = useAppSelector(
		(root: RootState) => root.templates.templateBuilder
	);

	useEffect(() => {
		setEditName(sectionName);
	}, [selectedSection]);

	const findParentNode = findParentBySystemName(
		templateBuilderData?.sections,
		selectedSection?.systemName.toLowerCase()
	);

	return (
		<div
			className={`bg-white shadow-main rounded-2xl p-6 ${!showSection && 'flex items-center'} ${proposalCategory === 'questionnaire' ? 'col-span-6' : 'col-span-4'}`}
		>
			{showSection ? (
				<div
					className={`flex flex-col col-span-3   ${
						showSection ? 'visible' : 'hidden'
					}`}
				>
					{isEditing ? (
						<>
							<label className="mb-1 text-sm font-semibold text-black">
								Section Alias
							</label>
							<Input
								placeholder="Section Alias"
								value={editName}
								onChange={(e) => {
									setEditName(e.target.value);
								}}
								className="w-full h-12 bg-white rounded-xl"
							/>
							{!editName || !editName.trim() ? (
								<p className="pt-2 dynamic-text font-medium text-lightRed">
									Please enter the section name
								</p>
							) : editName.length > 1000 ? (
								<p className="pt-2 dynamic-text font-medium text-lightRed">
									Character count must be less than 1000
								</p>
							) : (
								<></>
							)}
							<div className="flex items-center justify-end gap-3 mt-3">
								<SecondaryButton
									type="button"
									onClick={() => setIsEditing(false)}
								>
									Cancel
								</SecondaryButton>
								<PrimaryButton
									type="button"
									disabled={
										!editName || !editName.trim() || editName.length > 1000
									}
									onClick={() => {
										setIsEditing(false);
										updateSectionName(editName as string);
									}}
								>
									Save
								</PrimaryButton>
							</div>
						</>
					) : (
						<div className="flex flex-col gap-2 overflow-hidden 2xl:flex-row 2xl:items-center 2xl:justify-between 2xl:gap-5">
							<div className="flex flex-row items-center flex-grow w-full gap-2 overflow-hidden">
								{selectedSection && selectedSection?.organizationIdentifier && (
									<UserCircle
										size={18}
										className="flex flex-grow min-w-4"
										color="#E861C9"
									/>
								)}{' '}
								<TooltipProvider delayDuration={50}>
									<Tooltip>
										<TooltipTrigger type="button" className="w-full">
											<h2 className="flex w-full overflow-hidden font-bold text-black dynamic-xl-large text-nowrap text-ellipsis">
												{selectedSection?.alias && (
													<span className="pr-2 truncate">{sectionName}</span>
												)}
											</h2>
										</TooltipTrigger>
										{sectionName && sectionName.length > 40 && (
											<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
												{sectionName}
											</TooltipContent>
										)}
									</Tooltip>
								</TooltipProvider>
							</div>
							<div
								role="button"
								onClick={() => setIsEditing(!isEditing)}
								tabIndex={0}
								className="flex items-center gap-2 font-medium uppercase text-primary"
							>
								<PencilIcon size={16} />
								Edit
							</div>
						</div>
					)}
					<div className="flex items-center gap-2 mt-3">
						{selectedSection?.tags &&
							selectedSection.tags.slice(0, 2).map((item) => (
								<TooltipProvider key={item}>
									<Tooltip>
										<TooltipTrigger type="button">
											<Badge
												key={item}
												className="items-center justify-center block px-3 bg-white border max-w-40 text-secondary border-secondary"
											>
												<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-small text-nowrap text-ellipsis">
													{cropText(item, 16)}
												</span>
											</Badge>
										</TooltipTrigger>
										<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
											{item}
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							))}
						{selectedSection?.tags && selectedSection.tags.length > 2 && (
							<Popover>
								<PopoverTrigger onClick={(e) => e.stopPropagation()}>
									<TooltipProvider>
										<Tooltip>
											<TooltipTrigger
												type="button"
												className="flex items-center justify-center"
											>
												<ChevronRight
													className={cn('text-[#5D6F79]')}
													size={18}
												/>
											</TooltipTrigger>
											<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
												View More Tags
											</TooltipContent>
										</Tooltip>
									</TooltipProvider>
								</PopoverTrigger>
								<PopoverContent
									className="gap-2 py-4 bg-white rounded-md "
									align="end"
								>
									<h2 className="text-[#5D6F79] font-bold mb-3">
										Section Tags
									</h2>
									<div className="flex flex-wrap gap-1">
										{selectedSection?.tags &&
											selectedSection.tags.map((item) => (
												<Badge
													key={item}
													className="items-center justify-center block px-3 bg-white border text-secondary border-secondary"
												>
													{item.length > 35 ? (
														<TooltipProvider>
															<Tooltip>
																<TooltipTrigger
																	tabIndex={-1}
																	className="flex text-left"
																>
																	<span className="font-medium uppercase text-secondary dynamic-badge">
																		{cropText(item, 35)}
																	</span>
																</TooltipTrigger>
																<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
																	{item}
																</TooltipContent>
															</Tooltip>
														</TooltipProvider>
													) : (
														<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-badge">
															{item}
														</span>
													)}
												</Badge>
											))}
									</div>
								</PopoverContent>
							</Popover>
						)}
					</div>
					<p className="mt-3 text-black dynamic-text">
						{selectedSection?.description && (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger type="button" className="flex">
										<span className="inline-block text-left text-black">
											<span className="inline md:inline 2xl:hidden">
												{cropText(selectedSection.description, 160)}
											</span>
											<span className="hidden md:hidden 2xl:inline">
												{selectedSection.description}
											</span>
										</span>
									</TooltipTrigger>
									<TooltipContent
										className="bg-white overflow-y-auto text-wrap max-w-md break-words text-[#5D6F79] 
										dynamic-small py-4 px-4 rounded-xl border-none shadow-none drop-shadow-effect"
									>
										{selectedSection.description}
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						)}
					</p>

					<Separator className="my-5 bg-[#D3DDE2]" />
					<div className={`flex flex-col gap-5 `}>
						<div className="flex flex-col gap-3">
							<h2 className="font-bold leading-3 text-black dynamic-large">
								Words Count
							</h2>
							<span className="text-black dynamic-small">
								Please enter the max word count for this section.
							</span>
							<Input
								type="number"
								ref={wordCountRef}
								maxLength={4}
								min={100}
								onKeyDown={(e) =>
									['e', 'E', '+', '-', 'Enter'].includes(e.key) &&
									e.preventDefault()
								}
								onInput={(event: any) => {
									event.target.value = event.target.value.replace(/^0+/, ''); // Remove leading zeros
									if (event.target.value.length > event.target.maxLength) {
										event.target.value = event.target.value.slice(
											0,
											event.target.maxLength
										);
									}
									if (event.target.value > 1000) {
										customToast.dismiss();
										customToast.error({
											title:
												'Word count limit exceed. Can not add value greater than 1000.',
										});
										event.target.value = event.target.value.slice(0, -1);
									}
								}}
								onChange={(event) => updateSections(event.target.value)}
								placeholder="Enter word count"
								className={`p-4 rounded-xl bg-white h-12 dynamic-small
								text-black border`}
							/>
							{((wordCountRef.current?.value &&
								Number(wordCountRef.current.value) > 1000) ||
								(wordCountRef.current?.value &&
									Number(wordCountRef.current.value) < 100)) && (
								<p className="dynamic-text font-medium text-lightRed">
									Word count must be between 100 and 1000
								</p>
							)}
						</div>
						{proposalCategory !== 'questionnaire' &&
							tagOptions &&
							tagOptions.length > 0 && (
								<div className="flex flex-col gap-3">
									<h2 className="font-bold leading-3 text-black dynamic-large">
										Which sections the selected section connects to
									</h2>
									<span className="text-black dynamic-small">
										Connect sections that can help inform the creation of this
										section
									</span>
									<Multiselect
										title="Select prerequisites"
										options={tagOptions.filter(
											(item) =>
												item.value.toLocaleLowerCase() !==
												selectedSection?.systemName.toLocaleLowerCase()
										)}
										value={tags}
										canAddOption={false}
										onSelectedValuesChange={(values) => {
											updateTemplatePrerequisites(values as any);
										}}
										tbuilderParentSystemName={findParentNode?.systemName}
									></Multiselect>
								</div>
							)}
						{proposalCategory === 'questionnaire' && (
							<div className="flex flex-col gap-3">
								<div className="flex flex-row items-center justify-between">
									<p className="font-bold text-black dynamic-large leading-3">
										Expected Output
									</p>
								</div>
								<p className="text-black dynamic-text">
									Please specify if the output will be <b>just text</b> or{' '}
									<b>contain a table</b>
								</p>
								<RadioGroup
									value={expectedOutput || undefined}
									onValueChange={(value) => updateExpectedOutput(value)}
									defaultValue={'text'}
								>
									<div className="flex flex-row items-center gap-4">
										<RadioGroupItem
											value="text"
											onClick={() => {
												updateExpectedOutput('text');
											}}
										/>{' '}
										<p
											className="font-bold text-black dynamic-medium"
											onClick={() => {
												updateExpectedOutput('text');
											}}
										>
											Text
										</p>
										<RadioGroupItem
											value="table"
											onClick={() => {
												updateExpectedOutput('table');
											}}
										/>
										<p
											className="font-bold text-black dynamic-medium"
											onClick={() => {
												updateExpectedOutput('table');
											}}
										>
											Table
										</p>
									</div>
								</RadioGroup>

								{expectedOutput === 'table' && (
									<TagInput
										placeholder="Enter table headings"
										tags={tableHeadings}
										maxLength={50}
										inputFieldPostion="bottom"
										variant={'custom'}
										shape={'pill'}
										minTags={1}
										delimiter={Delimiter.Comma}
										delimiterList={[Delimiter.Enter, Delimiter.Comma]}
										allowDuplicates={false}
										className={`p-4 rounded-xl bg-[#fff] h-14 dynamic-text
												text-black border border-[#D3DDE2]`}
										setTags={(newTags) => {
											const splitTags =
												newTags && newTags.length > 0
													? splitTagArray(newTags)
													: [];
											updateTableHeadings(splitTags);
										}}
									/>
								)}
							</div>
						)}

						{/* required solicitation sections */}
						<div
							className={`flex flex-col gap-3 ${isTemplateMode ? 'visible' : 'hidden'}`}
						>
							<h2 className="font-bold leading-3 text-black dynamic-large">
								Information required from the solicitation to write this section
							</h2>
							<span className="text-black dynamic-small">
								Select parts of the solicitation that will help inform this
								section.{' '}
								<b>
									You can select a maximum of two sections from the
									solicitation.
								</b>
							</span>
							<Multiselect
								title="Select Sections"
								options={generalSolicitationDropdown || []}
								value={solicitationSections}
								canAddOption={false}
								onSelectedValuesChange={(values) => {
									if (values.length > 2) {
										customToast.dismiss();
										customToast.error({
											title: 'Max 2 Options can be selected',
										});
										values.pop();
										setSolicitationSections(values.slice(0, 2));
										return;
									} else {
										updateTemplateSolicitationSections(
											values.slice(0, 2) as [Dropdown, ...Dropdown[]]
										);
									}
								}}
							></Multiselect>
						</div>
					</div>
				</div>
			) : (
				<SelectedTemplateMessage />
			)}
		</div>
	);
};

export default TemplateSectionViewer;
