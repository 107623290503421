import ComplianceCard from '@/components/ComplianceCard/ComplianceCard';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import Lottie from 'lottie-react';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import ComplianceLottie from '@/assets/lotties/run_compliance_matrix_v1.json';
import WriteModeSkeleton from '@/components/WriteModeSkeleton/WriteModeSkeleton';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { cropText, findSection } from '@/lib/functions/funcUtils';
import { Section } from '@/lib/types/apiTypes';
import EmptyCompliance from '@/components/ui/icons/matrix/EmptyCompliance';
import { Skeleton } from '@/components/ui/skeleton';
import { runAndFetchComplianceMatrix } from '@/redux/requests/compliance';
import CustomTooltip from '@/components/CustomTooltip/CustomTooltip';

const ComplianceMatrixWrapper = () => {
	const dispatch = useAppDispatch();
	const matrix = useSelector(
		(state: RootState) => state.compliance.complianceMatrix
	);

	const sectionsRules = useSelector((state: RootState) => {
		return state.compliance.sectionsRules;
	});

	const { activeSection } = useActiveSection();

	const proposal = useSelector((state: RootState) => state.proposal.proposal);

	const loading = useSelector((state: RootState) => state.compliance.loading);

	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);

	// State to store the selected filter
	const [selectedFilter, setSelectedFilter] = useState<string>('all');

	const isComplianceActive = useMemo(() => {
		if (!activeSection) return false;
		const content = activeSection.editorRef?.current?.getData();
		if (content === '' || content === undefined) return true;
	}, [activeSection]);

	const section = useMemo(
		() =>
			findSection(
				proposal?.sections as Section[],
				String(activeSection.sectionId)
			),
		[activeSection.sectionId, proposal?.sections]
	);

	// Function to handle filter change
	const handleFilterChange = (value: string) => {
		setSelectedFilter(value);
	};

	// Filter rules based on the selected filter
	const filteredRules = useMemo(
		() =>
			sectionsRules[activeSection.sectionId as number]?.filter((rule) => {
				if (selectedFilter === 'all') return true; // No filter applied
				return rule.sections[0].sectionCompliance.status === selectedFilter; // Filter by status
			}),
		[selectedFilter, sectionsRules, activeSection.sectionId]
	);

	const handleCompliance = async () => {
		if (!matrix || !activeSection.sectionId) return;
		dispatch(
			runAndFetchComplianceMatrix({
				proposalId: matrix.proposalId,
				sectionId: activeSection.sectionId as number,
				complianceId: matrix.id,
			})
		);
	};

	if (isStreaming) return <WriteModeSkeleton />;

	return (
		<div className="h-full overflow-y-scroll compliance-matrix-wrapper">
			<div className="bg-[#EAF1FC] px-5 pt-5 sticky top-0 z-10">
				{loading ? (
					<div className="mb-6">
						<Lottie animationData={ComplianceLottie} className="mx-auto w-60" />
						<div className="flex flex-col gap-3 mt-4">
							<div className="p-4 bg-white rounded-xl">
								<Skeleton className="h-[96px] w-full rounded-xl bg-[#F5F5F5]" />
								<div className="flex items-center justify-between mt-3">
									<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
									<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
								</div>
							</div>
							<div className="p-4 bg-white rounded-xl">
								<Skeleton className="h-[96px] w-full rounded-xl bg-[#F5F5F5]" />
								<div className="flex items-center justify-between mt-3">
									<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
									<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
								</div>
							</div>
							<div className="p-4 bg-white rounded-xl">
								<Skeleton className="h-[96px] w-full rounded-xl bg-[#F5F5F5]" />
								<div className="flex items-center justify-between mt-3">
									<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
									<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
								</div>
							</div>

							<div className="p-4 bg-white rounded-xl">
								<Skeleton className="h-[96px] w-full rounded-xl bg-[#F5F5F5]" />
								<div className="flex items-center justify-between mt-3">
									<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
									<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
								</div>
							</div>
						</div>
					</div>
				) : (
					<>
						<div className={!matrix ? 'opacity-40 pointer-events-none' : ''}>
							<h3 className=" text-[#5D9BFD] font-bold flex items-center gap-3 flex-1 dynamic-medium mb-4">
								<span className="pr-6 border-r-2 border-[#0000] border-separater-pink">
									{activeSection.prefix}
								</span>
								<CustomTooltip
									text={section?.alias || section?.name}
									triggerNode={
										<h3 className=" text-[#5D9BFD] font-bold flex items-center gap-3 flex-1 dynamic-medium">
											{cropText(section?.alias || section?.name || '', 30)}
										</h3>
									}
								/>
							</h3>
							<PrimaryButton
								className="w-full mx-auto"
								onClick={handleCompliance}
								disabled={
									isComplianceActive ||
									sectionsRules[activeSection.sectionId as number] === undefined
										? true
										: false
								}
							>
								Run Compliance
							</PrimaryButton>

							<div className="flex items-center justify-between py-4">
								<Select onValueChange={handleFilterChange}>
									<SelectTrigger className="w-full max-w-[150px] bg-white rounded-xl h-12 text-base text-black px-4">
										<SelectValue placeholder="Filter" />
									</SelectTrigger>
									<SelectContent className="text-base text-black bg-white">
										<SelectItem value="all">All</SelectItem>
										<SelectItem value="not_checked">Not Checked</SelectItem>
										<SelectItem value="addressed">Addressed</SelectItem>
										<SelectItem value="not_addressed">Not Addressed</SelectItem>
									</SelectContent>
								</Select>
								<SecondaryButton
									onClick={() =>
										dispatch(
											openDialog({
												type: 'addMatrixRule',
												complianceRuleId: matrix?.id,
											})
										)
									}
								>
									Add Another
								</SecondaryButton>
							</div>
						</div>
						<div className="flex flex-col gap-3 py-2">
							{filteredRules && filteredRules?.length > 0 ? (
								filteredRules?.map((rule) => (
									<ComplianceCard
										key={v4()}
										text={rule.statement}
										status={rule.sections[0].sectionCompliance.status}
										ruleID={rule?.id}
										user={rule.user}
										isReferences={
											rule.sections[0].sectionCompliance.reference === null ||
											rule.sections[0].sectionCompliance.reference === ''
												? false
												: true
										}
									/>
								))
							) : (
								<div className="flex flex-col items-center justify-center gap-3 mt-12 text-center">
									<EmptyCompliance className="shrink-0" />
									<p className="max-w-sm mx-auto font-bold text-black">
										No compliance requirements connected to this section. Please
										click here to{' '}
										<span
											tabIndex={0}
											onClick={() =>
												dispatch(
													openDialog({
														type: 'addMatrixRule',
														complianceRuleId: matrix?.id,
													})
												)
											}
											className="text-primary"
										>
											add new matrix rule
										</span>{' '}
										and connect compliance requirements to this section
									</p>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ComplianceMatrixWrapper;
