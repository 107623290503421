const File = () => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.3361 8.83337H3.60885C2.4141 8.83337 1.44556 9.80191 1.44556 10.9967V18.7552C1.44556 19.95 2.4141 20.9185 3.60885 20.9185H18.3361C19.5308 20.9185 20.4994 19.95 20.4994 18.7552V10.9967C20.4994 9.80191 19.5308 8.83337 18.3361 8.83337Z"
				stroke="white"
				strokeWidth="1.5"
			/>
			<path
				d="M6.77006 1.94405L2.41877 2.01803C1.75521 2.02931 1.22643 2.57637 1.23771 3.23994L1.25595 4.31262C1.26723 4.97618 1.81429 5.50496 2.47786 5.49368L6.82915 5.41971C7.49271 5.40843 8.02149 4.86136 8.01021 4.1978L7.99197 3.12511C7.98069 2.46155 7.43363 1.93277 6.77006 1.94405Z"
				stroke="white"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export default File;
