import { Button } from '@/components/ui/button';
import { SolicitationProps } from '@/lib/types/constants';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';
import SolicitationStatus from '@/components/SolicitationStatus/SolicitationStatus';
import { Dispatch, SetStateAction } from 'react';
import { openDialog } from '@/redux/slices/pageSlice';
import store from '@/redux/store/store';
import ProposalCategory from '@/components/ProposalCategory/ProposalCategory';

export const columns = (
	handleCreateProposal: (proposalId: number) => void,
	handleCreateManualSolicitation: (solicitation: SolicitationProps) => void,
	setSelectedRowIds?: Dispatch<SetStateAction<string[]>>,
	selectedRowIds?: string[]
): ColumnDef<SolicitationProps>[] => [
	{
		id: 'select',
		header: ({ table }) => (
			<Checkbox
				className="border-black data-[state=checked]:text-white data-[state=checked]:border-primary"
				checked={
					table.getIsAllPageRowsSelected() ||
					(table.getIsSomePageRowsSelected() && 'indeterminate')
				}
				onCheckedChange={(value) => {
					table.toggleAllPageRowsSelected(!!value);
					if (selectedRowIds && setSelectedRowIds) {
						if (value) {
							setSelectedRowIds &&
								setSelectedRowIds([
									...selectedRowIds,
									...table
										.getFilteredRowModel()
										.rows.map((row) => String(row.original.id)),
								]);
						} else {
							setSelectedRowIds &&
								setSelectedRowIds(
									selectedRowIds.filter(
										(id) =>
											!table
												.getFilteredRowModel()
												.rows.map((row) => String(row.original.id))
												.includes(id)
									)
								);
						}
					}
				}}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				className="border-black data-[state=checked]:text-white data-[state=checked]:border-primary"
				checked={row.getIsSelected()}
				onCheckedChange={(value) => {
					row.toggleSelected(!!value);
					if (selectedRowIds && setSelectedRowIds) {
						const rowId = String(row.original.id);
						setSelectedRowIds(
							value
								? [...selectedRowIds, rowId]
								: selectedRowIds.filter((id) => id !== rowId)
						);
					}
				}}
				aria-label="Select row"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'name',
		header: ({ column }) => {
			return (
				<Button
					className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Solicitation Name
					<ArrowUpDown className="w-4 h-4 ml-2" />
				</Button>
			);
		},
		cell: ({ row }) => {
			const handleSelect = () => {
				if (row.original.status === 'being-developed') {
					handleCreateManualSolicitation(row.original);
				} else {
					if (row.original.proposalExists) {
						store.dispatch(
							openDialog({
								type: 'solicitationExists',
								solicitationID: row.original.id,
							})
						);
					} else {
						handleCreateProposal(row.original.id);
					}
				}
			};
			return (
				<div
					onClick={() => handleSelect()}
					className={
						row.original.status === 'errored'
							? 'pointer-events-none text-primary opacity-50'
							: row.original.status === 'processing'
								? 'pointer-events-none'
								: 'cursor-pointer text-primary'
					}
				>
					{row.original.name}
				</div>
			);
		},
	},
	{
		accessorKey: 'isSolicited',
		header: ({ column }) => {
			return (
				<Button
					className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Type
					<ArrowUpDown className="w-4 h-4 ml-2" />
				</Button>
			);
		},
		cell: ({ row }) => {
			return <ProposalCategory category={row.original.category} />;
		},
	},
	{
		accessorKey: 'status',
		header: ({ column }) => {
			return (
				<Button
					className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Status
					<ArrowUpDown className="w-4 h-4 ml-2" />
				</Button>
			);
		},
		cell: ({ row }) => {
			return <SolicitationStatus type={row.original.status} />;
		},
	},
	{
		accessorKey: 'metaData.agency',
		header: ({ column }) => {
			return (
				<Button
					className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Funder
					<ArrowUpDown className="w-4 h-4 ml-2" />
				</Button>
			);
		},
		cell: ({ row }) => {
			return <p>{row.original.metaData?.agency || '--'}</p>;
		},
	},
	{
		accessorKey: 'createdAt',
		header: ({ column }) => {
			return (
				<Button
					className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Created On
					<ArrowUpDown className="w-4 h-4 ml-2" />
				</Button>
			);
		},
		cell: ({ row }) => {
			return (
				<p>{new Date(row.original.createdAt || '--').toLocaleDateString()}</p>
			);
		},
	},
];
