import { ChartsRecommendation } from '@/lib/types/apiTypes';
import { FC } from 'react';
import ChartItem from '../ChartItem/ChartItem';
import { v4 } from 'uuid';

import { RadioGroup } from '../ui/radio-group';
import { Label } from '../ui/label';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { setSelectedChartPlot } from '@/redux/slices/chartSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { cn } from '@/lib/utils';

interface ChartDataListProps {
	data: ChartsRecommendation[];
}

const ChartDataList: FC<ChartDataListProps> = ({ data }) => {
	const activeChart = useSelector(
		(root: RootState) => root.chart.selectedChart
	);

	const activeChartPlot = useSelector(
		(root: RootState) => root.chart.selectedChartPlot
	);

	const dispatch = useAppDispatch();
	return (
		<div className="w-full">
			<div className="grid grid-cols-3 gap-4">
				{data.map((item) => (
					<div key={v4()}>
						<ChartItem item={item} />
					</div>
				))}
			</div>
			<div
				className={cn('mt-4 p-4 rounded-xl', activeChart ? 'bg-[#EAF1FC]' : '')}
			>
				{data
					.filter((item) => item.chart === activeChart)
					.map((item) => (
						<RadioGroup
							value={activeChartPlot}
							onValueChange={(item) => dispatch(setSelectedChartPlot(item))}
							key={v4()}
						>
							{item.plotSuggestions.map((item) => (
								<div
									key={item}
									onClick={() => dispatch(setSelectedChartPlot(item))}
									className={cn(
										'flex items-center p-4 space-x-3 bg-white rounded-lg',
										activeChartPlot === item ? 'bg-[#5D9BFD]' : ''
									)}
								>
									{/* <RadioGroupItem value={item} id={item} /> */}
									<Label
										htmlFor={item}
										className={cn(
											'font-normal text-[#5D6F79]',
											activeChartPlot === item ? 'text-white font-medium' : ''
										)}
									>
										{item}
									</Label>
								</div>
							))}
						</RadioGroup>
					))}
			</div>
		</div>
	);
};

export default ChartDataList;
