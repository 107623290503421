import { Search } from 'lucide-react';
import { FC, useEffect, useRef } from 'react';
import { Input } from '@/components/ui/input';
import DeleteCross from '@/components/ui/icons/new/DeleteCross';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { AvailableSectionsFilterProps } from '@/lib/types/TemplateBuilder/templateTypes';
import useDebounce from '@/lib/hooks/useDebounce';
import { useAppSelector } from '@/lib/hooks/hooks';
import { RootState } from '@/redux/store/store';
import { Separator } from '@/components/ui/separator';

const AvailableSectionsFilter: FC<AvailableSectionsFilterProps> = ({
	handleSearchChange,
	sectionSearch,
	setSectionTagFilter,
}) => {
	const sectionSearchRef = useRef<HTMLInputElement>(null);
	const debouncedSearchChange = useDebounce(handleSearchChange, 800);
	const sectionTagOptions = useAppSelector(
		(root: RootState) => root.sections.sectionTagOptions
	);

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	useEffect(() => {
		setSectionTagFilter('');
	}, [setSectionTagFilter]);

	return (
		<div className="flex flex-col gap-3 template-filters">
			<div className="grid items-center grid-cols-12 gap-3">
				<div className="col-span-12">
					<div className="relative">
						<Input
							ref={sectionSearchRef}
							defaultValue={sectionSearch}
							onChange={(event) => debouncedSearchChange(event.target.value)}
							onKeyDown={handleKeyDown}
							placeholder="Search"
							className="bg-white border-[#D3DDE2] h-12 p-4 dynamic-small rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-12"
						/>
						<div className="absolute right-3 top-4">
							<div className="flex flex-row items-center">
								{sectionSearch && (
									<DeleteCross
										onClick={() => {
											if (sectionSearchRef?.current) {
												sectionSearchRef.current.value = '';
												handleSearchChange('');
											}
										}}
									/>
								)}

								<Search color="#5D9BFD" size={16} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Separator className="bg-[#E9E9E9] my-2" />
			<div className="grid items-center grid-cols-12">
				<div className="col-span-2">
					<h2 className="font-bold text-black dynamic-text">Show</h2>
				</div>
				<div className="col-span-10">
					<Select
						onValueChange={(value) => {
							if (value === 'none') {
								setSectionTagFilter('');
							} else {
								setSectionTagFilter(value);
							}
						}}
						defaultValue="none"
					>
						<SelectTrigger className="h-12 rounded-xl dynamic-small border bg-white text-black px-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]">
							<SelectValue placeholder="Filer By" />
						</SelectTrigger>
						<SelectContent className="py-2 bg-white rounded-xl">
							<SelectItem
								className="text-left text-black dynamic-small py-2 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
								value="none"
							>
								All
							</SelectItem>
							<SelectItem
								className="text-left text-black dynamic-small py-2 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
								value="organization"
							>
								User Created
							</SelectItem>
							{sectionTagOptions.map((option) => (
								<SelectItem
									className="text-left text-black dynamic-small py-2 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
									key={option.value}
									value={option.value}
								>
									{option.label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
			</div>
		</div>
	);
};

export default AvailableSectionsFilter;
