import { FC } from 'react';
import Word from '@/components/ui/icons/new/Word';
import Pdf from '@/components/ui/icons/new/Pdf';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import styles from './ExportDownloadButtons.module.css';

interface ExportDownloadButtonsProps {
	handlePdfExport: () => void;
	handleWordExport: () => void;
}

const ExportDownloadButtons: FC<ExportDownloadButtonsProps> = ({
	handlePdfExport,
	handleWordExport,
}) => {
	return (
		<div className="flex">
			<h2 className="flex text-black dynamic-medium font-bold pr-2 items-center">
				Download
			</h2>
			<div className="border-blackSecondary border-l self-center mx-2 h-6"></div>
			<TextButton
				className="items-center gap-1 animated-hover-effect"
				onClick={handlePdfExport}
			>
				<Pdf className={styles.buttonIcon} />
				PDF
			</TextButton>
			<TextButton
				className="items-center gap-1 animated-hover-effect"
				onClick={handleWordExport}
			>
				<Word className={styles.buttonIcon} />
				Word
			</TextButton>
		</div>
	);
};

export default ExportDownloadButtons;
