import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { detachDocumentFromSection } from '@/lib/functions/apiCalls';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import {
	fetchProposalById,
	processStart,
	processSuccess,
} from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import customToast from '@/components/CustomToast/CustomToast';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

const DeleteDialog = () => {
	const isOpen = useAppSelector(
		(root: RootState) => root.page.dialog?.type === 'detachDocument'
	);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const { activeSection } = useActiveSection();
	const id = proposal?.id;
	const nodeId = activeSection.sectionId;

	const documentId = useAppSelector(
		(root: RootState) => root.page.dialog?.documentId
	);
	const dispatch = useAppDispatch();

	const handleDetach = async () => {
		if (documentId && nodeId) {
			dispatch(processStart());
			const response = await detachDocumentFromSection(
				Number(id),
				Number(nodeId),
				Number(documentId)
			);
			if (response.statusCode === 201) {
				customToast.success({ title: 'Document detached successfully' });
				dispatch(fetchProposalById(Number(id)));
				dispatch(processSuccess());
				dispatch(closeDialog());
			}
		}
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[450px] p-7 bg-white rounded-xl">
				<DialogHeader className="font-bold text-secondary dynamic-medium">
					Detach Document
				</DialogHeader>
				<p className="text-black">
					Are you sure want to detach the document from the section
				</p>
				<div className="flex items-center justify-end">
					<PrimaryButton onClick={handleDetach}>Yes</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteDialog;
