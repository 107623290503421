import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import FileAdd from '@/components/ui/icons/FileAdd';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { DiscoverOpportunity } from '@/lib/types/apiTypes';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { convertNumberToCurrency } from '@/utils';
import { format } from 'date-fns';
import { X } from 'lucide-react';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { useCallback, useEffect, useState } from 'react';
import { getMatchedOpportunity } from '@/lib/functions/apiCalls';
import customToast from '@/components/CustomToast/CustomToast';
import AiIcon from '@/components/ui/icons/AiIcon';

const OpportunityDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(
		(state: RootState) => state.page.dialog?.type === 'viewOpportunity'
	);

	const data: DiscoverOpportunity = useAppSelector(
		(state: RootState) => state.page.dialog?.data
	);

	const getFundingForText = (text: string) => {
		switch (text) {
			case 'profit':
				return 'For Profit Organizations';
			case 'non-profit':
				return 'Non Profit Organizations';
			default:
				return text;
		}
	};

	const [recommendations, setRecommendations] = useState('');
	const [recommendationsLoading, setRecommendationsLoading] = useState(true);
	const authToken = useAppSelector((state: RootState) => state.auth.token);

	const getStream = useCallback(
		() =>
			getMatchedOpportunity(
				authToken || '',
				{
					title: data?.title,
					summary: data?.summary,
					eligibility: data?.eligibility,
				},
				(data) => {
					setRecommendationsLoading(false);
					if (data.status === 'error') {
						customToast.error({ title: data.message });
					}
					setRecommendations((state) => state + data.data);
				}
			),
		[
			authToken,
			setRecommendations,
			data?.title,
			data?.summary,
			data?.eligibility,
		]
	);

	useEffect(() => {
		if (data?.title && data?.summary && data?.eligibility && data.isMatched) {
			getStream();
		}
	}, [data?.title, data?.summary, data?.eligibility]);

	return (
		<Dialog open={isOpen}>
			<DialogContent
				hideCloseBtn
				className="flex flex-col max-w-5xl max-h-[600px] p-6 rounded-2xl bg-white text-black"
				setModalOpen={() => dispatch(closeDialog())}
			>
				<DialogHeader>
					<div className="flex flex-col gap-4 pb-2 border-b">
						<div className="flex justify-between items-center">
							<DialogTitle className="font-bold dynamic-medium pr-10 leading-normal">
								{data?.title}
							</DialogTitle>
							<button
								onClick={() => {
									dispatch(closeDialog());
								}}
							>
								<X className="w-10 h-10 p-2 bg-white rounded-full stroke-primary " />
							</button>
						</div>
					</div>
				</DialogHeader>
				<div className="flex flex-col pr-4 gap-y-4 overflow-y-auto grow mt-4">
					<h3 className="text-secondary font-bold text-left dynamic-large">
						Deadline{' '}
						{!isNaN(new Date(data?.dueDate).getTime()) &&
							format(new Date(data.dueDate), 'MMM d, yyyy')}
					</h3>
					<h3 className="font-bold text-left dynamic-medium">
						Total Funding: {convertNumberToCurrency(data?.fundingAmount || 0)}
					</h3>
					<div className="flex flex-wrap gap-2">
						{data?.keywords?.map((_tag: any) => (
							<div
								key={_tag}
								className="bg-white border border-secondary text-secondary dynamic-small px-4 py-2 rounded-full uppercase"
							>
								{_tag}
							</div>
						))}
					</div>
					{/* Divider */}
					<Divider />
					<div>
						<div className="text-secondary font-bold capitalize dynamic-large">
							{data?.type}
						</div>
						<div className="flex flex-col mt-3">
							<div className="flex">
								<div className="font-bold min-w-20">Location(s):</div>
								<div className="capitalize">
									{data?.countriesList.join(', ') || 'Not Specified'}
								</div>
							</div>
							<div className="flex">
								<div className="font-bold min-w-20">For:</div>
								<div>{getFundingForText(data?.for || 'Not Specified')}</div>
							</div>
							<div className="flex">
								<div className="font-bold min-w-20">Funded by:</div>
								<div className="">{data?.funder || 'Not Specified'}</div>
							</div>
						</div>
					</div>

					{/* Divider */}
					<Divider />
					<div className="flex justify-between items-center">
						<div className="flex">
							<div className="font-bold min-w-20">Funding</div>
							<div className="">
								{convertNumberToCurrency(data?.fundingAmount || 0)}
							</div>
						</div>
						<div>{data?.solicitationLinks?.length > 0 && <FileAdd />}</div>
					</div>

					{/* Divider */}
					<Divider />
					<div className="flex flex-col gap-2">
						<div className="font-bold dynamic-medium">Summary</div>
						<div
							className="prose max-w-none prose-sm dynamic-text"
							dangerouslySetInnerHTML={{ __html: data?.summary }}
						/>
					</div>

					{/* Divider */}
					<Divider />
					<div className="flex flex-col gap-2">
						<div className="font-bold dynamic-medium">Eligibility</div>
						<div
							className="prose max-w-none prose-sm dynamic-text"
							dangerouslySetInnerHTML={{ __html: data?.eligibility }}
						/>
					</div>

					{data?.isMatched && (
						<>
							{/* Divider */}
							<Divider />
							<Accordion type="single" collapsible>
								<AccordionItem
									value="additional-doc"
									className="flex flex-col border-none bg-lightBackground px-4 rounded-xl"
								>
									<AccordionTrigger className="p-0 hover:no-underline text-primary">
										<div className="flex items-center gap-2">
											<AiIcon fill="var(--secondary)" />
											<h3 className="mb-0 dynamic-medium font-bold text-secondary">
												Recommendations
											</h3>
										</div>
									</AccordionTrigger>
									<AccordionContent>
										{recommendationsLoading ? (
											<div className="animate-pulse">
												<h3 className="mb-1 font-bold h-3 bg-slate-200 w-full rounded rounded-tremor-default"></h3>
												<h3 className="mb-1 h-3 bg-slate-200 w-10/12 rounded rounded-tremor-default">
													{' '}
												</h3>
												<h3 className="mb-1 h-3 bg-slate-200 w-8/12 rounded rounded-tremor-default"></h3>
												<h3 className="mb-0 font-bold h-3 bg-slate-200 w-full rounded rounded-tremor-default"></h3>
											</div>
										) : (
											<div
												className="prose max-w-none prose-sm dynamic-text pb-4"
												dangerouslySetInnerHTML={{ __html: recommendations }}
											/>
										)}
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</>
					)}

					<div className="flex justify-end">
						<a href={data?.rfpLink} target="_blank" rel="noopener noreferrer">
							<SecondaryButton>Open RFP Link</SecondaryButton>
						</a>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default OpportunityDialog;

function Divider() {
	return (
		<div className="h-[1px] border-b relative before:content-[' '] before:h-2 before:w-2 before:rounded-lg before:bg-gray-400 before:absolute before:top-1/2 before:-translate-y-1/2"></div>
	);
}
