import Document from '../ui/icons/new/Document';
import Brainstorm from '../ui/icons/new/Brainstorm';
import Ideas from '../ui/icons/new/Ideas';
import EmptyWidgetImage from '../ui/icons/widgets/EmptyWidgetImage';

const ToolBarInstruction = () => {
	return (
		<div className="dynamic-text text-black px-5 border-dashed border border-blackSecondary rounded-xl divide-y">
			<div className="flex flex-col gap-4 py-4">
				<EmptyWidgetImage className="self-center" />
				<span className="font-bold">You can also use the bar above to:</span>
				<div className="flex gap-2">
					<div className="flex h-10 w-8 rounded-lg items-center justify-center">
						<Ideas className="h-7 w-7" height={24} />
					</div>
					<div className="flex flex-col justify-center">
						<p>
							Add <span className="font-bold text-secondary">Ideas</span> that
							the AI will use when generating content. <br />
							These can be anything from data points to instructions.
						</p>
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-4 py-4">
				<div className="flex gap-2">
					<div className="flex h-10 w-8 min-w-8 rounded-lg items-center justify-center">
						<Brainstorm className="w-7" strokeWidth={1.2} height={22} />
					</div>
					<div className="flex flex-col justify-center">
						<p>
							Use the{' '}
							<span className="font-bold text-secondary">Brainstorm</span>{' '}
							feature to have the AI brainstorm ideas with you.
						</p>
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-4 py-4">
				<div className="flex gap-2">
					<div className="flex h-10 w-8 min-w-8 rounded-lg items-center justify-center">
						<Document className="h-6" strokeWidth={1.2} width={24} />
					</div>
					<div className="flex flex-col justify-center">
						<p>
							Use the{' '}
							<span className="font-bold text-secondary">Documents</span> button
							to add documents to this section.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ToolBarInstruction;
