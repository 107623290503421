import AddCitation from './AddCitation/AddCitation';
import ApproachDialog from './ApproachDialog/ApproachDialog';
import DeleteDialog from './DeleteDialog/DeleteDialog';
import DocumentDialog from './DocumentDialog/DocumentDialog';
import OpportunityDialog from './OpportunityDialog/OpportunityDialog';
import PDFDialog from './PDFDialog/PDFDialog';
import SolicitationDialog from './SolicitationDialog/SolicitationDialog';
import TemplateDialog from './TemplateDialog/TemplateDialog';
import ChangePasswordDialog from './ChangePasswordDialog/ChangePasswordDialog';
import AddUserDialog from './AddUserDialog/AddUserDialog';
import ChangeToneDialog from './ChangeToneDialog/ChangeToneDialog';
import ChangeTemplateDialog from './ConfirmTemplateDialog/ConfirmTemplateDialog';
import SuggestionBackgroundDialog from './SuggestionBackgroundDialog/SuggestionBackgroundDialog';
import HistoryRestoreDialog from './HistoryRestoreDialog/HistoryRestoreDialog';
import VisualDialog from './VisualDialog/VisualDialog';
import ShowTemplateDialog from './TemplateBuilder/ShowTemplateDialog';
import SectionDialog from './TemplateBuilder/SectionDialog';
import ConciseDialog from './MagicDialogs/ConciseDialog';
import EnhanceDialog from './MagicDialogs/EnhanceDialog';
import ExpandDialog from './MagicDialogs/ExpandDialog';
import SolicitationExistsDialog from './SolicitationExistsDialog/SolicitationExistsDialog';
import TemplateTagFilterDialog from './TemplateBuilder/TemplateTagFilterDialog';
import DeleteDocument from './DeleteDocument/DeleteDocument';
import ChangeProposalNameDialog from './ChangeProposalNameDialog/ChangeProposalNameDialog';
import NotificationDialog from './NotificationDialog/NotificationDialog';
import SectionInfoDialog from './SectionInfoDialog/SectionInfoDialog';
import SectionWordCountDialog from './SectionWordCountDialog/SectionWordCountDialog';
import RenameSectionDialog from './RenameSectionDialog/RenameSectionDialog';
import DeleteMatrixRule from './ComplianceMatrix/DeleteMatrixRule/DeleteMatrixRule';
import EditMatrixRule from './ComplianceMatrix/EditMatrixRule/EditMatrixRule';
import AddNewMatrixRule from './ComplianceMatrix/AddNewMatrixRule/AddNewMatrixRule';
import ComplianceMatrixEditWarning from './ComplianceMatrix/ComplianceMatrixEditWarning/ComplianceMatrixEditWarning';
import ComplianceRuleDetails from './ComplianceMatrix/ComplianceRuleDetails/ComplianceRuleDetails';
import OrganizationProfile from './OrganizationProfileDialog/OrganizationProfileDialog';
import ChangePhone from './ChangePhoneDialog/ChangePhoneDialog';
import SolicitationBriefDialog from './SolicitationBriefDialog/SolicitationBriefDialog';
import UploadDocumentDialog from './UploadDocumentDialog/UploadDocumentDialog';
import ChangeDocumentNameDialog from './ChangeDocumentNameDialog/ChangeDocumentNameDialog';
import TrialTermsDialog from './TrialTermsDialog/TrialTermsDialog';
import TrialEndDialog from './TrialEndDialog/TrialEndDialog';
import MoveIdeaDialog from './MoveIdeaDialog/MoveIdeaDialog';
import MultiStepUnsolicitedDialog from './MultiStepUnsolicitedDialog/MultiStepUnsolicitedDialog';
import NameEditorDialog from './NameEditorDialog/NameEditorDialog';
import MoveDocumentsDialog from './MoveDocumentsDialog/MoveDocumentsDialog';
import ChangeDocumentCategoryDialog from './ChangeDocumentCategoryDialog/ChangeDocumentCategoryDialog';
import UploadDocumentsDialog from './UploadDocumentsDialog/UploadDocumentsDialog';
import LogicalFrameDialog from './LogicalFrameDialog/LogicalFrameDialog';
import ViewTableDialog from './ViewTableDialog/ViewTableDialog';

const Dialogs = () => {
	return (
		<>
			<SolicitationBriefDialog />
			<PDFDialog />
			<DeleteDialog />
			<DocumentDialog />
			<UploadDocumentDialog />
			<TemplateDialog />
			<ApproachDialog />
			<ChangePasswordDialog />
			<SolicitationDialog />
			<OpportunityDialog />
			<AddUserDialog />
			<ChangeToneDialog />
			<ChangeTemplateDialog />
			<AddCitation />
			<SuggestionBackgroundDialog />
			<HistoryRestoreDialog />
			<VisualDialog />
			<ShowTemplateDialog />
			<SectionDialog />
			<ConciseDialog />
			<EnhanceDialog />
			<ExpandDialog />
			<SolicitationExistsDialog />
			<TemplateTagFilterDialog />
			<DeleteDocument />
			<ChangeProposalNameDialog />
			<NotificationDialog />
			<SectionInfoDialog />
			<SectionWordCountDialog />
			<RenameSectionDialog />
			<DeleteMatrixRule />
			<EditMatrixRule />
			<AddNewMatrixRule />
			<ComplianceMatrixEditWarning />
			<ComplianceRuleDetails />
			<OrganizationProfile />
			<ChangePhone />
			<ChangeDocumentNameDialog />
			<TrialTermsDialog />
			<TrialEndDialog />
			<MoveIdeaDialog />
			<MultiStepUnsolicitedDialog />
			<NameEditorDialog />
			<MoveDocumentsDialog />
			<ChangeDocumentCategoryDialog />
			<UploadDocumentsDialog />
			<LogicalFrameDialog />
			<ViewTableDialog />
		</>
	);
};

export default Dialogs;
