import { FC } from 'react';
import Ready from '../ui/icons/Ready';
import Processing from '../ui/icons/Processing';
import Error from '../ui/icons/Error';
import EditCircled from '../ui/icons/EditCircled';

interface SolicitationStatusProps {
	type: string;
}

const SolicitationStatus: FC<SolicitationStatusProps> = ({ type }) => {
	switch (type) {
		case 'ready':
			return (
				<div className="flex items-center gap-3">
					<Ready />
					<p className="text-base font-bold text-lightGreen">Ready</p>
				</div>
			);
		case 'processing':
			return (
				<div className="flex items-center gap-3">
					<Processing className="processing-animation" />
					<p className="text-base font-medium text-black">Processing</p>
				</div>
			);
		case 'errored':
			return (
				<div className="flex items-center gap-3">
					<Error />
					<p className="text-base font-medium text-lightRed">Error</p>
				</div>
			);
		case 'being-developed':
			return (
				<div className="flex items-center gap-3">
					<EditCircled />
					<p className="text-base font-medium text-primaryDark">
						Being Developed
					</p>
				</div>
			);
		default:
			return null;
	}
};

export default SolicitationStatus;
