const DocumentUploadIcon = ({ className = '', fill = '#5D9BFD' }) => {
	return (
		<svg
			width="28"
			height="29"
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<mask
				id="mask0_689_4647"
				maskUnits="userSpaceOnUse"
				x="3"
				y="3"
				width="22"
				height="22"
			>
				<path
					d="M24.9219 3.94629H3.92188V24.0668H24.9219V3.94629Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_689_4647)">
				<path
					d="M16.9067 24.0668H7.36751C6.45408 24.0655 5.57843 23.7024 4.93253 23.0571C4.28664 22.4118 3.92319 21.537 3.92188 20.6245L3.92188 7.38794C3.92319 6.47544 4.28666 5.6007 4.93258 4.95553C5.5785 4.31036 6.45414 3.94742 7.36751 3.94629L16.9067 3.94629C17.8201 3.94742 18.6956 4.31038 19.3414 4.95557C19.9872 5.60076 20.3505 6.4755 20.3517 7.38794V12.422C20.3517 12.6754 20.2509 12.9185 20.0715 13.0977C19.8921 13.2769 19.6489 13.3776 19.3952 13.3776C19.1415 13.3776 18.8982 13.2769 18.7188 13.0977C18.5395 12.9185 18.4387 12.6754 18.4387 12.422V7.38794C18.4381 6.9822 18.2765 6.59324 17.9894 6.30634C17.7022 6.01944 17.3129 5.85801 16.9067 5.85745H7.36751C6.96132 5.85801 6.57192 6.01942 6.28463 6.3063C5.99735 6.59318 5.8356 6.98214 5.83485 7.38794V20.6223C5.83542 21.0282 5.99707 21.4174 6.28438 21.7044C6.57169 21.9915 6.9612 22.153 7.36751 22.1535H16.9067C17.313 22.153 17.7024 21.9914 17.9896 21.7044C18.2768 21.4173 18.4383 21.0282 18.4387 20.6223V19.3771C18.4387 19.1237 18.5395 18.8807 18.7188 18.7015C18.8982 18.5222 19.1415 18.4216 19.3952 18.4216C19.6489 18.4216 19.8921 18.5222 20.0715 18.7015C20.2509 18.8807 20.3517 19.1237 20.3517 19.3771V20.6245C20.3505 21.537 19.9873 22.4118 19.3415 23.0571C18.6957 23.7024 17.8201 24.0655 16.9067 24.0668Z"
					fill={fill}
				/>
				<path
					d="M23.9631 16.6024H12.7815C12.5929 16.6027 12.4083 16.5472 12.2512 16.443C12.094 16.3387 11.9713 16.1903 11.8984 16.0164C11.8255 15.8426 11.8058 15.6511 11.8416 15.466C11.8775 15.281 11.9674 15.1107 12.1 14.9766L15.2714 11.7541C15.3589 11.6624 15.4639 11.589 15.58 11.5382C15.6962 11.4874 15.8214 11.4602 15.9482 11.4581C16.075 11.4561 16.201 11.4793 16.3188 11.5263C16.4365 11.5734 16.5437 11.6434 16.6342 11.7322C16.7246 11.8211 16.7965 11.927 16.8456 12.0438C16.8946 12.1607 16.92 12.2861 16.9201 12.4128C16.9202 12.5395 16.895 12.665 16.8461 12.7819C16.7972 12.8989 16.7256 13.0049 16.6353 13.0939L15.0627 14.6913H23.9653C24.2189 14.6913 24.4622 14.7919 24.6416 14.9712C24.821 15.1504 24.9217 15.3934 24.9217 15.6468C24.9217 15.9003 24.821 16.1433 24.6416 16.3225C24.4622 16.5017 24.2189 16.6024 23.9653 16.6024"
					fill={fill}
				/>
				<path
					d="M15.9528 19.7169C15.7017 19.7171 15.4606 19.6184 15.2819 19.4423L12.1126 16.3272C11.944 16.1473 11.8516 15.9093 11.8546 15.6629C11.8575 15.4165 11.9556 15.1807 12.1284 15.0049C12.3012 14.829 12.5353 14.7267 12.7819 14.7192C13.0284 14.7117 13.2683 14.7996 13.4515 14.9647L16.6237 18.0804C16.7589 18.2135 16.8515 18.3837 16.8896 18.5694C16.9277 18.7552 16.9097 18.9481 16.8377 19.1235C16.7658 19.299 16.6432 19.4491 16.4856 19.5548C16.3281 19.6605 16.1426 19.7169 15.9528 19.7169Z"
					fill={fill}
				/>
				<path
					d="M8.65852 9.69399H8.64427L4.86248 9.63992C4.6088 9.63803 4.36627 9.53554 4.18823 9.355C4.01018 9.17446 3.91122 8.93066 3.91311 8.67722C3.915 8.42379 4.01759 8.18148 4.1983 8.00361C4.37901 7.82574 4.62305 7.72687 4.87672 7.72876H4.89168L7.70203 7.77216V4.90329C7.70203 4.64948 7.80295 4.40606 7.9826 4.22659C8.16224 4.04712 8.40589 3.94629 8.65994 3.94629C8.914 3.94629 9.15764 4.04712 9.33729 4.22659C9.51693 4.40606 9.61785 4.64948 9.61785 4.90329V8.73913C9.61776 8.8648 9.59286 8.98921 9.54457 9.10525C9.49628 9.22129 9.42556 9.32668 9.33645 9.41537C9.24734 9.50407 9.14159 9.57433 9.02526 9.62214C8.90893 9.66995 8.7843 9.69437 8.65852 9.69399Z"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default DocumentUploadIcon;
