import TopHeader from '@/components/TopHeader/TopHeader';
import { stopStreaming } from '@/redux/slices/pageSlice';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './WriteModeWrapper.module.css';
import TopPanel from '@/components/TopPanel/TopPanel';
import { Proposal, Section } from '@/lib/types/apiTypes';
import { cn } from '@/lib/utils';
import MainCard from '@/components/WriteMode/MainCard/MainCard';
import CitationWrapper from '../CitationWrapper/CitationWrapper';
import { RootState } from '@/redux/store/store';
import { setWriterLoader } from '@/redux/slices/proposalSlice';

interface WriteModeWrapperProps {}

const WriteModeWrapper: FC<WriteModeWrapperProps> = () => {
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const parentRef = useRef<HTMLDivElement>(null);
	const scrollParentRef = useRef<HTMLDivElement>(null);

	const dispatch = useDispatch();

	const sections = useMemo(() => {
		if (!proposal) return [];
		return proposal.sections.filter((sec) => sec.type !== 'logical-framework');
	}, [proposal]);

	useEffect(() => {
		dispatch(stopStreaming());
		dispatch(setWriterLoader(false));
	}, [dispatch]);

	return (
		<div className="col-span-12 2xl:col-span-9">
			<TopHeader
				rfpLink={proposal?.rfpLink || ''}
				rfpName={proposal?.rfpTitle || ''}
				proposalName={proposal?.name || ''}
			/>
			<div className="px-6 2xl:pr-0 2xl:pl-5">
				<TopPanel
					sections={proposal?.sections as Section[]}
					scrollParentRef={scrollParentRef}
				/>
				<div
					ref={scrollParentRef}
					id="writeCardWrapper"
					className="relative pb-32 mx-auto h-[calc(100vh-100px)] overflow-hidden overflow-y-scroll w-[1280px] max-w-full -top-3 scroll-smooth"
				>
					<div
						ref={parentRef}
						className={cn(
							styles.card,
							' relative flex flex-col pb-2 scroll-smooth '
						)}
					>
						{sections &&
							sections
								.filter((sec) => sec.type !== 'logical-framework')
								.map((sec, index) => (
									<MainCard
										key={sec.id}
										section={sec}
										topLevelHeadingNumber={index + 1}
										parentRef={parentRef}
										scrollParentRef={scrollParentRef}
										topRoundedNone={index === 0}
									/>
								))}
					</div>
					<CitationWrapper proposal={proposal as Proposal} />
				</div>
			</div>
		</div>
	);
};

export default WriteModeWrapper;
