import { Input } from '../ui/input';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
	ApiResponse,
	ErrorResponse,
	LoginDomainPayload,
	LoginSSOResponse,
} from '@/lib/types/apiTypes';
import {
	handleApiError,
	handleGeneralError,
	handleUnexpectedError,
} from '@/lib/functions/funcUtils';
import { Label } from '../ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { loginWithSSO } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import { FC } from 'react';

const formSchema = z.object({
	domain: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
});

interface LoginSSOFormProps {
	domainName?: string;
}

const LoginSSOForm: FC<LoginSSOFormProps> = ({ domainName }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		values: {
			domain: domainName ?? '',
		},
	});

	const onSubmit = async (data: LoginDomainPayload) => {
		try {
			dispatch(processStart());
			const response: ApiResponse = await loginWithSSO(data);
			if (response.statusCode >= 400 && response.statusCode <= 599) {
				throw response; // Throw the response if status is 400s or 500s
			}
			const loginResponseData = response.data as LoginSSOResponse;
			dispatch(processSuccess());
			window.location.href = loginResponseData.ssoUrl;
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			} else {
				handleUnexpectedError();
			}
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			form.handleSubmit(onSubmit)();
		}
	};

	return (
		<Form {...form}>
			<form
				className="flex flex-col w-full gap-5 min-w-[500px]"
				onSubmit={form.handleSubmit(onSubmit)}
			>
				<FormField
					control={form.control}
					name="domain"
					render={({ field }) => {
						return (
							<FormItem className="col-span-1">
								<Label>Enter your Company Domain</Label>
								<div className="relative flex">
									<FormControl>
										<Input
											onKeyDown={handleKeyPress}
											disabled={!!domainName}
											placeholder="Enter domain"
											className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-36"
											{...field}
										></Input>
									</FormControl>
									<span className="absolute flex font-bold text-black right-4 place-self-center font-base">
										.grantassistant.ai
									</span>
								</div>
								<FormMessage />
							</FormItem>
						);
					}}
				/>
				<div className="flex justify-center gap-5">
					{!domainName && (
						<SecondaryButton tabIndex={2} onClick={() => navigate('')}>
							BACK
						</SecondaryButton>
					)}
					<PrimaryButton
						tabIndex={1}
						type="submit"
						title="CONTINUE"
						className="w-[242px]"
					>
						CONTINUE
					</PrimaryButton>
				</div>
				<div className="flex justify-center">
					<a className="font-bold text-black opacity-50 cursor-lock">
						I don’t know the company domain
					</a>
				</div>
			</form>
		</Form>
	);
};

export default LoginSSOForm;
