const Documents = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24"
			width="20"
			viewBox="0 0 26 33"
			fill="none"
		>
			<g clipPath="url(#clip0_438_648)">
				<path
					d="M21.5887 33H5.41201C3.97702 32.9988 2.60114 32.4333 1.58644 31.4275C0.571749 30.4217 0.00117799 29.0579 0 27.6355L0 5.36454C0 3.94178 0.570192 2.57728 1.58514 1.57124C2.60009 0.565191 3.97666 0 5.41201 0L21.5887 0C23.0237 0.00116766 24.3996 0.566734 25.4143 1.57253C26.429 2.57832 26.9996 3.94213 27.0007 5.36454V27.6369C26.9996 29.0593 26.429 30.4232 25.4143 31.4289C24.3996 32.4347 23.0237 33.0003 21.5887 33.0015M5.41201 2.35672C4.60765 2.35808 3.83663 2.67541 3.26786 3.23919C2.69909 3.80298 2.37895 4.56723 2.37758 5.36454V27.6369C2.37915 28.4341 2.69937 29.1982 3.26812 29.7618C3.83687 30.3254 4.60778 30.6427 5.41201 30.644H21.5887C22.3926 30.6423 23.163 30.325 23.7314 29.7615C24.2998 29.1981 24.6199 28.4345 24.6217 27.6377V5.36454C24.6203 4.56749 24.3004 3.80345 23.7319 3.23971C23.1635 2.67597 22.3928 2.35847 21.5887 2.35672H5.41201Z"
					fill="white"
				/>
				<path
					d="M19.3964 8.81107C19.2406 8.81107 19.0864 8.78066 18.9425 8.72157C18.7985 8.66248 18.6678 8.57587 18.5576 8.46669C18.4475 8.35751 18.3601 8.22789 18.3005 8.08524C18.2409 7.94259 18.2102 7.78969 18.2102 7.63528V1.17579C18.2102 0.86327 18.3355 0.563549 18.5584 0.342563C18.7813 0.121577 19.0837 -0.00257111 19.399 -0.00257111C19.7143 -0.00257111 20.0167 0.121577 20.2396 0.342563C20.4625 0.563549 20.5878 0.86327 20.5878 1.17579V6.43671L25.7952 6.35808H25.8137C25.9698 6.35688 26.1247 6.38616 26.2693 6.44426C26.414 6.50237 26.5458 6.58815 26.657 6.69672C26.7683 6.80529 26.8569 6.93451 26.9177 7.07702C26.9786 7.21952 27.0105 7.37251 27.0118 7.52726C27.013 7.682 26.9834 7.83547 26.9248 7.9789C26.8662 8.12232 26.7797 8.2529 26.6701 8.36318C26.5606 8.47345 26.4302 8.56126 26.2865 8.62159C26.1427 8.68193 25.9884 8.7136 25.8322 8.71481L19.4112 8.81107H19.3964Z"
					fill="white"
				/>
				<path
					d="M14.6598 13.7413H6.64484C6.32956 13.7413 6.02718 13.6172 5.80424 13.3962C5.5813 13.1752 5.45605 12.8755 5.45605 12.5629C5.45605 12.2504 5.5813 11.9507 5.80424 11.7297C6.02718 11.5087 6.32956 11.3846 6.64484 11.3846H14.6598C14.9751 11.3846 15.2775 11.5087 15.5004 11.7297C15.7234 11.9507 15.8486 12.2504 15.8486 12.5629C15.8486 12.8755 15.7234 13.1752 15.5004 13.3962C15.2775 13.6172 14.9751 13.7413 14.6598 13.7413Z"
					fill="white"
				/>
				<path
					d="M20.1541 20.1251H6.64631C6.49019 20.1251 6.33561 20.0946 6.19138 20.0354C6.04715 19.9762 5.9161 19.8894 5.80571 19.78C5.69532 19.6706 5.60775 19.5407 5.54801 19.3977C5.48827 19.2547 5.45752 19.1015 5.45752 18.9467C5.45752 18.792 5.48827 18.6388 5.54801 18.4958C5.60775 18.3528 5.69532 18.2229 5.80571 18.1135C5.9161 18.0041 6.04715 17.9173 6.19138 17.8581C6.33561 17.7989 6.49019 17.7684 6.64631 17.7684H20.1541C20.3102 17.7684 20.4648 17.7989 20.609 17.8581C20.7533 17.9173 20.8843 18.0041 20.9947 18.1135C21.1051 18.2229 21.1926 18.3528 21.2524 18.4958C21.3121 18.6388 21.3429 18.792 21.3429 18.9467C21.3429 19.1015 21.3121 19.2547 21.2524 19.3977C21.1926 19.5407 21.1051 19.6706 20.9947 19.78C20.8843 19.8894 20.7533 19.9762 20.609 20.0354C20.4648 20.0946 20.3102 20.1251 20.1541 20.1251Z"
					fill="white"
				/>
				<path
					d="M20.1541 26.506H6.64631C6.33102 26.506 6.02865 26.3818 5.80571 26.1608C5.58277 25.9399 5.45752 25.6401 5.45752 25.3276C5.45752 25.0151 5.58277 24.7154 5.80571 24.4944C6.02865 24.2734 6.33102 24.1492 6.64631 24.1492H20.1541C20.4694 24.1492 20.7718 24.2734 20.9947 24.4944C21.2176 24.7154 21.3429 25.0151 21.3429 25.3276C21.3429 25.6401 21.2176 25.9399 20.9947 26.1608C20.7718 26.3818 20.4694 26.506 20.1541 26.506Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_438_648">
					<rect width="27" height="33" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Documents;
