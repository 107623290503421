import {
	getToneOfVoiceFromProposal,
	updateOrganizationalToneOfVoice,
} from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	formatToneOfVoicePayload,
	handleFileRejections,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	ToneOfVoicePayload,
	ToneOfVoice,
	Organization,
	ApiResponse,
	User,
	ToneOfVoiceFormData,
} from '@/lib/types/apiTypes';
import ToneOfVoiceEdit from '@/components/ToneOfVoiceEdit/ToneOfVoiceEdit';
import { FC, useCallback, useEffect, useState } from 'react';
import styles from './ToneOfVoiceSetupForm.module.css';
import { cn } from '@/lib/utils';
import FileUpload from '@/components/FileUpload/FileUpload';
import DocumentUpload from '@/components/ui/icons/new/DocumentUpload';
import { updateProfile } from '@/redux/slices/authSlice';
import { useNavigate } from 'react-router';

interface ToneOfVoiceSetupFormProps {
	organizationId: number;
	organization: Organization | null;
	user: User | null;
	setIsLoading: (isLoading: boolean) => void;
	onCancel: () => void;
}

const ToneOfVoiceSetupForm: FC<ToneOfVoiceSetupFormProps> = ({
	organizationId,
	organization,
	user,
	setIsLoading,
	onCancel,
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const organizationToneOfVoice = (organization as any)?.settings?.toneOfVoice;
	const [uploadedDocumentTone, setUploadedDocumentTone] = useState<
		ToneOfVoice | undefined
	>();

	const [toneOfVoiceData, setToneOfVoiceData] = useState<ToneOfVoice>();

	useEffect(() => {
		if (organizationToneOfVoice) {
			setToneOfVoiceData(organizationToneOfVoice);
		}
	}, [organizationToneOfVoice]);

	const onSubmit = async (formData: ToneOfVoiceFormData) => {
		try {
			if (organizationId) {
				setIsLoading(true);
				const toneOfVoicePayload: ToneOfVoicePayload =
					formatToneOfVoicePayload(formData);
				const response = await updateOrganizationalToneOfVoice(organizationId, {
					...toneOfVoicePayload,
					initialSetupStep: null,
				});
				handleResponse(response);
				const updatedTone: ToneOfVoice = response.data.settings
					.toneOfVoice as ToneOfVoice;
				setToneOfVoiceData(updatedTone);
				setIsLoading(false);

				if (user) {
					dispatch(
						updateProfile({
							user: {
								...user,
								organization: {
									...user.organization,
									initialSetupStep: null,
								},
							},
						})
					);
					navigate('/');
				}
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleFiles = useCallback(
		async (files: File[]) => {
			try {
				if (!files.length) return;
				setIsLoading(true);
				const response: ApiResponse = await getToneOfVoiceFromProposal(
					files[0]
				);
				const documentToneOfVoice = (response.data as any).autoFetchResults
					.toneOfVoice as ToneOfVoice;
				handleResponse(response);
				setUploadedDocumentTone(documentToneOfVoice);
				setToneOfVoiceData(documentToneOfVoice);
				setIsLoading(false);
			} catch (error: unknown) {
				ErrorHandle(dispatch, error);
				setIsLoading(false);
			}
		},
		[dispatch]
	);

	return (
		<div className="flex flex-col max-w-[1500px]">
			<div className="flex flex-col items-center justify-center flex-1 gap-2 pb-6">
				<FileUpload
					type="custom"
					className="w-full cursor-pointer"
					customLayout={
						<div
							className={cn(
								'w-full flex p-4 2xl:p-6 rounded-2xl border border-[#D3DDE2] gap-3 2xl:gap-4'
							)}
						>
							<DocumentUpload className="w-12 h-12" />
							<div className="flex flex-col gap-1">
								<h4 className="font-bold text-primary dynamic-medium">
									Click to import a past proposal to set your organization’s
									tone of voice and writing style (optional)
								</h4>
								<p className="text-black">
									We will retrieve and set a tone of voice based on that
									proposal
								</p>
							</div>
						</div>
					}
					onDropAccepted={handleFiles}
					onDropRejected={(fileRejections) =>
						handleFileRejections({ fileRejections: fileRejections })
					}
				/>
			</div>
			<div className={cn(styles.separator, 'flex gap-4 pb-6')}>
				<p className={cn(styles.separator_text, 'text-black px-4')}>or</p>
			</div>
			<h3 className="font-bold text-black dynamic-large">
				Change tone of voice
			</h3>
			<div className="max-h-[700px] ">
				<div className="h-full pr-4 overflow-y-scroll light-scroll2">
					<ToneOfVoiceEdit
						onSubmit={onSubmit}
						setIsLoading={setIsLoading}
						type="organizationSetup"
						toneOfVoice={toneOfVoiceData}
						onCancel={onCancel}
						uploadedDocumentTone={uploadedDocumentTone}
						initialSetup={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default ToneOfVoiceSetupForm;
