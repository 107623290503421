import { FC, useEffect, useRef, useState } from 'react';
// import { Badge } from '../ui/badge';
import { Trash2 } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
	fetchProposalById,
	processStart,
	processSuccess,
} from '@/redux/slices/proposalSlice';
import { detachFormativeDocument } from '@/lib/functions/apiCalls';
import { ErrorHandle } from '@/lib/functions/funcUtils';
import { Document } from '@/lib/types/apiTypes';
import styles from './EssentialDocumentListItem.module.css';
import TextButton from '../ui/shared/Button/TextButton/TextButton';

interface EssentialDocumentListitemProps {
	document: Document;
}

const EssentialDocumentListitem: FC<EssentialDocumentListitemProps> = ({
	document,
}) => {
	const dispatch = useAppDispatch();
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const textRef = useRef<HTMLParagraphElement>(null);
	const [showReadMore, setShowReadMore] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		if (textRef.current) {
			const height = textRef.current.offsetHeight;
			if (height > 150) {
				setShowReadMore(true);
			} else {
				setShowReadMore(false);
			}
		}
	}, [document.summary, textRef]);

	const toggleText = () => {
		setIsExpanded(!isExpanded);
	};
	const handleDetachDocument = async (documentId: number) => {
		try {
			if (proposal?.id) {
				dispatch(processStart());
				const response = await detachFormativeDocument(proposal.id, documentId);
				if (response.statusCode >= 400 && response.statusCode <= 599) {
					throw response; // Throw the response if status is 400s or 500s
				}
				if (response.statusCode === 201) {
					dispatch(processSuccess());
					dispatch(fetchProposalById(proposal.id));
				}
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};
	return (
		<li className="first:pt-0 first:pb-5 py-5 border-b border-[#C6DBFB] group relative">
			<div>
				<h5 className="font-bold text-primary dynamic-medium ">
					{document.name}
				</h5>
				<Trash2
					onClick={() => handleDetachDocument(document.id)}
					size={18}
					className="absolute invisible text-red-400 right-3 group-first:top-0 top-3 group-hover:visible cursor-pointer"
				/>
			</div>
			<div
				className={`${styles.textContainer}  ${
					isExpanded ? styles.expanded : showReadMore ? styles.collapsed : ''
				}`}
			>
				<p className="py-2 text-black" ref={textRef}>
					{document.summary}
				</p>
			</div>
			{showReadMore && (
				<TextButton
					onClick={toggleText}
					className="h-fit 2xl:h-fit w-fit p-0 pt-1 font-bold text-start read-more-button text-primary"
				>
					{isExpanded ? <span className="font-normal">Less</span> : 'Read More'}
				</TextButton>
			)}
			<p className="mt-2 text-black">
				<span className="mr-2 font-bold">Category:</span>{' '}
				{document?.attachment?.attachedAs || document.category}
			</p>

			{/* <div className="flex items-center gap-2 mt-2 text-black">
				<p className="font-bold ">Category:</p>
				<p className="flex items-center gap-2">
					<Badge className="font-medium bg-white border-secondary text-secondary">
						{document.category}
					</Badge>
				</p>
			</div> */}
		</li>
	);
};

export default EssentialDocumentListitem;
