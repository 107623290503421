import { Message } from '@/lib/types/apiTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface enhanceState {
	queries: Record<number, Message[]>;
}

const initialState: enhanceState = {
	queries: {},
};

const enhanceSlice = createSlice({
	name: 'enhance',
	initialState,
	reducers: {
		clearEnhanceSlice(state) {
			state.queries = {};
		},
		addEnhanceQuery(
			state,
			action: PayloadAction<{ content: string; sectionId: number }>
		) {
			const { content, sectionId } = action.payload;
			const newMessage: Message = {
				content,
				role: 'user',
				sectionId,
			};

			// Ensure that `state.queries` is initialized as an empty object if undefined
			if (!state.queries) {
				state.queries = {};
			}

			// Ensure that the section array is initialized
			if (!state.queries[sectionId]) {
				// Initialize the array for the section ID immutably
				state.queries = {
					...state.queries,
					[sectionId]: [],
				};
			}
			state.queries[sectionId].push(newMessage);
		},
		addAIResponse(
			state,
			action: PayloadAction<{ content: string; sectionId: number }>
		) {
			const { content, sectionId } = action.payload;
			const newMessage: Message = {
				content,
				role: 'assistant',
				sectionId,
			};
			if (!state.queries[sectionId]) {
				state.queries[sectionId] = [];
			}
			state.queries[sectionId].push(newMessage);
		},
		updateLastAIResponse(
			state,
			action: PayloadAction<{ content: string; sectionId: number }>
		) {
			const { content, sectionId } = action.payload;
			if (state.queries[sectionId]) {
				const lastIndex = state.queries[sectionId].length - 1;
				const lastMessage = state.queries[sectionId][lastIndex];

				// Only update if the last message is from the assistant
				if (lastMessage.role === 'assistant') {
					// Update the message content immutably
					state.queries[sectionId] = [
						...state.queries[sectionId].slice(0, lastIndex),
						{
							...lastMessage,
							content,
						},
					];
				}
			}
		},
		resetQuery(state, action: PayloadAction<number>) {
			const sectionId = action.payload;
			state.queries[sectionId] = [];
		},
	},
});

export const {
	addEnhanceQuery,
	addAIResponse,
	resetQuery,
	clearEnhanceSlice,
	updateLastAIResponse,
} = enhanceSlice.actions;
export default enhanceSlice.reducer;
