import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { InviteUserPayload } from '@/lib/types/apiTypes';
import { inviteUser } from '@/lib/functions/apiCalls';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import useUser from '@/lib/hooks/useUser';
import DialogLoader from '@/components/Loader/DialogLoader/DailogLoader';

const formSchema = z.object({
	email: z.string().email({
		message: 'Please enter correct email address.',
	}),
	roleId: z.number(),
});

const AddUserDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'addUser'
	);
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const queryClient = useQueryClient();

	const form = useForm<InviteUserPayload>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
		},
	});
	const user = useUser();

	const onSubmit = async (inviteUserPayload: InviteUserPayload) => {
		try {
			setIsLoading(true);
			const response = await inviteUser(inviteUserPayload);
			handleResponse(
				response,
				`Your invitation has been sent to ${inviteUserPayload.email}.`
			);
			if (!user?.organization.isSsoEnabled) {
				queryClient.invalidateQueries('pendingRequests');
			} else {
				queryClient.invalidateQueries('users');
			}
			queryClient.invalidateQueries('usersStats');
			setIsLoading(false);
			dispatch(closeDialog());
		} catch (error: unknown) {
			if ((error as any)?.response?.data) {
				ErrorHandle(dispatch, (error as any)?.response?.data);
			} else {
				ErrorHandle(dispatch, error);
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		form.reset();
	}, [form, isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[450px] bg-white overflow-hidden">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="flex flex-col w-full h-full gap-6"
					>
						<h3 className="font-bold text-secondary dynamic-large">
							Invite New User
						</h3>
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Email</Label>
										<FormControl>
											<Input
												className="bg-white border-[#D3DDE2] h-12 p-4 text-black rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
												placeholder="Enter user email address"
												{...field}
											></Input>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
						<FormField
							control={form.control}
							name="roleId"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Role</Label>
										<FormControl>
											<Select
												onValueChange={(value) =>
													field.onChange(parseInt(value))
												}
											>
												<SelectTrigger className="h-12 rounded-xl border border-[#D3DDE2] bg-white text-black p-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]">
													<SelectValue placeholder="Select Role" />
												</SelectTrigger>
												<SelectContent className="py-2 bg-white rounded-2xl">
													<SelectItem
														className="text-black dynamic-small cursor-pointer py-2 rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
														value="2"
													>
														Admin
													</SelectItem>
													<SelectItem
														className="text-black dynamic-small cursor-pointer py-2 rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
														value="3"
													>
														Member
													</SelectItem>
													<SelectItem
														className="text-black dynamic-small cursor-pointer py-2 rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
														value="4"
													>
														Guest
													</SelectItem>
												</SelectContent>
											</Select>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
						<div className="flex justify-start w-full gap-4 mx-auto">
							<PrimaryButton type="submit">INVITE USER</PrimaryButton>
						</div>
					</form>
				</Form>
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default AddUserDialog;
