const AccordionOpen = ({ className = '' }) => {
	return (
		<svg
			className={className}
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" width="20" height="20" rx="4" fill="#E3EEFF" />
			<path d="M10.5 13L6.5 9H14.5L10.5 13Z" fill="#5D9BFD" />
		</svg>
	);
};

export default AccordionOpen;
