const DocumentUploadUpward = ({
	stroke = '#6D7D86',
	fill = 'white',
	...props
}) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="33"
			viewBox="0 0 24 33"
			fill="none"
		>
			<path
				d="M17.2542 4.5L4.96312 4.51684C3.87811 4.51684 2.9976 5.39958 3 6.48437L3.02406 26.5373C3.02406 27.6221 3.90698 28.5024 4.99198 28.5L19.0369 28.4832C20.1219 28.4832 21.0024 27.6004 21 26.5156L20.9759 7.91311L17.2542 4.5Z"
				fill={fill}
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7 25.5H17"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 15.5L8 18.5"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M16 18.8429L12 15.5V23.5" fill={fill} />
			<path
				d="M16 18.8429L12 15.5V23.5"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17 4.5V6.18353C17 6.91148 17.7447 7.5 18.66 7.5H21L17 4.5Z"
				fill={fill}
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default DocumentUploadUpward;
