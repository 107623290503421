const Send = ({ className = 'h-6' }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
		>
			<g clipPath="url(#clip0_1207_604)">
				<mask
					id="mask0_1207_604"
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="30"
					height="30"
				>
					<path d="M29.976 0H0V30H29.976V0Z" fill="white" />
				</mask>
				<g mask="url(#mask0_1207_604)">
					<path
						d="M28.4282 0.0606034L0.809209 9.2676C0.596194 9.33715 0.407583 9.46631 0.265726 9.63978C0.12387 9.81324 0.0347143 10.0237 0.00882544 10.2463C-0.0170634 10.4689 0.0213994 10.6942 0.119656 10.8956C0.217913 11.097 0.371844 11.266 0.563209 11.3826L12.0542 17.9196L18.5362 29.4196C18.656 29.6165 18.8298 29.7749 19.0368 29.876C19.2439 29.9772 19.4756 30.017 19.7045 29.9906C19.9335 29.9642 20.1501 29.8727 20.3287 29.727C20.5072 29.5814 20.6403 29.3876 20.7122 29.1686L29.9182 1.5466C29.9854 1.33922 29.994 1.1173 29.943 0.905353C29.892 0.693403 29.7834 0.499662 29.6293 0.345516C29.4752 0.19137 29.2814 0.0828141 29.0695 0.0318312C28.8575 -0.0191518 28.6356 -0.010579 28.4282 0.0566033V0.0606034ZM19.3052 25.9226L14.3672 17.2856L22.1002 9.5516L20.4272 7.8786L12.6932 15.6126L4.05621 10.6736L26.9222 3.0576L19.3052 25.9226Z"
						fill="#5D9BFD"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_1207_604">
					<rect width="29.976" height="30" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Send;
