import { FC } from 'react';
import { Card, CardContent } from '../ui/card';

import BrainstormImage from '../ui/icons/new/BrainstormImage';
import { cn } from '@/lib/utils';

interface BrainstormIntroProps {}

const BrainstormIntro: FC<BrainstormIntroProps> = () => {
	return (
		<div className="p-6">
			<Card className={cn('shadow-main rounded-3xl bg-white')}>
				<CardContent className="px-5 py-8 text-center ">
					<h2 className="font-bold dynamic-medium text-secondary">
						Nothing to brainstorm yet.
					</h2>
					<p className="mt-2 text-base font-medium text-black">
						Start by clicking on any section on the left to start working on
						that section
					</p>
				</CardContent>
			</Card>

			<div className="flex flex-col items-center justify-center max-w-sm gap-8 mx-auto mt-10">
				<p className="text-base text-center text-black">
					<span className="font-bold">Tip:</span> You can use this brainstorm
					tool to converse with any content in the section you are working on.
					Brainstorm can provide feedback and recommendations
				</p>
				<BrainstormImage />
			</div>
		</div>
	);
};

export default BrainstormIntro;
