export const prContainerExit = {
	exit: { opacity: 0, x: -158, transition: { duration: 0.6 } },
};

export const prTextSlideIn = {
	initial: { opacity: 0 },
	animate: { opacity: 1, transition: { duration: 0.8 } },
};

export const prInputSlideIn = {
	initial: { opacity: 0 },
	animate: { opacity: 1, transition: { duration: 0.8 } },
};

export const containerDivFadeIn = {
	initial: { opacity: 0 },
	animate: {
		opacity: 1,
		transition: {
			duration: 0.8,
			type: 'cubic-bezier',
			ease: [0.76, 0, 0.24, 1],
		},
	},
	exit: { opacity: 0, x: -320, transition: { duration: 0.4 } },
};

export const prInputSlideInDelay = {
	initial: { opacity: 0 },
	animate: { opacity: 1, transition: { duration: 0.8, delay: 0.15 } },
};

export const prDotsZoomInDelay = {
	initial: { opacity: 0, scale: 0.7 },
	animate: { opacity: 1, scale: 1, transition: { duration: 0.5, delay: 0.15 } },
};

export const prFooterSlideInDelay = {
	initial: { opacity: 0, y: 320 },
	animate: { opacity: 1, y: 0, transition: { duration: 0.7, delay: 0.7 } },
	exit: { opacity: 0, x: -320, transition: { duration: 0.6 } },
};

export const mCEaseInDelay = {
	initial: { opacity: 0 },
	animate: { opacity: 1, transition: { duration: 0.8, delay: 0.3 } },
};

export const mCSlideIn = {
	initial: { opacity: 0, y: 426.5 },
	animate: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 1,
			type: 'cubic-bezier',
			ease: [0.76, 0, 0.24, 1],
		},
	},
	exit: { opacity: 0, transition: { duration: 0.2 } },
};

export const mCSlideInReverse = {
	initial: { opacity: 0, y: -426.5 },
	animate: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.7,
			type: 'cubic-bezier',
			ease: [0.76, 0, 0.24, 1],
		},
	},
	exit: { opacity: 0, transition: { duration: 0.2 } },
};

export const iCSlideIn = {
	initial: { opacity: 0, y: '-50%', scaleY: 0 },
	animate: {
		opacity: 1,
		y: 0,
		scaleY: 1,
		transition: {
			duration: 0.6,
			type: 'cubic-bezier',
			ease: [0.76, 0, 0.24, 1],
		},
	},
	exit: { opacity: 0, transition: { duration: 0.25 } },
};

export const iCSlideInDelay = {
	initial: { opacity: 0, y: '-50%', scaleY: 0 },
	animate: {
		opacity: 1,
		y: 0,
		scaleY: 1,
		transition: {
			duration: 0.6,
			delay: 0.25,
			type: 'cubic-bezier',
			ease: [0.76, 0, 0.24, 1],
		},
	},
	exit: { opacity: 0, transition: { duration: 0.25 } },
};

export const fadeInWithDelay = {
	initial: { opacity: 0 },
	animate: {
		opacity: 1,
		transition: { duration: 1, delay: 0.8 },
		type: 'cubic-bezier',
		ease: [0.76, 0, 0.24, 1],
	},
};

export const slideInUnsolicitedCard = {
	initial: { opacity: 0, y: 50.5 },
	animate: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.24,
			type: 'cubic-bezier',
			ease: [0.24, 0, 0, 0.24],
		},
	},
	exit: { opacity: 0, transition: { duration: 0.2 } },
};

export const slideInUnsolicitedCardReverse = {
	initial: { opacity: 0, y: -50.5 },
	animate: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.24,
			type: 'cubic-bezier',
			ease: [0.24, 0, 0, 0.24],
		},
	},
	exit: { opacity: 0, transition: { duration: 0.2 } },
};
