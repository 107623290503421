import { UserCircle } from 'lucide-react';
import { FC } from 'react';

interface AvailableSectionNodeProps {
	rowInfo: any;
}

const AvailableSectionNode: FC<AvailableSectionNodeProps> = ({ rowInfo }) => {
	return (
		<div className="flex items-center gap-1">
			{rowInfo.node.organizationIdentifier && (
				<UserCircle size={14} className="min-w-4" color="#E861C9" />
			)}

			<h2 className="font-bold leading-5 truncate text-primary dynamic-medium">
				{rowInfo.node.name}
			</h2>
		</div>
	);
};

export default AvailableSectionNode;
