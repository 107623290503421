import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { getAllTemplates } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	Template,
	TemplateFilter,
	TemplatesPagination,
} from '@/lib/types/apiTypes';
import { cn } from '@/lib/utils';
import { useNavigate } from 'react-router';
import {
	clearIntermediatePage,
	closeDialog,
	openDialog,
	setIntermediatePage,
} from '@/redux/slices/pageSlice';
import { setTemplate } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import {
	ChevronLeftIcon,
	ChevronRight,
	ChevronRightIcon,
	Search,
	X,
} from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from '@/components/ui/input';
import {
	ErrorHandle,
	cropText,
	handleResponse,
} from '@/lib/functions/funcUtils';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import { Skeleton } from '@/components/ui/skeleton';
import useDebounce from '@/lib/hooks/useDebounce';
import ProposalStructure from '@/components/Proposal/ProposalStructure/ProposalStructure';
import DocumentLock from '@/components/ui/icons/DocumentLock';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { cloneDeep, omit } from 'lodash';
import {
	buildTemplate,
	setEditTemplateId,
	setTemplateMode,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { TemplateLibrary } from '@/lib/types/TemplateBuilder/templateTypes';
import useAuth from '@/lib/hooks/useAuth';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';

interface TemplatePayload {
	pageNumber: number;
	filter: TemplateFilter;
	searchQuery: string;
}
interface TemplateSelectionProps {
	currentSelectedTemplate: Template | null;
	pageTransitionLoad?: boolean;
}

const pageSize = screen.width > 1280 ? 6 : 4;

const TemplateSelection: FC<TemplateSelectionProps> = ({
	currentSelectedTemplate,
	pageTransitionLoad = false,
}) => {
	const organizationIdentifier = useOrganizationId();
	const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
		currentSelectedTemplate
	);
	const intermediatePage = useSelector(
		(root: RootState) => root.page.intermediatePage
	);
	const rfp = useSelector((root: RootState) => root.solicitation.rfp);
	const [templates, setTemplates] = useState<Template[] | null>(null);
	const [pagination, setPagination] = useState<TemplatesPagination | null>(
		null
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<TemplateFilter>('all');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const { hasTemplateBuilderAccess } = useAuth();
	const navigate = useNavigate();

	const templateSections = rfp?.sections.find(
		(section) => section.name === 'templateSections'
	);
	const sectionNames = templateSections?.summary
		?.split('-')
		?.map((sectionName) => sectionName.trim());

	const updateTemplatesPagination = (
		templatePagination: TemplatesPagination
	) => {
		const pagination = {
			hasNextPage: templatePagination.hasNextPage,
			hasPrevPage: templatePagination.hasPrevPage,
			currentPage: templatePagination.currentPage,
		};
		setPagination(pagination);
	};

	const getTemplates = async ({
		pageNumber,
		filter,
		searchQuery,
	}: TemplatePayload) => {
		try {
			if (rfp) {
				setIsLoading(true);
				const response = await getAllTemplates({
					pageNumber: pageNumber || pagination?.currentPage || 1,
					pageSize: pageSize,
					filter: filter || 'all',
					organizationIdentifier: organizationIdentifier,
					...(searchQuery && { search: searchQuery }),
				});
				handleResponse(response);
				setTemplates((response as any).templates);
				updateTemplatesPagination(response);
				setIsLoading(false);
				return (response as any).templates;
			}
		} catch (error) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const dispatch = useAppDispatch();
	const handleItemSelect = (template: Template) => {
		setSelectedTemplate(template);
	};

	const handleFilterChange = (filter: TemplateFilter) => {
		setFilter(filter);
		getTemplates({
			searchQuery: searchQuery,
			filter: filter,
			pageNumber: 1,
		});
	};

	const handleEditTemplate = (templateCardData) => {
		if (organizationIdentifier === templateCardData.organizationIdentifier) {
			dispatch(setTemplateMode('edit'));
			dispatch(setEditTemplateId(templateCardData?._id));
			dispatch(buildTemplate(templateCardData));
			navigate(`/template/edit/${templateCardData?._id}`);
		} else {
			const updatedData = omit(templateCardData, '_id');
			dispatch(setTemplateMode('clone'));
			dispatch(buildTemplate(updatedData as TemplateLibrary));
			navigate(`/template/new`);
		}
		dispatch(
			setIntermediatePage({
				mode: 'editTemplate',
				scope: [
					'/template/new',
					'/template/edit',
					'/create',
					'/template/design',
				],
				state: {
					filter: filter,
					searchQuery: searchQuery,
					selectedTemplate: selectedTemplate,
					pagination: pagination,
				},
				onCancel: async () => {
					navigate(`/create`);
					dispatch(
						openDialog({
							type: 'viewTemplates',
						})
					);
				},
				onCompletion: async () => {
					navigate(`/create`);
					dispatch(
						openDialog({
							type: 'viewTemplates',
						})
					);
				},
			})
		);
		dispatch(closeDialog());
	};

	const clearSearchQuery = () => {
		setSearchQuery('');
		getTemplates({
			filter: filter,
			searchQuery: '',
			pageNumber: 1,
		});
	};

	const debouncedSearchChange = useDebounce(getTemplates, 800);

	const reinstateTemplateSelection = async () => {
		const { selectedTemplate, pagination, filter, searchQuery } =
			intermediatePage?.state || {};

		pagination && setPagination(pagination);
		filter && setPagination(filter);
		searchQuery && setPagination(searchQuery);

		const templates = await getTemplates({
			filter: filter,
			searchQuery: searchQuery,
			pageNumber: pagination.currentPage,
		});

		const updatedTemplate = templates.find(
			(template) => template._id === selectedTemplate?._id
		);
		if (updatedTemplate || selectedTemplate) {
			setSelectedTemplate(cloneDeep(updatedTemplate || selectedTemplate));
			dispatch(setTemplate(updatedTemplate || selectedTemplate));
		}

		dispatch(clearIntermediatePage());
	};

	useEffect(() => {
		if (intermediatePage) {
			reinstateTemplateSelection();
		} else {
			getTemplates({
				filter: filter,
				searchQuery: searchQuery,
				pageNumber: 1,
			});
		}
	}, []);

	return (
		<div className="flex flex-col gap-4">
			<h4 className="w-full font-bold text-left text-black dynamic-medium">
				Select a template
			</h4>
			<div className="grid grid-cols-12 max-h-[600px] gap-6">
				<div className="flex flex-col items-center w-full h-full col-span-7 gap-4 mx-auto ">
					<div className="relative w-full mb-2">
						<Input
							value={searchQuery}
							onChange={(event) => {
								setPagination(null);
								setSearchQuery(event.target.value);
								debouncedSearchChange({
									filter: filter,
									pageNumber: 1,
									searchQuery: event.target.value,
								});
							}}
							placeholder="Search"
							className="h-12 bg-white border-[#D3DDE2] w-full p-4  rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-16"
						/>
						<div className="absolute right-4 top-4">
							{searchQuery !== '' ? (
								<X
									onClick={clearSearchQuery}
									className="cursor-pointer"
									color="#5D6F79"
									size={16}
								/>
							) : (
								<Search color="#5D9BFD" size={16} onClick={clearSearchQuery} />
							)}
						</div>
					</div>
					<div className="flex flex-col w-full gap-3">
						{pageTransitionLoad ? (
							<div className="flex items-center justify-start w-full gap-2">
								{[...Array(4)].map((key, index) => (
									<Skeleton
										key={key + '-' + index}
										className="w-20 h-8 px-4 rounded-full bg-slate-400"
									/>
								))}
							</div>
						) : (
							<div className="flex items-center justify-start w-full gap-2">
								<SecondaryButton
									onClick={() => handleFilterChange('all')}
									className={cn(
										'rounded-full px-4 h-8 2xl:h-8 min-w-fit dynamic-small',
										filter === 'all'
											? 'text-white bg-primary'
											: 'hover:bg-[#E3EEFF] hover:text-primary'
									)}
									disabled={isLoading}
								>
									All
								</SecondaryButton>
								<SecondaryButton
									onClick={() => handleFilterChange('pre-built')}
									className={cn(
										'rounded-full px-4 h-8 2xl:h-8 min-w-fit dynamic-small',
										filter === 'pre-built'
											? 'text-white bg-primary'
											: 'hover:bg-[#E3EEFF] hover:text-primary'
									)}
									disabled={isLoading}
								>
									Pre-Built
								</SecondaryButton>
								<SecondaryButton
									onClick={() => handleFilterChange('organization')}
									className={cn(
										'rounded-full px-4 h-8 2xl:h-8 min-w-fit dynamic-small',
										filter === 'organization'
											? 'text-white bg-primary'
											: 'hover:bg-[#E3EEFF] hover:text-primary'
									)}
									disabled={isLoading}
								>
									Created by Your organization
								</SecondaryButton>
							</div>
						)}

						{isLoading || pageTransitionLoad ? (
							<div className="flex flex-col w-full h-full gap-3">
								{[...Array(pageSize)].map((key, index) => (
									<Skeleton
										key={key + '-' + index}
										className="flex flex-row h-[47px] 2xl:h-[50px] w-full bg-slate-300 rounded-2xl items-center justify-between px-4 py-3"
									>
										<Skeleton className="h-6 rounded-full w-60 bg-slate-400" />
										<div className="flex gap-1">
											<Skeleton className="w-20 h-8 rounded-full bg-slate-500" />
											<Skeleton className="w-16 h-8 rounded-full bg-slate-500" />
										</div>
									</Skeleton>
								))}
							</div>
						) : templates && templates.length > 0 ? (
							<ul className="flex flex-col w-full gap-3">
								{templates?.map((template, index) => (
									<li
										key={template.name + '-' + index}
										className={cn(
											'h-[47px] 2xl:h-[50px] cursor-pointer bg-white rounded-2xl flex items-center justify-between dynamic-medium font-bold px-4 py-3 w-full',
											selectedTemplate?._id === template._id
												? 'bg-primary text-white border border-primary'
												: 'border border-[#D3DDE2] hover:bg-[#E3EEFF] text-primary'
										)}
										onClick={() => handleItemSelect(template)}
									>
										<TooltipProvider>
											<Tooltip>
												<TooltipTrigger
													className={cn(
														'truncate pr-2',
														selectedTemplate?._id === template._id
															? 'text-white'
															: 'text-primary'
													)}
												>
													{template.name}
												</TooltipTrigger>
												<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
													{template.name}
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>

										<div className="flex items-center gap-1">
											{template.tags.slice(0, 2).map((item) => (
												<TooltipProvider key={item}>
													<Tooltip>
														<TooltipTrigger>
															<Badge
																key={item}
																className="items-center justify-center block px-3 bg-white border max-w-40 text-secondary border-secondary"
															>
																<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-small text-nowrap text-ellipsis">
																	{cropText(item, 16)}
																</span>
															</Badge>
														</TooltipTrigger>
														<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
															{item}
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>
											))}
											{template.tags.length > 2 && (
												<Popover>
													<PopoverTrigger onClick={(e) => e.stopPropagation()}>
														<TooltipProvider>
															<Tooltip>
																<TooltipTrigger className="flex">
																	<ChevronRight
																		className={cn(
																			selectedTemplate?._id === template._id
																				? 'text-white'
																				: 'text-[#5D6F79]'
																		)}
																		size={18}
																	/>
																</TooltipTrigger>
																<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
																	View More Tags
																</TooltipContent>
															</Tooltip>
														</TooltipProvider>
													</PopoverTrigger>
													<PopoverContent
														className="gap-2 py-4 bg-white rounded-md "
														align="start"
													>
														<h2 className="text-[#5D6F79] font-bold mb-3">
															Template Tags
														</h2>
														<div className="flex flex-wrap gap-1">
															{template.tags.map((item) => (
																<Badge
																	key={item}
																	className="items-center justify-center block px-3 bg-white border text-secondary border-secondary"
																>
																	<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-badge text-nowrap text-ellipsis">
																		{item}
																	</span>
																</Badge>
															))}
														</div>
													</PopoverContent>
												</Popover>
											)}
										</div>
									</li>
								))}
							</ul>
						) : (
							<div className="flex items-center justify-center h-full py-5 text-center text-black dynamic-medium">
								No template found.
							</div>
						)}
					</div>
					<div
						className={cn(
							'flex items-center justify-end px-2 w-full gap-2',
							pagination?.hasNextPage === true ||
								pagination?.hasPrevPage === true
								? ''
								: 'hidden'
						)}
					>
						<Button
							className="w-8 h-8 p-0"
							onClick={() =>
								pagination &&
								getTemplates({
									filter: filter,
									pageNumber: pagination.currentPage - 1,
									searchQuery: searchQuery,
								})
							}
							disabled={isLoading || !pagination?.hasPrevPage}
						>
							<span className="sr-only">Go to previous page</span>
							<ChevronLeftIcon className="w-4 h-4 text-white" />
						</Button>
						<Button
							className="w-8 h-8 p-0"
							onClick={() =>
								pagination &&
								getTemplates({
									filter: filter,
									pageNumber: pagination.currentPage + 1,
									searchQuery: searchQuery,
								})
							}
							disabled={isLoading || !pagination?.hasNextPage}
						>
							<span className="sr-only">Go to next page</span>
							<ChevronRightIcon className="w-4 h-4 text-white" />
						</Button>
					</div>
				</div>
				<div className="flex flex-col h-full overflow-y-auto min-h-[350px] 2xl:min-h-[450px] col-span-5">
					{selectedTemplate ? (
						<ProposalStructure
							sectionNames={sectionNames}
							template={selectedTemplate}
							sections={selectedTemplate?.sections}
							className="w-full h-full p-0 m-0 border-0"
							pageTransitionLoad={pageTransitionLoad}
							maxCharacter={32}
						/>
					) : (
						<div className="flex flex-col items-center justify-center w-full h-full gap-4 bg-[#EEF2F9] rounded-xl border border-[rgba(185,212,255,0.40)]">
							<DocumentLock type="enabled" />
							<p className="pb-10 font-medium text-black dynamic-large">
								Click a template to preview its structure
							</p>
						</div>
					)}
				</div>
			</div>
			<div className="flex justify-end w-full gap-4">
				{hasTemplateBuilderAccess && (
					<SecondaryButton
						onClick={() => handleEditTemplate(selectedTemplate)}
						type="button"
						disabled={!selectedTemplate || isLoading || pageTransitionLoad}
					>
						Edit this Template
					</SecondaryButton>
				)}
				<PrimaryButton
					onClick={() => {
						if (selectedTemplate) {
							dispatch(setTemplate(selectedTemplate));
							dispatch(closeDialog());
						}
					}}
					type="button"
					disabled={!selectedTemplate || isLoading || pageTransitionLoad}
				>
					Select this Template
				</PrimaryButton>
			</div>
		</div>
	);
};

export default TemplateSelection;
