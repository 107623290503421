import axios from 'axios';
import { openDialog } from '@/redux/slices/pageSlice';
import { clearState } from '../functions/funcUtils';

const api = axios.create({
	baseURL: import.meta.env.VITE_SERVER_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

let store;

export const injectStore = (_store) => {
	store = _store;
};

api.interceptors.request.use(
	(config) => {
		const token = store.getState().auth.token; // Access the state directly
		if (token && config.headers) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			clearState(store.dispatch);
		} else if (
			error.response.status === 403 &&
			error.response.data.message === 'Trial Expired'
		) {
			store.dispatch(openDialog({ type: 'trialEnd' }));
		}
		return Promise.reject(error);
	}
);

export default api;
