import { convertTimestamp } from '@/lib/functions/funcUtils';
import { FC, useState } from 'react';
import styles from './HistoryCard.module.css';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { renderTextWithLineBreaks } from '@/lib/functions/editor';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import { RotateCcw } from 'lucide-react';

interface HistoryCardProps {
	text: string;
	timestamp: string;
	index: number;
	user?: {
		email: string;
		name: string;
		profilePicture: string;
	};
}

const HistoryCard: FC<HistoryCardProps> = ({
	timestamp,
	text,
	index,
	user,
}) => {
	const [isReadMore, setIsReadMore] = useState(true);
	const dispatch = useAppDispatch();

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const MAX_LENGTH = 200;

	return (
		<div className={styles.card}>
			<div className="flex items-center justify-between">
				<p className="dynamic-text text-[#5d6f79] font-bold">
					{convertTimestamp(timestamp)}
				</p>
				{user && (
					<p className="dynamic-small text-[#5d6f79] italic">
						Generated by{' '}
						<span className="font-semibold capitalize text-secondary">
							{user?.name}
						</span>
					</p>
				)}
			</div>
			<p className="mt-2 text-[#5d6f79] dynamic-text text-left">
				{isReadMore
					? `${text.slice(0, MAX_LENGTH)}...`
					: renderTextWithLineBreaks(text)}
			</p>
			{text.length > MAX_LENGTH && (
				<TextButton
					onClick={toggleReadMore}
					className="h-fit 2xl:h-fit w-fit p-0 mt-2"
				>
					{isReadMore ? 'Read More' : <span className="font-normal">Less</span>}
				</TextButton>
			)}
			<TextButton
				className="flex items-center gap-1 mt-3 font-medium uppercase cursor-pointer text-primary h-auto 2xl:h-auto p-0"
				onClick={() =>
					dispatch(
						openDialog({ type: 'historyRestoreConfirm', historyIndex: index })
					)
				}
			>
				<RotateCcw /> Restore
			</TextButton>
		</div>
	);
};

export default HistoryCard;
