import { SVGProps } from 'react';

const QuestionnaireIcon = (props: SVGProps<SVGSVGElement>) => {
	const color = props.color || '#5D9BFD';
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
		>
			<path
				d="M12.9085 19.9996H3.23599C2.37797 19.9989 1.55529 19.6562 0.948579 19.0467C0.341865 18.4372 0.000704356 17.6108 0 16.7489L0 3.25362C0 2.39149 0.340934 1.56466 0.947801 0.955036C1.55467 0.345413 2.37776 0.00292969 3.23599 0.00292969L12.9085 0.00292969C13.7665 0.00363724 14.5892 0.346347 15.1959 0.955817C15.8026 1.56529 16.1438 2.3917 16.1445 3.25362V16.7498C16.1438 17.6117 15.8026 18.4381 15.1959 19.0476C14.5892 19.6571 13.7665 19.9998 12.9085 20.0005M3.23599 1.43101C2.75505 1.43183 2.29403 1.62412 1.95395 1.96575C1.61386 2.30738 1.42244 2.77049 1.42162 3.25362V16.7498C1.42256 17.2329 1.61403 17.6958 1.9541 18.0374C2.29417 18.3789 2.75512 18.5711 3.23599 18.572H12.9085C13.3892 18.5709 13.8498 18.3786 14.1897 18.0372C14.5295 17.6958 14.7209 17.2331 14.722 16.7502V3.25362C14.7212 2.77064 14.5299 2.30767 14.19 1.96606C13.8501 1.62446 13.3893 1.43207 12.9085 1.43101H3.23599Z"
				fill={props.mode === 'hovered' ? 'white' : color}
			/>
			<path
				d="M11.5999 5.34071C11.5067 5.34071 11.4145 5.32228 11.3285 5.28648C11.2424 5.25067 11.1642 5.19819 11.0984 5.13203C11.0325 5.06587 10.9803 4.98733 10.9446 4.90089C10.909 4.81444 10.8906 4.7218 10.8906 4.62823V0.71404C10.8906 0.524665 10.9655 0.343046 11.0988 0.209137C11.2321 0.075229 11.4129 0 11.6014 0C11.79 0 11.9708 0.075229 12.1041 0.209137C12.2374 0.343046 12.3122 0.524665 12.3122 0.71404V3.90195L15.4259 3.8543H15.437C15.5303 3.85357 15.6229 3.87131 15.7094 3.90652C15.7959 3.94173 15.8747 3.99371 15.9412 4.0595C16.0077 4.12529 16.0607 4.20359 16.0971 4.28995C16.1335 4.3763 16.1526 4.469 16.1533 4.56277C16.1541 4.65654 16.1364 4.74954 16.1013 4.83645C16.0663 4.92336 16.0145 5.00248 15.9491 5.06931C15.8836 5.13613 15.8056 5.18934 15.7197 5.2259C15.6337 5.26245 15.5414 5.28165 15.4481 5.28238L11.6088 5.34071H11.5999Z"
				fill={props.mode === 'hovered' ? 'white' : color}
			/>
			<path
				d="M8.7649 8.32693H3.97253C3.78401 8.32693 3.60321 8.25167 3.46991 8.11773C3.33661 7.98378 3.26172 7.80211 3.26172 7.61268C3.26172 7.42325 3.33661 7.24158 3.46991 7.10763C3.60321 6.97369 3.78401 6.89844 3.97253 6.89844H8.7649C8.95342 6.89844 9.13422 6.97369 9.26752 7.10763C9.40083 7.24158 9.47571 7.42325 9.47571 7.61268C9.47571 7.80211 9.40083 7.98378 9.26752 8.11773C9.13422 8.25167 8.95342 8.32693 8.7649 8.32693Z"
				fill={props.mode === 'hovered' ? 'white' : color}
			/>
			<path
				d="M12.0512 12.197H3.97448C3.88114 12.197 3.78871 12.1786 3.70247 12.1427C3.61623 12.1068 3.53787 12.0542 3.47186 11.9878C3.40586 11.9215 3.3535 11.8428 3.31778 11.7561C3.28206 11.6695 3.26367 11.5766 3.26367 11.4828C3.26367 11.389 3.28206 11.2961 3.31778 11.2095C3.3535 11.1228 3.40586 11.0441 3.47186 10.9778C3.53787 10.9114 3.61623 10.8588 3.70247 10.8229C3.78871 10.787 3.88114 10.7686 3.97448 10.7686H12.0512C12.1445 10.7686 12.2369 10.787 12.3232 10.8229C12.4094 10.8588 12.4878 10.9114 12.5538 10.9778C12.6198 11.0441 12.6722 11.1228 12.7079 11.2095C12.7436 11.2961 12.762 11.389 12.762 11.4828C12.762 11.5766 12.7436 11.6695 12.7079 11.7561C12.6722 11.8428 12.6198 11.9215 12.5538 11.9878C12.4878 12.0542 12.4094 12.1068 12.3232 12.1427C12.2369 12.1786 12.1445 12.197 12.0512 12.197Z"
				fill={props.mode === 'hovered' ? 'white' : color}
			/>
			<path
				d="M12.0512 16.0633H3.97448C3.78596 16.0633 3.60517 15.988 3.47186 15.8541C3.33856 15.7201 3.26367 15.5384 3.26367 15.349C3.26367 15.1596 3.33856 14.9779 3.47186 14.844C3.60517 14.71 3.78596 14.6348 3.97448 14.6348H12.0512C12.2397 14.6348 12.4205 14.71 12.5538 14.844C12.6871 14.9779 12.762 15.1596 12.762 15.349C12.762 15.5384 12.6871 15.7201 12.5538 15.8541C12.4205 15.988 12.2397 16.0633 12.0512 16.0633Z"
				fill={props.mode === 'hovered' ? 'white' : color}
			/>
			<path
				d="M18.8911 11.1089C17.8219 10.0398 16.5155 9.5 15 9.5C13.4845 9.5 12.1781 10.0398 11.1089 11.1089C10.0398 12.1781 9.5 13.4845 9.5 15C9.5 16.5155 10.0398 17.8219 11.1089 18.8911C12.1781 19.9602 13.4845 20.5 15 20.5C16.5155 20.5 17.8219 19.9602 18.8911 18.8911C19.9602 17.8219 20.5 16.5155 20.5 15C20.5 13.4845 19.9602 12.1781 18.8911 11.1089Z"
				fill={props.mode === 'hovered' ? 'white' : color}
				stroke={props.mode === 'hovered' ? color : 'white'}
			/>
			<path
				d="M15.4626 17.8125C15.3376 17.9375 15.1834 18 15.0001 18C14.8168 18 14.6626 17.9375 14.5376 17.8125C14.4126 17.6875 14.3501 17.5333 14.3501 17.35C14.3501 17.1667 14.4126 17.0125 14.5376 16.8875C14.6626 16.7625 14.8168 16.7 15.0001 16.7C15.1834 16.7 15.3376 16.7625 15.4626 16.8875C15.5876 17.0125 15.6501 17.1667 15.6501 17.35C15.6501 17.5333 15.5876 17.6875 15.4626 17.8125Z"
				fill={props.mode === 'hovered' ? color : 'white'}
			/>
			<path
				d="M14.1251 12.2375C14.3918 12.0792 14.6834 12 15.0001 12C15.4668 12 15.8668 12.1542 16.2001 12.4625C16.5334 12.7708 16.7001 13.15 16.7001 13.6C16.7001 13.8667 16.6251 14.1125 16.4751 14.3375C16.3251 14.5625 16.1501 14.7667 15.9501 14.95C15.8168 15.0833 15.7001 15.2208 15.6001 15.3625C15.5001 15.5042 15.4501 15.6667 15.4501 15.85C15.4501 15.9833 15.4084 16.1 15.3251 16.2C15.2418 16.3 15.1334 16.35 15.0001 16.35C14.8668 16.35 14.7584 16.2875 14.6751 16.1625C14.5918 16.0375 14.5501 15.9 14.5501 15.75C14.5501 15.6333 14.5626 15.5208 14.5876 15.4125C14.6126 15.3042 14.6501 15.2 14.7001 15.1C14.7834 14.9667 14.8793 14.85 14.9876 14.75C15.0959 14.65 15.2001 14.55 15.3001 14.45C15.4334 14.3167 15.5501 14.1875 15.6501 14.0625C15.7501 13.9375 15.8001 13.7833 15.8001 13.6C15.8001 13.3833 15.7209 13.2042 15.5626 13.0625C15.4043 12.9208 15.2168 12.85 15.0001 12.85C14.8501 12.85 14.7126 12.8833 14.5876 12.95C14.4626 13.0167 14.3584 13.1083 14.2751 13.225C14.1918 13.3083 14.1001 13.375 14.0001 13.425C13.9001 13.475 13.8001 13.475 13.7001 13.425C13.5834 13.375 13.5001 13.2958 13.4501 13.1875C13.4001 13.0792 13.4084 12.975 13.4751 12.875C13.6418 12.6083 13.8584 12.3958 14.1251 12.2375Z"
				fill={props.mode === 'hovered' ? color : 'white'}
			/>
		</svg>
	);
};

export default QuestionnaireIcon;
