import { Step } from 'intro.js-react';
export const WriteSteps: Step[] = [
	{
		element: '.write',
		title: 'Proposal writer',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					This page lets you write your proposal. The AI can help you write each
					section of the proposal based on the solicitation, the responses to
					your win themes and approach questions, formative documents, and your
					program design.
				</p>
				<p>
					We recommend spending time developing your program design before
					proceeding to the <span className="font-bold">write</span> mode.
					However, you can easily toggle between sections in{' '}
					<span className="font-bold">design</span> and{' '}
					<span className="font-bold">write</span> by clicking on the tabs
					above.
				</p>
			</div>
		),
		position: 'left',
	},
	{
		element: '.suggestions',
		title: 'Suggestions',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					The Suggestions section pulls out pieces of information from documents
					that you associated with this proposal. You can see where these
					suggestions are coming from by pressing{' '}
					<span className="font-bold">tell me more</span> or pressing{' '}
					<span className="font-bold">add this in</span> to rewrite the section
					with the context of the suggestion.
				</p>
			</div>
		),
		position: 'bottom',
	},
	{
		element: '.brainstorm',
		title: 'Brainstorm',
		intro:
			'The Brainstorm section allows you to bounce off ideas using our custom chatbot that is focused on the specific section you are working on.',
		position: 'top',
	},
	{
		element: '.magic-history',
		title: 'Magic History',
		intro: 'You can revert to a previous version of a draft by clicking here. ',
		position: 'top',
	},
	{
		element: '.compliance',
		title: 'Compliance',
		intro:
			'You can find the full compliance matrix by clicking the compliance link. You can also run a compliance check at any time. ',
		position: 'bottom',
	},
	{
		element: '.visuals',
		title: 'Visuals',
		intro:
			'You can add visuals using csv data to further supplement your draft ',
		position: 'bottom',
	},
	{
		element: '.citations',
		title: 'Citations',
		intro: 'You can access all your citations by clicking here',
		position: 'bottom',
	},
	{
		element: '.section-outline',
		title: 'View outline',
		intro:
			'You can access the section outline by clicking here and toggle between sections.',
		position: 'bottom',
	},

	{
		element: '.export',
		title: 'Export to word or PDF',
		intro: 'You can export your draft to Word or PDF formats by clicking here.',
		position: 'bottom',
	},
];
