import TemplateCardSkeleton from '../TemplateCard/TemplateCardSkeleton';
import PrivateTemplateBodyHeader from '../TemplateBodyHeader/PrivateTemplateBodyHeader';
import PrivateTemplateCard from '../TemplateCard/PrivateTemplateCard';
import { FC } from 'react';
import { PrivateTemplateBodyProps } from '@/lib/types/TemplateBuilder/templateTypes';

const PrivateTemplateBody: FC<PrivateTemplateBodyProps> = ({
	privateTemplatesData,
	handlePrivateTemplateSorting,
	privateTemplatePageSizeLoading,
	privateTemplatePageSize,
}) => {
	return (
		<>
			<PrivateTemplateBodyHeader
				templateCount={privateTemplatesData?.length || 0}
				handlePrivateTemplateSorting={handlePrivateTemplateSorting}
			/>
			<div className="relative h-5 z-10 w-full bg-gradient-to-b from-[#f5f5f5] to-[#f5f5f500]"></div>
			<div className="relative grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 h-auto overflow-y-auto hidden-scroll px-6 xl:px-8 py-6 mt-[-1rem] gap-4">
				{!privateTemplatesData ? (
					<TemplateCardSkeleton />
				) : (
					<>
						{privateTemplatePageSizeLoading
							? Array(Number(privateTemplatePageSize) || 3)
									.fill('')
									.map((_, index) => <TemplateCardSkeleton key={index} />)
							: privateTemplatesData &&
								privateTemplatesData?.map((template) => (
									<PrivateTemplateCard
										key={template._id}
										templateCardData={template}
									/>
								))}
					</>
				)}
			</div>
		</>
	);
};

export default PrivateTemplateBody;
