import LoaderFill from '@/components/Loader/LoaderFill/LoaderFill';
import { RootState } from '@/redux/store/store';
import { FC } from 'react';
import { Toaster } from '@/components/ui/toaster';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';

interface LoginLayoutProps {}

const LoginLayout: FC<LoginLayoutProps> = () => {
	const isLoading = useSelector((root: RootState) => root.auth.isLoading);
	return (
		<>
			<Toaster />
			<Outlet />
			{isLoading && <LoaderFill />}
		</>
	);
};

export default LoginLayout;
