import { Button } from '@/components/ui/button';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';
import { ProposalTableProps } from '@/lib/types/constants';
import { Dispatch, SetStateAction } from 'react';

export function getColumns(
	handleNavigate?: (id: string) => void,
	setSelectedRowIds?: Dispatch<SetStateAction<string[]>>,
	selectedRowIds?: string[]
): ColumnDef<ProposalTableProps>[] {
	const columns: ColumnDef<ProposalTableProps>[] = [
		{
			id: 'select',
			header: ({ table }) => (
				<Checkbox
					className="border-black data-[state=checked]:text-white data-[state=checked]:border-primary"
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={(value) => {
						table.toggleAllPageRowsSelected(!!value);
						if (selectedRowIds && setSelectedRowIds) {
							if (value) {
								setSelectedRowIds &&
									setSelectedRowIds([
										...selectedRowIds,
										...table
											.getFilteredRowModel()
											.rows.map((row) => String(row.original.id)),
									]);
							} else {
								setSelectedRowIds &&
									setSelectedRowIds(
										selectedRowIds.filter(
											(id) =>
												!table
													.getFilteredRowModel()
													.rows.map((row) => String(row.original.id))
													.includes(id)
										)
									);
							}
						}
					}}
					aria-label="Select all"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					className="border-black data-[state=checked]:text-white data-[state=checked]:border-primary"
					checked={row.getIsSelected()}
					onCheckedChange={(value) => {
						row.toggleSelected(!!value);
						if (selectedRowIds && setSelectedRowIds) {
							const rowId = String(row.original.id);
							setSelectedRowIds(
								value
									? [...selectedRowIds, rowId]
									: selectedRowIds.filter((id) => id !== rowId)
							);
						}
					}}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},

		{
			accessorKey: 'name',
			header: ({ column }) => {
				return (
					<Button
						className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
						onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
					>
						Proposal Name
						<ArrowUpDown className="w-4 h-4 ml-2" />
					</Button>
				);
			},
			cell: ({ row }) => {
				if (!row.original.name) {
					return (
						<div
							onClick={() =>
								handleNavigate && handleNavigate(String(row.original.id))
							}
							className="cursor-pointer text-primary"
						>
							Untitled Proposal
						</div>
					);
				} else {
					return (
						<div
							onClick={() =>
								handleNavigate && handleNavigate(String(row.original.id))
							}
							className="cursor-pointer text-primary"
						>
							{row.original.name}
						</div>
					);
				}
			},
		},
		{
			accessorKey: 'rfpTitle',
			header: ({ column }) => {
				return (
					<Button
						className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
						onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
					>
						Solicitation Title
						<ArrowUpDown className="w-4 h-4 ml-2" />
					</Button>
				);
			},
		},
		{
			accessorKey: 'rfpCode',
			header: ({ column }) => {
				return (
					<Button
						className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
						onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
					>
						Solicitation Code
						<ArrowUpDown className="w-4 h-4 ml-2" />
					</Button>
				);
			},
		},

		{
			accessorKey: 'createdAt',
			header: ({ column }) => {
				return (
					<Button
						className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
						onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
					>
						Created On
						<ArrowUpDown className="w-4 h-4 ml-2" />
					</Button>
				);
			},
			cell: ({ row }) => {
				return <p>{new Date(row.original.createdAt).toLocaleDateString()}</p>;
			},
		},
	];

	return columns;
}
