import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronsLeftIcon,
	ChevronsRightIcon,
} from 'lucide-react';
import { Dispatch, FC, SetStateAction } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';

interface SectionPaginationProps {
	setSectionCurrentPage: Dispatch<SetStateAction<number>>;
	sectionCurrentPage: number;
	sectionTotalPages: number;
	sectionHasNextPage?: boolean;
	sectionHasPrevPage?: boolean;
}
export const SectionPagination: FC<SectionPaginationProps> = ({
	sectionCurrentPage,
	setSectionCurrentPage,
	sectionHasNextPage,
	sectionHasPrevPage,
	sectionTotalPages,
}) => {
	return (
		<div className="flex flex-row justify-end items-center mb-2 space-x-2 pt-1.5">
			<Button
				type="button"
				className="hidden w-8 h-8 p-0 lg:flex"
				onClick={() => {
					setSectionCurrentPage(1);
				}}
				disabled={!sectionHasPrevPage}
			>
				<span className="sr-only">Go to first page</span>
				<ChevronsLeftIcon className="w-4 h-4 text-white" />
			</Button>
			<Button
				type="button"
				className="w-8 h-8 p-0"
				onClick={() => {
					setSectionCurrentPage(sectionCurrentPage - 1);
				}}
				disabled={!sectionHasPrevPage}
			>
				<span className="sr-only">Go to previous page</span>
				<ChevronLeftIcon className="w-4 h-4 text-white" />
			</Button>
			<Button
				type="button"
				className="w-8 h-8 p-0"
				onClick={() => {
					setSectionCurrentPage(Number(sectionCurrentPage + 1));
				}}
				disabled={!sectionHasNextPage}
			>
				<span className="sr-only">Go to next page</span>
				<ChevronRightIcon className="w-4 h-4 text-white" />
			</Button>
			<Button
				type="button"
				className="hidden w-8 h-8 p-0 lg:flex "
				onClick={() => {
					setSectionCurrentPage(Number(sectionTotalPages));
				}}
				disabled={!sectionHasNextPage}
			>
				<span className="sr-only">Go to last page</span>
				<ChevronsRightIcon className="w-4 h-4 text-white" />
			</Button>
		</div>
	);
};

interface PrivateTemplatePaginationProps {
	setPrivateTemplateCurrentPage: Dispatch<SetStateAction<number>>;
	privateTemplateCurrentPage: number;
	privateTemplateTotalPages: number;
	privateTemplateHasPrevPage: boolean;
	privateTemplateHasNextPage: boolean;
	handlePrivateTemplatePageSize: (value: string) => void;
	privateTemplatePageSize: string;
}
export const PrivateTemplatePagination: FC<PrivateTemplatePaginationProps> = ({
	setPrivateTemplateCurrentPage,
	privateTemplateCurrentPage,
	privateTemplateHasPrevPage,
	privateTemplateHasNextPage,
	privateTemplateTotalPages,
	handlePrivateTemplatePageSize,
	privateTemplatePageSize,
}) => {
	return (
		<div className="flex items-end justify-between px-6 xl:px-8 text-black">
			<div className="flex items-center gap-4">
				<div className="flex items-center space-x-2">
					<p className="text-sm font-medium text-black">Rows per page</p>
					<Select
						value={`${privateTemplatePageSize}`}
						onValueChange={(value) => {
							handlePrivateTemplatePageSize(value);
						}}
					>
						<SelectTrigger className="h-8 w-[70px] ">
							<SelectValue placeholder={privateTemplatePageSize} />
						</SelectTrigger>
						<SelectContent side="top" className="bg-white">
							{[3, 5, 10, 20, 25].map((pageSize) => (
								<SelectItem key={pageSize} value={`${pageSize}`}>
									{pageSize}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<div className="flex items-center justify-center text-xs text-black">
					Page {privateTemplateCurrentPage} of {privateTemplateTotalPages}
				</div>
			</div>
			<div className="flex justify-center items-center space-x-2">
				<Button
					type="button"
					className="hidden w-8 h-8 p-0 lg:flex"
					onClick={() => {
						setPrivateTemplateCurrentPage(1);
					}}
					disabled={!privateTemplateHasPrevPage}
				>
					<span className="sr-only">Go to first page</span>
					<ChevronsLeftIcon className="w-4 h-4 text-white" />
				</Button>
				<Button
					type="button"
					className="w-8 h-8 p-0"
					onClick={() => {
						setPrivateTemplateCurrentPage(privateTemplateCurrentPage - 1);
					}}
					disabled={!privateTemplateHasPrevPage}
				>
					<span className="sr-only">Go to previous page</span>
					<ChevronLeftIcon className="w-4 h-4 text-white" />
				</Button>
				<Button
					type="button"
					className="w-8 h-8 p-0"
					onClick={() => {
						setPrivateTemplateCurrentPage(
							Number(privateTemplateCurrentPage + 1)
						);
					}}
					disabled={!privateTemplateHasNextPage}
				>
					<span className="sr-only">Go to next page</span>
					<ChevronRightIcon className="w-4 h-4 text-white" />
				</Button>
				<Button
					type="button"
					className="hidden w-8 h-8 p-0 lg:flex "
					onClick={() => {
						setPrivateTemplateCurrentPage(Number(privateTemplateTotalPages));
					}}
					disabled={!privateTemplateHasNextPage}
				>
					<span className="sr-only">Go to last page</span>
					<ChevronsRightIcon className="w-4 h-4 text-white" />
				</Button>
			</div>
		</div>
	);
};

interface PreBuildTemplatePaginationProps {
	setTemplateCurrentPage: Dispatch<SetStateAction<number>>;
	templateCurrentPage: number;
	templateTotalPages: number;
	templateHasPrevPage: boolean;
	templateHasNextPage: boolean;
	handleTemplatePageSize: (value: string) => void;
	templatePageSize: string;
}
export const PreBuildTemplatePagination: FC<
	PreBuildTemplatePaginationProps
> = ({
	templateCurrentPage,
	setTemplateCurrentPage,
	templateHasNextPage,
	templateHasPrevPage,
	templateTotalPages,
	handleTemplatePageSize,
	templatePageSize,
}) => {
	return (
		<div className="flex items-center justify-between px-6 xl:px-8 mt-4 text-black">
			<div className="flex items-center gap-4">
				<div className="flex items-center space-x-2">
					<p className="text-sm font-medium">Rows per page</p>
					<Select
						value={`${templatePageSize}`}
						onValueChange={(value) => {
							handleTemplatePageSize(value);
						}}
					>
						<SelectTrigger className="h-8 w-[70px] ">
							<SelectValue placeholder={templatePageSize} />
						</SelectTrigger>
						<SelectContent side="top" className="bg-white">
							{[3, 5, 10, 20, 25].map((pageSize) => (
								<SelectItem key={pageSize} value={`${pageSize}`}>
									{pageSize}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<div className="flex items-center justify-center text-xs ">
					Page {templateCurrentPage} of {templateTotalPages}
				</div>
			</div>
			<div className="flex justify-center items-center space-x-2">
				<Button
					type="button"
					className="hidden w-8 h-8 p-0 lg:flex"
					onClick={() => {
						setTemplateCurrentPage(1);
					}}
					disabled={!templateHasPrevPage}
				>
					<span className="sr-only">Go to first page</span>
					<ChevronsLeftIcon className="w-4 h-4 text-white" />
				</Button>
				<Button
					type="button"
					className="w-8 h-8 p-0"
					onClick={() => {
						setTemplateCurrentPage(templateCurrentPage - 1);
					}}
					disabled={!templateHasPrevPage}
				>
					<span className="sr-only">Go to previous page</span>
					<ChevronLeftIcon className="w-4 h-4 text-white" />
				</Button>
				<Button
					type="button"
					className="w-8 h-8 p-0"
					onClick={() => {
						setTemplateCurrentPage(Number(templateCurrentPage + 1));
					}}
					disabled={!templateHasNextPage}
				>
					<span className="sr-only">Go to next page</span>
					<ChevronRightIcon className="w-4 h-4 text-white" />
				</Button>
				<Button
					type="button"
					className="hidden w-8 h-8 p-0 lg:flex "
					onClick={() => {
						setTemplateCurrentPage(Number(templateTotalPages));
					}}
					disabled={!templateHasNextPage}
				>
					<span className="sr-only">Go to last page</span>
					<ChevronsRightIcon className="w-4 h-4 text-white" />
				</Button>
			</div>
		</div>
	);
};
