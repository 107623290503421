import { Input } from '../ui/input';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
	ApiResponse,
	ErrorResponse,
	ForgotPasswordPayload,
} from '@/lib/types/apiTypes';
import {
	handleApiError,
	handleGeneralError,
	handleResponse,
	handleUnexpectedError,
} from '@/lib/functions/funcUtils';
import { Label } from '../ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import { forgotPassword } from '@/lib/functions/apiCalls';
import { FC } from 'react';

const formSchema = z.object({
	email: z.string().email({
		message: 'Please enter correct email address.',
	}),
});

interface ForgotPasswordFormProps {
	setIsLoading: (isLoading: boolean) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ setIsLoading }) => {
	const navigate = useNavigate();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		values: {
			email: '',
		},
	});

	const onSubmit = async (data: ForgotPasswordPayload) => {
		try {
			setIsLoading(true);
			const response: ApiResponse = await forgotPassword({
				email: data.email,
			});
			handleResponse(response, 'Password reset link sent successfully.', 6000);
			setIsLoading(false);
			navigate('');
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
			} else {
				handleUnexpectedError();
			}
			setIsLoading(false);
		}
	};

	return (
		<Form {...form}>
			<form
				className="flex flex-col w-full gap-5 min-w-[500px]"
				onSubmit={form.handleSubmit(onSubmit)}
			>
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => {
						return (
							<FormItem className="col-span-1">
								<Label>Email Address</Label>
								<FormControl>
									<Input
										className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
										placeholder="Enter email address"
										{...field}
									></Input>
								</FormControl>
								<FormMessage />
							</FormItem>
						);
					}}
				/>
				<div className="flex justify-center">
					<div className="flex justify-center gap-5">
						<SecondaryButton
							type="reset"
							tabIndex={2}
							onClick={() => navigate('')}
						>
							BACK
						</SecondaryButton>
						<PrimaryButton
							tabIndex={1}
							type="submit"
							title="Send Email"
							className="w-[242px]"
						>
							Send Email
						</PrimaryButton>
					</div>
				</div>
			</form>
		</Form>
	);
};

export default ForgotPasswordForm;
