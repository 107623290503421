import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import React, { FC } from 'react';

interface IconButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	onClick?: () => void;
	className?: string;
}

const IconButton: FC<IconButtonProps> = ({ children, className, ...props }) => {
	return (
		<Button
			{...props}
			className={cn(
				'h-[54px] px-8 py-6 font-bold text-base text-black bg-white uppercase rounded-lg flex gap-2 hover:scale-110',
				className
			)}
		>
			{children}
		</Button>
	);
};

export default IconButton;
