import { FC } from 'react';
import styles from './LoaderFill.module.css';
import LottieLoader from '@/assets/lotties/logo_loading_v1.json';
import Lottie from 'lottie-react';

interface LoaderFillProps {}

const LoaderFill: FC<LoaderFillProps> = () => {
	return (
		<div className={styles.loader}>
			<Lottie
				className="animation-lottie"
				animationData={LottieLoader}
				loop={true}
				autoPlay={true}
			/>
		</div>
	);
};

export default LoaderFill;
