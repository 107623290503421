import TemplateAssemblerContent from '@/components/ui/icons/new/TemplateBuilder/TemplateAssemblerContent';

const SelectedTemplateMessage = () => {
	return (
		<div className="flex flex-col items-center max-w-md gap-3 mx-auto">
			<TemplateAssemblerContent />
			<h2 className="text-center text-black dynamic-text">
				Click on a section in the template assembler to set its
				<b>word count, The sections it connects to in the outline </b>
				and the <b>information required from the solicitation.</b>
			</h2>
		</div>
	);
};

export default SelectedTemplateMessage;
