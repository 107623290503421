import { FC, useEffect, useState } from 'react';
import { Input } from '../../ui/input';
import useDebounce from '@/lib/hooks/useDebounce';
import { FileX2, Search, X } from 'lucide-react';
import DocumentsEmptyUpload from '../../ui/icons/new/DocumentsEmptyUpload';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useQuery, useQueryClient } from 'react-query';
import { getDecDocuments } from '@/lib/functions/apiCalls';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import LoaderTransparent from '../../Loader/LoaderTransparent/LoaderTransparent';
import DecDocumentCard from '../DecDocumentCard/DecDocumentCard';
import { DecDocument } from '@/lib/types/apiTypes';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { countriesAndTerritories } from '@/constants/organizationalData';
import {
	Multiselect,
	SelectOptionProp,
} from '@/components/ui/shared/Multiselect/Multiselect';
import { cloneDeep } from 'lodash';
import DateRangePicker from '@/components/ui/shared/DateRangePicker/DateRangePicker';
import { DateRange } from 'react-day-picker';
import { format } from 'date-fns';

interface DecDocumentsPanelProps {}

const DecDocumentsPanel: FC<DecDocumentsPanelProps> = () => {
	const dispatch = useAppDispatch();
	const organizationId = useOrganizationId();
	const isOpen = useSelector(
		(root: RootState) => root.page.panel?.type === 'externalDocuments'
	);
	const folderId = useSelector(
		(root: RootState) => root.page.panel?.folder?.id
	);
	const queryClient = useQueryClient();

	const [date, setDate] = useState<DateRange>();
	const [locations, setLocations] = useState<SelectOptionProp[]>([]);
	// const [isQueryEmpty, setIsQueryEmpty] = useState(true);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [docs, setDocs] = useState<DecDocument[]>();

	const [searchValue, setSearchValue] = useState<string>('');
	const [isUpdating, setIsUpdating] = useState<boolean>(false);

	const handleSearchChange = (searchValue) => {
		setSearchTerm(searchValue);
	};

	const fetchDocs = async () => {
		// if (searchTerm === '') {
		// 	setIsQueryEmpty(!searchTerm);
		// 	return [];
		// }
		if (!organizationId) return;
		try {
			const response = await getDecDocuments({
				query: searchTerm || 'water',
				organizationId: organizationId,
				geographicLocations: locations.map((location) => location.value),
				...(date && date.from
					? {
							publicationStartDate: format(date.from || '', 'yyyy-MM-dd'),
						}
					: {}),
				...(date && date.to
					? {
							publicationEndDate: format(date.to || '', 'yyyy-MM-dd'),
						}
					: {}),
			});
			handleResponse(response);
			setDocs((response?.data as DecDocument[]) || []);
			// setIsQueryEmpty(!searchTerm);
			return response?.data;
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ['decDocuments', searchTerm, locations, date],
		queryFn: () => fetchDocs(),
		refetchOnWindowFocus: false,
	});

	const documents = (data as DecDocument[]) || docs;

	useEffect(() => {
		if (isOpen) {
			setSearchValue('');
			setSearchTerm('');
			// setIsQueryEmpty(searchTerm || searchValue ? false : true);
			setDate(undefined);
			setLocations([]);
			queryClient.invalidateQueries('decDocuments');
		}
	}, [isOpen]);

	const debouncedSearchChange = useDebounce(handleSearchChange, 600);

	return (
		<div className="flex flex-col h-[calc(100vh-132px)] bg-white p-4 gap-4 rounded-xl overflow-hidden ml-[3px]">
			<div className="flex justify-between w-full">
				<div className="flex w-1/3">
					<div className="relative w-full">
						<Input
							value={searchValue}
							onChange={(event) => {
								setSearchValue(event.target.value);
								debouncedSearchChange(event.target.value);
							}}
							placeholder="Search the external datasource"
							className="h-12 bg-white border-blackSecondary p-4 pr-8 dynamic-small rounded-xl hover:bg-lightBackground disabled:bg-background"
						/>
						<div className="absolute right-4 top-4">
							{searchValue !== '' ? (
								<X
									onClick={() => {
										setSearchValue('');
										debouncedSearchChange('');
									}}
									className="cursor-pointer"
									color="#5D6F79"
									size={16}
								/>
							) : (
								<Search color="#5D9BFD" size={16} />
							)}
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="flex flex-row gap-4 pr-4 border-r border-blackSecondary mr-4">
						<Multiselect
							className="!h-12 !p-2 !w-56 2xl:!w-64 dynamic-small"
							title="Select location"
							options={countriesAndTerritories}
							value={locations}
							canAddOption={true}
							isModal={true}
							onSelectedValuesChange={(values: any) => {
								setLocations(cloneDeep(values));
							}}
						></Multiselect>
						<DateRangePicker
							date={date}
							setDate={setDate}
							toDate={new Date()}
							label={'Select date range of publishing'}
							className="dynamic-small px-2"
						/>
					</div>
					<TextButton
						onClick={() => {
							setLocations([]);
							setDate(undefined);
							setSearchValue('');
							handleSearchChange('');
						}}
					>
						<FileX2 size={18} />
						Clear
					</TextButton>
				</div>
			</div>
			{/* {isQueryEmpty ? (
				<div className="flex flex-col py-12 m-auto min-h-[400px] w-[360px] gap-8 justify-center items-center">
					<DocumentsEmptyUpload />
					<h4 className="text-black text-center dynamic-xl-large">
						Search the USAID DEC from the DEC library.
					</h4>
				</div>
			) :  */}
			{!documents?.length ? (
				<div className="flex flex-col py-12 m-auto min-h-[400px] w-[360px] gap-8 justify-center items-center">
					{!isFetching && (
						<>
							<DocumentsEmptyUpload />
							<h4 className="text-black text-center dynamic-xl-large">
								No document found.
							</h4>
						</>
					)}
				</div>
			) : (
				<div className="grid grid-cols-2 xl:grid-cols-3 gap-2 pr-3 overflow-y-auto">
					{documents.map((document, index) => (
						<DecDocumentCard
							key={index}
							documentCard={document}
							folderId={folderId}
							setIsLoading={(isUpdating) => setIsUpdating(isUpdating)}
						/>
					))}
				</div>
			)}
			{(isLoading || isFetching || isUpdating) && <LoaderTransparent />}
		</div>
	);
};

export default DecDocumentsPanel;
