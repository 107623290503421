import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { findSection } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { triggerHistory } from '@/lib/observables/observables';
import { Section } from '@/lib/types/apiTypes';
import { closeDialog } from '@/redux/slices/pageSlice';
import { updateSection } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const HistoryRestoreDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'historyRestoreConfirm'
	);

	const { activeSection } = useActiveSection();
	const historyIndex = useSelector(
		(root: RootState) => root.page.dialog?.historyIndex
	);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const dispatch = useAppDispatch();

	const section = useMemo(
		() =>
			findSection(
				proposal?.sections || [],
				String(activeSection.sectionId)
			) as Section,
		[proposal?.sections, activeSection.sectionId]
	);

	const textToHtmlParagraphs = (text) => {
		return text
			.split('\n\n')
			.map((paragraph) => `<p>${paragraph}</p>`)
			.join('');
	};

	const handleRestore = () => {
		const originalText = section.generationHistory[historyIndex || 0].text;
		const htmlText = textToHtmlParagraphs(originalText);
		dispatch(
			updateSection({
				content: section.generationHistory[historyIndex || 0].text,
				editorState: htmlText,
				sectionId: activeSection.sectionId as number,
			})
		);
		triggerHistory();
		dispatch(closeDialog());
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[400px] p-8 rounded-3xl shadow-main bg-white">
				<DialogHeader>
					<DialogTitle className="font-bold dynamic-large text-secondary">
						Restore Section Content
					</DialogTitle>
					<DialogDescription className="py-3">
						Are you sure you want to restore this section? This action cannot be
						undone.
					</DialogDescription>
					<PrimaryButton onClick={handleRestore}>Restore</PrimaryButton>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default HistoryRestoreDialog;
