import { SVGProps } from 'react';

const Play = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="120"
			height="120"
			viewBox="0 0 120 120"
			fill="none"
		>
			<mask
				id="mask0_8759_12936"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="120"
				height="120"
			>
				<rect width="119.883" height="119.883" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_8759_12936)">
				<path
					d="M47.4556 82.4194L82.4214 59.9414L47.4556 37.4634V82.4194ZM59.9434 109.893C53.0334 109.893 46.5398 108.581 40.4624 105.959C34.385 103.336 29.0985 99.7775 24.6029 95.2819C20.1073 90.7863 16.5483 85.4998 13.9258 79.4224C11.3034 73.345 9.99219 66.8513 9.99219 59.9414C9.99219 53.0315 11.3034 46.5378 13.9258 40.4604C16.5483 34.3831 20.1073 29.0966 24.6029 24.601C29.0985 20.1053 34.385 16.5463 40.4624 13.9239C46.5398 11.3015 53.0334 9.99023 59.9434 9.99023C66.8533 9.99023 73.3469 11.3015 79.4243 13.9239C85.5017 16.5463 90.7882 20.1053 95.2838 24.601C99.7794 29.0966 103.338 34.3831 105.961 40.4604C108.583 46.5378 109.895 53.0315 109.895 59.9414C109.895 66.8513 108.583 73.345 105.961 79.4224C103.338 85.4998 99.7794 90.7863 95.2838 95.2819C90.7882 99.7775 85.5017 103.336 79.4243 105.959C73.3469 108.581 66.8533 109.893 59.9434 109.893ZM59.9434 99.9023C71.0991 99.9023 80.5482 96.0311 88.2906 88.2887C96.0331 80.5463 99.9043 71.0972 99.9043 59.9414C99.9043 48.7856 96.0331 39.3365 88.2906 31.5941C80.5482 23.8517 71.0991 19.9805 59.9434 19.9805C48.7876 19.9805 39.3385 23.8517 31.5961 31.5941C23.8536 39.3365 19.9824 48.7856 19.9824 59.9414C19.9824 71.0972 23.8536 80.5463 31.5961 88.2887C39.3385 96.0311 48.7876 99.9023 59.9434 99.9023Z"
					fill="white"
				/>
			</g>
		</svg>
	);
};

export default Play;
