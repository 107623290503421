const Suggestion = () => {
	return (
		<svg
			className="w-6 h-6"
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
		>
			<mask
				id="mask0_5181_11627"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="32"
				height="33"
			>
				<rect y="0.09375" width="32" height="32" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_5181_11627)">
				<path
					d="M26.7575 19.1344C26.5629 19.1349 26.3711 19.0872 26.1987 18.9951L21.789 16.629L17.3802 18.9951C17.1825 19.1012 16.9596 19.1488 16.7368 19.1324C16.514 19.1161 16.3001 19.0365 16.1193 18.9027C15.9385 18.7689 15.798 18.5861 15.7138 18.3751C15.6295 18.1641 15.6047 17.9333 15.6423 17.7087L16.4845 12.6996L12.9186 9.1504C12.7585 8.99136 12.6452 8.7898 12.5916 8.56851C12.538 8.34722 12.5462 8.11505 12.6152 7.89825C12.6842 7.68145 12.8113 7.48867 12.9822 7.34174C13.1531 7.1948 13.3608 7.09957 13.582 7.06681L18.5137 6.33402L20.7186 1.77439C20.8175 1.57007 20.9704 1.39805 21.1602 1.27777C21.3499 1.15749 21.5688 1.09375 21.7922 1.09375C22.0156 1.09375 22.2346 1.15749 22.4243 1.27777C22.6141 1.39805 22.767 1.57007 22.8659 1.77439L25.0708 6.33402L30.0017 7.06681C30.2229 7.09957 30.4306 7.1948 30.6015 7.34174C30.7723 7.48867 30.8995 7.68145 30.9685 7.89825C31.0375 8.11505 31.0457 8.34722 30.9921 8.56851C30.9384 8.7898 30.8252 8.99136 30.6651 9.1504L27.0976 12.698L27.9374 17.7087C27.9668 17.8839 27.9583 18.0636 27.9126 18.2351C27.8668 18.4067 27.7849 18.566 27.6726 18.7019C27.5602 18.8378 27.4201 18.9471 27.2621 19.0221C27.104 19.097 26.9318 19.1359 26.7575 19.136M21.7898 14.0284C21.9845 14.0284 22.1762 14.0764 22.3486 14.1684L25.1681 15.6804L24.6301 12.4789C24.597 12.2839 24.6109 12.0836 24.6705 11.8952C24.7302 11.7068 24.8339 11.5361 24.9726 11.3976L27.2548 9.12842L24.1017 8.66105C23.9096 8.63258 23.7273 8.55693 23.5703 8.44062C23.4133 8.32432 23.2863 8.17084 23.2004 7.9934L21.7898 5.0793L20.3793 7.9934C20.2933 8.17084 20.1664 8.32432 20.0094 8.44062C19.8524 8.55693 19.6701 8.63258 19.478 8.66105L16.3248 9.12842L18.6071 11.3976C18.7461 11.5359 18.8501 11.7066 18.91 11.895C18.9699 12.0833 18.9841 12.2837 18.9512 12.4789L18.4131 15.6804L21.2326 14.1684C21.4051 14.0764 21.5968 14.0284 21.7914 14.0284"
					fill="#E861C9"
				/>
				<path
					d="M21.5136 18.8713V22.1697H16.989C16.8193 22.1694 16.6516 22.2073 16.4979 22.2806C16.3442 22.3539 16.2082 22.4609 16.0997 22.594L12.4316 27.0722L8.74516 22.594C8.63645 22.4616 8.50063 22.3552 8.34727 22.2822C8.19391 22.2092 8.02672 22.1714 7.85748 22.1714H3.33844V11.2152H12.3997L10.3241 8.854H2.18253C1.87567 8.854 1.58134 8.97811 1.36406 9.19912C1.14677 9.42013 1.02428 9.72001 1.02344 10.033V23.352C1.02344 23.5072 1.05342 23.661 1.11167 23.8044C1.16992 23.9479 1.2553 24.0782 1.36293 24.188C1.47056 24.2977 1.59834 24.3848 1.73897 24.4442C1.87959 24.5037 2.03032 24.5342 2.18253 24.5342H7.31704L11.5431 29.6711C11.652 29.8034 11.788 29.9098 11.9415 29.9828C12.095 30.0558 12.2622 30.0937 12.4316 30.0937C12.6005 30.0929 12.7673 30.0546 12.9202 29.9813C13.0731 29.908 13.2084 29.8016 13.3169 29.6695L17.5246 24.5342H22.6727C22.9801 24.5342 23.2749 24.4097 23.4923 24.188C23.7097 23.9663 23.8318 23.6655 23.8318 23.352V20.6447L21.5136 18.8713Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Suggestion;
