import api from '@/lib/api';
import { ApiResponse } from '@/lib/types/apiTypes';
import {
	AISectionRequest,
	DecSemanticSearch,
	DecService,
	SectionApiResponse,
	SectionBuilder,
	SectionParams,
	SectionPublish,
	TagApiResponse,
	TagField,
	TagFieldParams,
	TemplateApiResponse,
	TemplateLibrary,
	TemplateLibraryParams,
	TemplatePublish,
	TemplateSemanticSearch,
} from '@/lib/types/TemplateBuilder/templateTypes';

/**
 * Retrieves all sections from the API.
 *
 * @return {Promise<SectionBuilder[]>} An array of section objects.
 */
export const getAllSections = async (
	params: SectionParams
): Promise<SectionApiResponse> => {
	const filterParams: any = {
		pageSize: params.pageSize || 10,
		pageNumber: params.pageNumber || 1,
		sort: params.sort || 'desc',
		search: params.search || '',
		isLive: params.isLive || 'none',
		organizationIdentifier: params.organizationIdentifier || null,
		publishType: params.publishType || 'all',
		tag: params.tag && params.tag.length > 0 ? params.tag : '',
		filter: params.filter,
	};
	const queryString = new URLSearchParams(filterParams).toString();
	const response = await api.get(`/api/template/section?${queryString}`);
	return response.data;
};

export const getSectionById = async (id: string): Promise<SectionBuilder> => {
	const response = await api.get(`/api/template/section/${id}`);
	return response.data.data;
};
export const createSection = async (
	data: SectionBuilder
): Promise<ApiResponse> => {
	const response = await api.post<ApiResponse>(`/api/template/section`, data);
	return response.data;
};
export const updateSection = async (
	data: SectionBuilder,
	id: string | undefined
): Promise<ApiResponse> => {
	if (id) {
		const response = await api.put<ApiResponse>(
			`/api/template/section/${id}`,
			data
		);
		return response.data;
	} else {
		return {
			statusCode: 400,
			message: 'Something went wrong',
			data: null,
		};
	}
};
export const publishSection = async (
	data: SectionPublish,
	id: string
): Promise<ApiResponse> => {
	if (id) {
		const response = await api.put<ApiResponse>(
			`/api/section/publishSection/${id}`,
			data
		);
		return response.data;
	} else {
		return {
			statusCode: 400,
			message: 'Something went wrong',
			data: null,
		};
	}
};
export const deleteSection = async (id: string): Promise<ApiResponse> => {
	if (id) {
		const response = await api.delete<ApiResponse>(
			`/api/template/section/${id}`
		);
		return response.data;
	} else {
		return {
			statusCode: 400,
			message: 'Something went wrong',
			data: null,
		};
	}
};
export const autoCompelteSection = async (
	data: AISectionRequest
): Promise<ApiResponse> => {
	const response = await api.post<ApiResponse>(
		`/api/ai/template-section/auto-complete`,
		data
	);
	return response.data;
};

/**
 * Retrieves all templates from the API.
 *
 * @return {Promise<TemplateLibrary[]>} An array of section objects.
 */
export const fetchAllTemplates = async (
	params: TemplateLibraryParams
): Promise<TemplateApiResponse> => {
	const filterParams: any = {
		pageSize: params.pageSize || 10,
		pageNumber: params.pageNumber || 1,
		sort: params.sort || 'desc',
		search: params.search || '',
		isLive: params.isLive || 'none',
		organizationIdentifier: params.organizationIdentifier || null,
		tag: params.tag && params.tag.length > 0 ? params.tag : '',
		filter: params.filter,
	};
	const queryString = new URLSearchParams(filterParams).toString();
	const response = await api.get(`/api/v2/template?${queryString}`);
	return response.data;
};

export const getTemplateById = async (
	id: string | undefined
): Promise<ApiResponse> => {
	if (id) {
		const response = await api.get(`/api/v2/template/${id}`);
		return response.data;
	} else {
		return {
			statusCode: 400,
			message: 'Something went wrong',
			data: null,
		};
	}
};
export const createTemplate = async (
	data: TemplateLibrary
): Promise<ApiResponse> => {
	const response = await api.post<ApiResponse>(`/api/v2/template`, data);
	return response.data;
};
export const updateTemplate = async (
	data: TemplateLibrary,
	id: string | undefined
): Promise<ApiResponse> => {
	if (id) {
		const response = await api.put<ApiResponse>(`api/v2/template/${id}`, data);
		return response.data;
	} else {
		return {
			statusCode: 400,
			message: 'Something went wrong',
			data: null,
		};
	}
};
export const deleteTemplate = async (id: string): Promise<ApiResponse> => {
	if (id) {
		const response = await api.delete<ApiResponse>(`/api/template/${id}`);
		return response.data;
	} else {
		return {
			statusCode: 400,
			message: 'Something went wrong',
			data: null,
		};
	}
};

export const publishTemplate = async (
	data: TemplatePublish,
	id: string | undefined
): Promise<ApiResponse> => {
	if (id) {
		const response = await api.put<ApiResponse>(
			`/api/v2/template/${id}/publish`,
			data
		);
		return response.data;
	} else {
		return {
			statusCode: 400,
			message: 'Something went wrong',
			data: null,
		};
	}
};
export const getRecommendedTemplates = async (
	query: TemplateSemanticSearch
): Promise<ApiResponse> => {
	const response = await api.post(`/api/template/recommend`, query);
	return response.data;
};
/**
 * Retrieves all tags from the API.
 *
 * @return {Promise<TemplateLibrary[]>} An array of section objects.
 */
export const fetchAllTags = async (
	params: TagFieldParams
): Promise<TagApiResponse> => {
	const filterParams: any = {
		pageSize: params.pageSize || 10,
		pageNumber: params.pageNumber || 1,
		sort: params.sort || 'desc',
		search: params.search || '',
		organizationIdentifier: params.organizationIdentifier || null,
	};
	const queryString = new URLSearchParams(filterParams).toString();
	const response = await api.get(`/api/template/tag?${queryString}`);
	return response.data;
};

export const getTagById = async (
	id: string | undefined
): Promise<ApiResponse> => {
	const response = await api.get(`/api/tag/getTagById/${id}`);
	return response.data;
};
export const createUpdateTag = async (data: TagField): Promise<ApiResponse> => {
	const response = await api.post<ApiResponse>(`api/template/tag`, data);
	return response.data;
};
export const updateTag = async (
	data: TagField,
	id: string | undefined
): Promise<ApiResponse> => {
	if (id) {
		const response = await api.put<ApiResponse>(
			`/api/tag/updateTag/${id}`,
			data
		);
		return response.data;
	} else {
		return {
			statusCode: 400,
			message: 'Something went wrong',
			data: null,
		};
	}
};
export const deleteTag = async (id: string): Promise<ApiResponse> => {
	const response = await api.delete<ApiResponse>(`/api/tag/deleteTag/${id}`);
	return response.data;
};

export const getTagByOrganization = async (
	identifier: string | undefined
): Promise<ApiResponse> => {
	const response = await api.get(`/api/template/tag/${identifier}`);
	return response.data;
};

export const decSemanticSearch = async (
	query: DecSemanticSearch
): Promise<DecService> => {
	const response = await api.post(`/api/dec/search`, query);
	return response.data;
};
