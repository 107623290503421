import { FC, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { Section, Template } from '@/lib/types/apiTypes';
import { Skeleton } from '@/components/ui/skeleton';
import { Card } from '@/components/ui/card';
import {
	SortableTreeWithExternalManager,
	toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree';
import CustomThemeNodeContentRenderer from '@/components/TemplateBuilder/TemplateDnd/NodeRenderer';
import { DndContext } from 'react-dnd';
import { SectionBuilder } from '@/lib/types/TemplateBuilder/templateTypes';
import {
	RecommendedTemplatesSectionTitle,
	RecommendedTemplatesMatchedSectionIcon,
} from '@/components/TemplateBuilder/TemplateDnd/GenerateNodeProps';
import { findMatchedSections } from '@/lib/functions/TemplateBuilder/templateUtils';
import DocumentLock from '@/components/ui/icons/DocumentLock';

interface ProposalStructureProps {
	sectionNames?: string[];
	sections?: Section[];
	agency?: string;
	template?: Template;
	className?: string;
	pageTransitionLoad?: boolean;
	maxCharacter?: number;
}

const ProposalStructure: FC<ProposalStructureProps> = ({
	sections,
	template,
	className,
	pageTransitionLoad = false,
}) => {
	const [treeData, setTreeData] = useState<SectionBuilder[]>([]);

	useEffect(() => {
		const expandedTree = toggleExpandedForAll({
			treeData: sections,
		});
		const newTreeData = [...expandedTree];
		const matchedSections =
			(template as any)?.recommendationMetrics?.matchedSections || [];
		newTreeData.forEach((node) => findMatchedSections(node, matchedSections));
		setTreeData(newTreeData as SectionBuilder[]);
	}, [sections]);

	return (
		<Card
			className={cn(
				'template-outline relative h-[400px] border-none shadow-none',
				className
			)}
		>
			{pageTransitionLoad ? (
				<SectionComponentSkeleton />
			) : template ? (
				<div className="flex flex-col p-6 h-full w-full gap-4 overflow-y-auto bg-[#EEF2F9] rounded-xl border border-[rgba(185,212,255,0.40)]">
					<h2 className="font-bold text-black dynamic-medium">
						Template Outline
					</h2>
					<div className="light-scroll2 overflow-y-auto h-full cursor-ga">
						<DndContext.Consumer>
							{({ dragDropManager }) =>
								!dragDropManager ? undefined : (
									<SortableTreeWithExternalManager
										dragDropManager={dragDropManager}
										treeData={treeData}
										onChange={setTreeData}
										dndType={'proposalSections'}
										maxDepth={0}
										canDrag={() => false}
										className="dynamic-text font-bold text-primary"
										style={{ height: '100%' }}
										generateNodeProps={({ node }) => ({
											buttons: [
												<RecommendedTemplatesMatchedSectionIcon
													sections={node}
												/>,
											],
											title: <RecommendedTemplatesSectionTitle node={node} />,
										})}
										nodeContentRenderer={(nodeInfo) => (
											<CustomThemeNodeContentRenderer
												{...nodeInfo}
												{...nodeInfo.nodeProps} // Access nodeProps within the callback
												showWordCount={false}
												dndType={'proposalSections'}
											/>
										)}
									/>
								)
							}
						</DndContext.Consumer>
					</div>
				</div>
			) : (
				<div className="flex flex-col items-center justify-center w-full h-full gap-4 bg-[#EEF2F9] rounded-xl border border-[rgba(185,212,255,0.40)]">
					<DocumentLock type="enabled" />
					<p className="pb-10 font-medium text-black dynamic-large">
						Click a template to preview its structure
					</p>
				</div>
			)}
		</Card>
	);
};

export default ProposalStructure;

const SectionComponentSkeleton: React.FC = () => {
	return (
		<div className="relative mb-8 bg-[#EEF2F9] rounded-xl border border-[rgba(185,212,255,0.40)] p-6">
			<div className="p-3 rounded-[30px] mb-4 bg-slate-300">
				<Skeleton className="h-5 w-[150px] bg-slate-50" />
			</div>
			<div className="ml-4">
				<div className="relative pl-8 mb-8">
					<div className="p-3 rounded-[30px] mb-4 bg-slate-300">
						<Skeleton className="h-5 w-[150px] bg-slate-50" />
					</div>
					<div className="ml-4">
						<div className="relative pl-8 mb-8">
							<div className="p-3 rounded-[30px] mb-4 bg-slate-300">
								<Skeleton className="h-5 w-[150px] bg-slate-50" />
							</div>
						</div>
						<div className="relative pl-8 mb-8">
							<div className="p-3 rounded-[30px] mb-4 bg-slate-300">
								<Skeleton className="h-5 w-[150px] bg-slate-50" />
							</div>
						</div>
					</div>
				</div>
				<div className="relative pl-8 mb-8">
					<div className="p-3 rounded-[30px] mb-4 bg-slate-300">
						<Skeleton className="h-5 w-[150px] bg-slate-50" />
					</div>
					<div className="ml-4">
						<div className="relative pl-8 mb-8">
							<div className="p-3 rounded-[30px] mb-4 bg-slate-300">
								<Skeleton className="h-5 w-[150px] bg-slate-50" />
							</div>
						</div>
					</div>
				</div>
				<div className="relative pl-8 mb-8">
					<div className="p-3 rounded-[30px] mb-4 bg-slate-300">
						<Skeleton className="h-5 w-[150px] bg-slate-50" />
					</div>
				</div>
				<div className="relative pl-8 mb-8">
					<div className="p-3 rounded-[30px] mb-4 bg-slate-300">
						<Skeleton className="h-5 w-[150px] bg-slate-50" />
					</div>
				</div>
			</div>
		</div>
	);
};
