import { FC, useState } from 'react';
import styles from './CitationBoxItem.module.css';
import { cn } from '@/lib/utils';
import Edit from '../ui/icons/new/Edit';
import Delete from '../ui/icons/new/Delete';
import { Input } from '../ui/input';
import Save from '../ui/icons/new/Save';

interface CitationBoxItemProps {
	cite: string;
	handleEdit: (index: number, newCite: string) => void;
	handleDelete: (index: number) => void;
	index: number;
}

const CitationBoxItem: FC<CitationBoxItemProps> = ({
	cite,
	handleEdit,
	handleDelete,
	index,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [editedCite, setEditedCite] = useState(cite);
	const maxChars = 600; // Maximum character limit

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newCite = e.target.value.slice(0, maxChars); // Ensures input doesn't exceed maxChars
		setEditedCite(newCite);
	};

	const startEditing = () => {
		setIsEditing(true);
	};

	const saveEdit = () => {
		if (editedCite !== cite) {
			handleEdit(index, editedCite);
		}
		setIsEditing(false);
	};

	const cancelEdit = () => {
		setEditedCite(cite);
		setIsEditing(false);
	};

	return (
		<li
			className={cn(
				styles.list,
				`text-black flex items-center justify-between`,
				isEditing ? styles.editableCite : null
			)}
		>
			{isEditing ? (
				<Input
					className="h-4 p-0 !text-base text-black bg-transparent border-none focus-visible:ring-0 focus-visible:ring-offset-0"
					value={editedCite}
					onChange={handleInputChange}
					onBlur={saveEdit}
					autoFocus
					maxLength={maxChars}
				/>
			) : (
				<span onClick={startEditing} className="text-base">
					{cite}
				</span>
			)}
			<div className="flex items-center gap-3 ml-2 bg-white">
				{isEditing ? (
					<>
						<span className="text-xs text-gray-400">
							{maxChars - editedCite.length}/{maxChars}
						</span>
						<div className="cursor-pointer" onClick={cancelEdit}>
							<Save />
						</div>
					</>
				) : (
					<div className="cursor-pointer" onClick={startEditing}>
						<Edit />
					</div>
				)}
				<div className="cursor-pointer" onClick={() => handleDelete(index)}>
					<Delete />
				</div>
			</div>
		</li>
	);
};

export default CitationBoxItem;
