import { FC } from 'react';
// import { FC, useEffect, useState } from 'react';
import styles from './LoaderProcess.module.css';
import { cn } from '@/lib/utils';
import DocumentUploadRFP from '@/assets/lotties/document-upload-RFP.json';
import Lottie from 'lottie-react';

interface LoaderProcessProps {}

const LoaderProcess: FC<LoaderProcessProps> = () => {
	return (
		<div
			className={cn(styles.loader, 'flex flex-col items-center justify-center')}
		>
			<Lottie
				className="animation-lottie relative top-[-1.5rem]"
				animationData={DocumentUploadRFP}
				loop={true}
				autoPlay={true}
			/>
		</div>
	);
};

export default LoaderProcess;
