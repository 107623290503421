import { FC } from 'react';
import SideBar from './components/shared/SideBar/SideBar';
import styles from './Layout.module.css';
import { cn } from './lib/utils';
import { Outlet, useLocation } from 'react-router';
import { Toaster } from '@/components/ui/toaster';
import TrialBanner from './components/TrialBanner/TrialBanner';

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => {
	const location = useLocation();
	const isDesign = location.pathname.includes('design');
	const isWrite = location.pathname.includes('write');
	const isDashboard = location.pathname.includes('proposal');
	const isManage = location.pathname.includes('manage');
	const isAccount = location.pathname.includes('account');
	const isSolicitation = location.pathname.includes('/');

	return (
		<main>
			<TrialBanner />
			<div
				className={cn(
					styles.wrapper,
					isDesign ||
						isWrite ||
						isDashboard ||
						isManage ||
						isAccount ||
						isSolicitation
						? 'pt-4'
						: 'pt-10'
				)}
			>
				<div className={cn(styles.sidebar, '')}>
					<SideBar />
				</div>
				<div className={styles.container}>
					<Outlet />
				</div>
			</div>

			<Toaster />
		</main>
	);
};

export default Layout;
