import { cn } from '@/lib/utils';
// Placeholder thumbnail for template global sections

export const TemplateGlobalSectionsSkeleton = ({ className = '' }) => {
	return (
		<div className={cn(`flex flex-col gap-5 justify-center`, className)}>
			<h2 className="font-bold text-center text-black dynamic-medium">
				No Sections Found
			</h2>
		</div>
	);
};
