import { CheckIcon, ChevronsUpDown } from 'lucide-react';
import * as React from 'react';
import * as RPNInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command';
import { Input, InputProps } from '@/components/ui/input';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';

import { cn } from '@/lib/utils';

type PhoneInputProps = Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	'onChange' | 'value'
> &
	Omit<RPNInput.Props<typeof RPNInput.default>, 'onChange'> & {
		onChange?: (value: RPNInput.Value) => void;
	};

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> =
	React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
		({ className, onChange, ...props }, ref) => {
			return (
				<RPNInput.default
					ref={ref}
					className={cn('flex', className)}
					flagComponent={FlagComponent}
					countrySelectComponent={CountrySelect}
					inputComponent={InputComponent}
					/**
					 * Handles the onChange event.
					 *
					 * react-phone-number-input might trigger the onChange event as undefined
					 * when a valid phone number is not entered. To prevent this,
					 * the value is coerced to an empty string.
					 *
					 * @param {E164Number | undefined} value - The entered value
					 */
					onChange={(value) => onChange?.(value as any)}
					{...props}
				/>
			);
		}
	);
PhoneInput.displayName = 'PhoneInput';

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, ...props }, ref) => (
		<Input
			type="tel"
			className={cn(
				'bg-white cursor-caret border-[#D3DDE2] h-12 p-4 rounded-e-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] rounded-s-none',
				className
			)}
			{...props}
			ref={ref}
		/>
	)
);
InputComponent.displayName = 'InputComponent';

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
	disabled?: boolean;
	value: RPNInput.Country;
	onChange: (value: RPNInput.Country) => void;
	options: CountrySelectOption[];
};

const CountrySelect = ({
	disabled,
	value,
	onChange,
	options,
}: CountrySelectProps) => {
	const handleSelect = React.useCallback(
		(country: RPNInput.Country) => {
			onChange(country);
		},
		[onChange]
	);

	return (
		<Popover modal={true}>
			<PopoverTrigger asChild>
				<Button
					type="button"
					variant={'outline'}
					className={cn(
						'flex gap-1 rounded-e-none rounded-s-xl px-3 bg-white cursor-caret border border-[#D3DDE2] h-12 p-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] cursor-pointer'
					)}
					disabled={disabled}
				>
					<FlagComponent country={value} countryName={value} />
					<ChevronsUpDown
						className={cn(
							'-mr-2 h-4 w-4 opacity-50',
							disabled ? 'hidden' : 'opacity-100'
						)}
					/>
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[300px] p-0">
				<Command>
					<CommandList className="rounded-[30px] bg-white overflow-hidden  pb-4">
						<CommandInput placeholder="Search country..." />
						<CommandEmpty className="text-black">
							No country found.
						</CommandEmpty>
						<CommandGroup className="max-h-[240px] overflow-auto overflow-x-hidden">
							{options
								.filter((x) => x.value)
								.map((option) => (
									<CommandItem
										className="gap-2 text-black"
										key={option.value}
										onSelect={() => handleSelect(option.value)}
									>
										<FlagComponent
											country={option.value}
											countryName={option.label}
										/>
										<span className="flex-1 text-sm">{option.label}</span>
										{option.value && (
											<span className="text-foreground/50 text-sm">
												{`+${RPNInput.getCountryCallingCode(option.value)}`}
											</span>
										)}
										<CheckIcon
											className={cn(
												'ml-auto h-4 w-4',
												option.value === value ? 'opacity-100' : 'opacity-0'
											)}
										/>
									</CommandItem>
								))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
	const Flag = flags[country];

	return (
		<span className="bg-foreground/20 flex h-4 w-6 overflow-hidden rounded-sm">
			{Flag && <Flag title={countryName} />}
		</span>
	);
};
FlagComponent.displayName = 'FlagComponent';

export { PhoneInput };
