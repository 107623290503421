import { Message } from '@/lib/types/apiTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BrainStormState {
	queries: Record<number, Message[]>;
}

const initialState: BrainStormState = {
	queries: {},
};

const brainStormSlice = createSlice({
	name: 'brainStorm',
	initialState,
	reducers: {
		clearBrainStorm(state) {
			state.queries = {};
		},
		addUserMessage(
			state,
			action: PayloadAction<{ content: string; sectionId: number }>
		) {
			const { content, sectionId } = action.payload;
			const newMessage: Message = {
				content,
				role: 'user',
				sectionId,
			};
			if (!state.queries[sectionId]) {
				state.queries[sectionId] = [];
			}
			state.queries[sectionId].push(newMessage);
		},
		addAIResponse(
			state,
			action: PayloadAction<{ content: string; sectionId: number }>
		) {
			const { content, sectionId } = action.payload;
			const newMessage: Message = {
				content,
				role: 'assistant',
				sectionId,
			};
			if (!state.queries[sectionId]) {
				state.queries[sectionId] = [];
			}
			state.queries[sectionId].push(newMessage);
		},
		resetQuery(state, action: PayloadAction<number>) {
			const sectionId = action.payload;
			state.queries[sectionId] = [];
		},
	},
});

export const { addUserMessage, addAIResponse, resetQuery, clearBrainStorm } =
	brainStormSlice.actions;
export default brainStormSlice.reducer;
