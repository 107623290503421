const ClipboardNotFound = () => {
	return (
		<svg
			width="221"
			height="116"
			viewBox="0 0 221 116"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_14692_16805)">
				<path
					d="M149.62 12.5547H10.6104C5.93806 12.5547 2.15039 16.3424 2.15039 21.0147V105.375C2.15039 110.047 5.93806 113.835 10.6104 113.835H149.62C154.293 113.835 158.08 110.047 158.08 105.375V21.0147C158.08 16.3424 154.293 12.5547 149.62 12.5547Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="2.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.8008 28.4443L135.501 28.4043"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.8398 41.1048L126.08 40.9648"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.8496 53.6141L138.33 53.4141"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.8008 68.4033L121.761 68.3633"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.8398 81.0541L126.08 80.9141"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.8496 93.575L84.4196 93.375"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M95.8609 75.2445L101.461 78.3045L95.8609 81.3745L92.8009 86.9745L89.7309 81.3745L84.1309 78.3045L89.7309 75.2445L92.8009 69.6445L95.8609 75.2445Z"
					fill="#AFC1DB"
					stroke="white"
					strokeWidth="4.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M70.9096 44.4338L80.4996 49.6737L70.9096 54.9137L65.6696 64.4938L60.4296 54.9137L50.8496 49.6737L60.4296 44.4338L65.6696 34.8438L70.9096 44.4338Z"
					fill="#AFC1DB"
					stroke="white"
					strokeWidth="4.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M213.76 1.91406H157.66C154.545 1.91406 152.02 4.43918 152.02 7.55406V27.0241C152.02 30.1389 154.545 32.6641 157.66 32.6641H213.76C216.874 32.6641 219.4 30.1389 219.4 27.0241V7.55406C219.4 4.43918 216.874 1.91406 213.76 1.91406Z"
					fill="white"
					stroke="#5E92FF"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M164.359 12.3945H207.049"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M164.359 22.1836H195.819"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M202.03 40.0645H153.3C150.185 40.0645 147.66 42.5896 147.66 45.7045V61.8145C147.66 64.9293 150.185 67.4545 153.3 67.4545H202.03C205.145 67.4545 207.67 64.9293 207.67 61.8145V45.7045C207.67 42.5896 205.145 40.0645 202.03 40.0645Z"
					fill="white"
					stroke="#5E92FF"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M158.66 49.4043H196.68"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M158.66 58.125H186.68"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M180.06 75.8535H144.06C140.945 75.8535 138.42 78.3786 138.42 81.4935V91.7935C138.42 94.9084 140.945 97.4335 144.06 97.4335H180.06C183.175 97.4335 185.7 94.9084 185.7 91.7935V81.4935C185.7 78.3786 183.175 75.8535 180.06 75.8535Z"
					fill="white"
					stroke="#5E92FF"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M147.08 83.2031H177.04"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M147.08 90.0742H169.16"
					stroke="#AFC1DB"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14692_16805">
					<rect
						width="219.5"
						height="114.17"
						fill="white"
						transform="translate(0.900391 0.914062)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default ClipboardNotFound;
