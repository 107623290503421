import { FC, useState } from 'react';
import TemplateView from '../Views/TemplateView/TemplateView';
// import NameView from '../Views/NameView/NameView';
import ApproachView from '../Views/ApproachView/ApproachView';
import SetupView from '../Views/SetupView/SetupView';
import { RFP, Template } from '@/lib/types/apiTypes';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import ToneView from '../Views/ToneView/ToneView';

interface ProposalCreationBodyProps {
	type: 'setup' | 'template' | 'tone' | 'approach' | 'name';
	currentStep: number;
	viewRFP?: () => void;
	rfp?: RFP;
	template?: Template;
	pageTransitionLoad?: boolean;
}

const ProposalCreationBody: FC<ProposalCreationBodyProps> = ({
	type,
	currentStep,
	viewRFP,
	rfp,
	template,
	pageTransitionLoad = true,
}) => {
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const currentNofo = useSelector(
		(root: RootState) => root.question.currentQuestion?.id
	);

	switch (type) {
		case 'setup':
			return (
				<SetupView
					currentStep={currentStep}
					data={rfp as RFP}
					viewRFP={viewRFP}
				/>
			);
		case 'approach':
			return (
				<ApproachView
					currentStep={currentStep}
					currentNofo={currentNofo as number}
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
					pageTransitionLoad={pageTransitionLoad}
				/>
			);
		case 'tone':
			return <ToneView currentStep={currentStep} />;
		// case 'name':
		// 	return (
		// 		<NameView
		// 			proposalName={proposalName}
		// 			setProposalName={setProposalName}
		// 			currentStep={currentStep}
		// 			pageTransitionLoad={pageTransitionLoad}
		// 		/>
		// 	);
		case 'template':
			return (
				<TemplateView
					selectedTemplate={template as Template}
					templateTags={template?.tags as string[]}
					rfp={rfp}
					pageTransitionLoad={pageTransitionLoad}
				/>
			);
		default:
			return null;
	}
};

export default ProposalCreationBody;
