import { SVGProps } from 'react';

const Add = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
		>
			<mask
				id="mask0_6257_12548"
				mask-type="luminance"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="19"
				height="19"
			>
				<path d="M18.7868 0.236328H0V18.2363H18.7868V0.236328Z" fill="white" />
			</mask>
			<g mask="url(#mask0_6257_12548)">
				<path
					d="M11.6164 18.2363H3.0825C2.26533 18.2351 1.48197 17.9103 0.904145 17.333C0.326321 16.7558 0.00117978 15.9732 0 15.1568L0 3.31526C0.00118017 2.49893 0.326341 1.71638 0.904184 1.1392C1.48203 0.562029 2.26539 0.237338 3.0825 0.236328L11.6164 0.236328C12.4334 0.237339 13.2167 0.56205 13.7945 1.13924C14.3722 1.71643 14.6972 2.49899 14.6982 3.31526V7.81876C14.6982 8.04549 14.6081 8.26293 14.4476 8.42324C14.2871 8.58356 14.0695 8.67363 13.8426 8.67363C13.6156 8.67363 13.398 8.58356 13.2375 8.42324C13.077 8.26293 12.9869 8.04549 12.9869 7.81876V3.31526C12.9864 2.95228 12.8418 2.60432 12.5849 2.34765C12.328 2.09099 11.9797 1.94657 11.6164 1.94607H3.0825C2.71911 1.94657 2.37075 2.09097 2.11374 2.34761C1.85673 2.60426 1.71204 2.95222 1.71137 3.31526V15.1549C1.71187 15.518 1.85649 15.8661 2.11352 16.1229C2.37055 16.3797 2.719 16.5242 3.0825 16.5247H11.6164C11.9798 16.5242 12.3282 16.3797 12.5851 16.1229C12.8421 15.8661 12.9865 15.5179 12.9869 15.1549V14.0409C12.9869 13.8142 13.077 13.5967 13.2375 13.4364C13.398 13.2761 13.6156 13.186 13.8426 13.186C14.0695 13.186 14.2871 13.2761 14.4476 13.4364C14.6081 13.5967 14.6982 13.8142 14.6982 14.0409V15.1568C14.6972 15.9731 14.3722 16.7557 13.7945 17.333C13.2168 17.9103 12.4335 18.2351 11.6164 18.2363Z"
					fill="#5D9BFD"
				/>
				<path
					d="M17.9221 11.5612H7.91892C7.75015 11.5615 7.58507 11.5119 7.44448 11.4186C7.30389 11.3253 7.19408 11.1925 7.12888 11.037C7.06368 10.8815 7.04601 10.7101 7.07809 10.5446C7.11018 10.3791 7.19058 10.2267 7.30918 10.1067L10.1464 7.22386C10.2247 7.14183 10.3185 7.07617 10.4225 7.03072C10.5264 6.98526 10.6384 6.96092 10.7519 6.95909C10.8653 6.95727 10.978 6.97802 11.0834 7.02011C11.1887 7.06221 11.2846 7.12482 11.3655 7.2043C11.4464 7.28378 11.5107 7.37854 11.5546 7.48307C11.5985 7.58761 11.6212 7.69982 11.6213 7.81318C11.6214 7.92654 11.5989 8.03879 11.5552 8.14339C11.5114 8.24799 11.4473 8.34285 11.3665 8.42246L9.95969 9.85149H17.924C18.1509 9.85149 18.3686 9.94155 18.529 10.1019C18.6895 10.2622 18.7797 10.4796 18.7797 10.7064C18.7797 10.9331 18.6895 11.1505 18.529 11.3108C18.3686 11.4712 18.1509 11.5612 17.924 11.5612"
					fill="#5D9BFD"
				/>
				<path
					d="M10.764 14.3474C10.5394 14.3476 10.3237 14.2593 10.1638 14.1017L7.32853 11.3149C7.17777 11.154 7.09507 10.941 7.09772 10.7206C7.10036 10.5002 7.18814 10.2893 7.34273 10.132C7.49731 9.97465 7.70675 9.88308 7.9273 9.87637C8.14786 9.86967 8.36249 9.94834 8.52636 10.096L11.3642 12.8834C11.4852 13.0024 11.568 13.1547 11.6021 13.3208C11.6362 13.487 11.6201 13.6596 11.5557 13.8165C11.4913 13.9735 11.3817 14.1078 11.2407 14.2023C11.0997 14.2969 10.9338 14.3474 10.764 14.3474Z"
					fill="#5D9BFD"
				/>
				<path
					d="M4.23749 5.37828H4.22475L0.841522 5.3299C0.614581 5.32821 0.397606 5.23653 0.23833 5.07501C0.0790529 4.9135 -0.00947855 4.69539 -0.00778875 4.46866C-0.00609894 4.24194 0.0856737 4.02517 0.24734 3.86604C0.409007 3.70692 0.627324 3.61847 0.854265 3.62016H0.867645L3.38181 3.65899V1.09247C3.38181 0.865408 3.4721 0.647644 3.63281 0.487087C3.79352 0.326529 4.01149 0.236328 4.23877 0.236328C4.46605 0.236328 4.68402 0.326529 4.84473 0.487087C5.00544 0.647644 5.09572 0.865408 5.09572 1.09247V4.52404C5.09564 4.63647 5.07336 4.74777 5.03016 4.85158C4.98696 4.95539 4.92369 5.04967 4.84398 5.12902C4.76426 5.20837 4.66965 5.27122 4.56558 5.314C4.46152 5.35677 4.35002 5.37861 4.23749 5.37828Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Add;
