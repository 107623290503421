// authSlice.ts
import { Question } from '@/lib/types/apiTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
	error: string | null;
	isLoading: boolean;
	questions: Question[];
	currentQuestion: Question | null;
	questionStep: number;
	questionStreaming: boolean;
}

const initialState: AuthState = {
	isLoading: false,
	questions: [],
	error: null,
	currentQuestion: null,
	questionStep: 0,
	questionStreaming: true,
};

const authSlice = createSlice({
	name: 'question',
	initialState,
	reducers: {
		setQuestions(state, action: PayloadAction<Question[]>) {
			state.questions = action.payload;
		},
		setCurrentStepQuestion(state, action: PayloadAction<Question>) {
			state.currentQuestion = action.payload;
		},
		nextNofo(state) {
			state.questionStep = state.questionStep + 1;
		},
		setQuestionStreaming(state, action: PayloadAction<boolean>) {
			state.questionStreaming = action.payload;
		},
		clearQuestion(state) {
			state.questions = [];
			state.error = null;
			state.currentQuestion = null;
			state.questionStep = 0;
			state.questionStreaming = true;
		},
	},
});

export const {
	setQuestions,
	setCurrentStepQuestion,
	nextNofo,
	setQuestionStreaming,
	clearQuestion,
} = authSlice.actions;
export default authSlice.reducer;
