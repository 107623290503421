import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Step } from 'intro.js-react';

interface TourState {
	run: boolean;
	steps: Step[];
	stepIndex?: number;
}

const initialState: TourState = {
	run: false,
	steps: [],
	stepIndex: 0,
};

export const tourSlice = createSlice({
	name: 'tour',
	initialState,
	reducers: {
		resetTour: (state) => {
			state.run = false;
			state.steps = [];
			state.stepIndex = 0;
		},
		setTour: (state, action: PayloadAction<Step[]>) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.steps = action.payload;
		},
		startTour: (state) => {
			state.run = true;
		},
		stopTour: (state) => {
			state.run = false;
		},
	},
});

export const { resetTour, setTour, startTour, stopTour } = tourSlice.actions;
export default tourSlice.reducer;
