interface DocumentLockProps {
	type?: 'disabled' | 'enabled';
}

const DocumentLock = ({ type = 'disabled' }: DocumentLockProps) => {
	const fill = type === 'disabled' ? '#B9D4FF' : '#EAF1FC';
	const stroke = type === 'disabled' ? '#ffffff' : '#5D9BFD';
	return (
		<svg
			width="132"
			height="171"
			viewBox="0 0 132 171"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="1"
				y="1"
				width="130"
				height="169"
				rx="9"
				fill={fill}
				stroke="#5D9BFD"
				strokeWidth="2"
			/>
			<path
				d="M20 31H112"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M20 57H112"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M20 83H112"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M20 109H112"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M20 135H99"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default DocumentLock;
