import { FC, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { openDialog } from '@/redux/slices/pageSlice';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { AccountProfileImage } from '@/components/AccountProfileImage/AccountProfileImage';
import { ArrowLeft } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { User, UserProfilePayload } from '@/lib/types/apiTypes';
import { updateUser, updateUserProfile } from '@/lib/functions/apiCalls';
import {
	clearState,
	ErrorHandle,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { updateProfile } from '@/redux/slices/authSlice';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import { cn } from '@/lib/utils';
import Logout from '../ui/icons/Logout';
import { useIntercom } from 'react-use-intercom';
import { Switch } from '../ui/switch';

const formSchema = z.object({
	name: z.string().min(2, {
		message: 'Please enter correct information before saving.',
	}),
	designation: z.string().optional(),
	telephoneNumber: z.string().optional(),
	isTwoFactorEnabled: z.boolean().optional(),
});

interface FormData {
	name: string;
	designation: string;
	telephoneNumber: string;
	isTwoFactorEnabled: boolean;
}

interface AccountInfoProps {
	user: User | null;
}

const AccountInfo: FC<AccountInfoProps> = ({ user }) => {
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [profileImage, setProfileImage] = useState<File | null>(null);
	const [removeProfileImage, setRemoveProfileImage] = useState(false);
	const isEditable = searchParams.get('mode') === 'edit';
	const { shutdown } = useIntercom();

	const form = useForm<FormData>({
		resolver: zodResolver(formSchema),
		values: {
			name: user?.name || '',
			designation: user?.designation || '',
			telephoneNumber: user?.telephoneNumber || '',
			isTwoFactorEnabled: user?.isTwoFactorEnabled || false,
		},
	});

	const setFormValues = (user) => {
		form.setValue('name', user.name);
		form.setValue('designation', user.designation || '');
		form.setValue('telephoneNumber', user.telephoneNumber || '');
		form.setValue('isTwoFactorEnabled', user.isTwoFactorEnabled || false);
	};

	const openChangePasswordDialog = () => {
		dispatch(openDialog({ type: 'changePassword' }));
	};

	const openChangePhoneDialog = () => {
		dispatch(
			openDialog({ type: 'changePhone', changePhoneType: 'changePhone' })
		);
	};

	const editProfile = () => {
		setRemoveProfileImage(false);
		navigate('?mode=edit');
	};

	const viewProfile = () => {
		navigate('');
		if (user) {
			setFormValues(user);
			setProfileImage(null);
		}
	};

	const formatUserProfilePayload = (formData: FormData): UserProfilePayload => {
		return {
			name: formData.name,
			designation: formData.designation || null,
			telephoneNumber: formData.telephoneNumber || null,
			...(removeProfileImage === true ? { profilePicture: null } : {}),
		};
	};

	const handleTwoFactorChange = async (field, checked: boolean) => {
		try {
			if (!user?.id) return;
			if (user?.isTelephoneNumberVerified || user?.isTwoFactorEnabled) {
				dispatch(processStart());
				const response = await updateUser(user.id, {
					isTwoFactorEnabled: checked,
				});
				handleResponse(
					response,
					`Two factor authentication has been ${checked ? 'enabled' : 'disabled'} successfully.`
				);
				field.onChange(checked);
				dispatch(
					updateProfile({ user: { ...user, isTwoFactorEnabled: checked } })
				);
				dispatch(processSuccess());
			} else {
				dispatch(
					openDialog({
						type: 'changePhone',
						changePhoneType: 'authenticationSetup',
					})
				);
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const onSubmit = async (formData: FormData) => {
		try {
			if (user?.id) {
				dispatch(processStart());
				const userProfile: UserProfilePayload =
					formatUserProfilePayload(formData);
				const response = await updateUserProfile(
					user.id,
					userProfile,
					profileImage
				);
				handleResponse(response, 'Profile has been updated successfully.');
				const updatedUser = response.data as User;
				dispatch(
					updateProfile({
						user: {
							...user,
							...userProfile,
							...{ profilePicture: updatedUser.profilePicture },
						},
					})
				);
				dispatch(processSuccess());
				viewProfile();
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleSignOut = () => {
		clearState(dispatch);
		shutdown();
	};

	useEffect(() => {
		setFormValues(user);
		viewProfile();
	}, [user?.isTwoFactorEnabled, user?.telephoneNumber]);

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="flex flex-col w-full h-full gap-6"
			>
				{isEditable ? (
					<div className="flex gap-2 flex-start">
						<span
							className="self-center w-6 h-auto p-0 text-black cursor-pointer"
							onClick={() => viewProfile()}
						>
							<ArrowLeft className="w-5 h-5" />
						</span>
						<h3 className="font-bold text-black dynamic-medium">
							Edit Profile
						</h3>
					</div>
				) : (
					<div className="flex items-center justify-between gap-3">
						<h3 className="font-bold text-black dynamic-medium">
							Profile Settings
						</h3>
						<div
							className="flex items-center gap-2 cursor-pointer"
							onClick={handleSignOut}
						>
							<Logout fill="#e861c9" />
							<p className="font-semibold text-secondary dynamic-medium">
								Logout
							</p>
						</div>
					</div>
				)}
				<AccountProfileImage
					profileImageUpdated={(file) => {
						if (file === null) {
							setRemoveProfileImage(true);
						} else {
							setProfileImage(file);
						}
					}}
					profileImage={profileImage}
					src={user?.profilePicture || ''}
					user={user}
					edit={isEditable}
				/>
				<div className="grid grid-cols-2 gap-4">
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<Label>Name</Label>
									<FormControl>
										<Input
											className={cn(
												'bg-white cursor-caret border-[#D3DDE2] h-12 p-4  rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
												!isEditable ? 'inactive' : ''
											)}
											placeholder="Enter your name"
											{...field}
											disabled={!isEditable}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
					<div className="col-span-1 space-y-2" hidden={isEditable}>
						<Label>Email</Label>
						<Input
							className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] inactive"
							defaultValue={user?.email}
							disabled
						/>
					</div>
					<FormField
						control={form.control}
						name="designation"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<Label>Job Title</Label>
									<FormControl>
										<Input
											className={cn(
												'bg-white cursor-caret border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
												!isEditable ? 'inactive' : ''
											)}
											placeholder="Enter your job title"
											disabled={!isEditable}
											{...field}
										></Input>
									</FormControl>
								</FormItem>
							);
						}}
					/>
					<FormField
						control={form.control}
						name="telephoneNumber"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<div className="flex justify-between h-5">
										<Label>Phone Number</Label>
										<a
											className="font-normal cursor-pointer text-bodyText text-primary animated-hover-effect"
											onClick={() => openChangePhoneDialog()}
											hidden={!isEditable}
										>
											Change Phone Number
										</a>
									</div>
									<FormControl>
										<Input
											className={cn(
												'bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] inactive',
												!isEditable ? 'inactive' : ''
											)}
											placeholder="Enter your phone number"
											disabled
											{...field}
										></Input>
									</FormControl>
								</FormItem>
							);
						}}
					/>
					{!user?.organization?.isSsoEnabled && (
						<div className="col-span-1 space-y-2" hidden={!isEditable}>
							<div className="flex justify-between h-5">
								<Label>Password</Label>
								<a
									className="font-normal cursor-pointer text-bodyText text-primary animated-hover-effect"
									onClick={() => openChangePasswordDialog()}
								>
									Change Password
								</a>
							</div>
							<Input
								className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] inactive"
								defaultValue={'********'}
								disabled
							></Input>
						</div>
					)}
				</div>
				{!user?.organization?.isSsoEnabled && (
					<div className="grid grid-cols-2 gap-4">
						<FormField
							control={form.control}
							name="isTwoFactorEnabled"
							render={({ field }) => {
								return (
									<FormItem className="flex items-center justify-between col-span-1">
										<Label htmlFor={'isTwoFactorEnabled'}>
											2-Factor Authentication
										</Label>
										<FormControl>
											<Switch
												id={'isTwoFactorEnabled'}
												className="cursor-pointer"
												checked={field.value}
												onCheckedChange={(checked) =>
													handleTwoFactorChange(field, checked)
												}
											/>
										</FormControl>
									</FormItem>
								);
							}}
						/>
					</div>
				)}
				<div className="flex flex-start">
					{isEditable ? (
						<div className="flex gap-4">
							<SecondaryButton type="button" onClick={() => viewProfile()}>
								CANCEL
							</SecondaryButton>
							<PrimaryButton type="submit">SAVE</PrimaryButton>
						</div>
					) : (
						<PrimaryButton type="button" onClick={() => editProfile()}>
							EDIT PROFILE
						</PrimaryButton>
					)}
				</div>
			</form>
		</Form>
	);
};

export default AccountInfo;
