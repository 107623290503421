import { SVGProps } from 'react';

const FilledHeart = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.00355 16.7964L8.99552 16.7884L8.98713 16.7808L7.26255 15.2061C7.26246 15.2061 7.26237 15.206 7.26228 15.2059C5.50652 13.5992 3.92266 12.0068 2.51002 10.429C1.15698 8.9177 0.5 7.27921 0.5 5.5C0.5 4.05783 0.977907 2.8792 1.92855 1.92855C2.8792 0.977907 4.05783 0.5 5.5 0.5C6.30993 0.5 7.06935 0.671318 7.7841 1.01349C8.50506 1.35863 9.11484 1.82797 9.61831 2.42297L10 2.87406L10.3817 2.42297C10.8852 1.82797 11.4949 1.35863 12.2159 1.01349C12.9306 0.671318 13.6901 0.5 14.5 0.5C15.9422 0.5 17.1208 0.977907 18.0714 1.92855C19.0221 2.8792 19.5 4.05783 19.5 5.5C19.5 7.28025 18.8464 8.92368 17.5007 10.4435C16.0981 12.0276 14.5035 13.6224 12.7159 15.228L12.7159 15.228L12.7131 15.2305L11.0131 16.7805L11.0046 16.7883L10.9964 16.7964C10.866 16.9269 10.7184 17.0191 10.5495 17.0773C10.3553 17.1443 10.1728 17.175 10 17.175C9.82718 17.175 9.64472 17.1443 9.4505 17.0773C9.28162 17.0191 9.13402 16.9269 9.00355 16.7964Z"
				fill="#5D9BFD"
				stroke="#5D9BFD"
			/>
		</svg>
	);
};

export default FilledHeart;
