import { SVGProps } from 'react';

const Download = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<mask
				id="mask0_8765_13203"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="25"
				height="24"
			>
				<rect x="0.328125" width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_8765_13203)">
				<path
					d="M6.32813 20C5.77813 20 5.30729 19.8042 4.91563 19.4125C4.52396 19.0208 4.32812 18.55 4.32812 18V4C4.32812 3.45 4.52396 2.97917 4.91563 2.5875C5.30729 2.19583 5.77813 2 6.32813 2H12.5031C12.7698 2 13.024 2.05 13.2656 2.15C13.5073 2.25 13.7198 2.39167 13.9031 2.575L18.7531 7.425C18.9365 7.60833 19.0781 7.82083 19.1781 8.0625C19.2781 8.30417 19.3281 8.55833 19.3281 8.825V10.025C19.3281 10.3083 19.2323 10.5458 19.0406 10.7375C18.849 10.9292 18.6115 11.025 18.3281 11.025C18.0448 11.025 17.8073 10.9292 17.6156 10.7375C17.424 10.5458 17.3281 10.3083 17.3281 10.025V9H13.8281C13.4115 9 13.0573 8.85417 12.7656 8.5625C12.474 8.27083 12.3281 7.91667 12.3281 7.5V4H6.32813V18H11.3281C11.6115 18 11.849 18.0958 12.0406 18.2875C12.2323 18.4792 12.3281 18.7167 12.3281 19C12.3281 19.2833 12.2323 19.5208 12.0406 19.7125C11.849 19.9042 11.6115 20 11.3281 20H6.32813Z"
					fill="#5D9BFD"
				/>
				<path
					d="M16.4281 16.3004L17.3281 17.2004V14.0254C17.3281 13.7421 17.424 13.5046 17.6156 13.3129C17.8073 13.1212 18.0448 13.0254 18.3281 13.0254C18.6115 13.0254 18.849 13.1212 19.0406 13.3129C19.2323 13.5046 19.3281 13.7421 19.3281 14.0254V17.2004L20.2281 16.3004C20.4115 16.1171 20.6448 16.0254 20.9281 16.0254C21.2115 16.0254 21.4448 16.1171 21.6281 16.3004C21.8115 16.4837 21.9031 16.7171 21.9031 17.0004C21.9031 17.2837 21.8115 17.5171 21.6281 17.7004L19.0281 20.3004C18.8281 20.5004 18.5948 20.6004 18.3281 20.6004C18.0615 20.6004 17.8281 20.5004 17.6281 20.3004L15.0281 17.7004C14.8448 17.5171 14.7531 17.2837 14.7531 17.0004C14.7531 16.7171 14.8448 16.4837 15.0281 16.3004C15.2115 16.1171 15.4448 16.0254 15.7281 16.0254C16.0115 16.0254 16.2448 16.1171 16.4281 16.3004Z"
					fill="#5D9BFD"
				/>
				<path
					d="M21.3281 22.0004H15.3281C15.0448 22.0004 14.8073 22.0962 14.6156 22.2879C14.424 22.4796 14.3281 22.7171 14.3281 23.0004C14.3281 23.2837 14.424 23.5212 14.6156 23.7129C14.8073 23.9046 15.0448 24.0004 15.3281 24.0004H21.3281C21.6115 24.0004 21.849 23.9046 22.0406 23.7129C22.2323 23.5212 22.3281 23.2837 22.3281 23.0004C22.3281 22.7171 22.2323 22.4796 22.0406 22.2879C21.849 22.0962 21.6115 22.0004 21.3281 22.0004Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Download;
