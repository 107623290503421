const Processing = ({ className }) => {
	return (
		<svg
			className={className}
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.3066 10C20.3066 15.5228 15.8295 20 10.3066 20C4.78379 20 0.306641 15.5228 0.306641 10C0.306641 4.47715 4.78379 0 10.3066 0C15.8295 0 20.3066 4.47715 20.3066 10ZM4.30664 10C4.30664 13.3137 6.99293 16 10.3066 16C13.6203 16 16.3066 13.3137 16.3066 10C16.3066 6.68629 13.6203 4 10.3066 4C6.99293 4 4.30664 6.68629 4.30664 10Z"
				fill="#D3DDE2"
			/>
			<path
				d="M20.3066 10C20.3066 11.5931 19.926 13.1631 19.1965 14.5793C18.467 15.9955 17.4097 17.217 16.1126 18.1419C14.8156 19.0668 13.3163 19.6685 11.7397 19.8968C10.163 20.1251 8.55468 19.9734 7.04853 19.4543C5.54239 18.9353 4.18204 18.0639 3.08078 16.9128C1.97952 15.7617 1.16922 14.3641 0.717351 12.8365C0.265482 11.3088 0.185126 9.69533 0.482975 8.13035C0.780823 6.56537 1.44826 5.0942 2.42969 3.83934L5.58047 6.30361C4.99161 7.05652 4.59115 7.93922 4.41244 8.87821C4.23373 9.8172 4.28195 10.7853 4.55307 11.7019C4.82419 12.6185 5.31037 13.457 5.97113 14.1477C6.63188 14.8384 7.44809 15.3612 8.35178 15.6726C9.25546 15.984 10.2205 16.075 11.1665 15.9381C12.1124 15.8011 13.012 15.4401 13.7902 14.8851C14.5685 14.3302 15.2029 13.5973 15.6406 12.7476C16.0783 11.8979 16.3066 10.9558 16.3066 10H20.3066Z"
				fill="url(#paint0_linear_7218_16886)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_7218_16886"
					x1="0.30664"
					y1="10"
					x2="19.9046"
					y2="10.0481"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#E362CA" />
					<stop offset="1" stopColor="#6179E6" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Processing;
