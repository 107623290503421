import { FC, useState } from 'react';
import AppTabs from '../ui/shared/Tabs/AppTabs/AppTabs';
import AssistanceTab from '../AssistanceTab/AssistanceTab';
import ComplianceTab from '../ComplianceTab/ComplianceTab';
import { useAppDispatch } from '@/lib/hooks/hooks';
import useProposalId from '@/lib/hooks/useProposalId';
import VisualsTab from '../VisualsTab/VisualsTab';
import { fetchAllProposalCharts } from '@/redux/slices/chartSlice';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { fetchSectionRules } from '@/redux/requests/compliance';

interface WriteModeSidebarProps {}

const WriteModeSidebar: FC<WriteModeSidebarProps> = () => {
	const [selectedTab, setSelectedTab] = useState('assistant');
	const dispatch = useAppDispatch();
	const proposalId = useProposalId() as number;
	const { activeSection } = useActiveSection();

	const handleVisuals = () => {
		dispatch(fetchAllProposalCharts(proposalId));
	};

	return (
		<div className="hidden col-span-3 2xl:block">
			<AppTabs
				defaultTab="assistant"
				type="secondary"
				handleTabChange={(val: string) => {
					setSelectedTab(val);
				}}
				selectedTab={selectedTab}
				tabs={[
					{
						label: 'Assistance',
						value: 'assistant',
						content: <AssistanceTab />,
					},
					{
						label: 'Compliance',
						value: 'compliance',
						content: <ComplianceTab />,
						tourClass: 'compliance',
						onClick: () =>
							activeSection.sectionId &&
							dispatch(
								fetchSectionRules({
									proposalId,
									sectionId: activeSection.sectionId,
								})
							),
					},
					{
						label: 'Visuals',
						value: 'visuals',
						content: <VisualsTab />,
						onClick: handleVisuals,
						tourClass: 'visuals',
					},
				]}
			/>
		</div>
	);
};

export default WriteModeSidebar;
