import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Tick from '../ui/icons/new/Tick';
import Cross from '../ui/icons/new/Cross';
import Info from '../ui/icons/new/Info';
import { cn } from '@/lib/utils';
import styles from './ComplianceCard.module.css';
import { ComplianceStatus } from '@/lib/types/apiTypes';
import { Lightbulb } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import {
	setHighlightedCard,
	setReferences,
} from '@/redux/slices/complianceMatrixSlice';

interface ComplianceCardProps {
	text: string;
	status: ComplianceStatus;
	ruleID?: number;
	user: {
		name: string;
	};
	isReferences: boolean;
}

const ComplianceCard: FC<ComplianceCardProps> = ({ text, status, ruleID }) => {
	const dispatch = useAppDispatch();
	const [mouseOver, setMouseOver] = useState(false);
	const { activeSection } = useActiveSection();
	const highlightedCard = useSelector(
		(state: RootState) => state.compliance.activeComplianceCardID
	);
	const highlightedSection = useSelector(
		(state: RootState) => state.compliance.activeComplianceSectionID
	);

	const isHighlighted = useMemo(
		() =>
			highlightedCard === ruleID &&
			highlightedSection === activeSection.sectionId,
		[highlightedCard, highlightedSection, ruleID, activeSection.sectionId]
	);
	const sectionReferences = useSelector(
		(root: RootState) => root.compliance.references
	);

	useEffect(() => {
		if (status === 'not_checked') {
			dispatch(setHighlightedCard({ cardID: null, sectionID: null })); // Reset the highlight
		}
	}, [status, dispatch]);

	const handleComplianceCardClick = useCallback(() => {
		if (ruleID && activeSection.sectionId && status === 'addressed') {
			dispatch(
				setHighlightedCard({
					cardID: ruleID,
					sectionID: activeSection.sectionId,
				})
			);
			dispatch(
				setReferences(
					sectionReferences[activeSection?.sectionId]?.filter(
						(reference) =>
							reference.id === `${ruleID}-${activeSection.sectionId}`
					)
				)
			);
		}
	}, [ruleID, activeSection, status, dispatch, sectionReferences]);

	const renderStatus = () => {
		switch (status) {
			case 'addressed':
				return (
					<p className="text-[#84CE53] font-bold flex items-center gap-2">
						<Tick />
						Addressed
					</p>
				);
			case 'not_addressed':
				return (
					<p className="flex items-center gap-2 font-bold text-secondary">
						<Cross />
						Not Addressed
					</p>
				);
			case 'not_checked':
				return (
					<p className="flex items-center gap-2 font-bold text-black">
						<Info />
						Not Checked
					</p>
				);
			default:
				return null;
		}
	};

	return (
		<div
			className={cn(
				styles.card,
				'flex flex-col gap-3 relative group',
				`compliance-card ${isHighlighted ? 'highlighted' : ''}`,
				mouseOver && status === 'addressed' ? styles.mouseOver : ''
			)}
			id={`compliance-card-${ruleID}-${activeSection.sectionId}`}
			onClick={handleComplianceCardClick}
			onMouseEnter={() => setMouseOver(true)}
			onMouseLeave={() => setMouseOver(false)}
		>
			<p className="text-black">{text}</p>
			<div className="flex items-center justify-between mt-2">
				{renderStatus()}
				<div
					tabIndex={0}
					role="button"
					onClick={() =>
						dispatch(
							openDialog({
								type: 'viewComplianceRuleDetail',
								complianceRuleId: ruleID,
							})
						)
					}
					className="flex items-center gap-1 font-bold uppercase text-primary animated-hover-effect"
				>
					<Lightbulb size={16} /> tell me more
				</div>
			</div>
		</div>
	);
};

export default ComplianceCard;
