const DocumentFilled = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
		>
			<mask
				id="mask0_2059_8756"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="32"
				height="33"
			>
				<rect y="0.5" width="32" height="32" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2059_8756)">
				<path
					d="M9.33333 23.1667H18.6667V20.5H9.33333V23.1667ZM9.33333 17.8333H22.6667V15.1667H9.33333V17.8333ZM9.33333 12.5H22.6667V9.83333H9.33333V12.5ZM6.66667 28.5C5.93333 28.5 5.30556 28.2389 4.78333 27.7167C4.26111 27.1944 4 26.5667 4 25.8333V7.16667C4 6.43333 4.26111 5.80556 4.78333 5.28333C5.30556 4.76111 5.93333 4.5 6.66667 4.5H25.3333C26.0667 4.5 26.6944 4.76111 27.2167 5.28333C27.7389 5.80556 28 6.43333 28 7.16667V25.8333C28 26.5667 27.7389 27.1944 27.2167 27.7167C26.6944 28.2389 26.0667 28.5 25.3333 28.5H6.66667ZM6.66667 25.8333H25.3333V7.16667H6.66667V25.8333Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default DocumentFilled;
