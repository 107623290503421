import { SVGProps } from 'react';

const InfoNew = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="mask0_6707_12086"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="21"
			>
				<rect y="0.5" width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_6707_12086)">
				<path
					d="M9.16602 14.666H10.8327V9.66602H9.16602V14.666ZM9.99935 7.99935C10.2355 7.99935 10.4334 7.91949 10.5931 7.75977C10.7528 7.60004 10.8327 7.40213 10.8327 7.16602C10.8327 6.9299 10.7528 6.73199 10.5931 6.57227C10.4334 6.41254 10.2355 6.33268 9.99935 6.33268C9.76324 6.33268 9.56532 6.41254 9.4056 6.57227C9.24588 6.73199 9.16602 6.9299 9.16602 7.16602C9.16602 7.40213 9.24588 7.60004 9.4056 7.75977C9.56532 7.91949 9.76324 7.99935 9.99935 7.99935ZM9.99935 18.8327C8.84657 18.8327 7.76324 18.6139 6.74935 18.1764C5.73546 17.7389 4.85352 17.1452 4.10352 16.3952C3.35352 15.6452 2.75977 14.7632 2.32227 13.7493C1.88477 12.7355 1.66602 11.6521 1.66602 10.4993C1.66602 9.34657 1.88477 8.26324 2.32227 7.24935C2.75977 6.23546 3.35352 5.35352 4.10352 4.60352C4.85352 3.85352 5.73546 3.25977 6.74935 2.82227C7.76324 2.38477 8.84657 2.16602 9.99935 2.16602C11.1521 2.16602 12.2355 2.38477 13.2493 2.82227C14.2632 3.25977 15.1452 3.85352 15.8952 4.60352C16.6452 5.35352 17.2389 6.23546 17.6764 7.24935C18.1139 8.26324 18.3327 9.34657 18.3327 10.4993C18.3327 11.6521 18.1139 12.7355 17.6764 13.7493C17.2389 14.7632 16.6452 15.6452 15.8952 16.3952C15.1452 17.1452 14.2632 17.7389 13.2493 18.1764C12.2355 18.6139 11.1521 18.8327 9.99935 18.8327ZM9.99935 17.166C11.8605 17.166 13.4368 16.5202 14.7285 15.2285C16.0202 13.9368 16.666 12.3605 16.666 10.4993C16.666 8.63824 16.0202 7.06185 14.7285 5.77018C13.4368 4.47852 11.8605 3.83268 9.99935 3.83268C8.13824 3.83268 6.56185 4.47852 5.27018 5.77018C3.97852 7.06185 3.33268 8.63824 3.33268 10.4993C3.33268 12.3605 3.97852 13.9368 5.27018 15.2285C6.56185 16.5202 8.13824 17.166 9.99935 17.166Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default InfoNew;
