const Upload = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="130"
			height="129"
			viewBox="0 0 130 129"
			fill="none"
		>
			<g opacity="0.76">
				<mask
					id="mask0_268_285027"
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="130"
					height="129"
				>
					<path
						d="M129.827 129V0L0.827148 7.89286e-09V129L129.827 129Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_268_285027)">
					<path
						d="M75.7464 33.1856L108.858 33.1855C113.196 33.186 117.357 34.8371 120.424 37.7758C123.492 40.7146 125.216 44.7002 125.217 48.8563L125.222 108.914C125.221 113.07 123.498 117.056 120.43 119.995C117.362 122.934 113.202 124.585 108.864 124.585L21.7957 124.585C17.4576 124.585 13.297 122.934 10.2292 119.995C7.16149 117.056 5.43785 113.07 5.43753 108.914L5.43281 48.8563C5.43249 44.7002 7.1555 40.7143 10.2228 37.7754C13.2901 34.8366 17.4504 33.1856 21.7886 33.1856L29.9958 33.1856"
						stroke="#5D9BFD"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M54.5495 4.41211L54.555 74.825L75.7481 54.851"
						stroke="#5D9BFD"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M54.5494 74.8234L34.0556 54.8525"
						stroke="#5D9BFD"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M100.368 33.1855L100.37 57.3576L125.222 56.9958"
						stroke="#5D9BFD"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
		</svg>
	);
};

export default Upload;
