import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

const useProposalCategory = () => {
	return useSelector(
		(state: RootState) => state.proposal.proposal?.rfpDocument?.category
	);
};

export default useProposalCategory;
