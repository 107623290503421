import { FC, useEffect, useState } from 'react';
import styles from './Login.module.css';
import LogoLarge from '@/components/ui/icons/LogoLarge';
import { Navigate, useNavigate } from 'react-router';
import { cn } from '@/lib/utils';
import { useSearchParams } from 'react-router-dom';
import LoginForm from '@/components/LoginForm/LoginForm';
import LoginSSOForm from '@/components/LoginSSOForm/LoginSSOForm';
// import DomainSearch from '@/components/DomainSearch/DomainSearch';
import ForgotPasswordForm from '@/components/ForgotPasswordForm/ForgotPasswordForm';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import useUser from '@/lib/hooks/useUser';
import { login, sendOTP, verifyOTP } from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	handleLoginResponse,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { loginFailure, loginRequest } from '@/redux/slices/authSlice';
import { ApiResponse, LoginResponse } from '@/lib/types/apiTypes';
import { OTPForm } from '@/components/Forms/OTPForm/OTPForm';
import useDebounce from '@/lib/hooks/useDebounce';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';

const domainName = import.meta.env.VITE_DOMAIN_NAME;
const showSSOlogin = import.meta.env.VITE_SSO_LOGIN;

interface LoginProps {}

export interface LoginCreds {
	email: string;
	password: string;
}

const Login: FC<LoginProps> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const user = useUser();

	const trialBannerDelayDate = useSelector(
		(root: RootState) => root.auth.trialBannerDelayDate
	);

	const [searchParams, setSearchParams] = useSearchParams();
	const [loginCreds, setLoginCreds] = useState<LoginCreds | null>(null);
	const [otpEncodedToken, setOtpEncodedToken] = useState<string | null>(null);
	const [disableResend, setDisableResend] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const enableResend = useDebounce(() => {
		setDisableResend(false);
	}, 30000);
	const formType = searchParams.get('type');

	const handle2FALogin = async (twoFactorToken: string) => {
		try {
			if (!loginCreds) return;
			dispatch(loginRequest());
			const response: ApiResponse = await login(
				{
					auth: 'basic',
					email: loginCreds.email,
					password: loginCreds.password,
				},
				twoFactorToken
			);
			if (response.statusCode >= 400 && response.statusCode <= 599) {
				throw response; // Throw the response if status is 400s or 500s
			}
			handleLoginResponse(
				dispatch,
				navigate,
				response.data as LoginResponse,
				trialBannerDelayDate
			);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			dispatch(loginFailure((error as any)?.message));
		}
	};

	const handleOTPSubmit = async (code: string) => {
		try {
			if (!otpEncodedToken) return;
			setIsLoading(true);
			const response = await verifyOTP({
				otp: code,
				otpEncodedToken: otpEncodedToken,
			});
			handleResponse(response);
			const twoFactorToken = (response?.data as any)?.twoFactorToken;
			handle2FALogin(twoFactorToken);
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handle2FAVerification = async (loginCreds: LoginCreds) => {
		try {
			setIsLoading(true);
			const response = await sendOTP({
				email: loginCreds?.email,
			});
			handleResponse(response);
			navigate('?type=verify-otp');
			setOtpEncodedToken((response?.data as any)?.otpEncodedToken);
			setLoginCreds(loginCreds);
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleResend = async () => {
		try {
			if (!loginCreds?.email) return;
			setIsLoading(true);
			const response = await sendOTP({
				email: loginCreds?.email,
			});
			handleResponse(response, 'OTP has been sent again on your phone number');
			setOtpEncodedToken((response?.data as any)?.otpEncodedToken);
			setDisableResend(true);
			enableResend();
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (showSSOlogin === 'true') {
			searchParams.set('type', 'sso');
			setSearchParams(searchParams);
		}
		if (formType === 'verify-otp') {
			navigate('/login');
			setLoginCreds(null);
		}
		setOtpEncodedToken(null);
		setIsLoading(false);
	}, []);

	if (user) {
		return <Navigate to="/" replace />;
	}

	if (isLoading) return <LoaderTransparent />;

	return (
		<div className={cn(styles.container, 'flex relative min-h-screen')}>
			<div className={cn(styles.white_box, 'm-auto gap-6 shadow-main')}>
				<div className="flex flex-col items-center justify-center gap-6">
					<LogoLarge />
					{/* {formType === 'searchdomain' ? (
						<>
							<h1 className="text-[#596c76] dynamic-xl-large">
								<span className="font-bold">Search Company Domain</span>
							</h1>
							<p className="text-xl text-black">
								Give us the email to search the company domain
							</p>
							<DomainSearch />
						</>
					) :  */}
					{formType === 'sso' ? (
						<>
							<h1 className="text-[#596c76] dynamic-xl-large">
								Login with <span className="font-bold">SSO</span>
							</h1>
							<LoginSSOForm
								domainName={showSSOlogin === 'true' ? domainName : ''}
							/>
						</>
					) : formType === 'forgot-password' ? (
						<>
							<h1 className="text-[#596c76] dynamic-xl-large">
								Reset your <span className="font-bold">Password</span>
							</h1>
							<p className="text-xl text-black">
								Plese give us the email you use to login.
							</p>
							<ForgotPasswordForm setIsLoading={setIsLoading} />
						</>
					) : formType === 'verify-otp' ? (
						<>
							<h1 className="text-[#596c76] dynamic-xl-large">
								Please verify <span className="font-bold">OTP</span>
							</h1>
							<p className="text-xl text-black">
								OTP Code has been sent on your phone number
							</p>
							<OTPForm
								className="w-[400px]"
								onSubmit={handleOTPSubmit}
								handleResend={handleResend}
								disableResend={disableResend}
							/>
						</>
					) : showSSOlogin !== 'true' ? (
						<>
							<h1 className="text-[#596c76] dynamic-xl-large">
								Welcome to <span className="font-bold">Grant Assistant</span>
							</h1>
							<LoginForm on2FALogin={handle2FAVerification} />
						</>
					) : (
						<>
							<h1 className="text-[#596c76] dynamic-xl-large">
								<span className="font-bold">Grant Assistant</span>
							</h1>
							<h2 className="text-black dynamic-mediuum">
								Something went wrong, please reload the page or try again later
							</h2>
						</>
					)}
				</div>
			</div>
			<div className={styles.pink_box}>
				<div className="flex items-center justify-center w-full px-12 py-4 text-white">
					<p>© 2024 GRANT ASSISTANT. All rights reserved.</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
