import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { getDocumentFromSource } from '@/lib/functions/apiCalls';
import { handleApiError, handleGeneralError } from '@/lib/functions/funcUtils';
import { ErrorResponse } from '@/lib/types/apiTypes';
import { openDialog } from '@/redux/slices/pageSlice';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useDispatch, useSelector } from 'react-redux';

const SuggestionBackgroundDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'viewSuggestionBackground'
	);
	const suggestion = useSelector(
		(root: RootState) => root.page.dialog?.suggestion
	);
	const dispatch = useDispatch();

	if (!suggestion) return null;

	const handlePDF = async () => {
		dispatch(processStart());
		try {
			const response = await getDocumentFromSource(
				suggestion.metadata.sourceLink
			);
			dispatch(openDialog({ type: 'viewPDF', pdf: response as ArrayBuffer }));
			dispatch(processSuccess());
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[1000px] pt-12 p-8  bg-white">
				<DialogHeader className="text-[#6D7D86] font-bold dynamic-large flex flex-col">
					{suggestion.metadata.name}
					<span className={'w-full float-left h-[1px] my-4 mr-8 bg-[#D3DDE2]'}>
						<span
							className={
								'relative block rounded-full top-[-1.5px] w-1 h-1 bg-[#D3DDE2]'
							}
						></span>
					</span>
				</DialogHeader>
				<div className="max-h-[70vh] px-4 pb-5 overflow-hidden overflow-y-scroll ">
					<p className="text-[#E861C9] font-bold dynamic-medium mb-2">
						Citation
					</p>
					<p className="text-[#6D7D86]  text-md">
						{suggestion.metadata.citation}
					</p>
					<span className={'w-full float-left h-[1px] my-4 mr-8 bg-[#D3DDE2]'}>
						<span
							className={
								'relative block rounded-full top-[-1.5px] w-1 h-1 bg-[#D3DDE2]'
							}
						></span>
					</span>
					<p className="font-bold dynamic-medium mb-4 text-[#6D7D86]">
						This is what I <span className="text-[#E861C9]">Found</span>
					</p>
					<ul className="text-[#6D7D86]  text-md list-disc pl-6">
						{suggestion?.relevancy?.map((item) => (
							<li key={item} className="mb-3">
								{item}
							</li>
						))}
					</ul>
					<span className={'w-full float-left h-[1px] my-4 mr-8 bg-[#D3DDE2]'}>
						<span
							className={
								'relative block rounded-full top-[-1.5px] w-1 h-1 bg-[#D3DDE2]'
							}
						></span>
					</span>
					<p className="text-[#6D7D86] font-bold dynamic-medium mb-2">
						This is what I <span className="text-[#E861C9]">Recommend</span>
					</p>
					<p className="text-[#6D7D86]  text-md">{suggestion.text}</p>
					<div className="flex items-center justify-start mt-5">
						<PrimaryButton onClick={handlePDF} className="">
							Read full document
						</PrimaryButton>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default SuggestionBackgroundDialog;
