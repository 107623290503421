import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { RootState } from '@/redux/store/store';
import { FC } from 'react';
import parse from 'html-react-parser';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { closeDialog } from '@/redux/slices/pageSlice';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { triggerHistory } from '@/lib/observables/observables';
import { updateSection } from '@/redux/slices/proposalSlice';

interface ViewTableDialogProps {}

const ViewTableDialog: FC<ViewTableDialogProps> = () => {
	const isOpen = useAppSelector(
		(root: RootState) => root.page.dialog?.type === 'viewTable'
	);

	const dispatch = useAppDispatch();

	const { activeSection } = useActiveSection();

	const tableCode = useSelector(
		(root: RootState) => root.page.dialog?.tableCode
	);

	const handleRestore = () => {
		dispatch(
			updateSection({
				content: '',
				editorState: tableCode as string,
				sectionId: activeSection.sectionId as number,
			})
		);
		triggerHistory();
		dispatch(closeDialog());
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[1200px] flex-row p-6 rounded-3xl shadow-main bg-white custom-table "
				hideCloseBtn
			>
				<DialogHeader>
					<DialogTitle className="font-bold dynamic-large text-secondary">
						View Table
					</DialogTitle>
				</DialogHeader>

				<div className="overflow-auto max-h-[600px] pr-3 ">
					{parse(String(activeSection.editorHTML))}
				</div>

				<div className="relative flex items-center justify-end gap-3 mt-4">
					<SecondaryButton
						type="button"
						onClick={() => {
							dispatch(closeDialog());
						}}
					>
						Cancel
					</SecondaryButton>
					<PrimaryButton type="submit" onClick={handleRestore}>
						Restore
					</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ViewTableDialog;
