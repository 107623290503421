import { FC, useState, useEffect } from 'react';
import styles from './OrganizationSetup.module.css';
import { cn } from '@/lib/utils';
import OrganizationSetupForm from '@/components/SetupForms/OrganizationSetupForm/OrganizationSetupForm';
import {
	ErrorHandle,
	getToken,
	handleResponse,
} from '@/lib/functions/funcUtils';
import useUser from '@/lib/hooks/useUser';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { getOrganizationInfo } from '@/lib/functions/apiCalls';
import { Organization } from '@/lib/types/apiTypes';
import { Doughnut } from 'react-chartjs-2';
import ToneOfVoiceSetupForm from '@/components/SetupForms/ToneOfVoiceSetupForm/ToneOfVoiceSetupForm';
import LoaderPanel from '@/components/Loader/LoaderPanel/LoaderPanel';
import useAuth from '@/lib/hooks/useAuth';
import { Navigate } from 'react-router';

interface OrganizationSetupProps {}

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: { display: false },
		tooltip: { enabled: false },
	},
};

const OrganizationSetup: FC<OrganizationSetupProps> = () => {
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const { isAuthenticated, isAdmin } = useAuth();
	const token = getToken();
	const user = useUser();

	const initialSetupStep = user?.organization?.initialSetupStep;

	const [organizationId, setOrganzationId] = useState<number>(-1);
	const [organization, setOrganization] = useState<Organization | null>(null);
	const [setupStep, setSetupStep] = useState(initialSetupStep || 1);

	const stepChartData = {
		datasets: [
			{
				label: 'Step',
				data: [setupStep, 3 - setupStep],
				backgroundColor: ['#E861C9', '#D3DDE2'],
				borderColor: ['#E861C9', '#D3DDE2'],
				borderWidth: 5,
				cutout: 100,
			},
		],
	};

	useEffect(() => {
		if (isAuthenticated) {
			setIsLoading(true);
			getOrganizationInfo(token)
				.then((response) => {
					handleResponse(response);
					const organization = response?.data as Organization;
					setOrganzationId(organization.id);
					setOrganization(organization);
					setIsLoading(false);
				})
				.catch((error) => {
					ErrorHandle(dispatch, error);
					setIsLoading(false);
				});
		}
	}, [token]);

	if (!isAuthenticated) {
		return <Navigate to="/login" />;
	}
	if (!isAdmin || !initialSetupStep) {
		return <Navigate to="/" />;
	}

	return (
		<div
			className={cn(styles.container, 'flex relative h-screen overflow-hidden')}
		>
			<div className="flex flex-col relative min-h-screen h-full w-full overflow-y-auto pt-16 hidden-scroll">
				<div
					className={cn(styles.white_box, 'relative m-auto gap-6 shadow-main')}
				>
					<div className="flex flex-col items-center justify-center gap-6">
						<div className="flex w-full justify-between gap-2 pb-4 border-b border-[#D3DDE2]">
							<h1 className="font-bold text-secondary dynamic-xl-large">
								Organization Setup
							</h1>
							<div className="flex items-center gap-4 text-base font-bold text-black">
								<div>
									Step <span className="text-secondary">{setupStep}</span>/3
								</div>
								<div className="w-8 h-8">
									{stepChartData && (
										<Doughnut
											className="w-8 h-8 pointer-events-none"
											data={stepChartData}
											options={options}
										/>
									)}
								</div>
							</div>
						</div>
						{setupStep === 3 ? (
							<ToneOfVoiceSetupForm
								setIsLoading={setIsLoading}
								organizationId={organizationId}
								organization={organization}
								user={user}
								onCancel={() => {
									setSetupStep(setupStep - 1);
								}}
							/>
						) : (
							<OrganizationSetupForm
								setIsLoading={setIsLoading}
								setupStep={setupStep}
								setSetupStep={setSetupStep}
								organizationId={organizationId}
								organization={organization}
								setOrganization={setOrganization}
							/>
						)}
					</div>
					{isLoading && <LoaderPanel className="rounded-[40px]" />}
				</div>
				<div className={styles.pink_box}>
					<div className="flex items-center justify-center w-full px-12 py-6 text-base font-normal text-white xl:text-lg 2xl:text-xl">
						<p>© 2024 GRANT ASSISTANT. All rights reserved.</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrganizationSetup;
