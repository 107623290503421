import { TagField } from '@/lib/types/TemplateBuilder/templateTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TagState {
	error: string | null;
	isLoading: boolean;
	tags: TagField[] | null;
	sort: string;
	perPage: number;
}

const initialState: TagState = {
	isLoading: false,
	tags: null,
	error: null,
	sort: 'desc',
	perPage: 5,
};

const tagSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		setTagFieldData(state, action: PayloadAction<TagField[]>) {
			state.tags = action.payload;
		},
		setTagFieldSorting(state, action: PayloadAction<string>) {
			state.sort = action.payload;
		},
		setTagFieldPerPage(state, action: PayloadAction<number>) {
			state.perPage = action.payload;
		},
	},
});

export const { setTagFieldData, setTagFieldSorting, setTagFieldPerPage } =
	tagSlice.actions;
export default tagSlice.reducer;
