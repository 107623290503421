import { SVGProps } from 'react';

const Replace = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="13"
			height="14"
			viewBox="0 0 13 14"
			fill="none"
		>
			<g clipPath="url(#clip0_6180_14020)">
				<path
					d="M9.63086 1V13L12.5009 9.88"
					stroke="white"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.63031 12.9999L6.82031 9.87988"
					stroke="white"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M0.5 4.12L3.37 1V8.22V12.65"
					stroke="white"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.36914 1L6.17914 4.12"
					stroke="white"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6180_14020">
					<rect
						width="13"
						height="13"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Replace;
