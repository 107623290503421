import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { getUsers } from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	handleResponse,
	getToken,
} from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { FC, useState } from 'react';
import { ApiResponse, ProposalTableFilters } from '@/lib/types/apiTypes';
import { UsersTableProps } from '@/lib/types/constants';
import { Label } from '@/components/ui/label';
import { Multiselect } from '@/components/ui/shared/Multiselect/Multiselect';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import DatePicker from '@/components/ui/shared/DatePicker/DatePicker';
import { format } from 'date-fns';
import { SlidersHorizontal } from 'lucide-react';
import LoaderPanel from '@/components/Loader/LoaderPanel/LoaderPanel';

interface ProposalFiltersDrawerProps {
	proposalFilters: ProposalTableFilters;
	appliedFiltersCount?: number;
	onApply?: (proposalFilters: ProposalTableFilters) => void;
	onClear?: () => void;
}

const ProposalFiltersDrawer: FC<ProposalFiltersDrawerProps> = ({
	proposalFilters,
	appliedFiltersCount = 0,
	onApply,
	onClear,
}) => {
	const dispatch = useAppDispatch();
	const token = getToken();
	const [open, setOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [createdOn, setCreatedOn] = useState<string>(
		proposalFilters?.createdOn || ''
	);
	const [assignedUsers, setAssignedUsers] = useState<string[]>(
		proposalFilters?.assignedUsers || []
	);
	const [usersData, setUsersData] = useState<UsersTableProps[]>([]);

	const handleApply = async () => {
		try {
			onApply &&
				onApply({
					assignedUsers: assignedUsers,
					createdOn: createdOn,
				});
			setOpen(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleClear = async () => {
		try {
			setAssignedUsers([]);
			setCreatedOn('');
			onClear && onClear();
			setOpen(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const loadUsersData = async () => {
		try {
			setIsLoading && setIsLoading(true);
			const response: ApiResponse = await getUsers(token);
			handleResponse(response);
			setUsersData(response?.data as any);
			setIsLoading && setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading && setIsLoading(false);
		}
	};

	const getUserLabel = (userId: string) => {
		const user = usersData.find((user) => String(user.id) === userId);
		return user?.name || userId;
	};

	const handleDrawerOpen = () => {
		setOpen(true);
		loadUsersData();
		setAssignedUsers(proposalFilters?.assignedUsers || []);
		setCreatedOn(proposalFilters?.createdOn || '');
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Sheet
			open={open}
			onOpenChange={(open) => {
				open ? handleDrawerOpen() : handleDrawerClose();
			}}
		>
			<SheetTrigger className="relative flex gap-8">
				{appliedFiltersCount > 0 && (
					<div
						onClick={(event) => {
							handleClear();
							event.stopPropagation();
						}}
						className="flex items-center gap-2 dynamic-small font-medium uppercase cursor-pointer text-secondary animated-hover-effect"
					>
						<p className="mt-1">Clear Filters</p>
					</div>
				)}
				<div className="h-full flex items-center gap-2 dynamic-small font-medium uppercase cursor-pointer text-primary animated-hover-effect">
					<SlidersHorizontal size={20} />
					<p className="mt-1">filters</p>
					{appliedFiltersCount > 0 && (
						<div className="absolute z-20 flex items-center justify-center w-5 h-5 text-white rounded-full dynamic-small -top-3 -right-3 bg-secondary">
							{appliedFiltersCount}
						</div>
					)}
				</div>
			</SheetTrigger>
			<SheetContent className="flex flex-col !max-w-[450px] p-0 bg-white gap-0">
				<div className="flex pb-4">
					<h2 className="dynamic-xl-large flex items-center gap-2 font-bold text-secondary p-4">
						Proposal Filters
					</h2>
				</div>
				<div className="flex flex-col flex-1 gap-6 pb-2 px-6 overflow-y-auto">
					<div className="flex flex-col gap-2 space-y-2">
						<Label>Assigned Users</Label>
						<Multiselect
							title="Select Users"
							options={
								usersData?.length > 0
									? usersData?.map((user) => {
											return { value: String(user.id), label: user.name };
										})
									: []
							}
							value={
								usersData?.length > 0
									? assignedUsers?.map((userId) => {
											return { value: userId, label: getUserLabel(userId) };
										})
									: []
							}
							canAddOption={false}
							isModal={true}
							onSelectedValuesChange={(users: any) => {
								const assignedUsers = users.map((user) => user.value);
								setAssignedUsers(assignedUsers);
							}}
						></Multiselect>
					</div>
					<div className="flex flex-col gap-2 space-y-2">
						<Label>Created On</Label>
						<DatePicker
							placeholder={'Select the date'}
							date={createdOn}
							setDate={(date) => {
								setCreatedOn(format(date || '', 'yyyy-MM-dd'));
							}}
							handleClear={() => setCreatedOn('')}
							setToday={() => setCreatedOn(format(new Date(), 'yyyy-MM-dd'))}
						/>
					</div>
				</div>
				<div className="flex flex-col justify-center w-full px-6 py-4">
					<TextButton onClick={handleClear}>Clear All</TextButton>
					<PrimaryButton onClick={handleApply}>Apply Filters</PrimaryButton>
				</div>
				{isLoading && <LoaderPanel />}
			</SheetContent>
		</Sheet>
	);
};

export default ProposalFiltersDrawer;
