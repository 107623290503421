import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog, openDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

const ComplianceMatrixEditWarning = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'editMatrixRuleWarning'
	);
	const dispatch = useAppDispatch();
	const complianceRuleId = useSelector(
		(root: RootState) => root.page.dialog?.complianceRuleId
	);

	const editMatrix = () => {
		if (!complianceRuleId) return;

		dispatch(openDialog({ type: 'editMatrixRule', complianceRuleId }));
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] p-5 rounded-2xl shadow-main bg-white">
				<DialogHeader>
					<p className="font-bold dynamic-large text-secondary">
						You're editing a compliance rule!
					</p>
					<p className="py-2 text-black">
						Editing compliance rule, will affect how this is used in the
						compliance run. Are you sure?
					</p>

					<div className="flex items-center justify-end gap-3">
						<SecondaryButton onClick={() => dispatch(closeDialog())}>
							Cancel
						</SecondaryButton>
						<PrimaryButton onClick={editMatrix}>Yes</PrimaryButton>
					</div>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default ComplianceMatrixEditWarning;
