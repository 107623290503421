import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
		>
			<g clipPath="url(#clip0_8622_17518)">
				<path
					d="M9.20273 5.05469H31.8818C34.1544 5.05469 35.9985 6.90078 35.9985 9.17581C35.9985 23.9801 23.9914 35.9999 9.20273 35.9999C6.93009 35.9999 5.08594 34.1538 5.08594 31.8787V9.17581C5.08594 6.90078 6.93009 5.05469 9.20273 5.05469Z"
					fill="url(#paint0_linear_8622_17518)"
				/>
				<path
					d="M26.7958 0C29.0685 0 30.9126 1.84609 30.9126 4.12112V26.824C30.9126 29.0991 29.0685 30.9452 26.7958 30.9452H4.11679C1.84415 30.9452 0 29.0991 0 26.824C0 12.0197 12.0071 0 26.7958 0Z"
					fill="url(#paint1_linear_8622_17518)"
				/>
				<path
					d="M29.2099 2.20898H6.53085C4.25721 2.20898 2.41406 4.05407 2.41406 6.33011V29.033C2.41406 31.3091 4.25721 33.1541 6.53085 33.1541H29.2099C31.4835 33.1541 33.3267 31.3091 33.3267 29.033V6.33011C33.3267 4.05407 31.4835 2.20898 29.2099 2.20898Z"
					fill="#F0F0F0"
				/>
				<path
					d="M25.0347 24.227H24.2037C23.4628 24.227 22.8591 23.6251 22.8496 22.8857C22.8496 22.8738 22.8544 22.8549 22.8544 22.8359V13.1125C22.878 12.3802 23.4699 11.8044 24.2061 11.8044H25.0347C25.4324 11.8044 25.7591 11.4797 25.7591 11.0792C25.7591 10.6787 25.4347 10.354 25.0347 10.354H24.2037C23.4201 10.354 22.6721 10.6929 22.1442 11.2688C21.6162 10.6716 20.8563 10.3232 20.0514 10.3232H19.2205C18.8228 10.3232 18.4961 10.6479 18.4961 11.0484C18.4961 11.4489 18.8204 11.7736 19.2205 11.7736H20.0514C20.79 11.7736 21.3961 12.3731 21.4055 13.1125C21.4055 13.1291 21.4032 13.1457 21.4032 13.1599V22.8857C21.3771 23.6156 20.7877 24.1915 20.0514 24.1915H19.2229C18.8252 24.1915 18.4985 24.5161 18.4985 24.9166C18.4985 25.3171 18.8228 25.6418 19.2229 25.6418H20.0538C20.8374 25.6418 21.5855 25.3053 22.1134 24.7294C22.6413 25.3266 23.4036 25.675 24.2061 25.6773H25.037C25.4347 25.6773 25.7614 25.3527 25.7614 24.9545C25.7614 24.5564 25.4371 24.2294 25.037 24.2294L25.0347 24.227Z"
					fill="#5E92FF"
				/>
				<path
					d="M17.6504 14.0633C18.2896 14.7174 18.6092 15.7079 18.6092 17.0398V21.7723C18.6092 22.1207 18.505 22.3956 18.299 22.597C18.0931 22.7985 17.809 22.898 17.4468 22.898C17.1106 22.898 16.836 22.7937 16.623 22.5875C16.4099 22.3814 16.3034 22.1088 16.3034 21.7723V21.3458C16.0832 21.8505 15.7376 22.2439 15.2665 22.5283C14.7954 22.8127 14.2485 22.9549 13.6283 22.9549C13.0081 22.9549 12.421 22.8245 11.9049 22.5662C11.3888 22.3079 10.9816 21.9524 10.6833 21.4998C10.3851 21.0472 10.2383 20.5424 10.2383 19.9878C10.2383 19.2887 10.4158 18.7413 10.7709 18.3384C11.126 17.9379 11.7037 17.6465 12.5038 17.4663C13.304 17.2862 14.4095 17.1938 15.8157 17.1938H16.301V16.7483C16.301 16.1155 16.1661 15.6534 15.8938 15.3619C15.6216 15.0705 15.1836 14.9259 14.5776 14.9259C14.2036 14.9259 13.8224 14.9804 13.4342 15.0918C13.0459 15.2008 12.589 15.3596 12.0588 15.5681C11.7226 15.7364 11.4764 15.8193 11.3225 15.8193C11.0905 15.8193 10.8988 15.7364 10.752 15.5681C10.6028 15.3999 10.5295 15.1795 10.5295 14.9093C10.5295 14.6889 10.5839 14.4993 10.6952 14.3382C10.8041 14.177 10.9887 14.0254 11.2468 13.8832C11.6989 13.6367 12.2387 13.4448 12.8637 13.3002C13.491 13.158 14.1065 13.0869 14.7149 13.0869C16.0311 13.0869 17.0112 13.4139 17.6504 14.0657V14.0633ZM15.6926 20.5613C16.0998 20.1277 16.3034 19.5684 16.3034 18.8835V18.4759H15.9554C15.0889 18.4759 14.419 18.5138 13.9408 18.592C13.4626 18.6702 13.1193 18.8053 12.9134 18.9996C12.7074 19.194 12.6032 19.4594 12.6032 19.7959C12.6032 20.2106 12.7477 20.5495 13.0388 20.8149C13.33 21.0803 13.6875 21.213 14.1136 21.213C14.7599 21.213 15.2854 20.9974 15.6926 20.5637V20.5613Z"
					fill="#E861C9"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_8622_17518"
					x1="2.97191"
					y1="31.4664"
					x2="31.5022"
					y2="2.9661"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00EEFF" />
					<stop offset="1" stopColor="#A251FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_8622_17518"
					x1="4.52634"
					y1="33.0614"
					x2="33.0566"
					y2="4.56114"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF5E00" />
					<stop offset="1" stopColor="#DC51FF" />
				</linearGradient>
				<clipPath id="clip0_8622_17518">
					<rect width="36" height="36" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Logo;
