import { Step } from 'intro.js-react';
export const SolicitationSteps: Step[] = [
	{
		element: '.solicitation-button-wrapper',
		title: 'Start a new solicitation',
		intro:
			'You can start a new solicitation by pressing either of these options.',
		position: 'bottom',
	},
	{
		element: '.solicitations',
		title: 'Solicitations',
		intro:
			'Solicitations that you have uploaded but have not started are placed here.',
		position: 'bottom',
	},
	{
		element: '.proposals',
		title: 'Proposals in progress',
		intro:
			'Solicitations that are being worked on are here. You can click on the heart icon to favorite proposals that you like.',
		position: 'bottom',
	},
	// {
	// 	element: '.users-avatar',
	// 	title: 'Assign a team member to proposal',
	// 	intro: 'You can assign writers to your proposal by clicking here.',
	// 	position: 'left',
	// },
];
