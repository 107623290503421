const Active = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="31"
			height="37"
			viewBox="0 0 31 37"
			fill="none"
		>
			<path
				d="M22.484 18.6552C22.4841 20.064 22.0665 21.4412 21.284 22.6127C20.5014 23.7841 19.389 24.6972 18.0875 25.2365C16.786 25.7757 15.3538 25.9169 13.972 25.6421C12.5903 25.3674 11.321 24.6891 10.3248 23.693C9.32858 22.6968 8.65011 21.4277 8.37521 20.046C8.1003 18.6642 8.24131 17.232 8.7804 15.9304C9.31949 14.6289 10.2324 13.5164 11.4038 12.7337C12.5752 11.951 13.9523 11.5332 15.3611 11.5332C16.2965 11.5331 17.2227 11.7172 18.0869 12.0751C18.951 12.433 19.7363 12.9576 20.3977 13.6189C21.0591 14.2803 21.5838 15.0654 21.9418 15.9296C22.2997 16.7937 22.484 17.7199 22.484 18.6552ZM14.5373 22.4267L19.8214 17.1427C19.9075 17.0565 19.9559 16.9396 19.9559 16.8177C19.9559 16.6958 19.9075 16.5789 19.8214 16.4927L19.1713 15.8427C19.0851 15.7565 18.9682 15.7081 18.8463 15.7081C18.7245 15.7081 18.6076 15.7565 18.5213 15.8427L14.2127 20.1521L12.2001 18.1403C12.1139 18.0542 11.997 18.0057 11.8751 18.0057C11.7532 18.0057 11.6363 18.0542 11.5501 18.1403L10.9001 18.7903C10.8139 18.8765 10.7655 18.9934 10.7655 19.1153C10.7655 19.2372 10.8139 19.3541 10.9001 19.4403L13.8873 22.4275C13.9735 22.5137 14.0904 22.5621 14.2123 22.5621C14.3342 22.5621 14.4511 22.5129 14.5373 22.4267Z"
				fill="white"
			/>
			<path
				d="M23.9933 36.9989H6.01397C4.41937 36.9976 2.89045 36.3635 1.7629 35.236C0.635344 34.1084 0.00130902 32.5795 0 30.9849V6.01788C0.00130902 4.42327 0.635344 2.89436 1.7629 1.7668C2.89045 0.639248 4.41937 0.00521527 6.01397 0.00390625L23.9933 0.00390625C25.5879 0.00521527 27.1168 0.639248 28.2443 1.7668C29.3719 2.89436 30.0059 4.42327 30.0072 6.01788V30.9866C30.0059 32.5812 29.3719 34.1101 28.2443 35.2376C27.1168 36.3652 25.5879 36.9992 23.9933 37.0005M6.01397 2.64593C5.12071 2.64833 4.26478 3.00446 3.63346 3.6364C3.00214 4.26833 2.64685 5.12462 2.64532 6.01788V30.9866C2.64706 31.8797 3.00245 32.7357 3.63375 33.3675C4.26505 33.9993 5.12086 34.3553 6.01397 34.3577H23.9933C24.8867 34.3559 25.743 34.0003 26.3748 33.3687C27.0066 32.737 27.3624 31.8808 27.3644 30.9874V6.01788C27.3624 5.12448 27.0066 4.26824 26.3748 3.63659C25.743 3.00494 24.8867 2.64932 23.9933 2.64758L6.01397 2.64593Z"
				fill="white"
			/>
			<path
				d="M21.5574 9.88062C21.2078 9.88062 20.8725 9.74175 20.6253 9.49455C20.3781 9.24735 20.2393 8.91208 20.2393 8.56249V1.32101C20.2393 0.970659 20.3784 0.634654 20.6262 0.386916C20.8739 0.139178 21.2099 0 21.5603 0C21.9106 0 22.2466 0.139178 22.4944 0.386916C22.7421 0.634654 22.8813 0.970659 22.8813 1.32101V7.21882L28.6679 7.13067H28.6885C29.0388 7.12805 29.3759 7.26471 29.6255 7.5106C29.8751 7.75648 30.0168 8.09144 30.0194 8.4418C30.022 8.79215 29.8853 9.1292 29.6394 9.37879C29.3936 9.62838 29.0586 9.77008 28.7082 9.7727L21.5805 9.88062H21.5574Z"
				fill="white"
			/>
		</svg>
	);
};

export default Active;
