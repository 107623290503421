import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { useAppSelector } from '@/lib/hooks/hooks';
import useProposalId from '@/lib/hooks/useProposalId';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

interface LogicalFrameDialogProps {}

const LogicalFrameDialog: FC<LogicalFrameDialogProps> = () => {
	const isOpen = useAppSelector(
		(root: RootState) => root.page.dialog?.type === 'openLogicalFrame'
	);
	const dispatch = useDispatch();

	const proposalId = useProposalId();
	const navigate = useNavigate();

	const handleClose = () => {
		dispatch(closeDialog());
		navigate(`/proposal/${proposalId}`);
	};

	const handleLogFrame = () => {
		navigate(`/proposal/${proposalId}/logical-frame`);
		dispatch(closeDialog());
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] p-5 rounded-2xl shadow-main bg-white">
				<DialogHeader>
					<p className="font-bold dynamic-large text-secondary">
						Logical Frame
					</p>
					<p className="py-2 text-black">
						Finally, would you like to generate a logical framework (Logframe)
						for this proposal at this time?
					</p>

					<div className="flex items-center justify-end gap-3">
						<SecondaryButton onClick={handleClose}>
							No, I can do it later
						</SecondaryButton>
						<PrimaryButton onClick={handleLogFrame}>
							Yes, Lets build a logframe now
						</PrimaryButton>
					</div>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default LogicalFrameDialog;
