import { SVGProps } from 'react';
const FileAdd = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={28}
		height={28}
		viewBox="0 0 28 28"
		fill="none"
		{...props}
	>
		<path
			fill={props.color || '#5D9BFD'}
			stroke={props.color || '#5D9BFD'}
			strokeWidth={0.424}
			d="M10.885 24.015H3.74A2.743 2.743 0 0 1 1 21.275V3.74C1 2.229 2.23 1 3.74 1h13.152a2.742 2.742 0 0 1 2.739 2.74v6.642a.548.548 0 0 1-1.095 0V3.74a1.646 1.646 0 0 0-1.645-1.644H3.741A1.646 1.646 0 0 0 2.097 3.74v17.535a1.646 1.646 0 0 0 1.644 1.644h7.146a.548.548 0 0 1 0 1.096h-.002Z"
		/>
		<path
			fill={props.color || '#5D9BFD'}
			stroke={props.color || '#5D9BFD'}
			strokeWidth={0.424}
			d="M15.796 10.864H4.836a.548.548 0 0 1 0-1.096h10.958a.548.548 0 0 1 0 1.096h.002Zm-4.385 4.384H4.835a.548.548 0 0 1 0-1.096h6.576a.548.548 0 0 1 0 1.096ZM10.316 6.48h-5.48a.548.548 0 1 1 0-1.097h5.48a.548.548 0 1 1 0 1.097Zm9.863 20.823a7.131 7.131 0 0 1-7.124-7.124c0-3.927 3.197-7.123 7.124-7.123 3.929 0 7.124 3.196 7.124 7.123 0 3.929-3.196 7.124-7.124 7.124Zm0-13.152a6.035 6.035 0 0 0-6.027 6.028 6.035 6.035 0 0 0 6.027 6.029 6.035 6.035 0 0 0 6.028-6.029 6.035 6.035 0 0 0-6.028-6.027Z"
		/>
		<path
			fill={props.color || '#5D9BFD'}
			stroke={props.color || '#5D9BFD'}
			strokeWidth={0.424}
			d="M20.179 24.015a.548.548 0 0 1-.548-.548v-6.576a.548.548 0 1 1 1.096 0v6.576a.548.548 0 0 1-.548.548Z"
		/>
		<path
			fill={props.color || '#5D9BFD'}
			stroke={props.color || '#5D9BFD'}
			strokeWidth={0.424}
			d="M23.468 20.727h-6.576a.548.548 0 0 1 0-1.095h6.576a.548.548 0 0 1 0 1.095Z"
		/>
	</svg>
);
export default FileAdd;
