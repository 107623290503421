export function convertNumberToCurrency(number: number) {
	return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function objectToQueryString(obj: any) {
	return Object.keys(obj)
		.map((key) => {
			// Check if the value is an array
			if (Array.isArray(obj[key])) {
				// Convert array values to a comma-separated string
				return obj[key]
					.map(
						(value) =>
							`${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`
					)
					.join('&');
			} else if (typeof obj[key] === 'object' && obj[key] !== null) {
				// Convert nested objects to query string recursively
				return objectToQueryString(obj[key]);
			} else {
				// Encode key and value
				return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
			}
		})
		.join('&');
}
