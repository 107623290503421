import { ColumnDef } from '@tanstack/react-table';
import Delete from '@/components/ui/icons/Delete';
import { Document } from '@/lib/types/apiTypes';
import IconButton from '@/components/ui/shared/Button/IconButton/IconButton';

export const columns = (
	handleDetachDocument: (id: number) => void
): ColumnDef<Document>[] => [
	{
		accessorKey: 'name',
		header: ({ column }) => {
			return (
				<h5 key={column.id} className="p-0 text-black bg-transparent">
					Document Name
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<a className="text-sm font-bold xl:text-base text-primaryDark line-clamp-2">
					{row.original.name}
				</a>
			);
		},
	},
	{
		accessorKey: 'category',
		header: () => <></>,
		cell: ({ row }) => {
			return (
				<p className="text-sm text-black xl:text-base">
					{row.original.attachment?.attachedAs || row.original.category}
				</p>
			);
		},
	},
	{
		accessorKey: 'id',
		header: () => <></>,
		cell: ({ row }) => {
			return (
				<IconButton
					className="h-0 p-0 bg-transparent"
					onClick={() => handleDetachDocument(row.original.id)}
				>
					<Delete fill={'#FF388C'} />
				</IconButton>
			);
		},
	},
];
