import { ChangeEvent, FC, KeyboardEvent } from 'react';
// import { Input } from '../ui/input';
import Send from '../ui/icons/Send';
import ExpandableTextarea from '../ui/shared/ExpandableTextarea/ExpandableTextarea';

interface ChatBoxProps {
	queryMessage: string;
	setQueryMessage: React.Dispatch<React.SetStateAction<string>>;
	onSubmit: () => void;
}

const ChatBox: FC<ChatBoxProps> = ({
	queryMessage,
	setQueryMessage,
	onSubmit,
}) => {
	const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setQueryMessage(event.target.value);
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			onSubmit();
			event.preventDefault(); // Prevent the default behavior (form submission)
		}
	};

	const handleSendClick = () => {
		onSubmit();
	};

	return (
		<div className={'relative z-10 px-5 mb-4'}>
			{/* <Input
				value={queryMessage}
				onChange={handleInputChange}
				onKeyDown={handleKeyPress}
				placeholder="Type a message"
				className="text-base bg-white border border-[#5d9bfd] h-[50px] rounded-2xl text-black pr-12"
			/> */}
			<ExpandableTextarea
				minRows={1}
				maxRows={13}
				value={queryMessage}
				onChange={handleInputChange}
				onKeyDown={handleKeyPress}
				placeholder="Type a message"
				className="text-base bg-white border border-[#5d9bfd] h-[50px] rounded-2xl text-black pr-12 py-4 min-h-12"
			/>
			<div className="absolute bottom-3.5 right-8" onClick={handleSendClick}>
				<Send />
			</div>
		</div>
	);
};

export default ChatBox;
