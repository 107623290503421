import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { Search } from 'lucide-react';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { useEffect, useRef, useState } from 'react';
import { Input } from '@/components/ui/input';
import DeleteCross from '@/components/ui/icons/new/DeleteCross';
import { Checkbox } from '@/components/ui/checkbox';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import useDebounce from '@/lib/hooks/useDebounce';
import {
	setTemplateTagFilter,
	setTemplateTagOptions,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { setSectionTagOptions } from '@/redux/slices/TemplateBuilder/sectionSlice';

const TemplateTagFilterDialog = () => {
	const isOpen = useAppSelector(
		(state: RootState) => state.page.dialog?.type === 'templateTagFilter'
	);
	const tagType = useAppSelector(
		(state: RootState) => state.page.dialog?.templateTagType
	);
	const dispatch = useAppDispatch();
	const sectionSearchRef = useRef<HTMLInputElement>(null);
	const [sectionSearch, setSectionSearch] = useState('');
	const templateTagOptions = useAppSelector(
		(root: RootState) => root.templates.templateTagOptions
	);
	const sectionTagOptions = useAppSelector(
		(root: RootState) => root.sections.sectionTagOptions
	);
	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const DialogClosed = () => {
		tagType === 'template'
			? dispatch(setTemplateTagOptions(templateTagOptions))
			: dispatch(setSectionTagOptions(sectionTagOptions));
		dispatch(closeDialog());
	};
	const toggleSelectedTags = (tag: string) => {
		if (selectedTags.includes(tag)) {
			setSelectedTags((prev) => prev.filter((c) => c !== tag));
		} else {
			setSelectedTags((prev) => [...prev, tag]);
		}
	};
	const clearTagFilter = () => {
		setSelectedTags([]);
	};

	const [sectionSearchTags, setSectionSearchTags] = useState(sectionTagOptions);
	const [templateSearchTags, setTemplateSectionSearchTags] =
		useState(templateTagOptions);
	const handleSearchChange = (search) => {
		setSectionSearch(search);
		const tagOptions =
			tagType === 'template' ? templateTagOptions : sectionTagOptions;
		if (search) {
			const filteredTags = tagOptions.filter((obj) =>
				// Check if any property of the object contains the search string
				Object.values(obj).some(
					(value) =>
						typeof value === 'string' &&
						value.toLowerCase().trim().includes(search.trim().toLowerCase())
				)
			);
			tagType === 'template'
				? setTemplateSectionSearchTags(filteredTags)
				: setSectionSearchTags(filteredTags);
		} else {
			tagType === 'template'
				? setTemplateSectionSearchTags(templateTagOptions)
				: setSectionSearchTags(sectionTagOptions);
		}
	};
	const handleApplyFilters = () => {
		dispatch(setTemplateTagFilter(selectedTags));
		DialogClosed();
	};
	const debouncedSearchChange = useDebounce(handleSearchChange, 600);
	useEffect(() => {
		tagType === 'template'
			? setTemplateSectionSearchTags(templateTagOptions)
			: setSectionSearchTags(sectionTagOptions);
	}, [tagType]);
	return (
		<Dialog open={isOpen}>
			<DialogContent
				hideCloseBtn={true}
				className={`flex flex-col h-[650px] overflow-hidden max-w-[700px] bg-white p-6`}
			>
				<div className="flex justify-between items-center border-b border-[#D9D9D9]">
					<h2 className="font-bold dynamic-xl-large text-black capitalize">
						Filter By Tags
					</h2>
					{/* <X
						onClick={() => {
							DialogClosed();
						}}
						className="w-10 h-10 p-2 bg-white rounded-full cursor-pointer stroke-primary"
					/> */}
					<TextButton
						type="button"
						className="text-secondary capitalize"
						onClick={() => {
							clearTagFilter();
						}}
					>
						Clear All
					</TextButton>
				</div>
				<div className="flex flex-row relative mt-1">
					<Input
						ref={sectionSearchRef}
						onChange={(event) => debouncedSearchChange(event.target.value)}
						defaultValue={sectionSearch}
						placeholder="Search"
						className="bg-white border-[#D3DDE2] h-12 p-4 dynamic-text placeholder:text-black rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-12"
					/>
					<div className="absolute right-3 top-4">
						<div className="flex flex-row items-center gap-2">
							{sectionSearch && (
								<DeleteCross
									onClick={() => {
										if (sectionSearchRef?.current) {
											sectionSearchRef.current.value = '';
											handleSearchChange('');
										}
									}}
								/>
							)}

							<Search color="#5D9BFD" size={16} />
						</div>
					</div>
				</div>
				{tagType &&
					tagType === 'template' &&
					templateSearchTags.length === 0 && (
						<div className="flex flex-col justify-center items-center">
							<p className="dynamic-text text-black font-bold text-center">
								Your searched tag is not available
							</p>
						</div>
					)}
				{tagType && tagType === 'section' && sectionSearchTags.length === 0 && (
					<div className="flex flex-col justify-center items-center">
						<p className="dynamic-text text-black font-bold text-center">
							Your searched tag is not available
						</p>
					</div>
				)}

				<div className="flex h-full overflow-y-scroll">
					<div className="grid grid-cols-3 gap-x-4 gap-y-2 text-black h-fit overflow-y-scroll items-start mt-1 pr-4">
						{tagType &&
							tagType === 'template' &&
							templateSearchTags.map((tag, i) => (
								<label
									key={tag.label + i}
									htmlFor={tag.label + i}
									className="py-2 flex items-center space-x-2 px-2 rounded-md cursor-pointer hover:bg-blue-50 transition-all duration-200 ease-in-out"
								>
									<Checkbox
										id={tag.label + i}
										className="text-white"
										checked={selectedTags.includes(tag.label)}
										onCheckedChange={() => toggleSelectedTags(tag.label)}
									/>
									<span className="text-sm grow font-medium leading-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
										{tag.label}
									</span>
								</label>
							))}

						{tagType &&
							tagType === 'section' &&
							sectionSearchTags.map((tag, i) => (
								<label
									key={tag.label + i}
									htmlFor={tag.label + i}
									className="py-2 flex items-center space-x-2 px-2 rounded-md cursor-pointer hover:bg-blue-50 transition-all duration-200 ease-in-out"
								>
									<Checkbox
										id={tag.label + i}
										className="text-white"
										checked={selectedTags.includes(tag.label)}
										onCheckedChange={() => toggleSelectedTags(tag.label)}
									/>
									<span className="grow text-sm font-medium leading-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
										{tag.label}
									</span>
								</label>
							))}
					</div>
				</div>

				<div className="flex flex-row items-center justify-end gap-4">
					<SecondaryButton
						type="button"
						className="uppercase"
						onClick={() => {
							DialogClosed();
						}}
					>
						Cancel
					</SecondaryButton>
					<PrimaryButton
						type="button"
						className="uppercase"
						onClick={handleApplyFilters}
					>
						Apply Filters
					</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};
export default TemplateTagFilterDialog;
