import { Step } from 'intro.js-react';
export const ProposalCreationStepOne: Step[] = [
	{
		element: '.solicitation-brief',
		title: 'Solicitation brief',
		intro: 'You can view a comprehensive summary of the proposal here.',
		position: 'bottom',
	},
	{
		element: '.read-rfp',
		title: 'Read solicitation',
		intro: 'You can read your uploaded solicitation here.',
		position: 'right',
	},
	{
		element: '.setup-proposal',
		title: 'Set up the proposal',
		intro: 'Once you are ready, click this link to continue the setup.',
		position: 'bottom',
	},
];
