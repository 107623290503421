import { TemplateBuilderQuestion } from '@/lib/types/TemplateBuilder/templateTypes';
export const defaultTemplateTagOptions: any = [
	{
		organizationIdentifier: undefined,
		label: 'Health',
		value: 'Health',
	},
	{
		organizationIdentifier: undefined,
		label: 'Education',
		value: 'Education',
	},
	{
		organizationIdentifier: undefined,
		label: 'Economic Development',
		value: 'Economic Development',
	},
	{
		organizationIdentifier: undefined,
		label: 'Agriculture and Food Security',
		value: 'Agriculture and Food Security',
	},
	{
		organizationIdentifier: undefined,
		label: 'Climate Change and Environmental Sustainability',
		value: 'Climate Change and Environmental Sustainability',
	},
	{
		organizationIdentifier: undefined,
		label: 'Disaster Relief and Preparedness',
		value: 'Disaster Relief and Preparedness',
	},
	{
		organizationIdentifier: undefined,
		label: 'Water, Sanitation, and Hygiene (WASH)',
		value: 'Water, Sanitation, and Hygiene (WASH)',
	},
	{
		organizationIdentifier: undefined,
		label: 'Gender Equality and Womens Empowerment',
		value: 'Gender Equality and Womens Empowerment',
	},
	{
		organizationIdentifier: undefined,
		label: 'Child Protection',
		value: 'Child Protection',
	},
	{
		organizationIdentifier: undefined,
		label: 'Refugee and Migrant Support',
		value: 'Refugee and Migrant Support',
	},
	{
		organizationIdentifier: undefined,
		label: 'Infrastructure Development',
		value: 'Infrastructure Development',
	},
	{
		organizationIdentifier: undefined,
		label: 'Peace and Security',
		value: 'Peace and Security',
	},
	{
		organizationIdentifier: undefined,
		label: 'Governance and Civil Society',
		value: 'Governance and Civil Society',
	},
	{
		organizationIdentifier: undefined,
		label: 'Health Systems Strengthening',
		value: 'Health Systems Strengthening',
	},
	{
		organizationIdentifier: undefined,
		label: 'Social Protection',
		value: 'Social Protection',
	},
	{
		organizationIdentifier: undefined,
		label: 'Innovation and Technology',
		value: 'Innovation and Technology',
	},
	{
		organizationIdentifier: undefined,
		label: 'Financial Inclusion',
		value: 'Financial Inclusion',
	},
	{
		organizationIdentifier: undefined,
		label: 'Urban Development',
		value: 'Urban Development',
	},
	{
		organizationIdentifier: undefined,
		label: 'Energy Access',
		value: 'Energy Access',
	},
	{
		organizationIdentifier: undefined,
		label: 'Legal Aid and Human Rights',
		value: 'Legal Aid and Human Rights',
	},
];
export const defaultSectionTagOptions: any = [
	{
		organizationIdentifier: undefined,
		label: 'Introduction',
		value: 'Introduction',
	},
	{
		organizationIdentifier: undefined,
		label: 'Problem Statement',
		value: 'Problem Statement',
	},
	{
		organizationIdentifier: undefined,
		label: 'Objectives And Outcomes',
		value: 'Objectives And Outcomes',
	},
	{
		organizationIdentifier: undefined,
		label: 'Implementation Plan',
		value: 'Implementation Plan',
	},
	{
		organizationIdentifier: undefined,
		label: 'Stakeholder Engagement',
		value: 'Stakeholder Engagement',
	},
	{
		organizationIdentifier: undefined,
		label: 'Monitoring And Evaluation',
		value: 'Monitoring And Evaluation',
	},
	{
		organizationIdentifier: undefined,
		label: 'Collaborating',
		value: 'Collaborating',
	},
	{
		organizationIdentifier: undefined,
		label: 'Learning And Adapting',
		value: 'Learning And Adapting',
	},
	{
		organizationIdentifier: undefined,
		label: 'Organizational Experience',
		value: 'Organizational Experience',
	},
];

export const defaultTreeCursorSvgBase64 =
	'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikdyb3VwIDQ0NzgiPgo8cGF0aCBpZD0ia2V5Ym9hcmRfYXJyb3dfcmlnaHQiIGQ9Ik04LjE2NDA2IDEuNDI0OTFMNS4wOTc0IDMuNzY1MTRMNC4xNjQwNiAzLjA1MjlMOC4xNjQwNiAwLjAwMDQzMDg5NkwxMi4xNjQxIDMuMDUyODlMMTEuMjMwNyAzLjc2NTE0TDguMTY0MDYgMS40MjQ5MVoiIGZpbGw9IiM1RDlCRkQiLz4KPHBhdGggaWQ9ImtleWJvYXJkX2Fycm93X3JpZ2h0XzIiIGQ9Ik04LjE2NDA2IDE0LjU3NTZMNS4wOTc0IDEyLjIzNTRMNC4xNjQwNiAxMi45NDc2TDguMTY0MDYgMTYuMDAwMUwxMi4xNjQxIDEyLjk0NzZMMTEuMjMwNyAxMi4yMzU0TDguMTY0MDYgMTQuNTc1NloiIGZpbGw9IiM1RDlCRkQiLz4KPHBhdGggaWQ9ImRyYWdfaGFuZGxlIiBkPSJNMCAxMS4wMjE4VjkuMTM5NDZIMTZWMTEuMDIxOEgwWk0wIDcuMjU3MTFWNS4zNzQ3NkgxNlY3LjI1NzExSDBaIiBmaWxsPSIjNUQ5QkZEIi8+CjwvZz4KPC9zdmc+Cg==';
export const selectedTreeCursorSvgBase64 =
	'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikdyb3VwIDQ0NzgiPgo8cGF0aCBpZD0ia2V5Ym9hcmRfYXJyb3dfcmlnaHQiIGQ9Ik04LjE2NDA2IDEuOTI0OTFMNS4wOTc0IDQuMjY1MTRMNC4xNjQwNiAzLjU1MjlMOC4xNjQwNiAwLjUwMDQzMUwxMi4xNjQxIDMuNTUyODlMMTEuMjMwNyA0LjI2NTE0TDguMTY0MDYgMS45MjQ5MVoiIGZpbGw9IiNFODYxQzkiLz4KPHBhdGggaWQ9ImtleWJvYXJkX2Fycm93X3JpZ2h0XzIiIGQ9Ik04LjE2NDA2IDE1LjA3NTZMNS4wOTc0IDEyLjczNTRMNC4xNjQwNiAxMy40NDc2TDguMTY0MDYgMTYuNTAwMUwxMi4xNjQxIDEzLjQ0NzZMMTEuMjMwNyAxMi43MzU0TDguMTY0MDYgMTUuMDc1NloiIGZpbGw9IiNFODYxQzkiLz4KPHBhdGggaWQ9ImRyYWdfaGFuZGxlIiBkPSJNMCAxMS41MjE4VjkuNjM5NDZIMTZWMTEuNTIxOEgwWk0wIDcuNzU3MTFWNS44NzQ3NkgxNlY3Ljc1NzExSDBaIiBmaWxsPSIjRTg2MUM5Ii8+CjwvZz4KPC9zdmc+Cg==';

export const generalSolicitationDropdown = [
	{
		label: 'Background',
		value: 'background',
	},
	{
		label: 'Outcomes',
		value: 'outcomes',
	},
	{
		label: 'Activities And End Customers',
		value: 'activitiesAndEndCustomers',
	},
	{
		label: 'Geographic Location',
		value: 'geographicLocation',
	},
	{
		label: 'Partnerships And Coordination',
		value: 'partnershipsAndCoordination',
	},
	{
		label: 'Eligibility And Qualifications',
		value: 'eligibilityAndQualifications',
	},
	{
		label: 'Timeline And Delivery',
		value: 'timelineAndDelivery',
	},
	{
		label: 'Submission Requirements',
		value: 'submissionRequirements',
	},
	{
		label: 'Evaluation Criterion',
		value: 'evaluationCriterion',
	},
	{
		label: 'Scope Of Work',
		value: 'scopeOfWork',
	},
];

export const templateQuestions: TemplateBuilderQuestion[] = [
	{
		text: 'Needs and goals: State the problem(s) that will be addressed through this proposed project.',
		answer: null,
		transformedAnswer: '',
		heading: '',
		description: '',
		rfpSections: ['background', 'outcomes'],
		proTip: '',
		order: 0,
	},
	{
		text: 'Your solution: Outline the solutions and/or strategies that we propose to implement to address these problems.',
		answer: null,
		transformedAnswer: '',
		heading: '',
		description: '',
		rfpSections: [
			'outcomes',
			'activitiesAndEndCustomers',
			'geographicLocation',
			'partnershipsAndCoordination',
		],
		proTip: '',
		order: 1,
	},
	{
		text: 'Your advantages: State our value proposition and/or win themes that distinguish us from competitors, including any innovations.',
		answer: null,
		transformedAnswer: '',
		heading: '',
		description: '',
		rfpSections: [
			'geographicLocation',
			'partnershipsAndCoordination',
			'timelineAndDelivery',
		],
		proTip: '',
		order: 2,
	},
	{
		text: 'Partnerships and collaborations: Please include names and scopes of partner organizations and/or subcontractors as well as any relationships you have with local partners that can help you execute on this project.',
		answer: null,
		transformedAnswer: '',
		heading: '',
		description: '',
		rfpSections: ['partnershipsAndCoordination'],
		proTip: '',
		order: 3,
	},
];

export const defaultAiSection = {
	name: '',
	alias: '',
	description: '',
	defaultContext: '',
	specialContext: '',
	tableHeadings: '',
	expectedOutput: '',
	wordCount: 0,
	tags: [],
	solicitation: [],
	questions: [],
};
