import React, { useEffect } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAppDispatch } from '@/lib//hooks/hooks';
import useAuth from '../hooks/useAuth';
import { clearState } from '../functions/funcUtils';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { clearIntermediatePage } from '@/redux/slices/pageSlice';
import useUser from '../hooks/useUser';

const PrivateRoute: React.FC = () => {
	const dispatch = useAppDispatch();
	const { isAuthenticated, isAdmin } = useAuth();
	const user = useUser();
	const initialSetupStep = user?.organization?.initialSetupStep;
	const intermediatePage = useSelector(
		(root: RootState) => root.page.intermediatePage
	);
	const location = useLocation();

	useEffect(() => {
		if (!isAuthenticated && user) clearState(dispatch);
	}, [dispatch, isAuthenticated, user]);

	useEffect(() => {
		if (
			intermediatePage?.scope &&
			!intermediatePage.scope.find((pathname) =>
				location.pathname.includes(pathname)
			)
		) {
			dispatch(clearIntermediatePage());
		}
	}, [dispatch, intermediatePage, location.pathname]);

	if (isAuthenticated && (!initialSetupStep || !isAdmin)) {
		return <Outlet />; // This renders the child routes
	} else if (isAuthenticated) {
		return <Navigate to="/setup-organization" />;
	} else {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}
};

export default PrivateRoute;
