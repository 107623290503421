import { Step } from 'intro.js-react';
export const OrganizationSettingsSteps: Step[] = [
	{
		element: '.edit-organization',
		title: 'Organization information',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					You can edit your organization’s background information in this
					section.
				</p>
			</div>
		),
		position: 'bottom',
	},
	{
		element: '.profile-organization',
		title: 'Organization profile',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					You can set up an Organizational profile. This is what the AI will use
					as a basis for its understanding of your organization's work. Please
					make sure this section is fully filled out to your satisfaction.
				</p>
			</div>
		),
		position: 'top',
	},
	{
		element: '.users',
		title: 'Users',
		intro:
			'You can add new users with different levels of access privileges by clicking here.',
		position: 'bottom',
	},
	{
		element: '.proposal-settings',
		title: 'Writing voice',
		intro:
			'You can set up a default tone of voice for your organization. This tone of voice will be inherited for every proposal as a starting point.',
		position: 'bottom',
	},
	{
		element: '.audit-log',
		title: 'Audit logs',
		intro: 'You can keep track of all users activities in your organization.',
		position: 'bottom',
	},
];
