import { FC, ReactNode, memo, useEffect, useState } from 'react';
import TableHeader from '../TableHeader/TableHeader';
import { SolicitationTable } from '../Tables/SolicitationTable/SolicitationTable';
import { ProposalTable } from '../Tables/ProposalTable/ProposalTable';
import { RootState } from '@/redux/store/store';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { clearPageState, setLastVisitPage } from '@/redux/slices/pageSlice';
import { getColumns } from '../Tables/ProposalTable/columns';
import { managedAssignedProposals } from '@/lib/functions/apiCalls';
import { ProposalAction, SortingType, Team } from '@/lib/types/apiTypes';
import customToast from '../CustomToast/CustomToast';
import { useQueryClient } from 'react-query';
import { clearProposal } from '@/redux/slices/proposalSlice';
import { PaginationState, Updater } from '@tanstack/react-table';
import LoaderPanel from '../Loader/LoaderPanel/LoaderPanel';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import useUser from '@/lib/hooks/useUser';
import useAuth from '@/lib/hooks/useAuth';
import { clearEnhanceSlice } from '@/redux/slices/enhanceSlice';

interface TableCardProps {
	columns: any;
	data: any;
	title: string;
	type: 'proposal' | 'solicitation';
	isRemovedDisabled?: boolean;
	handleRemove?: () => void;
	isProposalFetching?: boolean;
	proposalTotalItems?: number;
	proposalTotalPages?: number;
	proposalPagination?: PaginationState;
	setProposalPagination?: (pagination: Updater<PaginationState>) => void;
	proposalSortOrder?: SortingType;
	setProposalSortOrder?: (sortOrder: SortingType) => void;
	setFiltersSearch?: (searchTerm: string) => void;
	filtersPanel?: ReactNode;
	appliedFiltersCount?: number;
	teams?: Team[];
}

const TableCard: FC<TableCardProps> = ({
	columns,
	data,
	title,
	type,
	handleRemove,
	isRemovedDisabled,
	isProposalFetching,
	proposalTotalItems,
	proposalTotalPages,
	proposalPagination,
	setProposalPagination,
	proposalSortOrder,
	setProposalSortOrder,
	setFiltersSearch,
	filtersPanel,
	teams,
}) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const activeProposal = useAppSelector(
		(root: RootState) => root.proposal.proposal
	);
	const { resetActiveSection } = useActiveSection();
	const user = useUser();
	const { isMember } = useAuth();

	// State for multiple selected row IDs
	const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

	const handleNavigate = (id) => {
		dispatch(clearPageState());
		dispatch(clearProposal());
		resetActiveSection();
		dispatch(clearEnhanceSlice());
		if (!activeProposal || activeProposal?.id !== id) {
			navigate(`/proposal/${id}`);
			dispatch(setLastVisitPage({ page: 'dashboard' }));
		}
	};

	useEffect(() => {
		// Clear the selected rows when data changes
		setSelectedRowIds([]);
	}, [data]);

	const handleArchive = async (proposalIds?: number[]) => {
		try {
			const response = await managedAssignedProposals('archive', {
				proposalIds: proposalIds?.length
					? proposalIds
					: selectedRowIds.map((id) => Number(id)),
			});
			if (response.statusCode === 201) {
				customToast.success({
					title: `Proposals Archived successfully`,
				});
				queryClient.invalidateQueries(['proposals']);
				setSelectedRowIds([]);
			}
		} catch (err) {
			console.log(err);
			customToast.error({
				title: 'Something went wrong',
			});
		}
	};

	const handleBulkFavorite = async () => {
		try {
			const response = await managedAssignedProposals('favourite', {
				proposalIds: selectedRowIds.map((id) => Number(id)),
			});
			if (response.statusCode === 201) {
				customToast.success({
					title: `Proposals Favourited successfully`,
				});
				queryClient.invalidateQueries(['proposals']);
				setSelectedRowIds([]);
			}
		} catch (err) {
			console.log(err);
			customToast.error({
				title: 'Something went wrong',
			});
		}
	};

	const handleFavorite = async (action: ProposalAction, id: number) => {
		try {
			const response = await managedAssignedProposals(action, {
				proposalIds: [id],
			});
			if (response.statusCode === 201) {
				queryClient.invalidateQueries(['proposals']);
			}
		} catch (err) {
			console.log(err);
			customToast.error({
				title: 'Something went wrong',
			});
		}
	};

	switch (type) {
		case 'solicitation':
			return (
				<div className="solicitations">
					<TableHeader
						count={data?.length}
						title={title}
						onDelete={handleRemove}
						isRemovedDisabled={isRemovedDisabled}
					/>
					<div className="mx-auto max-w-[97%]">
						<SolicitationTable
							columns={columns}
							data={data}
							isRemovedDisabled={isRemovedDisabled}
						/>
					</div>
				</div>
			);
		case 'proposal':
			return (
				<div className="proposals">
					<TableHeader
						count={proposalTotalItems || data?.length}
						title={title}
						onFavorite={handleBulkFavorite}
						onArchive={handleArchive}
						filtersPanel={filtersPanel}
						onSearchChange={(searchTerm) => {
							setFiltersSearch && setFiltersSearch(searchTerm);
						}}
						isArchiveDisabled={selectedRowIds.length === 0}
						isFavDisabled={selectedRowIds.length === 0}
					/>
					<div className="relative mx-auto max-w-[97%]">
						<ProposalTable
							columns={getColumns({
								handleNavigate,
								handleFavorite,
								handleArchive,
								setSelectedRowIds,
								selectedRowIds,
								teams,
								...(user && { user }),
								isMember,
							})}
							data={data}
							totalPages={proposalTotalPages}
							pagination={proposalPagination}
							setPagination={setProposalPagination}
							sortOrder={proposalSortOrder}
							setSortOrder={setProposalSortOrder}
						/>
						{!!isProposalFetching && <LoaderPanel className="rounded-xl" />}
					</div>
				</div>
			);
		default:
			return null;
	}
};

const MemoTableCard = memo(TableCard);

export default MemoTableCard;
