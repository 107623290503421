import { FC, useEffect, useMemo, useState } from 'react';
import styles from './ComplianceMatrixPanel.module.css';
import { Plus, X } from 'lucide-react';
import { cn } from '@/lib/utils';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { ComplianceMatrixTable } from '@/components/Tables/ComplianceMatrixTable/ComplianceMatrixTable';
import { getColumns } from '@/components/Tables/ComplianceMatrixTable/columns';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closePanel, openDialog } from '@/redux/slices/pageSlice';
import LoadingLogo from '@/assets/lotties/logo_loading_v1.json';
import Lottie from 'lottie-react';
import { Input } from '@/components/ui/input';
import { ComplianceRule } from '@/lib/types/apiTypes';
import { CSVLink } from 'react-csv';
import { PortableMultiSelect } from '@/components/ui/shared/PortableMultiSelect/PortableMultiSelect';
import { transformSectionsRecursively } from '@/lib/functions/funcUtils';
import Filters from '@/components/ui/icons/matrix/Filters';
import Download from '@/components/ui/icons/new/Dashboard/Download';

interface ComplianceMatrixPanelProps {
	open: boolean;
	setOpen?: (open: boolean) => void;
}

const ComplianceMatrixPanel: FC<ComplianceMatrixPanelProps> = ({
	open,
	setOpen,
}) => {
	const dispatch = useAppDispatch();
	const complianceMatrix = useSelector(
		(root: RootState) => root.compliance.complianceMatrix
	);
	const sections = useSelector(
		(root: RootState) => root.proposal.proposal?.sections
	);
	const loading = useSelector((root: RootState) => root.compliance.loading);

	const [data, setData] = useState<ComplianceRule[]>([]);
	const [enableFilter, setEnableFilter] = useState<boolean>(false);
	const [selectedSections, setSelectedSections] = useState<number[]>([]);
	const transformSectionOptions =
		useMemo(() => transformSectionsRecursively(sections || []), [sections]) ||
		[];

	const exportData = useMemo(() => {
		return data.map((item) => {
			return {
				Statement: item.statement,
				Reference: item.solicitationReference,
				Explanation: item.explanation,
				Sections: item.sections.map((section) => section.name).join(', '),
			};
		});
	}, [data]);

	useEffect(() => {
		let filteredData = complianceMatrix?.rules || [];
		if (selectedSections.length > 0) {
			filteredData = filteredData.filter((rule) =>
				rule.sections.some((section) => selectedSections.includes(section.id))
			);
		}
		setData(filteredData);
	}, [complianceMatrix, selectedSections]);

	useEffect(() => {
		if (!enableFilter) {
			setData(complianceMatrix?.rules || []);
		}
	}, [enableFilter, complianceMatrix]);

	const handleFilterData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		const filteredData = complianceMatrix?.rules?.filter((rule) => {
			return rule.statement.toLowerCase().includes(value.toLowerCase());
		});
		setData(filteredData || []);
	};

	const handleEditMatrix = (id: number) => {
		if (!id) return;
		dispatch(
			openDialog({
				type: 'editMatrixRuleWarning',
				complianceRuleId: id,
			})
		);
	};

	const handleDeleteMatrix = async (id: number) => {
		if (!id) return;
		dispatch(openDialog({ type: 'deleteMatrixRule', complianceRuleId: id }));
	};

	const handleSectionChange = (
		transformArray: {
			label: string;
			value: number;
		}[]
	) => {
		const selectedIds = transformArray.map((section) => section.value);
		setSelectedSections(selectedIds);
	};

	return (
		<>
			<div
				className={cn(
					styles.panel,
					open ? styles.open : '',
					'h-[calc(100vh-50px)] overflow-y-scroll hidden-scroll'
				)}
			>
				<div className="sticky top-0 z-10 py-4 bg-white">
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-3 shrink-0">
							<X
								onClick={() => {
									setOpen && setOpen(false);
									dispatch(closePanel());
								}}
								className="w-8 h-8 p-2 rounded-full text-primary bg-lightBackground"
							/>
							<h2 className="font-bold text-black dynamic-xl-large">
								Compliance Matrix
							</h2>
						</div>
						<div className="flex items-center justify-end w-full gap-4">
							<div
								tabIndex={0}
								role="button"
								onClick={() => setEnableFilter(!enableFilter)}
								className="flex items-center gap-2 font-medium uppercase text-primary animated-hover-effect"
							>
								{enableFilter ? <X /> : <Filters />} Filters
							</div>
							<CSVLink
								className="flex items-center gap-2 font-medium uppercase text-primary animated-hover-effect"
								data={exportData}
								filename={`compliance_matrix.csv`}
							>
								<Download /> Export
							</CSVLink>
							<Input
								onChange={(e) => handleFilterData(e)}
								placeholder="Search Statement"
								className="w-full h-12 max-w-xs bg-white rounded-xl"
							/>
							<PrimaryButton
								onClick={() =>
									dispatch(
										openDialog({
											type: 'addMatrixRule',
										})
									)
								}
							>
								<Plus /> Add Another
							</PrimaryButton>
						</div>
					</div>
					{enableFilter && (
						<div className="flex items-center justify-end mt-4">
							<div className="w-full max-w-sm">
								<PortableMultiSelect
									options={transformSectionOptions}
									value={[]}
									onSelectedValuesChange={(selected) => {
										const transformed = selected.map((s) => ({
											label: s.label,
											value: parseInt(s.value),
										}));
										handleSectionChange(transformed);
									}}
									placeholder="Filter by Section"
								/>
							</div>
						</div>
					)}
				</div>

				{loading ? (
					<div className="flex items-center justify-center w-full h-[calc(100vh-150px)]">
						<Lottie
							className="block animation-lottie max-h-32 max-w-32"
							animationData={LoadingLogo}
							loop={true}
							autoPlay={true}
						/>
					</div>
				) : (
					<ComplianceMatrixTable
						columns={getColumns(handleEditMatrix, handleDeleteMatrix)}
						data={data}
					/>
				)}
			</div>
			<div className={cn(styles.overlay, open ? 'block' : 'hidden')}></div>
		</>
	);
};

export default ComplianceMatrixPanel;
