import { FC, useEffect, useState } from 'react';
import { Search, X } from 'lucide-react';
import { Input } from '../ui/input';
import MessagesList from '../MessagesList/MessagesList';
import {
	attachDocumentToSection,
	attachFormativeDocument,
	detachDocumentFromSection,
	detachFormativeDocument,
	findDocuments,
} from '@/lib/functions/apiCalls';
import { AddDocumentsTypes, FindDocuments } from '@/lib/types/apiTypes';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import useDebounce from '@/lib/hooks/useDebounce';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

interface DocumentChatBoxProps {
	addDocumentType: AddDocumentsTypes;
	setIsLoading: (isLoading: boolean) => void;
	handleReadDocument: (docData: FindDocuments) => void;
}

const DocumentChatBox: FC<DocumentChatBoxProps> = ({
	addDocumentType,
	setIsLoading,
	handleReadDocument,
}) => {
	const dispatch = useAppDispatch();
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const [queryMessage, setQueryMessage] = useState('');
	const [documents, setDocuments] = useState<FindDocuments[]>();
	const [isMessageLoading, setIsMessageLoading] = useState(false);
	const [isSuggestedList, setIsSuggestedList] = useState(true);

	const { activeSection } = useActiveSection();
	const proposalId = proposal?.id;
	const nodeId = activeSection.sectionId;

	const handleSubmit = async (refetching = false) => {
		const query = queryMessage || proposal?.rfpSummary;
		if (!query || !proposalId) return;
		if (!refetching) {
			setIsMessageLoading(true);
		}
		const response = await findDocuments(query, {
			...(proposalId && { proposalId: proposalId }),
			...(nodeId &&
				addDocumentType === 'attachSectionDocument' && {
					sectionId: Number(nodeId),
				}),
		});
		if (response.statusCode === 201) {
			const documentsResponse = (
				response.data as { documents: FindDocuments[] }
			).documents;
			setDocuments(documentsResponse);
			setIsSuggestedList(queryMessage === '');
			setIsMessageLoading(false);
			setIsLoading(false);
			if (refetching) {
				dispatch(fetchProposalById(proposalId));
			}
		} else {
			console.log('error');
			setIsMessageLoading(false);
		}
	};

	const debouncedSearchChange = useDebounce(handleSubmit, 600);

	const handleSuccess = async () => {
		handleSubmit(true);
	};

	const handleError = (error: unknown) => {
		ErrorHandle(dispatch, error);
		setIsLoading(false);
	};

	const handleAttachDocumentToSection = async (
		documentId: number,
		category: string
	) => {
		try {
			if (!proposalId) return;
			const response = await attachDocumentToSection(
				proposalId,
				Number(nodeId),
				documentId,
				category
			);
			handleResponse(response);
			handleSuccess();
		} catch (error: unknown) {
			handleError(error);
		}
	};

	const handleAttachFormativeDocument = async (
		documentId: number,
		category: string
	) => {
		try {
			if (!proposalId) return;
			const response = await attachFormativeDocument(
				proposalId,
				documentId,
				category
			);
			handleResponse(response);
			handleSuccess();
		} catch (error: unknown) {
			handleError(error);
		}
	};

	const handleAttachment = async (documentId: number, category: string) => {
		setIsLoading(true);
		switch (addDocumentType) {
			case 'attachFormativeDocument':
				handleAttachFormativeDocument(documentId, category);
				break;
			case 'attachSectionDocument':
				handleAttachDocumentToSection(documentId, category);
				break;
		}
	};

	const handleDetachFormativeDocument = async (documentId: number) => {
		try {
			if (!proposalId) return;
			const response = await detachFormativeDocument(proposalId, documentId);
			handleResponse(response);
			handleSuccess();
		} catch (error: unknown) {
			handleError(error);
		}
	};

	const handleDetachDocumentToSection = async (documentId: number) => {
		try {
			if (!proposalId) return;
			const response = await detachDocumentFromSection(
				proposalId,
				Number(nodeId),
				documentId
			);
			handleResponse(response);
			handleSuccess();
		} catch (error: unknown) {
			handleError(error);
		}
	};

	const handleDetachment = async (documentId: number) => {
		setIsLoading(true);
		switch (addDocumentType) {
			case 'attachFormativeDocument':
				handleDetachFormativeDocument(documentId);
				break;
			case 'attachSectionDocument':
				handleDetachDocumentToSection(documentId);
				break;
		}
	};

	useEffect(() => {
		if (proposal?.rfpSummary) {
			handleSubmit();
		}
	}, []);

	return (
		<>
			<div className="relative flex items-center justify-between">
				<Input
					value={queryMessage}
					onChange={(event) => {
						setQueryMessage(event.target.value);
						debouncedSearchChange();
					}}
					placeholder="Search"
					className="bg-white border-[#D3DDE2] h-12 py-2 pr-10 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
				/>
				<div className="absolute right-3 top-3">
					{queryMessage !== '' ? (
						<X
							onClick={() => {
								setQueryMessage('');
								debouncedSearchChange();
							}}
							className="pt-1 cursor-pointer"
							color="#5D6F79"
							size={20}
						/>
					) : (
						<Search color="#5D9BFD" className="pt-1 w-5 h-5" />
					)}
				</div>
			</div>
			<MessagesList
				list={documents as FindDocuments[]}
				isMessageLoading={isMessageLoading}
				handleReadDocument={handleReadDocument}
				handleAddDocument={handleAttachment}
				handleRemoveDocument={handleDetachment}
				isSuggestedList={isSuggestedList}
			/>
		</>
	);
};

export default DocumentChatBox;
