import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { UseAuthType } from '../types/constants';

const useAuth = (): UseAuthType => {
	const { user, isAuthenticated } = useSelector(
		(state: RootState) => state.auth
	);

	return {
		isAdmin: user?.role?.name === 'admin' || user?.role?.name === 'super_admin',
		isMember: user?.role?.name === 'member',
		isGuest: user?.role?.name === 'guest',
		isAuthenticated: isAuthenticated,
		hasTemplateBuilderAccess: user?.templateBuilderAccess || false,
	};
};

export default useAuth;
