import { SectionBuilder } from '@/lib/types/TemplateBuilder/templateTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SectionState {
	error: string | null;
	isLoading: boolean;
	sections: SectionBuilder[];
	editSection: SectionBuilder | null;
	sectionTagOptions: any;
}

const initialState: SectionState = {
	isLoading: false,
	sections: [],
	error: null,
	editSection: null,
	sectionTagOptions: [],
};

const authSlice = createSlice({
	name: 'sections',
	initialState,
	reducers: {
		setSectionData(state, action: PayloadAction<SectionBuilder[]>) {
			state.sections = action.payload;
		},
		setEditSectionData(state, action: PayloadAction<SectionBuilder | null>) {
			state.editSection = action.payload;
		},
		setSectionTagOptions(state, action: PayloadAction<any[]>) {
			state.sectionTagOptions = action.payload;
		},
	},
});

export const { setSectionData, setEditSectionData, setSectionTagOptions } =
	authSlice.actions;
export default authSlice.reducer;
