import { cn } from '@/lib/utils';
import { RootState } from '@/redux/store/store';
import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

interface ProposalCreationHeaderProps {
	title: string;
	agency: string;
	source: string;
	viewRFP: () => void;
}

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: { display: false },
		tooltip: { enabled: false },
	},
};

const ProposalCreationHeader: FC<ProposalCreationHeaderProps> = ({
	title,
	agency,
	source,
	viewRFP,
}) => {
	const proposal = useSelector((root: RootState) => root.proposal.proposal);

	const currentStep = useSelector(
		(root: RootState) => root.proposal.currentStep
	);
	const stepChartData = {
		datasets: [
			{
				label: 'Proposal Step',
				data: [currentStep, 5 - currentStep],
				backgroundColor: ['#E861C9', '#D3DDE2'],
				borderColor: ['#E861C9', '#D3DDE2'],
				borderWidth: 5,
				cutout: 100,
			},
		],
	};

	return (
		<div className="z-10 flex justify-between pb-4 bg-background">
			{title && agency ? (
				<p className="flex items-center text-base font-bold xl:text-lg text-primaryDark">
					<span className="mr-4 font-bold text-black dynamic-medium">
						RESPONDING TO
					</span>
					<span
						className={cn(
							'dynamic-medium',
							!source ? 'pointer-events-none' : 'cursor-pointer'
						)}
						onClick={viewRFP}
					>
						{agency}s RFP: {title}
					</span>
				</p>
			) : (
				<p className="flex items-center text-base font-bold xl:text-lg text-primaryDark">
					<span className="mr-4 font-bold text-black dynamic-medium">
						RESPONDING TO
					</span>
					<span
						className={cn(
							'dynamic-medium',
							!proposal?.rfpLink ? 'pointer-events-none' : 'cursor-pointer'
						)}
						onClick={viewRFP}
					>
						{proposal?.rfpAgency}s RFP: {proposal?.rfpTitle}
					</span>
				</p>
			)}
			<div className="flex items-center gap-4 text-base font-bold text-black">
				<div>
					Step <span className="text-secondary">{currentStep}</span>/5
				</div>
				<div className="w-8 h-8">
					{stepChartData && (
						<Doughnut
							className="w-8 h-8 pointer-events-none"
							data={stepChartData}
							options={options}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProposalCreationHeader;
