import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import {
	pageTransitionEnd,
	pageTransitionStart,
	processSuccess,
	setProposal,
	setTemplate,
	updateStep,
} from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import {
	createProposalFromTemplate,
	getProposalById,
	updateProposal,
} from '@/lib/functions/apiCalls';
import { ErrorHandle, convertToISO8601 } from '@/lib/functions/funcUtils';
import {
	nextNofo,
	setCurrentStepQuestion,
	setQuestions,
} from '@/redux/slices/questionSlice';
import { ProposalResponseData } from '@/lib/types/apiTypes';
import { useNavigate } from 'react-router';

const ChangeTemplateDialog = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'confirmTemplate'
	);
	const selectedTemplate = useSelector(
		(root: RootState) => root.proposal.selectedTemplate
	);
	const currentStep = useSelector(
		(root: RootState) => root.proposal.currentStep
	);
	const rfp = useSelector((state: RootState) => state.solicitation.rfp);

	const createProposal = async () => {
		if (!rfp) {
			console.error('RFP data is missing. Cannot create proposal.');
			return;
		}
		try {
			if (selectedTemplate) {
				const response = await createProposalFromTemplate({
					templateId: selectedTemplate._id,
					rfpLink: rfp.sourceLink,
					documentId: rfp.documentId,
					rfpType: rfp.category,
					rfpCode: rfp.metaData.code,
					rfpSummary: rfp.metaData.summary,
					rfpTitle: rfp.metaData.title,
					rfpAgency: rfp.metaData.agency,
					rfpDeadline: rfp.metaData.deadline
						? convertToISO8601(rfp.metaData.deadline)
						: null,
					rfpFunding: rfp.metaData.funding,
				});

				if (response) {
					const proposalResponse = await getProposalById(response.id);
					if (proposalResponse.statusCode !== 200) {
						console.log('Error in fetching proposal', proposalResponse);
					} else {
						const proposal = (proposalResponse.data as ProposalResponseData)
							.proposal;
						await updateProposal(proposal?.id as number, proposal.rfpTitle);
						dispatch(setProposal(proposal));
						dispatch(setQuestions(proposal.questions));
						dispatch(setCurrentStepQuestion(proposal.questions[0]));
						dispatch(nextNofo());
						dispatch(processSuccess());
						dispatch(pageTransitionEnd());
					}
				}
			}
		} catch (error) {
			ErrorHandle(dispatch, error);
			navigate('/solicitations');
		}
	};

	const handleClick = async () => {
		if (selectedTemplate) {
			dispatch(setTemplate(selectedTemplate));
			if (rfp) {
				dispatch(pageTransitionStart());
				dispatch(updateStep(currentStep + 1));
				createProposal();
				dispatch(closeDialog());
			}
		}
	};

	const cancelChange = () => {
		if (!selectedTemplate) return;
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[450px] bg-white rounded-2xl"
				setModalOpen={() => cancelChange()}
			>
				<div className="flex flex-col gap-4">
					<h3 className="font-bold text-center dynamic-large text-secondary">
						Confirm Template
					</h3>
					<div className="flex flex-col gap-3 text-sm text-center text-black ">
						<p>Please make sure you have selected the correct template.</p>
					</div>
					<div className="flex justify-center w-full mx-auto">
						<PrimaryButton type="button" onClick={handleClick}>
							Proceed with this template
						</PrimaryButton>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeTemplateDialog;
