import { Dialog, DialogContent } from '@/components/ui/dialog';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import TemplateSelection from '@/components/Proposal/TemplateSelection/TemplateSelection';

const TemplateDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'viewTemplates'
	);
	const pageTransitionLoad = useSelector(
		(state: RootState) => state.proposal.pageTransitionLoad
	);
	const selectedTemplate = useSelector(
		(root: RootState) => root.proposal.selectedTemplate
	);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[1000px] xl:max-w-[1100px] 2xl:max-w-[1250px] max bg-white rounded-3xl p-6">
				<TemplateSelection
					currentSelectedTemplate={selectedTemplate}
					pageTransitionLoad={pageTransitionLoad}
				/>
			</DialogContent>
		</Dialog>
	);
};
export default TemplateDialog;
