import { FC } from 'react';
import styles from './CollapseBox.module.css';
import { cn } from '@/lib/utils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { Eye } from 'lucide-react';
import { openPanel } from '@/redux/slices/pageSlice';

interface CollapseBoxProps {
	title: string;
	icon?: React.ReactNode;
	isExpanded: boolean;
	setIsExpanded: (val: boolean) => void;
	children: React.ReactNode;
	tourClass?: string;
}

const CollapseBox: FC<CollapseBoxProps> = ({
	title,
	isExpanded,
	children,
	setIsExpanded,
	icon,
	tourClass,
}) => {
	const dispatch = useAppDispatch();
	return (
		<div
			className={cn(styles.collapseBox, isExpanded ? styles.expanded : null)}
		>
			<div
				className={cn(
					styles.header,
					'cursor-pointer text-primary font-bold flex items-center justify-between gap-2 dynamic-medium',
					isExpanded ? styles.active : null
				)}
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<div className={cn('flex items-center gap-2 font-medium', tourClass)}>
					{icon}
					{title}
				</div>
				{title === 'Matrix' && (
					<div
						tabIndex={0}
						role="button"
						className="flex items-center gap-1 uppercase text-secondary dynamic-small animated-hover-effect"
						onClick={() => dispatch(openPanel({ type: 'compliance' }))}
					>
						<Eye size={16} />
						View Matrix
					</div>
				)}
			</div>
			<div
				className={cn(
					styles.body,
					isExpanded ? styles.expanded : styles.collapsed
				)}
			>
				{children}
			</div>
		</div>
	);
};

export default CollapseBox;
