import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { PortableMultiSelect } from '@/components/ui/shared/PortableMultiSelect/PortableMultiSelect';
import { Textarea } from '@/components/ui/textarea';
import { transformSectionsRecursively } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { cn } from '@/lib/utils';
import { updateMatrix } from '@/redux/requests/compliance';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface EditMatrixRuleProps {
	requirement: string;
	solicitationReference: string;
	explanation: string;
	sectionIds: { label: string; value: number }[];
}

const EditMatrixRule = () => {
	const form = useForm<EditMatrixRuleProps>();
	const dispatch = useAppDispatch();
	const { register, handleSubmit, reset, control, watch } = form;
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'editMatrixRule'
	);
	const sections = useSelector(
		(root: RootState) => root.proposal.proposal?.sections
	);
	const complianceMatrix = useSelector(
		(root: RootState) => root.compliance.complianceMatrix
	);
	const complianceRuleId = useSelector(
		(root: RootState) => root.page.dialog?.complianceRuleId
	);

	const { activeSection } = useActiveSection();

	const complianceMode = useSelector(
		(root: RootState) => root.page.dialog?.complianceMode
	);

	const [addedSections, setAddedSections] = useState<
		{ label: string; value: number }[]
	>([]);
	const [removedSections, setRemovedSections] = useState<
		{ label: string; value: number }[]
	>([]);

	const requirement = watch('requirement');
	const solicitationReference = watch('solicitationReference');
	const explanation = watch('explanation');

	const currentMatrixRule = useMemo(() => {
		return complianceMatrix?.rules?.find(
			(rule) => rule.id === complianceRuleId
		);
	}, [complianceRuleId, complianceMatrix]);

	const transformSectionOptions =
		useMemo(() => transformSectionsRecursively(sections || []), [sections]) ||
		[];

	useEffect(() => {
		const activeSections = currentMatrixRule?.sections || [];
		const transformedSections = activeSections.map((section) => ({
			label: section.name,
			value: section.id,
		}));

		if (currentMatrixRule) {
			reset({
				requirement: currentMatrixRule.statement,
				solicitationReference: currentMatrixRule.solicitationReference,
				explanation: currentMatrixRule.explanation,
				sectionIds: transformedSections,
			});
		}
	}, [currentMatrixRule, reset]);

	const handleSectionChange = (
		selectedSections: { label: string; value: number }[]
	) => {
		const initialSections =
			currentMatrixRule?.sections.map((section) => ({
				label: section.name,
				value: section.id,
			})) || [];

		const added = selectedSections.filter(
			(section) =>
				!initialSections.some((initial) => initial.value === section.value)
		);

		const removed = initialSections.filter(
			(initial) =>
				!selectedSections.some((section) => section.value === initial.value)
		);

		setAddedSections(added);
		setRemovedSections(removed);
	};

	const onSubmit = async (data: EditMatrixRuleProps) => {
		if (!complianceMatrix || !complianceRuleId) return;
		try {
			if (complianceMode === 'section' && activeSection.sectionId) {
				dispatch(
					updateMatrix({
						complianceId: complianceMatrix?.id,
						proposalId: complianceMatrix?.proposalId,
						ruleId: complianceRuleId,
						payload: {
							explanation: data.explanation,
							solicitationReference: data.solicitationReference,
							statement: data.requirement,
							sectionRef: {
								addIds: addedSections.map((section) => section.value),
								removeIds: removedSections.map((section) => section.value),
							},
						},
						mode: complianceMode,
						sectionId: activeSection.sectionId,
					})
				);
			} else {
				dispatch(
					updateMatrix({
						complianceId: complianceMatrix?.id,
						proposalId: complianceMatrix?.proposalId,
						ruleId: complianceRuleId,
						payload: {
							explanation: data.explanation,
							solicitationReference: data.solicitationReference,
							statement: data.requirement,
							sectionRef: {
								addIds: addedSections.map((section) => section.value),
								removeIds: removedSections.map((section) => section.value),
							},
						},
					})
				);
			}

			dispatch(closeDialog());
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[700px] p-6 rounded-3xl shadow-main bg-white"
				hideCloseBtn
			>
				<DialogHeader>
					<DialogTitle className="font-bold dynamic-large text-secondary">
						Edit Matrix Rule
					</DialogTitle>
				</DialogHeader>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="w-full mt-3 overflow-y-auto max-h-[450px] 2xl:max-h-[600px] pb-px pr-3"
				>
					<Label
						className="block mb-2 font-bold text-black dynamic-medium"
						htmlFor="requirement"
					>
						Solicitation Requirement
					</Label>
					<Input
						className="w-full h-12 bg-white rounded-xl"
						{...register('requirement', { maxLength: 600 })}
						id="requirement"
						name="requirement"
						placeholder="Enter Requirement"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
					<div
						className={cn(
							'flex justify-end mt-1 dynamic-small text-gray-400',
							requirement?.length > 600 ? 'text-red-500' : ''
						)}
					>
						{requirement?.length || 0}/600
					</div>
					<Label
						className="block mt-3 mb-2 font-bold text-black dynamic-medium"
						htmlFor="solicitationSection"
					>
						Solicitation Section
					</Label>
					<Input
						className="h-12 bg-white rounded-xl"
						{...register('solicitationReference', { maxLength: 500 })}
						id="solicitationSection"
						name="solicitationReference"
						placeholder="Enter Solicitation Section"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
					<div
						className={cn(
							'flex justify-end mt-1 dynamic-small text-gray-400',
							solicitationReference?.length > 500 ? 'text-red-500' : ''
						)}
					>
						{solicitationReference?.length || 0}/500
					</div>
					<Label
						className="block mt-4 mb-2 font-bold text-black dynamic-medium"
						htmlFor="explanation"
					>
						Explanation
					</Label>
					<Textarea
						className="h-40 p-3 bg-white resize-none rounded-xl"
						{...register('explanation', { maxLength: 1000 })}
						id="explanation"
						placeholder="Enter Explanation"
						name="explanation"
					/>
					<div
						className={cn(
							'flex justify-end mt-1 dynamic-small text-gray-400',
							explanation?.length > 1000 ? 'text-red-500' : ''
						)}
					>
						{explanation?.length || 0}/1000
					</div>
					<Label className="block mt-4 mb-2 font-bold text-black dynamic-medium">
						Proposal Section
					</Label>
					<Controller
						name="sectionIds"
						control={control}
						render={({ field }) => (
							<PortableMultiSelect
								options={transformSectionOptions}
								value={field.value?.map((v) => ({
									label: v.label,
									value: v.value.toString(),
								}))}
								onSelectedValuesChange={(selected) => {
									const transformed = selected.map((s) => ({
										label: s.label,
										value: parseInt(s.value),
									}));
									field.onChange(transformed);
									handleSectionChange(transformed);
								}}
							/>
						)}
					/>
					<div className="relative flex items-center justify-end gap-3 mt-4">
						<SecondaryButton
							type="button"
							onClick={() => {
								dispatch(closeDialog());
								reset({
									explanation: '',
									requirement: '',
									solicitationReference: '',
									sectionIds: [],
								});
							}}
						>
							Cancel
						</SecondaryButton>
						<PrimaryButton
							type="submit"
							disabled={
								!requirement ||
								!solicitationReference ||
								!explanation ||
								requirement?.length > 600 ||
								solicitationReference?.length > 500 ||
								explanation?.length > 1000
							}
						>
							Save
						</PrimaryButton>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default EditMatrixRule;
