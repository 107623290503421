import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import {
	getFolders,
	moveDocuments,
	moveFolders,
} from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { RootState } from '@/redux/store/store';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { closeDialog } from '@/redux/slices/pageSlice';
import { useQuery, useQueryClient } from 'react-query';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import { Folder } from '@/lib/types/apiTypes';
import { ChevronLeft, ChevronRight, FolderIcon } from 'lucide-react';
import { cloneDeep } from 'lodash';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import useDebounce from '@/lib/hooks/useDebounce';
import useAuth from '@/lib/hooks/useAuth';

const MoveDocumentsDialog = () => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'moveDocuments'
	);
	const folderIds = useSelector(
		(root: RootState) => root.page.dialog?.moveDocuments?.folderIds
	);
	const documentIds = useSelector(
		(root: RootState) => root.page.dialog?.moveDocuments?.documentIds
	);
	const sourceFoldersPath = useSelector(
		(root: RootState) => root.page.dialog?.moveDocuments?.foldersPath
	);
	const { isAuthenticated } = useAuth();

	const [folderId, setFolderId] = useState<number | null>(
		sourceFoldersPath?.[sourceFoldersPath?.length - 1]?.id || null
	);
	const [destinationFoldersPath, setDestinationFoldersPath] = useState<
		Folder[]
	>(cloneDeep(sourceFoldersPath) || []);

	const destFolderId =
		destinationFoldersPath?.[destinationFoldersPath?.length - 1]?.id || null;

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const invalidateFolders = useDebounce(() => {
		queryClient.invalidateQueries('folders');
	}, 300);

	const invalidateDocuments = useDebounce(() => {
		queryClient.invalidateQueries('documents');
	}, 300);

	const fetchFolders = async () => {
		try {
			const response = await getFolders({
				...(destFolderId !== null && { parentId: destFolderId }),
			});
			handleResponse(response);
			return (response?.data as any)?.folders as Folder[];
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const { data: folders, isFetching: isFolderFetching } = useQuery({
		queryKey: ['folders', destFolderId],
		queryFn: () => fetchFolders(),
		refetchOnWindowFocus: false,
		enabled: isAuthenticated && isOpen,
	});

	const handleFoldersMove = async () => {
		try {
			if (!folderIds) return;
			setIsLoading(true);
			const response = await moveFolders({
				...(destFolderId && { parentId: destFolderId }),
				folderIds,
			});
			handleResponse(response, 'Folder(s) name has been moved successfully.');
			dispatch(closeDialog());
			setIsLoading(false);
			invalidateFolders();
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleDocumentsMove = async () => {
		try {
			if (!documentIds) return;
			setIsLoading(true);
			const response = await moveDocuments({
				...(destFolderId && { folderId: destFolderId }),
				documentIds: documentIds,
			});
			handleResponse(response, 'Documents(s) has been moved successfully.');
			dispatch(closeDialog());
			setIsLoading(false);
			invalidateDocuments();
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			setIsLoading(false);
			setFolderId(
				sourceFoldersPath?.[sourceFoldersPath?.length - 1]?.id || null
			);
			setDestinationFoldersPath(cloneDeep(sourceFoldersPath) || []);
		}
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[550px] p-7 bg-white rounded-xl gap-4">
				<DialogHeader className="font-bold text-secondary dynamic-xl-large">
					Move {!folderIds ? 'Documents' : 'Folders'}
				</DialogHeader>
				<div className="flex gap-1 text-black items-center">
					<h4 className="min-w-fit dynamic-medium font-bold text-black self-start">
						Current Location:
					</h4>
					<div className="flex gap-1">
						{sourceFoldersPath && sourceFoldersPath.length > 0 ? (
							<p className="inline">
								{sourceFoldersPath[sourceFoldersPath.length - 1].name}
							</p>
						) : (
							<p className="inline">Main Drive</p>
						)}
					</div>
				</div>
				<div className="flex flex-col gap-3 text-black">
					<h4 className="dynamic-medium font-bold">
						{destinationFoldersPath?.length ? (
							<p
								className="flex w-fit items-center gap-2 cursor-pointer animated-hover-effect"
								onClick={() => {
									destinationFoldersPath.splice(-1);
									setDestinationFoldersPath(cloneDeep(destinationFoldersPath));
								}}
							>
								<ChevronLeft size={16} />{' '}
								{
									destinationFoldersPath?.[destinationFoldersPath.length - 1]
										.name
								}
							</p>
						) : (
							'Main Drive'
						)}
					</h4>
					<div className="flex flex-col border border-blackSecondary rounded-lg h-[250px] w-[500px] overflow-y-auto p-3 gap-3">
						{folders
							?.filter((folderItem) =>
								folderIds ? !folderIds?.includes(folderItem.id) : true
							)
							?.map((folderItem, index) => (
								<div
									className="flex justify-between items-center cursor-pointer hover:text-primary"
									key={index}
									onClick={() => {
										destinationFoldersPath?.push(folderItem);
										setDestinationFoldersPath(
											cloneDeep(destinationFoldersPath)
										);
									}}
								>
									<p className="flex gap-2 dynamic-text items-center">
										<FolderIcon size={16} />
										{folderItem.name}
									</p>
									<ChevronRight size={18} />
								</div>
							))}
					</div>
				</div>
				<div className="flex gap-1 text-black items-center">
					<h4 className="min-w-fit dynamic-medium font-bold text-black self-start">
						Move Location:
					</h4>
					<div
						className={`inline gap-1 ${!destinationFoldersPath?.length ? 'text-secondary' : ''}`}
					>
						Main Drive
						{destinationFoldersPath?.map((folderItem, index) => (
							<p className="inline" key={index}>
								{' > '}
								<span
									className={
										destinationFoldersPath?.length - 1 === index
											? 'text-secondary'
											: ''
									}
								>
									{folderItem.name}
								</span>
							</p>
						))}
					</div>
				</div>
				<div className="flex items-center justify-end gap-4">
					<TextButton onClick={() => dispatch(closeDialog())}>
						Cancel
					</TextButton>
					<PrimaryButton
						disabled={folderId === destFolderId}
						onClick={() =>
							!folderIds ? handleDocumentsMove() : handleFoldersMove()
						}
					>
						Move
					</PrimaryButton>
				</div>
				{(isLoading || isFolderFetching) && <LoaderTransparent />}
			</DialogContent>
		</Dialog>
	);
};

export default MoveDocumentsDialog;
