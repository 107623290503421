import { FC } from 'react';

import styles from './EnhanceUserMessage.module.css';
import { cn } from '@/lib/utils';

interface EnhanceUserMessageProps {
	content: string;
}

const EnhanceUserMessage: FC<EnhanceUserMessageProps> = ({ content }) => {
	return <div className={cn(styles.message, 'text-black')}>{content}</div>;
};

export default EnhanceUserMessage;
