import { Dialog, DialogContent } from '@/components/ui/dialog';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import styles from './MultiStepUnsolicitedDialog.module.css';
import { cn } from '@/lib/utils';
import { Progress } from '@/components/ui/progress';
import BasicInformation from './StepsData/BasicInformation/BasicInformation';
import RelevantDocuments from './StepsData/RelevantDocuments/RelevantDocuments';
import FunderPriorities from './StepsData/FunderPriorities/FunderPriorities';
import ProblemAndImpact from './StepsData/ProblemAndImpact/ProblemAndImpact';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import {
	nextStep,
	prevStep,
	resetForm,
	saveStepData,
	setCurrentStep,
	setRelevantFiles,
	setUnsolicitation,
} from '@/redux/slices/unsolicitedSlice';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
	createDocument,
	createKnowledgeBaseOfJourney,
	exportManualSolicitation,
	uploadDocumentInOrganization,
} from '@/lib/functions/apiCalls';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Form } from '@/components/ui/form';
import { UnsolicitedQuestions } from '@/lib/types/constants';
import { handleResponse, showToast } from '@/lib/functions/funcUtils';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import SuccessLottie from '@/assets/lotties/success-lottie.json';
import Lottie from 'lottie-react';
import { motion } from 'framer-motion';
import {
	containerDivFadeIn,
	slideInUnsolicitedCard,
	slideInUnsolicitedCardReverse,
} from '@/constants/variants';

const MultiStepUnsolicitedDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'multiStepUnsolicited'
	);

	const queryClient = useQueryClient();
	const [answerStreaming, setAnswerStreaming] = useState(false);
	const [reverseAnimation, setReverseAnimation] = useState(false);
	const dispatch = useAppDispatch();

	const {
		steps,
		currentStepIndex,
		formData,
		additionalFiles,
		unsolicitation,
		journeyType,
	} = useSelector((state: RootState) => state.unsolicited);

	const [loader, setLoader] = useState(false);

	const form = useForm({
		mode: 'onChange', // Validate form on change to reflect validity immediately, rather than on submit
		defaultValues: formData[steps[currentStepIndex].name] || {},
	});

	const questions = useSelector(
		(root: RootState) => root.unsolicited.questions
	);

	const {
		handleSubmit,
		formState: { isValid },
		register,
		reset,
		setValue,
		watch,
	} = form;

	useEffect(() => {
		if (isOpen) {
			// Reset the form with default values when the dialog is opened
			reset(formData[steps[currentStepIndex]?.name] || {});
		} else {
			reset({});
		}
	}, [isOpen, reset, formData, steps, currentStepIndex, setValue]);

	const makeApiCall = async (stepName: string, data: any) => {
		try {
			let response;
			setLoader(true); // Start loader when API call starts

			switch (stepName) {
				case 'Basic Information':
					response = await createDocument({
						category: journeyType,
						metaData: {
							agency: data.metaData.agency,
							funding: data.metaData.funding,
							funderUrl: data.metaData.funderUrl,
						},
						name: data.name,
						type: 'rfpDocument',
					});
					if (response.statusCode === 201) {
						dispatch(setUnsolicitation(response.data));
						queryClient.invalidateQueries('solicitations');
					}
					break;
				case 'Relevant documents or links':
					{
						if (!unsolicitation) return;
						if (additionalFiles.length > 0) {
							response = await createKnowledgeBaseOfJourney(unsolicitation.id, {
								file: additionalFiles || [],
								knowledgeUrl: data.knowledgeUrl,
							});
							if (response.statusCode === 201) {
								dispatch(setRelevantFiles([]));
								setValue('knowledgeUrl', '');
								queryClient.invalidateQueries('solicitations');
							}
						}
					}

					break;
				case 'Funder priorities':
					// call api

					break;
				case 'Problem and impact':
					response = await exportManualSolicitation({
						content: [
							{
								question: UnsolicitedQuestions.one,
								text: questions['question1'],
							},
							{
								question: UnsolicitedQuestions.two,
								text: questions['question2'],
							},
							{
								question: UnsolicitedQuestions.three,
								text: questions['question3'],
							},
							{
								question: UnsolicitedQuestions.forth,
								text: questions['question4'],
							},
							{
								question: 'Funding Amount',
								text: String(unsolicitation?.metaData.funding)?.replace(
									/[$,]/g,
									''
								),
							},
						],
					});
					if (response) {
						const blob = new Blob([response as ArrayBuffer]);
						const filePDF = new File([blob], 'solicitation.pdf', {
							type: 'application/pdf',
						});
						const docResponse = await uploadDocumentInOrganization({
							file: filePDF,
							name: unsolicitation?.name || '',
							type: unsolicitation?.type || '',
							category: unsolicitation?.category,
							hash: unsolicitation?.hash,
							isSolicited: unsolicitation?.isSolicited,
						});
						handleResponse(docResponse);
						queryClient.invalidateQueries('solicitations');
						dispatch(closeDialog());
						dispatch(resetForm());
						setAnswerStreaming(false);
					} else {
						showToast('error', 'Something went wrong');
					}
					break;
				default:
					throw new Error('Invalid step');
			}

			return response?.data;
		} catch (error: any) {
			showToast('error', error.message);
			return null;
		} finally {
			setLoader(false); // Stop loader when API call finishes
		}
	};

	const onSubmit: SubmitHandler<any> = async (data) => {
		setReverseAnimation(false);
		await makeApiCall(steps[currentStepIndex].name, data);
		if (currentStepIndex !== 1) {
			dispatch(
				saveStepData({
					key: steps[currentStepIndex].name,
					data: { ...data }, // Save both form data and API response
					isValid,
				})
			);
		}

		// Move to the next step
		if (currentStepIndex < steps.length - 1) {
			dispatch(nextStep());
			reset(); // Reset form for the next step
		}
	};

	const handleBackClick = () => {
		setReverseAnimation(true);
		dispatch(prevStep());

		// Pre-fill form with saved data for the previous step, including updated meta data
		const previousStepData = formData[steps[currentStepIndex - 1]?.name] || {};

		Object.keys(previousStepData).forEach((field) => {
			setValue(field, previousStepData[field]);
		});

		// Reset the form's validity based on the previous step's validation state
		const isPreviousStepValid = steps[currentStepIndex - 1].isValid;
		if (isPreviousStepValid) {
			reset(previousStepData); // Ensure the form is reset to the saved state
		}
	};

	const progressValue = ((currentStepIndex + 1) / steps.length) * 100;

	const hasAtLeastTwoChars = (str) => /[a-zA-Z].*[a-zA-Z]/.test(str);

	const solicitationName = watch('name') || '';

	watch('metaData.funderUrl') || '';

	const { metaData: metaData } = form.formState.errors;

	return (
		<Dialog open={isOpen}>
			<DialogContent
				hideCloseBtn
				className="grid max-w-[1000px] grid-cols-12 gap-0 p-0 border-none rounded-3xl"
			>
				<div className={cn(styles.left, 'col-span-4')}>
					<h2 className="font-bold text-white dynamic-xl-large">
						{journeyType === 'questionnaire'
							? 'Questionnaire'
							: 'Unsolicited Proposal'}{' '}
						Setup
					</h2>

					<div className="relative flex flex-col mt-12 gap-6">
						{steps.map((step, index) => (
							<div
								key={step.id}
								className={`${styles.step} flex items-center gap-4`}
							>
								<div
									className={cn(
										styles.step_bubble,
										'z-20',
										step.status === 'current' ? 'bg-white' : 'bg-[#D6D6D6]'
									)}
								>
									{currentStepIndex > index && (
										<Lottie
											className="block animation-lottie min-h-7 min-w-7"
											animationData={SuccessLottie}
											loop={false}
											autoPlay={true}
										/>
									)}
								</div>
								<span
									className={cn(
										step.status === 'current' || step.status === 'completed'
											? 'text-white'
											: 'text-[#D6D6D6]'
									)}
									onClick={() => {
										if (isValid && index !== 0) {
											dispatch(setCurrentStep(index));
										}
									}}
								>
									{step.name}
								</span>
							</div>
						))}
						<Progress
							value={progressValue}
							className={cn(
								styles.progress,
								'absolute z-10 -top-7 left-[10px] w-80 h-[2px] bg-[#D6D6D6] hidden'
							)}
						/>
					</div>
				</div>
				<div className={cn(styles.right, 'col-span-8 py-8 px-0')}>
					<motion.div
						variants={containerDivFadeIn}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						<h2 className="font-bold text-black dynamic-xl-large px-8">
							{steps[currentStepIndex].name}
						</h2>
					</motion.div>
					<Form {...form}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="overflow-hidden px-8">
								{currentStepIndex === 0 && (
									<motion.div
										variants={
											reverseAnimation
												? slideInUnsolicitedCardReverse
												: slideInUnsolicitedCard
										}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<BasicInformation
											register={register}
											isFunderURLValid={!metaData?.['funderUrl']}
										/>
									</motion.div>
								)}
								{currentStepIndex === 1 && (
									<motion.div
										variants={
											reverseAnimation
												? slideInUnsolicitedCardReverse
												: slideInUnsolicitedCard
										}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<RelevantDocuments register={register} />
									</motion.div>
								)}
								{currentStepIndex === 2 && (
									<motion.div
										variants={
											reverseAnimation
												? slideInUnsolicitedCardReverse
												: slideInUnsolicitedCard
										}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<FunderPriorities
											form={form}
											answerStreaming={answerStreaming}
											setAnswerStreaming={setAnswerStreaming}
										/>
									</motion.div>
								)}
								{currentStepIndex === 3 && (
									<motion.div
										variants={
											reverseAnimation
												? slideInUnsolicitedCardReverse
												: slideInUnsolicitedCard
										}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<ProblemAndImpact
											form={form}
											answerStreaming={answerStreaming}
											setAnswerStreaming={setAnswerStreaming}
										/>
									</motion.div>
								)}
							</div>
							<div className="flex justify-end gap-3 px-8">
								<TextButton
									type="button"
									onClick={() => {
										dispatch(closeDialog());
										dispatch(resetForm());
										setAnswerStreaming(false);
									}}
									disabled={answerStreaming}
								>
									Cancel
								</TextButton>
								{!(
									currentStepIndex === 0 ||
									currentStepIndex === 3 ||
									(unsolicitation !== null && currentStepIndex === 1)
								) && (
									<SecondaryButton
										type="button"
										onClick={handleBackClick}
										disabled={answerStreaming}
									>
										Back
									</SecondaryButton>
								)}
								<PrimaryButton
									type="submit"
									disabled={
										!isValid ||
										answerStreaming ||
										(currentStepIndex === 0 &&
											!hasAtLeastTwoChars(solicitationName))
									}
								>
									{currentStepIndex === steps.length - 1 ? 'Submit' : 'Next'}
								</PrimaryButton>
							</div>
						</form>
					</Form>
				</div>
				{loader && <LoaderTransparent />}
			</DialogContent>
		</Dialog>
	);
};

export default MultiStepUnsolicitedDialog;
