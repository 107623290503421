import { SVGProps } from 'react';

const Area = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="67"
			height="68"
			viewBox="0 0 67 68"
			fill="none"
		>
			<path
				d="M3.61719 3.45264V63.722H63.0609"
				stroke="#BDBDBD"
				strokeWidth="1.65444"
			/>
			<path
				d="M11.0469 46.42V55.4658L26.7334 50.2968L42.4199 55.4658L58.1065 50.2968V41.251L42.4199 46.42L26.7334 41.251L11.0469 46.42Z"
				fill="#1AC1D7"
			/>
			<path
				d="M11.0469 45.774V54.8198L26.7334 49.6508L42.4199 54.8198L58.1065 49.6508V40.605L42.4199 45.774L26.7334 40.605L11.0469 45.774Z"
				fill="#A8CAFF"
			/>
			<path
				d="M11.0469 13.3956V22.4414L26.7334 17.2723L42.4199 22.4414L58.1065 17.2723V8.22656L42.4199 13.3956L26.7334 8.22656L11.0469 13.3956Z"
				fill="#CD55B2"
			/>
			<path
				d="M11.0469 12.7496V21.7954L26.7334 16.6263L42.4199 21.7954L58.1065 16.6263V7.58057L42.4199 12.7496L26.7334 7.58057L11.0469 12.7496Z"
				fill="#E861C9"
			/>
			<path
				d="M26.7334 23.6508L11.0469 29.7544V40.6052L26.7334 34.8407L42.4199 39.927L58.1065 34.5016V22.2944L42.4199 29.7544L26.7334 23.6508Z"
				fill="#4385ED"
			/>
			<path
				d="M26.7334 22.9726L11.0469 29.0762V39.927L26.7334 34.1625L42.4199 39.2488L58.1065 33.8234V21.6162L42.4199 29.0762L26.7334 22.9726Z"
				fill="#5D9BFD"
			/>
		</svg>
	);
};

export default Area;
