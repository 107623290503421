import { FindDocuments } from '@/lib/types/apiTypes';
import { FC } from 'react';
import LoadingLogo from '@/assets/lotties/logo_loading_v1.json';
import Lottie from 'lottie-react';
import AiIcon from '../ui/icons/AiIcon';
import DocumentList from '../DocumentList/DocumentList';

interface MessagesListProps {
	list: FindDocuments[];
	isMessageLoading: boolean;
	handleReadDocument: (docData: FindDocuments) => void;
	handleAddDocument: (docId: number, category: string) => void;
	handleRemoveDocument: (docId: number) => void;
	isSuggestedList?: boolean;
}

const MessagesList: FC<MessagesListProps> = ({
	list,
	isMessageLoading,
	handleReadDocument,
	handleAddDocument,
	handleRemoveDocument,
	isSuggestedList = false,
}) => {
	return (
		<div className="flex flex-col h-full gap-4 overflow-hidden">
			{isMessageLoading ? (
				<div className="flex flex-col items-center justify-center h-full">
					<Lottie
						className="block animation-lottie max-h-24 max-w-24"
						animationData={LoadingLogo}
						loop={true}
						autoPlay={true}
					/>
				</div>
			) : !list ? (
				<div className="flex flex-col justify-center h-full">
					<div className="relative flex px-8 font-medium text-center justify-center text-black bg-white">
						You also can try to describe your document and I will try to locate
						it if its in my database
					</div>
				</div>
			) : list.length === 0 ? (
				<div className="flex flex-col justify-center h-full">
					<div className="relative flex px-8 font-medium text-center justify-center text-black bg-white rounded-xl">
						You description didn't match any document and please try again with
						different description
					</div>
				</div>
			) : (
				<>
					{isSuggestedList && (
						<div className="flex gap-2.5 items-center justify-start w-full pb-3 border-[#C6DBFB] border-b dynamic-text text-black">
							<AiIcon className="w-6 h-6" fill="#E861C9" />{' '}
							<div className="flex flex-col">
								<p className="font-bold text-black">
									Here are documents from the selected document library
								</p>
								<p>
									<span className="font-bold text-secondary">PLEASE NOTE:</span>{' '}
									These documents are ordered based on their relevance to the
									solicitation
								</p>
							</div>
						</div>
					)}
					<DocumentList
						documentsList={list}
						handleReadDocument={handleReadDocument}
						handleAddDocument={handleAddDocument}
						handleRemoveDocument={handleRemoveDocument}
					/>
				</>
			)}
		</div>
	);
};

export default MessagesList;
