import { ColumnDef } from '@tanstack/react-table';
import { PendingRequestsTableProps } from '@/lib/types/constants';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { convertTimestamp, getRoleName } from '@/lib/functions/funcUtils';

export const columns: ColumnDef<PendingRequestsTableProps>[] = [
	{
		accessorKey: 'email',
		header: ({ column }) => {
			return (
				<h5 key={column.id} className="p-0 text-black bg-transparent">
					Email
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<p className="text-base text-black">{row?.original?.email || '-'}</p>
			);
		},
	},
	{
		accessorKey: 'roleId',
		header: ({ column }) => {
			return (
				<h5 key={column.id} className="p-0 text-black bg-transparent">
					Role
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<p className="text-base text-black">
					{row?.original?.roleId ? getRoleName(row.original.roleId) : '-'}
				</p>
			);
		},
	},
	{
		accessorKey: 'createdAt',
		header: ({ column }) => {
			return (
				<h5 key={column.id} className="p-0 text-black bg-transparent">
					Created On
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<p className="text-base text-black">
					{row?.original?.createdAt
						? convertTimestamp(row.original.createdAt)
						: '-'}
				</p>
			);
		},
	},
	{
		accessorKey: 'expiresAt',
		header: ({ column }) => {
			return (
				<h5 key={column.id} className="p-0 text-black bg-transparent">
					Expires
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<p className="text-base text-black">
					{row?.original?.expiresAt
						? convertTimestamp(row.original.expiresAt)
						: '-'}
				</p>
			);
		},
	},
	{
		accessorKey: 'id',
		header: ({ column }) => {
			return (
				<h5 key={column.id} className="p-0 text-black bg-transparent">
					Action
				</h5>
			);
		},
		cell: ({ table, row }) => {
			const handleResendInvitation = () => {
				const meta = table?.options?.meta;
				meta?.handleResendInvitation &&
					meta?.handleResendInvitation(row.original.email, row.original.roleId);
			};
			const handleCancelInvitation = () => {
				const meta = table?.options?.meta;
				meta?.handleCancelInvitation &&
					meta?.handleCancelInvitation(row.original.id);
			};
			return (
				<div className="flex gap-4">
					<PrimaryButton
						className="h-7 2xl:h-8"
						onClick={handleResendInvitation}
					>
						Re-send Invite
					</PrimaryButton>
					<SecondaryButton
						className="h-7 2xl:h-8"
						onClick={handleCancelInvitation}
					>
						Cancel Invitation
					</SecondaryButton>
				</div>
			);
		},
	},
];
