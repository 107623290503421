import { SVGProps } from 'react';

const Ideas = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="19"
			viewBox="0 1 20 19"
			fill="none"
			{...props}
		>
			<path
				d="M16.7021 15.3296C16.703 15.3299 16.7038 15.3303 16.7047 15.3306V15.3602L16.8548 15.3602C16.934 15.3601 17.0114 15.3366 17.0772 15.2926C17.1431 15.2486 17.1944 15.1861 17.2247 15.1129C17.255 15.0397 17.2629 14.9592 17.2475 14.8815C17.2321 14.8038 17.1939 14.7325 17.1379 14.6765L17.1379 14.6764L15.7402 13.2788L15.7403 13.2788L15.7379 13.2765C15.6622 13.2041 15.5612 13.1643 15.4565 13.1655C15.3519 13.1666 15.2518 13.2087 15.1778 13.2827C15.1038 13.3568 15.0616 13.4568 15.0605 13.5615C15.0593 13.6662 15.0992 13.7672 15.1715 13.8428L15.1715 13.8428L15.1739 13.8452L16.5715 15.2428L16.5717 15.2429C16.609 15.2802 16.6533 15.3096 16.7021 15.3296Z"
				fill="#5D9BFD"
				stroke="#5D9BFD"
				strokeWidth="0.3"
			/>
			<path
				d="M17.2971 9.20279H18.8086H18.8105V9.20278C18.9118 9.20216 19.0007 9.15196 19.0607 9.07776C19.1204 9.00395 19.1502 8.90946 19.1502 8.81594C19.1502 8.72242 19.1204 8.62794 19.0607 8.55413C19.0003 8.47946 18.9107 8.4291 18.8086 8.4291H17.2971C17.195 8.4291 17.1054 8.47946 17.045 8.55413C16.9853 8.62794 16.9555 8.72242 16.9555 8.81594C16.9555 8.90946 16.9853 9.00395 17.045 9.07776C17.1054 9.15243 17.195 9.20279 17.2971 9.20279Z"
				fill="#5D9BFD"
				stroke="#5D9BFD"
				strokeWidth="0.3"
			/>
			<path
				d="M14.5193 4.93918C14.5715 4.93877 14.623 4.92811 14.671 4.90781C14.7193 4.88741 14.763 4.85769 14.7998 4.82036L16.1946 3.42413L16.1946 3.42416L16.1969 3.42177C16.2692 3.34613 16.3091 3.24518 16.3079 3.14053C16.3067 3.03589 16.2646 2.93585 16.1907 2.86184C16.1167 2.78783 16.0166 2.74572 15.912 2.74456C15.8073 2.74339 15.7064 2.78326 15.6308 2.8556L15.6307 2.85557L15.6283 2.85805L14.2352 4.25452C14.1778 4.30974 14.1383 4.38091 14.1218 4.45884C14.1052 4.53745 14.1127 4.61928 14.1435 4.69355C14.1742 4.76782 14.2267 4.83103 14.2941 4.87487C14.361 4.91843 14.4395 4.94083 14.5193 4.93918ZM14.5193 4.93918C14.5188 4.93919 14.5183 4.93919 14.5178 4.93919L14.5172 4.78919L14.5209 4.93915C14.5203 4.93916 14.5198 4.93917 14.5193 4.93918Z"
				fill="#5D9BFD"
				stroke="#5D9BFD"
				strokeWidth="0.3"
			/>
			<path
				d="M9.89946 3.02307L9.85255 2.88059C9.88165 2.87101 9.9081 2.85685 9.93036 2.83892C9.95262 2.82099 9.97024 2.79966 9.98221 2.77618C9.99417 2.75269 10.0002 2.72751 10 2.70211H10.15V2.70166V1.19148C10.15 1.08939 10.0997 0.999814 10.025 0.939438C9.95117 0.87976 9.8567 0.85 9.76321 0.85C9.66972 0.85 9.57525 0.87976 9.50143 0.939438C9.42677 0.999805 9.37637 1.08937 9.37637 1.19148V2.70324C9.37637 2.70324 9.37637 2.70324 9.37637 2.70325C9.37636 2.80467 9.4261 2.89379 9.50003 2.95416C9.57311 3.01384 9.66676 3.04398 9.75966 3.04472C9.807 3.0451 9.85444 3.03789 9.89946 3.02307Z"
				fill="#5D9BFD"
				stroke="#5D9BFD"
				strokeWidth="0.3"
			/>
			<path
				d="M4.2534 4.82248L4.2534 4.82248L4.25402 4.82311C4.31962 4.88812 4.4054 4.92809 4.49654 4.93687L4.49945 4.93833L4.53297 4.93915C4.61311 4.94112 4.69193 4.91873 4.75908 4.87496C4.82622 4.8312 4.87851 4.76812 4.90912 4.69407C4.93973 4.62002 4.94725 4.53844 4.93068 4.46005C4.91425 4.38232 4.8749 4.31131 4.81772 4.2562L3.43075 2.86667C3.39429 2.82883 3.35071 2.79855 3.30251 2.77759C3.25373 2.75639 3.20121 2.74515 3.14802 2.74456C3.09483 2.74396 3.04207 2.75402 2.99283 2.77413C2.94359 2.79424 2.89888 2.824 2.86131 2.86164C2.82374 2.89928 2.79407 2.94405 2.77402 2.9933C2.75398 3.04255 2.74396 3.09531 2.74456 3.14848C2.74515 3.20165 2.75634 3.25418 2.77747 3.30297C2.79835 3.35118 2.82853 3.3948 2.86629 3.43132L4.2534 4.82248Z"
				fill="#5D9BFD"
				stroke="#5D9BFD"
				strokeWidth="0.3"
			/>
			<path
				d="M1.1916 9.20279H2.70314C2.80524 9.20279 2.89483 9.15243 2.95524 9.07776C3.01495 9.00394 3.04474 8.90946 3.04474 8.81594C3.04474 8.72243 3.01495 8.62795 2.95524 8.55413C2.89483 8.47946 2.80524 8.4291 2.70314 8.4291H1.1916C1.0895 8.4291 0.999907 8.47946 0.939499 8.55413C0.879786 8.62795 0.85 8.72243 0.85 8.81594C0.85 8.90946 0.879786 9.00394 0.939499 9.07776C0.999907 9.15243 1.0895 9.20279 1.1916 9.20279Z"
				fill="#5D9BFD"
				stroke="#5D9BFD"
				strokeWidth="0.3"
			/>
			<path
				d="M2.19664 15.3602L2.19701 15.3602C2.24987 15.3601 2.30217 15.3494 2.35084 15.3288C2.39918 15.3084 2.44298 15.2785 2.47971 15.2411L3.86993 13.8524C3.90777 13.8159 3.93803 13.7722 3.95898 13.724L3.82138 13.6643L3.95898 13.724C3.98016 13.6752 3.99138 13.6226 3.99198 13.5694C3.99257 13.5162 3.98253 13.4634 3.96244 13.4141C3.94234 13.3648 3.91261 13.3201 3.87498 13.2824C3.83736 13.2448 3.79259 13.2151 3.74331 13.195C3.69403 13.1749 3.64124 13.1649 3.58803 13.1655C3.53481 13.166 3.48226 13.1773 3.43345 13.1984C3.3852 13.2194 3.34157 13.2496 3.30505 13.2875L1.91424 14.6782L1.91421 14.6782C1.85837 14.7341 1.82034 14.8053 1.80493 14.8828C1.78953 14.9602 1.79744 15.0405 1.82767 15.1135C1.8579 15.1865 1.90909 15.2489 1.97476 15.2928C2.04043 15.3367 2.11765 15.3601 2.19664 15.3602Z"
				fill="#5D9BFD"
				stroke="#5D9BFD"
				strokeWidth="0.3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.60477 18.7638C8.87918 18.9217 9.18626 19.0007 9.52601 19.0007C9.86575 19.0007 10.1728 18.9217 10.4472 18.7638C10.7217 18.6059 10.9373 18.3944 11.0941 18.1291C11.5253 18.1291 11.8944 17.9807 12.2015 17.6838C12.5086 17.387 12.6621 17.0301 12.6621 16.6133V13.9228C13.4331 13.4301 14.0505 12.7859 14.5144 11.9901C14.9783 11.1944 15.2102 10.3101 15.2102 9.33751C15.2102 7.80909 14.6581 6.51119 13.554 5.44383C12.4498 4.37646 11.1071 3.84277 9.52601 3.84277C7.94488 3.84277 6.60223 4.37646 5.49806 5.44383C4.39388 6.51119 3.8418 7.80909 3.8418 9.33751C3.8418 10.2849 4.07374 11.1628 4.53762 11.9712C5.00151 12.7796 5.61893 13.4301 6.38989 13.9228V16.6133C6.38989 17.0301 6.54343 17.387 6.85051 17.6838C7.15759 17.9807 7.52673 18.1291 7.95795 18.1291C8.11476 18.3944 8.33036 18.6059 8.60477 18.7638ZM11.0941 16.6133H7.95795V15.9312H11.0941V16.6133ZM11.0941 15.1733H7.95795V14.4533H11.0941V15.1733ZM7.80124 12.9371H8.93808H10.1141H11.251C11.9566 12.6087 12.5315 12.1256 12.9758 11.4877C13.4201 10.8498 13.6423 10.1329 13.6423 9.33714C13.6423 8.22556 13.2437 7.28451 12.4466 6.51398C11.6495 5.74346 10.676 5.35819 9.5261 5.35819C8.37619 5.35819 7.40269 5.74346 6.6056 6.51398C5.8085 7.28451 5.40995 8.22556 5.40995 9.33714C5.40995 10.1329 5.63209 10.8498 6.07638 11.4877C6.52066 12.1256 7.09561 12.6087 7.80124 12.9371Z"
				fill="#5D9BFD"
			/>
		</svg>
	);
};

export default Ideas;
