import { FC } from 'react';
import styles from './DashboardSolicitationCard.module.css';
import { cn } from '@/lib/utils';
import { Variants, motion } from 'framer-motion';

interface DashboardSolicitationCardProps {
	animationStart?: (startTime) => Variants;
}

const DashboardSolicitationCard: FC<DashboardSolicitationCardProps> = ({
	animationStart,
}) => {
	return (
		<motion.div
			className={cn(
				styles.card,
				'flex flex-col 2xl:flex-row 2xl:items-center 2xl:justify-between gap-4 shadow-main'
			)}
			variants={animationStart && animationStart(0.2)}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			<div>
				<h1 className="font-bold text-black dynamic-xl-large">
					Start New Proposal
				</h1>
				<p className="text-black">
					Get started on a new proposal by clicking on any of the proposal
					journeys below
				</p>
			</div>
		</motion.div>
	);
};

export default DashboardSolicitationCard;
