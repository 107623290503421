import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import React, { FC } from 'react';

interface PrimaryButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	onClick?: () => void;
	className?: string;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
	children,
	className,
	...props
}) => {
	return (
		<Button
			{...props}
			className={cn(
				'h-10 2xl:h-11 px-5 min-w-[100px] dynamic-text font-medium text-white uppercase rounded-lg flex gap-2 hover:bg-secondary transition-all ease-in duration-200',
				className
			)}
		>
			{children}
		</Button>
	);
};

export default PrimaryButton;
