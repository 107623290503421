import QuestionCard from '@/components/QuestionCard/QuestionCard';
import { findSection } from '@/lib/functions/funcUtils';
import { RootState } from '@/redux/store/store';

import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Node } from '@/lib/types/graphTypes';

interface InfoCardsProps {
	selectedNode?: string;
	sortedNodes?: Node[];
}

const InfoCards: FC<InfoCardsProps> = ({ selectedNode }) => {
	const proposal = useSelector((root: RootState) => root.proposal.proposal);

	const selectedSection = findSection(
		proposal?.sections || [],
		selectedNode as string
	);

	return (
		<div className="flex flex-col gap-2.5">
			{!selectedSection?.questions?.length ||
			selectedSection?.questions.length === 0 ? (
				<div className=" text-[#6D7D86] italic py-6 2xl:py-10 px-6 xl:px-8 2xl:px-12 border-dashed border border-[#5D9BFD] rounded-[30px]">
					This section does not appear to have a question associated with it.
					<br />
					<span className="text-[#E861C9] font-medium">
						You can enrich this section{' '}
					</span>
					using the tools to the right or move to the next section.
				</div>
			) : (
				<>
					{selectedSection?.questions.map((question) => (
						<QuestionCard
							question={question}
							selectedNode={selectedNode as string}
							key={question.id}
						/>
					))}
				</>
			)}
		</div>
	);
};

export default InfoCards;
