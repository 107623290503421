import { SVGProps } from 'react';

const TemplateNotFound = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="151"
			height="137"
			viewBox="0 0 151 137"
			fill="none"
			{...props}
		>
			<g clipPath="url(#clip0_12372_10407)">
				<path
					d="M113.82 2.04297H15.8497C8.30428 2.04297 2.1875 8.15975 2.1875 15.7052V108.98C2.1875 116.526 8.30428 122.643 15.8497 122.643H113.82C121.365 122.643 127.482 116.526 127.482 108.98V15.7052C127.482 8.15975 121.365 2.04297 113.82 2.04297Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M107.008 16.3438H22.6576C19.2655 16.3438 16.5156 19.0936 16.5156 22.4857V22.4943C16.5156 25.8864 19.2655 28.6363 22.6576 28.6363H107.008C110.4 28.6363 113.15 25.8864 113.15 22.4943V22.4857C113.15 19.0936 110.4 16.3438 107.008 16.3438Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M107.004 36.957H41.0873C37.6952 36.957 34.9453 39.7069 34.9453 43.099V43.1076C34.9453 46.4997 37.6952 49.2496 41.0873 49.2496H107.004C110.396 49.2496 113.146 46.4997 113.146 43.1076V43.099C113.146 39.7069 110.396 36.957 107.004 36.957Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M77.9477 57.5703H41.0873C37.6952 57.5703 34.9453 60.3202 34.9453 63.7123V63.7209C34.9453 67.113 37.6952 69.8629 41.0873 69.8629H77.9477C81.3398 69.8629 84.0896 67.113 84.0896 63.7209V63.7123C84.0896 60.3202 81.3398 57.5703 77.9477 57.5703Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M107.005 78.1758H21.0091C17.617 78.1758 14.8672 80.9256 14.8672 84.3177V84.3264C14.8672 87.7185 17.617 90.4683 21.0091 90.4683H107.005C110.397 90.4683 113.147 87.7185 113.147 84.3264V84.3177C113.147 80.9256 110.397 78.1758 107.005 78.1758Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M94.9781 98.7891H41.0873C37.6952 98.7891 34.9453 101.539 34.9453 104.931V104.94C34.9453 108.332 37.6952 111.082 41.0873 111.082H94.9781C98.3702 111.082 101.12 108.332 101.12 104.94V104.931C101.12 101.539 98.3702 98.7891 94.9781 98.7891Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M22.1797 28.6289V63.7148H34.946"
					stroke="#AFC1DB"
					strokeWidth="4.15"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M22.1797 43.0977H34.946"
					stroke="#AFC1DB"
					strokeWidth="4.15"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M34.946 104.941H22.1797V90.4688"
					stroke="#AFC1DB"
					strokeWidth="4.15"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M116.426 134.953C134.205 134.953 148.617 120.541 148.617 102.762C148.617 84.9829 134.205 70.5703 116.426 70.5703C98.647 70.5703 84.2344 84.9829 84.2344 102.762C84.2344 120.541 98.647 134.953 116.426 134.953Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M140.383 125.09L148.816 133.515"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M113.758 118.121C113.758 117.199 114.051 116.415 114.628 115.769C115.205 115.123 115.998 114.804 117.005 114.804C118.013 114.804 118.849 115.132 119.417 115.769C119.986 116.415 120.27 117.199 120.27 118.121C120.27 119.042 119.986 119.826 119.417 120.472C118.849 121.118 118.048 121.437 117.005 121.437C115.963 121.437 115.205 121.11 114.628 120.472C114.051 119.826 113.758 119.042 113.758 118.121ZM120.003 87.075L119.521 107.741C119.486 109.127 118.358 110.23 116.971 110.23C115.584 110.23 114.456 109.127 114.421 107.741L113.913 87.075C113.878 85.645 115.033 84.4648 116.463 84.4648H117.453C118.883 84.4648 120.038 85.645 120.003 87.075Z"
					fill="#AFC1DB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_12372_10407">
					<rect
						width="150"
						height="136.286"
						fill="white"
						transform="translate(0.5 0.355469)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default TemplateNotFound;
