import { Step } from 'intro.js-react';
export const UserPageSteps: Step[] = [
	{
		element: '.user-section',
		title: 'Users section',
		intro:
			'This is the users section where you can manage users invites, teams, and access to template builder.',
	},
	{
		element: '.user-information',
		title: 'Users information',
		intro: 'You can see the total invitations and types of users here.',
		position: 'bottom',
	},
	{
		element: '.pending-request',
		title: 'Pending requests',
		intro: 'All users who have not accessed yet are shown here.',
		position: 'top',
	},
	{
		element: '.team-card',
		title: 'Create/edit teams',
		intro: 'You can create or edit teams of users here.',
		position: 'top',
	},
	{
		element: '.user-permission',
		title: 'Manage your users and their permissions',
		intro:
			'You can add and delete new users here. You can also set up access to template builder, 2 factor authentication (for added security) and define their user level.',
		position: 'top',
	},
];
