import { Dialog, DialogContent } from '@/components/ui/dialog';
import {
	updateOrganizationalToneOfVoice,
	updateProposalTone,
} from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	formatToneOfVoicePayload,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { setProposalTone } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import {
	ToneOfVoicePayload,
	ToneOfVoice,
	ToneOfVoiceFormData,
} from '@/lib/types/apiTypes';
import ToneOfVoiceEdit from '@/components/ToneOfVoiceEdit/ToneOfVoiceEdit';
import { useEffect, useState } from 'react';
import { closeDialog } from '@/redux/slices/pageSlice';
import DialogLoader from '../../Loader/DialogLoader/DailogLoader';

const ChangeToneDialog = () => {
	const isOpen = useAppSelector(
		(state: RootState) => state.page.dialog?.type === 'changeTone'
	);
	const changeToneProps = useAppSelector(
		(state: RootState) => state.page.dialog?.changeToneProps
	);
	const dispatch = useAppDispatch();

	const [toneOfVoiceData, setToneOfVoiceData] = useState<
		ToneOfVoice | undefined
	>(changeToneProps?.toneOfVoice);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (changeToneProps?.toneOfVoice) {
			setToneOfVoiceData(changeToneProps?.toneOfVoice);
		}
	}, [changeToneProps?.toneOfVoice]);

	const handleOrganizationalToneOfVoiceUpdate = async (
		formData: ToneOfVoiceFormData
	) => {
		try {
			if (changeToneProps?.organizationId) {
				setIsLoading(true);
				const toneOfVoicePayload: ToneOfVoicePayload =
					formatToneOfVoicePayload(formData);
				const response = await updateOrganizationalToneOfVoice(
					changeToneProps.organizationId,
					toneOfVoicePayload
				);
				handleResponse(
					response,
					'Organization tone has been updated successfully.'
				);
				const updatedTone: ToneOfVoice = response.data.settings
					.toneOfVoice as ToneOfVoice;
				setToneOfVoiceData(updatedTone);
				changeToneProps?.onSave && changeToneProps?.onSave(updatedTone);
				setIsLoading(false);
				dispatch(closeDialog());
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleProposalToneOfVoiceUpdate = async (
		formData: ToneOfVoiceFormData
	) => {
		try {
			if (changeToneProps?.proposalId) {
				setIsLoading(true);
				const toneOfVoicePayload: ToneOfVoicePayload =
					formatToneOfVoicePayload(formData);
				const response = await updateProposalTone(
					changeToneProps?.proposalId,
					toneOfVoicePayload
				);
				handleResponse(
					response,
					'Proposal tone has been updated successfully.'
				);
				dispatch(setProposalTone(toneOfVoicePayload.settings.toneOfVoice));
				setIsLoading(false);
				dispatch(closeDialog());
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const onSubmit = (formData: ToneOfVoiceFormData) => {
		switch (changeToneProps?.type) {
			case 'organization':
				handleOrganizationalToneOfVoiceUpdate(formData);
				break;
			case 'proposal':
				handleProposalToneOfVoiceUpdate(formData);
				break;
		}
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[90%] xl:max-w-[1100px] 2xl:max-w-[1300px] gap-2 p-6 overflow-hidden bg-white"
				setModalOpen={() => setIsLoading(false)}
			>
				<h3 className="font-bold text-secondary dynamic-large">
					Change tone of voice
				</h3>
				<div className="max-h-[700px] ">
					<div className="h-full pr-4 overflow-y-scroll light-scroll2">
						<ToneOfVoiceEdit
							onSubmit={onSubmit}
							setIsLoading={setIsLoading}
							type={changeToneProps?.type || 'proposal'}
							toneOfVoice={toneOfVoiceData}
							uploadedDocumentTone={changeToneProps?.uploadedDocumentTone}
						/>
					</div>
				</div>
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default ChangeToneDialog;
