import { FC } from 'react';
import generateLottie from '@/assets/lotties/generating-write-lottie.json';
import generateLottie2 from '@/assets/lotties/generating-write-lottie2.json';
import Lottie from 'lottie-react';

interface WriteModeSkeletonProps {}

const WriteModeSkeleton: FC<WriteModeSkeletonProps> = () => {
	return (
		<div className="p-6">
			<div className="flex items-center justify-center bg-white min-h-[160px] rounded-2xl">
				<Lottie
					animationData={generateLottie}
					loop={true}
					autoplay
					className="w-80"
				/>
			</div>
			<div className="flex flex-col items-center justify-center max-w-sm gap-8 mx-auto mt-6">
				<p className="text-center text-black dynamic-text">
					Request:{' '}
					<span className="font-bold">
						Please don't interrupt this writing process by clicking anywhere
						else
					</span>
				</p>
				<Lottie
					animationData={generateLottie2}
					loop={true}
					autoplay
					className="w-60"
				/>
			</div>
		</div>
	);
};

export default WriteModeSkeleton;
