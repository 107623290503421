import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	fetchProposalById,
	processStart,
	processSuccess,
} from '@/redux/slices/proposalSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { detachFormativeDocument } from '@/lib/functions/apiCalls';
import { closeDialog, openDialog } from '@/redux/slices/pageSlice';
import { ErrorHandle } from '@/lib/functions/funcUtils';
import { FormativeDocumentTable } from '@/components/Tables/FormativeDocumentTable/FormativeDocumentTable';
import { columns } from '@/components/Tables/FormativeDocumentTable/column';
import AISmall from '../ui/icons/new/AISmall';
import { FileUp } from 'lucide-react';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';

const EssentialDocuments = () => {
	const dispatch = useAppDispatch();
	const proposal = useSelector((state: RootState) => state.proposal.proposal);

	const handleDetachDocument = async (documentId: number) => {
		try {
			if (proposal?.id) {
				dispatch(processStart());
				const response = await detachFormativeDocument(proposal.id, documentId);
				if (response.statusCode >= 400 && response.statusCode <= 599) {
					throw response; // Throw the response if status is 400s or 500s
				}
				if (response.statusCode === 201) {
					dispatch(processSuccess());
					dispatch(fetchProposalById(proposal.id));
					dispatch(closeDialog());
				}
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	return (
		<div className="flex flex-col w-full gap-4 p-6 bg-white h-full rounded-2xl shadow-main">
			<div className="relative flex flex-col gap-1">
				<h2 className="text-black font-bold dynamic-large">
					Add essential documents
				</h2>
				<p className="text-black whitespace-pre-line text-md">
					You can add essential documents to your proposal. These core documents
					contain valuable qualitative information related to your proposal.
					Note that these are not boilerplate policy documents but are critical
					resources to inform and enhance your proposal.
				</p>
				<div className="flex gap-4 pt-2">
					<PrimaryButton
						onClick={() =>
							dispatch(
								openDialog({
									type: 'addDocuments',
									addDocumentType: 'attachFormativeDocument',
								})
							)
						}
					>
						<AISmall className=" group/icon-hover:fill-white" />
						Search in your library
					</PrimaryButton>
					<PrimaryButton
						onClick={() =>
							dispatch(
								openDialog({
									type: 'uploadDocument',
									uploadDocumentType: 'attachFormativeDocument',
								})
							)
						}
					>
						<FileUp size={16} />
						Upload a new document
					</PrimaryButton>
				</div>
			</div>
			<FormativeDocumentTable
				columns={columns(handleDetachDocument)}
				data={(proposal?.formativeDocuments as any) || []}
			/>
		</div>
	);
};

export default EssentialDocuments;
