import { FC } from 'react';
import styles from './SideBar.module.css';
import { cn } from '@/lib/utils';

import SideBarMenu from '@/components/SideBarMenu/SideBarMenu';
import SettingsMenu from '@/components/SettingsMenu/SettingsMenu';
import Logo from '@/components/ui/icons/new/Logo';

interface SideBarProps {}

const SideBar: FC<SideBarProps> = () => {
	return (
		<aside
			className={cn(
				styles.sidebar,
				'flex flex-col justify-between h-full items-center'
			)}
		>
			<div className="flex flex-col gap-10">
				<Logo />

				<SideBarMenu />
			</div>
			<SettingsMenu />
		</aside>
	);
};

export default SideBar;
