import { FC } from 'react';
import { motion } from 'framer-motion';
import { containerDivFadeIn } from '@/constants/variants';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { TemplateBodyHeaderProps } from '@/lib/types/TemplateBuilder/templateTypes';

const TemplateBodyHeader: FC<TemplateBodyHeaderProps> = ({
	templateCount,
	handleTemplateSorting,
}) => {
	return (
		<motion.div
			className="z-10 flex items-center justify-between px-8 pt-8 pb-1"
			key="containerDivFadeIn"
			variants={containerDivFadeIn}
			initial="initial"
			animate="animate"
		>
			<h1 className="font-bold text-black dynamic-large prebuild-templates">
				Prebuilt Templates
				<b className="ml-2 text-secondary">({templateCount ?? 0} found)</b>
			</h1>
			<div className="flex gap-4">
				<Select
					onValueChange={(value) => {
						handleTemplateSorting(value);
					}}
					defaultValue="desc"
				>
					<SelectTrigger className="h-13 w-52 rounded-xl dynamic-text border border-primary bg-white text-black px-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]">
						<SelectValue placeholder="Sort by" />
					</SelectTrigger>
					<SelectContent className="py-2 bg-white rounded-xl">
						<SelectItem
							className="text-black dynamic-text py-2 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
							value="desc"
						>
							Sort by latest
						</SelectItem>
						<SelectItem
							className="text-black dynamic-text cursor-pointer rounded-lg py-2 hover:bg-[#E3EEFF] hover:text-primary"
							value="asc"
						>
							Sort by oldest
						</SelectItem>
					</SelectContent>
				</Select>
			</div>
		</motion.div>
	);
};

export default TemplateBodyHeader;
