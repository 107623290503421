import { FC, useRef, useState } from 'react';
import styles from './FolderCard.module.css';
import { Folder } from '@/lib/types/apiTypes';
import { cn } from '@/lib/utils';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { FolderIcon, LucideMoreVertical } from 'lucide-react';
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useQueryClient } from 'react-query';
import { updateFolder } from '@/lib/functions/apiCalls';
import useAuth from '@/lib/hooks/useAuth';

interface FolderCardProps {
	folderCard: Folder;
	openFolder: (folderId: number) => void;
	foldersPath: Folder[];
}

const FolderCard: FC<FolderCardProps> = ({
	folderCard,
	openFolder,
	foldersPath,
}) => {
	const dispatch = useAppDispatch();
	const cardRef = useRef<HTMLDivElement | null>(null);
	const [openDropdown, setOpenDropdown] = useState(false);
	const queryClient = useQueryClient();
	const { isGuest, isAdmin } = useAuth();

	const folderName = folderCard.name;

	const handleRenameFolder = async (folderId: number, folderName: string) => {
		try {
			const response = await updateFolder(folderId, { name: folderName });
			handleResponse(response, 'Folder has been updated successfully.');
			queryClient.invalidateQueries('folders');
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleFolderVisibilityUpdate = async (
		folderId: number,
		visibilityStatus
	) => {
		try {
			const response = await updateFolder(folderId, {
				status: visibilityStatus,
			});
			handleResponse(
				response,
				`Folder has been ${visibilityStatus === 'active' ? 'unhidden' : 'hidden'} successfully.`
			);
			queryClient.invalidateQueries('folders');
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	return (
		<div
			className={cn(
				styles.card,
				'relative flex rounded-2xl p-5 cursor-pointer justify-between items-center',
				folderCard.status === 'inactive' && 'opacity-75'
			)}
			onClick={() => openFolder(folderCard.id)}
			ref={cardRef}
		>
			<div className="flex items-center gap-3 w-full">
				<FolderIcon size={18} className="text-black" />

				{folderName?.length >= 18 ? (
					<TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger className="w-full overflow-hidden">
								<h4 className="text-start text-black font-bold dynamic-medium truncate mr-6">
									{folderName}
								</h4>
							</TooltipTrigger>
							<TooltipContent
								className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect"
								align="start"
							>
								{folderName}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				) : (
					<h4 className="text-start text-black font-bold dynamic-medium">
						{folderName}
					</h4>
				)}
			</div>
			{!isGuest && (
				<div
					className={cn(
						styles.options,
						'absolute text-black right-4',
						openDropdown && '!flex'
					)}
					onClick={(event) => event.stopPropagation()}
				>
					<DropdownMenu
						open={openDropdown}
						onOpenChange={(state) => setOpenDropdown(state)}
					>
						<DropdownMenuTrigger>
							<LucideMoreVertical
								className="text-primary animated-hover-effect"
								size={18}
							/>
						</DropdownMenuTrigger>
						<DropdownMenuContent
							className={cn(
								'bg-white rounded-2xl p-2 w-52',
								!openDropdown && '!hidden'
							)}
							sideOffset={10}
							alignOffset={-20}
							align="end"
						>
							<DropdownMenuCheckboxItem
								className="text-black px-2 text-sm xl:text-base cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary capitalize"
								onClick={() =>
									dispatch(
										openDialog({
											type: 'nameEditor',
											nameEditorObj: {
												title: 'Rename Folder',
												label: 'Folder Name',
												placeholder: 'Enter folder name',
												existingName: folderName,
												maxLength: 50,
												onConfirm: (folderName) => {
													handleRenameFolder(folderCard.id, folderName);
												},
											},
										})
									)
								}
							>
								Rename Folder
							</DropdownMenuCheckboxItem>
							<DropdownMenuCheckboxItem
								className="text-black px-2 text-sm xl:text-base cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary capitalize"
								onClick={() =>
									dispatch(
										openDialog({
											type: 'moveDocuments',
											moveDocuments: {
												folderIds: [folderCard.id],
												foldersPath: foldersPath,
											},
										})
									)
								}
							>
								Move Folder
							</DropdownMenuCheckboxItem>
							{isAdmin && (
								<DropdownMenuCheckboxItem
									className="text-black px-2 text-sm xl:text-base cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary capitalize"
									onClick={() =>
										dispatch(
											openDialog({
												type: 'notification',
												notificationObj: {
													title: `${folderCard.status === 'active' ? 'Hide' : 'Unhide'} Folder`,
													description: `Are you sure you want to ${folderCard.status === 'active' ? 'hide' : 'unhide'} this folder?`,
													onConfirm: () =>
														handleFolderVisibilityUpdate(
															folderCard.id,
															folderCard.status === 'active'
																? 'inactive'
																: 'active'
														),
												},
											})
										)
									}
								>
									{folderCard.status === 'active' ? 'Hide' : 'Unhide'} Folder
								</DropdownMenuCheckboxItem>
							)}
							{/* <div className="my-2 mx-3 border-blackSecondary border-t"></div>
						 <DropdownMenuCheckboxItem
							className="text-black pl-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#ff388c] hover:text-white capitalize"
						>
							Delete Folder
						</DropdownMenuCheckboxItem> */}
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			)}
		</div>
	);
};

export default FolderCard;
