const DeleteIcon = ({ color = '#E861C9', className = 'h-6' }) => {
	return (
		<svg
			className={className}
			width="20"
			height="24"
			viewBox="0 0 33 40"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.7707 14.4922C21.2533 14.4922 20.834 14.9115 20.834 15.429V33.1338C20.834 33.6509 21.2533 34.0706 21.7707 34.0706C22.2882 34.0706 22.7075 33.6509 22.7075 33.1338V15.429C22.7075 14.9115 22.2882 14.4922 21.7707 14.4922Z"
				fill={color}
			/>
			<path
				d="M10.717 14.4922C10.1996 14.4922 9.78027 14.9115 9.78027 15.429V33.1338C9.78027 33.6509 10.1996 34.0706 10.717 34.0706C11.2345 34.0706 11.6538 33.6509 11.6538 33.1338V15.429C11.6538 14.9115 11.2345 14.4922 10.717 14.4922Z"
				fill={color}
			/>
			<path
				d="M2.66057 11.9084V34.9883C2.66057 36.3525 3.16078 37.6336 4.03461 38.5528C4.90441 39.4745 6.11489 39.9978 7.38172 40H25.1056C26.3728 39.9978 27.5833 39.4745 28.4527 38.5528C29.3265 37.6336 29.8268 36.3525 29.8268 34.9883V11.9084C31.5638 11.4473 32.6894 9.76919 32.457 7.98678C32.2243 6.20473 30.7061 4.87167 28.9087 4.8713H24.1125V3.70035C24.118 2.71564 23.7286 1.7701 23.0315 1.07448C22.3345 0.37922 21.3875 -0.00792724 20.4028 0.000123083H12.0846C11.0999 -0.00792724 10.1529 0.37922 9.45577 1.07448C8.75869 1.7701 8.36934 2.71564 8.37483 3.70035V4.8713H3.57867C1.78125 4.87167 0.263033 6.20473 0.0303049 7.98678C-0.202057 9.76919 0.923525 11.4473 2.66057 11.9084ZM25.1056 38.1265H7.38172C5.78007 38.1265 4.5341 36.7506 4.5341 34.9883V11.9907H27.9532V34.9883C27.9532 36.7506 26.7073 38.1265 25.1056 38.1265ZM10.2484 3.70035C10.2421 3.21257 10.4339 2.74309 10.7801 2.39875C11.1258 2.05442 11.5964 1.86524 12.0846 1.87365H20.4028C20.8909 1.86524 21.3615 2.05442 21.7073 2.39875C22.0534 2.74272 22.2452 3.21257 22.239 3.70035V4.8713H10.2484V3.70035ZM3.57867 6.74483H28.9087C29.8399 6.74483 30.5948 7.49973 30.5948 8.43101C30.5948 9.36229 29.8399 10.1172 28.9087 10.1172H3.57867C2.64739 10.1172 1.89249 9.36229 1.89249 8.43101C1.89249 7.49973 2.64739 6.74483 3.57867 6.74483Z"
				fill={color}
			/>
			<path
				d="M16.2434 14.4922C15.726 14.4922 15.3066 14.9115 15.3066 15.429V33.1338C15.3066 33.6509 15.726 34.0706 16.2434 34.0706C16.7608 34.0706 17.1802 33.6509 17.1802 33.1338V15.429C17.1802 14.9115 16.7608 14.4922 16.2434 14.4922Z"
				fill={color}
			/>
		</svg>
	);
};

export default DeleteIcon;
