import { FC } from 'react';
import styles from './TransparentAnimatedLoader.module.css';
import FileLoading from '@/assets/lotties/logo_loading_v1.json';
import AddThisLottie from '@/assets/lotties/add-this-in.json';
import Lottie from 'lottie-react';

interface TransparentAnimatedLoaderProps {
	type?: string;
}

const TransparentAnimatedLoader: FC<TransparentAnimatedLoaderProps> = ({
	type,
}) => {
	return (
		<div
			className={
				styles.loader +
				(type === 'addThis'
					? ' bg-[rgba(205,205,255,0.25)]'
					: ' bg-[#0A0F27D9]')
			}
		>
			<Lottie
				className="animation-lottie"
				animationData={type === 'addThis' ? AddThisLottie : FileLoading}
				loop={type === 'addThis' ? false : true}
				autoPlay={true}
			/>
		</div>
	);
};

export default TransparentAnimatedLoader;
