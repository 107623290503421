import { getStepsForPage } from '@/constants/Onboarding/tourConfiguration';
import {
	checkTourCompleted,
	markTourCompleted,
} from '@/constants/Onboarding/tourFlags';
import { Page } from '@/constants/Onboarding/types';
import { resetTour, setTour, startTour } from '@/redux/slices/tourSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetails } from '../functions/apiCalls';
import { User } from '../types/apiTypes';
import { updateProfile } from '@/redux/slices/authSlice';
import { isEqual } from 'lodash';

export const useTour = async (page: Page) => {
	const dispatch = useDispatch();
	const dialog = useSelector((root: RootState) => root.page.dialog);

	const isTemplateBuilderEditPage =
		location.pathname.includes('/template/edit');
	const isTemplateBuilderNew = location.pathname.includes('/template/new');
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const userRef = useRef<User | null>(null);

	const plan = useSelector(
		(root: RootState) => root.auth.user?.organization?.plan?.type
	);
	const [currentPage, setCurrentPage] = useState<Page>();

	const { refetch: refetchUser } = useGetUserDetails(false);

	const handleUserUpdate = (user: User) => {
		if (!isEqual(userRef.current, user)) {
			dispatch(updateProfile({ user }));
			userRef.current = user;
		}
	};

	useEffect(() => {
		if (
			currentPage === page ||
			dialog ||
			isTemplateBuilderEditPage ||
			isTemplateBuilderNew
		)
			return;
		dispatch(resetTour());
		async function handleTour() {
			// dispatch(processStart());
			setCurrentPage(page);
			dispatch(setTour(getStepsForPage(page)));
			const { data: user } = await refetchUser();
			if (user) {
				handleUserUpdate(user);
			}
			const tourCompleted = await checkTourCompleted(page, user);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore

			if (!tourCompleted) {
				const startTourSequence = async () => {
					dispatch(startTour());
					await markTourCompleted(page, user);
				};

				startTourSequence();
			}
		}

		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		// Set new timeout
		timeoutRef.current = setTimeout(() => {
			handleTour();
		}, 1600);

		// Cleanup

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [
		page,
		dialog,
		dispatch,
		isTemplateBuilderEditPage,
		isTemplateBuilderNew,
		plan,
	]);
};
