import { FC, useState } from 'react';
import styles from './CitationBox.module.css';
import CitationBoxItem from '../CitationBoxItem/CitationBoxItem';
import { cn } from '@/lib/utils';
import { updateSection } from '@/lib/functions/apiCalls';
import useProposalId from '@/lib/hooks/useProposalId';
import { Section } from '@/lib/types/apiTypes';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import customToast from '../CustomToast/CustomToast';

interface CitationBoxProps {
	title: string;
	prefix: string;
	citation: string[];
	section: Section;
}

const CitationBox: FC<CitationBoxProps> = ({
	title,
	prefix,
	citation,
	section,
}) => {
	const [references, setReferences] = useState<string[]>(citation);
	const proposalId = useProposalId() as number;
	const dispatch = useAppDispatch();
	const handleEdit = async (index: number, newCite: string) => {
		if (references[index] !== newCite) {
			const updatedReferences = [...references];
			updatedReferences[index] = newCite;
			setReferences(updatedReferences);
			try {
				const updatedSection: Section = {
					...section,
					references: updatedReferences,
				};
				const response = await updateSection(
					proposalId,
					Number(section.id),
					updatedSection
				);
				if (response.statusCode === 200) {
					customToast.success({ title: 'Citation updated successfully' });
					dispatch(fetchProposalById(proposalId));
				}
			} catch (e) {
				console.log(e);
				customToast.error({ title: 'Error updating Citation' });
			}
		}
	};

	const handleDelete = async (index: number) => {
		const newReferences = references.filter((_, i) => i !== index);
		setReferences(newReferences);
		try {
			const updatedSection: Section = {
				...section,
				references: newReferences,
			};
			const response = await updateSection(
				proposalId,
				Number(section.id),
				updatedSection
			);
			if (response.statusCode === 200) {
				customToast.success({ title: 'Citation deleted successfully' });
				dispatch(fetchProposalById(proposalId));
			}
		} catch (e) {
			console.log(e);
			customToast.error({ title: 'Error deleting Citation' });
		}
	};
	return (
		<div>
			<div className={cn(styles.title, 'flex items-center  gap-2')}>
				<span className="w-[40px]">{prefix}</span>
				<span className="block w-[3px] h-5 mx-2 bg-secondary"></span>
				{title}
			</div>
			<div className={styles.citationBox}>
				<ul className={styles.citation}>
					{references.map((cite, index) => (
						<CitationBoxItem
							key={index}
							cite={cite}
							index={index}
							handleEdit={handleEdit}
							handleDelete={() => handleDelete(index)}
							// setReferences={setReferences}
						/>
					))}
				</ul>
			</div>
		</div>
	);
};

export default CitationBox;
