import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import TemplateBuilder from '@/pages/TemplateBuilder/TemplateBuilder';
import TemplateDesigner from '@/pages/TemplateBuilder/TemplateDesigner';
import {
	buildTemplate,
	setEditTemplateId,
	setTemplateMode,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

interface TemplateAssemblerProps {
	defaultTab: 'templates' | 'design';
}

const TemplateAssembler: FC<TemplateAssemblerProps> = ({ defaultTab }) => {
	const [selectedTab, setSelectedTab] = useState(defaultTab);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleTabChange = useCallback(
		(newTabIndex) => {
			setTimeout(() => {
				setSelectedTab(newTabIndex);
				if (newTabIndex === 'templates') {
					dispatch(setTemplateMode(''));
					dispatch(setEditTemplateId(undefined));
					dispatch(buildTemplate(null));
				}
			}, 250);
		},
		[dispatch]
	);

	useEffect(() => {
		handleTabChange(defaultTab);
	}, [defaultTab, handleTabChange]);

	return (
		<AppTabs
			defaultTab={defaultTab}
			selectedTab={selectedTab}
			handleTabChange={handleTabChange}
			type="main"
			tabs={[
				{
					label: 'Template Library',
					value: 'templates',
					content: <TemplateBuilder />,
					onClick: () => navigate(`/templates`),
				},

				{
					label: 'Design Template',
					value: 'design',
					content: <TemplateDesigner />,
				},
			]}
		/>
	);
};

export default TemplateAssembler;
