import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	useReactTable,
	SortingState,
	getSortedRowModel,
	getPaginationRowModel,
	RowData,
} from '@tanstack/react-table';

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronsLeftIcon,
	ChevronsRightIcon,
} from 'lucide-react';
import { useEffect, useState } from 'react';

interface TeamsTableMetaProps {}

declare module '@tanstack/react-table' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface TableMeta<TData extends RowData> extends TeamsTableMetaProps {}
}

interface TeamsTableProps<TData, TValue> extends TeamsTableMetaProps {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
}

const pageSize = 8;

export function TeamsTable<TData, TValue>({
	columns,
	data,
}: TeamsTableProps<TData, TValue>) {
	const [sorting, setSorting] = useState<SortingState>([]);
	const table = useReactTable({
		data,
		columns,
		initialState: {
			pagination: {
				pageSize: pageSize,
				pageIndex: 0,
			},
		},
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		state: {
			sorting,
		},
		getPaginationRowModel: getPaginationRowModel(),
		autoResetAll: false,
	});

	useEffect(() => {
		table.setPageIndex(0);
	}, [data.length]);

	return (
		<div>
			<Table parentClassName="shadow-main">
				<TableHeader className=" bg-blackSecondary">
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id} className="w-full">
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id} className="p-4 text-lg text-black">
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody className="overflow-y-auto text-base text-black bg-white">
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow
								className="transition hover:bg-lightBackground"
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								No teams yet, pleaset create new teams.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			{table.getRowCount() > pageSize && (
				<div className="flex items-center justify-end px-2 mt-4">
					<div className="flex items-center space-x-2">
						<Button
							className="hidden w-8 h-8 p-0 lg:flex"
							onClick={() => table.setPageIndex(0)}
							disabled={!table.getCanPreviousPage()}
						>
							<span className="sr-only">Go to first page</span>
							<ChevronsLeftIcon className="w-4 h-4 text-white" />
						</Button>
						<Button
							className="w-8 h-8 p-0"
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}
						>
							<span className="sr-only">Go to previous page</span>
							<ChevronLeftIcon className="w-4 h-4 text-white" />
						</Button>
						<Button
							className="w-8 h-8 p-0"
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}
						>
							<span className="sr-only">Go to next page</span>
							<ChevronRightIcon className="w-4 h-4 text-white" />
						</Button>
						<Button
							className="hidden w-8 h-8 p-0 lg:flex "
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							disabled={!table.getCanNextPage()}
						>
							<span className="sr-only">Go to last page</span>
							<ChevronsRightIcon className="w-4 h-4 text-white" />
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}
