import { SVGProps } from 'react';

const SuggestionImage = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="254"
			height="196"
			viewBox="0 0 254 196"
			fill="none"
		>
			<g clipPath="url(#clip0_2747_6644)">
				<path
					d="M176.114 83.0117H17.0393C14.912 83.0117 13.1875 84.7362 13.1875 86.8635V181.363C13.1875 183.491 14.912 185.215 17.0393 185.215H176.114C178.242 185.215 179.966 183.491 179.966 181.363V86.8635C179.966 84.7362 178.242 83.0117 176.114 83.0117Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.51523"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M188.799 184.459H6.59026C4.39276 184.459 2.61133 186.24 2.61133 188.438V190.263C2.61133 192.46 4.39276 194.242 6.59026 194.242H188.799C190.996 194.242 192.778 192.46 192.778 190.263V188.438C192.778 186.24 190.996 184.459 188.799 184.459Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.51523"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M19.1484 186.748H175"
					stroke="#AFC1DB"
					strokeWidth="4.74181"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M146.468 1.91653L68.3034 2.01202C62.2603 2.01941 57.3673 6.92432 57.3747 12.9675L57.5111 124.587C57.5184 130.63 62.4234 135.523 68.4665 135.516L146.632 135.42C152.675 135.413 157.568 130.508 157.56 124.465L157.424 12.8452C157.417 6.80209 152.512 1.90914 146.468 1.91653Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M73.3574 32.302L117.821 32.2422"
					stroke="#AFC1DB"
					strokeWidth="3.86675"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M73.3711 50.6635L133.668 50.4541"
					stroke="#AFC1DB"
					strokeWidth="3.88171"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M73.3789 68.8301L128.329 68.5459"
					stroke="#AFC1DB"
					strokeWidth="3.88171"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M153.706 27.9873C142.278 18.1223 126.482 14.1658 111.711 17.0901C107.537 17.9203 103.498 19.3339 99.699 21.256C97.8517 22.1909 99.4747 24.9657 101.322 24.0308C113.73 17.7558 129.018 17.8605 141.53 23.8289C145.098 25.5267 148.441 27.6732 151.433 30.2535C152.996 31.5998 155.277 29.3336 153.706 27.9798V27.9873Z"
					fill="#AFC1DB"
				/>
				<path
					d="M103.715 12.2141C101.045 15.8489 98.3823 19.4838 95.7123 23.1187C94.8297 24.3229 96.2807 25.7813 97.5297 25.4822C101.785 24.4575 106.213 24.6221 110.401 25.9085C112.383 26.5218 113.229 23.4179 111.254 22.8046C106.527 21.3536 101.479 21.219 96.6771 22.3783L98.4945 24.7417C101.165 21.1068 103.827 17.4719 106.497 13.837C107.013 13.134 106.617 12.042 105.921 11.6382C105.106 11.1595 104.239 11.511 103.722 12.2141H103.715Z"
					fill="#AFC1DB"
				/>
				<path
					d="M224.748 21.5366L154.047 21.6229C150.904 21.6268 148.359 24.1781 148.362 27.3216L148.379 40.8664C148.383 44.0098 150.934 46.5549 154.078 46.5511L224.778 46.4647C227.922 46.4609 230.467 43.9095 230.463 40.7661L230.447 27.2213C230.443 24.0778 227.891 21.5327 224.748 21.5366Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M244.643 72.2811L153.554 72.3924C149.506 72.3974 146.229 75.6829 146.234 79.731L146.255 97.1874C146.26 101.235 149.545 104.513 153.593 104.508L244.683 104.397C248.731 104.392 252.008 101.106 252.003 97.0582L251.982 79.6018C251.977 75.5537 248.691 72.2762 244.643 72.2811Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M161 31H214"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M160.916 37.7939H188.5"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M160.154 82.7802L238.118 82.7129"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M160.939 93.6103L217.751 93.2812"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M38.033 64.0378C38.5715 61.7342 40.7106 60.2159 43.0067 60.5151C47.6363 61.1209 56.5665 63.0505 64.3299 69.2059C68.2864 72.3472 72.213 77.1488 75.0999 82.3768C75.1373 82.4441 75.1673 82.5039 75.2047 82.5712L83.4617 84.8075C84.8229 85.174 85.9149 86.1463 86.4683 87.4701L90.6043 97.4025C91.0232 98.4047 90.9185 99.549 90.3126 100.454C89.7143 101.359 88.7196 101.883 87.65 101.853L80.3952 101.651C80.3952 101.793 80.3952 101.942 80.3952 102.084C80.3728 103.573 79.6473 104.964 78.4431 105.817L66.8205 114.059C65.8257 114.762 64.6066 115.023 63.4399 114.807C63.2005 114.762 62.9612 114.694 62.7293 114.612C62.5947 114.56 62.4601 114.515 62.3329 114.463L59.9471 121.493C59.5956 122.525 58.7728 123.296 57.7332 123.55C57.2994 123.655 56.8656 123.669 56.4468 123.587C55.856 123.475 55.3025 123.191 54.8537 122.75L47.2549 115.27C46.2377 114.276 45.7441 112.877 45.8862 111.441L46.7687 102.728C46.7238 102.668 46.679 102.616 46.6266 102.556C42.8721 97.941 39.8879 92.4812 38.4219 87.5673C35.5499 77.9341 36.9485 68.6898 38.033 64.0378Z"
					fill="#AFC1DB"
				/>
				<path
					d="M85.623 118.389C85.1967 118.307 84.7928 118.06 84.5311 117.663L80.0585 111.029C79.52 110.236 79.7145 109.145 80.4923 108.599C81.2627 108.053 82.3247 108.247 82.8632 109.04L87.3358 115.674C87.8743 116.467 87.6798 117.559 86.902 118.105C86.5131 118.381 86.0568 118.464 85.6305 118.389H85.623Z"
					fill="#AFC1DB"
				/>
				<path
					d="M74.561 112.803C75.3313 112.257 76.3934 112.451 76.9319 113.244L85.5255 126.003C86.064 126.796 85.8695 127.888 85.0917 128.434C84.7028 128.711 84.2466 128.793 83.8202 128.718C83.3939 128.644 82.99 128.389 82.7283 127.993L74.1347 115.233C73.5962 114.441 73.7906 113.349 74.5685 112.803H74.561Z"
					fill="#AFC1DB"
				/>
				<path
					d="M71.0003 117.446L75.4728 124.08C76.0113 124.873 75.8169 125.965 75.039 126.511C74.6501 126.788 74.1939 126.87 73.7676 126.795C73.3412 126.72 72.9374 126.466 72.6756 126.07L68.203 119.436C67.6645 118.643 67.859 117.551 68.6368 117.005C69.4072 116.459 70.4692 116.653 71.0077 117.446H71.0003Z"
					fill="#AFC1DB"
				/>
				<path
					d="M49.6044 112.742L56.8742 119.9L59.1927 113.049C55.8869 111.344 52.7307 109.003 49.8362 106.131L49.2603 111.808C49.223 112.159 49.3501 112.503 49.5969 112.75L49.6044 112.742Z"
					fill="white"
				/>
				<path
					d="M64.8756 111.18L76.5057 102.938C76.8049 102.728 76.9844 102.384 76.9844 102.025C77.0143 100.178 76.8647 98.3905 76.588 96.6777L59.1465 109.041C60.9041 110.088 62.5196 110.813 63.8808 111.322C64.2099 111.442 64.5839 111.389 64.883 111.18H64.8756Z"
					fill="white"
				/>
				<path
					d="M82.5865 88.1805L77.209 86.7295C78.8095 90.5214 79.8267 94.3732 80.2156 98.1502L87.291 98.3447L83.3345 88.8386C83.1998 88.5095 82.9306 88.2702 82.594 88.1805H82.5865Z"
					fill="white"
				/>
				<path
					d="M49.2375 100.319C51.5336 103.139 53.8596 105.308 56.066 106.976L75.7812 93.0044C74.8089 89.4742 73.4028 86.4152 72.1238 84.0967C69.4538 79.2576 65.8563 74.8374 62.2363 71.9729C55.1087 66.3186 46.8516 64.5385 42.566 63.9851C41.9976 63.9103 41.4666 64.2843 41.3394 64.8527C40.3297 69.1607 39.0358 77.7094 41.6685 86.5498C43.0073 91.0299 45.7596 96.0484 49.23 100.319H49.2375Z"
					fill="white"
				/>
				<path
					d="M51.945 90.087C49.7984 89.6831 47.8015 88.4415 46.4702 86.467C45.1763 84.5449 44.6901 82.2263 45.1015 79.9302C45.5128 77.6341 46.7693 75.6372 48.6391 74.3133C52.5059 71.5759 57.8161 72.5707 60.4862 76.5347C61.7801 78.4568 62.2663 80.7754 61.8549 83.0715C61.4435 85.3676 60.187 87.3645 58.3172 88.6884C56.3876 90.0571 54.0915 90.4908 51.945 90.0945V90.087ZM57.674 78.5241C56.8738 77.3349 55.6771 76.5945 54.3907 76.3477C53.1042 76.1009 51.7281 76.3701 50.5688 77.1928C49.4469 77.9856 48.6915 79.1823 48.4447 80.5659C48.1979 81.9496 48.4896 83.3333 49.2674 84.485C50.868 86.8634 54.0616 87.4618 56.3801 85.8163C57.502 85.0236 58.2574 83.8269 58.5042 82.4432C58.751 81.0596 58.4593 79.6759 57.6815 78.5241H57.674Z"
					fill="#AFC1DB"
				/>
				<path
					d="M101.068 82.4592C100.238 85.3612 99.4076 88.2556 98.5849 91.1575C98.3531 91.9653 98.8691 92.9451 99.7068 93.132C100.544 93.319 101.434 92.8778 101.681 92.0102C102.511 89.1082 103.342 86.2138 104.164 83.3119C104.396 82.5041 103.88 81.5243 103.043 81.3373C102.205 81.1504 101.315 81.5916 101.068 82.4592Z"
					fill="#AFC1DB"
				/>
				<path
					d="M102.452 83.9312C105.354 84.8436 108.256 85.7561 111.158 86.6686C111.958 86.9229 112.945 86.3694 113.132 85.5467C113.334 84.6716 112.871 83.8414 112.01 83.5722C109.109 82.6597 106.207 81.7473 103.305 80.8348C102.504 80.5805 101.517 81.134 101.33 81.9567C101.128 82.8317 101.592 83.6619 102.452 83.9312Z"
					fill="#AFC1DB"
				/>
				<path
					d="M101.09 84.9713C102.325 90.5508 104.733 95.861 108.562 100.132C111.831 103.774 116.049 106.444 120.649 108.052C129.01 110.977 138.778 110.438 146.758 106.594C150.386 104.851 153.879 102.173 155.719 98.5087C156.646 96.6613 153.871 95.0308 152.944 96.8857C152.6 97.5663 152.196 98.2095 151.747 98.8228C151.994 98.4937 151.59 99.0098 151.538 99.0771C151.433 99.2042 151.321 99.3314 151.209 99.4585C150.924 99.7727 150.625 100.079 150.319 100.371C150.034 100.64 149.735 100.902 149.436 101.156C148.995 101.523 149.114 101.433 148.583 101.807C147.858 102.323 147.095 102.794 146.317 103.221C144.567 104.178 143.303 104.686 141.351 105.277C140.409 105.562 139.459 105.801 138.501 106.003C137.469 106.22 136.504 106.369 135.704 106.459C127.163 107.401 118.352 105.218 112.145 99.2042C107.971 95.1655 105.466 89.8029 104.209 84.1187C103.761 82.0993 100.664 82.9519 101.105 84.9713H101.09Z"
					fill="#AFC1DB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2747_6644">
					<rect
						width="252.288"
						height="196"
						fill="white"
						transform="translate(0.855469)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default SuggestionImage;
