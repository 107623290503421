import { Badge } from '@/components/ui/badge';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import styles from './SolicitationBriefDialog.module.css';
// import { PrinterIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { exportDocumentBreakdown } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	convertToISO8601,
	getDeadline,
	getFunding,
	getSectionByName,
} from '@/lib/functions/funcUtils';
import SuccessHollowIcon from '@/components/ui/icons/SuccessHollowIcon';
import LocationHollowIcon from '@/components/ui/icons/LocationHollowIcon';
// import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useMemo } from 'react';
import ExportDownloadButtons from '@/components/ExportDownloadButtons/ExportDownloadButtons';

const SolicitationBriefDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'viewSolicitationBrief'
	);
	const rfp = useSelector((root: RootState) => root.page.dialog?.rfp);
	const viewProposalType = useSelector(
		(root: RootState) => root.page.dialog?.viewProposalType
	);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const solicitation = useSelector((root: RootState) => root.solicitation.rfp);

	const dispatch = useAppDispatch();

	const handlePrint = async (type?: string) => {
		dispatch(processStart());
		const response = await exportDocumentBreakdown(
			rfp?.documentId as number,
			type
		);
		const blob = new Blob([response as ArrayBuffer]);
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = `Summary of ${rfp?.metaData.title}.${type ? type : 'pdf'}`;
		link.click();
		dispatch(processSuccess());
	};

	const downloadButton = () => {
		return (
			<ExportDownloadButtons
				handlePdfExport={() => handlePrint()}
				handleWordExport={() => handlePrint('docx')}
			/>
		);
	};

	const sections = useMemo(() => {
		if (!rfp) return [];
		return rfp?.sections.filter(
			(sectionItem) =>
				sectionItem.name !== 'eligibility' &&
				sectionItem.name !== 'eligibilityAndQualifications' &&
				sectionItem.name !== 'geographicLocation'
		);
	}, [rfp]);

	const eligibilitySection = useMemo(() => {
		if (!rfp) return null;
		return (
			getSectionByName('eligibility', rfp) ||
			getSectionByName('eligibilityAndQualifications', rfp)
		);
	}, [rfp]);

	const targetLocationSection = useMemo(() => {
		if (!rfp) return null;
		return getSectionByName('geographicLocation', rfp);
	}, [rfp]);

	const deadline = useMemo(() => {
		if (!viewProposalType) return null;
		switch (viewProposalType) {
			case 'proposal':
				if (!rfp || !proposal) return null;
				return getDeadline(viewProposalType, rfp, proposal);
			case 'solicitation':
				if (!rfp || !solicitation) return null;
				return getDeadline(viewProposalType, rfp, undefined, solicitation);
		}
	}, [viewProposalType, rfp, proposal, solicitation]);

	const funding = useMemo(() => {
		if (!viewProposalType) return null;
		switch (viewProposalType) {
			case 'proposal':
				if (!rfp || !proposal) return null;
				return getFunding(viewProposalType, rfp, proposal);
			case 'solicitation':
				if (!rfp || !solicitation) return null;
				return getFunding(viewProposalType, rfp, undefined, solicitation);
		}
	}, [viewProposalType, rfp, proposal, solicitation]);

	const eligibilityCards = useMemo(() => {
		if (!eligibilitySection) return [];
		return eligibilitySection.text
			.split('\n')
			.map((line) => line.replace(/^- /, ''))
			.filter((line) => line.trim() !== '');
	}, [eligibilitySection]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[90%] max-h-[85%] gap-0 rounded-2xl overflow-y-scroll hidden-scroll 2xl:max-w-[1280px] bg-white px-8 pt-6 pr-8 ">
				<DialogHeader
					className={cn(
						styles.header,
						'flex flex-row items-center justify-between '
					)}
				>
					<h1 className="font-bold text-black dynamic-large">
						{rfp?.metaData.title ?? proposal?.rfpTitle}
					</h1>
				</DialogHeader>
				<div className="border-b-[1px] border-[#e9e9e9] py-4 mb-4">
					{deadline || funding ? (
						<div className="flex items-center justify-between text-black">
							<div className="flex items-center gap-4 ">
								{deadline ? (
									<Badge className="px-5 py-2 text-white bg-[#e861c9]">
										Due: {convertToISO8601(deadline)}
									</Badge>
								) : null}
								{funding ? (
									<p className="font-bold text-secondary">
										<span className="text-black">Total Funding: </span>
										{funding}
									</p>
								) : null}
							</div>

							{downloadButton()}
						</div>
					) : (
						<>{downloadButton()}</>
					)}
				</div>
				<div className="grid grid-cols-12 gap-4 ">
					<div
						className={cn(
							'grid col-span-7 h-[600px] shadow-main rounded-2xl p-4 '
						)}
					>
						<div className="flex flex-col gap-4 pr-3 overflow-y-auto light-scroll2">
							{sections?.map((section, index) => (
								<div key={index} className="flex flex-col gap-2">
									<h1 className="font-bold text-secondary">
										{section.heading}
									</h1>
									<p className="text-sm text-black xl:text-base">
										{section.name === 'templateSections' &&
										section.summary === 'NONE'
											? 'It appears that the solicitation does not provide specific information about the template sections.'
											: section.summary}
									</p>
								</div>
							))}
						</div>
					</div>
					<div
						className={cn('flex flex-col col-span-5 bg-transparent p-0 gap-4 ')}
					>
						{targetLocationSection && (
							<div
								className={cn(
									styles.body,
									'flex flex-col gap-4 h-[175px] p-4 rounded-2xl shadow-main'
								)}
							>
								<h1 className="inline-flex font-bold text-secondary">
									<LocationHollowIcon className="h-5 my-auto mr-2" />
									{targetLocationSection.heading}
								</h1>
								<div className="overflow-y-auto text-black light-scroll2">
									<ul className="list-disc list-inside">
										{targetLocationSection.text
											.split(',')
											.map((location, index) => (
												<li key={index}>{location}</li>
											))}
									</ul>
								</div>
							</div>
						)}
						{eligibilitySection && (
							<div
								className={cn(
									styles.body,
									'flex flex-col gap-4 bg-[#EAF1FC] rounded-2xl shadow-main p-5'
								)}
							>
								<h1 className="inline-flex font-bold text-black">
									<SuccessHollowIcon className="h-5 my-auto mr-2 " />
									{eligibilitySection.heading}
								</h1>
								<div className="h-[335px] overflow-y-scroll pr-3">
									<div className="flex flex-col gap-3">
										{eligibilityCards.map((card, index) => (
											<div
												key={index}
												className="p-4 text-black bg-white rounded-lg"
											>
												{card}
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default SolicitationBriefDialog;
