import { externalNodeType } from '@/lib/types/constants';
import { SortableTreeWithExternalManager } from '@nosferatu500/react-sortable-tree';
import { FC, useCallback, useEffect } from 'react';
import { DndContext } from 'react-dnd';
import CustomThemeNodeContentRenderer from '../../TemplateDnd/NodeRenderer';
import { TreeInterface } from '@/lib/types/TemplateBuilder/templateTypes';
import GlobalSectionEditDropdown from '../../GlobalSectionEditDropdown/GlobalSectionEditDropdown';
import AvailableSectionNode from '../AvailableSectionNode/AvailableSectionNode';
import { TemplateGlobalSectionsSkeleton } from './TemplateGlobalSectionsSkeleton';

interface TemplateGlobalSectionsProps {
	availableSectionsTree: TreeInterface[];
	dragState: (args: { node: any }) => boolean;
	sectionQuery: any;
}

const TemplateGlobalSections: FC<TemplateGlobalSectionsProps> = ({
	availableSectionsTree,
	dragState,
	sectionQuery,
}) => {
	const onScrollBottom = useCallback(() => {
		if (sectionQuery.hasNextPage && !sectionQuery.isFetchingNextPage) {
			sectionQuery.fetchNextPage();
		}
	}, [sectionQuery]);

	useEffect(() => {
		const item = document.querySelector(
			'.available-sections .rst__virtualScrollOverride'
		);
		const handleScroll = () => {
			if (item) {
				const tolerance = 5; // Tolerance value to account for floating point issues
				const bottom =
					Math.abs(item.scrollHeight - item.scrollTop - item.clientHeight) <=
					tolerance;

				if (bottom) {
					onScrollBottom();
				}
			}
		};

		if (item) {
			item.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (item) {
				item.removeEventListener('scroll', handleScroll);
			}
		};
	}, [onScrollBottom]);

	return (
		<DndContext.Consumer>
			{({ dragDropManager }) =>
				!dragDropManager ? undefined : (
					<SortableTreeWithExternalManager
						dragDropManager={dragDropManager}
						treeData={availableSectionsTree}
						placeholderRenderer={TemplateGlobalSectionsSkeleton}
						onChange={() => null}
						dndType={externalNodeType}
						maxDepth={0}
						canDrag={dragState}
						className="font-bold available-sections dynamic-medium "
						style={{ height: '100%' }}
						nodeContentRenderer={(nodeInfo) => (
							<CustomThemeNodeContentRenderer
								{...nodeInfo}
								{...nodeInfo.nodeProps} // Access nodeProps within the callback
								showWordCount={false}
							/>
						)}
						generateNodeProps={(rowInfo) => ({
							buttons: [
								<GlobalSectionEditDropdown
									rowInfo={rowInfo}
									visible={rowInfo.node.organizationIdentifier ? true : false}
								/>,
							],

							title: <AvailableSectionNode rowInfo={rowInfo} />,
						})}
					/>
				)
			}
		</DndContext.Consumer>
	);
};

export default TemplateGlobalSections;
