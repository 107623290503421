import { FC } from 'react';
import styles from './RelevantDocuments.module.css';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { FileUp } from 'lucide-react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { handleFileRejections, showToast } from '@/lib/functions/funcUtils';
import { FileWithPath } from 'react-dropzone';
import { cloneDeep } from 'lodash';
import FileUpload from '@/components/FileUpload/FileUpload';
import { UploadedDocumentsTable } from '@/components/Tables/UploadedDocumentsTable/UploadedDocumentsTable';
import { columns } from '@/components/Tables/UploadedDocumentsTable/columns';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { setRelevantFiles } from '@/redux/slices/unsolicitedSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

interface RelevantDocumentsProps {
	register: UseFormRegister<FieldValues>;
}

const RelevantDocuments: FC<RelevantDocumentsProps> = ({ register }) => {
	const additionalFiles = useSelector(
		(state: RootState) => state.unsolicited.additionalFiles
	);

	const dispatch = useAppDispatch();

	const handleAdditionalFiles = async (files: File[]) => {
		if (!files.length) return;
		const file: FileWithPath = files[0];
		if (
			additionalFiles &&
			additionalFiles.find(
				(addtionalFile: FileWithPath) => addtionalFile.path === file.path
			)
		) {
			showToast('error', 'Selected document has already been uploaded.');
		} else {
			// Clone the additionalFiles array and add the new file immutably
			const additionalFilesCopy = additionalFiles
				? cloneDeep(additionalFiles)
				: [];
			additionalFilesCopy.push(file);
			dispatch(setRelevantFiles(additionalFilesCopy));
		}
	};

	const handleDetachDocument = (documentPath: string) => {
		if (!additionalFiles) return;
		// Create a new array excluding the file to be detached
		const updatedFiles = additionalFiles.filter(
			(additionalFile: FileWithPath) => additionalFile.path !== documentPath
		);
		dispatch(setRelevantFiles(updatedFiles));
	};

	const renderUploadLayout = (disabled?) => {
		return (
			<div
				className={cn(
					'flex py-8 px-5 mt-4 bg-lightBackground rounded-xl border border-blackSecondary hover:border-primary gap-2 ',
					disabled ? 'opacity-50 cursor-ga' : 'cursor-pointer '
				)}
			>
				<FileUp className="text-primary" strokeWidth={'1'} size={36} />
				<div className="flex flex-col">
					<h4 className="font-bold text-primary ">Import your document</h4>
					<p className="text-black dynamic-small">
						This can be a PDF or a word document. Click here or click to specify
						its location
					</p>
				</div>
			</div>
		);
	};

	return (
		<div className={cn(styles.card, 'my-4 2xl:my-6')}>
			<label className="font-bold text-black">
				Is there a link where we can pull information related to your proposal?
				(optional)
			</label>
			<Input
				{...register('knowledgeUrl')}
				type="text"
				className="p-4 rounded-xl border border-[#D3DDE2] bg-white h-12 my-2"
				placeholder="(optional) please provide a web address where I can find information about your proposal"
			/>
			<label className="block mt-4 font-bold text-black">
				Are there documents with additional information about this funder and
				their priorities? (optional)
			</label>
			<p className="mt-3 font-bold text-secondary dynamic-small">
				NOTE: Please DO NOT INCLUDE boilerplate documents or general policy
				documents.{' '}
			</p>
			<p className="mt-1 text-black dynamic-small">
				<span className="font-bold">
					Please only include documents here that describe the funder and their
					priorities.
				</span>{' '}
				These can be their organizational brochures, information from their
				website that outlines their priorities. Other documents that outline
				their funding priorities etc.
			</p>
			{!additionalFiles?.length || additionalFiles.length < 2 ? (
				<FileUpload
					onDropRejected={(fileRejections) =>
						handleFileRejections({
							fileRejections: fileRejections,
						})
					}
					onDropAccepted={handleAdditionalFiles}
					type="custom"
					customLayout={renderUploadLayout()}
				/>
			) : (
				<>{renderUploadLayout(true)}</>
			)}
			{!!additionalFiles?.length && additionalFiles.length > 0 && (
				<div className="mt-4 shadow-main rounded-xl">
					<UploadedDocumentsTable
						columns={columns(handleDetachDocument)}
						data={(additionalFiles as any) || []}
						showHeader={true}
					/>
				</div>
			)}
		</div>
	);
};

export default RelevantDocuments;
