import CKEditor from '@/components/CKEditor/CKEditors';
import Ai from '@/components/ui/icons/new/AI';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { cn } from '@/lib/utils';
import {
	cropText,
	getCharacterCount,
	getCKEditorText,
	getWordCount,
	scrollToSection,
} from '@/lib/functions/funcUtils';
import { RootState } from '@/redux/store/store';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './SectionCard.module.css';
import { ThreeDots } from 'react-loader-spinner';

import {
	openDialog,
	openDrawer,
	setLastSection,
	startStreaming,
} from '@/redux/slices/pageSlice';
import {
	fetchProposalById,
	setWriterLoader,
} from '@/redux/slices/proposalSlice';
import { MagicContext } from '@/lib/context/MagicProvider';
import Tick from '@/components/ui/icons/new/Tick';
import BlueTick from '@/components/ui/icons/new/BlueTick';
import { updateSectionState } from '@/lib/functions/apiCalls';
import customToast from '@/components/CustomToast/CustomToast';
import { useParams } from 'react-router';
import { Ban } from 'lucide-react';
import { MAGIC_TYPES, SectionCardProps } from '@/lib/types/apiTypes';
import useCustomEvents from '@/lib/hooks/useCustomEvents';
import {
	getSelectionText,
	processEditorState,
} from '@/lib/functions/magicFunctions';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { useSelector } from 'react-redux';
import { fetchSectionRules } from '@/redux/requests/compliance';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import Chat from '@/components/ui/icons/Chat';
import CustomTooltip from '@/components/CustomTooltip/CustomTooltip';

const SectionCard: FC<SectionCardProps> = ({
	description,
	headingPrefix,
	title,
	id,
	expectedOutput,
	editorState,
	headingLevel,
	scrollParentRef,
	content,
	resolved,
	user,
	wordCount,
	type,
}) => {
	const dispatch = useAppDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [countMode, setCountMode] = useState('words');
	const editorRef = useRef<any>(null);
	const dataRef = useRef('');
	const HeadingTag = `h${headingLevel}` as keyof JSX.IntrinsicElements;
	const [isLoaded, setIsLoaded] = useState(false);

	const editorText = getCKEditorText(editorRef.current);

	const [shouldScrollToSection, setShouldScrollToSection] =
		useState<boolean>(false);
	const [currentWordCount, setCurrentWordCount] = useState(
		getWordCount(editorText) || 0
	);

	const activeSectionReference = useSelector(
		(root: RootState) => root.compliance.activeReferences
	);

	const [currentCharCount, setCurrentCharCount] = useState(
		getCharacterCount(editorText) || 0
	);
	const isStreaming = useAppSelector(
		(state: RootState) => state.page.isStreaming
	);

	const isLogicalFrame = location.pathname.endsWith('/logical-frame');

	const writerLoader = useAppSelector(
		(state: RootState) => state.proposal.writerLoader
	);
	const [isFocused, setIsFocused] = useState(false);
	const [isMouseOver, setIsMouseOver] = useState(false);
	const proposal = useAppSelector(
		(state: RootState) => state.proposal.proposal
	);
	const handleMagic = useContext(MagicContext);

	const { nodeId } = useParams();
	const { updateActiveSection, activeSection } = useActiveSection();

	const processedEditorState = useMemo(() => {
		if (id !== activeSection.sectionId || editorState === null)
			return editorState;
		return processEditorState(editorState, activeSectionReference);
	}, [editorState, activeSectionReference, id, activeSection.sectionId]);

	const toggleCountMode = () => {
		setCountMode(countMode === 'words' ? 'characters' : 'words');
	};

	useEffect(() => {
		if (!isLogicalFrame) return;

		updateActiveSection({
			editorHTML: editorState,
			editorRef: editorRef,
			sectionId: Number(id),
			prefix: headingPrefix,
			editorText: editorState,
			sectionName: title,
			sectionType: type,
		});
	}, [isLogicalFrame]);

	const handleMarkComplete = async (isComplete: boolean) => {
		try {
			if (!id || !proposal?.id) return;
			const response = await updateSectionState(proposal.id, Number(id), {
				isComplete: !isComplete,
			});
			if (response.statusCode === 200) {
				if (!isComplete) {
					customToast.success({
						title: 'Section successfully marked as completed',
					});
				} else {
					customToast.success({
						title: 'Section successfully marked as incomplete',
					});
				}
				dispatch(fetchProposalById(proposal.id));
			}
		} catch (error) {
			if (isComplete) {
				customToast.error({
					title: 'Unable to mark section as complete',
				});
			} else {
				customToast.error({
					title: 'Unable to mark section as incomplete',
				});
			}
		}
	};

	const handleFocus = (proposalId: number | null, sectionId: number) => {
		if (proposalId && sectionId) {
			dispatch(setLastSection({ proposalId, sectionId }));

			updateActiveSection({
				editorRef: editorRef,
				sectionId: sectionId,
				prefix: headingPrefix,
				sectionName: title,
				editorText: content,
				editorHTML: editorState,
				sectionType: type,
			});

			!isLogicalFrame && dispatch(fetchSectionRules({ proposalId, sectionId }));
		}
		setIsFocused(true);
	};

	useEffect(() => {
		if (nodeId && id && nodeId === String(id)) {
			scrollToSection(String(nodeId), scrollParentRef);
		}
		setShouldScrollToSection(false);
	}, [nodeId, isLoaded, shouldScrollToSection, id, scrollParentRef]);

	useEffect(() => {
		const editorText = getCKEditorText(editorRef.current);
		setCurrentWordCount(getWordCount(editorText) || 0);
		setCurrentCharCount(getCharacterCount(editorText) || 0);
	}, [editorRef?.current]);

	const handleBlur = () => {
		setIsFocused(false);
	};

	const handleMouseOver = () => {
		setIsMouseOver(true);
	};

	const handleMouseLeave = () => {
		setIsMouseOver(false);
	};

	const handleMagicButtonClick = (shouldScrollToSection: boolean = false) => {
		setShouldScrollToSection(shouldScrollToSection);
		dispatch(setWriterLoader(true));
		dataRef.current = '';
		dispatch(startStreaming());
		if (handleMagic && proposal) {
			handleMagic(
				proposal?.id,
				String(id),
				dataRef,
				editorRef,
				expectedOutput,
				MAGIC_TYPES.COMPLETE
			);
		}
	};

	const events = useMemo(
		() => ({
			'expand-this': (detail) => {
				const selectionText = getSelectionText(detail.editor);

				updateActiveSection({
					editorRef: editorRef,
					sectionId: id,
					prefix: headingPrefix,
					editorSelectedText: selectionText,
					sectionName: title,
				});

				dispatch(
					openDialog({ type: 'openMagicExpand', triggerToolbarMagic: true })
				);
			},
			'concise-this': (detail) => {
				const selectionText = getSelectionText(detail.editor);

				updateActiveSection({
					editorRef: editorRef,
					sectionId: id,
					prefix: headingPrefix,
					editorSelectedText: selectionText,
					sectionName: title,
				});

				dispatch(
					openDialog({ type: 'openMagicConcise', triggerToolbarMagic: true })
				);
			},
			'enhance-this': (detail) => {
				const selectionText = getSelectionText(detail.editor);

				updateActiveSection({
					editorRef: editorRef,
					sectionId: id,
					prefix: headingPrefix,
					editorSelectedText: selectionText,
					sectionName: title,
				});

				dispatch(
					openDialog({ type: 'openMagicEnhance', triggerToolbarMagic: true })
				);
			},
		}),
		[id, title, headingPrefix]
	);

	useCustomEvents(events, id);

	const renderCommentButton = () => {
		return (
			<div
				onMouseDown={() => dispatch(openDrawer({ type: 'viewComments' }))}
				className="flex gap-2 uppercase text-primary"
			>
				<Chat className="w-5 h-4 cursor-pointer animated-hover-effect" />
				Comments
			</div>
		);
	};

	const renderAIButton = (content: string) => {
		if (content?.length) {
			return (
				<div className="flex items-center gap-4">
					{currentWordCount > 1000 ? (
						<div className="flex items-center gap-2 text-red-500 uppercase">
							<Ban size={16} />
							<p className="mt-1 font-bold uppercase ">Word limit exceeded</p>
						</div>
					) : null}
					{!isLogicalFrame && (
						<TextButton
							onClick={() => handleMarkComplete(resolved)}
							onMouseDown={(e) => e.preventDefault()}
							className="h-auto p-0 mt-1 text-base font-semibold 2xl:h-auto text-primary animated-container-transition"
						>
							{resolved ? (
								<div className="flex items-center gap-2 text-primary">
									<Tick /> Completed
								</div>
							) : (
								<p className="flex items-center gap-2 text-primary">
									<BlueTick /> Mark as complete
								</p>
							)}
						</TextButton>
					)}
					{!isLogicalFrame && (
						<TextButton className={cn('relative comments')}>
							{renderCommentButton()}
						</TextButton>
					)}
					<TextButton
						className={cn(
							'flex h-auto 2xl:h-auto p-0 items-center gap-3 cursor-pointer animated-container-transition',
							isSaving ? 'pointer-events-none opacity-50' : ''
						)}
						onMouseDown={(e) => e.preventDefault()}
						onClick={() => handleMagicButtonClick(true)}
					>
						<Ai />
						<p className="p-0 font-bold uppercase text-primary">
							ReWrite with magic
						</p>
					</TextButton>
				</div>
			);
		} else {
			return (
				<div className="flex items-center gap-4">
					{!isLogicalFrame && (
						<TextButton className={cn('relative comments')}>
							{renderCommentButton()}
						</TextButton>
					)}
					<TextButton
						className={cn(
							'flex items-center gap-3 cursor-pointer animated-container-transition h-auto 2xl:h-auto p-0',
							isSaving ? 'pointer-events-none opacity-50' : ''
						)}
						onMouseDown={(e) => e.preventDefault()}
						onClick={() => handleMagicButtonClick()}
					>
						<Ai />
						<p className="p-0 font-bold uppercase text-primary ">
							Write with magic
						</p>
					</TextButton>
				</div>
			);
		}
	};

	return (
		<>
			<div
				id={String(id)}
				className={cn(
					'relative mb-2 animated-container-transition border-override-container p-6',
					styles.sectionCard,
					(isFocused ? styles.focusCard : '') +
						(isMouseOver && !isFocused ? styles.hoverCard : '')
				)}
				onFocus={() => handleFocus(proposal && Number(proposal?.id), id ?? id)}
				onBlur={handleBlur}
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}
			>
				<div className="flex items-center justify-between min-h-[48px]">
					<div className="flex items-center gap-3">
						<HeadingTag
							className={cn(
								'text-black font-bold flex items-center gap-3 flex-1 animated-container-transition dynamic-medium',
								isFocused ? '!text-primary' : ''
							)}
						>
							<span
								className={
									'pr-6 border-black ' +
									(isFocused
										? 'border-separater-pink'
										: 'border-separater-grey')
								}
							>
								{headingPrefix}
							</span>{' '}
							<CustomTooltip
								text={title}
								triggerNode={
									<h2
										className={cn(
											'text-black font-bold flex items-center gap-3 flex-1 animated-container-transition dynamic-medium',
											isFocused ? '!text-primary' : ''
										)}
									>
										{cropText(title, 100)}
									</h2>
								}
							/>
						</HeadingTag>
						{isFocused && user && !isStreaming && (
							<p className="italic text-[#5d6f79] dynamic-small">
								Last edited by{' '}
								<span className="font-semibold capitalize text-secondary">
									{user?.name}
								</span>
							</p>
						)}
						{isSaving && (
							<span className="italic text-gray-300 saving-animation font-regular">
								Saving...
							</span>
						)}
						{writerLoader && String(id) === nodeId && (
							<ThreeDots
								visible={true}
								height="50"
								width="50"
								color="#5D9BFD"
								radius="6"
								ariaLabel="three-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
							/>
						)}
					</div>
					{/* {isFocused && (
						<div className="flex items-center gap-1 dynamic-small opacity-50 text-[#5d6f79] ">
							<Info size={16} /> Type "/" to see more options
						</div>
					)} */}
				</div>
				<CKEditor
					onWordCountChange={setCurrentWordCount}
					placeholder={description}
					editorState={processedEditorState}
					setIsEditorFocused={setIsFocused}
					editorRef={editorRef}
					isStreaming={isStreaming}
					proposalId={proposal?.id as number}
					sectionId={id}
					setIsSaving={setIsSaving}
					onCharacterCountChange={setCurrentCharCount}
					onReady={() => {
						setIsLoaded(true);
					}}
				/>

				<div className="flex justify-end min-h-[50px] py-3">
					{isFocused && renderAIButton(content)}
				</div>
			</div>
			<div className="flex items-center text-[#b4b4b4] text-base mt-5">
				<div
					className={
						'flex-1 h-[1px] mr-8 bg-[#D3DDE2] animated-container-transition '
					}
				>
					<span
						className={
							'relative block rounded-full top-[-1.5px] w-1 h-1 bg-[#D3DDE2] animated-container-transition '
						}
					></span>
				</div>
				<div
					onClick={toggleCountMode}
					className={cn(
						'flex items-center gap-2  shrink-0',
						currentWordCount > Number(wordCount) - 1 ? 'text-red-500' : ''
					)}
				>
					<div>
						{countMode === 'words'
							? `${currentWordCount} / ${wordCount} Words`
							: `${currentCharCount} Characters`}
					</div>
				</div>
			</div>
		</>
	);
};

export default SectionCard;
