import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

const useTemplateId = () => {
	const editTemplateID = useSelector(
		(state: RootState) => state.templates.editTemplateId
	);
	const proposalTemplateID = useSelector(
		(state: RootState) => state.proposal.proposal?.templateId
	);
	return editTemplateID || proposalTemplateID;
};

export default useTemplateId;
