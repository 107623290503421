import { FC, ReactNode, useEffect, useState } from 'react';
import { Input } from '../ui/input';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import { Save } from 'lucide-react';
import { QuestionBuilderItem } from '@/lib/types/apiTypes';

interface QuestionBuilderInputProps {
	onSave: (questionText: string, wordCount: number) => void;
	editingQuestion?: QuestionBuilderItem | null;
	SaveBtn?: ReactNode;
	isEditing?: boolean;
}

const QuestionBuilderInput: FC<QuestionBuilderInputProps> = ({
	onSave,
	editingQuestion,
	SaveBtn,
	isEditing,
}) => {
	const [questionText, setQuestionText] = useState('');
	const [wordCount, setWordCount] = useState<number | string>(100);

	useEffect(() => {
		if (editingQuestion) {
			setQuestionText(editingQuestion.text);
			setWordCount(editingQuestion.wordCount);
		}
	}, [editingQuestion]);

	const handleSave = () => {
		onSave(
			questionText.trim(),
			typeof wordCount === 'number' ? wordCount : parseInt(wordCount, 10)
		);
		setQuestionText('');
		setWordCount(100);
	};

	return (
		<li
			className={`flex flex-col p-4 border-[2px] border-dashed border-[#D3DDE2] rounded-xl max-w-[1400px] ${isEditing ? 'bg-lightBackground' : 'mt-2 mx-auto'}`}
		>
			<div className="flex w-full items-center gap-4 ">
				<div className="flex flex-col gap-1 flex-1">
					<label className="text-black font-bold pl-1">Name</label>
					<Input
						className="h-12 bg-white rounded-xl border border-[#D3DDE2] p-3"
						placeholder="Enter a section name or question"
						value={questionText}
						onChange={(e) => setQuestionText(e.target.value)}
					/>
				</div>
				<div className="flex flex-col gap-1 max-w-[200px] grow-0 ">
					<label className="text-black font-bold pl-1">Word Count</label>
					<Input
						className="h-12 bg-white rounded-xl border border-[#D3DDE2] p-3"
						placeholder="Enter Words Count"
						type="number"
						value={wordCount}
						onChange={(e) => setWordCount(e.target.value)}
					/>
				</div>
				<div className="h-12 flex content-center self-end ">
					<PrimaryButton
						onClick={handleSave}
						disabled={
							!questionText ||
							!questionText.trim() ||
							questionText?.length > 1000 ||
							100 > Number(wordCount) ||
							Number(wordCount) > 1000
						}
					>
						{SaveBtn ? (
							<>{SaveBtn}</>
						) : (
							<>
								<Save />
								Save
							</>
						)}
					</PrimaryButton>
				</div>
			</div>
			{!questionText || !questionText.trim() ? (
				<p className="pt-2 pl-1 dynamic-text font-medium text-lightRed">
					Please enter section name or question
				</p>
			) : !questionText || questionText?.length > 1000 ? (
				<p className="pt-2 pl-1 dynamic-text font-medium text-lightRed">
					Characters must be less than 1000
				</p>
			) : 100 > Number(wordCount) || Number(wordCount) > 1000 ? (
				<p className="pt-2 pl-1 dynamic-text font-medium text-lightRed">
					Word count must be between 100 and 1000
				</p>
			) : (
				<></>
			)}
		</li>
	);
};

export default QuestionBuilderInput;
