import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComplianceMatrix, ComplianceRule } from '@/lib/types/apiTypes';
import { mapRulesBySection } from '@/lib/functions/funcUtils';
import {
	fetchComplianceMatrix,
	fetchSectionRules,
	pollComplianceMatrix,
	runAndFetchComplianceMatrix,
	updateMatrix,
} from '../requests/compliance';

interface ComplianceMatrixState {
	complianceMatrix: ComplianceMatrix | null;
	loading: boolean;
	error: string | null;
	sectionsRules: Record<number, ComplianceRule[]>;
	references: Record<
		number,
		{
			reference: string;
			id: string;
		}[]
	>;
	activeComplianceCardID: number | null;
	activeComplianceSectionID: number | null; // Add this line
	activeReferences: { reference: string; id: string }[];
}

const initialState: ComplianceMatrixState = {
	complianceMatrix: null,
	loading: false,
	error: null,
	sectionsRules: {},
	references: {},
	activeComplianceCardID: null,
	activeComplianceSectionID: null, // Add this line
	activeReferences: [],
};

const complianceMatrixSlice = createSlice({
	name: 'matrix',
	initialState,
	reducers: {
		resetMatrix: (state) => {
			state.complianceMatrix = null;
			state.loading = false;
			state.error = null;
			state.references = {};
			state.sectionsRules = {};
		},
		clearReferences: (state) => {
			state.references = {};
		},
		setComplianceLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setHighlightedCard: (
			state,
			action: PayloadAction<{ cardID: number | null; sectionID: number | null }>
		) => {
			state.activeComplianceCardID = action.payload.cardID;
			state.activeComplianceSectionID = action.payload.sectionID;
		},
		setReferences: (
			state,
			action: PayloadAction<{ reference: string; id: string }[]>
		) => {
			state.activeReferences = action.payload;
		},
		resetActiveReferences: (state) => {
			state.activeReferences = [];
			state.activeComplianceCardID = null;
			state.activeComplianceSectionID = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchComplianceMatrix.pending, (state) => {
				state.loading = true;
			})
			.addCase(
				fetchComplianceMatrix.fulfilled,
				(state, action: PayloadAction<ComplianceMatrix>) => {
					state.complianceMatrix = action.payload;
					state.loading = false;
					state.error = null;
					state.sectionsRules = mapRulesBySection(action.payload);
				}
			)
			.addCase(fetchComplianceMatrix.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
			})
			.addCase(updateMatrix.pending, (state) => {
				state.error = null;
			})
			.addCase(updateMatrix.fulfilled, (state) => {
				state.error = null;
			})
			.addCase(updateMatrix.rejected, (state, action) => {
				state.error = action.payload as string;
			})
			.addCase(runAndFetchComplianceMatrix.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(runAndFetchComplianceMatrix.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(runAndFetchComplianceMatrix.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
			})
			.addCase(fetchSectionRules.pending, (state) => {
				state.error = null;
			})

			.addCase(fetchSectionRules.rejected, (state, action) => {
				state.error = action.payload as string;
			})
			.addCase(fetchSectionRules.fulfilled, (state, action) => {
				const { sectionId, rules } = action.payload;
				if (rules.length > 0) {
					state.sectionsRules[sectionId] = rules;
					rules?.forEach((rule) => {
						const section = rule.sections[0];
						if (section) {
							const sectionReferences =
								section.sectionCompliance.reference?.split(':');
							sectionReferences?.forEach((reference) => {
								const trimmedReference = reference.trim();
								if (trimmedReference === '') return;

								// Ensure the array is initialized
								if (!state.references[section.id]) {
									state.references[section.id] = [];
								}
								state.references[section.id].push({
									reference: trimmedReference,
									id: `${rule.id}-${section.id}`,
								});
							});
						}
					});
				}
			})
			.addCase(pollComplianceMatrix.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(pollComplianceMatrix.fulfilled, (state, action) => {
				state.complianceMatrix = action.payload;
				state.loading = false;
				state.error = null;
				state.sectionsRules = mapRulesBySection(action.payload);
			})
			.addCase(pollComplianceMatrix.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
			});
	},
});

export const {
	resetMatrix,
	clearReferences,
	setHighlightedCard,
	setReferences,
	setComplianceLoading,
	resetActiveReferences,
} = complianceMatrixSlice.actions;
export default complianceMatrixSlice.reducer;
