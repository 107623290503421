import { manualSolicitation } from '@/lib/types/apiTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Step {
	id: number;
	name: string;
	status: 'completed' | 'current' | 'disabled';
	isValid: boolean;
}

interface unsolicitedState {
	steps: Step[];
	currentStepIndex: number;
	formData: {
		[key: string]: any;
	};
	unsolicitation: manualSolicitation | null;
	additionalFiles: File[];
	journeyType: 'un-solicited' | 'questionnaire';
	questions: Record<string, string>;
}

const initialState: unsolicitedState = {
	steps: [
		{ id: 0, name: 'Basic Information', status: 'current', isValid: false },
		{
			id: 1,
			name: 'Relevant documents or links',
			status: 'disabled',
			isValid: false,
		},
		{ id: 2, name: 'Funder priorities', status: 'disabled', isValid: false },
		{ id: 3, name: 'Problem and impact', status: 'disabled', isValid: false },
	],
	currentStepIndex: 0,
	formData: {},
	additionalFiles: [],
	unsolicitation: null,
	journeyType: 'un-solicited',
	questions: {},
};

const unsolicitedState = createSlice({
	name: 'unsolicited',
	initialState,
	reducers: {
		nextStep(state) {
			if (state.currentStepIndex < state.steps.length - 1) {
				state.steps[state.currentStepIndex].status = 'completed';
				state.currentStepIndex++;
				state.steps[state.currentStepIndex].status = 'current';
			}
		},
		prevStep(state) {
			if (state.currentStepIndex > 0) {
				state.steps[state.currentStepIndex].status = 'disabled';
				state.currentStepIndex--;
				state.steps[state.currentStepIndex].status = 'current';
			}
		},
		setCurrentStep(state, action: PayloadAction<number>) {
			state.steps[state.currentStepIndex].status = 'completed';
			state.currentStepIndex = action.payload;
			state.steps[state.currentStepIndex].status = 'current';
		},
		saveStepData(
			state,
			action: PayloadAction<{ key: string; data: any; isValid: boolean }>
		) {
			const { key, data, isValid } = action.payload;
			state.formData[key] = data;
			state.steps[state.currentStepIndex].isValid = isValid;
		},
		resetForm(state) {
			state.currentStepIndex = 0;
			state.steps.forEach((step, index) => {
				step.status = index === 0 ? 'current' : 'disabled';
			});
			state.formData = {};
			state.additionalFiles = [];
			state.unsolicitation = null;
			state.questions = {};
			state.journeyType = 'un-solicited';
		},
		setRelevantFiles(state, action: PayloadAction<File[]>) {
			state.additionalFiles = [...action.payload];
		},

		setUnsolicitation(state, action: PayloadAction<manualSolicitation>) {
			state.unsolicitation = action.payload;
		},
		setJourneyType(
			state,
			action: PayloadAction<'un-solicited' | 'questionnaire'>
		) {
			state.journeyType = action.payload;
		},
		addQuestions(state, action: PayloadAction<Record<string, string>>) {
			const questions = action.payload;
			state.questions = {
				...state.questions,
				...questions, // Merge existing questions with new ones
			};
		},
		addQuestion(state, action: PayloadAction<string>) {
			const question = action.payload;
			state.questions = {
				...state.questions,
				[question]: '',
			};
		},
	},
});

export const {
	nextStep,
	prevStep,
	saveStepData,
	resetForm,
	setCurrentStep,
	setRelevantFiles,
	setUnsolicitation,
	setJourneyType,
	addQuestions,
} = unsolicitedState.actions;
export default unsolicitedState.reducer;
