import { FC } from 'react';
import { Card, CardContent } from '../ui/card';

import SuggestionImage from '../ui/icons/new/SuggestionImage';

interface SuggestionIntroProps {}

const SuggestionIntro: FC<SuggestionIntroProps> = () => {
	return (
		<div>
			<Card className={'shadow-main rounded-xl bg-white'}>
				<CardContent className="px-4 py-6 text-center">
					<h2 className="font-bold dynamic-medium text-secondary">
						Nothing to suggest yet
					</h2>
					<p className="max-w-[300px] mx-auto mt-2 font-medium text-black">
						Start by clicking on any section on the left to start working on
						that section
					</p>
				</CardContent>
			</Card>

			<div className="flex flex-col items-center justify-center max-w-sm gap-8 mx-auto mt-6">
				<p className="text-center text-black dynamic-text">
					<span className="font-bold">Tip</span> Once you write some content in
					a section, press the <span className="font-bold">suggest button</span>{' '}
					and Grant Assistant will generate suggestions from documents attached
					to the design section.
				</p>
				<SuggestionImage />
			</div>
		</div>
	);
};

export default SuggestionIntro;
