import { SVGProps } from 'react';

const Pdf = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<rect width="32" height="32" rx="10" fill="#E3EEFF" />
			<mask
				id="mask0_3257_4367"
				maskUnits="userSpaceOnUse"
				x="4"
				y="4"
				width="25"
				height="25"
			>
				<rect x="4.10742" y="4.19434" width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_3257_4367)">
				<path
					d="M13.1074 16.6943H14.1074V14.6943H15.1074C15.3908 14.6943 15.6283 14.5985 15.8199 14.4068C16.0116 14.2152 16.1074 13.9777 16.1074 13.6943V12.6943C16.1074 12.411 16.0116 12.1735 15.8199 11.9818C15.6283 11.7902 15.3908 11.6943 15.1074 11.6943H13.1074V16.6943ZM14.1074 13.6943V12.6943H15.1074V13.6943H14.1074ZM17.1074 16.6943H19.1074C19.3908 16.6943 19.6283 16.5985 19.8199 16.4068C20.0116 16.2152 20.1074 15.9777 20.1074 15.6943V12.6943C20.1074 12.411 20.0116 12.1735 19.8199 11.9818C19.6283 11.7902 19.3908 11.6943 19.1074 11.6943H17.1074V16.6943ZM18.1074 15.6943V12.6943H19.1074V15.6943H18.1074ZM21.1074 16.6943H22.1074V14.6943H23.1074V13.6943H22.1074V12.6943H23.1074V11.6943H21.1074V16.6943ZM12.1074 22.1943C11.5574 22.1943 11.0866 21.9985 10.6949 21.6068C10.3033 21.2152 10.1074 20.7443 10.1074 20.1943V8.19434C10.1074 7.64434 10.3033 7.1735 10.6949 6.78184C11.0866 6.39017 11.5574 6.19434 12.1074 6.19434H24.1074C24.6574 6.19434 25.1283 6.39017 25.5199 6.78184C25.9116 7.1735 26.1074 7.64434 26.1074 8.19434V20.1943C26.1074 20.7443 25.9116 21.2152 25.5199 21.6068C25.1283 21.9985 24.6574 22.1943 24.1074 22.1943H12.1074ZM12.1074 20.1943H24.1074V8.19434H12.1074V20.1943ZM8.10742 26.1943C7.55742 26.1943 7.08659 25.9985 6.69492 25.6068C6.30326 25.2152 6.10742 24.7443 6.10742 24.1943V10.1943H8.10742V24.1943H22.1074V26.1943H8.10742Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Pdf;
