import styles from './OrganizationForm.module.css';
import { cn } from '@/lib/utils';
import { FC, useCallback, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import * as z from 'zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	ApiResponse,
	Organization,
	OrganizationPayload,
} from '@/lib/types/apiTypes';
import {
	updateOrganization,
	getOrganizationInfoFromProfile,
} from '@/lib/functions/apiCalls';
import {
	handleUnexpectedError,
	ErrorHandle,
	handleResponse,
	handleFileRejections,
} from '@/lib/functions/funcUtils';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import {
	Multiselect,
	SelectItemProp,
} from '@/components/ui/shared/Multiselect/Multiselect';
import FileUpload from '@/components/FileUpload/FileUpload';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import {
	expertise,
	regionsOfWork,
	countriesAndTerritories,
	organizationSize,
	annualRevenue,
} from '@/constants/organizationalData';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import DocumentFilled from '@/components/ui/icons/new/DocumentFilled';
import DocumentsUpload from '@/components/ui/icons/new/DocumentsUpload';
import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';
import TextButton from '../../ui/shared/Button/TextButton/TextButton';
import { PencilIcon } from 'lucide-react';

interface OrganizationFormProps {
	organization: OrganizationPayload;
	organizationId: number;
	setIsLoading: (isLoading: boolean) => void;
	onSubmitSuccess: (organization: Organization) => void;
}

interface FormData {
	name: string;
	websiteUrl: string;
	description: string;
	address: string;
	country: string;
	city: string;
	state: string;
	zip: number | string;
	expertise: SelectItemProp[];
	regionsOfWork: SelectItemProp[];
	countriesAndTerritories: SelectItemProp[];
	areaOfWork: string;
	organizationSize: string;
	annualRevenue: string;
}

const formSchema = z.object({
	name: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
	areaOfWork: z.string().optional(),
	description: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
	expertise: z
		.array(z.object({ value: z.string(), label: z.string() }))
		.min(1, {
			message: 'Please select an option.',
		}),
	regionsOfWork: z
		.array(z.object({ value: z.string(), label: z.string() }))
		.min(1, {
			message: 'Please select an option.',
		}),
	countriesAndTerritories: z
		.array(z.object({ value: z.string(), label: z.string() }))
		.optional(),
	organizationSize: z.string().optional(),
	annualRevenue: z.string().optional(),
	websiteUrl: z.string().optional(),
	address: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
	country: z.string().optional(),
	city: z.string().optional(),
	state: z.string().optional(),
	zip: z.any().optional(),
});

const OrganizationControls = ({
	isEditable,
	disabled,
	viewOrganization,
	editOrganization,
}) => {
	useTour(Page.OrganizationSettings);
	return (
		<div className="inline-flex w-full gap-6 justify-end">
			{isEditable ? (
				<div className="flex gap-4">
					<SecondaryButton type="button" onClick={() => viewOrganization()}>
						CANCEL
					</SecondaryButton>
					<PrimaryButton type="submit" disabled={disabled}>
						SAVE
					</PrimaryButton>
				</div>
			) : (
				<TextButton type="button" onClick={() => editOrganization()}>
					<PencilIcon size={16} />
					EDIT THIS Organization
				</TextButton>
			)}
		</div>
	);
};

const OrganizationForm: FC<OrganizationFormProps> = ({
	organization,
	organizationId,
	setIsLoading,
	onSubmitSuccess,
}) => {
	const dispatch = useAppDispatch();
	const [organizationDocument, setOrganizationDocument] = useState<File | null>(
		null
	);
	const [savedOrganzationState, setSavedOrganzationState] =
		useState<OrganizationPayload>();

	const [isEditable, setIsEditable] = useState(false);

	const editOrganization = () => {
		setIsEditable(true);
	};

	const viewOrganization = () => {
		setIsEditable(false);
		setOrganizationDocument(null);
		if (savedOrganzationState) {
			setFormValues(savedOrganzationState);
		}
	};

	const form = useForm<FormData>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: '',
			description: '',
			areaOfWork: '',
			expertise: [],
			regionsOfWork: [],
			countriesAndTerritories: [],
			organizationSize: '',
			websiteUrl: '',
			address: '',
			country: '',
			city: '',
			state: '',
			zip: '',
			annualRevenue: '',
		},
	});

	const setOrganizationFormValues = useCallback(
		(organization: OrganizationPayload) => {
			form.setValue('name', organization.name);
			form.setValue('description', organization.description || '');
			form.setValue(
				'expertise',
				organization.expertise?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue(
				'regionsOfWork',
				organization.regionsOfWork?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue(
				'countriesAndTerritories',
				organization.countriesAndTerritories?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue('areaOfWork', organization.areaOfWork || '');
			form.setValue('organizationSize', organization.organizationSize || '');
			form.setValue('websiteUrl', organization?.websiteUrl || '');
			form.setValue('address', organization?.address || '');
			form.setValue('country', organization?.country || '');
			form.setValue('city', organization?.city || '');
			form.setValue('state', organization?.state || '');
			form.setValue('zip', organization?.zip || '');
			form.setValue('annualRevenue', organization?.annualRevenue || '');
		},
		[form]
	);

	const setFormValues = useCallback(
		(organization: OrganizationPayload) => {
			setOrganizationFormValues(organization);
		},
		[form]
	);

	useEffect(() => {
		if (organization) {
			setOrganizationFormValues(organization);
			setSavedOrganzationState(organization);
		}
	}, [organization]);

	const formatOrganizationPayload = (
		formData: FormData
	): OrganizationPayload => {
		return {
			name: formData.name,
			description: formData.description || null,
			expertise: formData.expertise.map((item) => item.value) || [],
			regionsOfWork: formData.regionsOfWork.map((item) => item.value) || [],
			countriesAndTerritories:
				formData.countriesAndTerritories.map((item) => item.value) || [],
			areaOfWork: formData.areaOfWork || null,
			organizationSize: formData.organizationSize || null,
			websiteUrl: formData.websiteUrl || null,
			address: formData.address || null,
			country: formData.country || null,
			city: formData.city || null,
			state: formData.state || null,
			zip:
				typeof formData.zip === 'number'
					? formData.zip
					: parseInt(formData.zip) || null,
			annualRevenue: formData.annualRevenue || null,
		};
	};

	const onSubmit = async (formData: FormData) => {
		try {
			if (organizationId) {
				setIsLoading(true);
				const organizationPayload: OrganizationPayload =
					formatOrganizationPayload(formData);
				const response = await updateOrganization(
					organizationId,
					organizationPayload
				);
				handleResponse(
					response,
					`Organization information has been updated successfully.`
				);
				const updatedOrganizaiton = response.data as Organization;
				onSubmitSuccess(updatedOrganizaiton);
				setIsEditable(false);
				setIsLoading(false);
			}
		} catch (error: unknown) {
			if (!(error as any).status) {
				handleUnexpectedError();
			} else {
				ErrorHandle(dispatch, error);
			}
			setIsLoading(false);
		}
	};

	const handleFiles = useCallback(async (files: File[]) => {
		if (!files.length) return;
		setOrganizationDocument(files[0]);
	}, []);

	const fetchData = useCallback(
		async (organizationDocument) => {
			try {
				if (!organizationDocument) return;
				setIsLoading(true);
				const response: ApiResponse =
					await getOrganizationInfoFromProfile(organizationDocument);
				handleResponse(response);
				const organization = (response.data as any)
					.autoFetchResults as OrganizationPayload;
				setOrganizationFormValues(organization);
				setIsLoading(false);
				setOrganizationDocument(null);
			} catch (error: unknown) {
				ErrorHandle(dispatch, error);
				setIsLoading(false);
			}
		},
		[dispatch, setOrganizationFormValues]
	);

	const designationFormValue = useWatch({
		control: form.control,
		name: 'description',
	});
	const areOfWorkFormValue = useWatch({
		control: form.control,
		name: 'areaOfWork',
	});

	return (
		<Form {...form}>
			<form
				className="flex flex-col pb-2 gap-4"
				onSubmit={form.handleSubmit(onSubmit)}
			>
				<div className={cn('flex flex-col', isEditable ? 'gap-4' : '')}>
					<div className="relative flex items-start justify-between">
						{isEditable ? (
							<div
								className={cn(
									'flex flex-col w-full font-normal text-black dynamic-text self-end'
								)}
							>
								Upload your organization's profile to autofill your Information
								or Enter data manually.
							</div>
						) : (
							<div
								className={cn(
									'flex flex-col w-full font-bold text-black dynamic-medium self-center',
									isEditable ? 'hidden' : ''
								)}
							>
								{organization.name}
							</div>
						)}
						<OrganizationControls
							isEditable={isEditable}
							disabled={
								designationFormValue?.length > 1200 ||
								areOfWorkFormValue?.length > 250
							}
							viewOrganization={viewOrganization}
							editOrganization={editOrganization}
						/>
					</div>
					<div hidden={!isEditable}>
						<div className="flex w-full">
							{organizationDocument ? (
								<div
									className={
										'flex flex-col h-52 gap-4 p-4 rounded-xl border border-[#D3DDE2] text-center w-full justify-center'
									}
								>
									<div>
										<div className="flex items-center justify-center pb-2">
											<DocumentFilled />
										</div>
										<h4 className="font-bold text-heading5 xl:text-heading4 text-primary">
											{organizationDocument?.name || 'Organization Profile'}
										</h4>
									</div>
									<div className="flex justify-center gap-4 xl:gap-6">
										<PrimaryButton
											type="button"
											onClick={() => fetchData(organizationDocument)}
										>
											Fetch Data
										</PrimaryButton>
										<FileUpload
											onDropAccepted={handleFiles}
											type="custom"
											customLayout={
												<SecondaryButton type="button">
													Replace Document
												</SecondaryButton>
											}
											onDropRejected={(fileRejections) =>
												handleFileRejections({
													fileRejections: fileRejections,
												})
											}
										/>
									</div>
								</div>
							) : (
								<FileUpload
									onDropAccepted={handleFiles}
									type="default"
									icon={<DocumentsUpload />}
									title="UPLOAD ORGANIZATION PROFILE"
									infoText="Upload your organization`s profile"
									dropText="Drop the profile here ..."
									className="w-full h-52"
									onDropRejected={(fileRejections) =>
										handleFileRejections({ fileRejections: fileRejections })
									}
								/>
							)}
						</div>
					</div>
					<div className="grid grid-cols-12 gap-6 w-full pt-2 pb-6 border-b border-b-[#D6D6D9]">
						<div className="col-span-3">
							<div className="flex flex-col justify-between gap-4">
								<FormField
									control={form.control}
									name="name"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>Organization Name*</Label>
												<FormControl>
													<Input
														className={cn(
															'bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
															!isEditable ? 'inactive' : ''
														)}
														disabled={!isEditable}
														placeholder="Enter your organization name"
														{...field}
													></Input>
												</FormControl>
												<FormMessage hidden={!isEditable} />
											</FormItem>
										);
									}}
								/>
								<FormField
									control={form.control}
									name="websiteUrl"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>Website</Label>
												<FormControl>
													<Input
														className={cn(
															'bg-white border-[#D3DDE2] h-12 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
															!isEditable ? 'inactive' : ''
														)}
														disabled={!isEditable}
														placeholder="Enter your website"
														{...field}
													></Input>
												</FormControl>
											</FormItem>
										);
									}}
								/>
								<FormField
									control={form.control}
									name="areaOfWork"
									render={({ field }) => {
										return (
											<FormItem>
												<div className="flex justify-between">
													<Label>Area of work</Label>
													<p
														className={cn(
															'text-base font-medium leading-normal',
															field?.value?.length > 250
																? 'text-[#FF82A0]'
																: 'text-black opacity-50'
														)}
													>
														{field?.value?.length || 0}/250 characters
													</p>
												</div>
												<FormControl>
													<Input
														className={cn(
															'bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
															!isEditable ? 'inactive' : ''
														)}
														disabled={!isEditable}
														placeholder="Enter your area of work"
														{...field}
													></Input>
												</FormControl>
												<FormMessage hidden={!isEditable} />
											</FormItem>
										);
									}}
								/>
							</div>
						</div>
						<div className={cn('grid col-span-9')}>
							<FormField
								control={form.control}
								name="description"
								render={({ field }) => {
									return (
										<FormItem>
											<div className="flex justify-between">
												<Label>Describe your Organization*</Label>
												<p
													className={cn(
														'text-base font-medium leading-normal',
														field?.value?.length > 1200
															? 'text-[#FF82A0]'
															: 'text-black opacity-50'
													)}
												>
													{field?.value?.length || 0}/1200 characters
												</p>
											</div>
											<FormControl>
												<Textarea
													{...field}
													disabled={!isEditable}
													className={cn(
														'bg-white border-[#D3DDE2] rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] h-[225px] resize-none',
														!isEditable ? 'inactive' : ''
													)}
													placeholder="Organization Overview"
												></Textarea>
											</FormControl>
											<FormMessage hidden={!isEditable} />
										</FormItem>
									);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="grid gap-4">
					<div className="grid w-full grid-cols-10 gap-6">
						<div className="col-span-4">
							<FormField
								control={form.control}
								name="address"
								render={({ field }) => {
									return (
										<FormItem>
											<Label>Address*</Label>
											<FormControl>
												<Input
													className={cn(
														'bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
														!isEditable ? 'inactive' : ''
													)}
													disabled={!isEditable}
													placeholder="Enter your address"
													{...field}
												></Input>
											</FormControl>
											<FormMessage hidden={!isEditable} />
										</FormItem>
									);
								}}
							/>
						</div>
						<div className="col-span-3">
							<FormField
								control={form.control}
								name="country"
								render={({ field }) => {
									return (
										<FormItem>
											<Label>Country</Label>
											<FormControl>
												<Input
													className={cn(
														'bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
														!isEditable ? 'inactive' : ''
													)}
													disabled={!isEditable}
													placeholder="Enter your country"
													{...field}
												></Input>
											</FormControl>
										</FormItem>
									);
								}}
							/>
						</div>
						<div className="col-span-3">
							<FormField
								control={form.control}
								name="city"
								render={({ field }) => {
									return (
										<FormItem>
											<Label>City</Label>
											<FormControl>
												<Input
													className={cn(
														'bg-white border-[#D3DDE2] h-12 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
														!isEditable ? 'inactive' : ''
													)}
													disabled={!isEditable}
													placeholder="Enter your city"
													{...field}
												></Input>
											</FormControl>
										</FormItem>
									);
								}}
							/>
						</div>
					</div>
					<div className="grid w-full grid-cols-12 gap-6">
						<div className="col-span-3">
							<FormField
								control={form.control}
								name="state"
								render={({ field }) => {
									return (
										<FormItem>
											<Label>State</Label>
											<FormControl>
												<Input
													className={cn(
														'bg-white border-[#D3DDE2] h-12 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
														!isEditable ? 'inactive' : ''
													)}
													disabled={!isEditable}
													placeholder="Enter your state"
													{...field}
												></Input>
											</FormControl>
										</FormItem>
									);
								}}
							/>
						</div>
						<div className="col-span-3">
							<FormField
								control={form.control}
								name="zip"
								render={({ field }) => {
									return (
										<FormItem>
											<Label>Zip</Label>
											<FormControl>
												<Input
													className={cn(
														styles.number__input,
														'bg-white border-[#D3DDE2] h-12 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
														!isEditable ? 'inactive' : ''
													)}
													type="number"
													disabled={!isEditable}
													placeholder="Enter your zip code"
													{...field}
												></Input>
											</FormControl>
										</FormItem>
									);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="grid w-full grid-cols-12 gap-6">
					<div className="col-span-4">
						<FormField
							control={form.control}
							name="expertise"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Expertise*</Label>
										<FormControl>
											<Multiselect
												{...field}
												disabled={!isEditable}
												title="Select expertise"
												hasChildren={true}
												options={expertise}
												onSelectedValuesChange={field.onChange}
											></Multiselect>
										</FormControl>
										<FormMessage hidden={!isEditable} />
									</FormItem>
								);
							}}
						/>
					</div>
					<div className="col-span-4">
						<FormField
							control={form.control}
							name="regionsOfWork"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Regions*</Label>
										<FormControl>
											<Multiselect
												{...field}
												disabled={!isEditable}
												title="Select regions"
												options={regionsOfWork}
												onSelectedValuesChange={field.onChange}
											></Multiselect>
										</FormControl>
										<FormMessage hidden={!isEditable} />
									</FormItem>
								);
							}}
						/>
					</div>
					<div className="col-span-4">
						<FormField
							control={form.control}
							name="countriesAndTerritories"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Countries and territories</Label>
										<FormControl>
											<Multiselect
												{...field}
												disabled={!isEditable}
												title="Select countries and territories"
												options={countriesAndTerritories}
												onSelectedValuesChange={field.onChange}
											></Multiselect>
										</FormControl>
									</FormItem>
								);
							}}
						/>
					</div>
				</div>
				<div className="grid w-full grid-cols-12 gap-6">
					<div className="col-span-4">
						<FormField
							control={form.control}
							name="organizationSize"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Organization Size</Label>
										<FormControl>
											<Select
												disabled={!isEditable}
												onValueChange={field.onChange}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className={cn(
															'h-14 rounded-2xl text-sm xl:text-base border border-[#D3DDE2] bg-white text-black px-4 py-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
															!isEditable ? 'inactive' : ''
														)}
													>
														<SelectValue placeholder="Select organization size" />
													</SelectTrigger>
												</FormControl>
												<SelectContent className="py-2 bg-white rounded-2xl">
													{organizationSize.map((item, index) => {
														return (
															<SelectItem
																className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
																key={index}
																value={item.value}
															>
																{item.label}
															</SelectItem>
														);
													})}
												</SelectContent>
											</Select>
										</FormControl>
									</FormItem>
								);
							}}
						/>
					</div>
					<div className="col-span-4">
						<FormField
							control={form.control}
							name="annualRevenue"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Annual Revenue</Label>
										<FormControl>
											<Select
												disabled={!isEditable}
												onValueChange={field.onChange}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className={cn(
															'h-14 rounded-2xl text-sm xl:text-base border border-[#D3DDE2] bg-white text-black px-4 py-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]',
															!isEditable ? 'inactive' : ''
														)}
													>
														<SelectValue placeholder="Select annual revenue" />
													</SelectTrigger>
												</FormControl>
												<SelectContent className="py-2 bg-white rounded-2xl">
													{annualRevenue.map((item, index) => {
														return (
															<SelectItem
																className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
																key={index}
																value={item.value}
															>
																{item.label}
															</SelectItem>
														);
													})}
												</SelectContent>
											</Select>
										</FormControl>
									</FormItem>
								);
							}}
						/>
					</div>
				</div>
			</form>
		</Form>
	);
};

export default OrganizationForm;
