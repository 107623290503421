import Document from '@/components/ui/icons/onboard/Document';
import Home from '@/components/ui/icons/onboard/Home';
import Proposal from '@/components/ui/icons/onboard/Proposal';
import Settings from '@/components/ui/icons/onboard/Settings';
import Template from '@/components/ui/icons/onboard/Template';
import User from '@/components/ui/icons/onboard/User';
import { Step } from 'intro.js-react';
export const DashboardSteps: Step[] = [
	{
		element: '.quick-start',
		title: 'Quick start',
		intro: (
			<div>
				<p className="text-black">
					Welcome to Grant Assistant! I'm here to help you get started. You can
					access assistance on any page by clicking the question icon at the
					bottom left of the screen.
				</p>
				<p className="mt-2 text-black">
					Click <span className="font-bold">Next</span> to continue.
				</p>
			</div>
		),
		position: 'bottom',
	},
	{
		element: '.help-button',
		title: 'Walkthrough',
		intro: 'You can access the walkthrough of any page by clicking this icon.',
		position: 'right',
	},
	{
		element: '.top-left-menu',
		title: 'Navigation',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p className="mb-2">
					You can quickly navigate between various sections of the platform
					here.
				</p>
				<p className="flex items-center gap-3 leading-4">
					<Home />
					You are currently in the home page.
				</p>
				<p className="flex items-start gap-3 mt-1 leading-4">
					<Proposal className="shrink-0" />
					The proposal button takes you to a list of all your proposals.
				</p>
				<p className="flex items-start gap-3 mt-1 leading-4">
					<Document />
					The document button takes you to your document repository.
				</p>
				<p className="flex items-start gap-3 mt-1 leading-4">
					<Template className="shrink-0" />
					The template button takes you to the template library and template
					builder tool. You must have Template Builder access to view the
					section. This{' '}
					<a
						href="https://vimeo.com/950661002/618cf5763d"
						target="_blank"
						rel="noreferrer"
						className="contents text-primary bold"
					>
						video
					</a>{' '}
					will walk you through Template Builder, teaching you how to set up a
					custom template, fulfilling your proposal requirements.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.bottom-left-menu',
		title: 'Account information',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p className="flex items-start gap-3 leading-4">
					<Settings className="shrink-0" />
					If you are an admin, you can edit your organization's information by
					clicking on the gear button.
				</p>
				<p className="flex items-start gap-3 leading-4">
					<User className="shrink-0" />
					You can access your account information by clicking on the avatar
					icon.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.solicitation-button',
		title: 'Respond to a new solicitation proposal',
		intro:
			'If you have a request for proposal (RFP) document, click here to get started.',
		position: 'bottom',
	},
	{
		element: '.unsolicitation-button',
		title: 'Start an unsolicited proposal',
		intro:
			'By clicking here, you can start an unsolicited proposal or a concept note.',
		position: 'bottom',
	},
	{
		element: '.questionnaire-button',
		title: 'Answer to a Questionnaire based proposal ',
		intro:
			'Click here if you want to respond to questions from your doner, or a questionnaire based proposal.',
		position: 'bottom',
	},
	{
		element: '.video-container',
		title: 'Intro video',
		intro: 'To watch the video tutorial, click on the video play button. ',
		position: 'left',
	},
	{
		element: '.proposals-overview',
		title: 'Overview',
		intro: 'You can view your overview of all your proposals here. ',
		position: 'left',
	},
	{
		element: '.proposals-wrapper',
		title: 'Latest proposals',
		intro:
			'Once you have open proposals, you can jump back to them quickly from this space. ',
		position: 'left',
	},
	{
		element: '.proposals-resources',
		title: 'Resources',
		intro:
			'You can access helpful resources like our introduction video to help you get started right here. You can also press the chat icon on the bottom right of the screen to connect directly to a member of our team.',
		position: 'top-right-aligned',
	},
];
