import { Input } from '../ui/input';
import { useForm } from 'react-hook-form';
import { invitedUserInfoSubmit } from '@/lib/functions/apiCalls';
import { useNavigate } from 'react-router';
import {
	ApiResponse,
	ErrorResponse,
	InvitedUserInfoPayload,
	LoginResponse,
} from '@/lib/types/apiTypes';
import {
	handleApiError,
	handleGeneralError,
	handleResponse,
	handleUnexpectedError,
	handleLoginResponse,
} from '@/lib/functions/funcUtils';
import { Label } from '../ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '../ui/form';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { openDialog } from '@/redux/slices/pageSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';

interface FormData {
	name: string;
	password: string;
	confirmPassword: string;
	designation: string | undefined;
}

const formSchema = z
	.object({
		name: z.string().min(2, {
			message: 'Please enter correct information.',
		}),
		password: z.string().min(2, {
			message: 'Please enter correct information.',
		}),
		confirmPassword: z.string(),
		designation: z.string().optional(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: ['confirmPassword'],
	});

const InvitedUserInfoForm = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const dispatch = useAppDispatch();

	const trialBannerDelayDate = useSelector(
		(root: RootState) => root.auth.trialBannerDelayDate
	);

	if (token === null) {
		navigate('/login');
	}

	const form = useForm<FormData>({
		resolver: zodResolver(formSchema),
		values: {
			name: '',
			password: '',
			confirmPassword: '',
			designation: '',
		},
	});

	const formatInvitedUserInfoPayload = (
		formData: FormData
	): InvitedUserInfoPayload => {
		return {
			name: formData.name,
			password: formData.password,
			designation: formData.designation || null,
		};
	};

	const onSubmit = async (formData: FormData) => {
		try {
			if (token) {
				dispatch(processStart());
				const payload = formatInvitedUserInfoPayload(formData);
				const response: ApiResponse = await invitedUserInfoSubmit(
					token,
					payload
				);

				handleResponse(response);
				const loginResponse = (response.data as any).loginInfo as LoginResponse;
				if (loginResponse.user.organization.plan.type === 'trial') {
					dispatch(
						openDialog({
							type: 'trialTerms',
							loginResponse: {
								accessToken: loginResponse.accessToken,
								user: loginResponse.user,
							},
						})
					);
				} else {
					handleLoginResponse(
						dispatch,
						navigate,
						loginResponse,
						trialBannerDelayDate
					);
				}
				dispatch(processSuccess());
			}
		} catch (error: unknown) {
			dispatch(processSuccess());
			if (error instanceof Error) {
				handleGeneralError(error);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
			} else {
				handleUnexpectedError();
			}
		}
	};

	return (
		<div className="flex flex-col gap-6 px-8">
			<Form {...form}>
				<form
					className="flex flex-col gap-5 w-[500px]"
					onSubmit={form.handleSubmit(onSubmit)}
				>
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<Label>Name *</Label>
									<FormControl>
										<Input
											className="bg-white border-[#D3DDE2] h-14 p-4 text-sm xl:text-base rounded-2xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
											placeholder="Enter name"
											{...field}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<Label>Password *</Label>
									<FormControl>
										<Input
											className="bg-white border-[#D3DDE2] h-14 p-4 text-sm xl:text-base rounded-2xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
											type="password"
											placeholder="Enter password"
											autoComplete="true"
											{...field}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
					<FormField
						control={form.control}
						name="confirmPassword"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<Label>Confirm Password *</Label>
									<FormControl>
										<Input
											className="bg-white border-[#D3DDE2] h-14 p-4 text-sm xl:text-base rounded-2xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
											type="password"
											placeholder="Re-enter password"
											autoComplete="true"
											{...field}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
					<FormField
						control={form.control}
						name="designation"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<Label>Job Title</Label>
									<FormControl>
										<Input
											className="bg-white border-[#D3DDE2] h-14 p-4 text-sm xl:text-base rounded-2xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
											placeholder="Enter job title"
											{...field}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
					<div className="flex justify-center">
						<PrimaryButton type="submit" className="w-[242px]">
							JOIN ORGANIZATION
						</PrimaryButton>
					</div>
				</form>
			</Form>
		</div>
	);
};

export default InvitedUserInfoForm;
