import { ColumnDef } from '@tanstack/react-table';
import { AuditLogsTableProps } from '@/lib/types/constants';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { ArrowUpDown } from 'lucide-react';
import { convertTimestamp } from '@/lib/functions/funcUtils';

export const columns: ColumnDef<AuditLogsTableProps>[] = [
	{
		accessorKey: 'email',
		header: ({ column }) => {
			return (
				<h5
					key={column.id}
					className="p-0 text-black bg-transparent text-heading6 xl:text-heading5"
				>
					Email
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<p className="text-base text-black">{row?.original?.email || '-'}</p>
			);
		},
	},
	{
		accessorKey: 'status',
		header: ({ column }) => {
			return (
				<h5
					key={column.id}
					className="p-0 text-black bg-transparent text-heading6 xl:text-heading5"
				>
					Status
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<>
					{row?.original?.status ? (
						<div className="flex justify-center">
							<Badge className="items-center h-6 p-2 text-white w-max bg-secondary dynamic-small">
								{row.original.status}
							</Badge>
						</div>
					) : (
						<p className="text-base text-center text-black">-</p>
					)}
				</>
			);
		},
	},
	{
		accessorKey: 'event',
		header: ({ column }) => {
			return (
				<h5
					key={column.id}
					className="p-0 text-black bg-transparent text-heading6 xl:text-heading5"
				>
					Events
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<p className="text-base text-black">{row?.original?.event || '-'}</p>
			);
		},
	},
	{
		accessorKey: 'ipAddress',
		header: ({ column }) => {
			return (
				<h5
					key={column.id}
					className="p-0 text-black bg-transparent text-heading6 xl:text-heading5"
				>
					IP Address
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<p className="text-base text-black">
					{row?.original?.ipAddress || '-'}
				</p>
			);
		},
	},
	{
		accessorKey: 'userAgent',
		header: ({ column }) => {
			return (
				<h5
					key={column.id}
					className="p-0 text-black bg-transparent text-heading6 xl:text-heading5"
				>
					User Agent
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<p className="text-base text-black">
					{row?.original?.userAgent || '-'}
				</p>
			);
		},
	},
	{
		accessorKey: 'category',
		header: ({ column }) => {
			return (
				<h5
					key={column.id}
					className="p-0 text-black bg-transparent text-heading6 xl:text-heading5"
				>
					Category
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<>
					{row?.original?.category ? (
						<div className="flex justify-center w-auto">
							<Badge className="items-center h-6 p-2 text-white w-max bg-primary dynamic-small">
								{row.original.category}
							</Badge>
						</div>
					) : (
						<p className="text-base text-center text-black">-</p>
					)}
				</>
			);
		},
	},
	{
		accessorKey: 'createdAt',
		header: ({ column }) => {
			return (
				<Button
					className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
					key={column.id}
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Created On
					<ArrowUpDown className="w-4 h-4 ml-2" />
				</Button>
			);
		},
		cell: ({ row }) => {
			return (
				<div className="flex items-center gap-4">
					<p className="text-base text-black">
						{row?.original?.createdAt
							? convertTimestamp(row.original.createdAt)
							: '-'}
					</p>
				</div>
			);
		},
	},
];
