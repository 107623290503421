import { FC } from 'react';
import styles from './Signup.module.css';
import LogoLarge from '@/components/ui/icons/LogoLarge';
import { cn } from '@/lib/utils';
import InvitedUserInfoForm from '@/components/InvitedUserInfoForm/InvitedUserInfoForm';
import { Navigate } from 'react-router';
import useUser from '@/lib/hooks/useUser';

interface SignupProps {}

const Signup: FC<SignupProps> = () => {
	const user = useUser();
	if (user) {
		return <Navigate to="/" replace />;
	}
	return (
		<div
			className={cn(
				styles.container,
				'flex flex-col relative h-screen w-full pt-10 overflow-y-auto hidden-scroll'
			)}
		>
			<div className={cn(styles.white_box, 'm-auto gap-6 shadow-main')}>
				<div className="flex flex-col items-center justify-center gap-6">
					<LogoLarge />
					<div className="flex flex-col items-center justify-center gap-2">
						<h1 className="font-bold text-black dynamic-xl-large">
							Invitation Accept
						</h1>
						<p className="text-xl text-black">
							Thankyou for joining. Enter your required information.
						</p>
					</div>
					<InvitedUserInfoForm />
				</div>
			</div>
			<div className={styles.pink_box}>
				<div className="flex items-center justify-center w-full px-12 py-4 text-base font-normal text-white xl:text-lg 2xl:text-xl">
					<p>© 2024 GRANT ASSISTANT. All rights reserved.</p>
				</div>
			</div>
		</div>
	);
};

export default Signup;
