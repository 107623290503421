const Chat = ({ className = 'h-8' }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="31"
			height="25"
			viewBox="0 0 31 25"
			fill="none"
		>
			<path
				d="M18.8943 21.6458L18.9561 21.7021C20.8196 23.3739 23.1539 24.432 25.6413 24.7325C25.687 24.7394 25.7337 24.7356 25.7777 24.7216C25.8218 24.7075 25.8619 24.6834 25.8951 24.6513C25.9267 24.62 25.9506 24.5819 25.9652 24.54C25.9797 24.498 25.9845 24.4533 25.979 24.4092C25.9736 24.3651 25.9581 24.3229 25.9337 24.2857C25.9094 24.2485 25.8769 24.2173 25.8387 24.1946C24.5683 23.4652 23.5109 22.4172 22.771 21.1545L22.6474 20.938L22.8816 20.8601C27.3081 19.386 30.058 16.2387 30.058 12.6455C30.058 10.0188 28.4781 7.51759 25.7205 5.78484L25.6597 5.72532C23.3326 2.19381 18.6937 0 13.5548 0C6.08122 0 0 4.64196 0 10.35C0 13.9984 2.40734 17.295 6.438 19.1674L6.695 19.2875L6.49651 19.4888C6.00962 19.9888 5.45917 20.4229 4.85914 20.78C4.55697 20.961 4.3225 21.2358 4.19166 21.5624C4.06082 21.889 4.04077 22.2494 4.13461 22.5884C4.22845 22.9275 4.43102 23.2265 4.71128 23.4398C4.99155 23.653 5.33411 23.7687 5.68653 23.7693C5.76345 23.7694 5.84026 23.764 5.91639 23.753C8.38014 23.4295 10.7113 22.4505 12.6656 20.9185L12.7502 20.8525L12.8521 20.886C14.7703 21.5157 16.7909 21.7765 18.8065 21.6545L18.8943 21.6458ZM5.1313 22.3266L5.99879 21.8363C7.2715 21.0866 8.27164 19.9516 8.85397 18.5959C4.32775 17.1976 1.41836 13.9843 1.41836 10.3608C1.41836 5.43201 6.86523 1.42107 13.5635 1.42107C20.2617 1.42107 25.7086 5.43201 25.7086 10.3608C25.7086 15.2895 20.2617 19.3037 13.5635 19.3037C13.21 19.3037 12.837 19.2907 12.4498 19.2626C10.7348 20.9288 8.50644 21.9683 6.12567 22.2129L5.1313 22.3266Z"
				fill="#5D9BFD"
			/>
		</svg>
	);
};

export default Chat;
