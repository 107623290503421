import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { RootState } from '@/redux/store/store';
import { OrganizationProfilePayload } from '@/lib/types/apiTypes';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import DialogLoader from '@/components/Loader/DialogLoader/DailogLoader';
import OrganizationProfileSetupForm from '@/components/Organization/OrganizationProfileSetupForm/OrganizationProfileSetupForm';

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: { display: false },
		tooltip: { enabled: false },
	},
};

const OrganizationProfile = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'organizationalProfile'
	);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [organizationProfile, setOrganizationProfile] =
		useState<OrganizationProfilePayload>();
	const [setupStep, setSetupStep] = useState(1);

	const stepChartData = {
		datasets: [
			{
				label: 'Step',
				data: [setupStep, 2 - setupStep],
				backgroundColor: ['#E861C9', '#D3DDE2'],
				borderColor: ['#E861C9', '#D3DDE2'],
				borderWidth: 5,
				cutout: 100,
			},
		],
	};

	const clearDialog = () => {
		setOrganizationProfile(undefined);
		setIsLoading(false);
		setSetupStep(1);
	};
	useEffect(() => {
		if (isOpen) {
			clearDialog();
		}
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[950px] shadow-none bg-white"
				setModalOpen={() => clearDialog()}
			>
				<DialogHeader>
					<div className="flex justify-between pb-4 border-b">
						<h1 className="font-bold text-left text-secondary dynamic-xl-large">
							Additional Profile Setup
						</h1>
						<div className="flex items-center gap-4 text-base font-bold text-black pr-10">
							<div>
								Step <span className="text-secondary">{setupStep}</span>/2
							</div>
							<div className="w-8 h-8">
								{stepChartData && (
									<Doughnut
										className="w-8 h-8 pointer-events-none"
										data={stepChartData}
										options={options}
									/>
								)}
							</div>
						</div>
					</div>
				</DialogHeader>
				<OrganizationProfileSetupForm
					setIsLoading={setIsLoading}
					setupStep={setupStep}
					setSetupStep={setSetupStep}
					organizationProfile={organizationProfile}
					setOrganizationProfile={setOrganizationProfile}
				/>
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default OrganizationProfile;
