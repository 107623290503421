const DetachIcon = ({ color = '#5D9BFD', className = 'h-6' }) => {
	return (
		<svg
			className={className}
			width="33"
			height="35"
			viewBox="0 0 43 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.6486 1.64355H32.5325C32.9987 1.64355 33.4458 1.82876 33.7754 2.1584C34.1051 2.48805 34.2903 2.93515 34.2903 3.40134V38.8955C34.2903 39.1264 34.2448 39.3549 34.1565 39.5682C34.0681 39.7814 33.9387 39.9752 33.7754 40.1384C33.6122 40.3017 33.4184 40.4312 33.2052 40.5195C32.9919 40.6078 32.7633 40.6533 32.5325 40.6533H7.04805C6.81722 40.6533 6.58865 40.6078 6.37538 40.5195C6.16212 40.4312 5.96834 40.3017 5.80512 40.1384C5.6419 39.9752 5.51242 39.7814 5.42409 39.5682C5.33575 39.3549 5.29028 39.1264 5.29028 38.8955V8.00348C5.29021 7.77224 5.33576 7.54326 5.42433 7.32965C5.5129 7.11605 5.64274 6.92202 5.80643 6.75867L10.407 2.15811C10.5703 1.99491 10.7641 1.86546 10.9774 1.77717C11.1907 1.68889 11.4194 1.64348 11.6502 1.64355H11.6486Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.73999 8.04115H11.3025C11.5276 8.04115 11.7434 7.95174 11.9025 7.79261C12.0617 7.63348 12.1511 7.41765 12.1511 7.19261V1.6748"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.1716 13H10.0003"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.0002 17L10.0002 17"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M29.0002 21L10.0002 21"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M40.7727 1L2.16147 43.9343"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default DetachIcon;
