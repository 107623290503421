const Ai = ({ className = 'h-12' }) => {
	return (
		<svg
			className={className}
			width="29"
			height="46"
			viewBox="0 0 29 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.0389 37.8772H16.3698C16.1204 37.8772 15.8813 37.7782 15.705 37.6019C15.5287 37.4256 15.4297 37.1864 15.4297 36.9371C15.4297 36.6878 15.5287 36.4487 15.705 36.2724C15.8813 36.0961 16.1204 35.997 16.3698 35.997H18.0389C18.8981 35.9961 19.7218 35.6544 20.3293 35.0469C20.9368 34.4394 21.2785 33.6157 21.2795 32.7565V13.2537C21.2788 12.3944 20.9372 11.5704 20.3297 10.9626C19.7222 10.3548 18.8983 10.013 18.0389 10.012H16.3698C16.1204 10.012 15.8813 9.91297 15.705 9.73666C15.5287 9.56036 15.4297 9.32125 15.4297 9.07192C15.4297 8.8226 15.5287 8.58348 15.705 8.40718C15.8813 8.23088 16.1204 8.13184 16.3698 8.13184H18.0389C19.3974 8.13373 20.6995 8.6747 21.6594 9.63593C22.6193 10.5972 23.1584 11.9001 23.1584 13.2585V32.7565C23.1569 34.1139 22.617 35.4153 21.6573 36.3752C20.6976 37.3352 19.3963 37.8753 18.0389 37.8772Z"
				fill="#5D9BFD"
			/>
			<path
				d="M28.0601 37.9455H26.391C25.0326 37.9436 23.7304 37.4026 22.7705 36.4414C21.8106 35.4801 21.2715 34.1772 21.2715 32.8188V13.316C21.2731 11.9586 21.8129 10.6572 22.7726 9.69729C23.7323 8.73735 25.0336 8.19721 26.391 8.19531H28.0601C28.3095 8.19531 28.5486 8.29436 28.7249 8.47066C28.9012 8.64696 29.0002 8.88607 29.0002 9.1354C29.0002 9.38473 28.9012 9.62384 28.7249 9.80014C28.5486 9.97644 28.3095 10.0755 28.0601 10.0755H26.391C25.5316 10.0764 24.7078 10.4183 24.1002 11.0261C23.4927 11.6339 23.1511 12.4578 23.1505 13.3172V32.8248C23.1511 33.6841 23.4927 34.5081 24.1002 35.1159C24.7078 35.7236 25.5316 36.0655 26.391 36.0665H28.0601C28.3095 36.0665 28.5486 36.1655 28.7249 36.3418C28.9012 36.5181 29.0002 36.7572 29.0002 37.0066C29.0002 37.2559 28.9012 37.495 28.7249 37.6713C28.5486 37.8476 28.3095 37.9455 28.0601 37.9455Z"
				fill="#5D9BFD"
			/>
			<path
				d="M7.93845 14.642C9.88723 14.642 11.3297 15.1292 12.2659 16.1036C13.2212 17.0589 13.6988 18.5205 13.6988 20.4884V27.4811C13.6988 27.9969 13.546 28.4077 13.2403 28.7134C12.9346 29 12.5143 29.1433 11.9793 29.1433C11.4826 29.1433 11.0718 28.9904 10.747 28.6848C10.4413 28.3791 10.2885 27.9778 10.2885 27.4811V26.8506C9.96366 27.5957 9.4478 28.1785 8.74089 28.5988C8.05308 29.0191 7.25064 29.2293 6.33356 29.2293C5.39738 29.2293 4.54717 29.0382 3.78294 28.6561C3.01871 28.274 2.41688 27.7486 1.97745 27.0799C1.53801 26.4112 1.3183 25.666 1.3183 24.8445C1.3183 23.8128 1.57623 23.0008 2.09208 22.4085C2.62704 21.8162 3.4868 21.3864 4.67136 21.1189C5.85592 20.8514 7.48946 20.7176 9.57199 20.7176H10.2885V20.0585C10.2885 19.1223 10.0878 18.4441 9.68662 18.0237C9.2854 17.5843 8.63581 17.3646 7.73784 17.3646C7.18377 17.3646 6.62015 17.4506 6.04698 17.6225C5.4738 17.7754 4.79555 18.0046 4.01221 18.3103C3.51546 18.5587 3.15245 18.6829 2.92318 18.6829C2.57928 18.6829 2.29269 18.5587 2.06342 18.3103C1.85326 18.0619 1.74818 17.7371 1.74818 17.3359C1.74818 17.0111 1.8246 16.7341 1.97745 16.5048C2.1494 16.2565 2.42643 16.0272 2.80855 15.817C3.47725 15.454 4.27014 15.1674 5.18722 14.9573C6.1234 14.7471 7.04048 14.642 7.93845 14.642ZM7.05003 26.65C8.00532 26.65 8.7791 26.3347 9.37138 25.7043C9.98276 25.0547 10.2885 24.2236 10.2885 23.211V22.6091H9.7726C8.49251 22.6091 7.49901 22.6664 6.7921 22.7811C6.08519 22.8957 5.57888 23.0963 5.27319 23.3829C4.9675 23.6695 4.81465 24.0612 4.81465 24.5579C4.81465 25.1693 5.02482 25.6756 5.44514 26.0768C5.88458 26.4589 6.41954 26.65 7.05003 26.65Z"
				fill="#E861C9"
			/>
		</svg>
	);
};

export default Ai;
