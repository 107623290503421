import { Suggestion } from '@/lib/types/apiTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SuggestionState {
	suggestions: Record<number, Suggestion[]>;
}

const initialState: SuggestionState = {
	suggestions: {},
};

// Create the slice
const suggestionSlice = createSlice({
	name: 'suggestion',
	initialState,
	reducers: {
		resetSuggestions(state) {
			state.suggestions = {};
		},
		setSuggestions(
			state,
			action: PayloadAction<{ sectionId: number; suggestions: Suggestion[] }>
		) {
			const { sectionId, suggestions } = action.payload;
			state.suggestions[sectionId] = suggestions;
		},
		removeSuggestionByIndex(
			state,
			action: PayloadAction<{ sectionId: number; index: number }>
		) {
			const { sectionId, index } = action.payload;
			if (state.suggestions[sectionId]) {
				state.suggestions[sectionId].splice(index, 1);
			}
		},
	},
});

// Export actions and reducer
export const { resetSuggestions, setSuggestions, removeSuggestionByIndex } =
	suggestionSlice.actions;

export default suggestionSlice.reducer;
