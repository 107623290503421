import { FC } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { cn } from '@/lib/utils';

interface UserProfileProps {
	name: string;
	profilePicture: string;
	designation: string;
}

const UserProfile: FC<UserProfileProps> = ({
	name,
	profilePicture,
	designation,
}) => {
	return (
		<div className="flex items-center gap-2 xl:gap-3">
			<Avatar className={cn('h-[48px] w-[48px]')}>
				<AvatarImage src={profilePicture as string} alt={name} />
				<AvatarFallback className={cn('text-lg text-white bg-black font-bold')}>
					{name[0]}
				</AvatarFallback>
			</Avatar>
			<div className="flex flex-col justify-center h-full ">
				<h4 className="font-bold text-black text-heading5 xl:text-heading4">
					{name}
				</h4>
				<p className="text-sm text-black">{designation || ''}</p>
			</div>
		</div>
	);
};

export default UserProfile;
