import { FC, useEffect, useRef, useState } from 'react';
import styles from './Idea.module.css';
import { Note } from '@/lib/types/apiTypes';
import {
	PenBoxIcon,
	Trash2Icon,
	SaveIcon,
	Replace,
	ClipboardList,
} from 'lucide-react'; // Import icons
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import {
	createClipboard,
	createNoteOnSpecificSection,
	updateNoteOnSpecificSection,
} from '@/lib/functions/apiCalls';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import {
	fetchProposalById,
	processStart,
	processSuccess,
} from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { ideaCharLimit } from '@/lib/types/constants';
import { cn } from '@/lib/utils';
import useAuth from '@/lib/hooks/useAuth';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import useProposalId from '@/lib/hooks/useProposalId';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { openDialog } from '@/redux/slices/pageSlice';
import Ideas from '@/components/ui/icons/new/Ideas';
import { useQueryClient } from 'react-query';

interface IdeaProps {
	idea?: Note;
	onDelete?: () => void;
	isNew?: boolean;
	newContent?: string;
}

const Idea: FC<IdeaProps> = ({ idea, onDelete, isNew, newContent }) => {
	const [content, setContent] = useState('');
	const user = useAppSelector((root: RootState) => root.auth.user);
	const queryClient = useQueryClient();
	const [charCount, setCharCount] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const proposalId = useProposalId();
	const { activeSection } = useActiveSection();
	const sectionId = activeSection.sectionId;
	const { isAdmin } = useAuth();
	const dispatch = useAppDispatch();
	const ideaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		const initialContent = isNew ? newContent || '' : idea?.content || '';
		setContent(initialContent);
		setCharCount(initialContent.length);
		if (isNew) {
			setIsEditing(true);
		}
	}, [idea, isNew, newContent]);

	useEffect(() => {
		if (isEditing && ideaRef.current) {
			// Focus on the textarea when editing is enabled
			ideaRef.current?.setSelectionRange(
				ideaRef.current?.value.length,
				ideaRef.current?.value.length
			);
			ideaRef.current?.focus();
		}
	}, [isEditing]);

	const handleKeyPress = async (
		e: React.KeyboardEvent<HTMLTextAreaElement>
	) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			// Prevents triggering when Shift+Enter is pressed
			e.preventDefault(); // Prevents a newline from being added to the textarea
			if (content.trim() == '' || charCount > ideaCharLimit) return; // Prevents save if content is empty
			await handleSave();
		}
	};

	const handleDoubleClick = () => {
		if (user?.id === idea?.createdBy) {
			setIsEditing(true);
		} else {
			setIsEditing(false);
		}
	};

	const updateIdea = async () => {
		try {
			if (!idea || !proposalId || !sectionId) return;
			dispatch(processStart());
			const response = await updateNoteOnSpecificSection(
				proposalId,
				sectionId,
				idea.id,
				{ content }
			);

			if (response.statusCode === 200) {
				dispatch(processSuccess());
				dispatch(fetchProposalById(proposalId));
			}
			if (response.statusCode === 403) {
				dispatch(processSuccess());
				ErrorHandle(dispatch, response);
			}
		} catch (error: unknown) {
			dispatch(processSuccess());
			ErrorHandle(dispatch, error);
		}
	};

	const createIdea = async () => {
		try {
			if (!proposalId || !sectionId) return;
			dispatch(processStart());
			const response = await createNoteOnSpecificSection(
				proposalId,
				sectionId,
				{
					content: content,
					type: 'idea',
				}
			);

			if (response.statusCode === 201) {
				dispatch(processSuccess());
				// setIsNewNote(false);
				dispatch(fetchProposalById(proposalId));
			}
		} catch (error: unknown) {
			dispatch(processSuccess());
			ErrorHandle(dispatch, error);
		}
	};

	const handleSave = async () => {
		setIsEditing(false);
		if (isNew) {
			await createIdea();
		} else {
			await updateIdea();
		}
	};

	const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newText = e.target.value;
		setContent(newText);
		setCharCount(newText.length);
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
		const pasteText = e.clipboardData?.getData('text/plain');
		if (pasteText) {
			const newText = content + pasteText;
			setContent(newText);
			setCharCount(newText.length);
			e.preventDefault();
		}
	};

	const handleCreateClipboard = async () => {
		if (!proposalId) return;
		try {
			setIsLoading(true);
			const response = await createClipboard(proposalId, content);

			handleResponse(
				response,
				'Idea has been copied to clipboard successfully.'
			);
			setIsLoading(false);
			queryClient.invalidateQueries('clipboards');
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const isUserCreator = user?.id === idea?.createdBy;

	return (
		<div className={styles.box + ' hover-show-container'}>
			<div className="flex items-center gap-2 mb-2 ">
				<Ideas className="h-5 w-5" />
				<p className="dynamic-medium text-[#6d7d86]">
					Idea from
					<span className="ml-1 font-bold text-secondary">
						{(isNew ? user?.name : idea?.user?.name) || 'Deleted User'}
					</span>
				</p>
			</div>
			{isEditing ? (
				<>
					<textarea
						value={content}
						onChange={handleContentChange}
						placeholder="Add an idea"
						ref={ideaRef}
						className={styles.textarea + ' text-[#6d7d86]'}
						onKeyDown={handleKeyPress}
						onPaste={handlePaste}
					/>
					<div
						className={`flex ${charCount > ideaCharLimit ? 'justify-between' : 'justify-end'} gap-2 mt-2`}
					>
						{charCount > ideaCharLimit && (
							<p className="text-red-500 uppercase dynamic-small">
								Limit exceeded
							</p>
						)}
						<p
							className={cn(
								'text-black text-right dynamic-small',
								charCount > ideaCharLimit ? 'text-red-500' : ''
							)}
						>
							{charCount}/{ideaCharLimit} characters
						</p>
					</div>
				</>
			) : (
				<div
					onDoubleClick={handleDoubleClick}
					className="text-[#6d7d86] dynamic-medium"
				>
					{content}
				</div>
			)}
			<div className={cn(styles.btnContainer)}>
				<div className="flex items-center gap-2 right-6 border-t pt-3 mt-3 border-blackSecondary hover-element">
					{isAdmin && !idea?.user?.name && !isNew ? (
						<button
							className="flex font-medium items-center justify-center w-1/4 text-lightRed gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
							onClick={onDelete}
						>
							<Trash2Icon className="w-4 2x:w-5" color={'var(--light-red)'} />
							Delete
						</button>
					) : isNew || (isEditing && isUserCreator) ? (
						<>
							{charCount <= ideaCharLimit && (
								<button
									className={cn(
										'flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer',
										content.trim() !== ''
											? ''
											: 'opacity-50 pointer-events-none'
									)}
									onClick={handleSave}
								>
									<SaveIcon className="w-4 2x:w-5" color={'var(--primary)'} />
									Save
								</button>
							)}
							{!isNew && (
								<button
									className="flex font-medium items-center justify-center w-1/4 text-lightRed gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
									onClick={onDelete}
								>
									<Trash2Icon
										className="w-4 2x:w-5"
										color={'var(--light-red)'}
									/>
									Delete
								</button>
							)}
						</>
					) : (
						idea && (
							<>
								{isUserCreator && (
									<>
										<button
											className="flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
											onClick={() => setIsEditing(true)}
										>
											<PenBoxIcon
												className="w-4 2x:w-5"
												color={'var(--primary)'}
											/>
											Edit
										</button>
										<button
											className="flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
											onClick={() =>
												dispatch(
													openDialog({
														type: 'moveIdea',
														ideaId: idea.id,
													})
												)
											}
										>
											<Replace
												className="w-4 2xl:w-5"
												color={'var(--primary)'}
											/>
											Move
										</button>
									</>
								)}
								<button
									className="flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
									onClick={() => {
										handleCreateClipboard();
									}}
								>
									<ClipboardList
										className="w-4 2xl:w-5"
										color={'var(--primary)'}
									/>
									Copy
								</button>
								{isUserCreator && (
									<button
										className="flex font-medium items-center justify-center w-1/4 text-lightRed gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
										onClick={onDelete}
									>
										<Trash2Icon
											className="w-4 2xl:w-5"
											color={'var(--light-red)'}
										/>
										Delete
									</button>
								)}
							</>
						)
					)}
				</div>
			</div>
			{isLoading && <LoaderTransparent />}
		</div>
	);
};

export default Idea;
