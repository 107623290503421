import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './QuestionCard.module.css';
import { Question } from '@/lib/types/apiTypes';
import { cn } from '@/lib/utils';
import {
	getToken,
	getWordCount,
	typeWithInterval,
} from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	fetchProposalById,
	processStart,
	processSuccess,
} from '@/redux/slices/proposalSlice';
import { answerSpecificQuestion } from '@/lib/functions/apiCalls';
import customToast from '@/components/CustomToast/CustomToast';
// import { Select, SelectTrigger, SelectValue } from '../ui/select';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import AiIcon from '../ui/icons/AiIcon';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import useResponseHandler from '@/lib/hooks/useResponseHandler';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import { Info } from 'lucide-react';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';

interface QuestionCardProps {
	question: Question;
	selectedNode: string | null;
}

const QuestionCard: FC<QuestionCardProps> = ({ question, selectedNode }) => {
	const [answer, setAnswer] = useState<string>('');
	const answerRef = useRef<HTMLTextAreaElement>(null);
	const [answerStreaming, setAnswerStreaming] = useState(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const token = getToken();
	const [intervalId, setIntervalId] = useState<ReturnType<
		typeof setInterval
	> | null>(null);
	const dispatch = useAppDispatch();
	const answerWordCount = getWordCount(answer);

	useEffect(() => {
		setAnswer(question.answer || '');
		setIsEditing(!question.answer);
	}, [selectedNode, question]);

	const submitAnswer = async (question: Question) => {
		const response = await answerSpecificQuestion(
			Number(proposal?.id),
			question?.id,
			answer
		);
		return response;
	};

	const handleSubmit = async () => {
		try {
			dispatch(processStart());
			const response = await submitAnswer(question);
			if (response) {
				customToast.success({ title: 'Answer submitted successfully' });

				dispatch(processSuccess());
				dispatch(fetchProposalById(Number(proposal?.id)));
				setIsEditing(!isEditing);
			}
		} catch (err) {
			console.error(err);
			dispatch(processSuccess());
		}
	};

	const handleAnswerChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const text = e.target.value;
		setAnswer(text);
		const textWordCount = getWordCount(text);
		if (textWordCount > 300 && textWordCount > answerWordCount + 1) {
			customToast.warning({
				title: 'Content exceeds the permitted word count',
			});
		}
	};

	const cancelAnswerChange = () => {
		setAnswer(question.answer || '');
		setIsEditing(false);
	};

	const [handleSuggestResponse] = useResponseHandler({
		token,
		proposalId: proposal?.id,
		questionId: question.id,
		onInit: () => {
			setAnswerStreaming(true);
			setAnswer('');

			const interval = typeWithInterval(
				answerRef,
				'Hold on tight while I write this response',
				10
			);
			setIntervalId(interval);
		},
		onStart: () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
			setAnswer('');
		},
		onMessage: (message: string) => {
			setAnswer((prev) => prev + message);
			if (answerRef.current) {
				answerRef.current.scrollTop = answerRef.current.scrollHeight;
			}
		},
		onEnd: () => {
			setAnswerStreaming(false);
			setAnswer((prevAnswer) => prevAnswer || '');
		},
		onError: () => {
			setAnswerStreaming(false);
		},
	});

	return (
		<div className={cn(styles.card, 'shadow-main')}>
			<div className={styles.question}>
				{question.answer && !isEditing ? (
					<>
						<p className="text-black whitespace-pre-wrap dynamic-text">
							{question?.transformedAnswer}
						</p>
						<div className="flex items-center justify-end mt-4">
							<PrimaryButton onClick={() => setIsEditing(true)}>
								Edit
							</PrimaryButton>
						</div>
					</>
				) : (
					<>
						<p className="inline font-bold text-black dynamic-medium ">
							<span className="text-secondary">Define section approach: </span>
							{question?.text}
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger type="button">
										<Info
											className="ml-1 inline text-primary cursor-pointer"
											size={16}
										/>
									</TooltipTrigger>
									<TooltipContent
										align="center"
										side="bottom"
										className="bg-white overflow-y-auto text-wrap max-w-sm break-words text-[#5D6F79] font-normal 
																dynamic-small py-4 px-4 rounded-xl border-none shadow-none drop-shadow-effect"
									>
										By answering this question, you add further context to this
										section. Type in your answer to this question or use the
										“Suggest a response” button to have the AI make its
										recommendation on what it will include in that section.
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</p>
						<div className="relative">
							<div className="my-3 pb-2 hover:bg-[#F0F0F0] border border-[rgba(93, 155, 253, 0.30)] rounded-xl">
								<textarea
									ref={answerRef}
									placeholder={answerStreaming ? '' : question?.proTip}
									value={answer ?? ''}
									onChange={(e) => handleAnswerChange(e)}
									className="text-black bg-transparent border-0 animated-container-transition resize-none placeholder:text-[rgba(135,133,133,0.7)] placeholder:text-base px-4 pt-4 text-sm 2xl:text-base scroll-smooth h-auto min-h-40"
								/>
								<div className="flex justify-end w-full px-5 pb-2">
									<button
										className={cn(
											'flex dynamic-small text-primary gap-1 leading-normal items-center font-bold suggest-response',
											answerStreaming
												? 'cursor-ga opacity-50'
												: 'cursor-pointer animated-hover-effect'
										)}
										disabled={answerStreaming}
										onClick={handleSuggestResponse}
									>
										<AiIcon className="h-5" />
										SUGGEST A REPONSE TO THIS
									</button>
								</div>
							</div>
							<small
								className={
									'block mb-4 text-end dynamic-small ' +
									(answerWordCount > 300
										? 'text-[#FF82A0]'
										: 'text-[rgba(135,133,133,0.45)]')
								}
							>
								{answerWordCount ?? 0}
								/300 words
							</small>
						</div>
						<div className={cn('flex justify-end gap-3 items-center')}>
							{question.transformedAnswer && (
								<SecondaryButton onClick={() => cancelAnswerChange()}>
									Cancel
								</SecondaryButton>
							)}
							<PrimaryButton
								disabled={
									answer === '' || answerWordCount > 300 || answerStreaming
								}
								onClick={handleSubmit}
							>
								Submit
							</PrimaryButton>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default QuestionCard;
