import { FC, useState } from 'react';
import styles from './ChartImageItem.module.css';
import View from '../ui/icons/new/View';
import Add from '../ui/icons/new/Add';
import { cn } from '@/lib/utils';
import { IMAGE_BLOCK } from '@/lib/types/constants';
import { ChartsResponse } from '@/lib/types/apiTypes';
import ChartImageDialog from '../Dialogs/ChartImageDialog/ChartImageDialog';
import customToast from '../CustomToast/CustomToast';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { triggerVisual } from '@/lib/observables/observables';

interface ChartImageItemProps {
	item: {
		id: number;
		imageUrl: string;
		config: ChartsResponse;
	};
}

const ChartImageItem: FC<ChartImageItemProps> = ({ item }) => {
	const { activeSection } = useActiveSection();
	const [mouseOver, setMouseOver] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleAdd = () => {
		if (!activeSection.sectionName) {
			customToast.error({
				title: 'Please select specific Section',
			});
		}

		// TODO
		const editor = activeSection.editorRef?.current;

		if (!editor) return;
		editor.model.change((writer) => {
			// Get the current cursor position
			const currentPosition =
				editor.model.document.selection.getFirstPosition();

			// Check if there is an image at the current cursor position
			const currentElement = currentPosition.nodeAfter;
			const isImage = currentElement && currentElement.name === IMAGE_BLOCK;

			if (isImage) {
				// // Move selection to the end of the document.
				writer.setSelection(
					writer.createPositionAt(editor.model.document.getRoot(), 'end')
				);
				editor.execute('insertImage', { source: item.imageUrl });
			} else {
				// If there is no image, insert the new image at the current cursor position
				editor.execute(
					'insertImage',
					{ source: item.imageUrl },
					currentPosition
				);
			}
			triggerVisual();
		});
	};

	return (
		<>
			<div className={cn(styles.card, mouseOver ? styles.mouseOver : '')}>
				<h2 className="mb-2 font-bold text-secondary">
					{item.config.chart.title}
				</h2>
				<img
					src={item.imageUrl}
					width={380}
					height={190}
					alt={`Chart ${item.id}`}
					loading="lazy"
				/>
				<div className="flex items-center justify-between pt-2">
					<div
						onClick={handleAdd}
						tabIndex={0}
						role="button"
						aria-label="Add this in"
						className="flex items-center gap-2 dynamic-small text-[#5D9bfd] uppercase font-bold cursor-pointer"
						onMouseEnter={() => setMouseOver(true)}
						onMouseLeave={() => setMouseOver(false)}
					>
						<Add />
						Add this in
					</div>
					<div
						role="button"
						onClick={() => setIsOpen(true)}
						tabIndex={0}
						className="flex items-center gap-2 dynamic-small text-[#5D9bfd] uppercase font-bold cursor-pointer"
					>
						<View />
						View Visualization
					</div>
				</div>
			</div>
			<ChartImageDialog isOpen={isOpen} setIsOpen={setIsOpen} item={item} />
		</>
	);
};

export default ChartImageItem;
