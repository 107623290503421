import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import React, { FC } from 'react';

interface SecondaryButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children?: React.ReactNode;
	className?: string;
	onClick?: () => void;
}

const SecondaryButton: FC<SecondaryButtonProps> = ({
	children,
	className,
	...props
}) => {
	return (
		<Button
			{...props}
			className={cn(
				'h-10 2xl:h-11 px-5 min-w-[100px] font-medium dynamic-text text-primary bg-white border border-primary uppercase rounded-lg flex gap-2 transition-all ease-in duration-100 hover:bg-primary hover:text-white',
				className
			)}
		>
			{children}
		</Button>
	);
};

export default SecondaryButton;
