import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useEffect, useState } from 'react';
import useUser from '@/lib/hooks/useUser';
import DialogLoader from '@/components/Loader/DialogLoader/DailogLoader';
import { PhoneForm } from '@/components/Forms/PhoneForm/PhoneForm';
import { OTPForm } from '@/components/Forms/OTPForm/OTPForm';
import { changePhone, sendOTP, verifyOTP } from '@/lib/functions/apiCalls';
import { User } from '@/lib/types/apiTypes';
import { updateProfile } from '@/redux/slices/authSlice';
import useDebounce from '@/lib/hooks/useDebounce';

const ChangePhone = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'changePhone'
	);
	const dialogType = useSelector(
		(root: RootState) => root.page.dialog?.changePhoneType
	);

	const dispatch = useAppDispatch();

	const user = useUser();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [disableResend, setDisableResend] = useState(false);
	const [otpEncodedToken, setOtpEncodedToken] = useState<string | null>(null);
	const [telephoneNumber, setTelephoneNumber] = useState(
		user?.telephoneNumber || ''
	);
	const enableResend = useDebounce(() => {
		setDisableResend(false);
	}, 30000);

	const handlePhoneUpdate = async (twoFactorToken: string) => {
		try {
			if (!user?.id) return;
			setIsLoading(true);
			const response = await changePhone(
				user?.id,
				{
					telephoneNumber: telephoneNumber,
					...(dialogType === 'authenticationSetup'
						? { isTwoFactorEnabled: true }
						: {}),
				},
				twoFactorToken
			);
			handleResponse(
				response,
				dialogType === 'changePhone'
					? 'Phone number has been updated successfully.'
					: 'Two factor authentication has been enabled successfully.'
			);
			const updatedUser = response.data as User;
			dispatch(
				updateProfile({
					user: {
						...user,
						...{
							telephoneNumber: updatedUser.telephoneNumber,
							isTelephoneNumberVerified: updatedUser.isTelephoneNumberVerified,
							isTwoFactorEnabled: updatedUser.isTwoFactorEnabled,
						},
					},
				})
			);
			setIsLoading(false);
			dispatch(closeDialog());
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleOTPSubmit = async (code: string) => {
		try {
			if (!otpEncodedToken) return;
			setIsLoading(true);
			const response = await verifyOTP({
				otp: code,
				otpEncodedToken: otpEncodedToken,
			});
			handleResponse(response);
			const twoFactorToken = (response?.data as any)?.twoFactorToken;
			handlePhoneUpdate(twoFactorToken);
			setIsLoading(false);
			dispatch(closeDialog());
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handlePhoneSubmit = async (telephoneNumber: string) => {
		try {
			if (!user?.id) return;
			setIsLoading(true);
			const response = await sendOTP({
				telephoneNumber: telephoneNumber,
				email: user?.email,
			});
			handleResponse(
				response,
				`OTP has been sent on your phone number ${telephoneNumber ? 'XX XXX XXX-' + telephoneNumber.slice(-4) : ''}`
			);
			setTelephoneNumber(telephoneNumber);
			setOtpEncodedToken((response?.data as any)?.otpEncodedToken);
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleResend = async () => {
		try {
			if (!user?.id) return;
			setIsLoading(true);
			const response = await sendOTP({
				telephoneNumber: telephoneNumber,
				email: user?.email,
			});
			handleResponse(
				response,
				`OTP has been sent again on your phone number ${telephoneNumber ? 'XX XXX XXX-' + telephoneNumber.slice(-4) : ''}`
			);
			setOtpEncodedToken((response?.data as any)?.otpEncodedToken);
			setDisableResend(true);
			enableResend();
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const resetForm = () => {
		setOtpEncodedToken(null);
		setTelephoneNumber(user?.telephoneNumber || '');
		setIsLoading(false);
	};

	useEffect(() => {
		if (isOpen) {
			resetForm();
		}
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="flex flex-col w-full h-auto gap-6 max-w-[500px] bg-white">
				<DialogTitle className="font-bold dynamic-large text-secondary">
					{dialogType === 'changePhone'
						? 'Change Phone Number'
						: 'Setup 2-Factor Authentication'}
				</DialogTitle>
				{otpEncodedToken ? (
					<>
						<p className="flex text-black dynamic-text">
							OTP Code has been sent on your phone number{' '}
							<span className="pl-1.5 font-bold">
								{telephoneNumber
									? 'XX XXX XXX-' + telephoneNumber.slice(-4)
									: ''}
							</span>
						</p>
						<OTPForm
							onSubmit={handleOTPSubmit}
							handleResend={handleResend}
							disableResend={disableResend}
						/>
					</>
				) : (
					<>
						{dialogType === 'authenticationSetup' && (
							<p className="flex text-black dynamic-text">
								Enter your phone number to get codes on your phone
							</p>
						)}
						<PhoneForm
							value={telephoneNumber}
							submitLabel="Save Phone Number"
							onSubmit={handlePhoneSubmit}
						/>
					</>
				)}
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default ChangePhone;
