const Home = () => {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.16895 8.13809L10.0426 1.63501L18.9163 8.13809V18.3572C18.9163 18.6012 18.8653 18.8428 18.7662 19.0683C18.6671 19.2937 18.5218 19.4985 18.3387 19.671C18.1556 19.8436 17.9382 19.9804 17.699 20.0738C17.4597 20.1672 17.2033 20.2153 16.9443 20.2153H3.14087C2.88191 20.2153 2.62549 20.1672 2.38625 20.0738C2.147 19.9804 1.92962 19.8436 1.74651 19.671C1.5634 19.4985 1.41815 19.2937 1.31905 19.0683C1.21995 18.8428 1.16895 18.6012 1.16895 18.3572V8.13809Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7 19.1108V12.1108H13V19.1108"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Home;
