import { SVGProps } from 'react';

const QuestionnaireDashboardIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="53"
			height="52"
			viewBox="0 0 53 52"
			fill="none"
		>
			<g clipPath="url(#clip0_14466_18102)">
				<path
					d="M40.5 0H12.5C5.87258 0 0.5 5.37258 0.5 12V40C0.5 46.6274 5.87258 52 12.5 52H40.5C47.1274 52 52.5 46.6274 52.5 40V12C52.5 5.37258 47.1274 0 40.5 0Z"
					fill="#5D9BFD"
				/>
				<path
					d="M39.8194 25.8981V15.2781C39.8194 13.2381 38.1594 11.5781 36.1194 11.5781H15.3094C13.2694 11.5781 11.6094 13.2381 11.6094 15.2781V30.7481C11.6094 32.7881 13.2694 34.4481 15.3094 34.4481H16.2394L16.4894 36.3881C16.5694 37.0881 16.9994 37.6881 17.6394 37.9881C18.2694 38.2881 19.0294 38.2381 19.6194 37.8481L24.4094 34.6081C24.4794 40.1181 28.9794 44.5881 34.5094 44.5881C40.0394 44.5881 44.6194 40.0581 44.6194 34.4881C44.6194 31.0181 42.7794 27.7381 39.8194 25.8981ZM18.1694 33.2881C18.0994 32.7781 17.6594 32.3881 17.1394 32.3881H15.2994C14.3994 32.3881 13.6694 31.6581 13.6694 30.7581V15.2881C13.6694 14.3881 14.3994 13.6581 15.2994 13.6581H36.1094C37.0094 13.6581 37.7394 14.3881 37.7394 15.2881V24.9281C36.6994 24.5781 35.6194 24.3981 34.5094 24.3981C29.7394 24.3981 25.6194 27.7481 24.6394 32.3981H24.3294C24.1194 32.3981 23.9194 32.4581 23.7494 32.5781L18.5294 36.1081L18.1694 33.3081V33.2881Z"
					fill="white"
				/>
				<path
					d="M34.4631 42.5044C38.8869 42.5044 42.4731 38.9182 42.4731 34.4944C42.4731 30.0706 38.8869 26.4844 34.4631 26.4844C30.0393 26.4844 26.4531 30.0706 26.4531 34.4944C26.4531 38.9182 30.0393 42.5044 34.4631 42.5044Z"
					fill="#5D9BFD"
				/>
				<path
					d="M34.3209 31.9769V31.4969C34.3209 31.1469 34.1209 30.8369 33.8009 30.6869C33.4709 30.5269 33.0909 30.5769 32.8009 30.8069L30.0509 33.0969C29.8409 33.2669 29.7109 33.5269 29.7109 33.7969C29.7109 34.0669 29.8309 34.3269 30.0409 34.4969L32.8009 36.7969C33.0809 37.0269 33.4609 37.0669 33.7909 36.9069C34.1109 36.7569 34.3109 36.4469 34.3109 36.0969V35.6569C36.1909 35.8269 37.0709 37.0069 37.6309 38.0369C37.7509 38.2669 37.9709 38.3969 38.2209 38.3969C38.2709 38.3969 38.3309 38.3969 38.3909 38.3769C38.6909 38.3069 38.9109 38.0369 38.9109 37.7169V36.9269C38.9109 34.3469 36.8909 32.2169 34.3209 31.9869V31.9769ZM33.8609 34.7169C33.6109 34.7169 33.4009 34.9169 33.4009 35.1769V36.0869L30.6409 33.8069L33.4009 31.5069V32.4469C33.4109 32.6869 33.6109 32.8869 33.8609 32.8869C36.1109 32.8869 37.9509 34.6569 37.9909 36.8469C37.3509 35.9369 36.1209 34.7269 33.8609 34.7269V34.7169Z"
					fill="white"
				/>
				<path
					d="M18.7378 18.9175H17.5078C16.9578 18.9175 16.5078 18.4675 16.5078 17.9175V16.6875C16.5078 16.1375 16.9578 15.6875 17.5078 15.6875H18.7378C19.2878 15.6875 19.7378 16.1375 19.7378 16.6875V17.9175C19.7378 18.4675 19.2878 18.9175 18.7378 18.9175ZM17.5078 16.6875V17.9175H18.7378V16.6875H17.5078Z"
					fill="white"
				/>
				<path
					d="M18.7378 24.48H17.5078C16.9578 24.48 16.5078 24.03 16.5078 23.48V22.25C16.5078 21.7 16.9578 21.25 17.5078 21.25H18.7378C19.2878 21.25 19.7378 21.7 19.7378 22.25V23.48C19.7378 24.03 19.2878 24.48 18.7378 24.48ZM17.5078 22.25V23.48H18.7378V22.25H17.5078Z"
					fill="white"
				/>
				<path
					d="M18.7378 30.6675H17.5078C16.9578 30.6675 16.5078 30.2175 16.5078 29.6675V28.4375C16.5078 27.8875 16.9578 27.4375 17.5078 27.4375H18.7378C19.2878 27.4375 19.7378 27.8875 19.7378 28.4375V29.6675C19.7378 30.2175 19.2878 30.6675 18.7378 30.6675ZM17.5078 28.4375V29.6675H18.7378V28.4375H17.5078Z"
					fill="white"
				/>
				<path
					d="M35.6228 18.0469H22.3828C21.9728 18.0469 21.6328 17.7069 21.6328 17.2969C21.6328 16.8869 21.9728 16.5469 22.3828 16.5469H35.6228C36.0328 16.5469 36.3728 16.8869 36.3728 17.2969C36.3728 17.7069 36.0328 18.0469 35.6228 18.0469Z"
					fill="white"
				/>
				<path
					d="M35.6228 23.6172H22.3828C21.9728 23.6172 21.6328 23.2772 21.6328 22.8672C21.6328 22.4572 21.9728 22.1172 22.3828 22.1172H35.6228C36.0328 22.1172 36.3728 22.4572 36.3728 22.8672C36.3728 23.2772 36.0328 23.6172 35.6228 23.6172Z"
					fill="white"
				/>
				<path
					d="M24.7128 29.8047H22.3828C21.9728 29.8047 21.6328 29.4647 21.6328 29.0547C21.6328 28.6447 21.9728 28.3047 22.3828 28.3047H24.7128C25.1228 28.3047 25.4628 28.6447 25.4628 29.0547C25.4628 29.4647 25.1228 29.8047 24.7128 29.8047Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14466_18102">
					<rect
						width="52"
						height="52"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default QuestionnaireDashboardIcon;
