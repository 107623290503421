import { SVGProps } from 'react';

const Word = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="32" height="32" rx="10" fill="#E3EEFF" />
			<mask
				id="mask0_3257_4361"
				maskUnits="userSpaceOnUse"
				x="7"
				y="7"
				width="18"
				height="18"
			>
				<rect
					x="7.08008"
					y="7.14453"
					width="17.8554"
					height="17.8554"
					fill="#D9D9D9"
				/>
			</mask>
			<g mask="url(#mask0_3257_4361)"></g>
			<g clipPath="url(#clip0_3257_4361)">
				<path
					d="M10.7191 13.6959V8.74414C10.7191 8.19185 11.1668 7.74414 11.7191 7.74414H21.094C21.3592 7.74414 21.6135 7.8495 21.8011 8.03703L23.8192 10.0552C24.0067 10.2427 24.1121 10.4971 24.1121 10.7623V23.1116C24.1121 23.6639 23.6644 24.1116 23.1121 24.1116H9.97656M20.3922 7.74414V10.464C20.3922 11.0163 20.8399 11.464 21.3922 11.464H24.1121M16.1057 21.1357V21.1357C15.9988 21.1357 15.9031 21.0699 15.8647 20.9702L14.1352 16.4735C14.095 16.3689 13.9945 16.2999 13.8824 16.2999V16.2999C13.7704 16.2999 13.6699 16.3689 13.6297 16.4735L11.9001 20.9702C11.8618 21.0699 11.766 21.1357 11.6592 21.1357V21.1357C11.544 21.1357 11.4427 21.0593 11.411 20.9485L10.0402 16.1507C10.0083 16.039 10.0922 15.9279 10.2083 15.9279V15.9279C10.2903 15.9279 10.3612 15.9848 10.379 16.0648L11.4426 20.8509C11.4554 20.9087 11.5066 20.9497 11.5657 20.9497V20.9497C11.617 20.9497 11.6632 20.9187 11.6825 20.8712L13.6263 16.1001C13.6687 15.996 13.77 15.9279 13.8824 15.9279V15.9279C13.9949 15.9279 14.0962 15.996 14.1386 16.1001L16.0824 20.8712C16.1017 20.9187 16.1479 20.9497 16.1992 20.9497V20.9497C16.2583 20.9497 16.3095 20.9087 16.3223 20.8509L17.3859 16.0648C17.4037 15.9848 17.4746 15.9279 17.5566 15.9279V15.9279C17.6727 15.9279 17.7565 16.039 17.7246 16.1507L16.3538 20.9485C16.3222 21.0593 16.2209 21.1357 16.1057 21.1357Z"
					stroke="#5D9BFD"
					strokeWidth="1.75"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3257_4361">
					<rect
						width="17.8554"
						height="17.8554"
						fill="white"
						transform="translate(7 7)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Word;
