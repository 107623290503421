import { DashboardSteps } from './steps/DashboardSteps';
import { DesignSteps } from './steps/DesignSteps';
import { DocumentSteps } from './steps/DocumentSteps';
import { OrganizationSettingsSteps } from './steps/OrganizationSettingsSteps';
import { ProposalCreationStepFour } from './steps/ProposalCreation/ProposalCreationStepFour';
import { ProposalCreationStepOne } from './steps/ProposalCreation/ProposalCreationStepOne';
import { ProposalCreationStepThree } from './steps/ProposalCreation/ProposalCreationStepThree';
import { ProposalCreationStepTwo } from './steps/ProposalCreation/ProposalCreationStepTwo';
import { ProposalDashboardSteps } from './steps/ProposalDashboardSteps';
import { SolicitationSteps } from './steps/SolicitationSteps';
import { TemplateBuilderDesignerSteps } from './steps/TemplateBuilder/TemplateBuilderDesignerSteps';
import { TemplateBuilderMainSteps } from './steps/TemplateBuilder/TemplateBuilderMainSteps';
import { UserPageSteps } from './steps/UserPageSteps';
import { WriteSteps } from './steps/WriteSteps';
import { Page } from './types';

export const getStepsForPage = (page: Page) => {
	switch (page) {
		case 0:
			return DashboardSteps;
		case 1:
			return SolicitationSteps;
		case 2:
			return ProposalDashboardSteps;
		case 3:
			return DesignSteps;
		case 4:
			return WriteSteps;
		case 5:
			return OrganizationSettingsSteps;
		case 6:
			return ProposalCreationStepOne;
		case 7:
			return ProposalCreationStepTwo;
		case 8:
			return ProposalCreationStepThree;
		case 9:
			return ProposalCreationStepFour;
		case 10:
			return TemplateBuilderMainSteps;
		case 11:
			return TemplateBuilderDesignerSteps;
		case 12:
			return UserPageSteps;
		case 13:
			return DocumentSteps;
		default:
			return [];
	}
};
