import { FC, useCallback, useState } from 'react';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import { Download } from 'lucide-react';
import QuestionBuilderList from '../QuestionsBuilderList/QuestionBuilderList';
import QuestionBuilderInput from '../QuestionBuilderInput/QuestionBuilderInput';
import { cloneDeep } from 'lodash';
import {
	ProcessingStatus,
	ProposalResponseData,
	QuestionBuilderItem,
} from '@/lib/types/apiTypes';
import {
	convertToISO8601,
	ErrorHandle,
	generateSectionDetailsWithAI,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { createTemplate } from '@/lib/functions/TemplateBuilder/templateCalls';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import {
	SectionBuilder,
	TemplateLibrary,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { useNavigate } from 'react-router';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import {
	createProposalFromTemplate,
	getProposalById,
	updateProposal,
} from '@/lib/functions/apiCalls';
import {
	pageTransitionEnd,
	pageTransitionStart,
	processSuccess,
	setProposal,
	updateStep,
} from '@/redux/slices/proposalSlice';
import {
	nextNofo,
	setCurrentStepQuestion,
	setQuestions,
} from '@/redux/slices/questionSlice';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QuestionBuilderTemplate from '../ExportDocuments/QuestionBuilderTemplate/QuestionBuilderTemplate';
import { motion } from 'framer-motion';
import { prFooterSlideInDelay, prTextSlideIn } from '@/constants/variants';

interface QuestionBuilderProps {}

const QuestionBuilder: FC<QuestionBuilderProps> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const rfp = useSelector((state: RootState) => state.solicitation.rfp);
	const currentStep = useSelector(
		(root: RootState) => root.proposal.currentStep
	);

	const organizationIdentifier = useOrganizationId();

	const [questionsList, setQuestionsList] = useState<QuestionBuilderItem[]>([]);
	const [editingQuestion, setEditingQuestion] = useState<string | null>(null);

	const updateQuestions = (
		questionId: string,
		updates: {
			text?: string;
			wordCount?: number;
			status?: ProcessingStatus;
			section?: SectionBuilder;
		}
	) => {
		setQuestionsList((questionsList) =>
			questionsList.map((questionItem) =>
				questionItem.id === questionId
					? { ...questionItem, ...updates }
					: questionItem
			)
		);
	};

	const handleAddQuestion = async (questionText: string, wordCount: number) => {
		const questionId = String(Date.now());
		const updatedQuestions = [
			...questionsList,
			{
				id: questionId,
				text: questionText,
				wordCount,
				status: 'processing' as ProcessingStatus,
			},
		];
		setQuestionsList(updatedQuestions);
		const section = await generateSectionDetailsWithAI(
			dispatch,
			questionText,
			wordCount,
			organizationIdentifier
		);
		if (section) {
			updateQuestions(questionId, {
				section: { ...section, alias: section.name },
				status: 'ready',
			});
		} else {
			updateQuestions(questionId, {
				status: 'error',
			});
		}
	};

	const handleSaveQuestion = async (
		questionText: string,
		wordCount: number,
		id?: string
	) => {
		const questionId = id || editingQuestion;
		if (!questionId) return;
		updateQuestions(questionId, {
			text: questionText,
			wordCount: wordCount,
			status: 'processing',
		});
		setEditingQuestion(null);
		const section = await generateSectionDetailsWithAI(
			dispatch,
			questionText,
			wordCount,
			organizationIdentifier
		);
		if (section) {
			updateQuestions(questionId, {
				text: questionText,
				wordCount: wordCount,
				section: { ...section, alias: section.name },
				status: 'ready',
			});
		} else {
			updateQuestions(questionId, {
				text: questionText,
				wordCount: wordCount,
				status: 'error',
			});
		}
	};

	const updateQuestionPosition = (questions, dragIndex, hoverIndex) => {
		const updatedQuestions = cloneDeep(questions);
		const [draggedItem] = updatedQuestions.splice(dragIndex, 1);
		updatedQuestions.splice(hoverIndex, 0, draggedItem);
		return updatedQuestions;
	};

	const handleMoveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const updatedQuestions = updateQuestionPosition(
				questionsList,
				dragIndex,
				hoverIndex
			);
			setQuestionsList(updatedQuestions);
		},
		[questionsList]
	);

	const handleEditQuestion = (id: string) => {
		setEditingQuestion(id);
	};

	const handleDeleteQuestion = (id: string) => {
		setQuestionsList((prev) => prev.filter((q) => q.id !== id));
	};

	const getSections = (): SectionBuilder[] => {
		const filteredQuestionsList = questionsList.filter(
			(questionItem) => questionItem.section
		);
		return filteredQuestionsList.flatMap(
			(questionItem) => questionItem.section ?? []
		);
	};

	const createQuestionnaire = async () => {
		if (!rfp) {
			console.error('RFP data is missing. Cannot create proposal.');
			return;
		}
		try {
			dispatch(pageTransitionStart());
			dispatch(updateStep(currentStep + 1));

			const sections = getSections();
			const template: TemplateLibrary = {
				name: rfp.metaData.title,
				description: '',
				isLive: true,
				tags: [],
				organizationIdentifier: organizationIdentifier,
				questions: [],
				sections: sections,
				isHidden: true,
			};
			const templateResponse = await createTemplate(template);
			handleResponse(templateResponse);
			const createdTemplate = templateResponse.data as TemplateLibrary;
			if (createdTemplate) {
				const response = await createProposalFromTemplate({
					templateId: String(createdTemplate?._id),
					rfpLink: rfp.sourceLink,
					documentId: rfp.documentId,
					rfpType: rfp.category,
					rfpCode: rfp.metaData.code,
					rfpSummary: rfp.metaData.summary,
					rfpTitle: rfp.metaData.title,
					rfpAgency: rfp.metaData.agency,
					rfpDeadline: rfp.metaData.deadline
						? convertToISO8601(rfp.metaData.deadline)
						: null,
					rfpFunding: rfp.metaData.funding,
				});

				if (response) {
					const proposalResponse = await getProposalById(response.id);
					if (proposalResponse.statusCode !== 200) {
						console.log('Error in fetching proposal', proposalResponse);
					} else {
						const proposal = (proposalResponse.data as ProposalResponseData)
							.proposal;
						await updateProposal(proposal?.id as number, proposal.rfpTitle);
						dispatch(setProposal(proposal));
						dispatch(setQuestions(proposal.questions));
						dispatch(setCurrentStepQuestion(proposal.questions[0]));
						dispatch(nextNofo());
						dispatch(processSuccess());
						dispatch(pageTransitionEnd());
					}
				}
			}
		} catch (error) {
			ErrorHandle(dispatch, error);
			dispatch(pageTransitionEnd());
			navigate('/solicitations');
		}
	};

	return (
		<div className="px-3 mt-4">
			<motion.div
				key="questionHeader"
				variants={prTextSlideIn}
				initial="initial"
				animate="animate"
			>
				<h1 className="font-bold text-center text-black dynamic-xl-large">
					Now let's add your questions or sections
				</h1>
				<div className="flex items-center justify-between gap-4 px-5 py-3 mt-4 bg-white rounded-xl shadow-main">
					<h2 className="font-bold text-secondary dynamic-large">
						Questions or sections
					</h2>
					<div className="flex items-center gap-3">
						{/* <PrimaryButton className="bg-[#E3EEFF] text-primary hover:bg-primary hover:text-white">
									<Download /> Export
								</PrimaryButton> */}

						<PDFDownloadLink
							document={
								<QuestionBuilderTemplate questionsList={questionsList} />
							}
							fileName="Question Builder Template.pdf"
							className={`${questionsList.length > 0 ? '' : 'opacity-50 pointer-events-none'}`}
						>
							<div className=" h-10 2xl:h-11 px-5 min-w-[100px] dynamic-text font-medium uppercase rounded-lg flex gap-2 items-center transition-all ease-in duration-200 bg-[#E3EEFF] text-primary hover:bg-primary hover:text-white">
								<Download /> Export
							</div>
						</PDFDownloadLink>
					</div>
				</div>
			</motion.div>
			<motion.div
				key="questionBody"
				variants={prFooterSlideInDelay}
				animate="animate"
				exit="exit"
				initial="initial"
			>
				<QuestionBuilderList
					questions={questionsList}
					editingQuestion={editingQuestion}
					onEdit={handleEditQuestion}
					onSave={handleSaveQuestion}
					onRetry={handleSaveQuestion}
					onDelete={handleDeleteQuestion}
					moveCard={handleMoveCard}
				/>
				<QuestionBuilderInput onSave={handleAddQuestion} />
				<div className="flex items-center justify-center mt-5">
					<PrimaryButton
						disabled={
							!!editingQuestion ||
							(questionsList?.filter(
								(questionItem) => questionItem.status === 'processing'
							)?.length || 0) > 0 ||
							questionsList.length === 0
						}
						onClick={createQuestionnaire}
					>
						Complete adding your questions
					</PrimaryButton>
				</div>
			</motion.div>
		</div>
	);
};

export default QuestionBuilder;
