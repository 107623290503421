import { SVGProps } from 'react';

const TemplateSetupIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="193"
			height="166"
			viewBox="0 0 193 166"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Layer_1">
				<g id="Group">
					<g id="Group_2">
						<path
							id="Vector"
							d="M149.542 63.7441H14.3087C12.498 63.7441 11.0391 65.2262 11.0391 67.0423V148.086C11.0391 149.913 12.5083 151.395 14.3087 151.395H149.552C151.363 151.395 152.832 149.913 152.832 148.086V67.0527C152.832 65.2262 151.363 63.7546 149.552 63.7546L149.542 63.7441Z"
							fill="white"
							stroke="#AFC1DB"
							strokeWidth="2.90373"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_2"
							d="M160.325 150.752H5.42249C3.5497 150.752 2.03906 153.059 2.03906 155.908V158.277C2.03906 161.126 3.5497 163.433 5.42249 163.433H160.325C162.198 163.433 163.709 161.126 163.709 158.277V155.908C163.709 153.059 162.198 150.752 160.325 150.752Z"
							fill="white"
							stroke="#AFC1DB"
							strokeWidth="2.90373"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_3"
							d="M16.0996 152.713H148.602"
							stroke="#AFC1DB"
							strokeWidth="4.93044"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_4"
							d="M112.862 20.668H34.5486C29.4239 20.668 25.2695 24.843 25.2695 29.9932V123.285C25.2695 128.435 29.4239 132.61 34.5486 132.61H112.862C117.987 132.61 122.142 128.435 122.142 123.285V29.9932C122.142 24.843 117.987 20.668 112.862 20.668Z"
							fill="#EAF1FC"
							stroke="#AFC1DB"
							strokeWidth="2.90373"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
					<path
						id="Vector_5"
						d="M42.1172 40.2578H106.785"
						stroke="#AFC1DB"
						strokeWidth="2.90373"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_6"
						d="M42.1172 59.8477H106.785"
						stroke="#AFC1DB"
						strokeWidth="2.90373"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_7"
						d="M42.1172 79.2344H83.1632"
						stroke="#AFC1DB"
						strokeWidth="2.90373"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="Group_3">
						<path
							id="Vector_8"
							d="M176.446 47.5048L183.471 51.3769L176.446 55.2595L172.607 62.3463L168.758 55.2595L161.732 51.3769L168.758 47.5048L172.607 40.418L176.446 47.5048Z"
							fill="white"
							stroke="#AFC1DB"
							strokeWidth="3.41225"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_9"
							d="M158.835 15.0374L170.848 21.6649L158.835 28.2925L152.265 40.4204L145.685 28.2925L133.672 21.6649L145.685 15.0374L152.265 2.91992L158.835 15.0374Z"
							fill="white"
							stroke="#AFC1DB"
							strokeWidth="3.41225"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</g>
				<g id="Group_4">
					<path
						id="Vector_10"
						d="M182.251 91.0723H103.636C99.2073 91.0723 95.6172 94.6937 95.6172 99.161V115.986C95.6172 120.453 99.2073 124.074 103.636 124.074H182.251C186.68 124.074 190.27 120.453 190.27 115.986V99.161C190.27 94.6937 186.68 91.0723 182.251 91.0723Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="2.94794"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_11"
						d="M114.324 101.74H168.076"
						stroke="#AFC1DB"
						strokeWidth="2.94794"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_12"
						d="M114.324 113.471H149.762"
						stroke="#AFC1DB"
						strokeWidth="2.94794"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<g id="Group_5">
					<path
						id="Vector_13"
						d="M80.1847 113.47C80.1847 113.47 61.55 111.665 50.0547 91.0723V102.355"
						stroke="#AFC1DB"
						strokeWidth="3.41225"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_14"
						d="M50.0547 91.0723L60.1946 91.1548"
						stroke="#AFC1DB"
						strokeWidth="3.41225"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
		</svg>
	);
};

export default TemplateSetupIcon;
