import { ColumnDef } from '@tanstack/react-table';
import UserAvatarList from '@/components/UserAvatarList/UserAvatarList';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { Team, User } from '@/lib/types/apiTypes';
import ManageTeamsDrawer from '@/components/Drawers/ManageTeamsDrawer/ManageTeamsDrawer';

export function getColumnsForTeamsTable(
	handleDeleteTeam: (teamId: number) => void,
	users: User[]
): ColumnDef<Team>[] {
	const columns: ColumnDef<Team>[] = [
		{
			accessorKey: 'name',
			header: 'Team Name',
			cell: ({ row }) => {
				return (
					<div className="text-black w-[15vw]">{row.original.name || '-'}</div>
				);
			},
		},
		{
			accessorKey: 'userIds',
			header: 'Team Members',
			cell: ({ row }) => {
				const teamMemberIds = row.original.teamMembers.map(
					(teamMember) => teamMember.id
				);
				const teamUsers = users.filter((user) =>
					teamMemberIds.includes(user.id)
				);

				return (
					<div className="w-[35vw]">
						{teamUsers?.length ? (
							<UserAvatarList
								users={teamUsers.slice(0, 8)}
								allUsers={users}
								additionalCount={
									teamUsers.length - 8 > 0 ? teamUsers.length - 8 : 0
								}
								showAssignPopover={false}
							/>
						) : (
							<></>
						)}
					</div>
				);
			},
		},
		{
			accessorKey: 'id',
			header: 'Actions',
			cell: ({ row }) => {
				const teamMemberIds = row.original.teamMembers.map(
					(teamMember) => teamMember.id
				);
				return (
					<div className="flex gap-4">
						<ManageTeamsDrawer
							type="edit"
							users={users as any}
							triggerButton={
								<PrimaryButton className="h-8 2xl:h-9">Edit Team</PrimaryButton>
							}
							id={row.original.id}
							name={row.original.name}
							userIds={teamMemberIds}
						/>
						<SecondaryButton
							className="h-8 2xl:h-9"
							onClick={() =>
								handleDeleteTeam && handleDeleteTeam(row.original.id)
							}
						>
							Remove Team
						</SecondaryButton>
					</div>
				);
			},
		},
	];

	return columns;
}
