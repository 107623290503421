import styles from './AccountPage.module.css';
import { cn } from '@/lib/utils';
import { FC, useEffect, useMemo } from 'react';
import AccountInfo from '@/components/AccountInfo/AccountInfo';
// import AccountNotification from '@/components/AccountNotification/AccountNotification';
import { useSearchParams } from 'react-router-dom';
import useUser from '@/lib/hooks/useUser';
import { motion, useAnimation } from 'framer-motion';
import { iCSlideInDelay } from '@/constants/variants';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { updateProfile } from '@/redux/slices/authSlice';
import { refreshUserDetails } from '@/lib/functions/funcUtils';

interface AccountPageProps {}

const AccountPage: FC<AccountPageProps> = () => {
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const isEditable = searchParams.get('mode') === 'edit';
	const user = useUser();

	const hideAnimation = useMemo(() => {
		return { opacity: 0, transition: { duration: 0.25 } };
	}, []);
	const showAnimation = useMemo(() => {
		return { opacity: 1, transition: { duration: 0.4 } };
	}, []);

	const controlPanel = useAnimation();
	useEffect(() => {
		if (isEditable) {
			controlPanel.start(hideAnimation);
		} else {
			controlPanel.start(showAnimation);
		}
	}, [isEditable]);

	useEffect(() => {
		refreshUserDetails(dispatch, updateProfile);
		controlPanel.start('animate');
	}, []);

	return (
		<div className={cn(styles.wrapper, 'hidden-scroll')}>
			<motion.div
				className="grid w-full grid-cols-12 gap-5 h-fit"
				exit={{ opacity: 0, transition: { duration: 0.25 } }}
			>
				<motion.div
					className="col-span-8 bg-white p-8 rounded-[24px] shadow-main"
					variants={iCSlideInDelay}
					initial="initial"
					animate="animate"
					exit="exit"
				>
					<AccountInfo user={user} />
				</motion.div>
				{/* <motion.div
					className="col-span-4 p-8 bg-white rounded-[24px]"
					variants={slideInVariant(0.6)}
					animate={controlPanel}
					initial="initial"
					exit="exit"
				>
					<AccountNotification />
				</motion.div> */}
			</motion.div>
		</div>
	);
};

export default AccountPage;
