import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cn } from '@/lib/utils';

const Slider = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
		showSteps?: boolean;
	}
>(({ className, showSteps = false, ...props }, ref) => {
	const sliderValue = props?.value?.[0] || props?.defaultValue?.[0] || 0;
	const sliderPositionOffset = Math.round(15 * ((sliderValue - 50) / 50));
	const { max: max, step: step } = props;

	return (
		<SliderPrimitive.Root
			ref={ref}
			className={cn(
				'relative flex w-full touch-none select-none items-center',
				className
			)}
			{...props}
		>
			<SliderPrimitive.Track className="relative h-[1.4rem] w-full grow overflow-hidden cursor-pointer rounded-xl bg-designBackground">
				{step && max && showSteps && (
					<div className="flex absolute w-full justify-between">
						{Array.from({ length: Math.round(max / step) + 1 }, (_, index) => (
							<div
								key={index}
								className="h-[1.4rem] w-[1.4rem] bg-[#D9D9D9] rounded-full"
							></div>
						))}
					</div>
				)}
				<SliderPrimitive.Range
					className={`absolute h-full bg-primary here`}
					style={{
						width: `calc(${sliderValue}% ${
							sliderPositionOffset >= 0 ? '-' : '+'
						} ${Math.abs(sliderPositionOffset)}px)`,
					}}
				/>
			</SliderPrimitive.Track>
			<SliderPrimitive.Thumb className="block h-[1.4rem] w-[1.4rem] rounded-xl border border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 cursor-pointer" />
		</SliderPrimitive.Root>
	);
});
Slider.displayName = SliderPrimitive.Root.displayName;

export default Slider;
