import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { RootState } from '@/redux/store/store';

interface TagFilterProps {
	type: string;
}
export const TagFilter: FC<TagFilterProps> = ({ type }) => {
	const dispatch = useAppDispatch();
	const templateTagFilter = useAppSelector(
		(root: RootState) => root.templates.templateTagFilter
	);
	return (
		<SecondaryButton
			onClick={() => {
				dispatch(
					openDialog({
						type: 'templateTagFilter',
						templateTagType: type,
					})
				);
			}}
			type="button"
			className="relative rounded-xl w-full"
		>
			<span className="capitalize">Filter By Tags</span>
			{templateTagFilter && templateTagFilter.length > 0 && (
				<span className="absolute bg-secondary text-white px-2 py-0.5 text-xs font-bold rounded-full -top-3 -right-3">
					{templateTagFilter.length}
				</span>
			)}
		</SecondaryButton>
	);
};
