import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import React, { FC } from 'react';

interface TextButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children?: React.ReactNode;
	className?: string;
	onClick?: (event?) => void;
}

const TextButton: FC<TextButtonProps> = ({ children, className, ...props }) => {
	return (
		<Button
			{...props}
			className={cn(
				'h-10 2xl:h-11 px-1 font-bold dynamic-text text-primary bg-transparent uppercase flex gap-2 animated-hover-effect',
				className
			)}
		>
			{children}
		</Button>
	);
};

export default TextButton;
