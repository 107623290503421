import { FC, useState, useEffect, useRef } from 'react';
import UserMessage from '../UserMessage/UserMessage';
import AiMessage from '../AI/AiMessage/AiMessage';
import styles from './ChatMessageLists.module.css';
import { cn } from '@/lib/utils';
import LoadingLogo from '@/assets/lotties/loading_v1.json';
import Lottie from 'lottie-react';
import { Message } from '@/lib/types/apiTypes';

interface ChatMessageListsProps {
	isMessageLoading: boolean;
	messages: Message[];
}

const ChatMessageLists: FC<ChatMessageListsProps> = ({
	isMessageLoading,
	messages,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [loadingState, setLoadingState] = useState(false);

	const scrollToBottom = () => {
		setTimeout(() => {
			if (containerRef.current) {
				const container = containerRef.current;
				container.scrollTop = container.scrollHeight;
			}
		}, 50);
	};

	useEffect(() => {
		scrollToBottom();
	}, []);

	if (loadingState !== isMessageLoading) {
		scrollToBottom();
		setLoadingState(isMessageLoading);
	}

	return (
		<div
			className={cn(
				styles.chat_list,
				'pl-5 mr-5',
				messages?.length > 0 ? 'overflow-y-auto py-3' : ''
			)}
			ref={containerRef}
		>
			<div className="block">
				{messages.map((item, index) => {
					if (item.role === 'user') {
						return <UserMessage key={index} text={item.content} />;
					} else {
						return <AiMessage key={index} text={item.content} />;
					}
				})}
				{isMessageLoading && (
					<div className="flex items-center justify-center w-full py-4 xl:py-6 2xl:py-8">
						<Lottie
							className="block animation-lottie max-h-32 max-w-32"
							animationData={LoadingLogo}
							loop={true}
							autoPlay={true}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ChatMessageLists;
