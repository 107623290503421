import { generalSolicitationDropdown } from '@/constants/templateBuilderData';
import { SectionBuilder } from '@/lib/types/TemplateBuilder/templateTypes';
import { useEffect, useState } from 'react';
import { getWordCount } from '../funcUtils';
import store from '@/redux/store/store';
/**
 * get node key
 */
export const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex;

/**
 * find duplicate sections in available sections array so it can't be drop into template assembler
 * @param section
 * @param nodeId
 * @returns
 */
export const hasDuplicateInSection = (
	section: SectionBuilder,
	systemName: string
) => {
	// Check if the current section has the same ID
	if (section.systemName.toLowerCase() === systemName.toLowerCase()) {
		return true;
	}

	// Check duplicates in children recursively
	if (section.children && section.children.length > 0) {
		return section.children.some((child) =>
			hasDuplicateInSection(child, systemName)
		);
	}

	return false;
};

export const hasDuplicateInSectionArray = (
	sectionsArray: SectionBuilder[],
	systemName: string
) => {
	// Check duplicates in each section of the array
	return sectionsArray.some((section) =>
		hasDuplicateInSection(section, systemName)
	);
};

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const extractNames = (data: any) =>
	data && data.map((dt: any) => dt && dt.value);

export const tagAssociativeArray = (
	inputArray: any,
	identifier = undefined
) => {
	return (
		inputArray &&
		inputArray.map((value) => ({
			organizationIdentifier: identifier,
			label: value,
			value: value,
		}))
	);
};

export const tagComponentAssociativeArray = (inputArray: any) => {
	return (
		inputArray &&
		inputArray.map((value) => ({
			id: Math.random(),
			text: value,
		}))
	);
};

const labelMap = new Map(
	generalSolicitationDropdown.map((item) => [item.value, item.label])
);

function valueToLabelMap(value) {
	return labelMap.get(value) || value;
}
export const solicitationAssociativeArrayConversion = (inputArray: any) => {
	return inputArray &&
		inputArray.every((element) => typeof element === 'string')
		? inputArray.map((value) => ({
				label: valueToLabelMap(value),
				value: value,
			}))
		: inputArray.map((res) => ({
				label: valueToLabelMap(res.value),
				value: res.value,
			}));
};

export const associativeArrayConversion = (inputArray: any) => {
	return (
		inputArray &&
		inputArray.map((value) => ({
			label: value,
			value: value,
		}))
	);
};

// Helper function for debounce
export const useDebounce = (value: string | undefined, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

// This function is designed to parse a hierarchical structure of sections into a flat list of nodes.
export function parseTreeToSectionsIDS(
	sections: SectionBuilder[] | undefined
): string[] {
	// Initialize an empty array to store the resulting nodes.
	let nodes: any[] = [];

	// Iterate over each section in the provided sections array.
	sections?.forEach((section) => {
		// Push a new node to the nodes array. The node is constructed based on the current section's properties.
		nodes.push(section._id);

		// If the current section has children...
		if (section.children && section.children.length > 0) {
			// Recursively call the parseSectionsToNodes function for the children and concatenate the result to the nodes array.
			nodes = nodes.concat(parseTreeToSectionsIDS(section.children));
		}
	});

	// Return the constructed nodes array.
	return nodes;
}

export const splitTagArray = (data: any) => {
	return data.reduce((acc, obj) => {
		const splitTexts = obj.text.split(',');
		const newObjects = splitTexts.map((text, index) => ({
			id: obj.id + index,
			text: text.trim(),
		}));
		return [...acc, ...newObjects];
	}, []);
};

export const wordCountValidation = (value, maxWordCount) => {
	const wordCount = getWordCount(value);
	return (
		wordCount <= maxWordCount ||
		`Maximum word count exceeded (${wordCount - maxWordCount} words).`
	);
};

export const charactersCountValidation = (value, maxCharCount) => {
	const charCount = value?.length || 0;
	return (
		charCount <= maxCharCount ||
		`Maximum word count exceeded (${charCount - maxCharCount} characters).`
	);
};

export const changeNodeDesign = (node, cancel = false) => {
	if (node === undefined) return;
	const elements = document.querySelectorAll(
		'.selected-sections .generated-nodes'
	);

	elements.forEach((value) => {
		const result = value as HTMLElement;

		const currentElement = document.getElementById(result.id) as HTMLElement;
		const parent = result.closest(
			// '.rst__rowContents'
			'.rowContents'
		) as HTMLElement;

		const marker = result.closest(
			// '.rst__row'
			'.rstRow'
		) as HTMLElement;

		const nodeId = node.systemName;

		if (marker && marker.firstChild && node) {
			(marker.firstChild as HTMLElement).style.backgroundColor =
				result.id === `node-${nodeId}` ? '#fff' : '#fff';

			(marker.firstChild as HTMLElement).style.border =
				result.id === `node-${nodeId}`
					? '1px solid #E861C9'
					: '1px solid #5D9BFD';
		}
		const wordCountDivNode = parent.childNodes[1]
			.childNodes[0] as HTMLDivElement;
		if (
			(wordCountDivNode?.style?.color ||
				wordCountDivNode?.style?.color === '') &&
			node
		) {
			wordCountDivNode.style.color =
				result.id === `node-${nodeId}` ? '#fff' : '#5D9BFD';
		}
		if (cancel) {
			currentElement.style.color = '#5D9BFD';
			if (wordCountDivNode) {
				wordCountDivNode.style.color = '#5D9BFD';
			}
			parent.style.backgroundColor = '';
			parent.style.border = '1px solid #D3DDE2';
		}
		if (parent && !cancel && node) {
			currentElement.style.color =
				result.id === `node-${nodeId}` ? '#fff' : '#5D9BFD';
			parent.style.backgroundColor =
				result.id === `node-${nodeId}` ? '#E861C9' : '';
			parent.style.border =
				result.id === `node-${nodeId}` ? '#E861C9' : '1px solid #D3DDE2';

			parent.classList.add('hover:bg-[#EAF1FC]');
		}
	});
};

export const extractTableHeading = (tableHeadings: any) =>
	tableHeadings && tableHeadings.map((heading: any) => heading.text);

export const calculateTotalWordCount = (
	sections: SectionBuilder[] | undefined
) => {
	let wordCount: number = 0;

	// Iterate over each section in the provided sections array.
	sections?.forEach((section) => {
		wordCount += Number(section.wordCount);
		if (section.children && section.children.length > 0) {
			calculateTotalWordCount(section.children);
		}
	});
	return isValidNumber(wordCount) ? wordCount : 0;
};
export const isValidNumber = (value) => {
	return typeof value === 'number' && !isNaN(value);
};

export const templateTreeMapper = (data: any) => {
	const treeData: any = [];
	if (data) {
		data.forEach((v) => {
			const data = {
				id: v._id,
				_id: v._id,
				name: v.name,
				organizationIdentifier: v.organizationIdentifier,
				level: v.level,
				order: v.order,
				type: v.type,
				isLive: v.isLive,
				defaultContext: v.defaultContext,
				specialContext: v.specialContext,
				prerequisites: v.prerequisites,
				children: v.children,
				alias: v.alias,
				wordCount: v.wordCount,
				requiredRfpSections: v.requiredRfpSections,
				tags: v.tags,
				questions: v.questions,
				description: v.description,
				systemName: v.systemName,
				expectedOutput: v.expectedOutput,
				tableHeadings: v.tableHeadings,
				isPristine: true,
				isNew: false,
			};
			treeData.push(data);
		});
	}
	return treeData;
};

export const findNodeById = (tree, id) => {
	if (!tree) {
		return null;
	}
	for (const node of tree) {
		if (node._id === id) {
			return node;
		}

		if (node.children) {
			const foundNode = findNodeById(node.children, id);
			if (foundNode) {
				return foundNode;
			}
		}
	}

	return null;
};

export const findMatchedSections = (node, sections) => {
	node.isMatched = sections.some((section) => section.includes(node.name));
	if (node.children) {
		node.children.forEach((child) => findMatchedSections(child, sections));
	}
};

export const updateTemplateBuilderLevels = (
	array: SectionBuilder[],
	isTemplateMode?: boolean
) => {
	let order = 0;
	const templates = store.getState().templates;
	const updateObject = (obj: any, level: number) => {
		const updatedObj = {
			...obj,
			level,
			order,
			type: 'default',
		};
		if (!isTemplateMode && templates.deleteSections.length > 0) {
			if (obj.prerequisites?.length > 0) {
				updatedObj.prerequisites = obj.prerequisites.filter((item) => {
					const isNotExists = !templates.deleteSections.includes(
						item.toLowerCase()
					);
					if (!isNotExists) {
						updatedObj.isPristine = obj.isNew ? true : false; //update isPristine value if systemName exists in deletedSections
					}
					return isNotExists; //return those prerequisites which are not exists in deletedSections
				});
			}
		}
		if (!obj.isNew && !isTemplateMode) {
			if (obj.level !== level || obj.order !== order) {
				updatedObj.isPristine = false; //update isPristine value if level or order value changes
			}
		}
		if (isTemplateMode) {
			const updatedQuestions = updatedObj.questions?.map((question) => {
				return {
					...question,
					rfpSections: obj.requiredRfpSections,
					order,
				};
			});
			updatedObj.questions = updatedQuestions;
			updatedObj.isPristine = true;
			updatedObj.isNew = false;
		}
		order++;
		if (obj.children && obj.children.length > 0) {
			updatedObj.children = obj.children.map((child: any) =>
				updateObject(child, level + 1)
			);
		}

		return updatedObj;
	};

	return array.map((obj) => updateObject(obj, 0));
};

export const createUpdateTags = (
	values: string[],
	orgIdentifier: string,
	templateTagOptions
) => {
	const templateValues = templateTagOptions.map((option) => option.value);

	// Identify values not present in templateTagOptions
	const newTags = values.filter((value) => !templateValues.includes(value));

	// Filter existing tags from templateTagOptions that match values array
	const tagValues = templateTagOptions.filter((option) =>
		values.includes(option.value)
	);

	// If no new values are found, return null
	if (newTags.length === 0) {
		return null;
	}

	// Filter the default tag options
	const defaultTagOptions = templateTagOptions.filter(
		(option) => option.organizationIdentifier !== undefined
	);

	// Combine existing tags with default options and new tags
	const finalTags = tagValues.concat(
		defaultTagOptions,
		newTags.map((value) => ({ label: value, value: value }))
	);

	// Prepare the data
	const data = {
		organizationIdentifier: `templates_${orgIdentifier}`,
		tags: extractNames(finalTags),
	};

	return data;
};

export function isDescendant(older, younger) {
	return (
		!!older.children &&
		typeof older.children !== 'function' &&
		older.children.some(
			(child) => child === younger || isDescendant(child, younger)
		)
	);
}

export function parseSectionsToLabelValue(sections) {
	// Initialize an empty array to store the resulting nodes.
	let nodes: any[] = [];
	// Iterate over each section in the provided sections array.
	sections?.forEach((section) => {
		// Push a new node to the nodes array. The node is constructed based on the current section's properties.
		nodes.push({
			label: section.name,
			value: section.systemName,
		});
		// If the current section has children...
		if (section.children && section.children.length > 0) {
			// Recursively call the parseSectionsToNodes function for the children and concatenate the result to the nodes array.
			nodes = nodes.concat(parseSectionsToLabelValue(section.children));
		}
	});
	// Return the constructed nodes array.
	return nodes;
}

export function getSelectedPrerequisites(sections, prerequisites) {
	return sections.filter((section) => {
		const lowercaseValue = section.value.toLowerCase();
		return prerequisites?.some((prerequisite) =>
			lowercaseValue.includes(prerequisite.toLowerCase())
		);
	});
}
export function findParentBySystemName(tree, systemName, parent = null) {
	if (!tree || !systemName) {
		return null;
	}
	for (const node of tree) {
		// Check if the current node matches the systemName
		if (node.systemName.trim() === systemName.trim()) {
			return parent; // Return the parent node
		}

		// If the node has children, recursively search in the children
		if (node.children && node.children.length > 0) {
			const foundParent = findParentBySystemName(
				node.children,
				systemName,
				node
			);
			if (foundParent) {
				return foundParent; // Return as soon as the parent is found
			}
		}
	}
	return null; // Return null if no match is found
}
