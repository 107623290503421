import { SVGProps } from 'react';

const WordCount = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
		>
			<mask
				id="mask0_9852_17713"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="18"
				height="19"
			>
				<rect y="0.5" width="18" height="18" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_9852_17713)">
				<path
					d="M14.25 14.75H6C5.5875 14.75 5.23438 14.6031 4.94063 14.3094C4.64688 14.0156 4.5 13.6625 4.5 13.25V2.75C4.5 2.3375 4.64688 1.98438 4.94063 1.69063C5.23438 1.39688 5.5875 1.25 6 1.25H11.25L15.75 5.75V13.25C15.75 13.6625 15.6031 14.0156 15.3094 14.3094C15.0156 14.6031 14.6625 14.75 14.25 14.75ZM10.5 6.5V2.75H6V13.25H14.25V6.5H10.5ZM3 17.75C2.5875 17.75 2.23438 17.6031 1.94063 17.3094C1.64688 17.0156 1.5 16.6625 1.5 16.25V5.75H3V16.25H11.25V17.75H3Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default WordCount;
