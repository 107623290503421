const KeyIcon = () => {
	return (
		<svg
			width="29"
			height="29"
			viewBox="0 0 29 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_932_4117"
				maskUnits="userSpaceOnUse"
				x="2"
				y="2"
				width="24"
				height="25"
			>
				<rect
					width="24"
					height="24"
					transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 26 2.65625)"
					fill="#D9D9D9"
				/>
			</mask>
			<g mask="url(#mask0_932_4117)">
				<path
					d="M15.5 9.65625C15.5 9.10625 15.3042 8.63542 14.9125 8.24375C14.5208 7.85208 14.05 7.65625 13.5 7.65625C12.95 7.65625 12.4792 7.85208 12.0875 8.24375C11.6958 8.63542 11.5 9.10625 11.5 9.65625C11.5 10.2062 11.6958 10.6771 12.0875 11.0687C12.4792 11.4604 12.95 11.6562 13.5 11.6562C14.05 11.6562 14.5208 11.4604 14.9125 11.0687C15.3042 10.6771 15.5 10.2062 15.5 9.65625ZM13.5 26.6562L18 22.1562L16.5 20.1562L18 18.1562L16.5 16.0312L16.5 14.8562C17.4 14.3229 18.125 13.6021 18.675 12.6938C19.225 11.7854 19.5 10.7729 19.5 9.65625C19.5 7.98958 18.9167 6.57292 17.75 5.40625C16.5833 4.23958 15.1667 3.65625 13.5 3.65625C11.8333 3.65625 10.4167 4.23958 9.25 5.40625C8.08333 6.57292 7.5 7.98958 7.5 9.65625C7.5 10.7729 7.775 11.7854 8.325 12.6937C8.875 13.6021 9.6 14.3229 10.5 14.8562L10.5 23.6562L13.5 26.6562ZM17.5 9.65625C17.5 10.5896 17.2167 11.4104 16.65 12.1188C16.0833 12.8271 15.3667 13.2979 14.5 13.5312L14.5 16.6562L15.525 18.1062L14 20.1562L15.375 21.9312L13.5 23.8062L12.5 22.8062L12.5 13.5312C11.6333 13.2979 10.9167 12.8271 10.35 12.1187C9.78333 11.4104 9.5 10.5896 9.5 9.65625C9.5 8.55625 9.89167 7.61458 10.675 6.83125C11.4583 6.04792 12.4 5.65625 13.5 5.65625C14.6 5.65625 15.5417 6.04792 16.325 6.83125C17.1083 7.61458 17.5 8.55625 17.5 9.65625Z"
					fill="white"
				/>
			</g>
		</svg>
	);
};

export default KeyIcon;
