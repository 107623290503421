import { FC, useEffect, useRef, useState } from 'react';
import styles from './QuestionEditor.module.css';
import { cn } from '@/lib/utils';
import { QuestionEditorProps } from '@/lib/types/constants';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import AiIcon from '../ui/icons/AiIcon';

const QuestionEditor: FC<QuestionEditorProps> = ({
	placeholder,
	isEditable,
	isStreaming,
	value = '',
	onChange,
	onCancel,
	onSave,
	onEditEditor,
	handleSuggestResponse,
	generatingResponse = false,
	canSuggest = false,
	className,
	isSaveDisabled,
	onPaste,
}) => {
	const editorRef = useRef<HTMLDivElement>(null);
	const [contentValue, setContentValue] = useState<string>(value);

	const handlePaste = (e) => {
		e.preventDefault(); // Prevent the default paste behavior
		const text = e.clipboardData.getData('text/plain'); // Get plain text from clipboard
		// Insert the plain text into the content editable div
		document.execCommand('insertText', false, text);
		onPaste(e);
	};

	useEffect(() => {
		if (isStreaming == true) {
			setContentValue(value);
			editorRef.current!.textContent = value;
		}
	}, [isStreaming, value]);

	useEffect(() => {
		if (isEditable === false) {
			setContentValue(value);
			editorRef.current!.textContent = value;
		}
	}, [isEditable]);

	return (
		<>
			<div
				ref={editorRef}
				title={placeholder}
				onChange={(e) => onChange(e)}
				className={cn(styles.customEditor, className)}
				contentEditable={isEditable}
				suppressContentEditableWarning={true}
				onInput={(e) => onChange(e)}
				onPaste={(e) => handlePaste(e)}
			>
				{contentValue}
			</div>
			<div className="w-full flex justify-end gap-4">
				{isEditable && canSuggest && (
					<button
						className={cn(
							'flex text-xs text-primary gap-1 leading-normal items-center font-bold',
							generatingResponse
								? 'cursor-ga opacity-50'
								: 'cursor-pointer animated-hover-effect'
						)}
						disabled={generatingResponse}
						onClick={handleSuggestResponse}
					>
						<AiIcon className="h-6" />
						SUGGEST A REPONSE TO THIS
					</button>
				)}
				{isEditable && (
					<>
						<PrimaryButton
							onClick={onSave}
							disabled={generatingResponse || isSaveDisabled || !value}
						>
							Save
						</PrimaryButton>
						<SecondaryButton onClick={onCancel} disabled={generatingResponse}>
							Cancel
						</SecondaryButton>
					</>
				)}
				{!isEditable && (
					<PrimaryButton
						disabled={generatingResponse}
						onClick={() => {
							onEditEditor && onEditEditor();
						}}
					>
						Edit
					</PrimaryButton>
				)}
			</div>
		</>
	);
};

export default QuestionEditor;
