import {
	Page,
	Text,
	View,
	Document,
	Font,
	StyleSheet,
} from '@react-pdf/renderer';
import { FC } from 'react';
import FontRobotoRegular from '/fonts/Roboto-Regular.ttf';
import FontRobotoBold from '/fonts/Roboto-Bold.ttf';
import { QuestionBuilderItem } from '@/lib/types/apiTypes';

Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: FontRobotoRegular,
		},
		{
			src: FontRobotoBold,
			fontWeight: 'bold',
		},
	],
});

interface QuestionBuilderTemplateProps {
	questionsList: QuestionBuilderItem[];
}

// Create styles
const styles = StyleSheet.create({
	page: {
		padding: 24,
		flexDirection: 'column',
		backgroundColor: '#EEF2F9',
		fontFamily: 'Roboto',
		color: '#5D6F79',
	},
	title: {
		fontSize: 14,
		fontWeight: 'bold',
	},
	section: {
		marginTop: 12,
		padding: 12,
		borderRadius: 12,
		backgroundColor: '#FFFFFF',
	},
	sectionHeading: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		paddingBottom: 12,
	},
	heading: {
		fontSize: 12,
		fontWeight: 'semibold',
	},
	subInfo: {
		display: 'flex',
		flexDirection: 'row',
	},
	text: {
		fontSize: 12,
	},
	subText: {
		fontSize: 10,
		fontWeight: 'normal',
	},
	subTextBold: {
		fontSize: 10,
		fontWeight: 'semibold',
	},
	secondaryFont: {
		color: '#E861C9',
	},
});

// Create Document Component
const QuestionBuilderTemplate: FC<QuestionBuilderTemplateProps> = ({
	questionsList,
}) => {
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Text style={styles.title}>Question Builder Template</Text>
				<View>
					{questionsList.map((questions, index) => (
						<View style={styles.section} key={index}>
							<View style={styles.sectionHeading}>
								<Text style={styles.heading}>Question</Text>
								<View style={styles.subInfo}>
									<Text style={styles.subTextBold}>Word Count: </Text>
									<Text style={styles.subText}>{questions.wordCount}</Text>
								</View>
							</View>
							<Text style={styles.text}>{questions.text}</Text>
						</View>
					))}
				</View>
			</Page>
		</Document>
	);
};

export default QuestionBuilderTemplate;
