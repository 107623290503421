import { FC } from 'react';
import styles from './ProfileImages.module.css';
import { cn } from '@/lib/utils';
import useUser from '@/lib/hooks/useUser';
import FallbackAvatar from '@/components/FallbackAvatar/FallbackAvatar';
import { Avatar, AvatarImage } from '@/components/ui/avatar';

interface ProfileImageProps {
	className?: string;
	onClick?: () => void;
}

const ProfileImage: FC<ProfileImageProps> = ({ className }) => {
	const user = useUser();

	return (
		<div className={cn(styles.box, 'p-1 pointer-events-none', className)}>
			<Avatar className="w-[32px] h-[32px]">
				<AvatarImage
					height={32}
					width={32}
					alt={user?.name || ''}
					src={user?.profilePicture || ''}
				/>
				<FallbackAvatar
					user={user}
					className="dynamic-medium w-8 h-8"
				></FallbackAvatar>
			</Avatar>
		</div>
	);
};

export default ProfileImage;
