import CKEditors from '@/components/CKEditor/CKEditors';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import Ai from '@/components/ui/icons/Ai';
import Replace from '@/components/ui/icons/new/Replace';
import { Input } from '@/components/ui/input';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { MagicContext } from '@/lib/context/MagicProvider';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import useProposalId from '@/lib/hooks/useProposalId';
import { triggerConcise } from '@/lib/observables/observables';
import { MAGIC_TYPES } from '@/lib/types/apiTypes';
import { closeDialog, startStreaming } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

interface ConciseDialogProps {}

const ConciseDialog: FC<ConciseDialogProps> = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'openMagicConcise'
	);
	const editorRef = useRef<any>(null);
	const { activeSection, updateActiveSection } = useActiveSection();
	const [enableAutoSave, setEnableAutoSave] = useState(true);
	const [currentWordCount, setCurrentWordCount] = useState(0);
	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);
	const handleMagic = useContext(MagicContext);
	const proposalID = useProposalId();

	const [wordCount, setWordCount] = useState<string>('');
	const [enablePreview, setEnablePreview] = useState(false);

	const dispatch = useAppDispatch();

	const dataRef = useRef('');

	const editorText = useMemo(() => {
		if (!editorRef.current) return '';
		return editorRef.current.getData();
	}, [editorRef]);

	const selectedTextWordCount = useMemo(
		() => activeSection.editorSelectedText?.split(/\s+/).length || 0,
		[activeSection.editorSelectedText]
	);

	const expectedOutput = 'text';

	useEffect(() => {
		setEnablePreview(false);
		setWordCount('');
		setCurrentWordCount(0);
	}, []);

	const handleConcise = () => {
		setEnablePreview(true);
		if (
			proposalID &&
			handleMagic &&
			isOpen &&
			activeSection.editorSelectedText
		) {
			setEnableAutoSave(false);
			editorRef.current = '';
			dispatch(startStreaming());
			const payload: { query: string; wordCount?: number } = {
				query: activeSection.editorSelectedText as string,
			};
			if (wordCount) {
				payload.wordCount = Number(wordCount);
			}
			handleMagic(
				proposalID,
				String(activeSection.sectionId),
				dataRef,
				editorRef,
				expectedOutput,
				MAGIC_TYPES.CONCISE,
				payload
			);
			setEnableAutoSave(true);
		}
	};

	const handleInsertText = () => {
		// remove html tags
		dispatch(closeDialog());
		if (!activeSection.editorRef) return;
		const editor = activeSection.editorRef.current;
		if (!editorRef.current.getData()) return;

		// let modelText = '';
		const editorData = editorRef.current.getData();
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = editorData;
		const modelText = tempDiv.innerText || '';

		if (editor) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			editor.model.change((writer) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const selection = editor.model.document.selection;
				if (selection) {
					const ranges = Array.from(selection.getRanges());
					for (const range of ranges) {
						writer.remove(range);
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						writer.insertText(modelText, range.start);
					}
				}
			});
		}

		updateActiveSection({
			editorSelectedText: '',
		});
		triggerConcise();
		clearChange();
	};

	const clearChange = () => {
		dispatch(closeDialog());
		setEnablePreview(false);
		setWordCount('');
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-3xl px-4 py-6 bg-white rounded-2xl"
				IsMagic
				hideCloseBtn
			>
				<DialogHeader>
					<div className="flex items-center gap-1 px-2">
						<Ai className="w-8 h-8" />
						<h1 className="text-[#5D6F79] font-bold dynamic-medium">
							Here is the Concise version of the selected text
						</h1>
					</div>
				</DialogHeader>
				<div className="max-h-[600px] overflow-y-scroll px-3">
					<Accordion type="single" collapsible>
						<AccordionItem
							value="item-1"
							className="text-[#5D6F79] font-bold bg-[#eaf1fc] p-1 !border-none  rounded-lg"
						>
							<AccordionTrigger className="text-primary font-bold bg-[#eaf1fc] [data-state=closed]:!border-none p-2 hover:no-underline ">
								Selected Text
							</AccordionTrigger>
							<AccordionContent className="text-[#5D6F79] font-normal p-2 py-3 border-[#eaf1fc] bg-[#eaf1fc] ">
								{activeSection.editorSelectedText}
							</AccordionContent>
						</AccordionItem>
					</Accordion>
					{enablePreview ? (
						<div className="p-3 rounded-lg bg-[#F5F5F5] mt-4 relative z-10">
							<div className="flex items-center justify-between">
								<p className="text-[#5D6F79] font-bold mb-2">Preview</p>
							</div>
							<CKEditors
								onWordCountChange={setCurrentWordCount}
								placeholder={'Waiting for AI to generate concise text...'}
								editorState={editorText}
								setIsEditorFocused={() => {}}
								editorRef={editorRef}
								isStreaming={isStreaming}
								proposalId={proposalID as number}
								sectionId={activeSection.sectionId as number}
								setIsSaving={() => {}}
								onReady={() => {}}
								stopAutoSave={enableAutoSave}
							/>
							<div className="flex items-center text-[#b4b4b4] text-base my-2">
								<div
									className={
										'flex-1 h-[1px] mr-8 bg-[#D3DDE2] animated-container-transition '
									}
								>
									<span
										className={
											'relative block rounded-full top-[-1.5px] w-1 h-1 bg-[#D3DDE2] animated-container-transition '
										}
									></span>
								</div>
								<div className={'flex items-center gap-2  shrink-0'}>
									<div>{currentWordCount} Words</div>
								</div>
							</div>
							<div className="absolute inset-0 z-20"></div>
						</div>
					) : (
						<div className="my-4">
							<label className="block mb-2 font-bold text-black">
								Aim for word count
								<span className="ml-1 text-secondary">(optional)</span>
							</label>
							<Input
								type="number"
								value={wordCount}
								onChange={(e) => {
									setWordCount(e.target.value);
								}}
								className="w-full border border-[#D3DDE2] rounded-lg h-12 bg-white"
								placeholder="Enter Word Count"
							/>
							<small className="flex justify-end mt-1 text-black">
								The AI will aim to come close to the word count defined here but
								may exceed it slightly
							</small>
						</div>
					)}

					<div className="flex items-center justify-end w-full gap-3 pt-4 bg-white">
						{enablePreview && (
							<TextButton
								disabled={isStreaming}
								onClick={() => {
									setEnablePreview(!enablePreview);
									setWordCount('');
									setCurrentWordCount(0);
								}}
							>
								Back
							</TextButton>
						)}
						<SecondaryButton onClick={clearChange} disabled={isStreaming}>
							Cancel
						</SecondaryButton>
						{enablePreview ? (
							<PrimaryButton disabled={isStreaming} onClick={handleInsertText}>
								<Replace />
								replace selected text
							</PrimaryButton>
						) : (
							<PrimaryButton
								onClick={handleConcise}
								disabled={Number(wordCount) > selectedTextWordCount}
							>
								Concise
							</PrimaryButton>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ConciseDialog;
