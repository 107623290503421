const DecDocumentIcon = ({ ...props }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M4.07787 1.00289H13.7097C13.925 1.00289 14.1314 1.08838 14.2836 1.24057C14.4357 1.39275 14.5212 1.59916 14.5212 1.81438V18.1885C14.5212 18.4037 14.4357 18.6101 14.2836 18.7623C14.1314 18.9145 13.925 19 13.7097 19H1.95212C1.7369 19 1.53049 18.9145 1.37831 18.7623C1.22613 18.6101 1.14063 18.4037 1.14063 18.1885V3.93653C1.14029 3.82939 1.16114 3.72325 1.202 3.62422C1.24285 3.52518 1.30289 3.4352 1.37867 3.35946L3.50081 1.23732C3.57661 1.16168 3.66661 1.10177 3.76564 1.06104C3.86468 1.02031 3.97079 0.999571 4.07787 1.00001V1.00289Z"
				fill={props.mode === 'hovered' ? '#5D9BFD' : 'white'}
				stroke={props.mode === 'hovered' ? 'white' : '#5D9BFD'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				width="14"
				height="5.38462"
				transform="translate(1 8)"
				fill={props.mode === 'hovered' ? 'white' : '#5D9BFD'}
			/>
			<path
				d="M11.6164 11.3378H12.2777C12.2645 11.563 12.2048 11.763 12.0986 11.9376C11.9939 12.1122 11.8472 12.2486 11.6584 12.3466C11.4712 12.4447 11.2456 12.4937 10.9816 12.4937C10.7752 12.4937 10.5901 12.4569 10.4265 12.3834C10.2628 12.3083 10.1227 12.2011 10.0062 12.0617C9.89122 11.9223 9.80348 11.7538 9.74303 11.5561C9.68257 11.3585 9.65234 11.1372 9.65234 10.892V10.66C9.65234 10.4148 9.68331 10.1935 9.74524 9.99586C9.80864 9.7967 9.89859 9.62742 10.0151 9.48802C10.133 9.34861 10.2739 9.24137 10.4375 9.16631C10.6012 9.09124 10.784 9.05371 10.9861 9.05371C11.2544 9.05371 11.4808 9.10427 11.6651 9.20537C11.8509 9.30648 11.9946 9.44589 12.0964 9.62359C12.1996 9.8013 12.2615 10.0035 12.2822 10.2302H11.6186C11.6113 10.0954 11.5855 9.9813 11.5412 9.88785C11.497 9.79287 11.4299 9.72164 11.3399 9.67415C11.2515 9.62513 11.1335 9.60061 10.9861 9.60061C10.8755 9.60061 10.7789 9.62206 10.6963 9.66496C10.6137 9.70785 10.5444 9.77296 10.4884 9.86028C10.4324 9.9476 10.3903 10.0579 10.3623 10.1912C10.3358 10.3229 10.3225 10.4777 10.3225 10.6554V10.892C10.3225 11.0652 10.3351 11.2176 10.3601 11.3493C10.3852 11.4795 10.4235 11.5898 10.4751 11.6802C10.5282 11.7691 10.596 11.8365 10.6786 11.8824C10.7627 11.9269 10.8637 11.9491 10.9816 11.9491C11.1202 11.9491 11.2345 11.9261 11.3245 11.8801C11.4144 11.8342 11.483 11.766 11.5302 11.6756C11.5788 11.5852 11.6076 11.4726 11.6164 11.3378Z"
				fill={props.mode === 'hovered' ? '#5D9BFD' : 'white'}
			/>
			<path
				d="M9.36405 11.9096V12.4473H7.64991V11.9096H9.36405ZM7.86666 9.10156V12.4473H7.20312V9.10156H7.86666ZM9.14066 10.4642V10.9881H7.64991V10.4642H9.14066ZM9.36184 9.10156V9.64157H7.64991V9.10156H9.36184Z"
				fill={props.mode === 'hovered' ? '#5D9BFD' : 'white'}
			/>
			<path
				d="M5.24649 12.4473H4.54535L4.54977 11.9096H5.24649C5.42048 11.9096 5.5672 11.869 5.68663 11.7878C5.80607 11.7051 5.89602 11.5848 5.95647 11.4271C6.0184 11.2693 6.04937 11.0785 6.04937 10.8549V10.6917C6.04937 10.5201 6.03167 10.3692 5.99628 10.239C5.96237 10.1088 5.9115 9.99928 5.84367 9.91043C5.77584 9.82158 5.69253 9.75494 5.59374 9.71051C5.49494 9.66455 5.38141 9.64157 5.25312 9.64157H4.53208V9.10156H5.25312C5.4684 9.10156 5.66525 9.13986 5.84367 9.21646C6.02356 9.29152 6.17913 9.39953 6.31036 9.54046C6.44159 9.6814 6.5426 9.84992 6.61338 10.046C6.68563 10.2406 6.72175 10.4573 6.72175 10.6963V10.8549C6.72175 11.0923 6.68563 11.3091 6.61338 11.5052C6.5426 11.7013 6.44159 11.8698 6.31036 12.0107C6.1806 12.1501 6.02504 12.2581 5.84367 12.3347C5.66378 12.4098 5.46472 12.4473 5.24649 12.4473ZM4.92135 9.10156V12.4473H4.25781V9.10156H4.92135Z"
				fill={props.mode === 'hovered' ? '#5D9BFD' : 'white'}
			/>
			<path
				d="M1.59375 3.95436H4.15879C4.21019 3.95445 4.26111 3.94441 4.30862 3.92481C4.35614 3.9052 4.39932 3.87641 4.4357 3.8401C4.47208 3.80379 4.50095 3.76065 4.52064 3.71317C4.54033 3.66569 4.55047 3.6148 4.55047 3.5634V1.01855"
				fill={props.mode === 'hovered' ? '#5D9BFD' : 'white'}
			/>
			<path
				d="M1.59375 3.95436H4.15879C4.21019 3.95445 4.26111 3.94441 4.30862 3.92481C4.35614 3.9052 4.39932 3.87641 4.4357 3.8401C4.47209 3.80379 4.50095 3.76065 4.52064 3.71317C4.54033 3.66569 4.55047 3.6148 4.55047 3.5634V1.01855"
				stroke={props.mode === 'hovered' ? 'white' : '#5D9BFD'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default DecDocumentIcon;
