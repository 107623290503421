import ErrorPage from '@/pages/ErrorPage/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
const ErrorBound = ({ children }) => {
	return (
		<ErrorBoundary
			FallbackComponent={ErrorPage}
			onReset={() => {
				// Reset the state of your app so the error doesn't happen again
			}}
		>
			{children}
		</ErrorBoundary>
	);
};
export default ErrorBound;
