const BrainstormImage = () => {
	return (
		<svg
			width="330"
			height="220"
			viewBox="0 0 330 220"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2751_6881)">
				<path
					d="M312.859 216.65H292.289C288.359 216.65 285.069 213.65 284.719 209.73L279.959 157.49H325.189L320.429 209.73C320.069 213.65 316.789 216.65 312.859 216.65Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M325.408 194.22H279.728L277.568 174.12H327.578L325.408 194.22Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.87"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M317.949 151.109H308.279V156.389H317.949V151.109Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M279.728 152.98H325.408L327.578 159.75H277.568L279.728 152.98Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.31"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M247.44 60.3594H22.8599C19.8599 60.3594 17.4199 62.7894 17.4199 65.7994V199.219C17.4199 202.219 19.8499 204.659 22.8599 204.659H247.44C250.44 204.659 252.88 202.229 252.88 199.219V65.7994C252.88 62.7994 250.45 60.3594 247.44 60.3594Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M265.35 203.58H8.11023C5.01023 203.58 2.49023 206.09 2.49023 209.2V211.78C2.49023 214.88 5.01023 217.4 8.11023 217.4H265.36C268.46 217.4 270.98 214.88 270.98 211.78V209.2C270.98 206.1 268.46 203.58 265.36 203.58H265.35Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M25.8418 206.82H245.872"
					stroke="#AFC1DB"
					strokeWidth="6.69"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M236.333 95.2793L170.653 95.3593C167.733 95.3593 165.373 97.7293 165.373 100.649L165.393 113.229C165.393 116.149 167.763 118.509 170.683 118.509L236.363 118.429C239.283 118.429 241.653 116.059 241.643 113.139V100.559C241.623 97.6393 239.253 95.2793 236.333 95.2793Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M177.107 104.08H226.347"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M177.029 110.391H202.659"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M236.333 165.311L170.653 165.391C167.733 165.391 165.373 167.761 165.373 170.681L165.393 183.261C165.393 186.181 167.763 188.541 170.683 188.541L236.363 188.461C239.283 188.461 241.653 186.091 241.643 183.171V170.591C241.623 167.671 239.253 165.311 236.333 165.311Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M177.107 174.1H195.227"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M177.029 180.41H220.829"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M235.391 126.95L150.771 127.05C147.011 127.05 143.971 130.11 143.971 133.87L143.991 150.09C143.991 153.85 147.051 156.9 150.811 156.89L235.431 156.79C239.191 156.79 242.231 153.73 242.231 149.97L242.211 133.75C242.211 129.99 239.151 126.94 235.391 126.95Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M156.9 136.7L229.33 136.64"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M157.631 146.76L210.411 146.45"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M121.921 41.8906L45.261 41.9806C39.331 41.9806 34.531 46.8006 34.541 52.7206L34.671 162.191C34.671 168.121 39.491 172.921 45.411 172.911L122.071 172.821C128.001 172.821 132.801 168.001 132.791 162.081L132.661 52.6106C132.661 46.6806 127.841 41.8806 121.911 41.8906H121.921Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M50.209 71.6899L93.819 71.6299"
					stroke="#AFC1DB"
					strokeWidth="3.79"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M50.2285 89.7002L109.369 89.4902"
					stroke="#AFC1DB"
					strokeWidth="3.81"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M50.2285 107.51L104.119 107.23"
					stroke="#AFC1DB"
					strokeWidth="3.81"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M51.5127 54.4193L56.1527 56.9493L51.5127 59.4893L48.9827 64.1293L46.4427 59.4893L41.8027 56.9493L46.4427 54.4193L48.9827 49.7793L51.5127 54.4193Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.39"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M33.0277 31.8001L40.9577 36.1401L33.0277 40.4801L28.6877 48.4101L24.3577 40.4801L16.4277 36.1401L24.3577 31.8001L28.6877 23.8701L33.0277 31.8001Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M301.503 97.6504C304.803 98.8304 306.443 102.73 306.223 106.23C306.003 109.73 304.353 112.95 302.883 116.12C301.413 119.29 300.053 122.72 300.463 126.2C300.813 129.18 302.423 131.85 303.323 134.71C304.223 137.57 304.223 141.14 301.933 143.09"
					stroke="white"
					strokeWidth="3.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M314.851 113.3C316.941 115.37 316.191 118.91 314.831 121.51C313.471 124.11 311.581 126.72 311.801 129.65C311.951 131.65 313.091 133.43 313.711 135.34C314.331 137.25 314.251 139.72 312.581 140.83"
					stroke="white"
					strokeWidth="3.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M291.672 116.83C289.742 117.27 288.502 119.29 288.372 121.26C288.242 123.23 288.992 125.15 289.852 126.93C290.712 128.71 291.692 130.46 292.082 132.4C292.762 135.79 291.552 139.24 290.352 142.49"
					stroke="white"
					strokeWidth="3.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M57.5409 30.8005C65.7754 30.8005 72.4509 24.125 72.4509 15.8905C72.4509 7.6559 65.7754 0.980469 57.5409 0.980469C49.3063 0.980469 42.6309 7.6559 42.6309 15.8905C42.6309 24.125 49.3063 30.8005 57.5409 30.8005Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M51.9395 19.8203C51.9395 20.8103 52.8495 21.6803 54.2495 22.2203C55.1695 22.5803 56.3095 22.7903 57.5395 22.7903C58.7695 22.7903 59.9095 22.5803 60.8295 22.2203C62.2295 21.6803 63.1395 20.8103 63.1395 19.8203"
					fill="white"
				/>
				<path
					d="M51.9395 19.8203C51.9395 20.8103 52.8495 21.6803 54.2495 22.2203C55.1695 22.5803 56.3095 22.7903 57.5395 22.7903C58.7695 22.7903 59.9095 22.5803 60.8295 22.2203C62.2295 21.6803 63.1395 20.8103 63.1395 19.8203"
					stroke="#AFC1DB"
					strokeWidth="1.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M62.7087 15.8705C64.2661 15.8705 65.5287 14.0483 65.5287 11.8005C65.5287 9.55267 64.2661 7.73047 62.7087 7.73047C61.1512 7.73047 59.8887 9.55267 59.8887 11.8005C59.8887 14.0483 61.1512 15.8705 62.7087 15.8705Z"
					fill="#AFC1DB"
					stroke="white"
					strokeWidth="2.62"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M51.7009 15.8705C53.2583 15.8705 54.5209 14.0483 54.5209 11.8005C54.5209 9.55267 53.2583 7.73047 51.7009 7.73047C50.1434 7.73047 48.8809 9.55267 48.8809 11.8005C48.8809 14.0483 50.1434 15.8705 51.7009 15.8705Z"
					fill="#AFC1DB"
					stroke="white"
					strokeWidth="2.62"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2751_6881">
					<rect
						width="329.02"
						height="219.4"
						fill="white"
						transform="translate(0.490234)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default BrainstormImage;
