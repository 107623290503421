import styles from './UsersPage.module.css';
import { cn } from '@/lib/utils';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { UsersTable } from '@/components/Tables/UsersTable/UsersTable';
import { getColumns } from '@/components/Tables/UsersTable/columns';
import { getColumnsForTeamsTable } from '@/components/Tables/TeamsTable/columns';
import DocumentUploadIcon from '@/components/ui/icons/DocumentUploadIcon';
import { openDialog } from '@/redux/slices/pageSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	ApiResponse,
	UpdateUserPayload,
	UserStatus,
	InviteUserPayload,
	Team,
} from '@/lib/types/apiTypes';
import {
	getPendingRequests,
	getUsers,
	updateUser,
	inviteUser,
	cancelInvite,
	deleteUser,
	forgotPassword,
	getUsersStats,
	getTeams,
	deleteTeam,
	sendTwoFactorEmail,
} from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	getToken,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import customToast from '@/components/CustomToast/CustomToast';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { motion, useAnimation } from 'framer-motion';
import { PendingRequestsTable } from '@/components/Tables/PendingRequestsTable/PendingRequestsTable';
import { columns as pendingRequestsTableColumns } from '@/components/Tables/PendingRequestsTable/columns';
import { Search, X } from 'lucide-react';
import { Input } from '@/components/ui/input';
import useUser from '@/lib/hooks/useUser';
import ManageTeamsDrawer from '@/components/Drawers/ManageTeamsDrawer/ManageTeamsDrawer';
import { TeamsTable } from '@/components/Tables/TeamsTable/TeamsTable';
import { useQuery, useQueryClient } from 'react-query';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';

interface UsersPageProps {
	exiting?: boolean;
}

interface UsersStats {
	allowedInvites: number;
	remainingGuests: number;
	remainingInvites: number;
	remainingMembers: number;
}

const UsersPage: FC<UsersPageProps> = ({ exiting }) => {
	const user = useUser();

	const dispatch = useAppDispatch();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [searchTeamTerm, setSearchTeamTerm] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();
	const token = getToken();

	useTour(Page.UserPage);

	const handleUserUpdate = async (
		payload: UpdateUserPayload,
		userId: number
	) => {
		try {
			if (userId) {
				dispatch(processStart());
				const response = await updateUser(userId, payload);
				if (response.statusCode >= 400 && response.statusCode <= 599) {
					throw response; // Throw the response if status is 400s or 500s
				}
				if (response.statusCode === 200) {
					customToast.success({
						title: `${
							payload.isTwoFactorEnabled === true ||
							payload.isTwoFactorEnabled === false
								? '2FA'
								: payload.roleId
									? 'Role'
									: payload.status
										? 'Status'
										: 'Template builder access'
						} has been updated successfully.`,
					});
					queryClient.invalidateQueries('users');
				}
				dispatch(processSuccess());
			}
		} catch (error: unknown) {
			if ((error as any)?.response?.data) {
				ErrorHandle(dispatch, (error as any)?.response?.data);
			} else {
				ErrorHandle(dispatch, error);
			}
		}
	};

	const updateUserRole = async (roleId: number, user) => {
		handleUserUpdate({ roleId: roleId }, user.id);
	};

	const updateUserStatus = async (status: UserStatus, user) => {
		handleUserUpdate({ status: status }, user.id);
	};
	const updateTwoFactorAccess = async (isTwoFactorEnabled: boolean, user) => {
		handleUserUpdate({ isTwoFactorEnabled: isTwoFactorEnabled }, user.id);
	};

	const handleDeleteUser = async (userId: number) => {
		try {
			if (userId) {
				dispatch(processStart());
				const response = await deleteUser(userId);
				handleResponse(response, 'User has been deleted successfully.');
				queryClient.invalidateQueries('users');
				dispatch(processSuccess());
			}
		} catch (error: unknown) {
			if ((error as any)?.response?.data) {
				ErrorHandle(dispatch, (error as any)?.response?.data);
			} else {
				ErrorHandle(dispatch, error);
			}
		}
	};

	const updateUserTemplateBuilderAccess = async (
		templateBuilderAccess: boolean,
		user
	) => {
		handleUserUpdate({ templateBuilderAccess: templateBuilderAccess }, user.id);
	};

	const { data: usersStatsResponse, isLoading: isUsersStatsLoading } = useQuery(
		{
			queryKey: 'usersStats',
			queryFn: () => getUsersStats(),
			refetchOnWindowFocus: false,
		}
	);
	const usersStats = (usersStatsResponse?.data as UsersStats) || {};

	const { data: pendingRequestsResponse, isLoading: isPendingRequestsLoading } =
		useQuery({
			queryKey: 'pendingRequests',
			queryFn: () => getPendingRequests(),
			refetchOnWindowFocus: false,
		});
	const pendingRequests = (pendingRequestsResponse?.data as any) || [];

	const { data: usersResponse, isLoading: isUsersLoading } = useQuery({
		queryKey: 'users',
		queryFn: () => getUsers(token),
		refetchOnWindowFocus: false,
	});
	const users = (usersResponse?.data as any) || [];

	const { data: teamsResponse, isLoading: isTeamsLoading } = useQuery({
		queryKey: 'teams',
		queryFn: () => getTeams(),
		refetchOnWindowFocus: false,
	});
	const teams = ((teamsResponse?.data as any)?.teams as Team[]) || [];

	const openAddUserDialog = () => {
		dispatch(openDialog({ type: 'addUser' }));
	};

	const handleResendInvitation = async (email: string, roleId: number) => {
		try {
			dispatch(processStart());
			const inviteUserPayload: InviteUserPayload = {
				email: email,
				roleId: roleId,
			};
			const response: ApiResponse = await inviteUser(inviteUserPayload);
			handleResponse(response, 'Invitation has been re-sent successfully.');
			dispatch(processSuccess());
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleCancelInvitation = async (inviteId: number) => {
		try {
			setIsLoading(true);
			const response: ApiResponse = await cancelInvite(inviteId);
			handleResponse(response, 'Invitation has been cancelled successfully.');
			queryClient.invalidateQueries('pendingRequests');
			queryClient.invalidateQueries('usersStats');
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleSendTwoFactorEmail = async (userEmail: string) => {
		try {
			const response: ApiResponse = await sendTwoFactorEmail(userEmail);
			handleResponse(
				response,
				'Two-Factor Authentication email sent successfully.',
				6000
			);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleSendRecoveryEmail = async (userEmail: string) => {
		try {
			const response: ApiResponse = await forgotPassword({
				email: userEmail,
			});
			handleResponse(
				response,
				'Password recovery link sent successfully.',
				6000
			);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleSearchChange = (searchTerm: string) => {
		setSearchTerm(searchTerm);
	};

	const handleDeleteTeam = async (teamId: number) => {
		try {
			dispatch(
				openDialog({
					type: 'notification',
					notificationObj: {
						title: 'Confirm delete',
						description: 'Are you sure you want to delete this team?',
						onConfirm: async () => {
							setIsLoading(true);
							const response: ApiResponse = await deleteTeam(teamId);
							handleResponse(response, 'Teams has been deleted successfully.');
							queryClient.invalidateQueries('teams');
							setIsLoading(false);
						},
					},
				})
			);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	// Animation Handling

	const control = useAnimation();

	const startAnimationState = useMemo(() => {
		return {
			opacity: 0,
			scaleY: 0,
			translateY: '-50%',
		};
	}, []);

	const startOpeningAnimation = useMemo(() => {
		return {
			opacity: 1,
			scaleY: 1,
			translateY: 0,
			transition: {
				duration: 0.8,
				delay: 0.3,
				type: 'cubic-bezier',
				ease: [0.76, 0, 0.24, 1],
			},
		};
	}, []);

	const startExitingAnimation = useCallback(
		(exiting) => {
			if (exiting === true) {
				control.start({ opacity: 0, transition: { duration: 0.25 } });
			}
		},
		[control]
	);

	useEffect(() => {
		if (exiting) {
			startExitingAnimation(exiting);
		}
	}, [exiting, startExitingAnimation]);

	useEffect(() => {
		control.set(startAnimationState);
		control.start(startOpeningAnimation);
	}, [startOpeningAnimation]);

	return (
		<div className={cn(styles.wrapper)}>
			<div className="flex flex-col w-full gap-6 p-6 overflow-y-auto hidden-scroll ">
				<motion.div
					className="grid w-full grid-cols-12 p-6 bg-white h-fit rounded-2xl shadow-main user-information"
					animate={control}
				>
					<div className="grid grid-cols-12 col-span-6 gap-6 pr-6 border-r border-[#D3DDE2]">
						<div className="flex items-center border border-[#D3DDE2] col-span-6 justify-between rounded-xl p-4">
							<h4 className="text-black dynamic-text">
								Total Allowed Invitations
							</h4>
							<h2 className="font-bold text-secondary dynamic-xl-large">
								{usersStats?.allowedInvites || 0}
							</h2>
						</div>
						<div className="flex items-center border border-[#D3DDE2] col-span-6 justify-between rounded-xl p-4">
							<h4 className="text-black dynamic-text">Remaining Invitations</h4>
							<h2 className="font-bold text-secondary dynamic-xl-large">
								{usersStats?.remainingInvites || 0}
							</h2>
						</div>
					</div>
					<div className="grid grid-cols-12 col-span-6 gap-6 pl-6">
						<div className="flex items-center border border-[#D3DDE2] col-span-6 justify-between rounded-xl p-4">
							<h4 className="text-black dynamic-text">
								Remaining Member Seats
							</h4>
							<h2 className="font-bold text-secondary dynamic-xl-large">
								{usersStats?.remainingMembers || 0}
							</h2>
						</div>
						<div className="flex items-center border border-[#D3DDE2] col-span-6 justify-between rounded-xl p-4">
							<h4 className="text-black dynamic-text">Remaining Guest Seats</h4>
							<h2 className="font-bold text-secondary dynamic-xl-large">
								{usersStats?.remainingGuests || 0}
							</h2>
						</div>
					</div>
				</motion.div>
				{!user?.organization.isSsoEnabled && (
					<motion.div
						className="flex flex-col w-full gap-6 p-6 bg-white h-fit rounded-2xl shadow-main pending-request"
						animate={control}
					>
						<Accordion type="single" defaultValue="additional-doc" collapsible>
							<AccordionItem
								value="additional-doc"
								className="flex flex-col gap-6 border-none"
							>
								<AccordionTrigger className="p-0 hover:no-underline">
									<div className="flex justify-between">
										<h3 className="self-center font-bold text-black dynamic-large">
											Pending Requests
										</h3>
									</div>
								</AccordionTrigger>
								<AccordionContent className="shadow-main rounded-xl">
									<PendingRequestsTable
										columns={pendingRequestsTableColumns}
										data={pendingRequests}
										handleResendInvitation={handleResendInvitation}
										handleCancelInvitation={handleCancelInvitation}
									/>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					</motion.div>
				)}
				<motion.div
					className="flex flex-col w-full gap-6 p-6 bg-white h-fit rounded-2xl shadow-main team-card"
					animate={control}
				>
					<div className="flex justify-between w-full">
						<h3 className="self-center font-bold text-black dynamic-large">
							Teams
						</h3>
					</div>
					<div className="flex flex-col gap-4">
						<div className="flex justify-between">
							<div className="relative">
								<Input
									value={searchTeamTerm}
									onChange={(event) => setSearchTeamTerm(event.target.value)}
									placeholder="Search teams"
									className="bg-white border-[#D3DDE2] w-72 h-10 p-3 dynamic-small rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-12"
								/>
								<div className="absolute right-3 top-3">
									{searchTeamTerm === '' ? (
										<Search color="#5D9BFD" size={16} />
									) : (
										<X
											onClick={() => setSearchTeamTerm('')}
											className="cursor-pointer"
											color="#5D6F79"
											size={16}
										/>
									)}
								</div>
							</div>
							{users?.length && (
								<ManageTeamsDrawer
									type="create"
									users={users}
									triggerButton={
										<PrimaryButton type="button">Create new Team</PrimaryButton>
									}
								/>
							)}
						</div>
						<TeamsTable
							columns={getColumnsForTeamsTable(handleDeleteTeam, users as any)}
							data={teams.filter((team) =>
								team.name.toLowerCase().includes(searchTeamTerm.toLowerCase())
							)}
						/>
					</div>
				</motion.div>
				<motion.div
					className="flex flex-col w-full gap-6 p-6 bg-white h-fit rounded-xl shadow-main user-permission"
					animate={control}
				>
					<div className="flex justify-between ">
						<h3 className="self-center font-bold text-black dynamic-large">
							Users
						</h3>
					</div>
					<div className="flex flex-col gap-4">
						<div className="flex justify-between">
							<div className="relative">
								<Input
									value={searchTerm}
									onChange={(event) => handleSearchChange(event.target.value)}
									placeholder="Search users"
									className="bg-white border-[#D3DDE2] w-72 h-10 p-3 dynamic-small rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-12"
								/>
								<div className="absolute right-3 top-3">
									{searchTerm === '' ? (
										<Search color="#5D9BFD" size={16} />
									) : (
										<X
											onClick={() => handleSearchChange('')}
											className="cursor-pointer"
											color="#5D6F79"
											size={16}
										/>
									)}
								</div>
							</div>
							<PrimaryButton type="button" onClick={() => openAddUserDialog()}>
								<DocumentUploadIcon fill="#fff" /> INVITE NEW USER
							</PrimaryButton>
						</div>
						<UsersTable
							columns={getColumns(user)}
							data={users.filter(
								(user) =>
									user?.email
										?.toLowerCase()
										.includes(searchTerm.toLowerCase()) ||
									user?.name?.toLowerCase().includes(searchTerm.toLowerCase())
							)}
							updateUserStatus={updateUserStatus}
							updateUserRole={updateUserRole}
							updateTwoFactorAccess={updateTwoFactorAccess}
							updateUserTemplateBuilderAccess={updateUserTemplateBuilderAccess}
							sendTwoFactorEmail={handleSendTwoFactorEmail}
							handleSendRecoveryEmail={handleSendRecoveryEmail}
							deleteUser={(userId) =>
								dispatch(
									openDialog({
										type: 'notification',
										notificationObj: {
											title: 'Confirm delete',
											description: 'Are you sure you want to delete this user?',
											onConfirm: () => {
												handleDeleteUser(userId);
											},
										},
									})
								)
							}
						/>
					</div>
				</motion.div>
			</div>
			{(isUsersStatsLoading ||
				isPendingRequestsLoading ||
				isUsersLoading ||
				isTeamsLoading ||
				isLoading) && <LoaderTransparent />}
		</div>
	);
};

export default UsersPage;
