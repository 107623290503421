import {
	ApiResponse,
	Folder,
	UploadDocumentPayload,
} from '@/lib/types/apiTypes';
import { FC, useEffect, useState } from 'react';
import CategoryDropdown from '../CategoryDropdown/CategoryDropdown';
import { cloneDeep } from 'lodash';
import {
	AlertCircleIcon,
	CheckCircle2Icon,
	// ClockIcon,
	FolderIcon,
	InfoIcon,
	Loader2Icon,
	PlusCircleIcon,
} from 'lucide-react';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import { closeDialog } from '@/redux/slices/pageSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useQuery, useQueryClient } from 'react-query';
import {
	cropText,
	ErrorHandle,
	handleResponse,
	showToast,
} from '@/lib/functions/funcUtils';
import LoaderTransparent from '../Loader/LoaderTransparent/LoaderTransparent';
import {
	getFolders,
	uploadDocumentInOrganization,
} from '@/lib/functions/apiCalls';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';

interface UploadDocumentsListProps {
	documentsList: File[];
	folder?: Folder;
}

interface DocumentPayload extends UploadDocumentPayload {
	uploadStatus: 'Listed' | 'InProgress' | 'Uploaded' | 'Failed';
}

const UploadDocumentsList: FC<UploadDocumentsListProps> = ({
	documentsList,
	folder,
}) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const [docsList, setDocsList] = useState<DocumentPayload[]>([]);
	const [isUploading, setIsUploading] = useState(false);

	const folderId = folder?.id;

	const formatDocuments = (documents: File[]): DocumentPayload[] => {
		return documents.map((document) => ({
			file: document,
			name: document.name,
			type: 'text',
			category: 'Research',
			uploadStatus: 'Listed',
			folderId: folderId,
		}));
	};

	const fetchFolders = async () => {
		try {
			const response = await getFolders({
				...(folderId !== null && { parentId: folderId }),
			});
			handleResponse(response);
			return (response?.data as any)?.folders as Folder[];
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const { data: folders, isFetching: isFolderFetching } = useQuery({
		queryKey: ['folders', folderId],
		queryFn: () => fetchFolders(),
		refetchOnWindowFocus: false,
	});

	const updateSelectedCategory = (documentIndex: number, category: string) => {
		docsList[documentIndex].category = category;
		setDocsList(cloneDeep(docsList));
	};

	const updateSelectedFolder = (documentIndex: number, folderId: number) => {
		docsList[documentIndex].folderId = folderId;
		setDocsList(cloneDeep(docsList));
	};

	const findFolderName = (folderId): string => {
		return folders?.find((folder) => folder?.id === folderId)?.name || '';
	};

	const handleUploadFiles = async () => {
		if (!docsList.length) return;
		try {
			setIsUploading(true);
			await Promise.all(
				docsList.map(async (document, documentIndex) => {
					docsList[documentIndex].uploadStatus = 'InProgress';
					setDocsList(cloneDeep(docsList));

					const payload: UploadDocumentPayload = {
						file: document.file,
						name: document.name,
						type: 'text',
						category: document.category,
						...(document.folderId && { folderId: document.folderId }),
					};
					const response: ApiResponse =
						await uploadDocumentInOrganization(payload);

					if (response.statusCode >= 400 && response.statusCode <= 599) {
						docsList[documentIndex].uploadStatus = 'Failed';
						setDocsList(cloneDeep(docsList));
					} else {
						docsList[documentIndex].uploadStatus = 'Uploaded';
						setDocsList(cloneDeep(docsList));
					}
				})
			);

			const successCount = docsList.filter(
				(item) => item.uploadStatus === 'Uploaded'
			).length;
			const failedCount = docsList.filter(
				(item) => item.uploadStatus === 'Failed'
			).length;

			queryClient.invalidateQueries('documents');

			showToast(
				successCount === 0 ? 'error' : failedCount > 0 ? 'warning' : 'success',
				successCount === 0
					? `${failedCount} document(s) failed to upload. Please check the file format or try again later.`
					: failedCount > 0
						? `${successCount} document(s) has been uploaded successfully, but ${failedCount} document(s) failed to upload`
						: `${successCount} document(s) has been uploaded successfully`
			);
			dispatch(closeDialog());
			setIsUploading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsUploading(false);
		}
	};

	const showFolderName = (folderName) => {
		return (
			<TooltipProvider delayDuration={50}>
				<Tooltip>
					<TooltipTrigger className="flex text-primary gap-1 items-center leading-none font-bold uppercase dynamic-small">
						<FolderIcon size={18} />
						{cropText(folderName || '', 15)}
					</TooltipTrigger>
					{folderName?.length && folderName.length > 15 && (
						<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm p-2 rounded-lg w-60 border-none shadow-none drop-shadow-effect">
							{folderName}
						</TooltipContent>
					)}
				</Tooltip>
			</TooltipProvider>
		);
	};

	useEffect(() => {
		const docLists = formatDocuments(documentsList);
		setDocsList(docLists);
	}, [documentsList]);

	return (
		<div className="flex flex-col gap-6 h-[450px] 2xl:h-[500px]">
			<h4 className="text-black dynamic-text">
				Here are the documents you selected to be imported into Grant Assistant.
				Please make sure that the selected documents have the correct categories
				assigned. You can select categories from the drop down list provided.
			</h4>
			<div className="flex flex-col h-full gap-2 overflow-hidden">
				<div className="flex font-bold items-center text-black gap-2 pb-1">
					<FolderIcon size={18} />
					{folder?.name || 'Main Drive'}
					<TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger>
								<InfoIcon className="text-secondary cursor-pointer" size={18} />
							</TooltipTrigger>
							<TooltipContent
								className="bg-white text-black dynamic-small max-w-[450px] px-3 py-2 rounded-lg border-none shadow-none drop-shadow-effect font-normal"
								align="start"
							>
								<span className="font-bold">Destination folder:</span> This is
								where each document in the list will be saved. You can change
								the destination for each document using the dropdown.
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<div className="grid grid-cols-12 gap-4 w-full rounded-lg text-black bg-blackSecondary items-center pl-3 pr-6">
					<h4 className="flex h-8 items-center col-span-6 font-bold">
						Document Name
					</h4>
					<h4 className="flex h-8 items-center col-span-3 font-bold">
						Category
					</h4>
					<h4 className="flex h-8 items-center col-span-2 font-bold">Folder</h4>
				</div>
				<div className="flex flex-col h-full w-full text-black gap-2 overflow-y-auto pr-2">
					{docsList.map((docData, index) => {
						return (
							<div
								className="rounded-xl px-3 border border-[#D3DDE2]"
								key={index}
							>
								<div className="grid grid-cols-12 gap-4 py-3 w-full text-primary items-center">
									<h4 className="flex items-center col-span-6 font-normal text-primary text-left">
										{docData.name}
									</h4>
									<div className="flex h-8 items-center col-span-3">
										<CategoryDropdown
											className="h-auto w-fit font-normal border border-[#5D9BFD]"
											selectedCategory={docData?.category}
											setSelectedCategory={(category) =>
												updateSelectedCategory(index, category)
											}
										/>
									</div>
									<div
										className="flex col-span-2"
										onClick={(event) => event.preventDefault()}
									>
										{!folders?.length ? (
											<div className="flex text-primary">
												{showFolderName(folder?.name)}
											</div>
										) : (
											<DropdownMenu>
												<DropdownMenuTrigger
													className="flex text-primary pl-1 gap-1"
													disabled={!folders?.length}
												>
													{!!docData.folderId &&
													folderId !== docData.folderId ? (
														<>
															{showFolderName(findFolderName(docData.folderId))}
														</>
													) : folderId ? (
														<>{showFolderName(folder?.name)}</>
													) : (
														<>
															<PlusCircleIcon size={16} /> Add to this folder
														</>
													)}
												</DropdownMenuTrigger>
												<DropdownMenuContent
													className="bg-white rounded-2xl p-2 w-52 max-h-[200px] overflow-y-auto"
													sideOffset={10}
													alignOffset={-5}
													align="start"
													defaultValue={folder?.id}
												>
													{folders?.map((folder, folderIndex) => (
														<DropdownMenuCheckboxItem
															className="text-black px-2 text-sm xl:text-base cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary capitalize"
															key={folderIndex}
															onClick={() =>
																updateSelectedFolder(index, folder.id)
															}
														>
															{folder.name}
														</DropdownMenuCheckboxItem>
													))}
												</DropdownMenuContent>
											</DropdownMenu>
										)}
									</div>
									<div className="flex h-8 items-center col-span-1">
										{docData.uploadStatus === 'Uploaded' ? (
											<CheckCircle2Icon className="text-lightGreen" size={18} />
										) : docData.uploadStatus === 'InProgress' ? (
											<Loader2Icon
												className="text-primary processing-animation"
												size={18}
											/>
										) : docData.uploadStatus === 'Failed' ? (
											<AlertCircleIcon className="text-lightRed" size={18} />
										) : (
											// ) : isUploading ? (
											// 	<ClockIcon className="text-black" size={18} />
											<></>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="flex gap-4 justify-end">
				<SecondaryButton
					disabled={isUploading}
					onClick={() => {
						dispatch(closeDialog());
					}}
				>
					Cancel
				</SecondaryButton>
				<PrimaryButton
					disabled={!docsList.length || isUploading}
					onClick={() => {
						handleUploadFiles();
					}}
				>
					ADD these DOCUMENTS to grant assistant
				</PrimaryButton>
			</div>
			{isFolderFetching && <LoaderTransparent />}
		</div>
	);
};

export default UploadDocumentsList;
