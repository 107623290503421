import { SVGProps } from 'react';

const TemplateDialogIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="54"
			height="67"
			viewBox="0 0 54 67"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g id="Layer_1" clipPath="url(#clip0_9202_15897)">
				<g id="Group">
					<path
						id="Vector"
						d="M31.9664 16.3574L4.72211 16.3901C2.61742 16.3901 0.909405 18.1028 0.914072 20.2075L0.960739 59.1139C0.960739 61.2185 2.67342 62.9266 4.77811 62.9219L32.0224 62.8892C34.1271 62.8892 35.8351 61.1765 35.8304 59.0719L35.7838 20.1655C35.7838 18.0608 34.0711 16.3528 31.9664 16.3574Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="2.25447"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_2"
						d="M8.06641 25.0987L23.5646 25.0801"
						stroke="#AFC1DB"
						strokeWidth="2.17971"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_3"
						d="M11.2383 31.4965L25.3924 31.4219"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_4"
						d="M11.2383 37.7305L30.3905 37.8285"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_5"
						d="M8.06641 43.7324L27.2186 43.8304"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_6"
						d="M8.06641 49.459L20.5125 49.515"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_7"
						d="M11.4219 54.2285L23.868 54.2845"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<g id="Group_2">
					<path
						id="Vector_8"
						d="M35.2633 19.5176L8.01898 19.5503C5.9143 19.5503 4.20628 21.2629 4.21095 23.3676L4.25761 62.274C4.25761 64.3787 5.9703 66.0867 8.07498 66.0821L35.3193 66.0494C37.4239 66.0494 39.132 64.3367 39.1273 62.232L39.0806 23.3256C39.0806 21.2209 37.3679 19.5129 35.2633 19.5176Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="2.25447"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_9"
						d="M11.3594 28.2589L26.8575 28.2402"
						stroke="#AFC1DB"
						strokeWidth="2.17971"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_10"
						d="M14.5273 34.6606L28.6815 34.5859"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_11"
						d="M14.5273 40.8906L33.6795 40.9886"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_12"
						d="M11.3594 46.8926L30.5116 46.9906"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_13"
						d="M11.3594 52.6191L23.8055 52.6751"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_14"
						d="M14.7148 57.3926L27.161 57.4486"
						stroke="#AFC1DB"
						strokeWidth="2.19121"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<g id="Group_3">
					<path
						id="Vector_15"
						d="M49.3292 18.8226L52.1525 20.3626L49.3292 21.9073L47.7892 24.726L46.2491 21.9073L43.4258 20.3626L46.2491 18.8226L47.7892 16.0039L49.3292 18.8226Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="2.66281"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_16"
						d="M42.2628 5.9047L47.0835 8.54139L42.2628 11.1781L39.6261 16.0035L36.9894 11.1781L32.1641 8.54139L36.9894 5.9047L39.6261 1.08398L42.2628 5.9047Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="2.66281"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_9202_15897">
					<rect width="53.2285" height="67" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default TemplateDialogIcon;
