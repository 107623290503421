import { Step } from 'intro.js-react';
export const ProposalCreationStepThree: Step[] = [
	{
		element: '.formative-document',
		title: 'Formative documents',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					This section lets you add key documents by either uploading them or
					selecting them from your document library. These documents will be
					associated with your proposal.
				</p>
				<p>
					Remember, you can always add additional documents later and can even
					add documents more granularly to sections later in the system.
				</p>
				<p>
					<span className="font-bold">Note:</span> Please only add 4 or 5 key
					documents here.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.tone-of-voice',
		title: 'Tone of voice',
		intro:
			'This section gives you an overview of your organizations writing style. This default value is set when your administrator sets up the organization in our system.',
		position: 'left',
	},
	{
		element: '.writing-style',
		title: 'Writing style',
		intro:
			'These show you your style of writing (first person or collaborative) and the citation style that will be used for your draft.',
		position: 'left',
	},
	{
		element: '.tone-sample',
		title: 'Sample output',
		intro:
			'This is a sample output of what a paragraph written in this style will read like.',
		position: 'left',
	},
	{
		element: '.tone-edit',
		title: 'Edit tone of voice',
		intro:
			'You can customize the tone of voice for this specific proposal by clicking here.',
		position: 'top',
	},
];
