/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.min';

interface PDFViewerProps {
	pdfData: ArrayBuffer;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ pdfData }) => {
	const [pdfDataArray, setPDFDataArray] = useState<Uint8Array | null>();

	useEffect(() => {
		setPDFDataArray(null);
	}, []);

	useEffect(() => {
		// @ts-ignore
		if (pdfData?.data?.byteLength && pdfData.data.byteLength > 0) {
			// @ts-ignore
			const dataArray = new Uint8Array(pdfData.data);
			setPDFDataArray(dataArray);
		}
		// @ts-ignore
	}, [pdfData?.data?.byteLength]);

	const defaultLayoutPluginInstance = defaultLayoutPlugin();
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100%',
				width: '100%',
			}}
		>
			{pdfDataArray && (
				<Worker workerUrl={pdfjsWorker}>
					<Viewer
						fileUrl={pdfDataArray}
						plugins={[defaultLayoutPluginInstance]}
					></Viewer>
				</Worker>
			)}
		</div>
	);
};

export default PDFViewer;
