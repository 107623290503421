import { SVGProps } from 'react';

const EmptyCompliance = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="161"
			height="146"
			viewBox="0 0 161 146"
			fill="none"
		>
			<g clipPath="url(#clip0_11584_11606)">
				<path
					d="M149.019 1.64001H11.9801C6.13387 1.64001 1.39453 6.37935 1.39453 12.2256V117.637C1.39453 123.484 6.13387 128.223 11.9801 128.223H149.019C154.866 128.223 159.605 123.484 159.605 117.637V12.2256C159.605 6.37935 154.866 1.64001 149.019 1.64001Z"
					fill="#E3EEFF"
					stroke="#AFC1DB"
					strokeWidth="2.78983"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M117.491 19.549H20.1957C18.4437 19.549 17.0234 20.9692 17.0234 22.7213V41.0801C17.0234 42.8322 18.4437 44.2525 20.1957 44.2525H117.491C119.243 44.2525 120.663 42.8322 120.663 41.0801V22.7213C120.663 20.9692 119.243 19.549 117.491 19.549Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.6874"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M117.491 52.5825H20.1957C18.4437 52.5825 17.0234 54.0028 17.0234 55.7548V74.1137C17.0234 75.8657 18.4437 77.286 20.1957 77.286H117.491C119.243 77.286 120.663 75.8657 120.663 74.1137V55.7548C120.663 54.0028 119.243 52.5825 117.491 52.5825Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.6874"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M117.491 85.6162H20.1957C18.4437 85.6162 17.0234 87.0365 17.0234 88.7885V107.147C17.0234 108.899 18.4437 110.32 20.1957 110.32H117.491C119.243 110.32 120.663 108.899 120.663 107.147V88.7885C120.663 87.0365 119.243 85.6162 117.491 85.6162Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.6874"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.6758 28.329H97.5071"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.6758 35.3148H74.6372"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.2852 61.4414H85.046"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.2852 68.4271H95.5247"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.2852 94.9473H85.046"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.2852 101.939H48.1988"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M134.857 74.9631C140.396 74.9631 144.886 70.473 144.886 64.9343C144.886 59.3956 140.396 54.9055 134.857 54.9055C129.318 54.9055 124.828 59.3956 124.828 64.9343C124.828 70.473 129.318 74.9631 134.857 74.9631Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M131.762 61.8351L137.954 68.0278"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M137.954 61.8351L131.762 68.0278"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M134.857 107.997C140.396 107.997 144.886 103.506 144.886 97.9677C144.886 92.429 140.396 87.939 134.857 87.939C129.318 87.939 124.828 92.429 124.828 97.9677C124.828 103.506 129.318 107.997 134.857 107.997Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M129.754 97.287L132.381 101.888L140.368 96.3083"
					fill="white"
				/>
				<path
					d="M129.754 97.287L132.381 101.888L140.368 96.3083"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M134.857 41.9292C140.396 41.9292 144.886 37.4392 144.886 31.9005C144.886 26.3617 140.396 21.8717 134.857 21.8717C129.318 21.8717 124.828 26.3617 124.828 31.9005C124.828 37.4392 129.318 41.9292 134.857 41.9292Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M129.754 31.2198L132.381 35.8264L140.368 30.2411"
					fill="white"
				/>
				<path
					d="M129.754 31.2198L132.381 35.8264L140.368 30.2411"
					stroke="#AFC1DB"
					strokeWidth="2.20487"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M80.4998 144.602C88.9804 144.602 95.8552 137.727 95.8552 129.247C95.8552 120.766 88.9804 113.891 80.4998 113.891C72.0193 113.891 65.1445 120.766 65.1445 129.247C65.1445 137.727 72.0193 144.602 80.4998 144.602Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="2.31173"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M85.8104 127.891H81.8506V123.931C81.8506 123.183 81.2431 122.576 80.4951 122.576C79.747 122.576 79.1395 123.183 79.1395 123.931V127.891H75.1798C74.4317 127.891 73.8242 128.499 73.8242 129.247C73.8242 129.995 74.4317 130.602 75.1798 130.602H79.1395V134.562C79.1395 135.31 79.747 135.918 80.4951 135.918C81.2431 135.918 81.8506 135.31 81.8506 134.562V130.602H85.8104C86.5584 130.602 87.1659 129.995 87.1659 129.247C87.1659 128.499 86.5584 127.891 85.8104 127.891Z"
					fill="#AFC1DB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_11584_11606">
					<rect
						width="161"
						height="145.51"
						fill="white"
						transform="translate(0 0.244995)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default EmptyCompliance;
