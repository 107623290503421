const FileUploadIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_3912_1009"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_3912_1009)">
				<path
					d="M5.99988 20C5.44988 20 4.97904 19.8042 4.58738 19.4125C4.19571 19.0208 3.99988 18.55 3.99988 18V4C3.99988 3.45 4.19571 2.97917 4.58738 2.5875C4.97904 2.19583 5.44988 2 5.99988 2H12.1749C12.4415 2 12.6957 2.05 12.9374 2.15C13.179 2.25 13.3915 2.39167 13.5749 2.575L18.4249 7.425C18.6082 7.60833 18.7499 7.82083 18.8499 8.0625C18.9499 8.30417 18.9999 8.55833 18.9999 8.825V10.025C18.9999 10.3083 18.904 10.5458 18.7124 10.7375C18.5207 10.9292 18.2832 11.025 17.9999 11.025C17.7165 11.025 17.479 10.9292 17.2874 10.7375C17.0957 10.5458 16.9999 10.3083 16.9999 10.025V9H13.4999C13.0832 9 12.729 8.85417 12.4374 8.5625C12.1457 8.27083 11.9999 7.91667 11.9999 7.5V4H5.99988V18H10.9999C11.2832 18 11.5207 18.0958 11.7124 18.2875C11.904 18.4792 11.9999 18.7167 11.9999 19C11.9999 19.2833 11.904 19.5208 11.7124 19.7125C11.5207 19.9042 11.2832 20 10.9999 20H5.99988Z"
					fill="white"
				/>
				<path
					d="M16.0997 20.725L16.9997 19.825V23C16.9997 23.2833 17.0956 23.5208 17.2872 23.7125C17.4789 23.9042 17.7164 24 17.9997 24C18.2831 24 18.5206 23.9042 18.7122 23.7125C18.9039 23.5208 18.9997 23.2833 18.9997 23V19.825L19.8997 20.725C20.0831 20.9083 20.3164 21 20.5997 21C20.8831 21 21.1164 20.9083 21.2997 20.725C21.4831 20.5417 21.5747 20.3083 21.5747 20.025C21.5747 19.7417 21.4831 19.5083 21.2997 19.325L18.6997 16.725C18.4997 16.525 18.2664 16.425 17.9997 16.425C17.7331 16.425 17.4997 16.525 17.2997 16.725L14.6997 19.325C14.5164 19.5083 14.4247 19.7417 14.4247 20.025C14.4247 20.3083 14.5164 20.5417 14.6997 20.725C14.8831 20.9083 15.1164 21 15.3997 21C15.6831 21 15.9164 20.9083 16.0997 20.725Z"
					fill="white"
				/>
				<path
					d="M20.9997 15.025H14.9997C14.7164 15.025 14.4789 14.9292 14.2872 14.7375C14.0956 14.5458 13.9997 14.3083 13.9997 14.025C13.9997 13.7417 14.0956 13.5042 14.2872 13.3125C14.4789 13.1208 14.7164 13.025 14.9997 13.025H20.9997C21.2831 13.025 21.5206 13.1208 21.7122 13.3125C21.9039 13.5042 21.9997 13.7417 21.9997 14.025C21.9997 14.3083 21.9039 14.5458 21.7122 14.7375C21.5206 14.9292 21.2831 15.025 20.9997 15.025Z"
					fill="white"
				/>
			</g>
		</svg>
	);
};

export default FileUploadIcon;
