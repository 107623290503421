import React, {
	createContext,
	useState,
	ReactNode,
	MutableRefObject,
} from 'react';

interface ActiveSectionState {
	sectionId: number | null;
	sectionName: string | null;
	editorRef: MutableRefObject<any> | null;
	prefix: string | null;
	editorSelectedText?: string | null;
	editorText?: string | null;
	editorHTML?: string | null;
	sectionType?: string | null;
}

interface ActiveSectionContextType {
	activeSection: ActiveSectionState;
	updateActiveSection: (update: Partial<ActiveSectionState>) => void;
	updateActiveSectionContent: (content: string, html: string) => void;
	resetActiveSection: () => void;
}

const defaultState: ActiveSectionState = {
	sectionId: null,
	editorRef: null,
	prefix: null,
	sectionName: null,
	editorSelectedText: null,
	editorText: null,
	editorHTML: null,
	sectionType: null,
};

export const ActiveSectionContext = createContext<ActiveSectionContextType>({
	activeSection: defaultState,
	updateActiveSection: () => {},
	updateActiveSectionContent: () => {},
	resetActiveSection: () => {},
});

// Custom hook for using the active section context

// Provider component
interface ActiveSectionProviderProps {
	children: ReactNode;
}

export const ActiveSectionProvider: React.FC<ActiveSectionProviderProps> = ({
	children,
}) => {
	const [activeSection, setActiveSection] =
		useState<ActiveSectionState>(defaultState);

	const updateActiveSection = (update: Partial<ActiveSectionState>) => {
		setActiveSection((prev) => ({ ...prev, ...update }));
	};

	const updateActiveSectionContent = (content: string, html: string) => {
		setActiveSection((prev) => ({
			...prev,
			editorText: content,
			editorHTML: html,
		}));
	};

	const resetActiveSection = () => {
		setActiveSection(defaultState);
	};

	const contextValue = {
		activeSection,
		updateActiveSection,
		updateActiveSectionContent,
		resetActiveSection,
	};

	return (
		<ActiveSectionContext.Provider value={contextValue}>
			{children}
		</ActiveSectionContext.Provider>
	);
};
