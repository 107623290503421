const More = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="20"
			viewBox="0 0 14 20"
			fill="none"
		>
			<mask
				id="mask0_1298_2415"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="14"
				height="20"
			>
				<path
					d="M13.2606 0.792969H0.730469V19.316H13.2606V0.792969Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_1298_2415)">
				<path
					d="M8.91914 14.3643H5.07225C4.80963 14.3643 4.55776 14.4685 4.37206 14.654C4.18636 14.8395 4.08203 15.0912 4.08203 15.3535C4.08203 15.6159 4.18636 15.8675 4.37206 16.0531C4.55776 16.2386 4.80963 16.3428 5.07225 16.3428H8.9199C9.18252 16.3428 9.43439 16.2386 9.62009 16.0531C9.80579 15.8675 9.91012 15.6159 9.91012 15.3535C9.91012 15.0912 9.80579 14.8395 9.62009 14.654C9.43439 14.4685 9.18176 14.3643 8.91914 14.3643Z"
					fill="#5D9BFD"
				/>
				<path
					d="M7.95435 17.3369H6.03319C5.77057 17.3369 5.5187 17.4411 5.333 17.6267C5.1473 17.8122 5.04297 18.0638 5.04297 18.3262C5.04297 18.5886 5.1473 18.8402 5.333 19.0257C5.5187 19.2112 5.77057 19.3155 6.03319 19.3155H7.95435C8.21698 19.3155 8.46884 19.2112 8.65454 19.0257C8.84025 18.8402 8.94457 18.5886 8.94457 18.3262C8.94457 18.0638 8.84025 17.8122 8.65454 17.6267C8.46884 17.4411 8.21698 17.3369 7.95435 17.3369Z"
					fill="#5D9BFD"
				/>
				<path
					d="M6.99512 0.792969C5.34012 0.793553 3.75268 1.44882 2.57993 2.61548C1.40717 3.78213 0.744532 5.36526 0.736898 7.01867C0.693981 7.92738 0.866394 8.83337 1.24011 9.66294C1.61382 10.4925 2.17828 11.2222 2.88757 11.7927C3.17201 12.0072 3.40815 12.2791 3.58065 12.5907C3.65543 12.7999 3.79395 12.9804 3.97674 13.107C4.15953 13.2335 4.3774 13.2996 4.59974 13.296C4.77405 13.2957 4.94572 13.2535 5.10025 13.1729C5.25479 13.0924 5.38764 12.9758 5.48759 12.8332C5.58753 12.6905 5.65164 12.5259 5.67449 12.3533C5.69733 12.1806 5.67825 12.005 5.61884 11.8413C5.33311 11.1843 4.88679 10.6094 4.32084 10.1695C3.84601 9.80526 3.4684 9.32968 3.22145 8.78486C2.9745 8.24004 2.86582 7.64279 2.90505 7.046C2.89989 6.5047 3.0024 5.96777 3.20663 5.46637C3.41086 4.96497 3.71274 4.50909 4.09475 4.12519C4.47675 3.74129 4.93128 3.43702 5.43195 3.23003C5.93261 3.02304 6.46944 2.91746 7.01127 2.91943C7.55311 2.92139 8.08916 3.03085 8.58831 3.24145C9.08746 3.45206 9.53977 3.75961 9.91898 4.14627C10.2982 4.53292 10.5968 4.99098 10.7973 5.49384C10.9979 5.9967 11.0966 6.53436 11.0875 7.07561C11.1219 7.66814 11.0102 8.26004 10.7621 8.79935C10.5141 9.33866 10.1373 9.80888 9.66484 10.1687L9.5934 10.2325C9.05859 10.6618 8.63829 11.2166 8.36988 11.8474C8.31112 12.0114 8.2927 12.187 8.31619 12.3596C8.33968 12.5322 8.40438 12.6966 8.50483 12.8389C8.60528 12.9813 8.73853 13.0974 8.89334 13.1775C9.04815 13.2575 9.21996 13.2992 9.39429 13.299C9.61643 13.302 9.83393 13.2355 10.0163 13.1087C10.1986 12.9819 10.3367 12.8013 10.4111 12.5922C10.5751 12.2898 10.8012 12.0253 11.0746 11.8163C11.7883 11.2488 12.3578 10.521 12.7367 9.69207C13.1156 8.86312 13.2931 7.95648 13.2549 7.046C13.2528 5.38808 12.5927 3.79865 11.4192 2.62639C10.2456 1.45413 8.65462 0.794777 6.99512 0.792969Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default More;
