import { useEffect } from 'react';
import { useActiveSection } from './useActiveSection';

interface CustomEventDetail {
	message: string;
	editor: any;
}

type EventCallback = (eventDetail: CustomEventDetail) => void;

interface EventCallbacks {
	[eventName: string]: EventCallback;
}

const useCustomEvents = (events: EventCallbacks, currentSectionId: number) => {
	const { activeSection } = useActiveSection();

	useEffect(() => {
		const eventHandlers: { [key: string]: (event: Event) => void } = {};

		const addEventListeners = () => {
			Object.keys(events).forEach((eventName) => {
				const handler = (event: Event) => {
					const customEvent = event as CustomEvent<CustomEventDetail>;
					if (String(currentSectionId) === String(activeSection.sectionId)) {
						events[eventName](customEvent.detail);
					}
				};
				window.addEventListener(eventName, handler as EventListener);
				eventHandlers[eventName] = handler;
			});
		};

		const removeEventListeners = () => {
			Object.keys(eventHandlers).forEach((eventName) => {
				window.removeEventListener(
					eventName,
					eventHandlers[eventName] as EventListener
				);
				delete eventHandlers[eventName];
			});
		};

		// Only add event listeners if the current section ID matches
		if (String(currentSectionId) === String(activeSection.sectionId)) {
			addEventListeners();
		}

		return () => {
			// Remove all event listeners on cleanup
			removeEventListeners();
		};
	}, [events, currentSectionId, activeSection.sectionId]); // Dependency array
};

export default useCustomEvents;
