const Cross = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<circle cx="8" cy="8" r="8" fill="#E861C9" />
			<path
				d="M5.88672 5.7959L10.1829 10.0921"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M5.81836 10.0928L10.1145 5.79659"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Cross;
