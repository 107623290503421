const Solicited = () => {
	return (
		<svg
			width="17"
			height="20"
			viewBox="0 0 17 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.9085 19.9996H3.23599C2.37797 19.9989 1.55529 19.6562 0.948579 19.0467C0.341865 18.4372 0.000704356 17.6108 0 16.7489L0 3.25362C0 2.39149 0.340934 1.56466 0.947801 0.955036C1.55467 0.345413 2.37776 0.00292969 3.23599 0.00292969L12.9085 0.00292969C13.7665 0.00363724 14.5892 0.346347 15.1959 0.955817C15.8026 1.56529 16.1438 2.3917 16.1445 3.25362V16.7498C16.1438 17.6117 15.8026 18.4381 15.1959 19.0476C14.5892 19.6571 13.7665 19.9998 12.9085 20.0005M3.23599 1.43101C2.75505 1.43183 2.29403 1.62412 1.95395 1.96575C1.61386 2.30738 1.42244 2.77049 1.42162 3.25362V16.7498C1.42256 17.2329 1.61403 17.6958 1.9541 18.0374C2.29417 18.3789 2.75512 18.5711 3.23599 18.572H12.9085C13.3892 18.5709 13.8498 18.3786 14.1897 18.0372C14.5295 17.6958 14.7209 17.2331 14.722 16.7502V3.25362C14.7212 2.77064 14.5299 2.30767 14.19 1.96606C13.8501 1.62446 13.3893 1.43207 12.9085 1.43101H3.23599Z"
				fill="#E861C9"
			/>
			<path
				d="M11.5999 5.34071C11.5067 5.34071 11.4145 5.32228 11.3285 5.28648C11.2424 5.25067 11.1642 5.19819 11.0984 5.13203C11.0325 5.06587 10.9803 4.98733 10.9446 4.90089C10.909 4.81444 10.8906 4.7218 10.8906 4.62823V0.71404C10.8906 0.524665 10.9655 0.343046 11.0988 0.209137C11.2321 0.075229 11.4129 0 11.6014 0C11.79 0 11.9708 0.075229 12.1041 0.209137C12.2374 0.343046 12.3122 0.524665 12.3122 0.71404V3.90195L15.4259 3.8543H15.437C15.5303 3.85357 15.6229 3.87131 15.7094 3.90652C15.7959 3.94173 15.8747 3.99371 15.9412 4.0595C16.0077 4.12529 16.0607 4.20359 16.0971 4.28995C16.1335 4.3763 16.1526 4.469 16.1533 4.56277C16.1541 4.65654 16.1364 4.74954 16.1013 4.83645C16.0663 4.92336 16.0145 5.00248 15.9491 5.06931C15.8836 5.13613 15.8056 5.18934 15.7197 5.2259C15.6337 5.26246 15.5414 5.28165 15.4481 5.28238L11.6088 5.34071H11.5999Z"
				fill="#E861C9"
			/>
			<path
				d="M8.7649 8.32693H3.97253C3.78401 8.32693 3.60321 8.25167 3.46991 8.11773C3.33661 7.98378 3.26172 7.80211 3.26172 7.61268C3.26172 7.42325 3.33661 7.24158 3.46991 7.10763C3.60321 6.97369 3.78401 6.89844 3.97253 6.89844H8.7649C8.95342 6.89844 9.13422 6.97369 9.26752 7.10763C9.40083 7.24158 9.47571 7.42325 9.47571 7.61268C9.47571 7.80211 9.40083 7.98378 9.26752 8.11773C9.13422 8.25167 8.95342 8.32693 8.7649 8.32693Z"
				fill="#E861C9"
			/>
			<path
				d="M12.0512 12.197H3.97448C3.88114 12.197 3.78871 12.1786 3.70247 12.1427C3.61623 12.1068 3.53787 12.0542 3.47186 11.9878C3.40586 11.9215 3.3535 11.8428 3.31778 11.7561C3.28206 11.6695 3.26367 11.5766 3.26367 11.4828C3.26367 11.389 3.28206 11.2961 3.31778 11.2095C3.3535 11.1228 3.40586 11.0441 3.47186 10.9778C3.53787 10.9114 3.61623 10.8588 3.70247 10.8229C3.78871 10.787 3.88114 10.7686 3.97448 10.7686H12.0512C12.1445 10.7686 12.2369 10.787 12.3232 10.8229C12.4094 10.8588 12.4878 10.9114 12.5538 10.9778C12.6198 11.0441 12.6722 11.1228 12.7079 11.2095C12.7436 11.2961 12.762 11.389 12.762 11.4828C12.762 11.5766 12.7436 11.6695 12.7079 11.7561C12.6722 11.8428 12.6198 11.9215 12.5538 11.9878C12.4878 12.0542 12.4094 12.1068 12.3232 12.1427C12.2369 12.1786 12.1445 12.197 12.0512 12.197Z"
				fill="#E861C9"
			/>
			<path
				d="M12.0512 16.0633H3.97448C3.78596 16.0633 3.60517 15.988 3.47186 15.8541C3.33856 15.7201 3.26367 15.5384 3.26367 15.349C3.26367 15.1596 3.33856 14.9779 3.47186 14.844C3.60517 14.71 3.78596 14.6348 3.97448 14.6348H12.0512C12.2397 14.6348 12.4205 14.71 12.5538 14.844C12.6871 14.9779 12.762 15.1596 12.762 15.349C12.762 15.5384 12.6871 15.7201 12.5538 15.8541C12.4205 15.988 12.2397 16.0633 12.0512 16.0633Z"
				fill="#E861C9"
			/>
		</svg>
	);
};

export default Solicited;
