import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { updateNoteOnSpecificSection } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { RootState } from '@/redux/store/store';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
	ErrorHandle,
	getAllSections,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { closeDialog } from '@/redux/slices/pageSlice';
import { cn } from '@/lib/utils';
import DialogLoader from '@/components/Loader/DialogLoader/DailogLoader';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { Label } from '@/components/ui/label';
import { Replace } from 'lucide-react';

const ChangeDocumentNameDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'moveIdea'
	);
	const ideaId = useSelector((root: RootState) => root.page.dialog?.ideaId);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const proposalId = proposal?.id;
	const { activeSection } = useActiveSection();
	const sectionId = activeSection.sectionId;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedSection, setSelectedSection] = useState<string>('');
	const sections = getAllSections(proposal?.sections);

	const handleMoveSection = async () => {
		try {
			if (!proposalId || !sectionId || !ideaId || !selectedSection) return;
			setIsLoading(true);
			const response = await updateNoteOnSpecificSection(
				proposalId,
				sectionId,
				ideaId,
				{
					sectionId: Number(selectedSection),
				}
			);
			handleResponse(
				response,
				'Idea has been move to selected section successfully.'
			);
			dispatch(closeDialog());
			setIsLoading(false);
			dispatch(fetchProposalById(proposalId));
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setIsLoading(false);
		setSelectedSection('');
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] p-6 bg-white rounded-3xl">
				<DialogHeader className="flex-row font-bold text-black gap-2 items-center dynamic-xl-large">
					<Replace size={18} />
					Move to section
				</DialogHeader>
				<p className="dynamic-text text-black">
					Select the destination section
				</p>
				<div className="flex flex-col gap-3">
					<Label className="dynamic-medium">Sections</Label>
					<Select
						disabled={!sections}
						onValueChange={(section) => setSelectedSection(section)}
						value={selectedSection}
					>
						<SelectTrigger
							className={cn(
								'h-14 rounded-2xl text-sm xl:text-base border border-[#D3DDE2] bg-white text-black px-4 py-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
							)}
						>
							<SelectValue placeholder="Select section" />
						</SelectTrigger>
						<SelectContent className="py-2 bg-white rounded-2xl">
							{sections &&
								sections.map((section, index) => {
									return (
										<SelectItem
											className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
											key={index}
											value={String(section.id)}
										>
											{section.alias}
										</SelectItem>
									);
								})}
						</SelectContent>
					</Select>
				</div>
				<div className="flex items-center justify-end gap-4">
					<TextButton onClick={() => dispatch(closeDialog())}>
						Cancel
					</TextButton>
					<PrimaryButton
						disabled={selectedSection === ''}
						onClick={() => handleMoveSection()}
					>
						Move
					</PrimaryButton>
				</div>
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default ChangeDocumentNameDialog;
