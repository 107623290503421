import { FC, useState } from 'react';
import { Section } from '@/lib/types/apiTypes';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import styles from './TableContent.module.css';
import Menu from '../ui/icons/new/Menu';
import { X } from 'lucide-react';
import { setLastSection } from '@/redux/slices/pageSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useParams } from 'react-router-dom';
import { scrollToSection } from '@/lib/functions/funcUtils';
import useProposalId from '@/lib/hooks/useProposalId';

interface TableContentProps {
	sections: Section[] | undefined;
	scrollParentRef?: React.RefObject<HTMLDivElement>;
}

const TableContent: FC<TableContentProps> = ({ sections, scrollParentRef }) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { nodeId } = useParams();
	const dispatch = useAppDispatch();
	const proposalId = useProposalId();

	const setPopoverOpen = () => {
		setIsPopoverOpen(true);
	};

	const setPopoverClose = () => {
		setIsPopoverOpen(false);
	};

	const handleClick = (proposalId: number | null, sectionId: number) => {
		if (proposalId && sectionId) {
			setIsPopoverOpen(false);
			scrollToSection(sectionId.toString(), scrollParentRef);
			dispatch(setLastSection({ proposalId, sectionId }));
		}
	};

	const renderSection = (sec: Section, prefix: string) => {
		return (
			<div key={sec.id}>
				<div
					className="flex items-center p-2 mt-2 mr-2 cursor-pointer hover:bg-lightBackground rounded-xl"
					// key={prefix}
					onClick={() => proposalId && handleClick(proposalId, sec.id)}
				>
					<h2 className=" text-[#5D9BFD] font-bold flex items-center gap-3 flex-1 ml-2 animated-container-transition text-lg">
						<span
							className={
								'absolute pr-6 border-r-4 border-[#0000] mr-2 min-w-[66px] xl:min-w-[75px] ' +
								(nodeId && parseInt(nodeId) === sec.id
									? 'border-separater-pink'
									: 'border-separater-grey')
							}
						>
							{prefix}
						</span>{' '}
						{sec.name}
					</h2>
				</div>
				{sec.children.map((child, idx) =>
					renderSection(child, `${prefix}.${idx + 1}`)
				)}
			</div>
		);
	};

	return (
		<Popover open={isPopoverOpen}>
			<PopoverTrigger
				asChild
				className="flex w-8 justify-center text-xs uppercase font-bold text-[#5d9bfd] cursor-pointer"
			>
				<div>
					{isPopoverOpen ? (
						<X
							onMouseDown={() => setPopoverClose()}
							className="cursor-pointer"
							strokeWidth={4}
							color="#5D9BFD"
						/>
					) : (
						<div
							className="animated-hover-effect"
							onClick={() => setPopoverOpen()}
						>
							<Menu />
						</div>
					)}
				</div>
			</PopoverTrigger>
			<PopoverContent
				className={
					styles.popoverCard +
					' relative bg-white w-[500px]  top-4 p-6 rounded-3xl'
				}
				align="start"
				alignOffset={-10}
				sideOffset={10}
				side="bottom"
				onPointerDownOutside={() => setIsPopoverOpen(false)}
			>
				<div className="block max-h-[350px] overflow-y-auto">
					{sections &&
						sections.map((section, index) =>
							renderSection(section, String(index + 1))
						)}
				</div>
			</PopoverContent>
		</Popover>
	);
};

export default TableContent;
