import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { deleteMatrixRule } from '@/redux/requests/compliance';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';

interface DeleteMatrixRuleProps {}

const DeleteMatrixRule: FC<DeleteMatrixRuleProps> = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'deleteMatrixRule'
	);
	const dispatch = useAppDispatch();
	const complianceMatrix = useSelector(
		(root: RootState) => root.compliance.complianceMatrix
	);
	const complianceRuleId = useSelector(
		(root: RootState) => root.page.dialog?.complianceRuleId
	);

	const { activeSection } = useActiveSection();

	const complianceMode = useSelector(
		(root: RootState) => root.page.dialog?.complianceMode
	);

	const deleteMatrix = () => {
		if (!complianceMatrix || !complianceRuleId) return;
		if (complianceMode === 'section' && activeSection.sectionId) {
			dispatch(
				deleteMatrixRule({
					complianceId: complianceMatrix.id,
					ruleId: complianceRuleId,
					proposalId: complianceMatrix.proposalId,
					sectionId: activeSection.sectionId,
					mode: 'section',
				})
			);
		} else {
			dispatch(
				deleteMatrixRule({
					complianceId: complianceMatrix.id,
					ruleId: complianceRuleId,
					proposalId: complianceMatrix.proposalId,
				})
			);
		}
		dispatch(closeDialog());
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] p-5 rounded-2xl shadow-main bg-white">
				<DialogHeader>
					<p className="font-bold dynamic-large text-secondary">
						You're removing a compliance rule!
					</p>
					<p className="py-2 text-black">
						Deleting a compliance rule will permanently delete it from the
						compliance matrix. Which means, it will not be included in the
						compliance runs anymore.
					</p>

					<div className="flex items-center justify-end gap-3">
						<SecondaryButton onClick={() => dispatch(closeDialog())}>
							Cancel
						</SecondaryButton>
						<PrimaryButton onClick={deleteMatrix}>Delete</PrimaryButton>
					</div>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteMatrixRule;
