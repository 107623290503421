import SuggestionIntro from '@/components/SuggestionIntro/SuggestionIntro';
import { RootState } from '@/redux/store/store';
import { Card, CardContent } from '@/components/ui/card';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { cropText, findSection } from '@/lib/functions/funcUtils';
import { Section, Suggestion } from '@/lib/types/apiTypes';
import Documents from '@/components/ui/icons/new/Documents';
import { Button } from '@/components/ui/button';
import customToast from '@/components/CustomToast/CustomToast';
import useProposalId from '@/lib/hooks/useProposalId';
import { generateSuggestionForASection } from '@/lib/functions/apiCalls';
import { useNavigate } from 'react-router';
import SuggestionLists from '@/components/SuggestionLists/SuggestionLists';
import Lottie from 'lottie-react';
import suggestionLottie from '@/assets/lotties/compliance-lottie.json';

import styles from './SuggestionWrapper.module.css';
import { cn } from '@/lib/utils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { setSuggestions } from '@/redux/slices/suggestionsSlice';
import { RefreshCcw } from 'lucide-react';
import SuggestionImage from '@/components/ui/icons/new/SuggestionImage';
import { Skeleton } from '@/components/ui/skeleton';
import WriteModeSkeleton from '@/components/WriteModeSkeleton/WriteModeSkeleton';
import { memoizedSuggestionsForSection } from '@/redux/selectors/selectors';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import CustomTooltip from '@/components/CustomTooltip/CustomTooltip';

interface SuggestionWrapperProps {}

const SuggestionWrapper: FC<SuggestionWrapperProps> = () => {
	const proposalId = useProposalId() as number;
	const dispatch = useAppDispatch();
	const { activeSection } = useActiveSection();

	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const sections = useSelector(
		(state: RootState) => state.proposal.proposal?.sections
	);
	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);

	const isFormativeDocument = useMemo(() => {
		if (!proposal?.formativeDocuments) return false;
		return proposal?.formativeDocuments?.length > 0;
	}, [proposal]);

	const formativeCount = useMemo(() => {
		if (!proposal?.formativeDocuments) return 0;
		return proposal?.formativeDocuments?.length;
	}, [proposal]);

	const suggestions = useSelector((state: RootState) =>
		memoizedSuggestionsForSection(state, activeSection.sectionId)
	);

	const [loader, setLoader] = useState(false);

	const section = useMemo(
		() => findSection(sections as Section[], String(activeSection.sectionId)),
		[activeSection.sectionId, sections]
	);

	const navigate = useNavigate();

	if (loader) {
		return (
			<div className="p-6">
				<div className="flex items-center justify-center bg-white min-h-[160px] rounded-2xl">
					<Lottie
						animationData={suggestionLottie}
						loop={true}
						autoplay
						className="w-80"
					/>
				</div>
				<div className="flex flex-col gap-3 mt-4">
					<div className="p-4 bg-white rounded-xl">
						<Skeleton className="h-[96px] w-full rounded-xl bg-[#F5F5F5]" />
						<div className="flex items-center justify-between mt-3">
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
						</div>
					</div>
					<div className="p-4 bg-white rounded-xl">
						<Skeleton className="h-[96px] w-full rounded-xl bg-[#F5F5F5]" />
						<div className="flex items-center justify-between mt-3">
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
						</div>
					</div>
					<div className="p-4 bg-white rounded-xl">
						<Skeleton className="h-[96px] w-full rounded-xl bg-[#F5F5F5]" />
						<div className="flex items-center justify-between mt-3">
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (isStreaming) return <WriteModeSkeleton />;

	const handleSuggestion = async () => {
		if (section?.content === null || section?.content === '') {
			customToast.error({
				title: "Can't generate suggestion in empty content",
			});
			return;
		}
		if (section && section.content) {
			// Check if the content is less than 500 words
			if (
				section.content.split(/\s+/).length < 200 &&
				section.expectedOutput !== 'table'
			) {
				customToast.error({
					title: 'Content should be more than 200 words to generate suggestion',
				});
				return;
			}

			try {
				setLoader(true);
				const response = await generateSuggestionForASection(
					proposalId,
					section.id,
					section.content
				);
				if (response?.statusCode === 201) {
					setLoader(false);
					const suggestions = (response.data as { suggestions: Suggestion[] })
						.suggestions;

					dispatch(setSuggestions({ sectionId: section.id, suggestions }));
				}
				if (response?.statusCode >= 500) {
					customToast.error({ title: response.message });
				}
			} catch (error: any) {
				customToast.error({
					title: 'Something went wrong with suggestions. Please try again.',
				});
				console.error(error);
				setLoader(false);
			}
		}
	};

	const renderCards = (section: Section) => {
		return (
			<>
				<SuggestionLists
					suggestions={suggestions as Suggestion[]}
					section={section}
				/>
			</>
		);
	};

	const renderCardsBySection = (section: Section) => {
		if (
			!section?.content ||
			(section?.content.split(/\s+/).length < 200 &&
				section?.expectedOutput !== 'table')
		) {
			return (
				<div className="px-6">
					<Card className={'shadow-main rounded-xl bg-white'}>
						<CardContent className="px-4 py-6 text-center">
							<h2 className="font-bold dynamic-medium text-secondary">
								Nothing to suggest yet
							</h2>
							<p className="max-w-[300px] mx-auto mt-2 font-regular text-[#5D6F79]">
								Start by working on this section or click{' '}
								<span className="font-bold">Write with magic</span> to let us
								generate a first draft.
							</p>
						</CardContent>
					</Card>

					<div className="flex flex-col items-center justify-center max-w-xs gap-8 mx-auto mt-6">
						<p className="text-center text-black dynamic-text">
							<span className="font-bold">Tip</span> Once you write some content
							in a section, press the{' '}
							<span className="mr-1 font-bold">suggest button</span>
							and Grant Assistant will generate suggestions from documents
							attached to the design section.
						</p>
						<SuggestionImage />
					</div>
				</div>
			);
		} else {
			return (
				<div className="px-6">
					<Card className="border-none">
						<CardContent className="flex flex-col items-center justify-center gap-4 px-4 py-6 text-center bg-white rounded-xl shadow-main">
							<div className="relative">
								<Documents />
								<div className="absolute flex items-center justify-center font-bold text-white rounded-full top-4 -right-4 w-7 h-7 bg-secondary">
									{section.sectionDocuments.length}
								</div>
							</div>
							<p className="mt-2 font-black text-black dynamic-medium">
								{section.sectionDocuments.length > 0 && (
									<span className="mr-1 font-bold text-secondary">
										{section.sectionDocuments.length}{' '}
										{section.sectionDocuments.length === 1
											? 'document'
											: 'documents'}
									</span>
								)}
								{section.sectionDocuments.length > 0 && formativeCount > 0 && (
									<span className="mx-1">and</span>
								)}
								<span className="mr-1 font-bold text-secondary">
									{formativeCount} formative{' '}
									{formativeCount === 1 ? 'document' : 'documents'}
								</span>
								connected to this section.
							</p>

							<h2 className="text-base text-black">
								Do you want me to review them and make suggestions for
								<CustomTooltip
									triggerNode={
										<span className="ml-1 font-bold text-secondary">
											{cropText(section.alias || section.name, 30)}
										</span>
									}
									text={section.alias || section.name}
								/>{' '}
								section?
							</h2>

							<PrimaryButton
								onClick={handleSuggestion}
								type="button"
								disabled={section.expectedOutput === 'table' ? true : false}
							>
								Generate suggestions
							</PrimaryButton>
						</CardContent>
					</Card>
					<p className="mx-auto text-center max-w-sm dynamic-medium text-[#6B7280] mt-4">
						Please note:{' '}
						<span className="font-bold">
							You need to have at-least{' '}
							<span className="text-secondary">200 words</span> in the section
							to generate a suggestion
						</span>
					</p>
				</div>
			);
		}
	};

	const renderSuggestionsCard = (
		section: Section,
		suggestions: Suggestion[]
	) => {
		if (suggestions.length > 0) {
			return renderCards(section);
		} else {
			return renderCardsBySection(section);
		}
	};

	const renderSuggestions = (section: Section) => {
		if (section?.sectionDocuments?.length > 0 || isFormativeDocument) {
			return renderSuggestionsCard(section, suggestions);
		} else {
			return (
				<div className="px-6 ">
					<Card className="border-none">
						<CardContent className="flex flex-col items-center justify-center gap-4 px-4 py-6 text-center bg-white border-none rounded-xl shadow-main">
							<h2 className="flex flex-col gap-1 text-lg font-bold text-black dynamic-medium">
								We have nothing to suggest in section
								<TooltipProvider delayDuration={50}>
									<Tooltip>
										<TooltipTrigger>
											<div className="text-secondary">
												{cropText(section?.alias || section?.name, 40)}
											</div>
										</TooltipTrigger>
										{(section?.alias || section?.name || '').length > 40 && (
											<TooltipContent
												side="bottom"
												className="bg-white font-normal text-[#6D7D86] max-w-[600px] dynamic-text px-3 2xl:px-5 2xl:py-1 rounded-xl border-none shadow-none drop-shadow-effect"
											>
												<p>{section?.alias || section?.name}</p>
											</TooltipContent>
										)}
									</Tooltip>
								</TooltipProvider>
							</h2>
							<p className="text-base text-black">
								Maybe you haven't uploaded a document? Please associate one or a
								few documents so we can come up with some suggestions
							</p>
							<Button
								onClick={() =>
									navigate(
										`/proposal/${proposalId}/section/${section.id}/design`
									)
								}
								type="button"
								className="flex gap-2 p-6 text-base font-bold text-white uppercase rounded-2xl hover:bg-gradient-linear animated-hover-effect"
							>
								Go to Design
							</Button>
						</CardContent>
					</Card>
				</div>
			);
		}
	};

	return activeSection.sectionId ? (
		<div className={cn(styles.wrapper, 'light-scroll')}>
			<div className="sticky top-0 z-10 flex items-center justify-between p-5 bg-[#EAF1FC]">
				<h3 className=" text-[#5D9BFD] font-bold flex items-center gap-3 flex-1 dynamic-medium">
					<span className="pr-6 border-r-2 border-[#0000] border-separater-pink">
						{activeSection.prefix}
					</span>
					<CustomTooltip
						text={section?.alias || section?.name}
						triggerNode={
							<h3 className=" text-[#5D9BFD] font-bold flex items-center gap-3 flex-1 dynamic-medium">
								{cropText(section?.alias || section?.name || '', 30)}
							</h3>
						}
					/>
				</h3>
				{section &&
					(section.sectionDocuments.length > 0 || isFormativeDocument) && (
						<div
							className={cn(
								isStreaming ? 'opacity-50 pointer-events-none' : '',
								'cursor-pointer'
							)}
							onClick={handleSuggestion}
						>
							<RefreshCcw color="#5D9BFD" />
						</div>
					)}
			</div>
			<div>{renderSuggestions(section as Section)}</div>
		</div>
	) : (
		<div className="p-5">
			<SuggestionIntro />
		</div>
	);
};

export default SuggestionWrapper;
