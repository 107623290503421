import { RootState } from '@/redux/store/store';
import { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import OrganizationPage from '@/pages/Manage/OrganizationPage/OrganizationPage';
import UsersPage from '@/pages/Manage/UsersPage/UsersPage';
import ToneOfVoicePage from '@/pages/Manage/ToneOfVoicePage/ToneOfVoicePage';
import AuditLogsPage from '@/pages/Manage/AuditLogsPage/AuditLogsPage';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';

interface ManagePaneProps {
	defaultTab: 'organization' | 'users' | 'toneofvoice' | 'auditlogs';
}

const ManagePane: FC<ManagePaneProps> = ({ defaultTab }) => {
	const navigate = useNavigate();
	const isStreaming = useSelector((root: RootState) => root.page.isStreaming);

	const [selectedTab, setSelectedTab] = useState(defaultTab);
	const [dirtyTab, setDirtyTab] = useState(defaultTab);

	const handleTabChange = useCallback(
		(newTabIndex) => {
			setDirtyTab(newTabIndex);

			setTimeout(() => {
				setSelectedTab(newTabIndex);
				navigate(`/manage/${newTabIndex}`);
			}, 250);
		},
		[navigate]
	);

	useEffect(() => {
		if (isStreaming) return;
		handleTabChange(defaultTab);
	}, [defaultTab]);

	return (
		<AppTabs
			defaultTab={defaultTab}
			selectedTab={selectedTab}
			handleTabChange={handleTabChange}
			type="main"
			tabs={[
				{
					label: 'Organization',
					value: 'organization',
					content: <OrganizationPage exiting={dirtyTab !== 'organization'} />,
				},
				{
					label: 'Users',
					value: 'users',
					content: <UsersPage exiting={dirtyTab !== 'users'} />,
					tourClass: 'users',
				},
				{
					label: 'Writing Voice',
					value: 'toneofvoice',
					content: <ToneOfVoicePage exiting={dirtyTab !== 'toneofvoice'} />,
					tourClass: 'proposal-settings',
				},
				{
					label: 'Audit Logs',
					value: 'auditlogs',
					content: <AuditLogsPage exiting={dirtyTab !== 'auditlogs'} />,
					tourClass: 'audit-log',
				},
			]}
		/>
	);
};

export default ManagePane;
