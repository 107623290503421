import FileUpload from '@/components/FileUpload/FileUpload';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import {
	attachDocumentToSection,
	attachFormativeDocument,
	updateDocumentName,
	uploadDocumentInOrganization,
} from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	handleApiError,
	handleFileRejections,
	handleGeneralError,
	handleResponse,
	handleUnexpectedError,
} from '@/lib/functions/funcUtils';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import {
	ApiResponse,
	ErrorResponse,
	UploadDocumentPayload,
	UploadDocumentResponse,
} from '@/lib/types/apiTypes';
import { docName } from '@/lib/types/constants';
import { closeDialog } from '@/redux/slices/pageSlice';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { FileText, ChevronLeft, Pencil, Save, CircleSlash } from 'lucide-react';
import DialogLoader from '../../Loader/DialogLoader/DailogLoader';
import CategoryDropdown from '@/components/CategoryDropdown/CategoryDropdown';
import AddDocument from '@/components/ui/icons/new/AddDocument';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { useQueryClient } from 'react-query';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

const UploadDocumentDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(
		(state: RootState) => state.page.dialog?.type === 'uploadDocument'
	);
	const addDocumentType = useAppSelector(
		(state: RootState) => state.page.dialog?.uploadDocumentType
	);
	const folderId = useAppSelector(
		(state: RootState) => state.page.dialog?.folder?.id
	);
	const proposal = useAppSelector((state: RootState) => state.proposal);
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [uploadedDocument, setUploadedDocument] =
		useState<UploadDocumentResponse | null>(null);
	const [editDocumentName, setEditDocumentName] = useState<boolean>(false);
	const [documentName, setDocumentName] = useState<string>('');
	const queryClient = useQueryClient();

	const { activeSection } = useActiveSection();
	const nodeId = activeSection.sectionId;
	const id = proposal.proposal?.id;

	const currentDocumentName =
		uploadedDocument?.metaData?.alias || uploadedDocument?.metaData?.title;

	const handleFiles = async (files: File[]) => {
		if (!files.length) return;
		try {
			setIsLoading(true);
			const payload: UploadDocumentPayload = {
				file: files[0],
				name: docName,
				type: 'text',
				category: selectedCategory as string,
				...(addDocumentType === 'uploadOrganizationalDocument' &&
					folderId && { folderId: folderId }),
			};

			const response: ApiResponse = await uploadDocumentInOrganization(payload);
			if (addDocumentType === 'uploadOrganizationalDocument') {
				handleResponse(
					response,
					response.message === 'success'
						? 'Your document has been loaded.'
						: 'This document already exists.'
				);
				queryClient.invalidateQueries('documents');
				dispatch(closeDialog());
			} else {
				handleResponse(response);
				const docResponse = response.data as UploadDocumentResponse;
				setUploadedDocument(docResponse);
			}
			setIsLoading(false);
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
			} else {
				handleUnexpectedError();
			}
			setIsLoading(false);
		}
	};

	const handleAttachmentSuccess = () => {
		dispatch(closeDialog());
		dispatch(fetchProposalById(Number(id)));
		clearDocumentDialog();
	};

	const handleAttachDocumentToSection = async () => {
		try {
			if (!selectedCategory || !uploadedDocument?.documentId) return;
			setIsLoading(true);
			const response = await attachDocumentToSection(
				Number(id),
				Number(nodeId),
				uploadedDocument.documentId as number,
				selectedCategory
			);
			handleResponse(response);
			handleAttachmentSuccess();
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleAttachFormativeDocument = async () => {
		try {
			if (!selectedCategory || !uploadedDocument?.documentId) return;
			setIsLoading(true);
			const response = await attachFormativeDocument(
				Number(id),
				uploadedDocument?.documentId as number,
				selectedCategory
			);
			handleResponse(response);
			handleAttachmentSuccess();
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleAttachment = async () => {
		switch (addDocumentType) {
			case 'attachFormativeDocument':
				handleAttachFormativeDocument();
				break;
			case 'attachSectionDocument':
				handleAttachDocumentToSection();
				break;
		}
	};

	const handleDocumentNameUpdate = async () => {
		try {
			if (!uploadedDocument?.documentId) return;
			setIsLoading(true);
			const response = await updateDocumentName(
				documentName,
				uploadedDocument?.documentId as number
			);
			handleResponse(response, 'Document name has been updated successfully.');
			setIsLoading(false);
			setEditDocumentName(false);
			setUploadedDocument({
				...uploadedDocument,
				metaData: {
					...uploadedDocument.metaData,
					alias: documentName,
				},
			});
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const clearDocumentDialog = () => {
		setUploadedDocument(null);
		setSelectedCategory(null);
		setEditDocumentName(false);
		setShowPreview(false);
		setIsLoading(false);
	};

	useEffect(() => {
		if (isOpen) {
			setSelectedCategory('Research');
		}
	}, [isOpen]);

	const renderUploadContainer = () => {
		return (
			<div
				className={cn(
					'h-64 2xl:h-80 flex flex-col gap-8 justify-center rounded-xl text-black bg-background p-6 items-center border border-primary border-dashed',
					selectedCategory ? 'cursor-pointer' : ''
				)}
			>
				<AddDocument className="h-24 2xl:h-28" />
				<div className="flex flex-col items-center">
					<h4 className="font-bold">
						Click or drag a document to upload the document from your computer
					</h4>
					<p>
						Please upload one document at a time{' '}
						<span className="text-secondary">
							(please make sure you are satisfied with the categorization of the
							document)
						</span>
					</p>
				</div>
			</div>
		);
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className={cn(
					'flex flex-col overflow-hidden bg-white p-6 h-auto 2xl:h-auto',
					showPreview
						? 'max-w-[650px] 2xl:max-w-[750px]'
						: 'max-w-[750px] 2xl:max-w-[850px]'
				)}
				setModalOpen={() => clearDocumentDialog()}
			>
				{showPreview ? (
					<>
						<div className="flex gap-2">
							<ChevronLeft
								onClick={() => setShowPreview(false)}
								className="text-primary cursor-pointer"
							/>
							<h2 className="dynamic-large text-black font-bold">
								Document Preview
							</h2>
						</div>
						<div className="flex flex-col gap-2">
							<Badge className="items-center justify-center block px-3 bg-white border w-fit text-secondary border-secondary">
								{selectedCategory}
							</Badge>
							<div className="flex flex-col gap-2">
								{editDocumentName ? (
									<div className="flex gap-4 items-center justify-between">
										<div className="flex flex-col gap-2 w-full">
											<div className="rounded-xl shadow-main">
												<Input
													tabIndex={-1}
													placeholder={
														'What would you like to name this document?'
													}
													onChange={(e) => setDocumentName(e.target.value)}
													value={documentName}
													className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-lightBackground disabled:bg-[#F5F5F5]"
												/>
											</div>
											{/* <p
												className={cn(
													'flex w-full dynamic-badge justify-end self-center font-medium leading-normal',
													documentName?.length > 250
														? 'text-[#FF82A0]'
														: 'text-black opacity-50'
												)}
											>
												{documentName?.length || 0}/250 Char
											</p> */}
										</div>
										<div className="flex gap-2">
											<TextButton
												onClick={() => handleDocumentNameUpdate()}
												disabled={
													(currentDocumentName || '') === documentName ||
													documentName?.length > 250
												}
												className="h-5 2xl:h-6"
											>
												<Save size={16} />
												Save
											</TextButton>
											<TextButton
												onClick={() => setEditDocumentName(false)}
												className="h-5 2xl:h-6"
											>
												<CircleSlash size={16} />
												Cancel
											</TextButton>
										</div>
									</div>
								) : (
									<div className="flex gap-4 items-center justify-between">
										<h4 className="dynamic-medium text-black font-bold">
											{currentDocumentName}
										</h4>
										<TextButton
											onClick={() => {
												setDocumentName(currentDocumentName || '');
												setEditDocumentName(true);
											}}
											className="h-5 2xl:h-6"
										>
											<Pencil size={16} />
											Edit
										</TextButton>
									</div>
								)}
								{editDocumentName && documentName?.length > 250 && (
									<p className="dynamic-text font-medium text-lightRed">
										Characters must be less than 250
									</p>
								)}
								<p className="text-black dynamic-text pt-2">
									{uploadedDocument?.metaData?.summary}
								</p>
							</div>
						</div>
						<div className="flex border-[#C6DBFB] border-t pt-3 justify-end">
							<PrimaryButton
								disabled={editDocumentName}
								onClick={() => handleAttachment()}
							>
								Import
							</PrimaryButton>
						</div>
					</>
				) : (
					<>
						<div className="flex justify-between">
							<h3 className="font-bold text-black dynamic-large">
								Let’s Get Your Document Loaded Up
							</h3>
						</div>
						<div className="border-[#C6DBFB] border-b"></div>
						<div className="flex flex-col gap-2 xl:gap-3">
							<h2 className="text-secondary font-bold dynamic-large">Step 1</h2>
							<div className="flex">
								<div className="flex flex-col text-black w-full">
									<h4 className="font-bold">
										Please select what you want your document to be catagorized
										as:
									</h4>
									<p>
										This is how the AI model will understand how to use this
										document
									</p>
								</div>
								<div className="flex w-full max-w-[280px] 2xl:max-w-[350px] shrink-0">
									<CategoryDropdown
										className="h-11 w-full border border-[#5D9BFD]"
										selectedCategory={selectedCategory || ''}
										setSelectedCategory={setSelectedCategory}
									/>
								</div>
							</div>
						</div>
						<div className="border-[#C6DBFB] border-b"></div>
						<div className="flex flex-col gap-2 xl:gap-3">
							<h2 className="text-secondary font-bold dynamic-large">Step 2</h2>
							<h4 className="text-black font-bold">Upload your document</h4>
							{uploadedDocument ? (
								<div className="h-64 2xl:h-80 flex flex-col gap-6 justify-center rounded-xl text-black p-6 items-center border border-blackSecondary">
									<FileText
										className="text-primary"
										strokeWidth={1}
										size={100}
									/>
									<div className="flex flex-col items-center">
										<h4 className="font-bold">{currentDocumentName}</h4>
									</div>
									<FileUpload
										type="custom"
										onDropAccepted={handleFiles}
										onDropRejected={(fileRejections) =>
											handleFileRejections({
												fileRejections: fileRejections,
											})
										}
										acceptedExtensions={['.pdf', '.docx']}
										customLayout={
											<SecondaryButton>Replace Document</SecondaryButton>
										}
									/>
								</div>
							) : selectedCategory ? (
								<FileUpload
									type="custom"
									onDropAccepted={handleFiles}
									onDropRejected={(fileRejections) =>
										handleFileRejections({
											fileRejections: fileRejections,
										})
									}
									acceptedExtensions={['.pdf', '.docx']}
									customLayout={renderUploadContainer()}
								/>
							) : (
								<>{renderUploadContainer()}</>
							)}
						</div>
						{addDocumentType !== 'uploadOrganizationalDocument' && (
							<div className="flex gap-3 justify-end">
								<SecondaryButton
									onClick={() => {
										clearDocumentDialog();
										dispatch(closeDialog());
									}}
								>
									Cancel
								</SecondaryButton>
								<PrimaryButton
									disabled={!selectedCategory || !uploadedDocument}
									onClick={() => {
										setShowPreview(true);
										setEditDocumentName(false);
									}}
								>
									Next
								</PrimaryButton>
							</div>
						)}
					</>
				)}
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default UploadDocumentDialog;
