import { FC, useRef, useState } from 'react';
import styles from './Document.module.css';
import { Document, ErrorResponse } from '@/lib/types/apiTypes';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { getDocumentFromSource } from '@/lib/functions/apiCalls';
import { handleApiError, handleGeneralError } from '@/lib/functions/funcUtils';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import DetachIcon from '@/components/ui/icons/DetachIcon';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { cn } from '@/lib/utils';
import { BookOpen, FileTextIcon } from 'lucide-react';

interface DocumentCardProps {
	onSubmit?: (title: string, description: string) => void;
	onCancel?: () => void;
	onDelete?: () => void;
	document: Document;
	user?: {
		name: string;
	};
}

const DocumentCard: FC<DocumentCardProps> = ({ document, user }) => {
	const [showFullText, setShowFullText] = useState(false);
	const textRef = useRef<HTMLParagraphElement | null>(null);
	const dispatch = useAppDispatch();
	const handlePdf = async () => {
		try {
			dispatch(processStart());
			const response = await getDocumentFromSource(
				document.sourceLink as string
			);
			if (response) {
				dispatch(openDialog({ type: 'viewPDF', pdf: response as ArrayBuffer }));
				// setPdfData(response as ArrayBuffer);
				dispatch(processSuccess());
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};

	return (
		<div className={styles.box + ' flex flex-col '}>
			<div className="flex flex-col gap-3">
				<div className="flex items-center justify-between gap-2">
					<div className="flex items-center gap-2">
						<div className=" shrink-0">
							<FileTextIcon className="h-5 w-5" color={'var(--primary)'} />
						</div>
						<p className="dynamic-medium text-[#6d7d86] ">
							Attached by{' '}
							<span className="font-bold text-secondary">
								{user?.name || 'Deleted User'}
							</span>
						</p>
					</div>
				</div>
				<p className="dynamic-medium text-[#6d7d86] font-bold leading-tight">
					{document.name}
				</p>
				<div className="flex items-center justify-start text-black">
					<span className="font-bold pr-2">Category:</span>
					{document?.attachment?.attachedAs || document?.category}
				</div>
				<div className="flex flex-col">
					<div
						ref={textRef}
						className={`text-[#6d7d86] text-sm xl:text-base 2xl:text-lg ${
							!showFullText ? styles.limitedText : ''
						}`}
					>
						{document?.summary}{' '}
					</div>
					{document?.summary?.length <
					(textRef?.current?.offsetWidth || 300) / 2.5 ? (
						<></>
					) : (
						<>
							{!showFullText ? (
								<TextButton
									className="h-fit 2xl:h-fit w-fit p-0 mt-1 text-sm xl:text-base 2xl:text-lg"
									onClick={() => setShowFullText(true)}
								>
									Read More
								</TextButton>
							) : (
								<TextButton
									className="h-fit 2xl:h-fit w-fit p-0 mt-1 text-sm xl:text-base 2xl:text-lg font-normal"
									onClick={() => setShowFullText(false)}
								>
									Less
								</TextButton>
							)}
						</>
					)}
				</div>
			</div>
			<div className={cn(styles.btnContainer)}>
				<div className="flex items-center gap-2 right-6 border-t pt-3 mt-3 border-blackSecondary hover-element">
					<button
						className="flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
						onClick={handlePdf}
					>
						<BookOpen className="w-4 2xl:w-5" color={'var(--primary)'} />
						Read
					</button>
					<button
						className="flex font-medium items-center justify-center w-1/4 text-lightRed gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
						onClick={() =>
							dispatch(
								openDialog({ type: 'detachDocument', documentId: document.id })
							)
						}
					>
						<DetachIcon
							className="h-4 2xl:h-5 w-fit"
							color={'var(--light-red)'}
						/>
						Remove
					</button>
				</div>
			</div>
		</div>
	);
};

export default DocumentCard;
