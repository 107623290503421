const Tick = ({ color = '#84CE53' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<circle cx="8" cy="8" r="8" fill={color} />
			<path
				d="M4.36328 8.31746L5.6044 10.1823L11.636 6.5459"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Tick;
