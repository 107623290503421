import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { FC, useEffect, useState } from 'react';
import { ClipboardList, Combine } from 'lucide-react';
import useProposalId from '@/lib/hooks/useProposalId';
import {
	createNoteOnSpecificSection,
	getClipboards,
} from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	handleResponse,
	showToast,
} from '@/lib/functions/funcUtils';
import { Clipboard } from '@/lib/types/apiTypes';
import { cloneDeep } from 'lodash';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import ClipboardNotFound from '@/components/ui/icons/new/ClipboardNotFound';
import { closeDrawer } from '@/redux/slices/pageSlice';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import ClipboardIdea from '@/components/widgets/ClipboardIdea/ClipboardIdea';

interface ClipboardIdeaDrawerProps {}

const ClipboardIdeaDrawer: FC<ClipboardIdeaDrawerProps> = () => {
	const isOpen = useAppSelector(
		(state) => state.page.drawer?.type === 'clipboardIdea'
	);
	const dispatch = useAppDispatch();
	const proposalId = useProposalId();
	const { activeSection } = useActiveSection();
	const [isLoading, setIsLoading] = useState(false);
	const [clipboards, setClipboards] = useState<Clipboard[]>([]);
	const [selectedIds, setSelectedIds] = useState<number[]>([]);

	const sectionId = activeSection.sectionId;

	const handleGetClipboards = async () => {
		if (!proposalId) return;
		try {
			setIsLoading(true);
			const response = await getClipboards(proposalId);
			handleResponse(response);
			const clipboards = (response.data as any).clipboards as Clipboard[];
			setClipboards(clipboards);
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	// const handleUpdateClipboard = async () => {
	// 	try {
	// 		if (!editingClipboard?.id) return;
	// 		setIsLoading(true);
	// 		const response = await updateClipboard(
	// 			editingClipboard.id,
	// 			editingClipboard.text
	// 		);
	// 		handleResponse(response, 'Clipboard has been updated successfully');
	// 		handleGetClipboards();
	// 		setEditingClipboard(null);
	// 	} catch (error) {
	// 		ErrorHandle(dispatch, error);
	// 		setIsLoading(false);
	// 	}
	// };

	// const handleDeleteClipboard = async (clipboardId) => {
	// 	try {
	// 		setIsLoading(true);
	// 		const response = await deleteClipboard(clipboardId);
	// 		handleResponse(response, 'Clipboard has been deleted successfully');
	// 		handleGetClipboards();
	// 		queryClient.invalidateQueries('clipboards');
	// 	} catch (error) {
	// 		ErrorHandle(dispatch, error);
	// 		setIsLoading(false);
	// 	}
	// };

	const handleAddTheseIn = async () => {
		try {
			if (selectedIds.length === 0 || !sectionId) return;
			setIsLoading(true);
			await Promise.all(
				selectedIds.map(async (selectedId) => {
					const clipboard = clipboards.find(
						(clipboardItem) => clipboardItem.id === selectedId
					);
					if (!clipboard?.proposalId) return;

					const response = await createNoteOnSpecificSection(
						clipboard.proposalId,
						sectionId,
						{
							content: clipboard.text,
							type: 'idea',
						}
					);
					handleResponse(response);
				})
			);
			showToast('success', 'Ideas has been created successfully');
			setSelectedIds([]);
			dispatch(closeDrawer());
		} catch (error) {
			ErrorHandle(dispatch, error);
		} finally {
			setIsLoading(false);
			proposalId && dispatch(fetchProposalById(proposalId));
		}
	};

	useEffect(() => {
		setSelectedIds([]);
		setIsLoading(false);
		if (isOpen) {
			handleGetClipboards();
		}
	}, [isOpen]);

	return (
		<Sheet open={isOpen}>
			<SheetContent className="flex flex-col 2xl:w-[23%] !max-w-[450px] p-0 bg-white gap-0">
				<div>
					<h2 className="border-b border-[#D3DDE2] dynamic-large flex items-center gap-2 font-bold text-secondary p-4">
						<ClipboardList className="text-primary" />
						Ideas Clipboard
					</h2>
				</div>
				<div className="flex flex-col h-full flex-1 overflow-y-auto hidden-scroll px-6">
					{clipboards.length > 0 && (
						<div className="flex gap-4 items-center py-4">
							<TextButton
								onClick={() => {
									setSelectedIds(clipboards.map((clipboard) => clipboard.id));
								}}
								disabled={selectedIds?.length === clipboards.length}
								className="h-auto 2xl:h-auto p-0 text-primary dynamic-text uppercase animated-hover-effect cursor-pointer"
							>
								Select All
							</TextButton>
							<div className="h-3 border-l border-blackSecondary"></div>
							<TextButton
								onClick={() => {
									setSelectedIds([]);
								}}
								disabled={!selectedIds?.length}
								className="h-auto 2xl:h-auto p-0 text-primary dynamic-text uppercase animated-hover-effect cursor-pointer"
							>
								Deselect All
							</TextButton>
						</div>
					)}
					<div className="flex flex-col h-full overflow-y-auto gap-3 pr-2">
						{clipboards.length > 0 ? (
							clipboards.map((clipboard, index) => (
								<ClipboardIdea
									clipboard={clipboard}
									isSelected={selectedIds.includes(clipboard.id)}
									toggleSelection={() => {
										if (selectedIds.includes(clipboard.id)) {
											setSelectedIds(
												cloneDeep(
													selectedIds.filter(
														(selectedId) => selectedId !== clipboard.id
													)
												)
											);
										} else {
											selectedIds.push(clipboard.id);
											setSelectedIds(cloneDeep(selectedIds));
										}
									}}
									handleGetClipboards={handleGetClipboards}
									setIsLoading={setIsLoading}
									key={index}
								/>
							))
						) : (
							<div className="flex flex-col h-full gap-4 justify-center items-center pt-2">
								{!isLoading && (
									<>
										<div className="flex flex-col text-black text-center">
											<p className="dynamic-medium">
												Please add ideas to the clipboard.
											</p>
											<p className="dynamic-medium">
												You can then add them to other sections easily.
											</p>
										</div>
										<ClipboardNotFound />
									</>
								)}
							</div>
						)}
					</div>
				</div>
				<div className="flex items-center gap-4 w-full px-4 py-6">
					<PrimaryButton
						disabled={selectedIds.length < 1}
						className="w-full"
						onClick={handleAddTheseIn}
					>
						<Combine size={18} /> Add these in
					</PrimaryButton>
				</div>
				{isLoading && <LoaderTransparent />}
			</SheetContent>
		</Sheet>
	);
};

export default ClipboardIdeaDrawer;
