import { FC, useRef } from 'react';
import styles from './Template.module.css';
import { Search } from 'lucide-react';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { containerDivFadeIn } from '@/constants/variants';
import { TagFilter } from './Filters';
import { Input } from '@/components/ui/input';
import DeleteCross from '@/components/ui/icons/new/DeleteCross';
import useDebounce from '@/lib/hooks/useDebounce';

interface TemplateHeaderProps {
	templateSearch: string | undefined;
	handleTemplateSearch: (value: string) => void;
}

const TemplateHeader: FC<TemplateHeaderProps> = ({
	templateSearch,
	handleTemplateSearch,
}) => {
	const searchRef = useRef<HTMLInputElement>(null);
	const debouncedSearchChange = useDebounce(handleTemplateSearch, 800);
	return (
		<div className="z-20 flex flex-col w-full px-8 pt-5">
			<motion.div
				className={cn(
					styles.header,
					'px-5 py-4 flex justify-between items-center'
				)}
				key="containerDivFadeIn"
				variants={containerDivFadeIn}
				initial="initial"
				animate="animate"
			>
				<div className="flex items-center justify-start">
					<h2 className="font-bold text-secondary dynamic-xl-large">
						Templates Library
					</h2>
				</div>
				<div className="flex justify-end gap-3">
					<TagFilter type={'template'} />
					<div className="relative">
						<Input
							ref={searchRef}
							defaultValue={templateSearch}
							onChange={(event) => debouncedSearchChange(event.target.value)}
							placeholder="Search"
							className="bg-white border-[#D3DDE2] w-72 h-11 p-4 dynamic-text rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-12"
						/>
						<div className="absolute right-3 top-4">
							<div className="flex flex-row items-center">
								{templateSearch && (
									<DeleteCross
										onClick={() => {
											if (searchRef?.current) {
												searchRef.current.value = '';
												handleTemplateSearch('');
											}
										}}
									/>
								)}

								<Search color="#5D9BFD" size={16} />
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default TemplateHeader;
