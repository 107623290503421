import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { FC, ReactNode, useState } from 'react';
import { UsersTableProps } from '@/lib/types/constants';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import LoaderPanel from '@/components/Loader/LoaderPanel/LoaderPanel';
import { Input } from '@/components/ui/input';
import { Search, X } from 'lucide-react';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import FallbackAvatar from '@/components/FallbackAvatar/FallbackAvatar';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { createTeam, updateTeam } from '@/lib/functions/apiCalls';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { ManageTeamPayload } from '@/lib/types/apiTypes';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { useQueryClient } from 'react-query';

const formSchema = z.object({
	name: z
		.string()
		.min(2, {
			message: 'Please enter team name.',
		})
		.max(250, { message: 'Characters must be less than to 250.' }),
	userIds: z.array(z.number()).min(1, {
		message: 'Please select at least one user.',
	}),
});

interface ManageTeamsDrawerProps {
	type: 'create' | 'edit';
	users: UsersTableProps[];
	triggerButton: ReactNode;
	id?: number;
	name?: string;
	userIds?: number[];
}

const ManageTeamsDrawer: FC<ManageTeamsDrawerProps> = ({
	type,
	users,
	triggerButton,
	id,
	name,
	userIds,
}) => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const queryClient = useQueryClient();

	const form = useForm<ManageTeamPayload>({
		resolver: zodResolver(formSchema),
		values: {
			name: name || '',
			userIds: userIds || [],
		},
	});

	const filteredUsers = users?.filter((user) =>
		user.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const onSubmit = async (fromData: ManageTeamPayload) => {
		try {
			setIsLoading(true);
			const respone =
				type === 'create'
					? await createTeam({
							name: fromData.name,
							userIds: fromData.userIds,
						})
					: id &&
						(await updateTeam(
							{
								name: fromData.name,
								userIds: fromData.userIds,
							},
							id
						));
			handleResponse(
				respone,
				`Team has been ${type === 'create' ? 'created' : 'updated'} successfully.`
			);
			queryClient.invalidateQueries('teams');
			setIsLoading(false);
			setOpen(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const toggleSelectedUsers = (field, user) => {
		if (field.value.includes(user.id)) {
			field.onChange(field.value.filter((id) => id !== user.id));
		} else {
			field.onChange([...field.value, user.id]);
		}
	};
	const handleClear = async () => {
		try {
			setIsLoading(false);
			setOpen(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleDrawerOpen = () => {
		setSearchTerm('');
		form.setValue('name', name || '');
		form.setValue('userIds', userIds || []);
		setOpen(true);
	};

	const handleDrawerClose = () => {
		form.reset();
		setOpen(false);
	};

	return (
		<Sheet
			open={open}
			onOpenChange={(open) => {
				open ? handleDrawerOpen() : handleDrawerClose();
			}}
		>
			<SheetTrigger className="relative flex gap-8">
				{triggerButton && triggerButton}
			</SheetTrigger>
			<SheetContent className="flex flex-col p-0 overflow-hidden">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="flex flex-col h-full !max-w-[450px] p-0 bg-white gap-0 overflow-hidden"
					>
						<div className="flex items-center w-full justify-between pr-12 pb-4">
							<h2 className="dynamic-large flex items-center gap-2 font-bold text-black p-4">
								{type === 'create' ? 'Create Team' : 'Edit Team'}
							</h2>
						</div>
						<div className="flex flex-col flex-1 h-full gap-6 pb-2 px-6 overflow-hidden">
							<FormField
								control={form.control}
								name="name"
								render={({ field }) => {
									return (
										<FormItem className="flex flex-col gap-2">
											<Label>Team Name</Label>
											<FormControl>
												<Input
													className="bg-white cursor-caret border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
													placeholder="Enter team name"
													{...field}
												/>
											</FormControl>
											<FormMessage className="!mt-0" />
										</FormItem>
									);
								}}
							/>
							<FormField
								control={form.control}
								name="userIds"
								render={() => (
									<FormItem className="flex flex-col h-full overflow-hidden">
										<div className="flex flex-col gap-2">
											<div className="flex items-center justify-between">
												<Label>Select Users</Label>
												<TextButton
													onClick={() => form.setValue('userIds', [])}
													className="h-auto 2xl:h-auto p-0 dynamic-text"
												>
													Clear All
												</TextButton>
											</div>
											<div className="relative w-full">
												<Input
													value={searchTerm}
													onChange={(event) => {
														setSearchTerm(event.target.value);
													}}
													placeholder="Search users"
													className="bg-white cursor-caret border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
												/>
												<div className="absolute right-3 top-3.5">
													{searchTerm !== '' ? (
														<X
															onClick={() => {
																setSearchTerm('');
															}}
															className="cursor-pointer"
															color="#5D6F7999"
															size={16}
														/>
													) : (
														<Search color="#5D9BFD" size={16} />
													)}
												</div>
											</div>
										</div>
										<div className="w-full h-full flex flex-col gap-4 overflow-y-auto">
											{filteredUsers?.length ? (
												filteredUsers.map((user) => (
													<FormField
														key={String(user.id)}
														control={form.control}
														name="userIds"
														render={({ field }) => {
															return (
																<FormItem
																	key={String(user.id)}
																	className="flex flex-row items-center space-x-3 space-y-0"
																>
																	<Label
																		className="flex w-full items-center space-x-3 cursor-pointer"
																		htmlFor={String(user.id)}
																	>
																		<FormControl>
																			<Checkbox
																				className="text-white ml-2"
																				id={String(user.id)}
																				checked={field.value?.includes(user.id)}
																				onCheckedChange={() => {
																					toggleSelectedUsers(field, user);
																				}}
																			/>
																		</FormControl>
																		<Avatar>
																			<AvatarImage
																				height={50}
																				width={50}
																				src={user.profilePicture}
																			/>
																			<FallbackAvatar
																				user={user}
																				className="dynamic-medium w-10 h-10"
																			></FallbackAvatar>
																		</Avatar>
																		<p className="font-normal dynamic-text text-black">
																			{user.name}
																		</p>
																	</Label>
																</FormItem>
															);
														}}
													/>
												))
											) : (
												<p className="p-3 text-center text-black">
													No user found
												</p>
											)}
										</div>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex flex-col justify-center w-full px-6 py-4">
							<TextButton type="reset" onClick={handleClear}>
								Cancel
							</TextButton>
							<PrimaryButton type="submit">
								{type === 'create' ? 'Create' : 'Update'}
							</PrimaryButton>
						</div>
					</form>
				</Form>
				{isLoading && <LoaderPanel />}
			</SheetContent>
		</Sheet>
	);
};

export default ManageTeamsDrawer;
