import { SVGProps } from 'react';

const View = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
		>
			<path
				d="M16.9242 18.2363L10.6242 11.9363C10.1242 12.3363 9.54922 12.653 8.89922 12.8863C8.24922 13.1197 7.55755 13.2363 6.82422 13.2363C5.00755 13.2363 3.47005 12.6072 2.21172 11.3488C0.953385 10.0905 0.324219 8.55299 0.324219 6.73633C0.324219 4.91966 0.953385 3.38216 2.21172 2.12383C3.47005 0.865495 5.00755 0.236328 6.82422 0.236328C8.64089 0.236328 10.1784 0.865495 11.4367 2.12383C12.6951 3.38216 13.3242 4.91966 13.3242 6.73633C13.3242 7.46966 13.2076 8.16133 12.9742 8.81133C12.7409 9.46133 12.4242 10.0363 12.0242 10.5363L18.3242 16.8363L16.9242 18.2363ZM6.82422 11.2363C8.07422 11.2363 9.13672 10.7988 10.0117 9.92383C10.8867 9.04883 11.3242 7.98633 11.3242 6.73633C11.3242 5.48633 10.8867 4.42383 10.0117 3.54883C9.13672 2.67383 8.07422 2.23633 6.82422 2.23633C5.57422 2.23633 4.51172 2.67383 3.63672 3.54883C2.76172 4.42383 2.32422 5.48633 2.32422 6.73633C2.32422 7.98633 2.76172 9.04883 3.63672 9.92383C4.51172 10.7988 5.57422 11.2363 6.82422 11.2363ZM5.82422 9.73633V7.73633H3.82422V5.73633H5.82422V3.73633H7.82422V5.73633H9.82422V7.73633H7.82422V9.73633H5.82422Z"
				fill="#5D9BFD"
			/>
		</svg>
	);
};

export default View;
