import { SVGProps } from 'react';

const Template = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M12.9582 0H5.04179C2.25729 0 0 2.13459 0 4.76775V13.2323C0 15.8654 2.25729 18 5.04179 18H12.9582C15.7427 18 18 15.8654 18 13.2323V4.76775C18 2.13459 15.7427 0 12.9582 0Z"
				fill="#E861C9"
			/>
			<path
				d="M13.4747 9.94997L13.1181 9.44679C13.0073 9.29025 12.7996 9.26043 12.6542 9.3797L9.11229 12.2907C9.04651 12.3428 8.99458 12.4137 8.95649 12.4919L8.65874 13.1069C8.53064 13.3753 8.74876 13.6809 9.02227 13.6176L9.6524 13.4685C9.73203 13.4498 9.8082 13.4126 9.87398 13.3567L13.4158 10.4457C13.5613 10.3264 13.589 10.1028 13.4782 9.94624L13.4747 9.94997Z"
				stroke="white"
				strokeWidth="0.75"
				strokeMiterlimit="10"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.47145 3.18181C5.13682 3.18181 4.86556 3.47384 4.86556 3.83407V7.03203C4.86556 7.39227 5.13682 7.6843 5.47145 7.6843H12.1536C12.4882 7.6843 12.7594 7.39227 12.7594 7.03203V3.83407C12.7594 3.47384 12.4882 3.18181 12.1536 3.18181H5.47145ZM4 3.83407C4 2.95921 4.65879 2.25 5.47145 2.25H12.1536C12.9662 2.25 13.625 2.95921 13.625 3.83407V7.03203C13.625 7.90689 12.9662 8.6161 12.1536 8.6161H5.47145C4.65879 8.6161 4 7.90689 4 7.03203V3.83407Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.34335 10.2677C5.08034 10.2677 4.86556 10.499 4.86556 10.7821V14.1813C4.86556 14.4645 5.08034 14.6957 5.34335 14.6957H12.2817C12.5447 14.6957 12.7594 14.4645 12.7594 14.1813V12.8023C12.7594 12.5449 12.9532 12.3364 13.1922 12.3364C13.4312 12.3364 13.625 12.5449 13.625 12.8023V14.1813C13.625 14.9791 13.0227 15.6275 12.2817 15.6275H5.34335C4.6023 15.6275 4 14.9791 4 14.1813V10.7821C4 9.98434 4.60231 9.33594 5.34335 9.33594H9.08255C9.32157 9.33594 9.51533 9.54453 9.51533 9.80184C9.51533 10.0592 9.32157 10.2677 9.08255 10.2677H5.34335Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.04883 4.51473C6.04883 4.25742 6.24259 4.04883 6.48161 4.04883H11.2941C11.5331 4.04883 11.7269 4.25742 11.7269 4.51473C11.7269 4.77204 11.5331 4.98063 11.2941 4.98063H6.48161C6.24259 4.98063 6.04883 4.77204 6.04883 4.51473Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.04883 6.33504C6.04883 6.07773 6.24259 5.86914 6.48161 5.86914H10.1204C10.3594 5.86914 10.5532 6.07773 10.5532 6.33504C10.5532 6.59236 10.3594 6.80095 10.1204 6.80095H6.48161C6.24259 6.80095 6.04883 6.59236 6.04883 6.33504Z"
				fill="white"
			/>
		</svg>
	);
};

export default Template;
