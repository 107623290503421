import { Step } from 'intro.js-react';
export const TemplateBuilderDesignerSteps: Step[] = [
	{
		element: '.welcome-template-assembly',
		title: 'Template assembly screen',
		intro: (
			<div>
				<p className="text-black">
					Welcome to the template builder assembly screen.
				</p>
			</div>
		),
	},
	{
		element: '.section-area',
		title: 'Sections area',
		intro: 'Your sections are available here.',
		position: 'top',
	},
	{
		element: '.template-filters',
		title: 'Search and Filter',
		intro: 'You can search and filter these sections here.',
		position: 'bottom',
	},
	{
		element: '.create-section',
		title: 'Create new section',
		intro: 'Or create a new section by clicking here',
		position: 'right',
	},
	{
		element: '.template-node',
		title: 'Template assembly',
		intro:
			'When you are ready, drag and drop your sections into the section assembler',
		position: 'right',
	},
	{
		element: '.template-setting',
		title: 'Section settings',
		intro:
			'You can adjust the wordcount and connections associated to this section in this area.',
		position: 'right',
	},
	{
		element: '.template-video',
		title: 'Template Builder in detail',
		intro: (
			<div style={{ width: '100%', height: '300px' }}>
				<p className="mb-4 text-black">
					This helpful video will give you a guided tour of how template builder
					works. To watch this on full screen, please{' '}
					<a
						href="https://vimeo.com/950661002/618cf5763d"
						target="_blank"
						rel="noreferrer"
						className="text-primary"
					>
						click here.
					</a>
				</p>

				<div style={{ position: 'relative', paddingTop: '56.25%' }}>
					<iframe
						src="https://player.vimeo.com/video/950661002?h=618cf5763d"
						width="100%"
						height="100%"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						style={{ position: 'absolute', top: '0', left: '0' }}
					></iframe>
				</div>
			</div>
		),
	},
];
