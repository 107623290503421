import { FC } from 'react';
import styles from './SideBarMenu.module.css';
import SideBarMenuItem from '../SideBarMenuItem/SideBarMenuItem';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

interface SideBarMenuProps {}

const SideBarMenu: FC<SideBarMenuProps> = () => {
	const isStreaming = useSelector((root: RootState) => root.page.isStreaming);
	const templateBuilderAccess =
		useSelector((root: RootState) => root.auth.user?.templateBuilderAccess) ||
		false;

	return (
		<nav className={cn(styles.nav, 'top-left-menu')}>
			<ul className="flex flex-col items-center justify-center gap-2 ">
				<TooltipProvider delayDuration={50}>
					<Tooltip>
						<TooltipTrigger
							className={isStreaming ? 'cursor-lock' : ''}
							disabled={isStreaming}
						>
							<SideBarMenuItem
								link={'/'}
								name={'home'}
								className={`${isStreaming ? 'pointer-events-none' : ''}`}
							/>
						</TooltipTrigger>
						<TooltipContent
							// sideOffset={}
							side="right"
							hidden={isStreaming}
							className={`bg-white text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect `}
						>
							<p>Home</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
				{/* <TooltipProvider delayDuration={50}>
					<Tooltip>
						<TooltipTrigger className={isStreaming ? 'cursor-lock' : ''} disabled={isStreaming}>
							<SideBarMenuItem
								onClick={handleClear}
								link={'/discover'}
								name={'discover'}
								className={`${isStreaming ? 'pointer-events-none' : ''}`}
							/>
						</TooltipTrigger>
						<TooltipContent
							hidden={isStreaming}
							className={`bg-white  text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect `}
						>
							<p>Discover</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider> */}
				<TooltipProvider delayDuration={50}>
					<Tooltip>
						<TooltipTrigger
							className={isStreaming ? 'cursor-lock' : ''}
							disabled={isStreaming}
						>
							<SideBarMenuItem
								link={'/solicitations'}
								name={'doc'}
								className={`${isStreaming ? 'pointer-events-none' : ''}`}
							/>
						</TooltipTrigger>
						<TooltipContent
							side="right"
							hidden={isStreaming}
							className={`bg-white text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect `}
						>
							<p>Proposals</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</ul>
			<ul className="flex flex-col items-center gap-2 pt-4 mt-4 border border-t-2 border-b-0 border-l-0 border-r-0">
				<TooltipProvider delayDuration={50}>
					<Tooltip>
						<TooltipTrigger
							className={isStreaming ? 'cursor-lock' : ''}
							disabled={isStreaming}
						>
							<SideBarMenuItem
								link={'/document'}
								name={'file'}
								className={`${isStreaming ? 'pointer-events-none' : ''}`}
							/>
						</TooltipTrigger>
						<TooltipContent
							side="right"
							hidden={isStreaming}
							className="bg-white  text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect"
						>
							<p>Library</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
				{templateBuilderAccess && (
					<TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger
								className={isStreaming ? 'cursor-lock' : ''}
								disabled={isStreaming}
							>
								<SideBarMenuItem link={'/templates'} name={'template'} />
							</TooltipTrigger>
							<TooltipContent
								side="right"
								className="bg-white  text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect"
							>
								<p>Template Builder</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				)}
			</ul>
		</nav>
	);
};

export default SideBarMenu;
