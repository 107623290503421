import { SVGProps } from 'react';

const Info = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
		>
			<mask
				id="mask0_9852_17708"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="18"
				height="19"
			>
				<rect y="0.5" width="18" height="18" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_9852_17708)">
				<path
					d="M8.25 13.25H9.75V8.75H8.25V13.25ZM9 7.25C9.2125 7.25 9.39063 7.17812 9.53438 7.03438C9.67813 6.89062 9.75 6.7125 9.75 6.5C9.75 6.2875 9.67813 6.10938 9.53438 5.96562C9.39063 5.82187 9.2125 5.75 9 5.75C8.7875 5.75 8.60938 5.82187 8.46563 5.96562C8.32188 6.10938 8.25 6.2875 8.25 6.5C8.25 6.7125 8.32188 6.89062 8.46563 7.03438C8.60938 7.17812 8.7875 7.25 9 7.25ZM9 17C7.9625 17 6.9875 16.8031 6.075 16.4094C5.1625 16.0156 4.36875 15.4813 3.69375 14.8063C3.01875 14.1313 2.48438 13.3375 2.09063 12.425C1.69687 11.5125 1.5 10.5375 1.5 9.5C1.5 8.4625 1.69687 7.4875 2.09063 6.575C2.48438 5.6625 3.01875 4.86875 3.69375 4.19375C4.36875 3.51875 5.1625 2.98438 6.075 2.59063C6.9875 2.19687 7.9625 2 9 2C10.0375 2 11.0125 2.19687 11.925 2.59063C12.8375 2.98438 13.6313 3.51875 14.3063 4.19375C14.9813 4.86875 15.5156 5.6625 15.9094 6.575C16.3031 7.4875 16.5 8.4625 16.5 9.5C16.5 10.5375 16.3031 11.5125 15.9094 12.425C15.5156 13.3375 14.9813 14.1313 14.3063 14.8063C13.6313 15.4813 12.8375 16.0156 11.925 16.4094C11.0125 16.8031 10.0375 17 9 17ZM9 15.5C10.675 15.5 12.0938 14.9187 13.2563 13.7563C14.4187 12.5938 15 11.175 15 9.5C15 7.825 14.4187 6.40625 13.2563 5.24375C12.0938 4.08125 10.675 3.5 9 3.5C7.325 3.5 5.90625 4.08125 4.74375 5.24375C3.58125 6.40625 3 7.825 3 9.5C3 11.175 3.58125 12.5938 4.74375 13.7563C5.90625 14.9187 7.325 15.5 9 15.5Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Info;
