import { FC, useState, useCallback, ReactNode } from 'react';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import ProjectPage from '@/pages/ProjectPage/ProjectPage';
import FavoritePage from '@/pages/FavoritePage/FavoritePage';
import ArchivePage from '@/pages/ArchivePage/ArchivePage';
import styles from './DashboardTabs.module.css';
import { cn } from '@/lib/utils';
import useAuth from '@/lib/hooks/useAuth';
import { useSearchParams } from 'react-router-dom';

interface DashboardTabsProps {
	defaultTab: string;
}

const DashboardTabs: FC<DashboardTabsProps> = ({ defaultTab }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedTab, setSelectedTab] = useState(defaultTab);
	const [dirtyTab, setDirtyTab] = useState(defaultTab);
	const { isAdmin, isMember } = useAuth();

	const handleTabChange = useCallback((newTabIndex) => {
		setDirtyTab(newTabIndex);
		setTimeout(() => {
			newTabIndex === 'dashboard'
				? searchParams.delete('tab')
				: searchParams.set('tab', newTabIndex);
			setSearchParams(searchParams);
			setSelectedTab(newTabIndex);
		}, 500);
	}, []);

	const renderWrapper = (child: ReactNode) => {
		return (
			<div className={cn(styles.wrapper, ' rounded-tl-[30px] bg-background')}>
				{child}
			</div>
		);
	};

	return (
		<AppTabs
			defaultTab={defaultTab}
			selectedTab={selectedTab}
			handleTabChange={handleTabChange}
			type="main"
			tabs={[
				{
					label: 'Projects',
					value: 'dashboard',
					content: <ProjectPage exiting={dirtyTab !== 'dashboard'} />,
				},

				{
					label: 'Favorites',
					value: 'favorites',
					content: renderWrapper(
						<FavoritePage exiting={dirtyTab !== 'favorites'} />
					),
				},
				...(isAdmin || isMember
					? [
							{
								label: 'Archives',
								value: 'archives',
								content: renderWrapper(
									<ArchivePage exiting={dirtyTab !== 'archives'} />
								),
							},
						]
					: []),
			]}
		/>
	);
};

export default DashboardTabs;
