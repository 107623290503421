import { SVGProps } from 'react';

const Proposal = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M12.9582 0H5.04179C2.25729 0 0 2.13459 0 4.76775V13.2323C0 15.8654 2.25729 18 5.04179 18H12.9582C15.7427 18 18 15.8654 18 13.2323V4.76775C18 2.13459 15.7427 0 12.9582 0Z"
				fill="#E861C9"
			/>
			<path
				d="M12.2292 15.7404H5.76148C5.18774 15.7399 4.63764 15.5107 4.23195 15.1032C3.82625 14.6957 3.59813 14.1431 3.59766 13.5667V4.54279C3.59766 3.9663 3.82563 3.41343 4.23142 3.00579C4.63722 2.59815 5.1876 2.36914 5.76148 2.36914H12.2292C12.803 2.36961 13.3531 2.59877 13.7588 3.00631C14.1645 3.41385 14.3926 3.96645 14.3931 4.54279V13.5673C14.3926 14.1437 14.1645 14.6963 13.7588 15.1038C13.3531 15.5113 12.803 15.7405 12.2292 15.741M5.76148 3.32406C5.43988 3.32461 5.13161 3.45319 4.90421 3.68163C4.6768 3.91006 4.5488 4.21973 4.54826 4.54279V13.5673C4.54888 13.8903 4.67691 14.1999 4.90431 14.4283C5.13171 14.6567 5.43993 14.7852 5.76148 14.7858H12.2292C12.5506 14.785 12.8587 14.6565 13.0859 14.4282C13.3132 14.1999 13.4412 13.8905 13.4419 13.5676V4.54279C13.4413 4.21984 13.3134 3.91026 13.0861 3.68184C12.8588 3.45342 12.5507 3.32477 12.2292 3.32406H5.76148Z"
				fill="white"
			/>
			<path
				d="M11.3532 5.93838C11.2909 5.93838 11.2292 5.92606 11.1717 5.90211C11.1141 5.87817 11.0619 5.84308 11.0178 5.79884C10.9738 5.7546 10.9388 5.70208 10.915 5.64428C10.8912 5.58648 10.8789 5.52453 10.8789 5.46196V2.84465C10.8789 2.71802 10.929 2.59657 11.0181 2.50703C11.1073 2.41749 11.2281 2.36719 11.3542 2.36719C11.4803 2.36719 11.6012 2.41749 11.6903 2.50703C11.7794 2.59657 11.8295 2.71802 11.8295 2.84465V4.97631L13.9115 4.94445H13.9189C13.9813 4.94396 14.0432 4.95583 14.1011 4.97937C14.159 5.00292 14.2116 5.03768 14.2561 5.08167C14.3006 5.12566 14.336 5.17802 14.3603 5.23576C14.3847 5.2935 14.3974 5.35549 14.3979 5.41819C14.3984 5.48089 14.3866 5.54307 14.3632 5.60119C14.3397 5.6593 14.3051 5.71221 14.2613 5.7569C14.2175 5.80158 14.1654 5.83716 14.1079 5.8616C14.0505 5.88605 13.9888 5.89888 13.9263 5.89937L11.3591 5.93838H11.3532Z"
				fill="white"
			/>
			<path
				d="M9.46108 7.93566H6.25655C6.13049 7.93566 6.0096 7.88534 5.92046 7.79578C5.83133 7.70621 5.78125 7.58473 5.78125 7.45806C5.78125 7.3314 5.83133 7.20992 5.92046 7.12035C6.0096 7.03079 6.13049 6.98047 6.25655 6.98047H9.46108C9.58714 6.98047 9.70803 7.03079 9.79717 7.12035C9.88631 7.20992 9.93638 7.3314 9.93638 7.45806C9.93638 7.58473 9.88631 7.70621 9.79717 7.79578C9.70803 7.88534 9.58714 7.93566 9.46108 7.93566Z"
				fill="white"
			/>
			<path
				d="M11.6572 10.5236H6.25655C6.19413 10.5236 6.13233 10.5112 6.07466 10.4872C6.01699 10.4632 5.9646 10.428 5.92046 10.3837C5.87633 10.3393 5.84132 10.2867 5.81743 10.2287C5.79354 10.1708 5.78125 10.1087 5.78125 10.046C5.78125 9.98324 5.79354 9.92113 5.81743 9.86319C5.84132 9.80524 5.87633 9.75259 5.92046 9.70824C5.9646 9.6639 6.01699 9.62872 6.07466 9.60471C6.13233 9.58071 6.19413 9.56836 6.25655 9.56836H11.6572C11.7196 9.56836 11.7814 9.58071 11.8391 9.60471C11.8968 9.62872 11.9492 9.6639 11.9933 9.70824C12.0374 9.75259 12.0724 9.80524 12.0963 9.86319C12.1202 9.92113 12.1325 9.98324 12.1325 10.046C12.1325 10.1087 12.1202 10.1708 12.0963 10.2287C12.0724 10.2867 12.0374 10.3393 11.9933 10.3837C11.9492 10.428 11.8968 10.4632 11.8391 10.4872C11.7814 10.5112 11.7196 10.5236 11.6572 10.5236Z"
				fill="white"
			/>
			<path
				d="M11.6572 13.1075H6.25655C6.13049 13.1075 6.0096 13.0572 5.92046 12.9677C5.83133 12.8781 5.78125 12.7566 5.78125 12.6299C5.78125 12.5033 5.83133 12.3818 5.92046 12.2922C6.0096 12.2027 6.13049 12.1523 6.25655 12.1523H11.6572C11.7833 12.1523 11.9042 12.2027 11.9933 12.2922C12.0824 12.3818 12.1325 12.5033 12.1325 12.6299C12.1325 12.7566 12.0824 12.8781 11.9933 12.9677C11.9042 13.0572 11.7833 13.1075 11.6572 13.1075Z"
				fill="white"
			/>
		</svg>
	);
};

export default Proposal;
