import { SVGProps } from 'react';

const DeleteBlue = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<mask
				id="mask0_1218_14499"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1218_14499)">
				<path
					d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM12 13.9L13.9 15.8C14.0833 15.9833 14.3167 16.075 14.6 16.075C14.8833 16.075 15.1167 15.9833 15.3 15.8C15.4833 15.6167 15.575 15.3833 15.575 15.1C15.575 14.8167 15.4833 14.5833 15.3 14.4L13.4 12.5L15.3 10.6C15.4833 10.4167 15.575 10.1833 15.575 9.9C15.575 9.61667 15.4833 9.38333 15.3 9.2C15.1167 9.01667 14.8833 8.925 14.6 8.925C14.3167 8.925 14.0833 9.01667 13.9 9.2L12 11.1L10.1 9.2C9.91667 9.01667 9.68333 8.925 9.4 8.925C9.11667 8.925 8.88333 9.01667 8.7 9.2C8.51667 9.38333 8.425 9.61667 8.425 9.9C8.425 10.1833 8.51667 10.4167 8.7 10.6L10.6 12.5L8.7 14.4C8.51667 14.5833 8.425 14.8167 8.425 15.1C8.425 15.3833 8.51667 15.6167 8.7 15.8C8.88333 15.9833 9.11667 16.075 9.4 16.075C9.68333 16.075 9.91667 15.9833 10.1 15.8L12 13.9Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default DeleteBlue;
