import InfoNew from '@/components/ui/icons/new/TemplateBuilder/InfoNew';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import CustomDragLayer from '../../TemplateDnd/CustomDragLayer';
import { DndContext } from 'react-dnd';
import {
	changeNodeAtPath,
	SortableTreeWithExternalManager,
} from '@nosferatu500/react-sortable-tree';
import { TemplateAssemblerSkeleton } from '../../TemplateDnd/TemplateAssemblerSkeleton';
import { externalNodeType } from '@/lib/types/constants';
import {
	AssemblerSectionButton,
	SelectedSectionNodeProps,
} from '../../TemplateDnd/GenerateNodeProps';
import CustomThemeNodeContentRenderer from '../../TemplateDnd/NodeRenderer';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { SectionBuilder } from '@/lib/types/TemplateBuilder/templateTypes';
import {
	changeNodeDesign,
	getNodeKey,
} from '@/lib/functions/TemplateBuilder/templateUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	addDeletedSection,
	buildTemplate,
	toggleProposalTemplateEdit,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import {
	calculateTemplateTotalWordCount,
	ErrorHandle,
	generateSectionDetailsWithAI,
} from '@/lib/functions/funcUtils';
import useProposalCategory from '@/lib/hooks/useProposalCategory';
import QuestionBuilderInput from '@/components/QuestionBuilderInput/QuestionBuilderInput';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import { PlusIcon } from 'lucide-react';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
// import { setEditSectionData } from '@/redux/slices/TemplateBuilder/sectionSlice';
// import { openDialog } from '@/redux/slices/pageSlice';
// import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
// import { PlusIcon } from 'lucide-react';

interface TemplateDesignerDndProps {
	isNodeDragging: boolean;
	treeData: any[];
	setTreeData: (value: SetStateAction<any[]>) => void;
	removeNodeFromTree: (path: number[]) => void;
	selectedSection: SectionBuilder | null | undefined;
	selectedNodeData: (node: any) => void;
	setNodePath: Dispatch<SetStateAction<number[]>>;
	toggleSection: (value: React.SetStateAction<boolean>) => void;
	setSelectedSection: (
		value: React.SetStateAction<SectionBuilder | null | undefined>
	) => void;
}

const TemplateDesignerDnd: FC<TemplateDesignerDndProps> = ({
	isNodeDragging,
	treeData,
	setTreeData,
	removeNodeFromTree,
	selectedSection,
	selectedNodeData,
	setNodePath,
	toggleSection,
	setSelectedSection,
}) => {
	const templateBuilderData = useSelector(
		(state: RootState) => state.templates.templateBuilder
	);
	const dispatch = useAppDispatch();
	const proposalCategory = useProposalCategory();
	const organizationIdentifier = useOrganizationId();

	const [isLoading, setIsLoading] = useState(false);

	// Helper function to recursively collect all node IDs from the tree
	const getAllNodeIds = (treeData) => {
		const ids = new Set();

		const traverse = (nodes) => {
			nodes.forEach((node) => {
				ids.add(node.id);
				if (node.children && node.children.length > 0) {
					traverse(node.children);
				}
			});
		};

		traverse(treeData);
		return ids;
	};

	// Helper function to recursively find a node by its ID
	const findNodeById = (
		treeData: SectionBuilder[],
		id: string
	): SectionBuilder | null => {
		let found: SectionBuilder | null = null;

		const traverse = (nodes) => {
			for (const node of nodes) {
				if (node.id === id) {
					found = node;
					break;
				}
				if (node.children && node.children.length > 0) {
					traverse(node.children);
					if (found) break;
				}
			}
		};

		traverse(treeData);
		return found;
	};

	// Helper function to mark a node as new and pristine
	const markNodeAsNew = (treeData: SectionBuilder[], targetId) => {
		const traverseAndMark = (nodes) => {
			return nodes.map((node) => {
				if (node.id === targetId) {
					return {
						...node,
						isNew: true,
						isPristine: true,
					};
				}
				if (node.children && node.children.length > 0) {
					return {
						...node,
						children: traverseAndMark(node.children),
					};
				}
				return node;
			});
		};

		return traverseAndMark(treeData);
	};

	const handleTreeChange = (newTreeData: SectionBuilder[]) => {
		dispatch(toggleProposalTemplateEdit(true));

		// Collect all node IDs from the previous and new tree data
		const previousNodeIds = Array.from(getAllNodeIds(treeData)); // Convert Set to Array
		const currentNodeIds = Array.from(getAllNodeIds(newTreeData)); // Convert Set to Array

		// Detect added nodes (present in current but not in previous)
		const addedNodes = currentNodeIds.filter(
			(id) => !previousNodeIds.includes(id)
		);

		// Detect removed nodes (present in previous but not in current)
		const removedNodes = previousNodeIds.filter(
			(id) => !currentNodeIds.includes(id)
		);

		// Handle added nodes
		if (addedNodes.length > 0) {
			addedNodes.forEach((id) => {
				const addedNode = findNodeById(newTreeData, id as string);
				if (addedNode) {
					// Mark the node as new and pristine

					newTreeData = markNodeAsNew(newTreeData, id);
				}
			});
		}

		// Handle removed nodes
		if (removedNodes.length > 0) {
			removedNodes.forEach((id) => {
				const removedNode = findNodeById(treeData, id as string);
				if (removedNode) {
					dispatch(addDeletedSection(removedNode?.systemName));
				}
			});
		}
		// Update Redux store if there are changes
		if (templateBuilderData) {
			const updatedTemplateBuilder = {
				...templateBuilderData,
				sections: newTreeData,
			};
			dispatch(buildTemplate(updatedTemplateBuilder));
		}

		// Update the tree data state
		setTreeData(newTreeData);
	};

	const handleMoveNode = ({ treeData, node, nextPath, nextParentNode }) => {
		// Set the path and handle node selection/design changes
		setNodePath(nextPath);
		// update prerequisites if the node move or newly added
		const updatedNode = {
			...node,
			prerequisites: nextParentNode
				? Array.isArray(node.prerequisites) && node.prerequisites.length > 0
					? Array.from(
							new Set([...node.prerequisites, nextParentNode.systemName])
						)
					: [nextParentNode.systemName]
				: node.prerequisites,
		};
		selectedNodeData(updatedNode);
		//if moved node has parent then update its prerequisites
		if (nextParentNode) {
			const updatedData = changeNodeAtPath({
				treeData,
				path: nextPath,
				getNodeKey,
				newNode: (oldNode) => ({
					...oldNode.node,
					prerequisites: nextParentNode
						? Array.isArray(oldNode.node.prerequisites) &&
							node.prerequisites.length > 0
							? Array.from(
									new Set([
										...oldNode.node.prerequisites,
										nextParentNode.systemName,
									])
								)
							: [nextParentNode.systemName]
						: oldNode.node.prerequisites,
				}),
			});
			handleTreeChange(updatedData as any);
		}
		setTimeout(() => {
			changeNodeDesign(node);
		}, 100);
	};

	const handleAddQuestion = async (questionText: string, wordCount: number) => {
		try {
			setIsLoading(true);
			const sectionDetails = await generateSectionDetailsWithAI(
				dispatch,
				questionText,
				wordCount,
				organizationIdentifier
			);
			sectionDetails.isNew = true;
			if (proposalCategory === 'questionnaire') {
				sectionDetails.alias = sectionDetails.name;
			}
			handleTreeChange([sectionDetails, ...treeData]);
			setIsLoading(false);
		} catch (error: any) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	return (
		<div
			className={`template-node relative bg-white p-5 rounded-[16px] selected-sections shadow-main ${proposalCategory === 'questionnaire' ? 'col-span-6' : 'col-span-5'}`}
		>
			<div className="flex items-center justify-between gap-2">
				<div className="flex items-center gap-2">
					<h2 className="font-bold text-black dynamic-xl-large">
						Template Assembler
					</h2>
					<TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger type="button">
								<InfoNew />
							</TooltipTrigger>
							<TooltipContent
								align="start"
								side="top"
								className="max-w-sm px-4 overflow-y-auto text-black break-words bg-white border-none shadow-none text-wrap dynamic-small rounded-xl drop-shadow-effect"
							>
								You can drag and drop sections from the available sections list
								here to build out a template. Push sections under a main section
								to make it a subsection.
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<p className="font-medium text-secondary">
					Total words:{' '}
					{calculateTemplateTotalWordCount(
						templateBuilderData?.sections as SectionBuilder[]
					)?.toLocaleString()}
				</p>
			</div>
			<p className="py-3 text-black ">
				You can drag and drop sections from the available sections list here to
				build out a template. Push sections under a main section to make it a
				subsection.
			</p>
			{proposalCategory === 'questionnaire' && (
				<div className="mb-2">
					<QuestionBuilderInput
						onSave={handleAddQuestion}
						SaveBtn={
							<>
								<PlusIcon />
								Add New Section
							</>
						}
					/>
				</div>
			)}
			<div
				className={cn(
					`relative h-[calc(100vh-250px)] 2xl:h-[calc(100vh-350px)] template-assembler px-6 z-10`,
					isNodeDragging ? 'node-dragging' : ''
				)}
			>
				<CustomDragLayer screenWidth={1600} />
				<DndContext.Consumer>
					{({ dragDropManager }) =>
						!dragDropManager ? undefined : (
							<SortableTreeWithExternalManager
								dragDropManager={dragDropManager}
								treeData={treeData}
								placeholderRenderer={TemplateAssemblerSkeleton}
								onChange={handleTreeChange}
								maxDepth={proposalCategory === 'questionnaire' ? 0 : 3}
								dndType={externalNodeType}
								className="font-bold dynamic-medium"
								style={{ height: '100%' }}
								generateNodeProps={({ node, path }) => ({
									buttons: [
										<AssemblerSectionButton
											node={node}
											path={path}
											selectedSection={selectedSection}
											removeNodeFromTree={removeNodeFromTree}
										/>,
									],
									title: <SelectedSectionNodeProps node={node} />,
								})}
								nodeContentRenderer={(nodeInfo) => (
									<CustomThemeNodeContentRenderer
										{...nodeInfo}
										{...nodeInfo.nodeProps} // Access nodeProps within the callback
										selectedNodeData={selectedNodeData}
										setNodePath={setNodePath}
										showWordCount={true}
									/>
								)}
								onMoveNode={handleMoveNode}
								onVisibilityToggle={() => {
									changeNodeDesign(selectedSection, true);
									toggleSection(false);
									setSelectedSection(undefined);
								}}
							/>
						)
					}
				</DndContext.Consumer>
				{!isNodeDragging && (
					<div className="absolute left-0 bottom-0 right-0 w-full h-[5px] 2xl:h-[10px] bg-gradient-to-b from-transparent to-[rgba(255,255,255,1)]" />
				)}
			</div>
			{isLoading && <LoaderTransparent />}
		</div>
	);
};

export default TemplateDesignerDnd;
