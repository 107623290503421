import { FC, useCallback, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import * as z from 'zod';
import {
	ToneOfVoice,
	ToneOfVoiceFormData,
	ToneOfVoiceViewProps,
} from '@/lib/types/apiTypes';
import ToneOfVoiceChart from '../ToneOfVoiceChart/ToneOfVoiceChart';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import { PencilIcon } from 'lucide-react';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';

const collaborative = 'collaborative';
const collaborativeExampleText = 'We the organization and our partners';

const firstPerson = 'first_person';
const firstPersonExampleText = 'We the organization';

const defaultCitationFormat = 'APA (American Psychological Association)';

const formSchema = z.object({
	analytical: z.number(),
	authoritative: z.number(),
	concise: z.number(),
	emotive: z.number(),
	sampleText: z.string().optional(),
	writingStyle: z.object({
		name: z.string(),
		exampleText: z.string(),
	}),
	citationFormat: z.string(),
});

const ToneOfVoiceView: FC<ToneOfVoiceViewProps> = ({
	toneOfVoice,
	type,
	onEditTone,
}) => {
	const [toneOfVoiceData, setToneOfVoiceData] = useState<
		ToneOfVoice | undefined
	>(toneOfVoice);
	const form = useForm<ToneOfVoiceFormData>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			analytical: 20,
			authoritative: 20,
			concise: 20,
			emotive: 20,
			sampleText: '',
			writingStyle: {
				name: collaborative,
				exampleText: collaborativeExampleText,
			},
			citationFormat: defaultCitationFormat,
		},
	});

	const setFormValues = useCallback(
		(toneOfVoice: ToneOfVoice) => {
			toneOfVoice.tone.forEach((toneItem) => {
				switch (toneItem.name) {
					case 'Analytical':
						form.setValue('analytical', toneItem.percentage);
						break;
					case 'Authoritative':
						form.setValue('authoritative', toneItem.percentage);
						break;
					case 'Concise':
						form.setValue('concise', toneItem.percentage);
						break;
					case 'Emotive':
						form.setValue('emotive', toneItem.percentage);
						break;
				}
			});
			form.setValue('sampleText', toneOfVoice.sampleText || '');
			form.setValue(
				'writingStyle.name',
				toneOfVoice.writingStyle.name || collaborative
			);
			form.setValue(
				'writingStyle.exampleText',
				toneOfVoice.writingStyle.exampleText || collaborativeExampleText
			);
			form.setValue(
				'citationFormat',
				toneOfVoice.citationFormat || defaultCitationFormat
			);
		},
		[form]
	);

	useWatch(form);

	const formData = form.getValues();

	useEffect(() => {
		if (toneOfVoice) {
			setToneOfVoiceData(toneOfVoice);
		}
	}, [setFormValues, toneOfVoice]);

	useEffect(() => {
		if (toneOfVoiceData) {
			setFormValues(toneOfVoiceData);
		}
	}, [setFormValues, toneOfVoiceData]);

	useEffect(() => {
		switch (formData.writingStyle.name) {
			case collaborative:
				form.setValue('writingStyle.exampleText', collaborativeExampleText);
				break;
			case firstPerson:
				form.setValue('writingStyle.exampleText', firstPersonExampleText);
				break;
		}
	}, [form, formData.writingStyle.name]);

	return (
		<div className="flex flex-col gap-4 tone-of-voice">
			<div className="flex flex-col gap-2">
				<div className="flex flex-col gap-1">
					<div className="flex justify-between">
						<h3 className="col-span-2 font-bold text-black dynamic-medium">
							Tone of voice of your{' '}
							{type === 'organization' ? 'organization' : 'proposal'}
						</h3>
						{type === 'proposal' && (
							<TextButton
								type="button"
								onClick={onEditTone}
								className="h-auto p-0 2xl:h-auto tone-edit"
							>
								<PencilIcon size={16} /> CHANGE TONE OF VOICE
							</TextButton>
						)}
					</div>
					<p className="text-black">
						This is the default tone of voice set by your organization. You can
						update it for this proposal by pressing edit.
					</p>
				</div>
				<ToneOfVoiceChart toneOfVoice={toneOfVoice} />
			</div>
			<div className="tone-sample flex flex-col col-span-2 p-4 border-[#D3DDE2] border rounded-xl hidden-scroll max-h-[285px] overflow-auto">
				<h3 className="font-bold text-black">Tone of voice sample</h3>
				<p className="text-black dynamic-small">{formData.sampleText}</p>
			</div>
			{type === 'organization' && (
				<SecondaryButton className="w-fit" type="button" onClick={onEditTone}>
					EDIT TONE OF VOICE
				</SecondaryButton>
			)}
		</div>
	);
};

export default ToneOfVoiceView;
