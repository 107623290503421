import { SVGProps } from 'react';

const Brainstorm = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="16"
			viewBox="0 0 20 16"
			fill="none"
			{...props}
		>
			<g clipPath="url(#clip0_14648_16812)">
				<path
					d="M13.9824 2.98415C13.5262 1.89678 12.4513 1.14062 11.1952 1.14062H3.95236C2.28382 1.14062 0.927734 2.49671 0.927734 4.16525V7.30862C0.927734 8.42098 1.53391 9.38337 2.42755 9.9083L1.27144 12.3767L3.92112 10.327C3.92112 10.327 3.93987 10.327 3.95236 10.327H4.83351"
					stroke="#E861C9"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.25803 4.30312H16.5071C18.1757 4.30312 19.5318 5.65921 19.5318 7.32775V10.4711C19.5318 11.5835 18.9256 12.5459 18.032 13.0708L19.1881 15.5393L15.2885 13.4895C15.2885 13.4895 15.2698 13.4895 15.2573 13.4895H9.25803C7.58948 13.4895 6.2334 12.1334 6.2334 10.4649V7.3215C6.2334 5.65296 7.58948 4.29688 9.25803 4.29688V4.30312Z"
					stroke="#5D9BFD"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M15.5251 11.4915H15.1813C14.8751 11.4915 14.6189 11.2415 14.6189 10.9353C14.6189 10.9353 14.6189 10.9228 14.6189 10.9165V6.86705C14.6314 6.56083 14.8751 6.32336 15.1813 6.32336H15.5251C15.6938 6.32336 15.825 6.18588 15.825 6.0234C15.825 5.86092 15.6875 5.72344 15.5251 5.72344H15.1813C14.8564 5.72344 14.5439 5.86717 14.3252 6.10464C14.1065 5.85467 13.7878 5.71094 13.4503 5.71094H13.1066C12.9379 5.71094 12.8066 5.84842 12.8066 6.0109C12.8066 6.17338 12.9441 6.31086 13.1066 6.31086H13.4503C13.7565 6.31086 14.0127 6.56083 14.0127 6.86705C14.0127 6.87329 14.0127 6.87954 14.0127 6.88579V10.9353C14.0002 11.2415 13.7565 11.479 13.4503 11.479H13.1066C12.9379 11.479 12.8066 11.6165 12.8066 11.7789C12.8066 11.9414 12.9441 12.0789 13.1066 12.0789H13.4503C13.7753 12.0789 14.0877 11.9414 14.3065 11.6977C14.5252 11.9477 14.8439 12.0914 15.1813 12.0914H15.5251C15.6938 12.0914 15.825 11.9539 15.825 11.7914C15.825 11.629 15.6875 11.4915 15.5251 11.4915Z"
					fill="#5D9BFD"
				/>
				<path
					d="M12.4445 7.26167C12.7133 7.53664 12.8445 7.94908 12.8445 8.49902V10.4675C12.8445 10.6113 12.8008 10.73 12.7133 10.8112C12.6258 10.8925 12.507 10.9362 12.3571 10.9362C12.2196 10.9362 12.1008 10.8925 12.0133 10.805C11.9259 10.7175 11.8821 10.605 11.8821 10.4675V10.2925C11.7884 10.505 11.6446 10.6675 11.4509 10.7862C11.2572 10.905 11.026 10.9612 10.7697 10.9612C10.5135 10.9612 10.2636 10.905 10.0511 10.7987C9.83861 10.6925 9.66363 10.5425 9.5449 10.355C9.41991 10.1676 9.35742 9.95509 9.35742 9.72387C9.35742 9.43015 9.43241 9.20518 9.58239 9.03645C9.73238 8.86772 9.96985 8.74899 10.3073 8.674C10.6385 8.59901 11.101 8.56151 11.6884 8.56151H11.8884V8.37403C11.8884 8.11156 11.8321 7.91784 11.7196 7.7991C11.6071 7.68037 11.4259 7.61788 11.1697 7.61788C11.0135 7.61788 10.8572 7.64287 10.6948 7.68662C10.5323 7.73036 10.3448 7.7991 10.1198 7.88659C9.98235 7.95533 9.87611 7.99283 9.81362 7.99283C9.71988 7.99283 9.63864 7.95533 9.57615 7.88659C9.51365 7.81785 9.48241 7.72411 9.48241 7.61163C9.48241 7.51789 9.5074 7.4429 9.55115 7.37416C9.59489 7.30541 9.67613 7.24292 9.78237 7.18668C9.96985 7.08669 10.1948 7.00545 10.4573 6.94296C10.7198 6.88671 10.976 6.85547 11.2259 6.85547C11.7759 6.85547 12.1821 6.99295 12.4508 7.26167H12.4445ZM11.6259 9.96759C11.7946 9.78636 11.8821 9.55514 11.8821 9.26767V9.09894H11.7384C11.3759 9.09894 11.101 9.11769 10.901 9.14894C10.701 9.18018 10.5573 9.23643 10.4698 9.31767C10.3823 9.39891 10.3386 9.51139 10.3386 9.64888C10.3386 9.82385 10.401 9.96134 10.5198 10.0738C10.6385 10.1863 10.7885 10.2426 10.9697 10.2426C11.2384 10.2426 11.4572 10.1551 11.6259 9.97384V9.96759Z"
					fill="#5D9BFD"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14648_16812">
					<rect
						width="19.5414"
						height="15.3294"
						fill="white"
						transform="translate(0.458984 0.671875)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Brainstorm;
