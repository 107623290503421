import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './LogicalFrame.module.css';
import { cn } from '@/lib/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import TopPanel from '@/components/TopPanel/TopPanel';
import { Section } from '@/lib/types/apiTypes';
import MainCard from '@/components/WriteMode/MainCard/MainCard';
import AssistanceTab from '@/components/AssistanceTab/AssistanceTab';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import useProposalId from '@/lib/hooks/useProposalId';
import LogicalFrameDrawer from '@/components/Drawers/LogicalFrameDrawer/LogicalFrameDrawer';

interface LogicalFrameProps {}

const LogicalFrame: FC<LogicalFrameProps> = () => {
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const [selectedTab, setSelectedTab] = useState('assistant');
	const scrollParentRef = useRef<HTMLDivElement>(null);
	const parentRef = useRef<HTMLDivElement>(null);
	const proposalId = useProposalId();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!proposalId) return;
		dispatch(fetchProposalById(Number(proposalId)));
	}, [proposalId, dispatch]);

	const logicalFrameSection = useMemo(() => {
		if (!proposal) return [];
		return proposal.sections.filter((sec) => sec.type === 'logical-framework');
	}, [proposal]);

	return (
		<div className={cn(styles.wrapper, 'grid grid-cols-12 gap-6 pt-4')}>
			<div className="col-span-12 mt-2 2xl:col-span-9">
				<div className="px-6 2xl:pr-0 2xl:pl-5">
					<TopPanel
						sections={proposal?.sections as Section[]}
						scrollParentRef={scrollParentRef}
						mode={'logicalframe'}
					/>
					<div
						ref={scrollParentRef}
						id="writeCardWrapper"
						className="relative pb-16 mx-auto h-[calc(100vh-120px)] overflow-hidden overflow-y-scroll w-[1280px] max-w-full -top-3 scroll-smooth"
					>
						<div
							ref={parentRef}
							className={cn(
								styles.card,
								' relative flex flex-col pb-2 scroll-smooth '
							)}
						>
							{logicalFrameSection &&
								logicalFrameSection.map((sec, index) => (
									<MainCard
										key={sec.id}
										section={sec}
										topLevelHeadingNumber={index + 1}
										parentRef={parentRef}
										scrollParentRef={scrollParentRef}
										topRoundedNone={index === 0}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
			<div className="col-span-3 2xl:block">
				<AppTabs
					defaultTab="assistant"
					type="secondary"
					handleTabChange={(val: string) => {
						setSelectedTab(val);
					}}
					selectedTab={selectedTab}
					tabs={[
						{
							label: 'Assistance',
							value: 'assistant',
							content: <AssistanceTab mode="logical-frame" />,
						},
					]}
				/>
			</div>
			<div className="block 2xl:hidden">
				<LogicalFrameDrawer />
			</div>
		</div>
	);
};

export default LogicalFrame;
