import { SVGProps } from 'react';

const TemplateAssemblerDrag = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="185"
			height="153"
			viewBox="0 0 185 153"
			fill="none"
		>
			<g clipPath="url(#clip0_6690_12100)">
				<path
					d="M164.876 58.7402H88.6855C78.7444 58.7402 70.6855 66.7991 70.6855 76.7402V85.6202C70.6855 95.5614 78.7444 103.62 88.6855 103.62H164.876C174.817 103.62 182.876 95.5614 182.876 85.6202V76.7402C182.876 66.7991 174.817 58.7402 164.876 58.7402Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M164.244 2.93945H20.7441C10.803 2.93945 2.74414 10.9983 2.74414 20.9395V29.8195C2.74414 39.7606 10.803 47.8195 20.7441 47.8195H164.244C174.185 47.8195 182.244 39.7606 182.244 29.8195V20.9395C182.244 10.9983 174.185 2.93945 164.244 2.93945Z"
					fill="#F0F0F0"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M96.315 81.5488H20.125C10.1839 81.5488 2.125 89.6077 2.125 99.5488V108.429C2.125 118.37 10.1839 126.429 20.125 126.429H96.315C106.256 126.429 114.315 118.37 114.315 108.429V99.5488C114.315 89.6077 106.256 81.5488 96.315 81.5488Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M126.775 73.5098V88.8498L121.385 83.2898"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M126.775 88.8491L132.345 83.2891"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M99.4937 141.269L92.5837 116.349C92.2937 115.309 93.2537 114.349 94.2937 114.629L119.324 121.459C120.354 121.739 120.694 123.029 119.944 123.789L116.344 127.389C115.804 127.929 115.804 128.819 116.344 129.359L128.444 141.459C128.984 141.999 128.984 142.889 128.444 143.429L122.034 149.839C121.494 150.379 120.604 150.379 120.064 149.839L107.904 137.679C107.354 137.129 106.464 137.139 105.924 137.679L101.824 141.859C101.074 142.629 99.7737 142.289 99.4837 141.259L99.4937 141.269Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M68.4141 150.25L83.1641 135.5"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M82.3633 145.36L92.2333 135.5"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M34.1445 47.8203V67.6803H70.6845"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6690_12100">
					<rect
						width="184.75"
						height="151.31"
						fill="white"
						transform="translate(0.125 0.939453)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default TemplateAssemblerDrag;
