import styles from './TrialBanner.module.css';
import { Clock5 } from 'lucide-react';
import { cn } from '@/lib/utils';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { triggerTrialBanner } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { sendInquiryEmail } from '@/lib/functions/apiCalls';
import customToast from '../CustomToast/CustomToast';
import { updateTrialBannerDelayDate } from '@/redux/slices/authSlice';
import { handleShowTrialBanner } from '@/lib/functions/funcUtils';
import { useEffect } from 'react';

const TrialBanner = () => {
	const trialBanner = useSelector((root: RootState) => root.page.trialBanner);
	const dispatch = useAppDispatch();
	const daysUntilTrialEnds = useSelector(
		(root: RootState) => root.auth.user?.organization?.daysUntilTrialEnds
	);
	const email = useSelector((root: RootState) => root.auth.user?.email);

	const plan = useSelector(
		(root: RootState) => root.auth.user?.organization?.plan?.type
	);

	const trialBannerDelayDate = useSelector(
		(root: RootState) => root.auth.trialBannerDelayDate
	);
	const planEndDate = useSelector(
		(root: RootState) => root.auth.user?.organization?.plan?.endDate
	);

	const tourRunning = useSelector((state: RootState) => state.tour.run);

	const handleSendInquiry = async () => {
		if (!email) return;
		try {
			const response = await sendInquiryEmail({
				email: email,
			});
			if (response.statusCode === 201) {
				handleLaterClick();
				customToast.success({
					title: response.data as string,
				});
			}
		} catch (error: unknown) {
			customToast.error({
				title: 'Something went wrong',
			});
		}
	};

	const handleLaterClick = () => {
		// Dispatch action to hide the banner
		dispatch(triggerTrialBanner(false));

		// Update tiral banner delay date to snooze banner
		dispatch(updateTrialBannerDelayDate(String(new Date())));
	};

	useEffect(() => {
		if (plan === 'trial' && planEndDate) {
			handleShowTrialBanner(dispatch, planEndDate, trialBannerDelayDate);
		}
	}, [dispatch, plan, planEndDate, trialBannerDelayDate]);

	if (plan !== 'trial' || tourRunning) {
		return null;
	}

	return (
		<div
			className={cn(
				styles.banner,
				'flex justify-between items-center gap-3',
				trialBanner ? '' : 'hidden'
			)}
		>
			<div className="flex gap-3">
				<Clock5 className="text-primary" size={32} />
				<div>
					<p className="text-black">
						<span className="font-bold">
							Your trial ends in {daysUntilTrialEnds ?? 0} day(s).
						</span>{' '}
						During this trial, you are limited to{' '}
						<span className="font-bold">5 proposals</span> and can only upload{' '}
						<span className="font-bold">20 documents</span> (deleted documents
						count)
					</p>
					<p
						className="font-bold cursor-pointer text-primary"
						onClick={handleSendInquiry}
					>
						To be able to use all the features available in Grant Assistant,
						please click here{' '}
					</p>
				</div>
			</div>
			<PrimaryButton onClick={handleLaterClick}>Later</PrimaryButton>
		</div>
	);
};

export default TrialBanner;
