import { FC } from 'react';
import Bar from './Bar';
import Pie from './Pie';
import Line from './Line';
import { ChartType } from '@/lib/types/apiTypes';
import Area from './Area';
import Bubble from './Bubble';
import Doghnut from './Doghnut';
import Histogram from './Histogram';
import Scatter from './Scatter';

interface ChartIconsProps {
	type: ChartType;
}

const ChartIcons: FC<ChartIconsProps> = ({ type }) => {
	switch (type) {
		case 'bar':
			return <Bar />;
		case 'pie':
			return <Pie />;
		case 'line':
			return <Line />;
		case 'area':
			return <Area />;
		case 'bubble':
			return <Bubble />;
		case 'doughnut':
			return <Doghnut />;
		case 'histogram':
			return <Histogram />;
		case 'scatter':
			return <Scatter />;
		default:
			return <Bar />;
	}
};

export default ChartIcons;
