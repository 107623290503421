import { FC, useState } from 'react';
import styles from './ResetPassword.module.css';
import LogoLarge from '@/components/ui/icons/LogoLarge';
import { cn } from '@/lib/utils';
import ResetPasswordForm from '@/components/ResetPasswordForm/ResetPasswordForm';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import useUser from '@/lib/hooks/useUser';
import { Navigate } from 'react-router';

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = () => {
	const [isLoading, setIsLoading] = useState(false);
	const user = useUser();

	if (user) {
		return <Navigate to="/" replace />;
	}
	if (isLoading) return <LoaderTransparent />;

	return (
		<div
			className={cn(
				styles.container,
				'flex relative min-h-screen h-full py-20'
			)}
		>
			<div className={cn(styles.white_box, 'm-auto gap-6 shadow-main')}>
				<div className="flex flex-col items-center justify-center gap-10">
					<LogoLarge className="h-28 2xl:h-36" />
					<div className="flex flex-col items-center justify-center gap-2">
						<h1 className="font-bold text-black dynamic-xl-large">
							Reset Password
						</h1>
					</div>
					<ResetPasswordForm setIsLoading={setIsLoading} />
				</div>
			</div>
			<div className={styles.pink_box}>
				<div className="flex items-center justify-center w-full px-12 py-6 text-base font-normal text-white xl:text-lg 2xl:text-xl">
					<p>© 2024 GRANT ASSISTANT. All rights reserved.</p>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
