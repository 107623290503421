import { getAllChartsOfAProposal } from '@/lib/functions/apiCalls';
import {
	Chart,
	ChartsRecommendation,
	ChartsResponse,
} from '@/lib/types/apiTypes';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
	file: File | null;
	chartData: ChartsRecommendation[];
	selectedChart: string;
	selectedChartPlot: string;
	generatedChart: Chart | null;
	proposalCharts: {
		id: number;
		imageUrl: string;
		config: ChartsResponse;
	}[];
	loading: boolean;
}

const initialState: AuthState = {
	file: null,
	chartData: [],
	selectedChart: '',
	selectedChartPlot: '',
	generatedChart: null,
	proposalCharts: [],
	loading: false,
};

// redux function to fetch all proposalCharts

export const fetchAllProposalCharts = createAsyncThunk(
	'chart/fetchAllProposalCharts',
	async (proposalId: number, { rejectWithValue }) => {
		try {
			const response = await getAllChartsOfAProposal(proposalId);

			if (response.statusCode === 200) {
				const charts = (response.data as any).charts;
				return charts;
			}
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const chartSlice = createSlice({
	name: 'chart',
	initialState,
	reducers: {
		resetChartData(state) {
			state.chartData = [];
			state.selectedChart = '';
			state.selectedChartPlot = '';
			state.generatedChart = null;
			state.proposalCharts = [];
		},
		setFile(state, action: PayloadAction<File | null>) {
			state.file = action.payload;
		},
		setChartData(state, action: PayloadAction<ChartsRecommendation[]>) {
			state.chartData = action.payload;
		},
		setSelectedChart(state, action: PayloadAction<string>) {
			state.selectedChart = action.payload;
		},
		setSelectedChartPlot(state, action: PayloadAction<string>) {
			state.selectedChartPlot = action.payload;
		},
		setGeneratedChart(state, action: PayloadAction<Chart | null>) {
			state.generatedChart = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAllProposalCharts.fulfilled, (state, action) => {
			state.loading = false;
			state.proposalCharts = action.payload;
		});
		builder.addCase(fetchAllProposalCharts.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchAllProposalCharts.rejected, (state) => {
			state.loading = false;
		});
	},
});

export const {
	setFile,
	setChartData,
	setSelectedChart,
	setSelectedChartPlot,
	setGeneratedChart,
	resetChartData,
} = chartSlice.actions;
export default chartSlice.reducer;
