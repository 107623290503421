import { MutableRefObject, RefObject, createContext, useCallback } from 'react';
import { useAppDispatch } from '../hooks/hooks';
import { EventSourcePolyfill } from 'event-source-polyfill';
import {
	constructMagicPayload,
	fetchProposal,
	getToken,
} from '../functions/funcUtils';
import {
	updateSectionState,
	writeASectionWithMagic,
} from '../functions/apiCalls';
import {
	setProposal,
	setWriterLoader,
	updateSection,
} from '@/redux/slices/proposalSlice';
import { MAGIC_TYPES, Proposal } from '../types/apiTypes';
import { stopStreaming } from '@/redux/slices/pageSlice';
import customToast from '@/components/CustomToast/CustomToast';
import {
	clearSectionData,
	insertTableToEditor,
	updateSectionStateInProposal,
} from '../functions/magicFunctions';
import { fetchSectionRules } from '@/redux/requests/compliance';
import { updateLastAIResponse } from '@/redux/slices/enhanceSlice';
import { triggerUpdateSection } from '../observables/observables';

export const MagicContext = createContext<
	| ((
			proposalId: number,
			sectionId: string,
			dataRef: MutableRefObject<string>,
			editorRef: RefObject<any>,
			expectedOutput: string,
			magicType: MAGIC_TYPES,
			payload?: any
	  ) => Promise<void>)
	| null
>(null);

export const MagicProvider = ({ children }) => {
	const dispatch = useAppDispatch();

	const initializeEventSource = useCallback(
		(token: string, proposalId: number, sectionId: string) => {
			return new EventSourcePolyfill(
				`${
					import.meta.env.VITE_SERVER_URL
				}/api/proposal/${proposalId}/section/${sectionId}/sse`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
		},
		[]
	);
	const handleMagic = useCallback(
		async (
			proposalId: number,
			sectionId: string,
			dataRef: MutableRefObject<string>,
			editorRef: RefObject<any>,
			expectedOutput: string,
			magicType: MAGIC_TYPES,
			payload?: any
		) => {
			let eventSource: any;
			try {
				const token = getToken();
				eventSource = initializeEventSource(token, proposalId, sectionId);
				const magicPayload = constructMagicPayload(magicType, payload);

				await writeASectionWithMagic(
					Number(proposalId),
					Number(sectionId),
					magicPayload
				);

				clearSectionData(magicType, Number(sectionId));

				eventSource.onopen = (event) => {
					console.log('Connection opened', event);
				};

				eventSource.onerror = (event) => {
					console.log('Connection error', event);
					dispatch(stopStreaming());
					dispatch(setWriterLoader(false));
					eventSource.close();
				};

				eventSource.onmessage = (ev: any) => {
					const eventData = {
						data: typeof ev.data === 'string' ? JSON.parse(ev.data) : ev.data,
						lastEventId: ev.lastEventId,
					};

					processEventSourceMessage(eventData, eventSource);
				};

				const processEventSourceMessage = async (
					eventData: any,
					eventSource: any
				) => {
					if (eventData.data.eventType === 'llm_execution_continue') {
						handleContinueEvent(eventData);
					} else if (eventData.data.eventType === 'llm_execution_end') {
						handleEndEvent();
						eventSource.close();
					}
				};

				const handleContinueEvent = (eventData: any) => {
					if (editorRef.current) {
						if (expectedOutput === 'text') {
							insertTextToEditor(eventData.data.data.token);
						} else {
							const { content, editorState } = insertTableToEditor(
								dataRef,
								eventData.data.data.token,
								editorRef
							);

							dispatch(
								updateSection({
									content: content,
									editorState: editorState,
									sectionId: Number(sectionId),
								})
							);
						}
					}
				};

				const handleEndEvent = async () => {
					dispatch(stopStreaming());
					const editor = editorRef.current;
					if (!editor) return;
					const htmlContent = editor?.getData(); // This gets you the HTML content

					let modelText = '';
					const root = editor.model.document.getRoot();
					for (const child of root.getChildren()) {
						for (const item of child.getChildren()) {
							if (item.is('text')) {
								modelText += item.data + ' ';
							}
						}
					}

					if (expectedOutput === 'text') {
						updateSectionStateInProposal(
							magicType,
							proposalId,
							sectionId,
							modelText,
							htmlContent
						);
						dispatch(
							fetchSectionRules({ proposalId, sectionId: Number(sectionId) })
						);
					} else {
						const cleanedText = dataRef.current
							.replace('```html', '')
							.replace('```', '')
							.trim();
						const sectionResponse = await updateSectionState(
							proposalId,
							Number(sectionId),
							{
								content: modelText.trim(),
								editorState: cleanedText,
							}
						);
						if (sectionResponse.statusCode === 200) {
							triggerUpdateSection();
							if (magicType === MAGIC_TYPES.ENHANCE_WITH_ME) {
								dispatch(
									updateLastAIResponse({
										content: cleanedText,
										sectionId: Number(sectionId),
									})
								);
							}

							const proposal = (await fetchProposal(proposalId)) as Proposal;
							dispatch(setProposal(proposal));
							dispatch(
								fetchSectionRules({ proposalId, sectionId: Number(sectionId) })
							);
						}
					}
				};

				const insertTextToEditor = (token: string) => {
					const editor = editorRef.current;
					const model = editor.model;
					// Get the current selection

					const cursor_position = model.document.selection.getLastPosition();

					model.change((writer) => {
						if (token === '\n\n') {
							//create new paragraph element
							const selection = model.document.selection;
							// Find the block element that the selection anchor is inside
							const currentBlock = selection.anchor.parent;
							// Determine the position directly after the current block
							const insertPosition = writer.createPositionAfter(currentBlock);
							// const breakpoint = writer.createElement('breakpoint');
							const paragraph = writer.createElement('paragraph');
							// writer.insert(breakpoint, insertPosition);
							writer.insert(paragraph, insertPosition);

							writer.setSelection(paragraph, 'in');
						} else {
							writer.setSelection(cursor_position, 0);
							writer.insertText(token, cursor_position);
						}
					});
				};
			} catch (error) {
				console.error('Error in handleMagic:', error);
				if (magicType === MAGIC_TYPES.ENHANCE_WITH_ME) {
					customToast.error({
						title:
							'Error! You need to have at least 100 words in this section before you can start using this feature',
					});
				} else {
					customToast.error({
						title: 'Oops! Looks like something went wrong. Please try again.',
					});
				}

				dispatch(stopStreaming());
				dispatch(setWriterLoader(false));
				if (eventSource) eventSource.close();
			}
		},
		[initializeEventSource, dispatch]
	);

	return (
		<MagicContext.Provider value={handleMagic}>
			{children}
		</MagicContext.Provider>
	);
};
