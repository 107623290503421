import { Button } from '@/components/ui/button';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';
import { ProposalTableProps } from '@/lib/types/constants';
import Heart from '@/components/ui/icons/new/Heart';
import UserAvatarList from '@/components/UserAvatarList/UserAvatarList';
import FilledHeart from '@/components/ui/icons/new/FilledHeart';
import { ProposalAction, Team, User } from '@/lib/types/apiTypes';
import { Dispatch, SetStateAction } from 'react';
import ProposalAssignmentDrawer from '@/components/Drawers/ProposalAssignmentDrawer/ProposalAssignmentDrawer';
import Archive from '@/components/ui/icons/new/Archive';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import ProposalCategory from '@/components/ProposalCategory/ProposalCategory';

interface GetColumnsParams {
	handleNavigate?: (id: string) => void;
	handleFavorite?: (action: ProposalAction, id: number) => void;
	handleArchive?: (ids: number[]) => void;
	setSelectedRowIds?: Dispatch<SetStateAction<string[]>>;
	selectedRowIds?: string[];
	teams?: Team[];
	user?: User;
	isMember?: boolean;
}

export function getColumns({
	handleNavigate,
	handleFavorite,
	handleArchive,
	setSelectedRowIds,
	selectedRowIds,
	teams,
	user,
	isMember,
}: GetColumnsParams): ColumnDef<ProposalTableProps>[] {
	const columns: ColumnDef<ProposalTableProps>[] = [
		{
			id: 'select',
			header: ({ table }) => (
				<Checkbox
					className="border-black data-[state=checked]:text-white data-[state=checked]:border-primary"
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={(value) => {
						table.toggleAllPageRowsSelected(!!value);
						if (selectedRowIds && setSelectedRowIds) {
							if (value) {
								setSelectedRowIds &&
									setSelectedRowIds([
										...selectedRowIds,
										...table
											.getFilteredRowModel()
											.rows.map((row) => String(row.original.id)),
									]);
							} else {
								setSelectedRowIds &&
									setSelectedRowIds(
										selectedRowIds.filter(
											(id) =>
												!table
													.getFilteredRowModel()
													.rows.map((row) => String(row.original.id))
													.includes(id)
										)
									);
							}
						}
					}}
					aria-label="Select all"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					className="border-black data-[state=checked]:text-white data-[state=checked]:border-primary"
					checked={
						selectedRowIds && selectedRowIds.includes(String(row.original.id))
					}
					onCheckedChange={(value) => {
						row.toggleSelected(!!value);
						if (selectedRowIds && setSelectedRowIds) {
							if (value) {
								setSelectedRowIds &&
									setSelectedRowIds([
										...selectedRowIds,
										String(row.original.id),
									]);
							} else {
								setSelectedRowIds &&
									setSelectedRowIds(
										selectedRowIds.filter(
											(id) => id !== String(row.original.id)
										)
									);
							}
						}
					}}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'name',
			header: 'Proposal Name',
			cell: ({ row }) => {
				if (!row.original.name) {
					return (
						<div
							onClick={() =>
								handleNavigate && handleNavigate(String(row.original.id))
							}
							className="cursor-pointer text-primary"
						>
							Untitled Proposal
						</div>
					);
				} else {
					return (
						<div
							onClick={() =>
								handleNavigate && handleNavigate(String(row.original.id))
							}
							className="cursor-pointer text-primary"
						>
							{row.original.name}
						</div>
					);
				}
			},
		},
		{
			accessorKey: 'rfpDocument',
			header: 'Type',
			cell: ({ row }) => {
				return (
					<ProposalCategory category={row.original?.rfpDocument?.category} />
				);
			},
		},
		{
			accessorKey: 'users',
			header: 'Users',
			cell: ({ row }) => {
				const assignedUsers = row.original.allUsers.filter(
					(user) => user.isAssigned
				);
				const assignedTeams = row.original.teams || [];

				return (
					<ProposalAssignmentDrawer
						triggerButton={
							<UserAvatarList
								proposalId={row.original.id}
								users={assignedUsers.slice(
									0,
									4 - assignedTeams.length > 0 ? 4 - assignedTeams.length : 0
								)}
								teams={assignedTeams.slice(0, 4)}
								allUsers={row.original.allUsers}
								additionalCount={
									assignedUsers.length + assignedTeams.length - 4 > 0
										? assignedUsers.length + assignedTeams.length - 4
										: 0
								}
								createdBy={row.original.createdBy}
								showAssignPopover={false}
							/>
						}
						proposalId={row.original.id}
						users={row.original.allUsers}
						teams={teams || []}
						assignedUsers={assignedUsers.map((user) => user.id)}
						assignedTeams={assignedTeams.map((team) => team.id)}
						createdBy={row.original.createdBy}
					/>
				);
			},
		},
		{
			accessorKey: 'rfpTitle',
			header: 'Solicitation Title',
		},
		{
			accessorKey: 'rfpCode',
			header: 'Solicitation Code',
		},
		{
			accessorKey: 'createdAt',
			header: ({ column }) => {
				return (
					<Button
						className="p-0 text-lg text-black bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
						onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
					>
						Created On
						<ArrowUpDown className="w-4 h-4 ml-2" />
					</Button>
				);
			},
			cell: ({ row }) => {
				return <p>{new Date(row.original.createdAt).toLocaleDateString()}</p>;
			},
		},
		{
			accessorKey: 'favorite',
			header: '',
			cell: ({ row }) => {
				if (!handleFavorite || !setSelectedRowIds || !selectedRowIds)
					return null;
				return (
					<div className="flex gap-4 items-center">
						<TooltipProvider delayDuration={50}>
							<Tooltip>
								<TooltipTrigger>
									<div
										className="cursor-pointer"
										onClick={() => {
											handleFavorite(
												row.original.isFavourite ? 'unfavourite' : 'favourite',
												row.original.id
											);
											setSelectedRowIds &&
												setSelectedRowIds(
													selectedRowIds.filter(
														(id) => id !== String(row.original.id)
													)
												);
										}}
									>
										{row.original.isFavourite ? (
											<FilledHeart className="w-6" />
										) : (
											<Heart />
										)}
									</div>
								</TooltipTrigger>
								<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
									{row.original.isFavourite ? <>Unfavorite</> : <>Favorite</>}
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						{!!handleArchive &&
							isMember &&
							row.original.createdBy === user?.id && (
								<TooltipProvider delayDuration={50}>
									<Tooltip>
										<TooltipTrigger>
											<div
												className="cursor-pointer"
												onClick={() => {
													handleArchive([row.original.id]);
												}}
											>
												<Archive />
											</div>
										</TooltipTrigger>
										<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
											Archive
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							)}
					</div>
				);
			},
		},
	];

	return columns;
}
