import { FindDocuments } from '@/lib/types/apiTypes';
import { FC, useEffect, useState } from 'react';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import { CheckCircle2, ThumbsUp } from 'lucide-react';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import CategoryDropdown from '../CategoryDropdown/CategoryDropdown';
import styles from './DocumentList.module.css';
import { cn } from '@/lib/utils';

interface DocumentListProps {
	documentsList: FindDocuments[];
	handleReadDocument: (docData: FindDocuments) => void;
	handleAddDocument: (docId: number, category: string) => void;
	handleRemoveDocument: (docId: number) => void;
}

const DocumentList: FC<DocumentListProps> = ({
	documentsList,
	handleReadDocument,
	handleAddDocument,
	handleRemoveDocument,
}) => {
	const [docsList, setDocsList] = useState<FindDocuments[]>(documentsList);

	const updateSelectedCategory = (documentId: number, category: string) => {
		const docs = docsList.map((doc) => {
			if (doc.documentId === documentId) {
				return { ...doc, attachedAs: category };
			} else {
				return doc;
			}
		});
		setDocsList(docs);
	};

	useEffect(() => {
		setDocsList(documentsList);
	}, [documentsList]);

	return (
		<div className="flex flex-col h-full gap-2 overflow-hidden">
			<div className="grid items-center w-full grid-cols-11 gap-2 pl-3 pr-6 text-black rounded-lg bg-blackSecondary">
				<h4 className="flex items-center h-8 col-span-6 font-bold">
					Document Name
				</h4>
				<h4 className="flex items-center h-8 col-span-3 font-bold">Category</h4>
				<h4 className="flex items-center h-8 col-span-1 font-bold">Action</h4>
			</div>
			<Accordion
				type="single"
				collapsible
				className="flex flex-col w-full h-full gap-2 overflow-y-auto text-black"
			>
				{docsList.map((docData, index) => {
					return (
						<AccordionItem
							value={`doc-${index}`}
							className="rounded-xl px-3 border border-[#D3DDE2] mr-3"
						>
							<AccordionTrigger
								className={cn(
									'grid grid-cols-11 gap-2 py-3 w-full text-primary',
									styles.accordion__trigger
								)}
							>
								<div className="flex flex-col items-start col-span-6 gap-2 font-normal text-left text-primary">
									<h4>{docData?.alias || docData.name}</h4>
									{index < 3 && (
										<span className="flex items-center gap-1 p-2 font-medium uppercase border rounded-full text-secondary border-secondary dynamic-badge">
											<ThumbsUp size={14} /> Recommended
										</span>
									)}
								</div>
								<div className="flex items-center h-8 col-span-3 font-bold">
									<CategoryDropdown
										className="h-auto w-fit font-normal border border-[#5D9BFD]"
										disabled={docData?.isAdded}
										selectedCategory={docData?.attachedAs || docData?.category}
										setSelectedCategory={(category) =>
											updateSelectedCategory(docData.documentId, category)
										}
									/>
								</div>
								<div className="flex items-center col-span-1 font-bold">
									<div onClick={(event) => event.preventDefault()}>
										{docData?.isAdded ? (
											<TextButton
												className="text-lightGreen dynamic-small"
												onClick={() => {
													handleRemoveDocument(docData.documentId);
												}}
											>
												<CheckCircle2 />
												Added
											</TextButton>
										) : (
											<TextButton
												className="dynamic-small"
												onClick={() => {
													handleAddDocument(
														docData.documentId,
														docData.attachedAs || docData.category
													);
												}}
											>
												Add this in
											</TextButton>
										)}
									</div>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div className="flex flex-col gap-2 py-4 border-t border-[#C6DBFB]">
									<p>{docData?.summary}</p>
									<PrimaryButton
										className="h-8 rounded-md 2xl:h-9 dynamic-small w-fit"
										onClick={() => handleReadDocument(docData)}
									>
										Read Document
									</PrimaryButton>
								</div>
							</AccordionContent>
						</AccordionItem>
					);
				})}
			</Accordion>
		</div>
	);
};

export default DocumentList;
