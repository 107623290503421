import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import styles from './CommentBox.module.css';
import { Input } from '../../ui/input';
import Send from '../../ui/icons/Send';
import { cn } from '@/lib/utils';
import { getWordCount } from '@/lib/functions/funcUtils';

interface CommentBoxProps {
	onSubmit: (comment: string) => void;
	defaultValue?: string;
	value?: string;
	clearOnSubmit?: boolean;
	placeholder?: string;
	className?: string;
}

const CommentBox: FC<CommentBoxProps> = ({
	onSubmit,
	defaultValue,
	clearOnSubmit,
	className,
	placeholder = 'Write your comment here',
}) => {
	const [comment, setComment] = useState<string>(defaultValue || '');
	const wordCount = getWordCount(comment);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setComment(event.target.value);
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			handleSubmit();
			event.preventDefault(); // Prevent the default behavior (form submission)
		}
	};

	const handleSubmit = () => {
		if (!comment || wordCount > 100) return;
		onSubmit(comment);
		if (clearOnSubmit) {
			setComment('');
		}
	};

	return (
		<div className={cn('bg-white z-10', styles.box, className)}>
			<div className="relative">
				<Input
					value={comment}
					onChange={handleInputChange}
					onKeyDown={handleKeyPress}
					placeholder={placeholder}
					className="text-base bg-white border border-[#D3DDE2] h-[50px] rounded-2xl text-black pr-12"
				/>
				<div className="absolute top-[0.825rem] right-4" onClick={handleSubmit}>
					<button
						className="disabled:opacity-50"
						disabled={!comment || wordCount > 100}
					>
						<Send />
					</button>
				</div>
			</div>
			{wordCount > 100 && (
				<p className="pt-2 dynamic-text font-medium text-lightRed">
					Word count must be less than 100
				</p>
			)}
		</div>
	);
};

export default CommentBox;
