import { FC } from 'react';
import { RFP, Template } from '@/lib/types/apiTypes';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	pageTransitionStart,
	setTemplate,
	setTemplates,
	updateStep,
} from '@/redux/slices/proposalSlice';
import { openDialog } from '@/redux/slices/pageSlice';
import { motion } from 'framer-motion';
import {
	prTextSlideIn,
	prInputSlideIn,
	prInputSlideInDelay,
	prContainerExit,
} from '@/constants/variants';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { ExternalLink } from 'lucide-react';
import { getAllTemplates } from '@/lib/functions/apiCalls';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { getRecommendedTemplates } from '@/lib/functions/TemplateBuilder/templateCalls';
import customToast from '@/components/CustomToast/CustomToast';
import { useNavigate } from 'react-router';

interface SetupViewProps {
	currentStep: number;
	data: RFP;
	viewRFP?: () => void;
}

const pageSize = screen.width > 1280 ? 6 : 4;

const SetupView: FC<SetupViewProps> = ({ currentStep, data, viewRFP }) => {
	const organizationIdentifier = useOrganizationId();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const documentId = data?.documentId;

	const getTemplates = async () => {
		try {
			const response = await getAllTemplates({
				pageNumber: 1,
				pageSize: pageSize,
				filter: 'all',
				organizationIdentifier: organizationIdentifier,
			});
			handleResponse(response);
			dispatch(setTemplates((response as any).templates));
			if (!(response as any)?.templates?.length) {
				customToast.error({
					title: 'Published templates not found. Please contact support.',
				});
				navigate('/solicitations');
			}
			return (response as any).templates;
		} catch (error) {
			ErrorHandle(dispatch, error);
		}
	};

	const getRecTemplates = async (templates: Template[]) => {
		try {
			if (!documentId) return;
			const search = {
				documentId,
			};
			const response = await getRecommendedTemplates(search);
			handleResponse(response);
			const res = response?.data as any;
			const suggestedTemplate = (res?.templateSuggestions as Template[]) || [];
			if (suggestedTemplate?.[0]) {
				dispatch(setTemplate(suggestedTemplate?.[0]));
			} else if (templates?.[0]) {
				dispatch(setTemplate(templates?.[0]));
			}
			return (response as any).templates;
		} catch (error) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleClick = async () => {
		try {
			dispatch(pageTransitionStart());
			const nextStep = currentStep + 1;
			dispatch(updateStep(nextStep));
			const templates = await getTemplates();
			getRecTemplates(templates);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<motion.div
			className="max-w-[1400px] w-full  mx-auto flex flex-col items-center justify-center gap-6"
			key="prContainer"
			variants={prContainerExit}
			exit="exit"
		>
			<motion.h2
				className="font-bold text-center text-black dynamic-xl-large"
				key="prText"
				variants={prTextSlideIn}
				initial="initial"
				animate="animate"
			>
				You are <span className="text-secondary">responding</span> to...
			</motion.h2>
			<div className="flex flex-col w-full gap-4">
				<motion.div
					className="flex items-center justify-between w-full px-8 py-4 bg-white rounded-xl shadow-main"
					key="prInput"
					variants={prInputSlideIn}
					initial="initial"
					animate="animate"
				>
					<p className="text-lg xl:text-xl text-[#5d9bfd] font-bold">
						<span>{data?.metaData?.code}</span> {data?.metaData?.title}
					</p>
					<div className="pl-3 border-l">
						<TextButton
							onClick={() => {
								viewRFP && viewRFP();
							}}
							className="h-auto p-0 capitalize 2xl:h-auto"
						>
							<ExternalLink />
							Open RFP Link
						</TextButton>
					</div>
				</motion.div>
			</div>
			<motion.div
				className="flex items-center gap-5"
				key="prInputDelay"
				variants={prInputSlideInDelay}
				initial="initial"
				animate="animate"
			>
				<SecondaryButton
					type="button"
					className="solicitation-brief"
					onClick={() =>
						dispatch(
							openDialog({
								type: 'viewSolicitationBrief',
								rfp: data,
								viewProposalType: 'solicitation',
							})
						)
					}
				>
					Solicitation Brief
				</SecondaryButton>
				<PrimaryButton onClick={handleClick} className="setup-proposal">
					let's set up the proposal
				</PrimaryButton>
			</motion.div>
		</motion.div>
	);
};

export default SetupView;
