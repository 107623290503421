import { SVGProps } from 'react';

const Delete = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
		>
			<mask
				id="mask0_2705_8046"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="25"
			>
				<rect y="0.864746" width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2705_8046)">
				<path
					d="M7 21.8647C6.45 21.8647 5.97917 21.6689 5.5875 21.2772C5.19583 20.8856 5 20.4147 5 19.8647V6.86475H4V4.86475H9V3.86475H15V4.86475H20V6.86475H19V19.8647C19 20.4147 18.8042 20.8856 18.4125 21.2772C18.0208 21.6689 17.55 21.8647 17 21.8647H7ZM17 6.86475H7V19.8647H17V6.86475ZM9 17.8647H11V8.86475H9V17.8647ZM13 17.8647H15V8.86475H13V17.8647Z"
					fill="#FF388C"
				/>
			</g>
		</svg>
	);
};

export default Delete;
