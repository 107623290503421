import { Textarea, TextareaProps } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { FC, useEffect, useRef } from 'react';

interface ExpandableTextareaProps extends TextareaProps {
	minRows?: number;
	maxRows?: number;
}

const ExpandableTextarea: FC<ExpandableTextareaProps> = ({
	minRows = 1,
	maxRows = 7,
	className,
	...props
}) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const { value } = props;

	useEffect(() => {
		adjustHeight();
	}, [value]);

	const adjustHeight = () => {
		if (textareaRef.current) {
			textareaRef.current.style.height = 'auto';
			const scrollHeight = textareaRef.current.scrollHeight;
			const lineHeight = parseInt(
				getComputedStyle(textareaRef.current).lineHeight
			);
			const lines = scrollHeight / lineHeight;
			const maxHeight = lineHeight * maxRows;

			if (lines <= maxRows) {
				textareaRef.current.style.height = `${scrollHeight}px`;
				textareaRef.current.style.overflowY = 'hidden';
			} else {
				textareaRef.current.style.height = `${maxHeight}px`;
				textareaRef.current.style.overflowY = 'auto';
			}
		}
	};

	return (
		<Textarea
			ref={textareaRef}
			value={value}
			className={cn(
				'resize-none transition-height duration-300 ease-out',
				className
			)}
			rows={minRows}
			{...props}
		/>
	);
};

export default ExpandableTextarea;
