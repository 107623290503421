import customToast from '@/components/CustomToast/CustomToast';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { updateSectionState } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import useProposalId from '@/lib/hooks/useProposalId';
import { closeDialog } from '@/redux/slices/pageSlice';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface RenameSectionDialogProps {
	label: string;
}

const RenameSectionDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'renameSection'
	);
	const [loader, setLoader] = useState(false);
	const form = useForm<RenameSectionDialogProps>();
	const { register, handleSubmit, reset } = form;
	const section = useSelector((root: RootState) => root.page.dialog?.section);
	const dispatch = useAppDispatch();
	const [sectionName, setSectionName] = useState(
		section?.alias || section?.label
	);
	const proposalId = useProposalId();

	useEffect(() => {
		if (section) {
			setSectionName(section?.alias || section?.label);
		}
	}, [section]);
	const handleClose = () => {
		dispatch(closeDialog());
		reset();
	};

	const handleRenameSection = async (data: RenameSectionDialogProps) => {
		try {
			if (!section || !data.label || !proposalId) return;

			setLoader(true);
			const response = await updateSectionState(proposalId, section.id, {
				alias: data.label,
			});

			if (response.statusCode === 200) {
				customToast.success({
					title: 'Section renamed successfully',
				});

				await dispatch(fetchProposalById(Number(proposalId)));
				setLoader(false);
				reset();

				dispatch(closeDialog());
			}
		} catch (error) {
			console.log('error', error);
			setLoader(false);
			customToast.error({
				title: 'Error renaming section',
			});
		}
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[500px] p-6 bg-white rounded-xl"
				hideCloseBtn
			>
				<DialogHeader className="font-bold text-secondary dynamic-large">
					Rename Section
				</DialogHeader>
				<form onSubmit={handleSubmit(handleRenameSection)}>
					<label
						htmlFor="sectionName"
						className="block mb-1 font-bold text-black"
					>
						Section Name
					</label>
					<Input
						{...register('label')}
						id="label"
						type="text"
						placeholder="Enter Section Label"
						className="w-full bg-white rounded-lg h-11"
						value={sectionName}
						maxLength={1000}
						onChange={(e) => setSectionName(e.target.value)}
					/>
					<p className="mt-1 text-right text-black dynamic-badge">
						{sectionName?.length}/1000
					</p>

					{/* <div>
						<div className="flex items-center gap-2 py-3 font-bold text-secondary">
							<AiIcon className="w-5 h-5" fill="#E861C9" /> Here are some name
							options I came up with.
						</div>
						<div className="flex items-center gap-2 mb-4">
							<Badge className="px-4 py-1 font-medium bg-white text-primary border-primary">
								Section Name
							</Badge>
							<Badge className="px-4 py-1 font-medium bg-white text-primary border-primary">
								Section Name
							</Badge>
							<RefreshCcw color="#5D9BFD" />
						</div>
					</div> */}
					<div className="flex items-center justify-end gap-3 mt-3">
						<SecondaryButton type="button" onClick={handleClose}>
							Cancel
						</SecondaryButton>
						<PrimaryButton
							type="submit"
							disabled={sectionName === '' || loader}
						>
							{loader ? 'Updating...' : 'Update'}
						</PrimaryButton>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default RenameSectionDialog;
