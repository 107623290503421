import BrainstormIntro from '@/components/BrainstormIntro/BrainstormIntro';
import ChatBox from '@/components/ChatBox/ChatBox';
import { brainstormWithAI } from '@/lib/functions/apiCalls';
import { Message } from '@/lib/types/apiTypes';
import { RootState } from '@/redux/store/store';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './BrainstormWrapper.module.css';
import ChatMessageLists from '@/components/ChatMessageLists/ChatMessageLists';
import { cn } from '@/lib/utils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { addAIResponse, addUserMessage } from '@/redux/slices/brainStormSlice';
import WriteModeSkeleton from '@/components/WriteModeSkeleton/WriteModeSkeleton';
import { v4 } from 'uuid';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

interface BrainstormWrapperProps {}

const BrainstormWrapper: FC<BrainstormWrapperProps> = () => {
	const { activeSection } = useActiveSection();
	const [isMessageLoading, setIsMessageLoading] = useState(false);
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const dispatch = useAppDispatch();
	const messages = useSelector((root: RootState) => root.brainStorm.queries);

	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);

	const [queryMessage, setQueryMessage] = useState('');
	const queryPrompts = [
		'Come up with one actionable idea for this section',
		'How can I enhance this section?',
	];

	if (isStreaming) return <WriteModeSkeleton />;

	const handleSubmit = async (customQuery?: string) => {
		setIsMessageLoading(true);
		setQueryMessage('');
		const query = customQuery || queryMessage;

		if (!activeSection.sectionId && !proposal) {
			setIsMessageLoading(false);
			return;
		}

		// Create a new user message
		const newUserMessage: Message = {
			content: query,
			role: 'user',
			sectionId: activeSection.sectionId as number,
		};

		try {
			const updatedMessages = [
				...(messages[activeSection.sectionId as number] || []),
				newUserMessage,
			];

			const response = await brainstormWithAI(
				proposal?.id as number,
				activeSection.sectionId as number,
				{
					query: updatedMessages,
				}
			);

			if (response.statusCode === 201) {
				const data = response.data as Message;

				dispatch(
					addUserMessage({
						content: query,
						sectionId: activeSection.sectionId as number,
					})
				);
				dispatch(
					addAIResponse({
						content: data.content,
						sectionId: activeSection.sectionId as number,
					})
				);

				setIsMessageLoading(false);
			}
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<div
			className={cn(
				styles.wrapper,
				!activeSection.sectionId ? 'bg-[#EAF1FC]' : null
			)}
		>
			{activeSection.sectionId ? (
				<div className="flex flex-col pt-5 h-full">
					<div className="mx-5 pb-5 border-b border-[#C6DBFB]">
						<p className="w-[300px] mx-auto text-secondary text-center font-medium">
							I am here to help you brainstorm. Lets talk about how we can
							improve this section
						</p>
					</div>

					<div
						className={cn(
							styles.list,
							'flex flex-col overflow-hidden border-0 justify-end flex-1'
						)}
					>
						<ChatMessageLists
							isMessageLoading={isMessageLoading}
							messages={messages[activeSection.sectionId] || []}
						/>
						{!messages[activeSection.sectionId] && !isMessageLoading && (
							<div className="flex flex-col items-end gap-3 px-5 py-5 text-center">
								{queryPrompts.map((text) => (
									<a
										key={v4()}
										className="flex px-3 py-2 text-black border cursor-pointer dynamic-small text-primary border-blackSecondary hover:border-primary bg-lightBackground rounded-xl"
										onClick={() => handleSubmit(text)}
									>
										{text}
									</a>
								))}
							</div>
						)}
					</div>

					<ChatBox
						queryMessage={queryMessage}
						setQueryMessage={setQueryMessage}
						onSubmit={handleSubmit}
					/>
				</div>
			) : (
				<BrainstormIntro />
			)}
		</div>
	);
};

export default BrainstormWrapper;
