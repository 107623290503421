import { FC } from 'react';
import styles from './LogicalFrameCard.module.css';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { cn } from '@/lib/utils';
import { useNavigate } from 'react-router';

interface LogicalFrameCardProps {}

const LogicalFrameCard: FC<LogicalFrameCardProps> = () => {
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const navigate = useNavigate();
	return (
		<div className={cn(styles.card, 'compliance-card')}>
			<h2 className="font-bold text-black dynamic-large">Logical Framework</h2>

			<div className="flex items-center justify-start gap-6 mt-2 ">
				<p className="text-black">
					Edit or create a logical framework for this project
				</p>
				<PrimaryButton
					className="shrink-0"
					onClick={() => {
						navigate(`/proposal/${proposal?.id}/logical-frame`);
					}}
				>
					View Logical Framework
				</PrimaryButton>
			</div>
		</div>
	);
};

export default LogicalFrameCard;
