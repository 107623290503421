import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { ChartsResponse } from '@/lib/types/apiTypes';
import { FC } from 'react';

interface ChartImageDialogProps {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	item: {
		id: number;
		imageUrl: string;
		config: ChartsResponse;
	};
}

const ChartImageDialog: FC<ChartImageDialogProps> = ({
	isOpen,
	setIsOpen,
	item,
}) => {
	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogContent className="max-w-5xl bg-white">
				<DialogHeader>
					<DialogTitle className="font-bold dynamic-large text-[#5d6f79]">
						{item.config.chart.title}
					</DialogTitle>
				</DialogHeader>
				<img src={item.imageUrl} alt={`Chart ${item.id}`} loading="lazy" />
			</DialogContent>
		</Dialog>
	);
};

export default ChartImageDialog;
