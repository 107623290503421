import { FC, useCallback } from 'react';
import styles from './WidgetMenu.module.css';
import { cn } from '@/lib/utils';
import { Card } from '@/lib/types/constants';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog, openDrawer } from '@/redux/slices/pageSlice';
import Ideas from '../ui/icons/new/Ideas';
import Document from '../ui/icons/new/Document';
// import Chat from '../ui/icons/widgets/Chat';
import ChatIcon from '@/components/ui/icons/widgets/Chat';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import Brainstorm from '../ui/icons/new/Brainstorm';
import { ClipboardList } from 'lucide-react';
import { getClipboards } from '@/lib/functions/apiCalls';
import useProposalId from '@/lib/hooks/useProposalId';
import { useQuery } from 'react-query';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';

interface WidgetMenuProps {
	setSectionData: React.Dispatch<React.SetStateAction<Record<string, Card[]>>>;
	commentCount: number;
}

const WidgetMenu: FC<WidgetMenuProps> = ({ setSectionData, commentCount }) => {
	const dispatch = useAppDispatch();
	const proposalId = useProposalId();
	const { activeSection } = useActiveSection();
	const sectionId = activeSection.sectionId;

	const { data: clipboardsData } = useQuery({
		queryKey: 'clipboards',
		queryFn: () => getClipboards(Number(proposalId)),
		refetchOnWindowFocus: false,
	});

	const clipboards =
		((clipboardsData?.data as any)?.clipboards as Clipboard[]) || [];
	const clipboardsCount = clipboards.length;

	const handleAddIdea = useCallback(() => {
		if (!sectionId) return;
		const newCard: Card = {
			type: 'idea',
			content: '',
		};

		setSectionData((prev) => {
			const prevCards = prev[sectionId] || [];
			return { ...prev, [sectionId]: [...prevCards, newCard] };
		});
	}, [sectionId]);

	return (
		<ul className={cn(styles.menu, 'flex gap-4 items-center')}>
			<li className="documents flex items-center h-8 bg-[#EAF1FC] rounded-lg px-3 py-1.5 animated-hover-effect cursor-pointer">
				<Popover>
					<PopoverTrigger className="flex items-center gap-2">
						<Document />
						<p className="text-sm font-bold text-primary">Documents</p>
					</PopoverTrigger>
					<PopoverContent
						className={cn(
							styles.popover__content,
							'flex flex-col bg-white border-none rounded-xl w-52 p-2'
						)}
						align="center"
						sideOffset={12}
					>
						<a
							className="text-black dynamic-text px-4 py-2 cursor-pointer hover:bg-[#E3EEFF] hover:text-primary rounded-lg"
							onClick={() =>
								dispatch(
									openDialog({
										type: 'uploadDocument',
										uploadDocumentType: 'attachSectionDocument',
									})
								)
							}
						>
							Upload document
						</a>
						<a
							className="text-black dynamic-text px-4 py-2 cursor-pointer hover:bg-[#E3EEFF] hover:text-primary rounded-lg"
							onClick={() =>
								dispatch(
									openDialog({
										type: 'addDocuments',
										addDocumentType: 'attachSectionDocument',
									})
								)
							}
						>
							Search your library
						</a>
					</PopoverContent>
				</Popover>
			</li>
			<li className="relative h-8">
				<div
					onClick={() => dispatch(openDrawer({ type: 'viewBrainstorm' }))}
					className="brainstorm items-center flex h-8 bg-[#EAF1FC] rounded-lg px-3 py-1.5 gap-2 animated-hover-effect cursor-pointer"
				>
					<Brainstorm />
					<p className="text-sm font-bold text-primary">Brainstorm</p>
				</div>
			</li>
			<li
				onClick={handleAddIdea}
				className="ideas flex items-center h-8 bg-[#EAF1FC] rounded-lg px-3 py-1.5 gap-2 animated-hover-effect cursor-pointer"
			>
				<Ideas />
				<p className="text-sm font-bold text-primary">Ideas</p>
			</li>
			<div className="w-[1px] h-6 bg-[#D3DDE2]"></div>
			<li
				onClick={() =>
					dispatch(
						openDrawer({
							type: 'clipboardIdea',
						})
					)
				}
				className="relative flex clipboard"
			>
				<TooltipProvider delayDuration={50}>
					<Tooltip>
						<TooltipTrigger>
							<div className="flex h-8 w-8 bg-[#EAF1FC] rounded-lg px-2 py-1.5 gap-2 animated-hover-effect cursor-pointer">
								<ClipboardList size={20} className="text-primary" />
							</div>
							{clipboardsCount > 0 && (
								<div className="absolute z-20 flex leading-none items-center justify-center w-5 h-5 p-1 text-white rounded-full dynamic-small -top-2 -right-2 bg-secondary">
									{clipboardsCount}
								</div>
							)}
						</TooltipTrigger>
						<TooltipContent
							sideOffset={5}
							side="bottom"
							className="bg-white  text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect"
						>
							<p>Clipboard</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</li>
			<li
				className="relative h-8 w-8 comments"
				onClick={() => dispatch(openDrawer({ type: 'viewComments' }))}
			>
				<TooltipProvider delayDuration={50}>
					<Tooltip>
						<TooltipTrigger>
							<ChatIcon className="relative z-10 cursor-pointer animated-hover-effect" />
							{commentCount > 0 && (
								<div className="absolute z-20 flex leading-none items-center justify-center w-5 h-5 p-1 text-white rounded-full dynamic-small -top-2 -right-2 bg-secondary">
									{commentCount}
								</div>
							)}
						</TooltipTrigger>
						<TooltipContent
							sideOffset={5}
							side="bottom"
							className="bg-white  text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect"
						>
							<p>Comments</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</li>
		</ul>
	);
};

export default WidgetMenu;
