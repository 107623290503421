import FileUpload from '@/components/FileUpload/FileUpload';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import Upload from '@/components/ui/icons/new/Upload';
import {
	createAChart,
	generateCharts,
	getChartsRecommendation,
} from '@/lib/functions/apiCalls';
import { RootState } from '@/redux/store/store';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import suggestionLottie from '@/assets/lotties/charts-and-graphs.json';
import Lottie from 'lottie-react';
import {
	ChartType,
	ChartsRecommendResponse,
	ChartsResponse,
} from '@/lib/types/apiTypes';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { closeDialog } from '@/redux/slices/pageSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import ChartDataList from '@/components/ChartDataList/ChartDataList';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import AiIcon from '@/components/ui/icons/AiIcon';
import {
	fetchAllProposalCharts,
	resetChartData,
	setChartData,
	setFile,
	setGeneratedChart,
	setSelectedChart,
	setSelectedChartPlot,
} from '@/redux/slices/chartSlice';

import { Chart, registerables } from 'chart.js';

import { Bar, Bubble, Doughnut, Line, Pie, Scatter } from 'react-chartjs-2';
import useProposalId from '@/lib/hooks/useProposalId';
import { Badge } from '@/components/ui/badge';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { handleFileRejections } from '@/lib/functions/funcUtils';
import { triggerVisual } from '@/lib/observables/observables';
Chart.register(...registerables);

interface VisualDialogProps {}

const VisualDialog: FC<VisualDialogProps> = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'openVisuals'
	);
	const { activeSection } = useActiveSection();
	const proposalId = useProposalId() as number;
	const chartData = useSelector((root: RootState) => root.chart.chartData);
	const dispatch = useAppDispatch();
	const [chartJourney, setChartJourney] = useState(false);
	const [loader, setLoader] = useState(false);

	const chartFile = useSelector((root: RootState) => root.chart.file);
	const selectedChart = useSelector(
		(root: RootState) => root.chart.selectedChart
	);
	const selectedChartPlot = useSelector(
		(root: RootState) => root.chart.selectedChartPlot
	);
	const generatedChart = useSelector(
		(root: RootState) => root.chart.generatedChart
	);

	const handleGenerateVisuals = async () => {
		try {
			setLoader(true);
			const response = await generateCharts(
				chartFile as File,
				selectedChart,
				selectedChartPlot
			);
			if (response.statusCode === 201) {
				const chart = (response.data as ChartsResponse).chart;
				dispatch(setGeneratedChart(chart));
				setLoader(false);
			}
			if (response.statusCode === 500) {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const handleVisuals = async (files: File[]) => {
		if (!files.length) return;

		try {
			setLoader(true);
			const response = await getChartsRecommendation(files[0]);
			if (response.statusCode === 201) {
				setChartJourney(true);
				dispatch(setFile(files[0]));
				const data = (response.data as ChartsRecommendResponse).recommendations;
				dispatch(setChartData(data));
				dispatch(setSelectedChart(data[0].chart));
				dispatch(setSelectedChartPlot(data[0].plotSuggestions[0]));
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const handleClose = () => {
		dispatch(closeDialog());
		setChartJourney(false);
	};

	const handleInsertChart = async () => {
		const canvas = document.querySelector(
			'.chart-wrapper canvas'
		) as HTMLCanvasElement;
		if (canvas && generatedChart) {
			// base64 image

			const image = canvas
				.toDataURL('image/png')
				.replace('image/png', 'image/octet-stream');
			try {
				// create a chart
				setLoader(true);
				const response = await createAChart(proposalId, image, {
					chart: generatedChart,
				});
				if (response.statusCode === 201) {
					setLoader(false);

					const data = (response.data as any).imageUrl;
					const editor = activeSection.editorRef?.current;
					if (!editor) return;

					const insertPosition =
						editor.model.document.selection.getFirstPosition();
					editor.execute('insertImage', { source: data }, insertPosition);
					dispatch(fetchAllProposalCharts(proposalId));
					triggerVisual();
					handleClose();
				}
			} catch (error) {
				console.error(error);
				setLoader(false);
			}
		}
	};

	const renderChartsData = () => {
		if (!generatedChart) {
			return (
				<>
					<DialogHeader>
						<DialogTitle className="font-bold dynamic-large text-[#5d6f79] flex items-center gap-3">
							Let's get your document loaded up{' '}
							<Badge className="px-2 py-1 text-white uppercase bg-secondary dynamic-small">
								Beta
							</Badge>
						</DialogTitle>
						<p className="text-[#5d6f79] mt-2">
							Select the one that you want and then click continue
						</p>
					</DialogHeader>
					<div className="mt-4">
						<div className="flex items-center justify-start w-full gap-2 pb-4 font-bold dynamic-text text-secondary">
							<AiIcon className="w-4 h-4" fill="#E861C9" /> I found some
							visualization options that may help illustrate your data
						</div>
						{chartData && <ChartDataList data={chartData} />}
						<div className="flex items-center gap-4 mt-5">
							<SecondaryButton
								onClick={() => {
									setChartJourney(false);
									dispatch(resetChartData());
								}}
							>
								Upload file Again
							</SecondaryButton>
							<PrimaryButton onClick={handleGenerateVisuals}>
								Generate Visual
							</PrimaryButton>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<div className="chart-wrapper">
					<div className="w-full h-[500px]">
						{renderCharts(
							generatedChart.type,
							generatedChart.metaData,
							generatedChart.title
						)}
					</div>
					<div className="sticky bottom-0 z-20 flex items-center gap-5 py-1 mt-5 bg-white">
						<SecondaryButton
							onClick={() => {
								dispatch(setGeneratedChart(null));
							}}
						>
							Select a different chart
						</SecondaryButton>
						<PrimaryButton onClick={handleInsertChart}>
							Place This In
						</PrimaryButton>
					</div>
				</div>
			);
		}
	};

	const renderCharts = (type: ChartType, data, title) => {
		switch (type) {
			case 'line':
				return (
					<Line
						data={data}
						options={{
							responsive: true,
							plugins: {
								title: {
									display: true,
									text: title,
								},
							},
						}}
					/>
				);
			case 'bar':
				return (
					<Bar
						data={data}
						options={{
							responsive: true,
							plugins: {
								title: {
									display: true,
									text: title,
								},
							},
						}}
					/>
				);
			case 'pie':
				return (
					<Pie
						data={data}
						options={{
							responsive: true,
							maintainAspectRatio: false,
							plugins: {
								title: {
									display: true,
									text: title,
								},
							},
						}}
					/>
				);
			case 'doughnut':
				return (
					<Doughnut
						data={data}
						options={{
							responsive: true,
							plugins: {
								title: {
									display: true,
									text: title,
								},
							},
						}}
					/>
				);
			case 'area':
				return (
					<Line
						data={data}
						options={{
							responsive: true,
							plugins: {
								title: {
									display: true,
									text: title,
								},
							},
						}}
					/>
				);
			case 'scatter':
				return (
					<Scatter
						data={data}
						options={{
							responsive: true,
							plugins: {
								title: {
									display: true,
									text: title,
								},
							},
						}}
					/>
				);
			case 'bubble':
				return (
					<Bubble
						data={data}
						options={{
							responsive: true,
							plugins: {
								title: {
									display: true,
									text: title,
								},
							},
						}}
					/>
				);
			case 'histogram':
				return (
					<Bar
						data={data}
						options={{
							responsive: true,
							plugins: {
								title: {
									display: true,
									text: title,
								},
							},
						}}
					/>
				);
			default:
				return null;
		}
	};

	const renderVisualUpload = () => {
		return (
			<>
				<DialogHeader>
					<DialogTitle className="font-bold dynamic-large text-[#5d6f79] flex items-center gap-3">
						Let's make your visualization{' '}
						<Badge className="px-2 py-1 text-white uppercase bg-secondary dynamic-small">
							Beta
						</Badge>
					</DialogTitle>
				</DialogHeader>
				<div className="pt-3">
					<DialogDescription className="mb-4 dynamic-text text-[#5d6f79]">
						The data can be an Excel file contains various fields such as
						employee, including ID, name, job title, department, business unit,
						gender, ethnicity, age, hire date, annual salary, bonus percentage,
						country, city, and exit date
					</DialogDescription>
					<FileUpload
						onDropAccepted={handleVisuals}
						type="custom"
						acceptedExtensions={['.csv', '.xlsx']}
						onDropRejected={(fileRejections) =>
							handleFileRejections({
								fileRejections: fileRejections,
								fileType: 'csv, xlsx',
							})
						}
						customLayout={
							<div className="flex p-4 bg-[#EAF1FC] justify-start items-center rounded-xl border border-[#D3DDE2] cursor-pointer gap-4">
								<Upload />
								<div>
									<h5 className="text-[#5d9bfd] font-bold">Import your data</h5>
									<p className="text-[#5d6f79] dynamic-small">
										Drag and drop your XLSX, CSV file here or click to specify
										its location
									</p>
								</div>
							</div>
						}
					/>
					<div className="flex items-center justify-end mt-4">
						<SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
					</div>
				</div>
			</>
		);
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className={`max-w-[1100px] p-3 rounded-2xl shadow-main bg-white`}
				hideCloseBtn={true}
			>
				<div className="max-h-[700px]  p-3 overflow-y-scroll light-scroll2 ">
					{loader ? (
						<div className="flex items-center justify-center min-h-[500px]">
							<Lottie
								animationData={suggestionLottie}
								loop={true}
								autoplay
								className="w-80"
							/>
						</div>
					) : (
						<>{chartJourney ? renderChartsData() : renderVisualUpload()}</>
					)}
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default VisualDialog;
