import DocumentChatBox from '@/components/DocumentChatBox/DocumentChatBox';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { getDocumentFromSource } from '@/lib/functions/apiCalls';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { FindDocuments } from '@/lib/types/apiTypes';
import { RootState } from '@/redux/store/store';
import { useState } from 'react';
import DialogLoader from '../../Loader/DialogLoader/DailogLoader';
import PDFViewer from '@/components/PDFViewer/PDFViewer';
import { ChevronLeft } from 'lucide-react';
import { cn } from '@/lib/utils';

const DocumentDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(
		(state: RootState) => state.page.dialog?.type === 'addDocuments'
	);
	const addDocumentType = useAppSelector(
		(state: RootState) => state.page.dialog?.addDocumentType
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [pdfData, setPdfData] = useState<ArrayBuffer | null>(null);

	const handleReadDocument = async (docData: FindDocuments) => {
		try {
			if (!docData?.sourceLink) return;
			setIsLoading(true);
			const response = await getDocumentFromSource(docData?.sourceLink);
			handleResponse(response);
			setPdfData(response as ArrayBuffer);
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};
	const clearDocumentDialog = () => {
		setIsLoading(false);
		setPdfData(null);
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className={cn(
					'flex flex-col bg-white p-6 overflow-hidden h-[600px] 2xl:h-[760px]',
					pdfData
						? 'max-w-[1000px] 2xl:max-w-[1260px]'
						: 'max-w-[950px] 2xl:max-w-[1050px]'
				)}
				setModalOpen={() => clearDocumentDialog()}
			>
				{pdfData ? (
					<>
						<div className="flex items-center font-bold dynamic-large text-black gap-2">
							<ChevronLeft
								className="text-primary cursor-pointer"
								onClick={() => setPdfData(null)}
							/>
							Document Preview
						</div>
						<div className="h-auto overflow-hidden">
							<PDFViewer pdfData={pdfData as ArrayBuffer} />
						</div>
					</>
				) : (
					<>
						<div className="flex justify-between">
							<h3 className="font-bold text-black dynamic-large">
								Select a document from{' '}
								<span className="text-secondary">
									your organization’s documents
								</span>
							</h3>
						</div>
						<DocumentChatBox
							addDocumentType={addDocumentType || 'attachSectionDocument'}
							setIsLoading={setIsLoading}
							handleReadDocument={handleReadDocument}
						/>
					</>
				)}
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default DocumentDialog;
