import { SVGProps } from 'react';

const Rename = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
		>
			<mask
				id="mask0_9852_17722"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="18"
				height="19"
			>
				<rect y="0.5" width="18" height="18" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_9852_17722)">
				<path
					d="M3.75 14.75H4.81875L12.15 7.41875L11.0813 6.35L3.75 13.6812V14.75ZM2.25 16.25V13.0625L12.15 3.18125C12.3 3.04375 12.4656 2.9375 12.6469 2.8625C12.8281 2.7875 13.0188 2.75 13.2188 2.75C13.4187 2.75 13.6125 2.7875 13.8 2.8625C13.9875 2.9375 14.15 3.05 14.2875 3.2L15.3188 4.25C15.4688 4.3875 15.5781 4.55 15.6469 4.7375C15.7156 4.925 15.75 5.1125 15.75 5.3C15.75 5.5 15.7156 5.69062 15.6469 5.87187C15.5781 6.05312 15.4688 6.21875 15.3188 6.36875L5.4375 16.25H2.25ZM11.6063 6.89375L11.0813 6.35L12.15 7.41875L11.6063 6.89375Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Rename;
