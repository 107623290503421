import { Step } from 'intro.js-react';
import { MoreVertical } from 'lucide-react';
export const DesignSteps: Step[] = [
	{
		element: '.design',
		title: 'Proposal designer',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					This page lets you design your program with your team. Think of this
					as the planning space to lay out a strong foundation and scaffolding
					for your proposal.
				</p>
				<p>
					We recommend spending time developing your program design before
					proceeding to the <span className="font-bold">write</span> mode.
					However, you can easily toggle between sections in{' '}
					<span className="font-bold">design</span> and{' '}
					<span className="font-bold">write</span> by clicking on the tabs
					above.
				</p>
			</div>
		),
	},
	{
		element: '.section-outline',
		title: 'Sections outline',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					This lets you select the section of the proposal you want to work on.
					If you click on the <span className="font-bold">write</span> tab on
					the top with a section selected, it will jump to that section in the
					write tab.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.edit-template',
		title: 'Edit template',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					Click here to edit the template. Please be aware, however, that any
					section that you remove from this template will lose any associated
					connections to its ideas.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.section-node',
		title: 'Section node',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p className="inline">
					If you need to change the name or update the word count of a section,
					you don’t need to edit the template; simply hover over a section name,
					click the menu button <MoreVertical size={18} className="inline" />,
					and make the required change.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.section-approach',
		title: 'Section approach',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					By answering this question, you add further context to this section.
					Type in your answer to this question or use the{' '}
					<span className="font-bold">Suggest a response</span> button to have
					the AI make its recommendation on what it will include in that
					section.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.suggest-response',
		title: 'Suggest response',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					You can use this feature to have the AI come up with a response. The
					AI will use its pretraining, solicitation, information about your
					organization, and any formative documents attached, as well as any
					documents attached to this section, to come up with a response.
				</p>
				<p>
					<span className="font-bold">Note:</span> This is a suggestion only. We
					recommend that you read through and edit or delete any recommendations
					that don’t align with your objectives.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.documents',
		title: 'Documents',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					Use the <span className="font-bold">Documents</span> button to add
					documents to this section.
				</p>
				<p>
					<span className="font-bold">Note:</span> Documents that you add here
					will only be available to this section and its sub sections while
					essential documents (added in the dashboard) are available to each
					section of this proposal
				</p>
			</div>
		),
		position: 'left',
	},
	{
		element: '.brainstorm',
		title: 'Brainstorm',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					Use the <span className="font-bold">Brainstorm</span> feature to have
					the AI brainstorm ideas with you
				</p>
			</div>
		),
		position: 'left',
	},
	{
		element: '.ideas',
		title: 'Ideas',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					Add <span className="font-bold">Ideas</span> that the AI will use when
					generating content. These can be anything from data points to
					instructions
				</p>
			</div>
		),
		position: 'left',
	},
	{
		element: '.clipboard',
		title: 'Clipboard',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					Here you can see the entire list of ideas that you can copy from all
					the sections. You can easily reuse them in different sections.
				</p>
			</div>
		),
		position: 'left',
	},
	{
		element: '.comments',
		title: 'Comments',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					You can add comments to the proposal for other collaborators to read.{' '}
				</p>
				<p>
					<span className="font-bold">Note:</span> The AI model will not read
					these comments
				</p>
			</div>
		),
		position: 'left',
	},
];
