const Add = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="22"
			viewBox="0 0 23 22"
			fill="none"
		>
			<mask
				id="mask0_1298_2390"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="23"
				height="22"
			>
				<path d="M22.408 0H0V21.4696H22.408V0Z" fill="white" />
			</mask>
			<g mask="url(#mask0_1298_2390)">
				<path
					d="M13.855 21.4696H3.67617C2.70149 21.4682 1.76714 21.0807 1.07793 20.3922C0.388732 19.7036 0.000918908 18.7701 -0.000488281 17.7964L-0.000488281 3.6724C0.000919367 2.69872 0.388757 1.76533 1.07798 1.07691C1.7672 0.388481 2.70156 0.00120503 3.67617 0L13.855 0C14.8295 0.00120546 15.7638 0.388505 16.4529 1.07695C17.142 1.7654 17.5297 2.69879 17.5309 3.6724V9.04397C17.5309 9.3144 17.4234 9.57375 17.232 9.76497C17.0406 9.95619 16.781 10.0636 16.5103 10.0636C16.2396 10.0636 15.98 9.95619 15.7886 9.76497C15.5972 9.57375 15.4897 9.3144 15.4897 9.04397V3.6724C15.489 3.23946 15.3166 2.82443 15.0102 2.51829C14.7038 2.21215 14.2883 2.0399 13.855 2.0393H3.67617C3.24274 2.0399 2.82723 2.21213 2.52069 2.51824C2.21414 2.82436 2.04155 3.23939 2.04075 3.6724V17.7941C2.04135 18.2273 2.21385 18.6425 2.52042 18.9488C2.82699 19.255 3.24261 19.4274 3.67617 19.428H13.855C14.2885 19.4274 14.704 19.255 15.0105 18.9487C15.3169 18.6424 15.4893 18.2272 15.4897 17.7941V16.4655C15.4897 16.195 15.5972 15.9357 15.7886 15.7445C15.98 15.5532 16.2396 15.4458 16.5103 15.4458C16.781 15.4458 17.0406 15.5532 17.232 15.7445C17.4234 15.9357 17.5309 16.195 17.5309 16.4655V17.7964C17.5297 18.7701 17.142 19.7035 16.453 20.3921C15.7639 21.0807 14.8296 21.4682 13.855 21.4696Z"
					fill="#5D9BFD"
				/>
				<path
					d="M21.3874 13.503H9.45607C9.25477 13.5033 9.05787 13.4441 8.89018 13.3329C8.72249 13.2216 8.59151 13.0632 8.51374 12.8777C8.43598 12.6922 8.4149 12.4879 8.45317 12.2904C8.49144 12.093 8.58734 11.9113 8.7288 11.7682L12.1129 8.32961C12.2063 8.23176 12.3182 8.15345 12.4422 8.09923C12.5662 8.04501 12.6997 8.01597 12.8351 8.0138C12.9704 8.01163 13.1048 8.03637 13.2305 8.08658C13.3561 8.13679 13.4705 8.21147 13.567 8.30627C13.6635 8.40107 13.7402 8.5141 13.7926 8.63878C13.8449 8.76346 13.872 8.8973 13.8721 9.03251C13.8722 9.16773 13.8454 9.30161 13.7932 9.42637C13.741 9.55114 13.6645 9.66429 13.5682 9.75924L11.8902 11.4637H21.3896C21.6603 11.4637 21.9199 11.5711 22.1113 11.7624C22.3027 11.9536 22.4102 12.2129 22.4102 12.4834C22.4102 12.7538 22.3027 13.0131 22.1113 13.2044C21.9199 13.3956 21.6603 13.503 21.3896 13.503"
					fill="#5D9BFD"
				/>
				<path
					d="M12.8384 16.8274C12.5705 16.8276 12.3133 16.7224 12.1225 16.5344L8.74071 13.2105C8.56089 13.0185 8.46225 12.7645 8.46541 12.5016C8.46856 12.2386 8.57326 11.9871 8.75764 11.7995C8.94202 11.6118 9.19183 11.5026 9.4549 11.4946C9.71797 11.4866 9.97396 11.5805 10.1694 11.7565L13.5543 15.0812C13.6986 15.2232 13.7974 15.4048 13.838 15.603C13.8787 15.8012 13.8594 16.007 13.7827 16.1943C13.7059 16.3815 13.5751 16.5417 13.407 16.6544C13.2388 16.7672 13.0409 16.8274 12.8384 16.8274Z"
					fill="#5D9BFD"
				/>
				<path
					d="M5.05579 6.1321H5.04059L1.00523 6.0744C0.73455 6.07238 0.475752 5.96303 0.285774 5.77038C0.0957965 5.57774 -0.00979969 5.31758 -0.00778417 5.04716C-0.00576865 4.77673 0.103693 4.51818 0.296522 4.32838C0.48935 4.13858 0.749749 4.03309 1.02043 4.0351H1.03639L4.03517 4.08141V1.02019C4.03517 0.74936 4.14286 0.489622 4.33455 0.298116C4.52624 0.10661 4.78622 -0.000976562 5.05731 -0.000976562C5.3284 -0.000976562 5.58838 0.10661 5.78007 0.298116C5.97176 0.489622 6.07945 0.74936 6.07945 1.02019V5.11321C6.07935 5.2473 6.05277 5.38006 6.00125 5.50388C5.94972 5.6277 5.87426 5.74015 5.77917 5.83479C5.68409 5.92944 5.57125 6.00441 5.44712 6.05543C5.32299 6.10644 5.19001 6.1325 5.05579 6.1321Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Add;
