const DocumentWithExclamation = ({ className = '' }) => {
	return (
		<svg
			className={className}
			width="133"
			height="141"
			viewBox="0 0 133 141"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_10391_20232)">
				<path
					d="M23.7572 2.79688L93.0772 2.89687C99.1972 2.89687 104.157 7.87687 104.157 13.9969L104.017 127.127C104.017 133.247 99.0372 138.207 92.9172 138.207L13.6972 138.107C7.57719 138.107 2.61719 133.127 2.61719 127.007L2.75719 22.0569L23.7572 2.79688Z"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M72.2459 42.3281H17.5859"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M72.2459 59.0781H17.5859"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M72.2459 75.8359H17.5859"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M53.3859 92.5859H17.5859"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M23.7578 2.79688V13.9369C23.7578 18.7569 19.8578 22.6569 15.0378 22.6569H2.75781L23.7578 2.79688Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M92.7763 124.967C113.415 124.967 130.146 108.235 130.146 87.5966C130.146 66.9577 113.415 50.2266 92.7763 50.2266C72.1374 50.2266 55.4062 66.9577 55.4062 87.5966C55.4062 108.235 72.1374 124.967 92.7763 124.967Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M120.594 113.516L130.384 123.296"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M89.6719 105.425C89.6719 104.355 90.0119 103.445 90.6819 102.695C91.3519 101.945 92.2719 101.575 93.4419 101.575C94.6119 101.575 95.5819 101.955 96.2419 102.695C96.9019 103.445 97.2319 104.355 97.2319 105.425C97.2319 106.495 96.9019 107.405 96.2419 108.155C95.5819 108.905 94.6519 109.275 93.4419 109.275C92.2319 109.275 91.3519 108.895 90.6819 108.155C90.0119 107.405 89.6719 106.495 89.6719 105.425ZM96.9319 69.3855L96.3719 93.3755C96.3319 94.9855 95.0219 96.2655 93.4119 96.2655C91.8019 96.2655 90.4919 94.9855 90.4519 93.3755L89.8619 69.3855C89.8219 67.7255 91.1619 66.3555 92.8219 66.3555H93.9719C95.6319 66.3555 96.9719 67.7255 96.9319 69.3855Z"
					fill="#AFC1DB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10391_20232">
					<rect
						width="131.69"
						height="139.33"
						fill="white"
						transform="translate(0.65625 0.835938)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default DocumentWithExclamation;
