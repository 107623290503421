import TemplateAssembler from '@/components/ui/icons/new/TemplateBuilder/TemplateAssembler';
import TemplateAssemblerDrag from '@/components/ui/icons/new/TemplateBuilder/TemplateAssemblerDrag';
import { useAppSelector } from '@/lib/hooks/hooks';
import { cn } from '@/lib/utils';
import { RootState } from '@/redux/store/store';

// Placeholder thumbnail for template assembler
// TODO: not work on child

export const TemplateAssemblerSkeleton = ({
	showBoundary = true,
	showFirstSection = true,
	showCompact = false,
	className = '',
}) => {
	const isNodeDragging = useAppSelector(
		(root: RootState) => root.templates.isNodeDragging
	);
	return (
		<div
			className={cn(
				`flex flex-col gap-5 justify-center items-center h-[calc(100vh-250px)] 2xl:h-[calc(100vh-350px)] 2xl:px-[150px] px-[100px] py-[80px] ${isNodeDragging && showBoundary ? 'border-2 border-primary border-dashed rounded-2xl' : ''}`,
				className
			)}
		>
			<div className="flex flex-col items-center gap-2">
				<TemplateAssembler className={showCompact ? 'h-20' : ''} />
				<h2 className="mt-3 font-normal text-center text-black dynamic-text">
					{showFirstSection
						? 'Click on a section on the right and drag it here to make it a primary section'
						: 'Drag a section above or below the current section to make another section'}
				</h2>
			</div>
			<div className="border-[#AFC1DB] h-[1px] border border-dashed w-full"></div>
			<div className="flex flex-col items-center gap-2">
				<TemplateAssemblerDrag className={showCompact ? 'h-20' : ''} />
				<h2 className="mt-3 font-normal text-center text-black dynamic-text">
					Drag a section under a primary section to make it its sub section
				</h2>
			</div>
		</div>
	);
};
