import { SVGProps } from 'react';

const LogoLarge = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="66"
			height="65"
			viewBox="0 0 66 65"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_8201_9675)">
				<path
					d="M17.1186 9.12695H58.0669C62.1702 9.12695 65.5 12.4602 65.5 16.5679C65.5 43.2978 43.8205 65.0002 17.1186 65.0002C13.0153 65.0002 9.68555 61.6669 9.68555 57.5592V16.5679C9.68555 12.4602 13.0153 9.12695 17.1186 9.12695Z"
					fill="url(#paint0_linear_8201_9675)"
				/>
				<path
					d="M48.8813 0C52.9847 0 56.3144 3.33322 56.3144 7.44092V48.4323C56.3144 52.54 52.9847 55.8732 48.8813 55.8732H7.93309C3.82972 55.8732 0.5 52.54 0.5 48.4323C0.5 21.7023 22.1795 0 48.8813 0Z"
					fill="url(#paint1_linear_8201_9675)"
				/>
				<path
					d="M53.2412 3.98828H12.293C8.18777 3.98828 4.85986 7.31969 4.85986 11.4292V52.4206C4.85986 56.5301 8.18777 59.8615 12.293 59.8615H53.2412C57.3464 59.8615 60.6743 56.5301 60.6743 52.4206V11.4292C60.6743 7.31969 57.3464 3.98828 53.2412 3.98828Z"
					fill="#F0F0F0"
				/>
				<path
					d="M45.7052 43.7427H44.2049C42.867 43.7427 41.777 42.6559 41.7599 41.3209C41.7599 41.2995 41.7685 41.2652 41.7685 41.231V23.6749C41.8112 22.3527 42.8798 21.313 44.2091 21.313H45.7052C46.4233 21.313 47.0131 20.7267 47.0131 20.0036C47.0131 19.2805 46.4275 18.6943 45.7052 18.6943H44.2049C42.7901 18.6943 41.4394 19.3062 40.4862 20.3459C39.533 19.2677 38.1609 18.6387 36.7077 18.6387H35.2074C34.4893 18.6387 33.8994 19.2249 33.8994 19.948C33.8994 20.6711 34.485 21.2573 35.2074 21.2573H36.7077C38.0413 21.2573 39.1355 22.3399 39.1526 23.6749C39.1526 23.7048 39.1483 23.7348 39.1483 23.7605V41.3209C39.1013 42.6387 38.037 43.6785 36.7077 43.6785H35.2116C34.4935 43.6785 33.9037 44.2647 33.9037 44.9878C33.9037 45.711 34.4893 46.2972 35.2116 46.2972H36.7119C38.1267 46.2972 39.4774 45.6896 40.4306 44.6498C41.3838 45.7281 42.7601 46.3571 44.2091 46.3613H45.7094C46.4275 46.3613 47.0174 45.7751 47.0174 45.0563C47.0174 44.3374 46.4318 43.747 45.7094 43.747L45.7052 43.7427Z"
					fill="#5E92FF"
				/>
				<path
					d="M32.3693 25.3908C33.5234 26.5718 34.1004 28.3603 34.1004 30.765V39.3099C34.1004 39.9389 33.9123 40.4352 33.5405 40.799C33.1686 41.1627 32.6557 41.3424 32.0017 41.3424C31.3948 41.3424 30.8989 41.1541 30.5142 40.7818C30.1296 40.4096 29.9372 39.9175 29.9372 39.3099V38.5397C29.5397 39.4511 28.9156 40.1614 28.065 40.6749C27.2144 41.1883 26.2271 41.4451 25.1072 41.4451C23.9873 41.4451 22.9273 41.2097 21.9955 40.7433C21.0637 40.2769 20.3285 39.6351 19.7899 38.8178C19.2513 38.0006 18.9863 37.0892 18.9863 36.0879C18.9863 34.8257 19.3069 33.8373 19.9481 33.1099C20.5892 32.3867 21.6321 31.8604 23.0769 31.5352C24.5216 31.21 26.5177 31.0432 29.0567 31.0432H29.9329V30.2387C29.9329 29.0963 29.6893 28.2619 29.1977 27.7356C28.7062 27.2093 27.9154 26.9483 26.8212 26.9483C26.1459 26.9483 25.4577 27.0467 24.7567 27.2478C24.0557 27.4447 23.2308 27.7313 22.2733 28.1079C21.6663 28.4117 21.2218 28.5614 20.944 28.5614C20.5251 28.5614 20.1789 28.4117 19.9139 28.1079C19.6446 27.8041 19.5121 27.4062 19.5121 26.9184C19.5121 26.5204 19.6104 26.1781 19.8113 25.8872C20.0079 25.5962 20.3413 25.3224 20.8072 25.0656C21.6236 24.6206 22.5982 24.274 23.7266 24.013C24.8593 23.7563 25.9706 23.6279 27.0691 23.6279C29.4457 23.6279 31.2152 24.2184 32.3693 25.3951V25.3908ZM28.8344 37.1234C29.5696 36.3404 29.9372 35.3306 29.9372 34.094V33.358H29.3089C27.7445 33.358 26.5348 33.4265 25.6714 33.5677C24.808 33.7089 24.1882 33.9528 23.8163 34.3037C23.4445 34.6545 23.2564 35.1338 23.2564 35.7414C23.2564 36.4901 23.5171 37.102 24.0429 37.5813C24.5686 38.0605 25.2141 38.3001 25.9834 38.3001C27.1503 38.3001 28.0992 37.9107 28.8344 37.1277V37.1234Z"
					fill="#E861C9"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_8201_9675"
					x1="5.86856"
					y1="56.8147"
					x2="57.3816"
					y2="5.35589"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00EEFF" />
					<stop offset="1" stopColor="#A251FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_8201_9675"
					x1="8.67255"
					y1="59.6942"
					x2="60.1856"
					y2="8.23539"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF5E00" />
					<stop offset="1" stopColor="#DC51FF" />
				</linearGradient>
				<clipPath id="clip0_8201_9675">
					<rect
						width="65"
						height="65"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default LogoLarge;
