import { cn } from '@/lib/utils';
import { FC, useState } from 'react';
import { OrganizationProfile } from '@/lib/types/apiTypes';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { Skeleton } from '../ui/skeleton';
import styles from './OrganizationProfileSelection.module.css';

interface OrganizationProfileSelectionProps {
	isLoading?: boolean;
	organizationProfiles: OrganizationProfile[];
	organizationProfile?: OrganizationProfile;
	setOrganizationProfile: (organizationProfileId: string) => void;
}

const OrganizationProfileSelection: FC<OrganizationProfileSelectionProps> = ({
	isLoading = false,
	organizationProfiles,
	organizationProfile,
	setOrganizationProfile,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			{isLoading ? (
				<div className="flex flex-col text-start gap-2 px-4 py-3 w-full">
					<Skeleton className="h-4 w-4/12 bg-slate-300" />
					<div className="flex flex-col gap-1">
						<Skeleton className="h-3 w-full bg-slate-300" />
						<Skeleton className="h-3 w-3/12 bg-slate-300" />
					</div>
				</div>
			) : (
				<Select
					disabled={organizationProfiles?.length === 1}
					onValueChange={setOrganizationProfile}
					onOpenChange={(open) => setIsOpen(open)}
					value={String(organizationProfile?.id)}
				>
					<SelectTrigger
						className={cn(
							styles.profileSelectTrigger,
							'h-full border-none rounded-xl text-sm xl:text-base bg-white text-black px-4 py-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
						)}
					>
						<SelectValue placeholder="Select organization profile">
							<div className="flex flex-col text-start gap-2">
								<h2 className="text-black font-bold dynamic-medium">
									{organizationProfile?.name}
								</h2>
								<TooltipProvider delayDuration={50}>
									<Tooltip>
										<TooltipTrigger className="block w-full overflow-hidden">
											<p className="text-black dynamic-text pr-4 truncate z-50 hover:bg-[#EAF1FC]">
												{organizationProfile?.description}
											</p>
										</TooltipTrigger>
										<TooltipContent
											hidden={isOpen}
											side="bottom"
											className="bg-white text-black dynamic-text p-4 rounded-3xl border-none shadow-none drop-shadow-effect max-w-[650px] xl:max-w-[850px] 2xl:max-w-[1000px]"
										>
											{organizationProfile?.description}
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</div>
						</SelectValue>
					</SelectTrigger>
					<SelectContent className="py-2 bg-white rounded-xl max-w-[var(--radix-select-trigger-width)] overflow-visible">
						{organizationProfiles.map((organizationProfile, index) => {
							return (
								<div
									className="mx-4 py-2 border-b border-[#D3DDE2]"
									key={index}
								>
									<SelectItem
										className="text-black cursor-pointer rounded-xl hover:bg-[#E3EEFF]"
										value={String(organizationProfile.id)}
									>
										<div className="flex flex-col text-start gap-2 pr-20 max-w-[var(--radix-select-trigger-width)]">
											<h2 className="font-bold dynamic-medium ">
												{organizationProfile.name}
											</h2>
											<p className="dynamic-text truncate">
												{organizationProfile.description}
											</p>
										</div>
									</SelectItem>
								</div>
							);
						})}
					</SelectContent>
				</Select>
			)}
		</>
	);
};

export default OrganizationProfileSelection;
