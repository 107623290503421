import { Badge } from '@/components/ui/badge';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cropText } from '@/lib/functions/funcUtils';
import { extractNames } from '@/lib/functions/TemplateBuilder/templateUtils';
import { cn } from '@/lib/utils';
import { RootState } from '@/redux/store/store';
import { ChevronRight } from 'lucide-react';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

interface TemplateTagsProps {}

const TemplateTags: FC<TemplateTagsProps> = () => {
	const templateBuilderData = useSelector(
		(state: RootState) => state.templates.templateBuilder
	);
	const tagsFilter = useMemo(() => {
		return templateBuilderData?.tags.every(
			(element) => typeof element === 'string'
		)
			? templateBuilderData?.tags
			: extractNames(templateBuilderData?.tags);
	}, [templateBuilderData]);

	return (
		<div className="flex items-center w-full gap-1">
			{tagsFilter &&
				tagsFilter.slice(0, 3).map((item) => (
					<TooltipProvider key={item}>
						<Tooltip>
							<TooltipTrigger type="button">
								<Badge
									key={item}
									className="items-center justify-center block px-3 bg-white border max-w-40 text-secondary border-secondary"
								>
									<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-small text-nowrap text-ellipsis">
										{cropText(item, 16)}
									</span>
								</Badge>
							</TooltipTrigger>
							<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
								{item}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				))}
			{tagsFilter && tagsFilter.length > 3 && (
				<Popover>
					<PopoverTrigger onClick={(e) => e.stopPropagation()}>
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger
									type="button"
									className="flex items-center justify-center"
								>
									<ChevronRight className={cn('text-[#5D6F79]')} size={18} />
								</TooltipTrigger>
								<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
									View More Tags
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</PopoverTrigger>
					<PopoverContent
						className="gap-2 py-4 bg-white rounded-md "
						align="end"
					>
						<h2 className="text-[#5D6F79] font-bold mb-3">Template Tags</h2>
						<div className="flex flex-wrap gap-1">
							{tagsFilter &&
								tagsFilter.map((item) => (
									<Badge
										key={item}
										className="items-center justify-center block px-3 bg-white border text-secondary border-secondary"
									>
										{item.length > 35 ? (
											<TooltipProvider>
												<Tooltip>
													<TooltipTrigger
														tabIndex={-1}
														className="flex text-left"
													>
														<span className="font-medium uppercase text-secondary dynamic-badge">
															{cropText(item, 35)}
														</span>
													</TooltipTrigger>
													<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
														{item}
													</TooltipContent>
												</Tooltip>
											</TooltipProvider>
										) : (
											<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-badge">
												{item}
											</span>
										)}
									</Badge>
								))}
						</div>
					</PopoverContent>
				</Popover>
			)}
		</div>
	);
};

export default TemplateTags;
