import { SVGProps } from 'react';

const Logout = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_4543_11836"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_4543_11836)">
				<path
					d="M2.22222 20C1.61111 20 1.08796 19.7824 0.652778 19.3472C0.217593 18.912 0 18.3889 0 17.7778V2.22222C0 1.61111 0.217593 1.08796 0.652778 0.652778C1.08796 0.217593 1.61111 0 2.22222 0H10V2.22222H2.22222V17.7778H10V20H2.22222ZM14.4444 15.5556L12.9167 13.9444L15.75 11.1111H6.66667V8.88889H15.75L12.9167 6.05556L14.4444 4.44444L20 10L14.4444 15.5556Z"
					fill={props.fill}
				/>
			</g>
		</svg>
	);
};

export default Logout;
