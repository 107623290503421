import { FC, useState } from 'react';
import { FilePlus, FolderPlus } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog, openPanel } from '@/redux/slices/pageSlice';
import { motion } from 'framer-motion';
import { containerDivFadeIn } from '@/constants/variants';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { createFolder } from '@/lib/functions/apiCalls';
import { useQueryClient } from 'react-query';
import { Folder } from '@/lib/types/apiTypes';
import useAuth from '@/lib/hooks/useAuth';
import DecDocumentIcon from '../ui/icons/DecDocumentIcon';

interface DocumentActionsProps {
	folder?: Folder;
}

const DocumentActions: FC<DocumentActionsProps> = ({ folder }) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const { isGuest } = useAuth();
	const [isHovered, setIsHovered] = useState(false);

	const handleCreateFolder = async (folderName: string) => {
		try {
			const response = await createFolder({
				...(folder?.id && { parentId: folder.id }),
				name: folderName,
			});
			handleResponse(response, 'Folder has been created successfully.');
			queryClient.invalidateQueries('folders');
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	return (
		<motion.div
			className="z-10 flex items-center justify-between"
			key="containerDivFadeIn"
			variants={containerDivFadeIn}
			initial="initial"
			animate="animate"
		>
			{!isGuest && (
				<div className="flex gap-4">
					<SecondaryButton
						onClick={() =>
							dispatch(
								openDialog({
									type: 'nameEditor',
									nameEditorObj: {
										title: 'Create Folder',
										label: 'Folder Name',
										placeholder: 'Enter folder name',
										existingName: '',
										maxLength: 50,
										onConfirm: (folderName) => {
											handleCreateFolder(folderName);
										},
										confirmText: 'Create',
									},
								})
							)
						}
						className="create-folder-button"
					>
						<FolderPlus />
						Folder
					</SecondaryButton>
					<PrimaryButton
						onClick={() =>
							dispatch(
								openDialog({
									type: 'uploadDocuments',
									folder: folder,
								})
							)
						}
						className="add-folder-button"
					>
						<FilePlus />
						Document
					</PrimaryButton>
					<SecondaryButton
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
						onClick={() =>
							dispatch(
								openPanel({
									type: 'externalDocuments',
									folder: folder,
								})
							)
						}
						className="usaid-dec-button"
					>
						<DecDocumentIcon mode={isHovered ? 'hovered' : ''} />
						USAID Dec
					</SecondaryButton>
				</div>
			)}
		</motion.div>
	);
};

export default DocumentActions;
