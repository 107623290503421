import { FC } from 'react';
import styles from './SideBarMenuItem.module.css';
import Icons from '../ui/icons/Icons';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';

interface SideBarMenuItemProps {
	name: string;
	link?: string | undefined;
	active?: boolean;
	className?: string;
	onClick?: () => void;
}

const SideBarMenuItem: FC<SideBarMenuItemProps> = ({
	name,
	link,
	className = '',
	...props
}) => {
	const windowLocation = useLocation();
	return (
		<li {...props}>
			<Link
				tabIndex={-1}
				to={link ? link : '#'}
				className={cn(
					styles.nav_link,
					className,
					windowLocation.pathname === link && 'bg-secondary'
				)}
			>
				<Icons type={name} />
			</Link>
		</li>
	);
};

export default SideBarMenuItem;
