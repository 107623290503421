import {
	Sheet,
	SheetContent,
	SheetOverlay,
	SheetTrigger,
} from '@/components/ui/sheet';
import { useState } from 'react';

import { useAppDispatch } from '@/lib/hooks/hooks';
import useProposalId from '@/lib/hooks/useProposalId';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import AssistanceTab from '@/components/AssistanceTab/AssistanceTab';
import ComplianceTab from '@/components/ComplianceTab/ComplianceTab';
import AIWhite from '@/components/ui/icons/new/AIWhite';
import VisualsTab from '@/components/VisualsTab/VisualsTab';
import { fetchAllProposalCharts } from '@/redux/slices/chartSlice';
import { fetchComplianceMatrix } from '@/redux/requests/compliance';

const WriteModeDrawer = () => {
	const [selectedTab, setSelectedTab] = useState('assistant');
	const dispatch = useAppDispatch();
	const proposalId = useProposalId() as number;

	const isLogicalFrame = location.pathname.includes('/logical-frame');

	const handleVisuals = () => {
		dispatch(fetchAllProposalCharts(proposalId));
	};
	return (
		<Sheet>
			<SheetTrigger className="fixed z-50 top-2 right-4">
				<div className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-transparent border border-white rounded-lg animated-hover-effect animated-hover-color-16 ">
					<AIWhite /> Assistant
				</div>
			</SheetTrigger>
			<SheetOverlay>
				<SheetContent className="!max-w-[450px] p-0 bg-[#f1f3f5]">
					<AppTabs
						defaultTab="assistant"
						type="drawer"
						handleTabChange={(val: string) => {
							setSelectedTab(val);
						}}
						selectedTab={selectedTab}
						tabs={[
							{
								label: 'Assistance',
								value: 'assistant',
								content: (
									<AssistanceTab mode={isLogicalFrame ? 'logical-frame' : ''} />
								),
							},
							{
								label: 'Compliance',
								value: 'compliance',
								content: <ComplianceTab />,
								onClick: () => dispatch(fetchComplianceMatrix(proposalId)),
							},
							{
								label: 'Visuals',
								value: 'visuals',
								content: <VisualsTab />,
								onClick: handleVisuals,
							},
						]}
					/>
				</SheetContent>
			</SheetOverlay>
		</Sheet>
	);
};

export default WriteModeDrawer;
