import { Section } from '../types/apiTypes';
import { Node, NodeType } from '../types/graphTypes';

// This function is designed to parse a hierarchical structure of sections into a flat list of nodes.
export function parseSectionsToNodes(sections: Section[]): Node[] {
	// Initialize an empty array to store the resulting nodes.
	let nodes: Node[] = [];

	// Iterate over each section in the provided sections array.
	sections?.forEach((section) => {
		// Push a new node to the nodes array. The node is constructed based on the current section's properties.
		nodes.push({
			// Convert the section's id (which is a number) to a string.
			id: section.id.toString(),
			// Determine the node type (parent, child, grandchild) based on the section's level.
			type: getNodeType(section.level),
			// Construct the data object for the node.
			data: {
				// Use the section's name as the label for the node.
				label: section.name,
				id: section.id,
				alias: section.alias,
				// If the section has a parentId, convert it to a string. Otherwise, set it to undefined.
				parentId: section.parentId ? section.parentId.toString() : undefined,
				level: section.level,
				isComplete: section.isComplete,
				commentCount: section.commentsCount,
				wordCount: section.wordCount,
				description: section.description,
				expectedOutput: section.expectedOutput,
				question: section.questions[0]?.text,
				questionDescription: section.questions[0]?.description,
				questionTip: section.questions[0]?.proTip,
				prerequisites: section.prerequisites,
				requiredRfpSections: section.requiredRfpSections,
			},
		});

		// If the current section has children...
		if (section.children && section.children.length > 0) {
			// Recursively call the parseSectionsToNodes function for the children and concatenate the result to the nodes array.
			nodes = nodes.concat(parseSectionsToNodes(section.children));
		}
	});

	// Return the constructed nodes array.
	return nodes;
}

// This helper function determines the node type based on the provided level.
export function getNodeType(level: number): NodeType {
	switch (level) {
		// If the level is 0, the node type is 'parent'.
		case 0:
			return 'parent';
		// If the level is 1, the node type is 'child'.
		case 1:
			return 'child';
		// If the level is 2, the node type is 'grandchild'.
		case 2:
			return 'grandchild';
		// If the level doesn't match any of the known levels, throw an error.
		default:
			throw new Error('Invalid node level');
	}
}

export function nodeHasChildren(nodeId: string, nodes: Node[]): boolean {
	return nodes.some((node) => node.data.parentId === nodeId);
}

export function isDescendantOf(
	nodeId: string,
	ancestorId: string,
	nodes: Node[]
): boolean {
	let currentNode = nodes.find((node) => node.id === nodeId);
	while (currentNode && currentNode.data.parentId !== null) {
		if (currentNode.data.parentId === ancestorId) return true;
		currentNode = nodes.find((node) => node.id === currentNode?.data.parentId);
	}
	return false;
}

export const getTree = (sortedNodes: Node[], sectionId: number) => {
	// Find the node for the given sectionId
	const node = sortedNodes.find((node) => node.id === String(sectionId));

	if (!node) {
		console.log('Node not found');
		return [];
	}

	let parentId;

	// Determine the parent ID based on the node type
	if (node.type === 'parent') {
		parentId = node.id;
	} else if (node.type === 'child') {
		// For a child, the parent ID is in the data.parentId field
		parentId = node.data.parentId;
	} else if (node.type === 'grandchild') {
		// For a grandchild, find its parent and then the grandparent
		const child = sortedNodes.find((n) => n.id === node.data.parentId);
		parentId = child ? child.data.parentId : null;
	}

	if (!parentId) {
		console.log('Parent not found');
		return [];
	}

	const parent = sortedNodes.find((n) => n.id === parentId);

	// Return the parent (if found), all its children, and all grandchildren of those children
	const result = sortedNodes.filter(
		(n) =>
			n.id === parent?.id ||
			n.data.parentId === parent?.id ||
			(n.type === 'grandchild' &&
				sortedNodes.some(
					(p) => p.id === n.data.parentId && p.data.parentId === parent?.id
				))
	);

	// Return the parent and all its children and grandchildren
	return result;
};

export const findParent = (sortedNodes: Node[], sectionId: string) => {
	// Find the node for the given sectionId
	const node = sortedNodes.find((node) => node.id === sectionId);

	if (!node) return [];

	let parentId;

	// Determine the parent ID based on the node type
	if (node.type === 'parent') {
		parentId = node.id;
	} else if (node.type === 'child') {
		// For a child, the parent ID is in the data.parentId field
		parentId = node.data.parentId;
	} else if (node.type === 'grandchild') {
		// For a grandchild, find its parent and then the grandparent
		const child = sortedNodes.find((n) => n.id === node.data.parentId);
		parentId = child ? child.data.parentId : null;
	}

	if (!parentId) {
		console.log('Parent not found');
		return [];
	}

	const parent = sortedNodes.find((n) => n.id === parentId);

	return parent!.id;
};
