import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { ChangePasswordPayload } from '@/lib/types/apiTypes';
import { changePassword } from '@/lib/functions/apiCalls';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useEffect } from 'react';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import useUser from '@/lib/hooks/useUser';

interface FormData {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const formSchema = z
	.object({
		currentPassword: z.string().min(2, {
			message: 'Please enter correct information before saving.',
		}),
		newPassword: z.string().min(2, {
			message: 'Please enter correct information before saving.',
		}),
		confirmPassword: z.string(),
	})
	.refine((data) => data.newPassword === data.confirmPassword, {
		message: "Passwords don't match",
		path: ['confirmPassword'],
	});

const ChangePasswordDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'changePassword'
	);
	const user = useUser();
	const dispatch = useAppDispatch();

	const form = useForm<FormData>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
	});

	useEffect(() => {
		form.reset();
	}, [form, isOpen]);

	const formatChangePasswordPayload = (
		formData: FormData
	): ChangePasswordPayload => {
		return {
			userInput: formData.currentPassword,
			password: formData.newPassword,
		};
	};

	const onSubmit = async (formData: FormData) => {
		try {
			if (user?.id) {
				dispatch(processStart());
				const changePasswordPayload: ChangePasswordPayload =
					formatChangePasswordPayload(formData);
				const response = await changePassword(user.id, changePasswordPayload);
				handleResponse(response, 'Password has been updated successfully.');
				dispatch(processSuccess());
				dispatch(closeDialog());
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] bg-white">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="flex flex-col w-full h-full gap-5"
					>
						<h3 className="font-bold text-secondary dynamic-medium">
							Change Password
						</h3>
						<FormField
							control={form.control}
							name="currentPassword"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Current password</Label>
										<FormControl>
											<Input
												className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
												placeholder="Enter current password"
												type="password"
												{...field}
											></Input>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
						<FormField
							control={form.control}
							name="newPassword"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>New password</Label>
										<FormControl>
											<Input
												className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
												placeholder="Enter new password"
												type="password"
												{...field}
											></Input>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
						<FormField
							control={form.control}
							name="confirmPassword"
							render={({ field }) => {
								return (
									<FormItem>
										<Label>Confirm password</Label>
										<FormControl>
											<Input
												className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
												placeholder="Enter confirm password"
												type="password"
												{...field}
											></Input>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							}}
						/>
						<div className="flex justify-end w-full gap-4 mx-auto">
							<SecondaryButton
								onClick={() => dispatch(closeDialog())}
								type="button"
							>
								CANCEL
							</SecondaryButton>
							<PrimaryButton title="" type="submit">
								Change Password
							</PrimaryButton>
						</div>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default ChangePasswordDialog;
