import { FC, MouseEventHandler } from 'react';
import styles from './CustomEditor.module.css';
import { cn } from '@/lib/utils';
import { Button } from '../ui/button';
import EditIcon from '../ui/icons/EditIcon';
import Ai from '../ui/icons/Ai';

interface CustomEditorProps {
	placeholder: string;
	value?: string;
	isEditable?: boolean;
	onChange: (e: React.FormEvent<HTMLDivElement>) => void;
	canSave?: boolean;
	onSave?: MouseEventHandler<HTMLButtonElement> | undefined;
	onEditEditor?: () => void;
	canSuggest?: boolean;
	className: string;
	disabled?: boolean;
	forwardedRef: React.RefObject<HTMLDivElement>;
}

const CustomEditor: FC<CustomEditorProps> = ({
	placeholder,
	isEditable = true,
	value = '',
	onChange,
	onSave,
	onEditEditor,
	canSave = false,
	canSuggest = false,
	forwardedRef,
	className,
}) => {
	const handlePaste = (e) => {
		e.preventDefault(); // Prevent the default paste behavior
		const text = e.clipboardData.getData('text/plain'); // Get plain text from clipboard
		// Insert the plain text into the content editable div
		document.execCommand('insertText', false, text);
	};

	return (
		<>
			<div
				title={placeholder}
				onChange={(e) => onChange(e)}
				className={cn(styles.customEditor, className)}
				contentEditable={isEditable}
				suppressContentEditableWarning={true}
				onInput={(e) => onChange(e)}
				onPaste={handlePaste}
				ref={forwardedRef}
			>
				{value}
			</div>
			{canSave && isEditable && canSuggest && (
				<div className="absolute top-[5rem] right-[1rem] has-tooltip flex items-center gap-2 cursor-pointer">
					<div className="w-12 h-12 pl-4 hover:scale-110">
						<Ai className="h-10" />
					</div>
					<span className="bg-white w-[17.5rem] absolute px-2 rounded-main right-0 top-[-3rem] tooltip text-[#5d9bfd] font-bold uppercase ">
						SUGGEST A RESPONSE TO THIS
					</span>
				</div>
			)}
			{canSave && isEditable && (
				<Button
					className="!absolute bottom-[2rem] 2xl:bottom-[3.2rem] right-[1.2rem] h-8 uppercase bg-[#5d9bfd] text-white rounded-full font-medium animated-hover-effect animated-hover-color"
					onClick={onSave}
				>
					Save the Response
				</Button>
			)}
			{canSave && !isEditable && (
				<button
					className="!absolute bottom-[2.25rem]  right-[1rem]"
					onClick={() => {
						onEditEditor && onEditEditor();
					}}
				>
					<EditIcon className="h-5 " />
				</button>
			)}
		</>
	);
};

export default CustomEditor;
