import { SVGProps } from 'react';

const VisualEmpty = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="204"
			height="158"
			viewBox="0 0 204 158"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Layer_1" clipPath="url(#clip0_6243_12158)">
				<g id="Group">
					<path
						id="Vector"
						d="M148.378 50.2002H30.9984C27.0772 50.2002 23.8984 53.379 23.8984 57.3002V126.68C23.8984 130.601 27.0772 133.78 30.9984 133.78H148.378C152.3 133.78 155.478 130.601 155.478 126.68V57.3002C155.478 53.379 152.3 50.2002 148.378 50.2002Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="3.92"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="Vector_2">
						<path d="M36.8867 75.2305V121.92H108.607" fill="white" />
						<path
							d="M36.8867 75.2305V121.92H108.607"
							stroke="#AFC1DB"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
					<g id="Vector_3">
						<path
							d="M49.8789 108.931L62.8189 96.3705L78.4289 105.881L99.7489 83.5205"
							fill="white"
						/>
						<path
							d="M49.8789 108.931L62.8189 96.3705L78.4289 105.881L99.7489 83.5205"
							stroke="#AFC1DB"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</g>
				<g id="Group_2">
					<path
						id="Vector_4"
						d="M194.09 6.58984H120.83C116.909 6.58984 113.73 9.76862 113.73 13.6898V82.0298C113.73 85.9511 116.909 89.1298 120.83 89.1298H194.09C198.012 89.1298 201.19 85.9511 201.19 82.0298V13.6898C201.19 9.76862 198.012 6.58984 194.09 6.58984Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="3.92"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="Group_3">
						<path
							id="Vector_5"
							d="M153.419 52.0902L180.219 43.4902C181.109 46.2702 181.569 49.1702 181.569 52.0902C181.569 67.5302 168.869 80.2302 153.429 80.2302C137.989 80.2302 125.289 67.5302 125.289 52.0902C125.289 36.6502 137.989 23.9502 153.429 23.9502C156.089 23.9502 158.739 24.3302 161.299 25.0702L153.429 52.0902H153.419Z"
							fill="white"
							stroke="#AFC1DB"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_6"
							d="M153.418 52.0902L163.628 15.4902C169.698 17.1802 175.258 20.3602 179.798 24.7402C184.338 29.1202 187.718 34.5602 189.628 40.5702L153.418 52.0902Z"
							fill="#EAF1FC"
							stroke="#AFC1DB"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</g>
				<g id="Group_4">
					<path
						id="Vector_7"
						d="M152.771 153.33C167.163 153.33 178.831 141.662 178.831 127.27C178.831 112.877 167.163 101.21 152.771 101.21C138.378 101.21 126.711 112.877 126.711 127.27C126.711 141.662 138.378 153.33 152.771 153.33Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="3.92"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_8"
						d="M161.781 124.97H155.061V118.25C155.061 116.98 154.031 115.95 152.761 115.95C151.491 115.95 150.461 116.98 150.461 118.25V124.97H143.741C142.471 124.97 141.441 126 141.441 127.27C141.441 128.54 142.471 129.57 143.741 129.57H150.461V136.29C150.461 137.56 151.491 138.59 152.761 138.59C154.031 138.59 155.061 137.56 155.061 136.29V129.57H161.781C163.051 129.57 164.081 128.54 164.081 127.27C164.081 126 163.051 124.97 161.781 124.97Z"
						fill="#AFC1DB"
					/>
				</g>
				<g id="Group_5">
					<path
						id="Vector_9"
						d="M71.2009 5.20996H9.06094C5.13972 5.20996 1.96094 8.38874 1.96094 12.31V57.77C1.96094 61.6912 5.13972 64.87 9.06094 64.87H71.2009C75.1222 64.87 78.3009 61.6912 78.3009 57.77V12.31C78.3009 8.38874 75.1222 5.20996 71.2009 5.20996Z"
						fill="white"
						stroke="#AFC1DB"
						strokeWidth="3.92"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="Group_6">
						<path
							id="Vector_10"
							d="M29.5742 26.8294V48.4894C29.5742 50.0744 30.8592 51.3594 32.4442 51.3594H32.5542C34.1393 51.3594 35.4242 50.0744 35.4242 48.4894V26.8294C35.4242 25.2443 34.1393 23.9594 32.5542 23.9594H32.4442C30.8592 23.9594 29.5742 25.2443 29.5742 26.8294Z"
							fill="white"
							stroke="#AFC1DB"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_11"
							d="M16.4023 38.8694V48.4894C16.4023 50.0744 17.6873 51.3594 19.2723 51.3594H19.3823C20.9674 51.3594 22.2523 50.0744 22.2523 48.4894V38.8694C22.2523 37.2843 20.9674 35.9994 19.3823 35.9994H19.2723C17.6873 35.9994 16.4023 37.2843 16.4023 38.8694Z"
							fill="white"
							stroke="#AFC1DB"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_12"
							d="M43.793 32.4196V48.4796C43.793 50.0647 45.0779 51.3496 46.663 51.3496H46.773C48.358 51.3496 49.643 50.0647 49.643 48.4796V32.4196C49.643 30.8346 48.358 29.5496 46.773 29.5496H46.663C45.0779 29.5496 43.793 30.8346 43.793 32.4196Z"
							fill="white"
							stroke="#AFC1DB"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_13"
							d="M58.0039 21.5894V48.4894C58.0039 50.0744 59.2888 51.3594 60.8739 51.3594H60.9839C62.569 51.3594 63.8539 50.0744 63.8539 48.4894V21.5894C63.8539 20.0043 62.569 18.7194 60.9839 18.7194H60.8739C59.2888 18.7194 58.0039 20.0043 58.0039 21.5894Z"
							fill="#EAF1FC"
							stroke="#AFC1DB"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_6243_12158">
					<rect
						width="203.15"
						height="156.93"
						fill="white"
						transform="translate(0 0.535156)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default VisualEmpty;
