import { Badge } from '@/components/ui/badge';
import { Template as TemplateIcon } from '@/components/ui/icons/Template';
import { cn } from '@/lib/utils';
import styles from './TemplateView.module.css';
import { FC, useEffect } from 'react';
import { RFP, Template } from '@/lib/types/apiTypes';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { motion } from 'framer-motion';
import {
	prTextSlideIn,
	prInputSlideInDelay,
	prContainerExit,
	prInputSlideIn,
	prFooterSlideInDelay,
} from '@/constants/variants';
import {
	clearIntermediatePage,
	openDialog,
	setIntermediatePage,
} from '@/redux/slices/pageSlice';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { Skeleton } from '@/components/ui/skeleton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import useAuth from '@/lib/hooks/useAuth';
import { useNavigate } from 'react-router';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import { omit } from 'lodash';
import {
	buildTemplate,
	setEditTemplateId,
	setTemplateMode,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { TemplateLibrary } from '@/lib/types/TemplateBuilder/templateTypes';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import TemplateNotFound from '@/components/ui/icons/TemplateNotFound';
import ProposalStructure from '../../ProposalStructure/ProposalStructure';
import TemplateSectionDetails from '../../TemplateSectionDetails/TemplateSectionDetails';
import { pageTransitionEnd, setTemplate } from '@/redux/slices/proposalSlice';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';

interface TemplateViewProps {
	selectedTemplate: Template;
	templateTags?: string[];
	documentId?: number;
	rfp?: RFP;
	pageTransitionLoad: boolean;
}

const TemplateView: FC<TemplateViewProps> = ({
	selectedTemplate,
	rfp,
	pageTransitionLoad = false,
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { hasTemplateBuilderAccess } = useAuth();
	const organizationIdentifier = useOrganizationId();
	const intermediatePage = useSelector(
		(root: RootState) => root.page.intermediatePage
	);
	const templateSections = rfp?.sections.find(
		(section) => section.name === 'templateSections'
	);
	const sectionNames = templateSections?.summary
		?.split('-')
		?.map((sectionName) => sectionName.trim());

	const openChangeTemplateDialog = async () => {
		dispatch(
			openDialog({
				type: 'confirmTemplate',
			})
		);
	};

	const handleEditTemplate = (templateCardData) => {
		if (organizationIdentifier === templateCardData.organizationIdentifier) {
			dispatch(setTemplateMode('edit'));
			dispatch(setEditTemplateId(templateCardData?._id));
			dispatch(buildTemplate(templateCardData));
			navigate(`/template/edit/${templateCardData?._id}`);
		} else {
			const updatedData = omit(templateCardData, '_id');
			dispatch(setTemplateMode('clone'));
			dispatch(buildTemplate(updatedData as TemplateLibrary));
			navigate(`/template/new`);
		}
		dispatch(
			setIntermediatePage({
				mode: 'editTemplate',
				scope: [
					'/template/new',
					'/template/edit',
					'/create',
					'/template/design',
				],
				state: {},
				onCancel: async () => {
					navigate(`/create`);
				},
				onCompletion: async () => {
					navigate(`/create`);
				},
			})
		);
	};

	useEffect(() => {
		if (intermediatePage) {
			if (intermediatePage?.state?.selectedTemplate) {
				dispatch(setTemplate(intermediatePage.state.selectedTemplate));
			}
			dispatch(clearIntermediatePage());
		}
		if (selectedTemplate) {
			dispatch(pageTransitionEnd());
		}
	}, [selectedTemplate, intermediatePage, dispatch]);

	return (
		<>
			<motion.div
				className="w-full max-w-[1400px] mx-auto flex flex-col items-center justify-center gap-6"
				key="prContainer"
				variants={prContainerExit}
				exit="exit"
			>
				<motion.h2
					className="font-bold text-center text-black dynamic-xl-large"
					key="prText"
					variants={prTextSlideIn}
					initial="initial"
					animate="animate"
				>
					Now let's select a <span className="text-secondary">template</span>
				</motion.h2>
				{pageTransitionLoad ? (
					<div className="flex items-center justify-between w-full bg-white rounded-xl shadow-main">
						<Skeleton className="h-[53px] w-full rounded-xl bg-slate-300" />
					</div>
				) : (
					<div className="flex flex-col w-full gap-4 px-4">
						<motion.div
							className="flex items-center justify-between w-full px-5 py-4 bg-white rounded-xl shadow-main"
							key="prInput"
							variants={prInputSlideIn}
							initial="initial"
							animate="animate"
						>
							<div className="flex items-center gap-4">
								<p className="text-lg font-bold xl:text-xl text-primary">
									{selectedTemplate?.name}
								</p>
								<div className="flex items-center gap-2">
									{selectedTemplate?.tags?.slice(0, 2).map((item) => (
										<Badge
											key={item}
											className="px-2 min-w-[50px] flex items-center justify-center dynamic-badge text-white uppercase text-md bg-[#E861C9]"
										>
											{item}
										</Badge>
									))}
								</div>
							</div>
							<div
								className={cn(
									styles.box,
									'flex items-center gap-2 cursor-pointer choose-template'
								)}
							>
								<TextButton
									className="h-auto 2xl:h-auto p-0"
									onClick={() =>
										dispatch(
											openDialog({
												type: 'viewTemplates',
												selectedTemplate: selectedTemplate,
											})
										)
									}
								>
									<TemplateIcon className="w-5 h-5" />
									<p className="font-bold uppercase text-primary ">
										choose a different template
									</p>
								</TextButton>
							</div>
						</motion.div>
					</div>
				)}
				<motion.div
					key="prInputDelay"
					variants={prInputSlideInDelay}
					initial="initial"
					animate="animate"
					className="flex gap-4"
				>
					{hasTemplateBuilderAccess && (
						<SecondaryButton
							onClick={() => handleEditTemplate(selectedTemplate)}
							type="button"
							disabled={!selectedTemplate}
						>
							Edit this Template
						</SecondaryButton>
					)}
					<PrimaryButton
						onClick={openChangeTemplateDialog}
						className="template-selection"
						disabled={!selectedTemplate}
					>
						Select this Template
					</PrimaryButton>
				</motion.div>
			</motion.div>
			<motion.div
				className="col-span-12 mx-auto pt-6 px-4 w-10/12 max-w-[1400px]"
				key="prFooterDelay0"
				variants={prFooterSlideInDelay}
				initial="initial"
				animate="animate"
				exit="exit"
			>
				{selectedTemplate || pageTransitionLoad ? (
					<div className="grid justify-center w-full h-full grid-cols-11 gap-4 mx-auto">
						<div className="flex w-full col-span-6 overflow-hidden h-[350px] 2xl:h-[450px] ">
							<TemplateSectionDetails
								handleEditTemplate={handleEditTemplate}
								selectedTemplate={selectedTemplate}
								sectionNames={sectionNames || []}
								pageTransitionLoad={pageTransitionLoad}
							/>
						</div>
						<div className="flex col-span-5 overflow-hidden h-[350px] 2xl:h-[450px] w-full rounded-xl border border-[rgba(185,212,255,0.40)]">
							<ProposalStructure
								sectionNames={sectionNames}
								template={selectedTemplate}
								sections={selectedTemplate?.sections}
								className="w-full h-full p-0 m-0 border-0"
								maxCharacter={32}
								pageTransitionLoad={pageTransitionLoad}
							/>
						</div>
					</div>
				) : (
					<div className="flex justify-center w-full h-auto mt-8">
						<div
							className="flex flex-col items-center max-w-[800px] w-full h-full rounded-3xl border border-blackSecondary p-6 gap-2 cursor-pointer"
							onClick={() =>
								dispatch(
									openDialog({
										type: 'viewTemplates',
										selectedTemplate: selectedTemplate,
									})
								)
							}
						>
							<div className="flex items-center pb-6">
								<TemplateNotFound />
							</div>
							<h4 className="font-medium text-black dynamic-medium">
								We could not find a recommended outline in the solicitation.
							</h4>
							<a className="text-primary dynamic-medium">
								Please click here to select an outline template from your
								template library
							</a>
						</div>
					</div>
				)}
			</motion.div>
		</>
	);
};

export default TemplateView;
