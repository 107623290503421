import { Step } from 'intro.js-react';
export const DocumentSteps: Step[] = [
	{
		element: '.document-control-container',
		title: 'Documents',
		intro:
			'You can search for documents, sort and filter them, and add new folders and documents using this pannel.',
		position: 'center',
	},
	{
		element: '.create-folder-button',
		title: 'Add folders',
		intro: 'Create a new folder.',
		position: 'bottom',
	},
	{
		element: '.add-folder-button',
		title: 'Add a new document',
		intro:
			'Click here to add a new document (these will get added to the main drive, or a folder of your choice).',
		position: 'bottom',
	},
	{
		element: '.usaid-dec-button',
		title: 'Search the USAID DEC',
		intro:
			'This feature lets you import documents from the USAID Development Experiences Clearing House. It is a repository of thousands of proposals, reports, and articles available on the open source USAID DEC DEC website. You can easily use this feature to pull a document directly into your document library by searching and clicking the "Import" button.',
		position: 'bottom',
	},
];
