import { FC } from 'react';
import LottieLoader from '@/assets/lotties/logo_loading_v1.json';
import Lottie from 'lottie-react';
import { cn } from '@/lib/utils';

interface LoaderPanelProps {
	className?: string;
}

const LoaderPanel: FC<LoaderPanelProps> = ({ className }) => {
	return (
		<div
			className={cn(
				'absolute flex justify-center items-center top-0 z-10 bg-[rgba(255,255,255,0.75)] h-full w-full',
				className
			)}
		>
			<Lottie
				className="animation-lottie max-h-60 max-w-60"
				animationData={LottieLoader}
				loop={true}
				autoPlay={true}
			/>
		</div>
	);
};

export default LoaderPanel;
