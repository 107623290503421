import { useState } from 'react';
import styles from './ComplianceTab.module.css';
import CollapseBox from '../CollapseBox/CollapseBox';
import ComplianceMatrixWrapper from '@/containers/ComplianceMatrixWrapper/ComplianceMatrixWrapper';
import Matrix from '../ui/icons/new/Matrix';

const ComplianceTab = () => {
	const [expandedBox, setExpandedBox] = useState<string | null>('matrix');

	const handleSetExpanded = (title: string) => {
		if (expandedBox === title) {
			// setExpandedBox(null); // Collapse if the same box is clicked again
		} else {
			setExpandedBox(title);
		}
	};
	return (
		<div className={styles.container}>
			<CollapseBox
				title="Matrix"
				icon={<Matrix className="w-6" />}
				isExpanded={expandedBox === 'matrix'}
				setIsExpanded={() => handleSetExpanded('matrix')}
			>
				<ComplianceMatrixWrapper />
			</CollapseBox>
		</div>
	);
};

export default ComplianceTab;
