import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { updateDocumentName } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { RootState } from '@/redux/store/store';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { closeDialog } from '@/redux/slices/pageSlice';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { useQueryClient } from 'react-query';

const ChangeDocumentNameDialog = () => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'changeDocumentName'
	);
	const documentId = useSelector(
		(root: RootState) => root.page.dialog?.documentId
	);
	const currentDocumentName = useSelector(
		(root: RootState) => root.page.dialog?.documentName
	);

	const [documentName, setDocumentName] = useState(currentDocumentName || '');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleDocumentUpdate = async (documentName: string) => {
		try {
			if (!documentId) return;
			setIsLoading(true);
			const response = await updateDocumentName(documentName, documentId);
			handleResponse(response, 'Document name has been updated successfully.');
			queryClient.invalidateQueries('documents');
			dispatch(closeDialog());
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			setIsLoading(false);
			setDocumentName(currentDocumentName || '');
		}
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] p-7 bg-white rounded-xl">
				<DialogHeader className="font-bold text-secondary dynamic-medium">
					Change Document Name
				</DialogHeader>
				<div className="flex flex-col gap-2">
					<div className="rounded-xl shadow-main">
						<Input
							tabIndex={-1}
							placeholder={'What would you like to name this document?'}
							onChange={(e) => setDocumentName(e.target.value)}
							value={documentName}
							className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-lightBackground disabled:bg-[#F5F5F5]"
						/>
					</div>
					<p
						className={cn(
							'flex w-full dynamic-badge justify-end self-center font-medium leading-normal',
							documentName?.length > 250
								? 'text-[#FF82A0]'
								: 'text-black opacity-50'
						)}
					>
						{documentName?.length || 0}/250 Char
					</p>
				</div>
				<div className="flex items-center justify-end">
					<PrimaryButton
						disabled={
							isLoading ||
							!documentName ||
							(currentDocumentName || '') === documentName ||
							documentName?.length > 250
						}
						onClick={() => handleDocumentUpdate(documentName)}
					>
						{isLoading ? 'Updating...' : 'Update'}
					</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeDocumentNameDialog;
