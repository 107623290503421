import { SVGProps } from 'react';

const Unsolicited = (props: SVGProps<SVGSVGElement>) => {
	const color = props.color || '#5D9BFD';
	return (
		<svg
			{...props}
			width="17"
			height="20"
			viewBox="0 0 17 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_8921_16651)">
				<path
					d="M13.423 0.586914H3.02745C1.67904 0.586914 0.585938 1.68002 0.585938 3.02843V16.9799C0.585938 18.3283 1.67904 19.4215 3.02745 19.4215H13.423C14.7714 19.4215 15.8645 18.3283 15.8645 16.9799V3.02843C15.8645 1.68002 14.7714 0.586914 13.423 0.586914Z"
					stroke={color}
					strokeMiterlimit="10"
				/>
				<path
					d="M3.17383 4.26172H12.8038"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.17383 8.08984H8.27804"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.17383 11.9092H5.01134"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.17383 15.7373H4.17766"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.348 8.54998L12.4633 8.05657C12.1911 7.90344 11.8423 7.99702 11.6806 8.27775L7.92053 14.9983C7.85248 15.1259 7.80994 15.262 7.80143 15.4066L7.71636 16.5211C7.68234 17.006 8.22679 17.3122 8.61811 17.023L9.52836 16.3679C9.64746 16.2829 9.74103 16.1808 9.80909 16.0532L13.5692 9.33262C13.7223 9.0604 13.6287 8.71161 13.348 8.54998Z"
					stroke={color}
					strokeMiterlimit="10"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8921_16651">
					<rect width="16.4441" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Unsolicited;
