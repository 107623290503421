import { SVGProps } from 'react';

const Bar = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="67"
			height="67"
			viewBox="0 0 67 67"
			fill="none"
		>
			<rect
				x="3.39844"
				y="38.3203"
				width="56.1413"
				height="12.3841"
				fill="#E861C9"
			/>
			<rect
				x="3.39844"
				y="19.3315"
				width="34.6755"
				height="12.3841"
				fill="#A8CAFF"
			/>
			<rect
				x="1.74609"
				y="2.81934"
				width="1.65121"
				height="61.0949"
				fill="#BDBDBD"
			/>
			<rect
				x="64.4922"
				y="62.2632"
				width="1.65121"
				height="61.0949"
				transform="rotate(90 64.4922 62.2632)"
				fill="#BDBDBD"
			/>
		</svg>
	);
};

export default Bar;
