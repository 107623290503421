const DocumentsUpload = ({ ...props }) => {
	return (
		<svg
			{...props}
			width="74"
			height="80"
			viewBox="0 0 74 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_9516_18183)">
				<path
					d="M41.5994 2.08693L6.31813 2.12923C3.59256 2.12923 1.38069 4.34715 1.38673 7.07272L1.44716 57.4564C1.44716 60.182 3.66509 62.3938 6.39065 62.3878L41.6719 62.3455C44.3975 62.3455 46.6094 60.1276 46.6033 57.402L46.5429 7.01833C46.5429 4.29276 44.325 2.08088 41.5994 2.08693Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="2.36901"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.60254 15.8054L28.6726 15.7812"
					stroke="#AFC1DB"
					strokeWidth="2.29044"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.6084 24.0908L35.8217 23.9941"
					stroke="#AFC1DB"
					strokeWidth="2.30253"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.6084 32.2919L33.4104 32.165"
					stroke="#AFC1DB"
					strokeWidth="2.30253"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M45.865 6.18458L10.5838 6.22689C7.85819 6.22689 5.64631 8.44481 5.65236 11.1704L5.71279 61.554C5.71279 64.2796 7.93071 66.4915 10.6563 66.4854L45.9375 66.4431C48.6631 66.4431 50.875 64.2252 50.8689 61.4997L50.8085 11.116C50.8085 8.39042 48.5906 6.17854 45.865 6.18458Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="2.36901"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.8682 19.8972L32.9382 19.873"
					stroke="#AFC1DB"
					strokeWidth="2.29044"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.874 28.1885L40.0874 28.0918"
					stroke="#AFC1DB"
					strokeWidth="2.30253"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.874 36.3828L37.6761 36.2559"
					stroke="#AFC1DB"
					strokeWidth="2.30253"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M54.0968 77.9065C64.3201 77.9065 72.6077 69.6189 72.6077 59.3956C72.6077 49.1724 64.3201 40.8848 54.0968 40.8848C43.8735 40.8848 35.5859 49.1724 35.5859 59.3956C35.5859 69.6189 43.8735 77.9065 54.0968 77.9065Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="2.36901"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M54.689 49.2432L45.8838 57.3353"
					stroke="#AFC1DB"
					strokeWidth="2.29044"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M63.4947 57.3353L54.6895 49.2432V68.6001"
					stroke="#AFC1DB"
					strokeWidth="2.29044"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_9516_18183">
					<rect
						width="73.5963"
						height="78.1953"
						fill="white"
						transform="translate(0.202148 0.902344)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default DocumentsUpload;
