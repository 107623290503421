const DocumentUpload = ({ ...props }) => {
	return (
		<svg
			{...props}
			width="115"
			height="122"
			viewBox="0 0 115 122"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.0668 2.5498L80.9114 2.63614C86.1948 2.63614 90.4769 6.93542 90.4769 12.2189L90.356 109.885C90.356 115.169 86.0567 119.451 80.7733 119.451L12.3819 119.364C7.09842 119.364 2.81641 115.065 2.81641 109.781L2.93727 19.1771L21.0668 2.5498Z"
				fill="#EAF1FC"
				stroke="#AFC1DB"
				strokeWidth="3.92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M62.9307 36.6758H15.7422"
				stroke="#AFC1DB"
				strokeWidth="3.92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M62.9307 51.1367H15.7422"
				stroke="#AFC1DB"
				strokeWidth="3.92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M62.9307 65.6055H15.7422"
				stroke="#AFC1DB"
				strokeWidth="3.92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M46.6487 80.0664H15.7422"
				stroke="#AFC1DB"
				strokeWidth="3.92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.067 2.5498V12.1671C21.067 16.3282 17.7001 19.6951 13.5389 19.6951H2.9375L21.067 2.5498Z"
				fill="white"
				stroke="#AFC1DB"
				strokeWidth="3.92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M80.5353 108.258C98.3531 108.258 112.797 93.814 112.797 75.9963C112.797 58.1785 98.3531 43.7344 80.5353 43.7344C62.7176 43.7344 48.2734 58.1785 48.2734 75.9963C48.2734 93.814 62.7176 108.258 80.5353 108.258Z"
				fill="white"
				stroke="#AFC1DB"
				strokeWidth="3.92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M68.4168 87.4757C67.6254 87.4757 66.9479 87.1939 66.3844 86.6304C65.8208 86.0668 65.5391 85.3894 65.5391 84.598V67.3318C65.5391 66.5404 65.8208 65.863 66.3844 65.2994C66.9479 64.7359 67.6254 64.4541 68.4168 64.4541H75.611V67.3318H68.4168V84.598H91.4383V67.3318H84.2441V64.4541H91.4383C92.2297 64.4541 92.9072 64.7359 93.4707 65.2994C94.0343 65.863 94.316 66.5404 94.316 67.3318V84.598C94.316 85.3894 94.0343 86.0668 93.4707 86.6304C92.9072 87.1939 92.2297 87.4757 91.4383 87.4757H68.4168ZM79.9276 80.857L72.7333 73.6627L74.7477 71.6483L78.4887 75.3894V64.4541H81.3664V75.3894L85.1074 71.6483L87.1218 73.6627L79.9276 80.857Z"
				fill="#AFC1DB"
			/>
		</svg>
	);
};

export default DocumentUpload;
