import { FC, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/vimeo';
import styles from './VideoLightBox.module.css';
import { cn } from '@/lib/utils';

interface VideoLightBoxProps {
	isOpen: boolean;
	url: string;
	setIsOpen: (val: boolean) => void;
}

const VideoLightBox: FC<VideoLightBoxProps> = ({ isOpen, url, setIsOpen }) => {
	const playerRef = useRef<any>(null);

	useEffect(() => {
		if (!isOpen && playerRef.current) {
			playerRef.current.seekTo(0);
			playerRef.current.getInternalPlayer()?.pause();
		}
	}, [isOpen]);
	return (
		<div
			className={cn(styles.light_box, isOpen ? styles.open : styles.close)}
			onClick={() => setIsOpen(false)}
		>
			<ReactPlayer
				ref={playerRef}
				playing={isOpen}
				width={800}
				height={500}
				url={url}
				controls={true}
				loop
			/>
		</div>
	);
};

export default VideoLightBox;
