import { useCallback, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '@/lib/hooks/hooks';
import customToast from '@/components/CustomToast/CustomToast';
import { useNavigate } from 'react-router';
import { templateQuestions } from '@/constants/templateBuilderData';
import { motion, useAnimation } from 'framer-motion';
import { RootState } from '@/redux/store/store';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import {
	createUpdateTags,
	extractNames,
	updateTemplateBuilderLevels,
} from '@/lib/functions/TemplateBuilder/templateUtils';
import { TemplateLibrary } from '@/lib/types/TemplateBuilder/templateTypes';
import { Form } from '@/components/ui/form';
import TemplateDragAndDrop from '@/components/TemplateBuilder/TemplateDnd/TemplateDnd';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';
import { useTemplateMutations } from '@/lib/hooks/templateBuilder/useTemplateMutations';
import { useTemplateQueries } from '@/lib/hooks/templateBuilder/useTemplateQueries';

const TemplateDesigner = () => {
	const orgIdentifier = useOrganizationId();

	const templateBuilderData = useAppSelector(
		(root: RootState) => root.templates.templateBuilder
	);
	const templateSetupData = useAppSelector(
		(root: RootState) => root.templates.templateSetup
	);
	const templateTagOptions = useAppSelector(
		(root: RootState) => root.templates.templateTagOptions
	);
	const isLoading = useAppSelector(
		(root: RootState) => root.templates.isLoading
	);

	const templateMode = useAppSelector(
		(root: RootState) => root.templates.templateMode
	);

	const intermediatePage = useAppSelector(
		(root: RootState) => root.page.intermediatePage
	);
	const navigate = useNavigate();

	useTour(Page.TemplateBuilderEdit);

	const form = useForm<TemplateLibrary>({
		defaultValues: {
			_id: templateBuilderData?._id,
			name:
				templateMode === 'clone'
					? templateBuilderData?.name + ' copy'
					: templateBuilderData?.name || '',
			organizationIdentifier: templateBuilderData?.organizationIdentifier,
			description: templateBuilderData?.description || '',
			isLive: templateBuilderData?.isLive || false,
			tags: templateBuilderData?.tags || [],
			sections: templateBuilderData?.sections,
			questions: templateBuilderData?.questions,
		},
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = form;

	const {
		createTemplateMutation,
		updateTemplateMutation,
		createUpdateTagMutation,
	} = useTemplateMutations({ reset, intermediatePage });

	const { sectionTagQuery } = useTemplateQueries();

	const onSubmit = async () => {
		if (templateBuilderData) {
			/** template builder tags */
			const tagsFilter = templateBuilderData.tags.every(
				(element) => typeof element === 'string'
			)
				? templateBuilderData.tags
				: extractNames(templateBuilderData.tags);

			if (
				orgIdentifier === 'undefined' ||
				orgIdentifier === undefined ||
				orgIdentifier === 'null' ||
				orgIdentifier === null
			) {
				customToast.error({
					title: 'Organization Identifier is missing. Please contact support.',
				});
				return;
			}
			const updatedTreeData = updateTemplateBuilderLevels(
				templateBuilderData.sections,
				true
			);
			const updatedTemplateBuilder = {
				...templateBuilderData,
				sections: updatedTreeData,
				isLive: templateBuilderData.isLive || false,
				name: templateBuilderData.name.trim(),
				organizationIdentifier: orgIdentifier,
				description: templateBuilderData.description,
				tags: tagsFilter,
				questions: templateQuestions,
			};

			const updatedTags = createUpdateTags(
				updatedTemplateBuilder.tags,
				orgIdentifier,
				templateTagOptions
			);

			if (updatedTags && updatedTags.tags && updatedTags.tags.length > 0) {
				createUpdateTagMutation.mutate(updatedTags);
			}

			if (templateBuilderData._id) {
				updateTemplateMutation.mutate(updatedTemplateBuilder); //update template
			} else {
				updatedTemplateBuilder.isLive = true;
				createTemplateMutation.mutate(updatedTemplateBuilder); //create new template
			}
		}
	};

	useEffect(() => {
		form.reset();
	}, [form]);

	useEffect(() => {
		if (!templateSetupData) navigate('/templates');
	}, [templateSetupData, navigate]);

	// Animation Controls

	const templateDNDControls = useAnimation();

	const startOpeningAnimation = useCallback(() => {
		templateDNDControls.set({ opacity: 0, y: '100%' });
		templateDNDControls.start({
			opacity: 1,
			y: 0,
			transition: {
				delay: 0,
				duration: 0.7,
				type: 'cubic-bezier',
				ease: [0.76, 0, 0.24, 1],
			},
		});
	}, [templateDNDControls]);

	useEffect(() => {
		startOpeningAnimation();
	}, [startOpeningAnimation]);

	return (
		<div
			className={cn(
				`bg-[#f5f5f5] rounded-tl-[30px] p-5 h-[calc(100vh-40px)] overflow-y-scroll hidden-scroll`
			)}
		>
			{(isLoading ||
				createTemplateMutation.isLoading ||
				updateTemplateMutation.isLoading ||
				createUpdateTagMutation.isLoading ||
				sectionTagQuery.isLoading) && <LoaderTransparent />}
			<Form {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<motion.div animate={templateDNDControls}>
						<TemplateDragAndDrop
							register={register}
							setValue={setValue}
							form={form}
							errors={errors}
							onSubmit={onSubmit}
						/>
					</motion.div>
				</form>
			</Form>
		</div>
	);
};

export default TemplateDesigner;
