import { FC, useState } from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { FieldValues, UseFormRegister } from 'react-hook-form';

interface BasicInformationProps {
	register: UseFormRegister<FieldValues>;
	isFunderURLValid?: boolean;
}

const BasicInformation: FC<BasicInformationProps> = ({
	register,
	isFunderURLValid,
}) => {
	const [funding, setFunding] = useState('');

	const handleChange = (event) => {
		const inputValue = event.target.value.replace(/[^\d]/g, ''); // Only numbers
		setFunding(`$${Number(inputValue).toLocaleString('en-US')}`);
	};

	return (
		<div className={cn('my-4 2xl:my-6')}>
			<label htmlFor="name" className="font-bold text-black">
				Program Title{' '}
				<span className="font-semibold text-secondary">(required)</span>
			</label>
			<Input
				type="text"
				id="name"
				{...register('name', { required: true })}
				className="p-4 rounded-xl border border-[#D3DDE2] bg-white h-12 my-2"
				placeholder="Enter Program Title"
			/>
			<label className="block mt-4 font-bold text-black">Funding Amount</label>
			<Input
				type="text"
				id="funding"
				{...register('metaData.funding', {
					value: `${Number(funding).toLocaleString('en-US')}`,
				})}
				onChange={handleChange}
				value={funding}
				className="p-4 rounded-xl border border-[#D3DDE2] bg-white h-12 my-2"
				placeholder="Enter the amount of funding sought"
			/>
			<label className="block mt-4 font-bold text-black">
				What donor or funding institution is this project for?{' '}
				<span className="font-semibold text-secondary">(required)</span>
			</label>
			<Input
				type="text"
				{...register('metaData.agency', { required: true })}
				className="p-4 rounded-xl border border-[#D3DDE2] bg-white h-12 my-2"
				placeholder="Enter the name of the funder, institution or agency funding this project"
			/>
			<label className="block mt-4 font-bold text-black">
				Please provide a website url for the funder
			</label>
			<Input
				type="text"
				{...register('metaData.funderUrl', {
					pattern: {
						value:
							/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)([a-zA-Z]{2,})(\/[^\s]*)?$/,
						message: 'Please provide a valid URL',
					},
				})}
				className="p-4 rounded-xl border border-[#D3DDE2] bg-white h-12 my-2"
				placeholder="Please provide the web url where information related to this funder can be found"
			/>
			{!isFunderURLValid && (
				<p className="pt-2 dynamic-text font-medium text-lightRed">
					Provide a valid URL, it must include http or https
				</p>
			)}
		</div>
	);
};

export default BasicInformation;
