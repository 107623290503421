import { Subject } from 'rxjs';

const expandSubject = new Subject<void>();
const enhanceSubject = new Subject<void>();
const conciseSubject = new Subject<void>();
const visualSubject = new Subject<void>();
const historySubject = new Subject<void>();
const updateSectionSubject = new Subject<void>();

export const expandObservable = expandSubject.asObservable();
export const enhanceObservable = enhanceSubject.asObservable();
export const conciseObservable = conciseSubject.asObservable();
export const visualObservable = visualSubject.asObservable();
export const historyObservable = historySubject.asObservable();
export const updateSectionObservable = updateSectionSubject.asObservable();

export const triggerExpand = () => expandSubject.next();
export const triggerEnhance = () => enhanceSubject.next();
export const triggerConcise = () => conciseSubject.next();
export const triggerVisual = () => visualSubject.next();
export const triggerHistory = () => historySubject.next();
export const triggerUpdateSection = () => updateSectionSubject.next();
