import { FC, forwardRef } from 'react';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { PhoneInput } from '@/components/ui/phone-input';
import { Label } from '@/components/ui/label';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';
import { cn } from '@/lib/utils';
import useUser from '@/lib/hooks/useUser';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';

const errorMessage = 'Please enter phone number';

const baseFormSchema = z.object({
	phone: z
		.string({ required_error: errorMessage })
		.refine(isValidPhoneNumber, { message: errorMessage }),
});

interface PhoneFormProps {
	title?: string;
	value?: string;
	placeholder?: string;
	className?: string;
	disabled?: boolean;
	submitLabel?: string;
	cancelLabel?: string;
	onSubmit: (phone: string) => void;
	onCancel?: () => void;
}

export const PhoneForm: FC<PhoneFormProps> = forwardRef<
	HTMLFormElement,
	PhoneFormProps
>(
	(
		{
			title = 'Phone Number',
			placeholder = 'Enter a phone number',
			value = '',
			className = '',
			disabled = false,
			submitLabel = 'Submit',
			onSubmit,
			cancelLabel = 'Cancel',
			onCancel,
		}: PhoneFormProps,
		ref
	) => {
		const user = useUser();

		const formSchema = user?.isTelephoneNumberVerified
			? baseFormSchema.extend({
					phone: baseFormSchema.shape.phone.refine(
						(phone) => phone !== user?.telephoneNumber,
						{ message: 'Please enter a different phone number' }
					),
				})
			: baseFormSchema;

		const form = useForm<z.infer<typeof formSchema>>({
			resolver: zodResolver(formSchema),
			defaultValues: {
				phone: value || '',
			},
		});
		const formSubmit = (data: z.infer<typeof formSchema>) => {
			onSubmit(data.phone);
		};

		return (
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(formSubmit)}
					className={cn('flex flex-col w-full space-y-4', className)}
					ref={ref}
				>
					<FormField
						control={form.control}
						name="phone"
						render={({ field }) => (
							<FormItem className="flex flex-col w-full space-y-4">
								<Label className="text-left">{title}</Label>
								<FormControl className="w-full">
									<PhoneInput
										disabled={disabled}
										placeholder={placeholder}
										defaultCountry="US"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<div className="flex justify-end">
						{onCancel && (
							<SecondaryButton
								className="flex w-auto"
								type="button"
								onClick={onCancel}
							>
								{cancelLabel}
							</SecondaryButton>
						)}
						<PrimaryButton className="flex w-auto" type="submit">
							{submitLabel}
						</PrimaryButton>
					</div>
				</form>
			</Form>
		);
	}
);
