import { EventSourcePolyfill } from 'event-source-polyfill';

type UseSuggestionHandlerParams = {
	token: string;
	documentId?: number;
	questionId?: number | null;
	onInit: () => void;
	onStart: () => void;
	onMessage: (message: string) => void;
	onEnd: () => void;
	onError: () => void;
	setEnableQuestion1?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useSuggestionHandler = ({
	token,
	documentId,
	questionId,
	onInit,
	onStart,
	onMessage,
	onEnd,
	onError,
	setEnableQuestion1,
}: UseSuggestionHandlerParams): [() => void] => {
	const handleClick = () => {
		onInit();
		execute();
	};

	const execute = () => {
		try {
			if (!documentId || !questionId) {
				return;
			}

			const initializeAnswerEventSource = (
				token: string,
				documentId: number,
				questionId: number
			) => {
				return new EventSourcePolyfill(
					`${
						import.meta.env.VITE_SERVER_URL
					}/api/document/${documentId}/question/${questionId}/suggest-response`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
			};

			const eventSource = initializeAnswerEventSource(
				token,
				documentId,
				questionId
			);

			const processEventSourceMessage = async (
				eventData: any,
				eventSource: any
			) => {
				if (eventData.data.eventType === 'llm_execution_continue') {
					onMessage(eventData.data.data.token);
				} else if (eventData.data.eventType === 'llm_execution_end') {
					eventSource.close();
					onEnd();
					setEnableQuestion1 && setEnableQuestion1(true);
				}
			};

			eventSource.onerror = () => {
				eventSource.close();
				onError();
			};

			let firstMessageReceived = false;
			eventSource.onmessage = (ev: any) => {
				if (!firstMessageReceived) {
					firstMessageReceived = true;
					onStart();
				}
				const eventData = {
					data: typeof ev.data === 'string' ? JSON.parse(ev.data) : ev.data,
					lastEventId: ev.lastEventId,
				};
				processEventSourceMessage(eventData, eventSource);
			};
		} catch (error) {
			console.log(error);
			onError();
		}
	};

	return [handleClick];
};

export default useSuggestionHandler;
