import { FC, useEffect, useRef, useState } from 'react';
import styles from './TemplateCard.module.css';
import { openDialog } from '@/redux/slices/pageSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useNavigate } from 'react-router';
import { omit } from 'lodash';
import { cn } from '@/lib/utils';
import { TemplateCardProps } from '@/lib/types/TemplateBuilder/templateTypes';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import {
	buildTemplate,
	setTemplateMode,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { ChevronRight, Files } from 'lucide-react';
import { cropText } from '@/lib/functions/funcUtils';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
const TemplateCard: FC<TemplateCardProps> = ({ templateCardData }) => {
	const [showFullText, setShowFullText] = useState(false);
	const cardRef = useRef<HTMLDivElement | null>(null);
	const textRef = useRef<HTMLParagraphElement | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
				setShowFullText(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	const dispatch = useAppDispatch();

	return (
		<div
			className={cn(
				styles.card,
				'flex flex-col gap-5 shadow-main justify-between rounded-2xl p-4 2xl:p-6'
			)}
			ref={cardRef}
		>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row items-center justify-between">
					<h4 className="flex items-center h-10 font-bold text-black dynamic-medium">
						{templateCardData?.name}
					</h4>
					<TextButton
						className="h-fit 2xl:h-fit p-0"
						onClick={() => {
							dispatch(
								openDialog({
									type: 'showTemplate',
									selectedTemplateId: templateCardData._id,
									privateTemplate: false,
								})
							);
						}}
					>
						Show Template
					</TextButton>
				</div>
				<div className="flex items-center gap-1 pb-4 border-b border-b-[#E9E9E9]">
					{templateCardData.tags.slice(0, 3).map((item) => (
						<TooltipProvider key={item}>
							<Tooltip>
								<TooltipTrigger>
									<Badge
										key={item}
										className="items-center justify-center block px-3 bg-white border max-w-40 text-secondary border-secondary"
									>
										<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-small text-nowrap text-ellipsis">
											{cropText(item, 16)}
										</span>
									</Badge>
								</TooltipTrigger>
								<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
									{item}
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					))}
					{templateCardData.tags.length > 3 && (
						<Popover>
							<PopoverTrigger onClick={(e) => e.stopPropagation()}>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger className="flex items-center justify-center">
											<ChevronRight
												className={cn('text-[#5D6F79]')}
												size={18}
											/>
										</TooltipTrigger>
										<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
											View More Tags
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</PopoverTrigger>
							<PopoverContent
								className="gap-2 py-4 bg-white rounded-md "
								align="center"
							>
								<h2 className="text-[#5D6F79] font-bold mb-3">Template Tags</h2>
								<div className="flex flex-wrap gap-1">
									{templateCardData.tags.map((item) => (
										<Badge
											key={item}
											className="items-center justify-center block px-3 bg-white border text-secondary border-secondary"
										>
											{item.length > 35 ? (
												<TooltipProvider>
													<Tooltip>
														<TooltipTrigger
															tabIndex={-1}
															className="flex text-left"
														>
															<span className="font-medium uppercase text-secondary dynamic-badge">
																{cropText(item, 35)}
															</span>
														</TooltipTrigger>
														<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
															{item}
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>
											) : (
												<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-badge">
													{item}
												</span>
											)}
										</Badge>
									))}
								</div>
							</PopoverContent>
						</Popover>
					)}
				</div>
				<div>
					<p
						ref={textRef}
						className={`text-sm xl:text-base text-black font-normal ${
							!showFullText ? styles.limitedText : ''
						}`}
					>
						{templateCardData?.description}{' '}
					</p>

					{templateCardData?.description?.length <
					((textRef?.current?.offsetWidth &&
						textRef?.current?.offsetWidth - 150) ||
						300) ? (
						<></>
					) : (
						<>
							{!showFullText ? (
								<TextButton
									className="h-fit 2xl:h-fit w-fit p-0 mt-2 text-sm font-bold xl:text-sm 2xl:text-base"
									onClick={() => setShowFullText(true)}
								>
									Read More
								</TextButton>
							) : (
								<TextButton
									className="h-fit 2xl:h-fit w-fit p-0 mt-2 text-sm xl:text-sm 2xl:text-base font-normal"
									onClick={() => setShowFullText(false)}
								>
									Less
								</TextButton>
							)}
						</>
					)}
				</div>
			</div>

			<div className="flex flex-row items-center justify-end">
				<div className="flex justify-items-end">
					<Button
						className="px-2 m-0 bg-transparent hover:bg-transparent text-primary hover:text-secondary copy-button"
						title="Duplicate"
						onClick={() => {
							const updatedData = omit(templateCardData, '_id');
							dispatch(setTemplateMode('clone'));
							dispatch(buildTemplate(updatedData));
							navigate(`/template/new`);
						}}
					>
						<Files />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default TemplateCard;
