import { FC } from 'react';
import DashboardActionCard from '../DashboardActionCard/DashboardActionCard';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import SolicitationDashboardIcon from '@/components/ui/icons/new/Dashboard/SolicitationDashboardIcon';
import UnsolicitedDashboardIcon from '@/components/ui/icons/new/Dashboard/UnsolicitedDashboardIcon';
import QuestionnaireDashboardIcon from '@/components/ui/icons/new/Dashboard/QuestionnaireDashboardIcon';
import { resetForm, setJourneyType } from '@/redux/slices/unsolicitedSlice';
interface DashboardActionCardsProps {}

const DashboardActionCards: FC<DashboardActionCardsProps> = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	return (
		<div className="grid grid-cols-12 gap-4 mt-4">
			<DashboardActionCard
				card={{
					icon: <SolicitationDashboardIcon />,
					onClick: () => {
						navigate('/solicitations');
						dispatch(openDialog({ type: 'uploadSolicitation' }));
					},
					title: 'Respond to a Solicitation',
					description: (
						<p className="text-center text-black">
							Respond to an RFx document (RFP, RFA, NOFO, Appeals etc) issued by
							a funding institution or a federal contract or grant application.
							You can also create concept notes for proposals
						</p>
					),
				}}
				extraClass={'col-span-12 solicitation-button'}
			/>

			<DashboardActionCard
				card={{
					icon: <UnsolicitedDashboardIcon />,
					title: 'Create an Unsolicited Proposal',
					onClick: () => {
						navigate('/solicitations');
						dispatch(openDialog({ type: 'multiStepUnsolicited' }));
						dispatch(resetForm());
					},
					description: (
						<p className="text-center text-black">
							Use a template to create an unsolicited proposal or a concept note
							that you can use later in a solicited proposal
						</p>
					),
				}}
				extraClass={'col-span-6 unsolicitation-button'}
			/>
			<DashboardActionCard
				card={{
					icon: <QuestionnaireDashboardIcon />,
					title: 'Respond to a Questionnaire',
					onClick: () => {
						navigate('/solicitations');
						dispatch(openDialog({ type: 'multiStepUnsolicited' }));
						dispatch(resetForm());
						dispatch(setJourneyType('questionnaire'));
					},
					description: (
						<p className="text-center text-black">
							Respond to questions from your donors or to questionnaire grant or
							solicitation
						</p>
					),
				}}
				extraClass={'col-span-6 questionnaire-button'}
			/>
		</div>
	);
};

export default DashboardActionCards;
