import { ColumnDef } from '@tanstack/react-table';
import { UsersTableProps } from '@/lib/types/constants';
import { Switch } from '@/components/ui/switch';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';
import Delete from '@/components/ui/icons/Delete';
import { User } from '@/lib/types/apiTypes';
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import DotMenu from '@/components/ui/icons/DotMenu';
import { Button } from '@/components/ui/button';
import { Mail } from 'lucide-react';
import FallbackAvatar from '@/components/FallbackAvatar/FallbackAvatar';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';

export function getColumns(user: User | null): ColumnDef<UsersTableProps>[] {
	const columns: ColumnDef<UsersTableProps>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => {
				return (
					<h5 key={column.id} className="p-0 text-black bg-transparent">
						Name
					</h5>
				);
			},
			cell: ({ row }) => {
				return (
					<div className="flex items-center gap-4">
						<Avatar>
							<AvatarImage
								height={50}
								width={50}
								src={row.original.profilePicture}
							/>
							<FallbackAvatar
								user={row.original}
								className="dynamic-medium w-10 h-10"
							></FallbackAvatar>
						</Avatar>
						<p className="text-base text-black">{row?.original?.name || '-'}</p>
					</div>
				);
			},
		},
		{
			accessorKey: 'email',
			header: ({ column }) => {
				return (
					<h5 key={column.id} className="p-0 text-black bg-transparent">
						Email
					</h5>
				);
			},
			cell: ({ row }) => {
				return (
					<p className="text-base text-black">{row?.original?.email || '-'}</p>
				);
			},
		},
		{
			accessorKey: 'designation',
			header: ({ column }) => {
				return (
					<h5 key={column.id} className="p-0 text-black bg-transparent">
						Designation
					</h5>
				);
			},
			cell: ({ row }) => {
				return (
					<p className="text-base text-black">
						{row?.original?.designation || '-'}
					</p>
				);
			},
		},
		{
			accessorKey: 'templateBuilderAccess',
			header: ({ column }) => {
				return (
					<h5 key={column.id} className="p-0 text-black bg-transparent">
						Template Builder Access
					</h5>
				);
			},
			cell: ({ table, row }) => {
				const handleTemplateBuilderAccess = (templateBuilderAccess) => {
					const meta = table?.options?.meta;
					meta?.updateUserTemplateBuilderAccess &&
						meta?.updateUserTemplateBuilderAccess(
							templateBuilderAccess,
							row.original
						);
				};
				return (
					<div className="flex ">
						<Switch
							className={`cursor-pointer ${row.original.id === user?.id ? 'hidden' : ''}`}
							checked={row.original.templateBuilderAccess}
							onCheckedChange={(checked) =>
								handleTemplateBuilderAccess(checked)
							}
						/>
					</div>
				);
			},
		},
		{
			accessorKey: 'status',
			header: ({ column }) => {
				return (
					<h5 key={column.id} className="p-0 text-black bg-transparent">
						Active/Inactive
					</h5>
				);
			},
			cell: ({ table, row }) => {
				const handleUserStatus = (status) => {
					const meta = table?.options?.meta;
					meta?.updateUserStatus &&
						meta?.updateUserStatus(
							status ? 'active' : 'inactive',
							row.original
						);
				};
				return (
					<div className="flex ">
						<Switch
							className={`cursor-pointer ${row.original.id === user?.id ? 'hidden' : ''}`}
							checked={row.original.status === 'active'}
							onCheckedChange={(checked) => handleUserStatus(checked)}
						/>
					</div>
				);
			},
		},
		{
			accessorKey: 'isTwoFactorEnabled',
			header: ({ column }) => {
				return (
					<h5 key={column.id} className="p-0 text-black bg-transparent">
						2FA
					</h5>
				);
			},
			cell: ({ table, row }) => {
				const handleTwoFactorAccess = (isTwoFactorEnabled) => {
					const meta = table?.options?.meta;
					meta?.updateTwoFactorAccess &&
						meta?.updateTwoFactorAccess(isTwoFactorEnabled, row.original);
				};
				const handleTwoFactorEmail = (userEmail: string) => {
					const meta = table?.options?.meta;
					meta?.sendTwoFactorEmail && meta?.sendTwoFactorEmail(userEmail);
				};
				return (
					<div
						className={`flex gap-1 text-blackSecondary ${row.original.id === user?.id ? 'hidden' : ''}`}
					>
						{row.original.isTwoFactorEnabled ? (
							<>
								<span className="text-lightGreen">On</span> —{' '}
								<TextButton
									className="h-auto 2xl:h-auto p-0 font-normal normal-case"
									onClick={() => handleTwoFactorAccess(false)}
								>
									Turn off
								</TextButton>
							</>
						) : (
							<>
								<span className="text-secondary">Off</span> —{' '}
								<TextButton
									className="h-auto 2xl:h-auto p-0 font-normal normal-case"
									onClick={() => handleTwoFactorEmail(row.original?.email)}
								>
									Send email
								</TextButton>
							</>
						)}
					</div>
				);
			},
		},
		{
			accessorKey: 'roles',
			header: ({ column }) => {
				return (
					<h5 key={column.id} className="p-0 text-black bg-transparent">
						Role
					</h5>
				);
			},
			cell: ({ table, row }) => {
				const handleUserRole = (roleId) => {
					const meta = table?.options?.meta;
					meta?.updateUserRole &&
						meta?.updateUserRole(parseInt(roleId), row.original);
				};
				return (
					<Select
						disabled={row.original.id === user?.id}
						value={row.original.roleId.toString()}
						onValueChange={(value) => handleUserRole(value)}
					>
						<SelectTrigger className="min-w-[150px] h-10 rounded-xl border border-[#D3DDE2] bg-white text-black p-2 disabled:bg-[#F5F5F5]">
							<SelectValue placeholder="Select Role" />
						</SelectTrigger>
						<SelectContent className="py-2 bg-white rounded-2xl">
							<SelectItem
								className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
								value="2"
							>
								Admin
							</SelectItem>
							<SelectItem
								className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
								value="3"
							>
								Member
							</SelectItem>
							<SelectItem
								className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
								value="4"
							>
								Guest
							</SelectItem>
						</SelectContent>
					</Select>
				);
			},
		},
		{
			accessorKey: 'id',
			header: () => {
				return <></>;
			},
			cell: ({ table, row }) => {
				const handleDeleteUser = (userId) => {
					const meta = table?.options?.meta;
					meta?.deleteUser && meta?.deleteUser(userId);
				};
				const handleSendRecoveryEmail = (userEmail: string) => {
					const meta = table?.options?.meta;
					meta?.handleSendRecoveryEmail &&
						meta?.handleSendRecoveryEmail(userEmail);
				};
				return (
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button className="p-0 pb-1 ml-4 bg-white border-0 my-auto bg-transparent">
								<DotMenu
									className={cn(
										'h-4 w-4',
										user?.id === row.original?.id ? 'hidden' : ''
									)}
									fill="var(--black)"
								/>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent
							className="bg-white rounded-2xl py-2"
							sideOffset={-5}
							alignOffset={-5}
							align="end"
						>
							{!user?.organization.isSsoEnabled && (
								<DropdownMenuCheckboxItem
									onClick={() => handleSendRecoveryEmail(row.original?.email)}
									className="text-black px-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-lightBackground capitalize gap-2"
								>
									<Mail size={21} className="px-0.5" />
									Send Password Reset Email
								</DropdownMenuCheckboxItem>
							)}
							<DropdownMenuCheckboxItem
								onClick={() => handleDeleteUser(row.original?.id)}
								className={cn(
									'text-black pl-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-lightBackground capitalize gap-2'
								)}
							>
								<Delete />
								Delete
							</DropdownMenuCheckboxItem>
						</DropdownMenuContent>
					</DropdownMenu>
				);
			},
		},
	];
	return columns;
}
