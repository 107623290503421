import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { Skeleton } from '@/components/ui/skeleton';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { getToken } from '@/lib/functions/funcUtils';
import { setQuestionStreaming } from '@/redux/slices/questionSlice';

interface ProposalApproachCardProps {
	pageTransitionLoad: boolean;
}

const ProposalApproachCard: FC<ProposalApproachCardProps> = ({
	pageTransitionLoad = true,
}) => {
	const [showStream, setStreamData] = useState<string | null>('');
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const dataRef = useRef<string | null>('');
	const currentQuestion = useSelector(
		(root: RootState) => root.question.currentQuestion
	);
	const dispatch = useDispatch();

	const initializeEventSource = useCallback(
		(
			token: string,
			proposalId: number,
			questionId: number,
			type: 'question' | 'text'
		) => {
			return new EventSourcePolyfill(
				`${
					import.meta.env.VITE_SERVER_URL
				}/api/proposal/${proposalId}/question/${questionId}/v2/resolve?type=${type}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
		},
		[]
	);

	useEffect(() => {
		const token = getToken();
		let eventSource; // Declare this outside the if block so we can reference it in the cleanup function

		if (currentQuestion && proposal?.id) {
			dataRef.current = '';
			setStreamData('');
			eventSource = initializeEventSource(
				token,
				proposal.id,
				currentQuestion.id,
				'text'
			);

			eventSource.onerror = () => {
				eventSource.close();
				dispatch(setQuestionStreaming(false));
			};

			eventSource.onmessage = (ev: any) => {
				const eventData = {
					data: typeof ev.data === 'string' ? JSON.parse(ev.data) : ev.data,
					lastEventId: ev.lastEventId,
				};
				dispatch(setQuestionStreaming(true));

				processEventSourceMessage(eventData, eventSource);
			};

			const processEventSourceMessage = async (
				eventData: any,
				eventSource: any
			) => {
				if (eventData.data.eventType === 'llm_execution_continue') {
					dataRef.current += eventData.data.data.token;
					setStreamData(dataRef.current as string);
				} else if (eventData.data.eventType === 'llm_execution_end') {
					eventSource.close();
					dispatch(setQuestionStreaming(false));
				}
			};
		}

		// Cleanup function
		return () => {
			if (eventSource) {
				eventSource.close();
				dataRef.current = '';
				setStreamData('');
			}
		};
	}, [currentQuestion, initializeEventSource, proposal?.id, dispatch]);
	const showLoader = !showStream || pageTransitionLoad;
	const hideFooter = currentQuestion !== null && showStream === null;
	return hideFooter ? (
		<></>
	) : (
		<Card className="p-6 bg-lightBackground mt-8 rounded-xl border border-[rgba(185,212,255,0.40)] guidelines">
			<CardContent className="flex flex-col gap-4 p-0 h-[250px] overflow-y-auto">
				{showLoader ? (
					<Skeleton className="h-6 w-[400px] bg-slate-300" />
				) : (
					<p className="font-bold text-black">
						Guidance for answering this question:
					</p>
				)}
				{showLoader ? (
					<div>
						<Skeleton className="w-full h-5 mt-3 bg-slate-300" />
						<Skeleton className="w-full h-5 mt-3 bg-slate-300" />
						<Skeleton className="w-full h-5 mt-3 bg-slate-300" />
						<Skeleton className="w-9/12 h-5 mt-3 bg-slate-300" />
					</div>
				) : (
					<p className="text-sm text-black whitespace-pre-wrap">{showStream}</p>
				)}
			</CardContent>
		</Card>
	);
};

export default ProposalApproachCard;
