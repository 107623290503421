import { FC, useEffect, useRef, useState } from 'react';
import styles from './TemplateCard.module.css';
import { useMutation, useQueryClient } from 'react-query';
import {
	cropText,
	handleApiError,
	handleGeneralError,
	handleUnexpectedError,
} from '@/lib/functions/funcUtils';
import { openDialog } from '@/redux/slices/pageSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useNavigate } from 'react-router';
import { omit } from 'lodash';
import { cn } from '@/lib/utils';
import {
	deleteTemplate,
	publishTemplate,
} from '@/lib/functions/TemplateBuilder/templateCalls';
import customToast from '@/components/CustomToast/CustomToast';
import {
	TemplateCardProps,
	TemplateLibrary,
	TemplatePublish,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { ErrorResponse } from '@/lib/types/apiTypes';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import {
	buildTemplate,
	setEditTemplateId,
	setTemplateMode,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { ChevronRight, Files, Trash2 } from 'lucide-react';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';

const PrivateTemplateCard: FC<TemplateCardProps> = ({ templateCardData }) => {
	const [showFullText, setShowFullText] = useState(false);
	const cardRef = useRef<HTMLDivElement | null>(null);
	const textRef = useRef<HTMLParagraphElement | null>(null);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
				setShowFullText(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	const deleteMutation = useMutation((id: string) => deleteTemplate(id), {
		onSuccess: () => {
			customToast.success({
				title: 'Template deleted successfully',
			});
			// Invalidate and refetch the data query when the mutation is successful
			queryClient.invalidateQueries('privatetemplates');
		},
		onError: (error: any) => {
			if (error instanceof Error) {
				handleGeneralError(error);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
			} else {
				handleUnexpectedError();
			}
		},
	});
	const publishMutation = useMutation(
		(data: TemplatePublish) => publishTemplate(data, data._id),
		{
			onSuccess: (result) => {
				const res = result.data as TemplatePublish;
				customToast.success({
					title: `Template ${
						res.isLive ? 'Published' : 'UnPublished'
					} successfully`,
				});
				// Invalidate and refetch the data query when the mutation is successful
				queryClient.invalidateQueries('privatetemplates');
			},
			onError: (error: any) => {
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
		}
	);
	const OnDeleteTemplate = (id: string | undefined) => {
		if (id) {
			deleteMutation.mutate(id);
		}
	};
	const OnPublishTemplate = (data: TemplatePublish) => {
		publishMutation.mutate(data);
	};

	return (
		<div
			className={cn(
				styles.card,
				'flex flex-col gap-5 shadow-main justify-between rounded-2xl p-4 2xl:p-6'
			)}
			ref={cardRef}
		>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row items-center justify-between">
					<h4 className="flex items-center h-10 font-bold text-black dynamic-medium">
						{templateCardData?.name}
					</h4>
					<TextButton
						className="h-fit 2xl:h-fit p-0"
						onClick={() =>
							dispatch(
								openDialog({
									type: 'showTemplate',
									selectedTemplateId: templateCardData._id,
									privateTemplate: true,
								})
							)
						}
					>
						Show Template
					</TextButton>
				</div>
				<div className="flex items-center gap-1 pb-4 border-b border-b-[#E9E9E9]">
					{templateCardData.tags.slice(0, 3).map((item) => (
						<TooltipProvider key={item}>
							<Tooltip>
								<TooltipTrigger className="flex items-center justify-center">
									<Badge
										key={item}
										className="items-center justify-center block px-3 bg-white border max-w-40 text-secondary border-secondary"
									>
										<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-small text-nowrap text-ellipsis">
											{cropText(item, 16)}
										</span>
									</Badge>
								</TooltipTrigger>
								<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
									{item}
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					))}
					{templateCardData.tags.length > 3 && (
						<Popover>
							<PopoverTrigger onClick={(e) => e.stopPropagation()}>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger className="flex items-center justify-center">
											<ChevronRight
												className={cn('text-[#5D6F79]')}
												size={18}
											/>
										</TooltipTrigger>
										<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
											View More Tags
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</PopoverTrigger>
							<PopoverContent
								className="gap-2 py-4 bg-white rounded-md "
								align="center"
							>
								<h2 className="text-[#5D6F79] font-bold mb-3">Template Tags</h2>
								<div className="flex flex-wrap gap-1">
									{templateCardData.tags.map((item) => (
										<Badge
											key={item}
											className="items-center justify-center block px-3 bg-white border text-secondary border-secondary"
										>
											{item.length > 35 ? (
												<TooltipProvider>
													<Tooltip>
														<TooltipTrigger
															tabIndex={-1}
															className="flex text-left"
														>
															<span className="font-medium uppercase text-secondary dynamic-badge">
																{cropText(item, 35)}
															</span>
														</TooltipTrigger>
														<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
															{item}
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>
											) : (
												<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-badge">
													{item}
												</span>
											)}
										</Badge>
									))}
								</div>
							</PopoverContent>
						</Popover>
					)}
				</div>
				<div>
					<p
						ref={textRef}
						className={`text-sm xl:text-base text-black font-normal ${
							!showFullText ? styles.limitedText : ''
						}`}
					>
						{templateCardData?.description}{' '}
					</p>

					{templateCardData?.description?.length < 300 ? (
						<></>
					) : (
						<>
							{!showFullText ? (
								<TextButton
									className="p-0 mt-2 text-sm font-bold h-fit 2xl:h-fit w-fit xl:text-sm 2xl:text-base"
									onClick={() => setShowFullText(true)}
								>
									Read More
								</TextButton>
							) : (
								<TextButton
									className="p-0 mt-2 text-sm font-normal h-fit 2xl:h-fit w-fit xl:text-sm 2xl:text-base"
									onClick={() => setShowFullText(false)}
								>
									Less
								</TextButton>
							)}
						</>
					)}
				</div>
			</div>

			<div className="flex flex-row items-center justify-between">
				<div className="flex flex-row items-center gap-4">
					<PrimaryButton
						onClick={() => {
							const data = {
								_id: templateCardData._id,
								isLive: !templateCardData.isLive,
							};
							dispatch(
								openDialog({
									type: 'notification',
									notificationObj: {
										title: `${data.isLive ? 'Publish' : 'Unpublish'} Template`,
										description: `Are you sure you want to ${
											data.isLive ? 'publish' : 'unpublish'
										} this template ?`,
										onConfirm: () => {
											OnPublishTemplate(data);
										},
									},
								})
							);
						}}
					>
						{templateCardData.isLive ? 'UnPublish' : 'Publish'}
					</PrimaryButton>
					<SecondaryButton
						onClick={() => {
							dispatch(setTemplateMode('edit'));
							dispatch(setEditTemplateId(templateCardData?._id));
							dispatch(buildTemplate(templateCardData));
							navigate(`/template/edit/${templateCardData?._id}`);
						}}
					>
						Edit
					</SecondaryButton>
				</div>

				<div className="flex items-center gap-2">
					<Button
						className="bg-transparent hover:bg-transparent text-primary hover:text-secondary p-0 m-0"
						title="Duplicate"
						onClick={() => {
							const updatedData = omit(templateCardData, '_id');
							dispatch(setTemplateMode('clone'));
							dispatch(buildTemplate(updatedData as TemplateLibrary));
							navigate(`/template/new`);
						}}
					>
						<Files size={21} />
					</Button>
					<Button
						onClick={() => {
							dispatch(
								openDialog({
									type: 'notification',
									notificationObj: {
										title: 'Remove Template ?',
										description:
											'Are you sure you want to remove this template ?',
										onConfirm: () => {
											OnDeleteTemplate(templateCardData._id);
										},
									},
								})
							);
						}}
						className="bg-transparent hover:bg-transparent hover:text-secondary p-0 m-0"
					>
						<Trash2 size={21} />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default PrivateTemplateCard;
