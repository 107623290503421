import { FunctionComponent } from 'react';
import styles from './node-renderer.module.css';
import { cn } from '@/lib/utils';
import {
	changeNodeDesign,
	isDescendant,
} from '@/lib/functions/TemplateBuilder/templateUtils';
import { CustomThemeNodeContentRendererProps } from '@/lib/types/TemplateBuilder/templateTypes';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { setisNodeDragging } from '@/redux/slices/TemplateBuilder/templateSlice';

const CustomThemeNodeContentRenderer: FunctionComponent<
	CustomThemeNodeContentRendererProps
> = ({
	scaffoldBlockPxWidth,
	toggleChildrenVisibility,
	connectDragPreview,
	connectDragSource,
	isDragging,
	canDrop,
	canDrag,
	node,
	title,
	subtitle,
	draggedNode,
	path,
	treeIndex,
	isSearchMatch,
	isSearchFocus,
	buttons,
	className,
	style,
	didDrop,
	selectedNodeData,
	setNodePath,
	dndType,
	showWordCount = false,
	showTemplate = false,
	isLastNode,
	lastItemRef,
}) => {
	const nodeTitle = title || node.alias;
	const nodeSubtitle = subtitle || node.subtitle;

	const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
	const isLandingPadActive = !didDrop && isDragging;
	const dispatch = useAppDispatch();
	const nodeId = node.systemName;

	const handleDragStart = (ev) => {
		// Set custom data for drag
		!showWordCount && dispatch(setisNodeDragging(true));
		ev.target.style.opacity = '0';
	};

	const handleDragEnd = (ev) => {
		!showWordCount && dispatch(setisNodeDragging(false));
		ev.target.style.opacity = '1';
	};

	const nodeContent = connectDragPreview(
		<div
			ref={isLastNode ? lastItemRef : null}
			className={cn(
				styles.row +
					(showWordCount && isLandingPadActive
						? ` ${styles.rowLandingPad}`
						: '') +
					(showWordCount && isLandingPadActive && !canDrop
						? ` ${styles.rowCancelPad}`
						: '') +
					(isSearchMatch ? ` ${styles.rowSearchMatch}` : '') +
					(isSearchFocus ? ` ${styles.rowSearchFocus}` : '') +
					(className ? ` ${className}` : ''),
				`rstRow relative group/item h-[50px] ${isLastNode ? 'lastItem' : ''}`
			)}
			style={{
				opacity: isDraggedDescendant ? 0.5 : 1,
				...style,
			}}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
		>
			<div
				className={cn(
					styles.rowContents +
						(!canDrag ? ` ${styles.rowContentsDragDisabled}` : ''),
					'rowContents flex gap-2 pl-4 ',
					`${dndType === 'proposalSections' ? ' cursor-ga ' : ' hover:bg-[#EAF1FC] '}`,
					`${!canDrag ? 'available-drag-sections' : ''}`
				)}
				onClick={(event) => {
					event.preventDefault();
					if (showWordCount) {
						selectedNodeData && selectedNodeData(node);
						setNodePath && setNodePath(path);
						setTimeout(() => {
							changeNodeDesign(node);
						}, 100);
					}
				}}
			>
				<div
					className={cn(
						styles.rowLabel,
						'rstLabel w-full flex flex-grow overflow-hidden'
					)}
				>
					<span
						className={cn(
							styles.rowTitle +
								(node.subtitle ? ` ${styles.rowTitleWithSubtitle}` : ''),
							'overflow-hidden'
						)}
					>
						{typeof nodeTitle === 'function'
							? nodeTitle({
									node,
									path,
									treeIndex,
								})
							: nodeTitle}
					</span>
					{nodeSubtitle && (
						<span className={cn(styles.rowSubtitle)}>
							{typeof nodeSubtitle === 'function'
								? nodeSubtitle({
										node,
										path,
										treeIndex,
									})
								: nodeSubtitle}
						</span>
					)}
				</div>
				{(showWordCount || showTemplate) && (
					<div className="flex invisible rst__wordcount 2xl:visible">
						<span
							className={`dynamic-small text-primary ${showTemplate ? 'pr-4' : 'pr-8'}`}
							id={`wordcount-${nodeId}`}
						>
							{node.wordCount + ' words'}
						</span>
					</div>
				)}
			</div>
			<div
				className={cn(styles.rowToolbar, 'absolute right-2')}
				style={{
					top: '55%',
					transform: 'translateY(-50%)',
				}}
			>
				{buttons?.map((btn, index) => (
					<div
						key={index}
						className={cn(
							styles.toolbarButton,
							'pr-2 hover:bg-transparent',
							`${!canDrag ? 'available-drag-button' : ''}`
						)}
					>
						{btn}
					</div>
				))}
			</div>
		</div>
	);

	return (
		<div style={{ height: '100%' }} className="hello">
			{toggleChildrenVisibility &&
				node.children &&
				(node.children.length > 0 || typeof node.children === 'function') && (
					<div>
						<button
							type="button"
							aria-label={node.expanded ? 'Collapse' : 'Expand'}
							className={cn(
								node.expanded ? styles.collapseButton : styles.expandButton
							)}
							style={{
								left: -0.5 * scaffoldBlockPxWidth,
							}}
							onClick={() =>
								toggleChildrenVisibility({
									node,
									path,
									treeIndex,
								})
							}
						/>

						{node.expanded && !isDragging && (
							<div
								style={{
									width: scaffoldBlockPxWidth,
								}}
								className={cn(styles.lineChildren)}
							/>
						)}
					</div>
				)}

			<div
				className={cn(
					styles.rowWrapper +
						(!canDrag ? ` ${styles.rowWrapperDragDisabled}` : '')
				)}
			>
				{canDrag
					? connectDragSource(nodeContent, {
							dropEffect: 'copy',
						})
					: nodeContent}
			</div>
		</div>
	);
};

CustomThemeNodeContentRenderer.defaultProps = {
	buttons: [],
	canDrag: false,
	canDrop: false,
	className: '',
	draggedNode: null,
	isSearchFocus: false,
	isSearchMatch: false,
	style: {},
	subtitle: null,
	title: null,
	toggleChildrenVisibility: undefined,
};

export default CustomThemeNodeContentRenderer;
