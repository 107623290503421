import customToast from '@/components/CustomToast/CustomToast';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { updateSection } from '@/lib/functions/apiCalls';
import { findSection } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import useProposalId from '@/lib/hooks/useProposalId';
import { Section } from '@/lib/types/apiTypes';
import { closeDialog } from '@/redux/slices/pageSlice';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface AddCitationProps {
	sectionId: string;
	description: string;
}

const AddCitation = () => {
	const proposalId = useProposalId() as number;
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'addCitation'
	);
	const sections = useSelector(
		(root: RootState) => root.proposal.proposal?.sections
	);
	const [loader, setLoader] = useState(false);
	const dispatch = useAppDispatch();
	const form = useForm<AddCitationProps>();
	const { handleSubmit, reset, register, control, watch } = form;

	const sectionId = watch('sectionId');
	const description = watch('description');

	const maxChars = 600; // Maximum character limit
	const [descriptionLength, setDescriptionLength] = useState(0);

	useEffect(() => {
		reset();
		setDescriptionLength(0);
	}, []);

	const handleDescriptionChange = (event) => {
		setDescriptionLength(event.target.value.length);
		form.setValue('description', event.target.value.slice(0, maxChars));
	};

	const AddCitation = async (data: AddCitationProps) => {
		const section = findSection(sections as Section[], data.sectionId);
		try {
			if (section) {
				setLoader(true);
				const updatedSection: Section = {
					...section, // Spread all existing properties of the section
					references: section.references
						? [...section.references, data.description]
						: [data.description],
				};
				const response = await updateSection(
					proposalId,
					section.id,
					updatedSection
				);
				if (response.statusCode === 200) {
					dispatch(fetchProposalById(proposalId));
					setLoader(false);
					dispatch(closeDialog());
					customToast.success({
						title: 'Citation added successfully',
					});
					reset();
					setDescriptionLength(0);
				}
			}
		} catch (e) {
			console.log(e);
			customToast.error({
				title: 'Error adding Citation',
			});
			setLoader(false);
		}
	};

	const renderCitation = (
		sec: Section,
		level: number,
		prefix: string,
		index?: number
	) => {
		let headingPrefix = prefix;
		if (level === 0) {
			headingPrefix = String(index);
		}
		return (
			<div key={sec.id}>
				<SelectItem value={String(sec.id)} key={sec.id}>
					{sec.name}
				</SelectItem>
				{sec.children.map((child, idx) =>
					renderCitation(child, level + 1, `${headingPrefix}.${idx + 1}`)
				)}
			</div>
		);
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[400px] p-6 rounded-3xl shadow-main bg-white">
				<DialogHeader>
					<DialogTitle className="font-bold dynamic-large text-secondary">
						Add Citation
					</DialogTitle>
					<DialogDescription className="mt-4">
						<form onSubmit={handleSubmit(AddCitation)}>
							<label className="block my-3 text-xl font-bold text-black">
								Select Section
							</label>
							<Controller
								{...register('sectionId')}
								name="sectionId"
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										onValueChange={(value) => {
											field.onChange(value);
										}}
									>
										<SelectTrigger className="w-full p-4 rounded-2xl border-[#d3dde2] bg-white focus:ring-0 focus:shadow-none focus:ring-offset-0 h-[50px]">
											<SelectValue placeholder="Select Section" />
										</SelectTrigger>
										<SelectContent className="bg-white">
											{sections?.map((section, index) =>
												renderCitation(section, 0, '', index + 1)
											)}
										</SelectContent>
									</Select>
								)}
							/>
							<label className="block my-3 text-xl font-bold text-black">
								Description
							</label>
							<Textarea
								{...register('description')}
								placeholder="Enter Description"
								onChange={handleDescriptionChange}
								maxLength={maxChars}
								className="p-4 rounded-2xl resize-none border-[#d3dde2] bg-white focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-offset-0 min-h-[150px]"
							/>
							<div className="mt-2 text-sm text-right text-gray-400">
								{descriptionLength}/{maxChars}
							</div>
							<div className="flex justify-center gap-4 mt-6">
								<Button
									type="button"
									onClick={() => {
										dispatch(closeDialog());
										reset();
										setDescriptionLength(0);
									}}
									className="flex gap-2 px-8 py-6 text-base font-bold uppercase bg-white border text-primary border-primary rounded-2xl hover:bg-primary hover:text-white animated-hover-effect"
								>
									Cancel
								</Button>
								<Button
									disabled={!sectionId || !description}
									type="submit"
									className="flex gap-2 px-8 py-6 text-base font-bold text-white uppercase rounded-2xl hover:bg-gradient-linear animated-hover-effect"
								>
									{loader ? 'Creating....' : 'Add Citation'}
								</Button>
							</div>
						</form>
					</DialogDescription>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default AddCitation;
