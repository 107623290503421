import { SVGProps } from 'react';

const UnsolicitedDashboardIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="53"
			height="52"
			viewBox="0 0 53 52"
			fill="none"
		>
			<g clipPath="url(#clip0_14466_18083)">
				<path
					d="M40.5 0H12.5C5.87258 0 0.5 5.37258 0.5 12V40C0.5 46.6274 5.87258 52 12.5 52H40.5C47.1274 52 52.5 46.6274 52.5 40V12C52.5 5.37258 47.1274 0 40.5 0Z"
					fill="#5D9BFD"
				/>
				<path
					d="M29.79 40.3487H15.71C14.46 40.3487 13.26 39.8487 12.38 38.9587C11.5 38.0687 11 36.8687 11 35.6087V15.9587C11 14.6987 11.5 13.4987 12.38 12.6087C13.26 11.7187 14.46 11.2188 15.71 11.2188H29.79C31.04 11.2188 32.24 11.7187 33.12 12.6087C34 13.4987 34.5 14.6987 34.5 15.9587V35.6087C34.5 36.8687 34 38.0687 33.12 38.9587C32.24 39.8487 31.04 40.3487 29.79 40.3487ZM15.71 13.3088C15.01 13.3088 14.34 13.5887 13.84 14.0887C13.34 14.5887 13.07 15.2587 13.06 15.9688V35.6187C13.06 36.3187 13.34 36.9987 13.84 37.4987C14.34 37.9987 15.01 38.2787 15.71 38.2787H29.79C30.49 38.2787 31.16 37.9987 31.66 37.4987C32.15 36.9987 32.43 36.3288 32.44 35.6287V15.9587C32.44 15.2587 32.16 14.5787 31.67 14.0887C31.18 13.5887 30.5 13.3088 29.8 13.3088H15.72H15.71Z"
					fill="white"
				/>
				<path
					d="M27.8916 18.9987C27.7516 18.9987 27.6216 18.9688 27.4916 18.9187C27.3616 18.8687 27.2516 18.7888 27.1516 18.6988C27.0516 18.5988 26.9816 18.4888 26.9316 18.3588C26.8816 18.2288 26.8516 18.0987 26.8516 17.9587V12.2587C26.8516 11.9787 26.9616 11.7188 27.1516 11.5188C27.3416 11.3188 27.6116 11.2188 27.8816 11.2188C28.1516 11.2188 28.4216 11.3288 28.6116 11.5188C28.8016 11.7088 28.9116 11.9787 28.9116 12.2587V16.8987L33.4416 16.8287H33.4616C33.6016 16.8287 33.7316 16.8487 33.8616 16.9087C33.9916 16.9587 34.1016 17.0387 34.2016 17.1287C34.3016 17.2287 34.3716 17.3387 34.4316 17.4687C34.4816 17.5987 34.5116 17.7288 34.5116 17.8688C34.5116 18.0088 34.4916 18.1388 34.4316 18.2688C34.3816 18.3988 34.3016 18.5088 34.2116 18.6088C34.1216 18.7088 34.0016 18.7787 33.8816 18.8387C33.7516 18.8888 33.6216 18.9187 33.4816 18.9187L27.8916 18.9987Z"
					fill="white"
				/>
				<path
					d="M23.76 23.3456H16.78C16.51 23.3456 16.24 23.2356 16.05 23.0456C15.86 22.8456 15.75 22.5856 15.75 22.3056C15.75 22.0256 15.86 21.7656 16.05 21.5656C16.24 21.3656 16.51 21.2656 16.78 21.2656H23.76C24.03 21.2656 24.3 21.3756 24.49 21.5656C24.68 21.7656 24.79 22.0256 24.79 22.3056C24.79 22.5856 24.68 22.8456 24.49 23.0456C24.3 23.2456 24.03 23.3456 23.76 23.3456Z"
					fill="white"
				/>
				<path
					d="M24.22 28.9906H16.79C16.65 28.9906 16.52 28.9606 16.39 28.9106C16.26 28.8606 16.15 28.7806 16.05 28.6806C15.95 28.5806 15.88 28.4706 15.83 28.3406C15.78 28.2106 15.75 28.0806 15.75 27.9406C15.75 27.8006 15.78 27.6706 15.83 27.5406C15.88 27.4106 15.96 27.3006 16.05 27.2006C16.15 27.1006 16.26 27.0306 16.39 26.9706C16.52 26.9106 16.65 26.8906 16.79 26.8906H24.22C24.36 26.8906 24.49 26.9206 24.62 26.9706C24.75 27.0206 24.86 27.1006 24.96 27.2006C25.06 27.3006 25.13 27.4106 25.18 27.5406C25.23 27.6706 25.26 27.8006 25.26 27.9406C25.26 28.0806 25.23 28.2106 25.18 28.3406C25.13 28.4706 25.05 28.5806 24.96 28.6806C24.86 28.7806 24.75 28.8506 24.62 28.9106C24.49 28.9606 24.36 28.9906 24.22 28.9906Z"
					fill="white"
				/>
				<path
					d="M22.4878 34.6191H16.7878C16.5178 34.6191 16.2478 34.5091 16.0578 34.3191C15.8678 34.1191 15.7578 33.8591 15.7578 33.5791C15.7578 33.2991 15.8678 33.0391 16.0578 32.8391C16.2478 32.6391 16.5178 32.5391 16.7878 32.5391H22.4878C22.7578 32.5391 23.0278 32.6491 23.2178 32.8391C23.4078 33.0291 23.5178 33.2991 23.5178 33.5791C23.5178 33.8591 23.4078 34.1191 23.2178 34.3191C23.0278 34.5091 22.7578 34.6191 22.4878 34.6191Z"
					fill="white"
				/>
				<path
					d="M34.7275 43.6269C29.0575 43.6269 24.4375 39.0169 24.4375 33.3369C24.4375 27.6569 29.0475 23.0469 34.7275 23.0469C40.4075 23.0469 45.0175 27.6569 45.0175 33.3369C45.0175 39.0169 40.4075 43.6269 34.7275 43.6269Z"
					fill="white"
				/>
				<path
					d="M34.7331 41.8803C39.4441 41.8803 43.2631 38.0613 43.2631 33.3503C43.2631 28.6393 39.4441 24.8203 34.7331 24.8203C30.0221 24.8203 26.2031 28.6393 26.2031 33.3503C26.2031 38.0613 30.0221 41.8803 34.7331 41.8803Z"
					fill="#5D9BFD"
				/>
				<path
					d="M30.9219 33.3484C30.9219 32.8084 31.3619 32.3684 31.9019 32.3684H37.5619C38.1019 32.3684 38.5419 32.8084 38.5419 33.3484C38.5419 33.8884 38.1019 34.3284 37.5619 34.3284H31.9019C31.3619 34.3284 30.9219 33.8884 30.9219 33.3484ZM33.6919 36.0684V30.6284C33.6919 30.0884 34.1319 29.6484 34.6719 29.6484H34.7819C35.3219 29.6484 35.7619 30.0884 35.7619 30.6284V36.0684C35.7619 36.6084 35.3219 37.0484 34.7819 37.0484H34.6719C34.1319 37.0484 33.6919 36.6084 33.6919 36.0684Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14466_18083">
					<rect
						width="52"
						height="52"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default UnsolicitedDashboardIcon;
