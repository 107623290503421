import {
	TemplateLibrary,
	TemplateSetup,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TemplateState {
	error: string | null;
	isLoading: boolean;
	templates: TemplateLibrary[];
	templateSetup: TemplateSetup;
	templateMode: 'edit' | 'create' | 'clone' | '';
	privateTemplates: TemplateLibrary[];
	editTemplate: TemplateLibrary | null;
	templateBuilder: TemplateLibrary | null;
	organizationIdentifier: string | null;
	editTemplateId: string | undefined;
	templateTagOptions: any;
	templateTagFilter: string[];
	isNodeDragging: boolean;
	deleteSections: string[];
	isTemplateSetup: boolean;
	isProposalTemplateEdit: boolean;
}

const initialState: TemplateState = {
	isLoading: false,
	templates: [],
	privateTemplates: [],
	error: null,
	editTemplate: null,
	templateBuilder: null,
	organizationIdentifier: null,
	editTemplateId: undefined,
	templateTagOptions: [],
	templateTagFilter: [],
	isNodeDragging: false,
	templateSetup: {
		name: '',
		description: '',
		tags: [],
		isLive: false,
		organizationIdentifier: undefined,
	},
	templateMode: '',
	deleteSections: [],
	isTemplateSetup: false,
	isProposalTemplateEdit: false,
};

const templateSlice = createSlice({
	name: 'templates',
	initialState,
	reducers: {
		resetTemplateBuilder(state) {
			state.templateMode = '';
			state.editTemplateId = undefined;
			state.templateBuilder = null;
			state.organizationIdentifier = null;
			state.templateSetup = {
				name: '',
				description: '',
				tags: [],
				isLive: false,
				organizationIdentifier: undefined,
			};

			state.templateTagOptions = [];
			state.templateTagFilter = [];
			state.isNodeDragging = false;
			state.deleteSections = [];
			state.isTemplateSetup = false;
			state.isProposalTemplateEdit = false;
		},
		toggleProposalTemplateEdit(state, action: PayloadAction<boolean>) {
			state.isProposalTemplateEdit = action.payload;
		},
		addDeletedSection(state, action: PayloadAction<string>) {
			if (!state.deleteSections) {
				state.deleteSections = []; // Ensure it's initialized if it wasn't
			}
			if (!state.deleteSections.includes(action.payload)) {
				state.deleteSections = [...state.deleteSections, action.payload];
			}
		},
		toggleIsTemplateSetup(state, action: PayloadAction<boolean>) {
			state.isTemplateSetup = action.payload;
		},
		resetDeletedSections(state) {
			state.deleteSections = [];
		},
		setTemplateMode(
			state,
			action: PayloadAction<'edit' | 'create' | 'clone' | ''>
		) {
			state.templateMode = action.payload;
		},
		setTemplateSetupData(state, action: PayloadAction<TemplateSetup>) {
			state.templateSetup = action.payload;
		},
		setTemplateData(state, action: PayloadAction<TemplateLibrary[]>) {
			state.templates = action.payload;
		},
		setPrivateTemplateData(state, action: PayloadAction<TemplateLibrary[]>) {
			state.privateTemplates = action.payload;
		},
		setEditTemplateData(state, action: PayloadAction<TemplateLibrary | null>) {
			state.editTemplate = action.payload;
		},
		buildTemplate(state, action: PayloadAction<TemplateLibrary | null>) {
			state.templateBuilder = action.payload;
		},
		setOrganizationIdentifier(state, action: PayloadAction<string | null>) {
			state.organizationIdentifier = action.payload;
		},
		setEditTemplateId(state, action: PayloadAction<string | undefined>) {
			state.editTemplateId = action.payload;
		},
		setTemplateTagOptions(state, action: PayloadAction<any[]>) {
			state.templateTagOptions = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
		setTemplateTagFilter(state, action: PayloadAction<string[]>) {
			state.templateTagFilter = action.payload;
		},
		setisNodeDragging(state, action: PayloadAction<boolean>) {
			state.isNodeDragging = action.payload;
		},
	},
});

export const {
	setTemplateData,
	setEditTemplateData,
	buildTemplate,
	setPrivateTemplateData,
	setOrganizationIdentifier,
	setEditTemplateId,
	setTemplateTagOptions,
	setLoading,
	setTemplateTagFilter,
	setisNodeDragging,
	setTemplateSetupData,
	setTemplateMode,
	resetTemplateBuilder,
	addDeletedSection,
	resetDeletedSections,
	toggleIsTemplateSetup,
	toggleProposalTemplateEdit,
} = templateSlice.actions;
export default templateSlice.reducer;
