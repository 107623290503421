import { Step } from 'intro.js-react';
export const TemplateBuilderMainSteps: Step[] = [
	{
		element: '.welcome-template',
		title: 'Welcome to the template builder',
		intro: (
			<div>
				<p className="text-black">
					A comprehensive tool designed to help you build and manage sections
					and templates for your proposal.
				</p>
			</div>
		),
	},
	{
		element: '.prebuild-templates',
		title: 'Prebuilt templates',
		intro: 'We have prebuilt templates for you to start with.',
		position: 'top',
	},
	{
		element: '.copy-button',
		title: 'Copy Button',
		intro: 'You can use these as a starting point by pressing the copy button.',
		position: 'bottom',
	},
	{
		element: '.new-template',
		title: 'Create a new template',
		intro: 'Or start a new template by pressing create a new template',
		position: 'left',
	},
];
