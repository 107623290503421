import { SVGProps } from 'react';

const Upload = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="41"
			viewBox="0 0 40 41"
			fill="none"
		>
			<mask
				id="mask0_5335_9986"
				mask-type="luminance"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="40"
				height="41"
			>
				<path d="M40 40.5V0.5L0 0.5V40.5L40 40.5Z" fill="white" />
			</mask>
			<g mask="url(#mask0_5335_9986)">
				<path
					d="M23.2309 10.791L33.4981 10.791C34.8433 10.7911 36.1333 11.3031 37.0846 12.2144C38.0358 13.1256 38.5704 14.3615 38.5707 15.6502L38.5722 34.2727C38.5721 35.5615 38.0376 36.7974 37.0864 37.7087C36.1352 38.6199 34.8451 39.1319 33.4998 39.1319L6.50207 39.1319C5.15691 39.1319 3.8668 38.6199 2.91556 37.7086C1.96432 36.7974 1.42986 35.5614 1.42976 34.2727L1.42829 15.6502C1.42819 14.3614 1.96246 13.1255 2.91356 12.2142C3.86466 11.303 5.15468 10.791 6.49984 10.791L9.04471 10.791"
					stroke="#5D9BFD"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.6585 1.86914L16.6602 23.7026L23.2317 17.5091"
					stroke="#5D9BFD"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.6567 23.7023L10.3021 17.5098"
					stroke="#5D9BFD"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M30.8672 10.791L30.8678 18.2862L38.574 18.174"
					stroke="#5D9BFD"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};

export default Upload;
