import CKEditors from '@/components/CKEditor/CKEditors';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import Ai from '@/components/ui/icons/Ai';
import Replace from '@/components/ui/icons/new/Replace';
import Tick from '@/components/ui/icons/new/Tick';
import { Input } from '@/components/ui/input';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { MagicContext } from '@/lib/context/MagicProvider';
import { findSection } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import useProposalId from '@/lib/hooks/useProposalId';
import { triggerEnhance } from '@/lib/observables/observables';
import { Document, MAGIC_TYPES } from '@/lib/types/apiTypes';
import { cn } from '@/lib/utils';
import { closeDialog, startStreaming } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

interface EnhanceDialogProps {}

const EnhanceDialog: FC<EnhanceDialogProps> = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'openMagicEnhance'
	);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const maxChars = 250; // Maximum character limit
	const [descriptionLength, setDescriptionLength] = useState(0);
	const dispatch = useAppDispatch();
	const editorRef = useRef<any>(null);
	const [enableAutoSave, setEnableAutoSave] = useState(true);
	const [currentWordCount, setCurrentWordCount] = useState(0);
	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);
	const handleMagic = useContext(MagicContext);
	const proposalID = useProposalId();
	const { activeSection, updateActiveSection } = useActiveSection();

	const [enableButton, setEnableButton] = useState(false);
	const [directedText, setDirectedText] = useState('');
	const [enablePreview, setEnablePreview] = useState(false);
	const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);

	const removeDuplicateDocuments = (documents: Document[]): Document[] => {
		const seenIds = new Set();
		return documents.filter((doc) => {
			if (seenIds.has(doc.id)) {
				return false;
			}
			seenIds.add(doc.id);
			return true;
		});
	};
	const documents = useMemo(() => {
		if (!proposal || !activeSection.sectionId) return [];

		const section = findSection(
			proposal.sections,
			String(activeSection.sectionId)
		);

		const allDocuments = [
			...(proposal.formativeDocuments || []),
			...(section?.sectionDocuments.map((doc) => doc.document) || []),
		];

		return removeDuplicateDocuments(allDocuments);
	}, [proposal, activeSection]) as Document[];

	const dataRef = useRef('');

	const expectedOutput = 'text';

	const editorText = useMemo(() => {
		if (!editorRef.current) return '';
		return editorRef.current.getData();
	}, [editorRef]);

	useEffect(() => {
		setEnablePreview(false);
		setCurrentWordCount(0); // Reset word count here
		setDescriptionLength(0);
		setSelectedDocuments([]);
		setDirectedText('');
		editorRef.current = '';
	}, []);

	const handleDirected = () => {
		if (proposalID && handleMagic) {
			setEnableButton(true);
			setEnablePreview(true);
			setEnableAutoSave(false);
			dispatch(startStreaming());
			const payload: {
				query: string;
				instructions: string;
				documentIds?: number[];
			} = {
				query: activeSection.editorSelectedText as string,
				instructions: directedText as string,
			};
			if (selectedDocuments.length > 0) {
				payload.documentIds = selectedDocuments;
			}
			handleMagic(
				proposalID,
				String(activeSection.sectionId),
				dataRef,
				editorRef,
				expectedOutput,
				MAGIC_TYPES.ENHANCE,
				payload
			);
			if (editorRef.current) {
				editorRef.current.setData(''); // Set the editor data/content to an empty string
			}
			setEnableAutoSave(true);
		}
	};

	const handleInsertText = () => {
		setEnableButton(false);
		if (!activeSection.editorRef) return;
		const editor = activeSection.editorRef.current;
		if (!editorRef.current.getData()) return;

		const editorData = editorRef.current.getData();
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = editorData;
		const modelText = tempDiv.innerText || '';

		if (editor) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			editor.model.change((writer) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const selection = editor.model.document.selection;
				if (selection) {
					const ranges = Array.from(selection.getRanges());
					for (const range of ranges) {
						writer.remove(range);
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						writer.insertText(modelText, range.start);
					}
				}
			});
		}
		triggerEnhance();
		handleClear();
	};

	const handleSelectDoc = (id: number) => {
		if (selectedDocuments.includes(id)) {
			setSelectedDocuments(selectedDocuments.filter((docId) => docId !== id));
		} else {
			setSelectedDocuments([...selectedDocuments, id]);
		}
	};

	const handleClear = () => {
		dispatch(closeDialog());
		setCurrentWordCount(0);
		setDescriptionLength(0);
		setEnablePreview(false);
		setSelectedDocuments([]);
		updateActiveSection({
			editorSelectedText: '',
		});
		setDirectedText('');
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-3xl px-4 py-6 bg-white rounded-2xl"
				IsMagic
				hideCloseBtn
			>
				<DialogHeader>
					<div className="flex items-center gap-1 px-2">
						<Ai className="w-8 h-8" />
						<h1 className="text-[#5D6F79] font-bold dynamic-medium">
							Let me help you enhance the selected text
						</h1>
					</div>
				</DialogHeader>
				<div className="max-h-[600px] overflow-y-scroll px-3">
					<Accordion type="single" collapsible>
						<AccordionItem
							value="item-1"
							className="text-[#5D6F79] font-bold bg-[#eaf1fc] p-1 !border-none  rounded-lg"
						>
							<AccordionTrigger className="text-primary font-bold bg-[#eaf1fc] [data-state=closed]:!border-none p-2 hover:no-underline ">
								Selected Text
							</AccordionTrigger>
							<AccordionContent className="text-[#5D6F79] font-normal p-2 py-3 border-[#eaf1fc] bg-[#eaf1fc] ">
								{activeSection.editorSelectedText}
							</AccordionContent>
						</AccordionItem>
					</Accordion>
					{!enablePreview && (
						<div className="flex flex-col items-start gap-3 my-4">
							<div className="relative w-full">
								<p className="mb-1 font-bold text-black">
									Enhance selected text
								</p>
								<Input
									onChange={(e) => {
										setDirectedText(e.target.value);
										setDescriptionLength(e.target.value.length);
									}}
									maxLength={maxChars}
									placeholder="Please provide directions on how you would like the selected text to be re-written"
									className={cn(
										'w-full h-12 bg-white rounded-lg relative',
										descriptionLength === maxChars ? 'border-red-500' : ''
									)}
								/>
								<p
									className={cn(
										'absolute right-0 -bottom-6 dynamic-small text-gray-400',
										descriptionLength === maxChars ? 'text-red-500' : ''
									)}
								>
									{descriptionLength}/{maxChars}
								</p>
							</div>
						</div>
					)}
					{enablePreview ? (
						<div className={cn('p-3 rounded-lg bg-[#F5F5F5] relative mt-3')}>
							<p className="text-[#5D6F79] font-bold mb-2">Preview</p>
							<CKEditors
								onWordCountChange={setCurrentWordCount}
								placeholder={'Waiting for AI to generate Enhanced text...'}
								editorState={editorText}
								setIsEditorFocused={() => {}}
								editorRef={editorRef}
								isStreaming={isStreaming}
								proposalId={proposalID as number}
								sectionId={activeSection.sectionId as number}
								setIsSaving={() => {}}
								onReady={() => {}}
								stopAutoSave={enableAutoSave}
							/>
							<div className="flex items-center text-[#b4b4b4] text-base my-2">
								<div
									className={
										'flex-1 h-[1px] mr-8 bg-[#D3DDE2] animated-container-transition '
									}
								>
									<span
										className={
											'relative block rounded-full top-[-1.5px] w-1 h-1 bg-[#D3DDE2] animated-container-transition '
										}
									></span>
								</div>
								<div className={'flex items-center gap-2  shrink-0'}>
									<div>{currentWordCount || 0} Words</div>
								</div>
							</div>
							<div className="absolute inset-0 z-20"></div>
						</div>
					) : (
						<Accordion type="single" collapsible className="mt-4">
							<AccordionItem
								value="item-1"
								className="text-[#5D6F79] font-bold bg-white border-b-0"
							>
								<AccordionTrigger className="font-bold text-black py-3 border-b-[1px] border-[#C6DBFB] ">
									<p>
										Do you want to focus on specific documents while expanding?{' '}
										<span className="text-secondary">(optional)</span>
									</p>
								</AccordionTrigger>
								<AccordionContent className="text-[#5D6F79] font-normal py-3  ">
									<ul className="flex flex-col gap-2 group">
										{documents.length > 0 ? (
											documents.map((doc) => (
												<li
													key={doc.id}
													onClick={() => handleSelectDoc(doc.id)}
													className={cn(
														'flex items-center gap-2 px-2 py-3 last:border-none hover:bg-[#eaf1fc]  text-primary border-b border-[#C6DBFB]',
														selectedDocuments.includes(doc.id) &&
															'bg-[#eaf1fc] rounded-md border-none'
													)}
												>
													{selectedDocuments.includes(doc.id) && <Tick />}{' '}
													{doc.name}
												</li>
											))
										) : (
											<div className="text-center ">
												No documents attached to this section
											</div>
										)}
									</ul>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					)}

					<div className="flex items-center justify-end gap-4 pt-4 bg-white">
						{enablePreview && (
							<TextButton
								disabled={isStreaming}
								onClick={() => {
									setEnablePreview(false);
									setCurrentWordCount(0);
									setDescriptionLength(0);
									setDirectedText('');
								}}
							>
								Back
							</TextButton>
						)}
						<SecondaryButton onClick={handleClear} disabled={isStreaming}>
							Cancel
						</SecondaryButton>
						{enablePreview ? (
							<PrimaryButton
								onClick={handleInsertText}
								disabled={isStreaming || !directedText || !enableButton}
							>
								<Replace />
								replace selected text
							</PrimaryButton>
						) : (
							<PrimaryButton
								disabled={!directedText}
								type="button"
								onClick={handleDirected}
							>
								Enhance
							</PrimaryButton>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default EnhanceDialog;
