import { motion } from 'framer-motion';
import { iCSlideInDelay } from '@/constants/variants';
import TemplateCardSkeleton from '../TemplateCard/TemplateCardSkeleton';
import TemplateCard from '../TemplateCard/TemplateCard';
import TemplateBodyHeader from '../TemplateBodyHeader/TemplateBodyHeader';
import { TemplateBodyProps } from '@/lib/types/TemplateBuilder/templateTypes';
import { FC } from 'react';
const TemplateBody: FC<TemplateBodyProps> = ({
	templatesData,
	handleTemplateSorting,
	templatePageSizeLoading,
	templatePageSize,
}) => {
	return (
		<div className="flex flex-col h-full">
			<TemplateBodyHeader
				templateCount={templatesData?.length || 0}
				handleTemplateSorting={handleTemplateSorting}
			/>
			<div className="relative h-5 z-10 w-full bg-gradient-to-b from-[#f5f5f5] to-[#f5f5f500]"></div>
			<motion.div
				className="prebuild-templates relative grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 h-auto overflow-y-auto hidden-scroll px-6 xl:px-8 py-6 mt-[-1rem] gap-4"
				key="prFooterDelay0"
				variants={iCSlideInDelay}
				initial="initial"
				animate="animate"
				exit="exit"
			>
				{!templatesData ? (
					<TemplateCardSkeleton />
				) : (
					<>
						{templatePageSizeLoading
							? Array(Number(templatePageSize) || 3)
									.fill('')
									.map((_, index) => <TemplateCardSkeleton key={index} />)
							: templatesData?.map((template) => (
									<TemplateCard
										key={template._id}
										templateCardData={template}
									/>
								))}
					</>
				)}
			</motion.div>
		</div>
	);
};

export default TemplateBody;
