import { FC, useState } from 'react';
import DocumentCard from '../DocumentCard/DocumentCard';
import { Document as DocumentType, Folder } from '@/lib/types/apiTypes';
import { motion } from 'framer-motion';
import { iCSlideInDelay } from '@/constants/variants';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import Processing from '../ui/icons/Processing';
import { cn } from '@/lib/utils';
import { Plus } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import DocumentsEmptyUpload from '../ui/icons/new/DocumentsEmptyUpload';
import FolderCard from '../FolderCard/FolderCard';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import FoldersPath from '../FoldersPath/FoldersPath';
import { cloneDeep } from 'lodash';
import LoaderTransparent from '../Loader/LoaderTransparent/LoaderTransparent';
import {
	ErrorHandle,
	handleResponse,
	showToast,
} from '@/lib/functions/funcUtils';
import { deleteDocumentById } from '@/lib/functions/apiCalls';
import { useQueryClient } from 'react-query';
import useAuth from '@/lib/hooks/useAuth';

interface DocumentBodyProps {
	documents: DocumentType[];
	documentsCount: number;
	folders?: Folder[];
	foldersPath: Folder[];
	selectedDocuments: number[];
	isFetchingNextPage: boolean;
	showLoadMore?: boolean;
	openFolder: (folderId: number | null) => void;
	fetchNextPage: () => void;
	selectAllDocuments: () => void;
	deselectAllDocuments: () => void;
	toggleDocumentSelection: (documentId: number) => void;
}

const DocumentBody: FC<DocumentBodyProps> = ({
	documents,
	documentsCount,
	folders,
	foldersPath,
	selectedDocuments,
	isFetchingNextPage = false,
	showLoadMore = false,
	openFolder,
	fetchNextPage,
	selectAllDocuments,
	deselectAllDocuments,
	toggleDocumentSelection,
}) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const { isGuest } = useAuth();

	const [isLoading, setIsLoading] = useState(false);

	const handleDeleteSelectedDocuments = async () => {
		try {
			if (selectedDocuments?.length === 0) return;
			setIsLoading(true);
			await Promise.all(
				selectedDocuments.map(async (documentId) => {
					const response = await deleteDocumentById(documentId);
					handleResponse(response);
				})
			);
			showToast('success', 'Documents ha been deleted successfully');
		} catch (error) {
			ErrorHandle(dispatch, error);
		} finally {
			deselectAllDocuments();
			setIsLoading(false);
			queryClient.invalidateQueries('documents');
		}
	};

	return (
		<div className="flex-col h-full">
			<motion.div
				className="flex pt-4 pl-6 xl:pl-8 pr-2 xl:pr-4 items-center"
				key="prFooterDelay0"
				variants={iCSlideInDelay}
				initial="initial"
				animate="animate"
				exit="exit"
			>
				<FoldersPath foldersPath={foldersPath} openFolder={openFolder} />
			</motion.div>
			<div className="h-5 z-10 relative w-full bg-gradient-to-b from-[#f5f5f5] to-[#f5f5f500]"></div>
			<motion.div
				className="relative flex flex-col h-full overflow-y-auto hidden-scroll mt-[-1rem] pt-2 pb-8"
				key="prFooterDelay1"
				variants={iCSlideInDelay}
				initial="initial"
				animate="animate"
				exit="exit"
			>
				{!!folders?.length && (
					<Accordion
						type="single"
						collapsible
						defaultValue="folders"
						className="h-fit"
					>
						<AccordionItem value="folders" className="!border-none">
							<div className="flex pl-6 xl:pl-8 pr-2 xl:pr-4 items-center">
								<h2 className="font-bold content-center dynamic-medium text-black">
									Folders
								</h2>
								<div className="border-blackSecondary border-b w-full mx-8"></div>
								<AccordionTrigger className="px-4"></AccordionTrigger>
							</div>
							<AccordionContent>
								{!folders?.length ? (
									<div className="flex flex-col px-6 xl:px-8">
										<h4 className="content-center h-20 text-black dynamic-medium uppercase px-6 py-3 w-full bg-[#E7E7E7] rounded-xl">
											No folders found
										</h4>
									</div>
								) : (
									<>
										<div className="grid grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 px-6 xl:px-8 py-2 gap-2.5 h-fit">
											{folders?.map((folder) => (
												<FolderCard
													openFolder={openFolder}
													key={folder.id}
													folderCard={folder}
													foldersPath={foldersPath}
												/>
											))}
										</div>
									</>
								)}
							</AccordionContent>
						</AccordionItem>
					</Accordion>
				)}
				<Accordion
					type="single"
					collapsible
					defaultValue="documents"
					className="h-fit"
				>
					<AccordionItem value="documents" className="!border-none">
						<div className="flex pl-6 xl:pl-8 pr-2 xl:pr-4 items-center">
							<h2 className="font-bold content-center dynamic-medium text-black">
								Documents
							</h2>
							<div className="border-blackSecondary border-b w-full mx-4"></div>
							{!isGuest && (
								<>
									<div className="border-blackSecondary border-l h-8 mx-2"></div>
									<TextButton
										className="font-medium"
										disabled={!selectedDocuments.length}
										onClick={() => {
											dispatch(
												openDialog({
													type: 'moveDocuments',
													moveDocuments: {
														documentIds: cloneDeep(selectedDocuments),
														foldersPath: foldersPath,
													},
												})
											);
											deselectAllDocuments();
										}}
									>
										Move Selected
									</TextButton>
									<div className="border-blackSecondary border-l h-4 mx-2"></div>
									<TextButton
										className="font-medium text-secondary"
										disabled={!selectedDocuments.length}
										onClick={() =>
											dispatch(
												openDialog({
													type: 'notification',
													notificationObj: {
														title: `Delete Documents`,
														description: `Are you sure you want to delete all the selected Documents?`,
														onConfirm: () => handleDeleteSelectedDocuments(),
													},
												})
											)
										}
									>
										Delete Selected
									</TextButton>
									<div className="border-blackSecondary border-l h-8 mx-2"></div>
									<TextButton
										className="font-medium"
										disabled={
											documentsCount === 0 ||
											selectedDocuments?.length === 20 ||
											selectedDocuments?.length === documents.length
										}
										onClick={selectAllDocuments}
									>
										Select All
									</TextButton>
									<div className="border-blackSecondary border-l h-4 mx-2"></div>
									<TextButton
										className="font-medium"
										disabled={
											documentsCount === 0 || !selectedDocuments?.length
										}
										onClick={deselectAllDocuments}
									>
										Deselect All
									</TextButton>
									<div className="border-blackSecondary border-l h-8 mx-2"></div>
								</>
							)}
							<AccordionTrigger className="px-4"></AccordionTrigger>
						</div>
						<AccordionContent>
							{documentsCount === 0 ? (
								<div className="flex h-full justify-center items-center">
									<div className="flex flex-col w-[400px] gap-8 justify-center items-center">
										<DocumentsEmptyUpload />
										<h4 className="text-black text-center dynamic-xl-large">
											No document found, add a new document
										</h4>
										<SecondaryButton
											className="flex w-fit self-center bg-transparent"
											onClick={() =>
												dispatch(
													openDialog({
														type: 'uploadDocuments',
														folder: foldersPath?.[foldersPath?.length - 1],
													})
												)
											}
										>
											<Plus />
											Add New Document
										</SecondaryButton>
									</div>
								</div>
							) : (
								<div className="flex flex-col">
									<div className="grid grid-cols-2 2xl:grid-cols-3 px-6 xl:px-8 pt-2 pb-5 gap-4 h-fit">
										{documents?.map((document) => (
											<DocumentCard
												key={document.id}
												documentCard={document}
												foldersPath={foldersPath}
												isSelected={selectedDocuments.includes(document.id)}
												toggleDocumentSelection={toggleDocumentSelection}
											/>
										))}
									</div>
									{showLoadMore && (
										<SecondaryButton
											className={cn(
												'w-40 self-center',
												isFetchingNextPage ? 'pointer-events-none' : ''
											)}
											onClick={() => !isFetchingNextPage && fetchNextPage()}
										>
											{isFetchingNextPage ? (
												<>
													<Processing className="processing-animation" />
													Loading
												</>
											) : (
												<>Load More</>
											)}
										</SecondaryButton>
									)}
								</div>
							)}
						</AccordionContent>
					</AccordionItem>
				</Accordion>
			</motion.div>
			{isLoading && <LoaderTransparent />}
		</div>
	);
};

export default DocumentBody;
