import { Step } from 'intro.js-react';
export const ProposalDashboardSteps: Step[] = [
	{
		element: '.dashboard',
		title: 'Dashboard',
		intro:
			'This is your proposal dashboard. This will give you a single space to change settings related to your proposal.',
	},
	{
		element: '.solicitation-button',
		title: 'Read Solicitation',
		intro: 'Click the name of the solicitation to read it.',
		position: 'bottom',
	},
	{
		element: '.summary',
		title: 'Solicitation summary',
		intro:
			'Access a quick summary of the solicitation and the solicitation brief here',
		position: 'right',
	},
	{
		element: '.revisit-approach',
		title: 'Edit your value propositions',
		intro:
			'You can edit your value propositions at any time by clicking here. This will take you to a dialog box that will let you edit or enhance your value proposition answers.',
		position: 'right',
	},
	{
		element: '.tone-of-voice',
		title: 'Proposal Settings',
		intro:
			'You can view or change the tone of voice, collaboration and citation style here.',
		position: 'left',
	},
	{
		element: '.formative-documents',
		title: 'Essential Documents',
		intro:
			'Click this button to add or remove essential documents linked to this proposal.',
		position: 'top',
	},
	{
		element: '.compliance-card',
		title: 'Compliance Matrix',
		intro:
			'View the compliance matrix to see all the compliance requirements for this solicitation.',
		position: 'left',
	},
];
