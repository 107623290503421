import { FC } from 'react';
import HistoryThumbnail from '../ui/icons/new/HistoryThumbnail';

interface HistoryIntroProps {}

const HistoryIntro: FC<HistoryIntroProps> = () => {
	return (
		<div className="flex flex-col items-center justify-center max-w-sm gap-8 mx-auto mt-6">
			<HistoryThumbnail />
			<p className="font-bold text-center text-black dynamic-text">
				<span className="font-normal">Hint:</span> You can start creating new
				content by using the "Write with Magic" button.
			</p>
		</div>
	);
};

export default HistoryIntro;
