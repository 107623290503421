import { SVGProps } from 'react';

const Doghnut = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="67"
			height="68"
			viewBox="0 0 67 68"
			fill="none"
		>
			<g clipPath="url(#clip0_6197_23083)">
				<path
					d="M63.4145 36.4929L34.9029 36.6199L13.7408 57.896C26.9841 71.0683 46.8959 67.9835 56.1789 57.782C63.6054 49.6208 63.7128 40.1008 63.4145 36.4929Z"
					fill="#B14398"
				/>
				<path
					d="M12.6641 15.6574L33.4862 36.7858H63.4946C63.4946 18.1071 47.2656 6.16496 33.4862 6.77737C22.4627 7.26731 15.0117 12.9015 12.6641 15.6574Z"
					fill="#A8CAFF"
				/>
				<path
					d="M63.4732 36.3938L33.8395 36.2265L12.721 57.4588C25.9643 70.6311 45.8525 67.57 55.1156 57.3886C62.526 49.2435 63.775 39.9983 63.4732 36.3938Z"
					fill="#E861C9"
				/>
				<path
					d="M28.0112 65.7033L34.0771 36.6659L17.4626 11.6767C1.90806 22.0184 0.948798 42.1449 9.08792 53.2804C15.5992 62.1889 24.4164 65.2742 28.0112 65.7033Z"
					fill="#5D9BFD"
				/>
				<circle cx="33.9749" cy="36.7664" r="12.2483" fill="#6678E5" />
				<circle cx="33.0882" cy="35.8792" r="12.2483" fill="white" />
			</g>
			<defs>
				<clipPath id="clip0_6197_23083">
					<rect
						width="66.0486"
						height="66.0486"
						fill="white"
						transform="translate(0.140625 0.975586)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Doghnut;
