const BlueTick = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
		>
			<mask
				id="mask0_2307_5166"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="18"
				height="19"
			>
				<rect y="0.335938" width="18" height="18" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2307_5166)">
				<path
					d="M7.95 12.7859L13.2375 7.49844L12.1875 6.44844L7.95 10.6859L5.8125 8.54844L4.7625 9.59844L7.95 12.7859ZM9 16.8359C7.9625 16.8359 6.9875 16.6391 6.075 16.2453C5.1625 15.8516 4.36875 15.3172 3.69375 14.6422C3.01875 13.9672 2.48438 13.1734 2.09063 12.2609C1.69687 11.3484 1.5 10.3734 1.5 9.33594C1.5 8.29844 1.69687 7.32344 2.09063 6.41094C2.48438 5.49844 3.01875 4.70469 3.69375 4.02969C4.36875 3.35469 5.1625 2.82031 6.075 2.42656C6.9875 2.03281 7.9625 1.83594 9 1.83594C10.0375 1.83594 11.0125 2.03281 11.925 2.42656C12.8375 2.82031 13.6313 3.35469 14.3063 4.02969C14.9813 4.70469 15.5156 5.49844 15.9094 6.41094C16.3031 7.32344 16.5 8.29844 16.5 9.33594C16.5 10.3734 16.3031 11.3484 15.9094 12.2609C15.5156 13.1734 14.9813 13.9672 14.3063 14.6422C13.6313 15.3172 12.8375 15.8516 11.925 16.2453C11.0125 16.6391 10.0375 16.8359 9 16.8359Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default BlueTick;
