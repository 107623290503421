import { FC, MutableRefObject, useContext, useRef, useState } from 'react';
import { Badge } from '../ui/badge';
import styles from './SuggestionListItem.module.css';
import Add from '../ui/icons/Add';
import More from '../ui/icons/More';
import { MAGIC_TYPES, Section, Suggestion } from '@/lib/types/apiTypes';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	// clearSection,
	transparentAnimatedEnd,
	transparentAnimatedStart,
} from '@/redux/slices/proposalSlice';
import { openDialog, startAddInWriteStreaming } from '@/redux/slices/pageSlice';
import { MagicContext } from '@/lib/context/MagicProvider';
import useProposalId from '@/lib/hooks/useProposalId';
import { removeSuggestionByIndex } from '@/redux/slices/suggestionsSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { cn } from '@/lib/utils';
import { scrollToSection } from '@/lib/functions/funcUtils';

interface SuggestionListItemProps {
	suggestion: Suggestion;
	section: Section;
	activeSection: {
		sectionId: number | null;
		editorRef: MutableRefObject<any> | null;
		prefix: string | null;
	};
	index: number;
}

const SuggestionListItem: FC<SuggestionListItemProps> = ({
	suggestion,
	section,
	activeSection,
	index,
}) => {
	const dispatch = useAppDispatch();
	const proposalId = useProposalId() as number;
	const handleMagic = useContext(MagicContext);
	const [mouseOver, setMouseOver] = useState(false);
	const dataRef = useRef('');
	const isStreaming = useSelector((root: RootState) => root.page.isStreaming);

	const handleTrigger = async () => {
		scrollToSection(String(activeSection.sectionId));
		dispatch(transparentAnimatedStart('addThis'));
		setTimeout(() => {
			dispatch(transparentAnimatedEnd());
		}, 800);
		dispatch(startAddInWriteStreaming());
		// dispatch(clearSection({ data: '', sectionId: section.id }));
		dataRef.current = '';

		const payload = {
			magicType: 'magic_suggestion_add',
			query: section?.content as string,
			suggestion: suggestion,
		};

		if (!activeSection.sectionId && !activeSection.editorRef) return;

		const sectionId = String(activeSection.sectionId);
		const editorRef = activeSection.editorRef;

		if (handleMagic && editorRef) {
			handleMagic(
				proposalId,
				sectionId,
				dataRef,
				editorRef,
				section.expectedOutput,
				MAGIC_TYPES.SUGGESTION,
				payload
			);
		}
		dispatch(removeSuggestionByIndex({ sectionId: section.id, index: index }));
	};

	const renderBadges = () => {
		return (
			<Badge className="absolute text-white uppercase dynamic-small right-10 -top-3 bg-secondary">
				{suggestion?.metadata?.category}
			</Badge>
		);
	};

	return (
		<div className={cn(styles.card, mouseOver ? styles.mouseOver : '')}>
			<p className="text-base">{suggestion?.text}</p>
			{renderBadges()}
			<div className="flex items-center justify-between mt-3">
				<div
					className={cn(
						'flex items-center gap-1 xl:gap-2 cursor-pointer animated-hover-effect',
						isStreaming ? 'opacity-50 pointer-events-none' : ''
					)}
					onClick={handleTrigger}
					onMouseEnter={() => setMouseOver(true)}
					onMouseLeave={() => setMouseOver(false)}
				>
					<Add />
					<p className={cn('dynamic-small uppercase font-bold text-[#5d9bfd]')}>
						Add this in
					</p>
				</div>
				<div className="flex items-center gap-1 xl:gap-2 animated-hover-effect">
					<More />
					<p
						className="dynamic-small uppercase font-bold text-[#5d9bfd] cursor-pointer"
						onClick={() =>
							dispatch(
								openDialog({
									type: 'viewSuggestionBackground',
									suggestion: suggestion,
								})
							)
						}
					>
						Tell me more
					</p>
				</div>
			</div>
		</div>
	);
};

export default SuggestionListItem;
