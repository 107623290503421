import { SVGProps } from 'react';

const Video = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				d="M9.32812 17.4L17.7281 12L9.32812 6.6V17.4ZM12.3281 24C10.6681 24 9.10813 23.685 7.64813 23.055C6.18813 22.425 4.91813 21.57 3.83812 20.49C2.75813 19.41 1.90313 18.14 1.27313 16.68C0.643125 15.22 0.328125 13.66 0.328125 12C0.328125 10.34 0.643125 8.78 1.27313 7.32C1.90313 5.86 2.75813 4.59 3.83812 3.51C4.91813 2.43 6.18813 1.575 7.64813 0.945C9.10813 0.315 10.6681 0 12.3281 0C13.9881 0 15.5481 0.315 17.0081 0.945C18.4681 1.575 19.7381 2.43 20.8181 3.51C21.8981 4.59 22.7531 5.86 23.3831 7.32C24.0131 8.78 24.3281 10.34 24.3281 12C24.3281 13.66 24.0131 15.22 23.3831 16.68C22.7531 18.14 21.8981 19.41 20.8181 20.49C19.7381 21.57 18.4681 22.425 17.0081 23.055C15.5481 23.685 13.9881 24 12.3281 24ZM12.3281 21.6C15.0081 21.6 17.2781 20.67 19.1381 18.81C20.9981 16.95 21.9281 14.68 21.9281 12C21.9281 9.32 20.9981 7.05 19.1381 5.19C17.2781 3.33 15.0081 2.4 12.3281 2.4C9.64813 2.4 7.37813 3.33 5.51813 5.19C3.65813 7.05 2.72813 9.32 2.72813 12C2.72813 14.68 3.65813 16.95 5.51813 18.81C7.37813 20.67 9.64813 21.6 12.3281 21.6Z"
				fill="white"
			/>
		</svg>
	);
};

export default Video;
