import { cn } from '@/lib/utils';
import styles from './ParentNode.module.css';
import { NodeData, NodeProps } from '@/lib/types/graphTypes';
import AccordionOpen from '@/components/ui/icons/AccordionOpen';
import Tick from '@/components/ui/icons/new/Tick';
import SectionEditPopover from '@/components/SectionEditPopover/SectionEditPopover';
import { useState } from 'react';
import { cropText } from '@/lib/functions/funcUtils';
import useProposalCategory from '@/lib/hooks/useProposalCategory';

type ParentNodeProps = NodeProps & {
	id: string;
	onClick?: (id: string) => void; // Ensure onClick is correctly typed here as well
	hasChildren?: boolean;
	section?: NodeData;
	editTemplate?: boolean;
	noScaleEffect?: boolean;
};

const ParentNode: React.FC<ParentNodeProps> = ({
	id,
	data,
	onClick,
	isSelected,
	hasChildren,
	section,
	editTemplate,
	noScaleEffect,
}) => {
	const proposalCategory = useProposalCategory();
	const [isHovered, setIsHovered] = useState(false);
	return (
		<div
			className={cn(
				styles.parent,
				!noScaleEffect ? '' : styles.scalingContainer,
				isSelected ? 'bg-[#E861C9] text-white border-none' : 'cursor-pointer'
			)}
			onClick={() => onClick && onClick(id)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div className="flex items-center justify-between w-full gap-2">
				<div className="flex items-center flex-1 gap-2">
					{hasChildren && <AccordionOpen />}
					{isSelected ? (
						<div>{data?.alias || data?.label}</div>
					) : (
						<div>
							{cropText(
								data?.alias || (data?.label as string),
								proposalCategory === 'questionnaire'
									? screen.width > 1536
										? 100
										: 60
									: 30
							)}
						</div>
					)}
				</div>
				<div className="h-6 flex items-center gap-1">
					{data.isComplete && <Tick />}
					{editTemplate && isHovered && (
						<div className="flex items-center gap-2">
							<p className="flex items-center gap-2 font-semibold dynamic-small">
								{data.wordCount} words
							</p>
							<SectionEditPopover
								isSelected={isSelected}
								section={section as NodeData}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ParentNode;
