import { SVGProps } from 'react';

const HistoryThumbnail = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="167"
			height="115"
			viewBox="0 0 167 115"
			fill="none"
		>
			<g clipPath="url(#clip0_5335_10479)">
				<path
					d="M117.371 23.2396L117.271 69.5596C117.271 75.6796 112.291 80.6396 106.171 80.6396L13.0409 80.4996C6.92094 80.4996 1.96094 75.5196 1.96094 69.3996L2.06094 13.1796C2.06094 7.05961 7.04094 2.09961 13.1609 2.09961L98.1109 2.23961L117.361 23.2396H117.371Z"
					fill="#E3EEFF"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M77.8319 24.1699H23.1719"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M77.8319 40.9199H23.1719"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M77.8319 57.6699H23.1719"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M117.372 23.2393H106.232C101.412 23.2393 97.5117 19.3393 97.5117 14.5193V2.23926L117.372 23.2393Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M127.13 113.36C147.769 113.36 164.5 96.629 164.5 75.9901C164.5 55.3512 147.769 38.6201 127.13 38.6201C106.491 38.6201 89.7598 55.3512 89.7598 75.9901C89.7598 96.629 106.491 113.36 127.13 113.36Z"
					fill="white"
				/>
				<path
					d="M91.0695 69.3101C87.3795 89.1901 100.699 108.59 120.579 112.29C140.459 115.98 159.859 102.66 163.559 82.7801C167.249 62.9001 153.929 43.5001 134.049 39.8001C120.029 37.1901 105.699 42.9501 97.3795 54.5401V44.2101"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M97.3691 54.54H107.509"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M127.76 94.8396C137.895 94.8396 146.11 86.6241 146.11 76.4896C146.11 66.3552 137.895 58.1396 127.76 58.1396C117.626 58.1396 109.41 66.3552 109.41 76.4896C109.41 86.6241 117.626 94.8396 127.76 94.8396Z"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M127.039 66.6201V79.5301L132.329 84.8101"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5335_10479">
					<rect
						width="166.13"
						height="114.72"
						fill="white"
						transform="translate(0 0.139648)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default HistoryThumbnail;
