import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import LogicalFrame from '@/pages/LogicalFrame/LogicalFrame';
import { FC, useCallback, useState } from 'react';

interface LogicalFramePaneProps {
	defaultTab: string;
}

const LogicalFramePane: FC<LogicalFramePaneProps> = ({ defaultTab }) => {
	const [selectedTab, setSelectedTab] = useState(defaultTab);

	const handleTabChange = useCallback((newTabIndex) => {
		setTimeout(() => {
			setSelectedTab(newTabIndex);
		}, 500);
	}, []);

	return (
		<AppTabs
			defaultTab={defaultTab}
			selectedTab={selectedTab}
			handleTabChange={handleTabChange}
			type="main"
			tabs={[
				{
					label: 'Logical Frame',
					value: 'logicalFrame',
					content: <LogicalFrame />,
				},
			]}
		/>
	);
};

export default LogicalFramePane;
