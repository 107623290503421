import { MAGIC_TYPES, Proposal } from '@/lib/types/apiTypes';
import { clearSection, setProposal } from '@/redux/slices/proposalSlice';
import store from '@/redux/store/store';
import { updateSectionState } from './apiCalls';
import { fetchProposal } from './funcUtils';
import { stopStreaming } from '@/redux/slices/pageSlice';
import * as cheerio from 'cheerio';
import { updateLastAIResponse } from '@/redux/slices/enhanceSlice';
import { triggerUpdateSection } from '../observables/observables';

export const insertTableToEditor = (
	dataRef: any,
	token: any,
	editorRef: any
) => {
	dataRef.current += token;

	const editor = editorRef.current;
	let modelText = '';
	const root = editor.model.document.getRoot();
	for (const child of root.getChildren()) {
		for (const item of child.getChildren()) {
			if (item.is('text')) {
				modelText += item.data + ' ';
			}
		}
	}

	const formattedHtmlContent = dataRef.current
		.replace(/\n\n/g, '<br><br>')
		.replace(/\r/g, '');

	return {
		content: modelText.trim(),
		editorState: formattedHtmlContent,
	};
};

export const clearSectionData = (type: MAGIC_TYPES, sectionId: number) => {
	switch (type) {
		case MAGIC_TYPES.COMPLETE:
		case MAGIC_TYPES.ENHANCE_WITH_ME:
		case MAGIC_TYPES.SUGGESTION: {
			store.dispatch(clearSection({ data: '', sectionId: sectionId }));
		}
	}
};

export const updateSectionStateInProposal = async (
	type: MAGIC_TYPES,
	proposalId: number,
	sectionId: string,
	modelText: string,
	htmlContent: string
) => {
	switch (type) {
		case MAGIC_TYPES.COMPLETE:
		case MAGIC_TYPES.SUGGESTION:
			{
				// store.dispatch(stopStreaming());
				const sectionResponse = await updateSectionState(
					proposalId,
					Number(sectionId),
					{
						content: modelText.trim(),
						editorState: htmlContent,
					}
				);
				if (sectionResponse.statusCode === 200) {
					triggerUpdateSection();
					const proposal = (await fetchProposal(proposalId)) as Proposal;

					store.dispatch(setProposal(proposal));
					// call obserable update section state
				}
			}
			break;
		case MAGIC_TYPES.ENHANCE_WITH_ME:
			{
				const sectionResponse = await updateSectionState(
					proposalId,
					Number(sectionId),
					{
						content: modelText.trim(),
						editorState: htmlContent,
					}
				);

				if (sectionResponse.statusCode === 200) {
					triggerUpdateSection();
					store.dispatch(
						updateLastAIResponse({
							content: modelText.trim(),
							sectionId: Number(sectionId),
						})
					);
					const proposal = (await fetchProposal(proposalId)) as Proposal;

					store.dispatch(setProposal(proposal));
				}
			}
			break;
		case MAGIC_TYPES.EXPAND:
		case MAGIC_TYPES.CONCISE:
		case MAGIC_TYPES.ENHANCE: {
			store.dispatch(stopStreaming());
		}
	}
};

export const getSelectionText = (editor: any) => {
	const selection = editor.model.document.selection;
	const range = selection.getFirstRange();

	let text = '';

	for (const item of range.getItems()) {
		text = text + item.data;
	}
	return text;
};

// Utility function to escape special regex characters in the key
const escapeRegExp = (string) => {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const decodeHtmlEntities = (text) => {
	return text
		.replace(/&amp;/g, '&')
		.replace(/&quot;/g, '"')
		.replace(/&#039;/g, "'");
};

export const processEditorState = (text, references) => {
	if (!references || references.length === 0) return text;

	const idMap = {};
	references?.forEach((reference) => {
		if (!idMap[reference.reference]) {
			idMap[reference.reference] = [];
		}
		idMap[reference.reference].push(reference.id);
	});

	const $ = cheerio.load(text);
	const bodyElements = $('*');

	Object.keys(idMap).forEach((key) => {
		const id = idMap[key].join(',');
		const regex = new RegExp(`(${escapeRegExp(key)})`, 'gi');

		bodyElements.each((_, elem) => {
			const elemText = decodeHtmlEntities($(elem).html());
			if (!elemText) return;
			// Only create a new HTML string if there's a match
			if (regex.test(elemText)) {
				const newText = elemText.replace(
					regex,
					`<span class="compliance-reference compliance-color" data-id="${id}">$1</span>`
				);
				$(elem).html(newText);
			}
		});
	});

	return $.html();
};
