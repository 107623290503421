import styles from './OrganizationPage.module.css';
import { cn } from '@/lib/utils';
import { FC, useCallback, useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import OrganizationForm from '@/components/Organization/OrganizationForm/OrganizationForm';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	ErrorHandle,
	getToken,
	handleResponse,
	refreshUserDetails,
} from '@/lib/functions/funcUtils';
import {
	ApiResponse,
	Organization,
	OrganizationProfile,
} from '@/lib/types/apiTypes';
import {
	deleteOrganizationProfile,
	getOrganizationInfo,
	getOrganizationProfiles,
} from '@/lib/functions/apiCalls';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import OrganizationProfileForm from '@/components/Organization/OrganizationProfileForm/OrganizationProfileForm';
import { openDialog } from '@/redux/slices/pageSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import useUser from '@/lib/hooks/useUser';
import { updateProfile } from '@/redux/slices/authSlice';

interface OrganizationPageProps {
	exiting?: boolean;
}

const OrganizationPage: FC<OrganizationPageProps> = ({ exiting }) => {
	const dispatch = useAppDispatch();
	const token = getToken();
	const control = useAnimation();
	const user = useUser();
	const [isLoading, setIsLoading] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [organization, setOrganization] = useState<Organization>();
	const [organizationProfiles, setOrganizationProfiles] =
		useState<OrganizationProfile[]>();
	const isDialogOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'organizationalProfile'
	);

	const startOpeningAnimation = useCallback(() => {
		control.set({ opacity: 0, y: '400px' });
		control.start({
			opacity: 1,
			y: 0,
			transition: { duration: 0.2, delay: 0.3 },
		});
	}, [control]);

	const startExitingAnimation = useCallback(
		(exiting) => {
			if (exiting === true) {
				control.start({ opacity: 0, transition: { duration: 0.25 } });
			}
		},
		[control]
	);

	const getOrganization = async (token: string) => {
		try {
			if (!token) return;
			const response: ApiResponse = await getOrganizationInfo(token);
			const organization = response?.data as Organization;
			setOrganization(organization);
			setInitialLoad(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setInitialLoad(false);
		}
	};

	const getProfilesData = async () => {
		try {
			setIsLoading(true);
			const response: ApiResponse = await getOrganizationProfiles();
			const organizationProfiles = response?.data as Organization[];
			setOrganizationProfiles(organizationProfiles);
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleOrganizationSubmitSuccess = (
		updatedOrganization: Organization
	) => {
		setOrganization({
			...organization,
			...updatedOrganization,
		} as Organization);
	};

	const handleOrganizationProfileSubmitSuccess = () => {
		getProfilesData();
	};

	const handleOrganizationProfileDeletion = async (profileId) => {
		try {
			setIsLoading(true);
			const response = await deleteOrganizationProfile(profileId);
			handleResponse(response, 'Organization profile deleted successfully.');
			getProfilesData();
			setIsLoading(false);
		} catch (error) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!isDialogOpen) {
			getProfilesData();
		}
	}, [isDialogOpen]);

	useEffect(() => {
		refreshUserDetails(dispatch, updateProfile);
		getOrganization(token);
	}, [token, dispatch]);

	useEffect(() => {
		if (exiting) {
			startExitingAnimation(exiting);
		}
	}, [exiting, startExitingAnimation]);

	useEffect(() => {
		if (!initialLoad) {
			startOpeningAnimation();
		}
	}, [initialLoad, startOpeningAnimation]);

	return (
		<div className={cn(styles.wrapper, 'hidden-scroll')}>
			{initialLoad ? (
				<LoaderTransparent />
			) : (
				<>
					<motion.div
						className="flex flex-col w-full gap-6 h-fit "
						animate={control}
					>
						<div className="flex flex-col gap-4 p-6 bg-white hidden shadow-main rounded-2xl">
							{organization && (
								<Accordion
									type="single"
									collapsible
									defaultValue="organization"
									className="flex flex-col w-full h-full bg-transparent-scroll "
								>
									<AccordionItem value={'organization'} className="border-none">
										<AccordionTrigger className="px-0 py-2 hover:no-underline">
											<h3 className="font-bold text-secondary dynamic-xl-large ">
												Organization Information
											</h3>
										</AccordionTrigger>
										<AccordionContent className="overflow-visible">
											<OrganizationForm
												organization={organization}
												organizationId={organization.id}
												setIsLoading={setIsLoading}
												onSubmitSuccess={handleOrganizationSubmitSuccess}
											/>
										</AccordionContent>
									</AccordionItem>
								</Accordion>
							)}
						</div>
						<div className="flex flex-col gap-2 p-6 bg-white shadow-main rounded-2xl profile-organization">
							{!!organizationProfiles?.length && (
								<>
									<div className="flex flex-col gap-1">
										<h3 className="font-bold text-secondary dynamic-xl-large ">
											Organizational Profiles
										</h3>
										<p className="text-black dynamic-text">
											This is what the AI will be using as an information source
											for your organization
										</p>
									</div>
									<Accordion
										type="single"
										collapsible
										defaultValue={'organization-profile-0'}
										className="flex flex-col w-full h-full bg-transparent-scrol"
									>
										{organizationProfiles?.map((profile, index) => (
											<AccordionItem
												value={`organization-profile-${index}`}
												key={profile.id}
											>
												<AccordionTrigger className="px-0 py-2 hover:no-underline">
													<h3 className="font-bold text-black dynamic-large">
														Profile # {index + 1}: {profile.name}
													</h3>
												</AccordionTrigger>
												<AccordionContent className="overflow-visible">
													<OrganizationProfileForm
														organizationProfile={profile}
														organizationProfileId={profile.id}
														setIsLoading={setIsLoading}
														onSubmitSuccess={
															handleOrganizationProfileSubmitSuccess
														}
														deleteOrganizationProfile={() => {
															handleOrganizationProfileDeletion(profile.id);
														}}
														isDefault={profile?.isDefault}
													/>
												</AccordionContent>
											</AccordionItem>
										))}
									</Accordion>
									{user?.organization.isOrganizationProfileEnabled && (
										<div className="flex justify-end">
											<PrimaryButton
												onClick={() =>
													dispatch(
														openDialog({ type: 'organizationalProfile' })
													)
												}
											>
												Add another profile
											</PrimaryButton>
										</div>
									)}
								</>
							)}
						</div>
					</motion.div>
					{isLoading && <LoaderTransparent />}
				</>
			)}
		</div>
	);
};

export default OrganizationPage;
