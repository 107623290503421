import styles from './NotFoundPage.module.css';
import { cn } from '@/lib/utils';
import { Home } from 'lucide-react';
import { useNavigate } from 'react-router';
import FailureIcon from '@/components/ui/icons/FailureIcon';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import customToast from '@/components/CustomToast/CustomToast';

const NotFoundPage = () => {
	const navigate = useNavigate();
	return (
		<div
			className={cn(styles.container, 'flex h-screen w-screen')}
			role="alert"
		>
			<div className="flex flex-col bg-white w-[700px] 2xl:w-[800px] gap-4 p-6 m-auto rounded-3xl items-center">
				<FailureIcon />
				<div className="flex flex-col items-center">
					<h2 className="font-bold text-black dynamic-xl-large">Oops!</h2>
					<h2 className="font-bold text-black dynamic-xl-large">
						404... Page not found.
					</h2>
				</div>
				<div className="flex flex-col items-center">
					<p className="text-black dynamic-medium">
						We couldn't find the page that you're looking for.
					</p>
					<p className="text-black dynamic-medium">
						Please check the address and try again.
					</p>
				</div>
				<PrimaryButton
					className="flex items-center"
					onClick={() => {
						customToast.dismiss();
						navigate('/');
					}}
				>
					<Home size={18} />
					Go to Home
				</PrimaryButton>
			</div>
		</div>
	);
};

export default NotFoundPage;
