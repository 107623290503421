import { FC } from 'react';
import { cn } from '@/lib/utils';
import CommentCard from '../CommentCard/CommentCard';
import CommentImage from '../../ui/icons/new/CommentImage';
import { Comment } from '@/lib/types/apiTypes';
import { motion } from 'framer-motion';
import { containerDivFadeIn } from '@/constants/variants';

interface CommentsListProps {
	proposalId: number;
	sectionId: number;
	comments: Comment[] | null;
	fetchComments: (countUpdated?: boolean) => void;
	setLoading: (loading: boolean) => void;
}

const CommentsList: FC<CommentsListProps> = ({
	proposalId,
	sectionId,
	comments,
	fetchComments,
	setLoading,
}) => {
	return (
		<div
			className={cn(
				'flex flex-col h-full flex-1 overflow-y-auto hidden-scroll'
			)}
		>
			{comments?.length ? (
				comments.map((comment, index) => {
					return (
						<CommentCard
							key={comment.id}
							proposalId={proposalId}
							sectionId={sectionId}
							comment={comment}
							fetchComments={fetchComments}
							alias={`comment-${index}}`}
							setLoading={setLoading}
						/>
					);
				})
			) : (
				<motion.div
					className="flex flex-col items-center justify-center h-full gap-6 xl:gap-8"
					variants={containerDivFadeIn}
					initial="initial"
					animate="animate"
				>
					<CommentImage />
					<div className="text-center">
						<h4 className="dynamic-large text-black font-medium">
							No comments yet
						</h4>
						<p className="dynamic-text text-black">
							Be the first one to add comments
						</p>
					</div>
				</motion.div>
			)}
		</div>
	);
};

export default CommentsList;
