const CommentImage = () => {
	return (
		<svg
			width="254"
			height="195"
			viewBox="0 0 254 195"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_4689_8912)">
				<path
					d="M116.395 97.0049H9.99469C6.16183 97.0049 3.05469 100.112 3.05469 103.945C3.05469 107.778 6.16183 110.885 9.99469 110.885H116.395C120.228 110.885 123.335 107.778 123.335 103.945C123.335 100.112 120.228 97.0049 116.395 97.0049Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M116.395 130.725H9.99469C6.16183 130.725 3.05469 133.832 3.05469 137.665C3.05469 141.497 6.16183 144.605 9.99469 144.605H116.395C120.228 144.605 123.335 141.497 123.335 137.665C123.335 133.832 120.228 130.725 116.395 130.725Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M116.395 164.445H9.99469C6.16183 164.445 3.05469 167.552 3.05469 171.385C3.05469 175.218 6.16183 178.325 9.99469 178.325H116.395C120.228 178.325 123.335 175.218 123.335 171.385C123.335 167.552 120.228 164.445 116.395 164.445Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M229.775 150.935H210.285V191.885L184.305 150.935H102.705C91.0152 150.935 81.5352 141.455 81.5352 129.765V43.3546C81.5352 31.6646 91.0152 22.1846 102.705 22.1846H229.775C241.465 22.1846 250.945 31.6646 250.945 43.3546V129.765C250.945 141.455 241.465 150.935 229.775 150.935Z"
					fill="#E3EEFF"
					stroke="#AFC1DB"
					strokeWidth="4.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M180.634 82.8844H169.904V72.1544C169.904 70.1244 168.264 68.4844 166.234 68.4844C164.204 68.4844 162.564 70.1244 162.564 72.1544V82.8844H151.834C149.804 82.8844 148.164 84.5244 148.164 86.5544C148.164 88.5844 149.804 90.2244 151.834 90.2244H162.564V100.954C162.564 102.984 164.204 104.624 166.234 104.624C168.264 104.624 169.904 102.984 169.904 100.954V90.2244H180.634C182.664 90.2244 184.304 88.5844 184.304 86.5544C184.304 84.5244 182.664 82.8844 180.634 82.8844Z"
					fill="#AFC1DB"
				/>
				<path
					d="M58.8245 60.745L67.9845 65.755L58.8245 70.765L53.8145 79.925L48.8045 70.765L39.6445 65.755L48.8045 60.745L53.8145 51.585L58.8245 60.745Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M35.8547 18.7852L51.5247 27.3552L35.8547 35.9152L27.2947 51.5852L18.7247 35.9152L3.05469 27.3552L18.7247 18.7852L27.2947 3.11523L35.8547 18.7852Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4689_8912">
					<rect
						width="252.85"
						height="193.73"
						fill="white"
						transform="translate(0.574219 0.634766)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default CommentImage;
