import customToast from '@/components/CustomToast/CustomToast';

import {
	AuditLogsPayload,
	ComplianceMatrix,
	ComplianceRule,
	ErrorResponse,
	LoginResponse,
	MAGIC_TYPES,
	Proposal,
	RFP,
	Section,
	ToneOfVoiceFormData,
	ToneOfVoicePayload,
	User,
	ViewProposalTypes,
} from '../types/apiTypes';
import { getProposalById, getUserDetails } from './apiCalls';
import { clearProposal, processSuccess } from '@/redux/slices/proposalSlice';
import { AppDispatch } from '../hooks/hooks';
import { logout, loginSuccess } from '@/redux/slices/authSlice';
import { triggerTrialBanner, setLastSection } from '@/redux/slices/pageSlice';
import { resetMatrix } from '@/redux/slices/complianceMatrixSlice';
import { resetSuggestions } from '@/redux/slices/suggestionsSlice';
import { clearSolicitation } from '@/redux/slices/solicitationSlice';
import { clearQuestion } from '@/redux/slices/questionSlice';
import { clearBrainStorm } from '@/redux/slices/brainStormSlice';
import { DateRange } from 'react-day-picker';
import { differenceInDays, format, subDays } from 'date-fns';
import { FileRejection } from 'react-dropzone';
import { resetTour } from '@/redux/slices/tourSlice';
import { NavigateFunction } from 'react-router';
import { SectionBuilder } from '../types/TemplateBuilder/templateTypes';
import { clearEnhanceSlice } from '@/redux/slices/enhanceSlice';
import { autoCompelteSection } from './TemplateBuilder/templateCalls';
import { cloneDeep } from 'lodash';

type ToastType = 'error' | 'success' | 'info' | 'warning';

interface ProposalResponse {
	proposal?: Proposal;
}

export const showToast = (
	type: ToastType,
	message: string,
	duration?: number
) => {
	switch (type) {
		case 'error':
			customToast.error({ title: message, duration });
			break;
		case 'success':
			customToast.success({ title: message, duration });
			break;
		case 'warning':
			customToast.warning({ title: message, duration });
			break;
		default:
			customToast.info({ title: message, duration });
			break;
	}
};

export const handleGeneralError = (error: Error) => {
	showToast('error', error.message);
};

export const handleApiError = (error: ErrorResponse) => {
	switch (error.statusCode) {
		case 429:
			showToast(
				'error',
				`Looks like I've baked too many ideas at once. Waiting for the oven to free up. We'll be cooking again soon!`,
				6000
			);
			break;
		default:
			showToast('error', error.message);
			break;
	}
};

export const handleUnexpectedError = () => {
	const errorMessage = 'An unexpected error occurred. Please try again.';
	showToast('error', errorMessage);
};

export const fetchProposal = async (id: number) => {
	const proposalResponse = await getProposalById(id);
	if (proposalResponse.statusCode === 200) {
		const data = proposalResponse.data as ProposalResponse;
		return data.proposal;
	}
};

export const findSection = (
	sections: Section[],
	id: string
): Section | null => {
	if (!sections) return null;
	for (const section of sections) {
		if (section.id.toString() === id) {
			return section;
		} else if (section.children && section.children.length > 0) {
			const foundSection = findSection(section.children, id);
			if (foundSection) return foundSection;
		}
	}
	return null;
};

export const handleResponse = (response, successMessasge?, duration?) => {
	if (response.statusCode >= 400 && response.statusCode <= 599) {
		throw response; // Throw the response if status is 400s or 500s
	} else if (successMessasge) {
		showToast('success', successMessasge, duration);
	}
};

export const ErrorHandle = (dispatch: AppDispatch, error: unknown) => {
	if ((error as any)?.response?.data?.statusCode) {
		const apiError = (error as any)?.response?.data as ErrorResponse;
		handleApiError(apiError);
		dispatch(processSuccess());
	} else if (error instanceof Error) {
		handleGeneralError(error);
		dispatch(processSuccess());
	} else if (
		typeof error === 'object' &&
		error !== null &&
		'message' in error
	) {
		const apiError = error as ErrorResponse;
		handleApiError(apiError);
		dispatch(processSuccess());
	} else {
		handleUnexpectedError();
		dispatch(processSuccess());
	}
};

export const getToken = () => {
	const persistedRoot = localStorage.getItem('persist:root');

	if (persistedRoot) {
		const rootObject = JSON.parse(persistedRoot);
		const authObject = JSON.parse(rootObject.auth);
		const token = authObject.token;

		return token;
		// config.headers.Authorization = `Bearer ${token}`;
	}
};

export function convertToISO8601(dateString: string): string {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
	const day = date.getDate().toString().padStart(2, '0');

	return `${year}-${month}-${day}`;
}

export const convertTimestamp = (timestamp, showOnlyDate = false) => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const date = new Date(timestamp);
	const day = date.getDate();
	const month = months[date.getMonth()];
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 || 12; // Convert 24h to 12h format
	const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

	return `${day} ${month} ${year}${showOnlyDate ? '' : ` ${formattedHours}:${formattedMinutes} ${ampm}`}`;
};

export const combineSectionIntoArray = (section: Section) => {
	const combinedArray = [
		...section.notes,
		...section.sectionDocuments.map((document) => {
			return {
				...document.document,
				user: document.user,
				type: 'document',
				...(document?.attachedAs && {
					attachment: {
						attachedAs: document.attachedAs,
					},
				}),
			};
		}),
	];
	combinedArray.sort((a, b) => {
		return getDate(b.createdAt) - getDate(a.createdAt);
	});
	return combinedArray;
};

export function getWordCount(text: string | undefined) {
	return text?.trim().split(/\s+/).filter(Boolean).length || 0;
}

export function getCharacterCount(text: string | undefined) {
	return text?.trim().length || 0;
}

export const cropText = (text: string, maxLength: number) => {
	if (!text) return text;
	if (text?.length > maxLength) {
		return text.substring(0, maxLength) + '...';
	}
	return text;
};

export const formatToneOfVoicePayload = (
	formData: ToneOfVoiceFormData
): ToneOfVoicePayload => {
	return {
		settings: {
			toneOfVoice: {
				tone: [
					{
						name: 'Analytical',
						percentage: formData.analytical,
					},
					{
						name: 'Authoritative',
						percentage: formData.authoritative,
					},
					{
						name: 'Concise',
						percentage: formData.concise,
					},
					{
						name: 'Emotive',
						percentage: formData.emotive,
					},
				],
				sampleText: formData.sampleText || null,
				writingStyle: {
					name: formData.writingStyle.name || null,
					exampleText: formData.writingStyle.exampleText || null,
				},
				citationFormat: formData.citationFormat || null,
			},
		},
	};
};

export const typeWithInterval = (
	textField,
	message: string,
	intervalTime: number = 50
) => {
	let index = 0;
	const interval = setInterval(() => {
		if (index < message.length) {
			if (textField?.current) {
				switch (textField.current.nodeName) {
					case 'DIV':
						textField.current.textContent += message[index];
						break;
					case 'TEXTAREA':
						textField.current.value += message[index];
						break;
				}
			}
			index++;
		} else {
			clearInterval(interval);
		}
	}, intervalTime);

	return interval;
};

export const scrollToSection = (
	sectionId: string,
	scrollParentRef?: React.RefObject<HTMLDivElement>
) => {
	const scrollParentRefElem =
		scrollParentRef?.current ||
		(document.getElementById('writeCardWrapper') as HTMLDivElement);
	const getReferenceElementPosition = (): number => {
		return (
			scrollParentRefElem?.children[0]?.children[0]?.getBoundingClientRect()
				?.top ||
			scrollParentRefElem?.children[0]?.getBoundingClientRect()?.top ||
			0
		);
	};

	const getSectionOffsetTop = (sectionId: string): number => {
		const sectionTopPosition =
			document.getElementById(sectionId)?.getBoundingClientRect()?.top || 0;
		const referenceElementPosition = getReferenceElementPosition();

		return sectionTopPosition && referenceElementPosition
			? sectionTopPosition - referenceElementPosition - 20
			: 0;
	};

	scrollParentRefElem &&
		scrollParentRefElem?.scrollTo({
			top: getSectionOffsetTop(sectionId.toString()),
			behavior: 'smooth',
		});
};

export const getRoleName = (roleId: number) => {
	switch (roleId) {
		case 1:
		case 2:
			return 'Admin';
		case 3:
			return 'Member';
		case 4:
			return 'Guest';
		default:
			return 'Unknown Role';
	}
};

export const getDate = (date) => {
	return new Date(date).getTime();
};

export const constructGetQueryForStringParams = (
	identifier: string,
	list?: string[] | string
): string => {
	if (!list) {
		return '';
	}
	const queryList = Array.isArray(list) ? list : [list];
	return queryList.reduce((accumulator, email) => {
		return `${accumulator}&${identifier}=${email}`;
	}, '');
};

export const constructGetQueryForDateRange = (
	startIdentifier: string,
	endIdentifier: string,
	date?: DateRange
) => {
	const startDateFilter = date?.from
		? `&${startIdentifier}=${format(date.from, 'yyyy-MM-dd')}`
		: '';
	const endDateFilter = date?.to
		? `&${endIdentifier}=${format(date.to, 'yyyy-MM-dd')}`
		: '';
	return `${startDateFilter}${endDateFilter}`;
};

export const constructGetQueryForAuditLogs = (payload: AuditLogsPayload) => {
	const dateRangeQuery = constructGetQueryForDateRange(
		'startDate',
		'endDate',
		payload.date
	);
	const emailsQuery = constructGetQueryForStringParams(
		'userEmails[]',
		payload.userEmails
	);
	const categoryQuery = constructGetQueryForStringParams(
		'category[]',
		payload.category
	);
	const statusQuery = constructGetQueryForStringParams(
		'status[]',
		payload.status
	);
	const sortOrder = payload.sortOrder ? `&sortOrder=${payload.sortOrder}` : '';

	return `${dateRangeQuery}${emailsQuery}${categoryQuery}${statusQuery}${sortOrder}`;
};

export const createFormDataFromJSON = (formData: any): FormData => {
	const formdata = new FormData();
	const keys = Object.keys(formData);
	keys.forEach((key) => {
		if (Array.isArray(formData[key])) {
			formData[key].forEach((value) => {
				formdata.append(key, value);
			});
		} else {
			formdata.append(key, formData[key]);
		}
	});
	return formdata;
};

export const clearState = (dispatch: any) => {
	dispatch(logout());
	dispatch(resetTour());
	dispatch(clearProposal());
	localStorage.clear();
	dispatch(setLastSection({ proposalId: null, sectionId: null }));
	dispatch(resetMatrix());
	dispatch(resetSuggestions());
	dispatch(clearSolicitation());
	dispatch(clearQuestion());
	dispatch(clearBrainStorm());
	dispatch(triggerTrialBanner(false));
	dispatch(clearEnhanceSlice());
};

export const constructMagicPayload = (
	magicType: MAGIC_TYPES,
	additionParams: any
) => {
	switch (magicType) {
		case MAGIC_TYPES.SUGGESTION:
			return {
				magicType: magicType,
				...additionParams,
			};
		case MAGIC_TYPES.EXPAND:
			return {
				magicType: magicType,
				...additionParams,
			};
		case MAGIC_TYPES.CONCISE:
			return {
				magicType: magicType,
				...additionParams,
			};
		case MAGIC_TYPES.ENHANCE:
			return {
				magicType: magicType,
				...additionParams,
			};
		case MAGIC_TYPES.ENHANCE_WITH_ME:
			return {
				magicType: magicType,
				...additionParams,
			};
		default:
			return {
				magicType: magicType,
			};
	}
};

export const flattenObjectValuesToArray = (object) => {
	return object ? Object.values(object).flat() : [];
};

export const handleFileRejections = ({
	fileRejections,
	fileType,
	fileSize,
}: {
	fileRejections: FileRejection[];
	fileType?: string;
	fileSize?: string;
}) => {
	const error = fileRejections?.[0]?.errors?.[0];
	if (error?.code) {
		switch (error?.code) {
			case 'file-too-large':
				showToast(
					'error',
					`The file you're trying to upload is too large. Please select a file smaller than ${fileSize || '20 MB'}`
				);
				break;
			case 'file-invalid-type':
				showToast(
					'error',
					`You may be trying to upload an invalid document format (such as doc) please upload a valid document format such as ${fileType || `pdf or docx`}.`
				);
				break;
			default:
				showToast('error', error?.message);
				break;
		}
	}
};

export const handleLoginResponse = (
	dispatch: AppDispatch,
	navigate: NavigateFunction,
	loginResponse: LoginResponse,
	trialBannerDelayDate: string | null
) => {
	dispatch(
		loginSuccess({
			token: loginResponse.accessToken,
			user: loginResponse.user,
		})
	);
	if (loginResponse.user.organization.plan.type === 'trial') {
		handleShowTrialBanner(
			dispatch,
			loginResponse.user.organization.plan.endDate,
			trialBannerDelayDate
		);
	}
	navigate('/');
};

// Function to recursively transform sections
export const transformSectionsRecursively = (sections: Section[]) => {
	const transformedSections: any = [];

	const traverseSections = (section) => {
		transformedSections.push({
			label: section.name,
			value: section.id.toString(),
		});

		if (section.children && section.children.length > 0) {
			section.children.forEach((child) => {
				traverseSections(child);
			});
		}
	};

	sections.forEach((section) => {
		traverseSections(section);
	});

	return transformedSections;
};

export const mapRulesBySection = (
	complianceMatrix: ComplianceMatrix
): Record<number, ComplianceRule[]> => {
	const sectionsRules: Record<number, ComplianceRule[]> = {};
	complianceMatrix.rules?.forEach((rule) => {
		rule.sections.forEach((section) => {
			if (!sectionsRules[section.id]) {
				sectionsRules[section.id] = [];
			}
			sectionsRules[section.id].push(rule);
		});
	});
	return sectionsRules;
};

export const getDeadline = (
	type: ViewProposalTypes,
	rfp: RFP,
	proposal?: Proposal,
	solicitationRFP?: RFP
) => {
	switch (type) {
		case 'proposal':
			return rfp?.metaData.deadline && proposal?.deadline
				? rfp?.metaData.deadline ?? proposal?.deadline
				: null;
		case 'solicitation':
			return rfp?.metaData.deadline && solicitationRFP?.metaData.deadline
				? rfp?.metaData.deadline ?? solicitationRFP?.metaData.deadline
				: null;
	}
};

export const getFunding = (
	type: ViewProposalTypes,
	rfp: RFP,
	proposal?: Proposal,
	solicitationRFP?: RFP
) => {
	switch (type) {
		case 'proposal':
			return rfp?.metaData.funding && proposal?.rfpFunding
				? rfp?.metaData.funding ?? proposal?.rfpFunding
				: null;
		case 'solicitation':
			return rfp?.metaData.funding && solicitationRFP?.metaData.funding
				? rfp?.metaData.funding ?? solicitationRFP?.metaData.funding
				: null;
	}
};

export const getSectionByName = (name: string, rfp: RFP) => {
	if (!rfp) return null;
	return rfp?.sections.find((sectionItem) => sectionItem.name === name);
};

export const refreshUserDetails = async (dispatch, updateProfile) => {
	try {
		const userResponse = await getUserDetails();
		handleResponse(userResponse);
		dispatch(updateProfile({ user: userResponse.data as User }));
	} catch (error: unknown) {
		ErrorHandle(dispatch, error);
	}
};

export const calculateProposalTotalWordCount = (
	sections: Section[]
): number => {
	const calculateSectionWordCount = (section: Section): number => {
		let totalWordCount =
			section.type === 'logical-framework' ? 0 : section.wordCount;

		if (section.children && section.children.length > 0) {
			section.children.forEach((child) => {
				totalWordCount += calculateSectionWordCount(child);
			});
		}

		return totalWordCount;
	};

	return sections?.reduce((total, section) => {
		return total + calculateSectionWordCount(section);
	}, 0);
};

export const calculateTemplateTotalWordCount = (
	sections: SectionBuilder[]
): number => {
	const calculateSectionWordCount = (section: SectionBuilder): number => {
		let totalWordCount = section.wordCount;

		if (section.children && section.children.length > 0) {
			section.children.forEach((child) => {
				totalWordCount += calculateSectionWordCount(child);
			});
		}

		return totalWordCount;
	};

	return sections?.reduce((total, section) => {
		return total + calculateSectionWordCount(section);
	}, 0);
};

export const calculateProposalTotalCharCount = (
	sections: Section[]
): number => {
	const calculateSectionCharCount = (section: Section): number => {
		let totalCharCount = section.content?.length || 0;

		if (section.children && section.children.length > 0) {
			section.children.forEach((child) => {
				totalCharCount += calculateSectionCharCount(child);
			});
		}

		return totalCharCount;
	};

	return sections?.reduce((total, section) => {
		return total + calculateSectionCharCount(section);
	}, 0);
};

export const getAllSections = (sections?: Section[]): Section[] => {
	let allSections: Section[] = [];
	if (!sections) return [];

	sections.forEach((section) => {
		allSections.push(section); // Add the current section

		// If the section has children, recursively add them
		if (section.children && section.children.length > 0) {
			allSections = allSections.concat(getAllSections(section.children));
		}
	});

	return allSections;
};

export const generateSectionDetailsWithAI = async (
	dispatch: AppDispatch,
	questionText: string,
	wordCount: number,
	organizationIdentifier
) => {
	try {
		const defaultDescription =
			"This section should include clear and concise information that aligns with the project's overall goals and requirements, ensuring that the information provided is both relevant and actionable.";

		const autoCompleteResponse = await autoCompelteSection({
			name: questionText,
			description: defaultDescription,
			wordCount: wordCount,
			expectedOutput: 'text',
		});
		handleResponse(autoCompleteResponse);
		const responseData = (autoCompleteResponse.data as any)
			?.autoCompleteResults;

		const requiredRfpSections = cloneDeep(responseData.solicitation);
		delete responseData.solicitation;
		return {
			...responseData,
			isLive: true,
			isPristine: true,
			isNew: false,
			type: 'default',
			tableHeadings: null,
			expectedOutput: 'text',
			organizationIdentifier: organizationIdentifier,
			requiredRfpSections: requiredRfpSections,
			children: [],
			prerequisites: [],
			systemName: `${responseData.alias.toLowerCase()}_${Date.now()}`,
			level: 0,
			order: 0,
			wordCount: wordCount,
			...(responseData.specialContext && { specialContext: null }),
		};
	} catch (err) {
		ErrorHandle(dispatch, err);
	}
};

export const handleShowTrialBanner = (
	dispatch: AppDispatch,
	endDate: string,
	trialBannerDelayDate: string | null
) => {
	const today = new Date();
	const trialEndDate = new Date(endDate);

	// If user has not clicked "Later" (i.e., no delay date)
	if (trialBannerDelayDate === null) {
		dispatch(triggerTrialBanner(true));
		return;
	}

	// Parse the trialBannerDelayDate (if it exists)
	const delayedDate = new Date(trialBannerDelayDate);

	// Difference in days between today and the trial end date
	const daysLeft = differenceInDays(trialEndDate, today);

	// If it's the last 2 days, and trialBanner later is clicked in less than 2 days before the trial end date
	if (daysLeft === 1 && delayedDate <= subDays(trialEndDate, 2)) {
		dispatch(triggerTrialBanner(true));
	}

	// If it's the last day, and trialBanner later is clicked in less than 1 day before the trial end date
	if (daysLeft === 0 && delayedDate <= subDays(trialEndDate, 1)) {
		dispatch(triggerTrialBanner(true));
	}
};

export const getCKEditorText = (editor) => {
	if (!editor) return '';
	let modelText = '';
	const root = editor.model.document.getRoot();
	for (const child of root.getChildren()) {
		for (const item of child.getChildren()) {
			if (item.is('text')) {
				modelText += item.data + ' ';
			}
		}
	}
	return modelText;
};
