import { FC, useEffect, useRef, useState } from 'react';
import styles from './DecDocumentCard.module.css';
import { DecDocument, ErrorResponse } from '@/lib/types/apiTypes';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	importExternalDocument,
	uploadDocumentInOrganization,
} from '@/lib/functions/apiCalls';
import { openDialog } from '@/redux/slices/pageSlice';
import {
	convertTimestamp,
	ErrorHandle,
	handleApiError,
	handleGeneralError,
	handleResponse,
	showToast,
} from '@/lib/functions/funcUtils';
import { cn } from '@/lib/utils';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
// import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
// import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
// import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import useUser from '@/lib/hooks/useUser';
import { useQueryClient } from 'react-query';
import { CheckCircle2, BookOpenIcon, Replace } from 'lucide-react';
import { Badge } from '@/components/ui/badge';

interface DecDocumentCardProps {
	documentCard: DecDocument;
	folderId?: number;
	setIsLoading: (isLoading: boolean) => void;
}

const DecDocumentCard: FC<DecDocumentCardProps> = ({
	documentCard,
	folderId,
	setIsLoading,
}) => {
	const dispatch = useAppDispatch();
	const user = useUser();
	const queryClient = useQueryClient();

	const [showFullText, setShowFullText] = useState(false);
	const cardRef = useRef<HTMLDivElement | null>(null);
	const textRef = useRef<HTMLParagraphElement | null>(null);

	const documentName = documentCard.title;
	const organizationId = user?.organizationId;

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
				setShowFullText(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handlePDF = async () => {
		if (!documentCard.documentLink) {
			showToast(
				'error',
				'Something went wrong, please reload the page or try again later'
			);
			return;
		}
		setIsLoading(true);
		try {
			const response = await importExternalDocument(documentCard.documentLink);
			if (response) {
				dispatch(openDialog({ type: 'viewPDF', pdf: response as ArrayBuffer }));
				setIsLoading(false);
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				setIsLoading(false);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				setIsLoading(false);
			}
		}
	};

	const handleImport = async () => {
		if (!organizationId) return;
		try {
			setIsLoading(true);
			const docResponse = await importExternalDocument(
				documentCard.documentLink
			);
			handleResponse(docResponse);
			const blob = new Blob([docResponse?.data as ArrayBuffer]);
			const file = new File([blob], `${documentName || 'DECDocument'}.pdf`, {
				type: 'application/pdf',
			});
			const ingestPayload = {
				file: file,
				name: documentName,
				type: 'text',
				category: 'Research',
				decDocumentId: documentCard._id,
				...(folderId && { folderId: folderId }),
			};
			if (!ingestPayload) {
				showToast('error', 'Something went wrong');
				return;
			}
			const ingestResponse = await uploadDocumentInOrganization(ingestPayload);
			handleResponse(
				ingestResponse,
				'Document has been imported into the organization successfully.'
			);
			queryClient.invalidateQueries('decDocuments');
			queryClient.invalidateQueries('documents');
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const categories = documentCard.tags?.[0]
		?.split('~|~_©_~|~')
		?.map((category) => category.trim());

	const locations = documentCard.geographicLocations?.[0]
		?.split('~|~_©_~|~')
		?.map((location) => location.trim());

	return (
		<div
			className={cn(
				styles.card,
				' flex flex-col rounded-xl p-6 cursor-pointer border border-blackSecondary h-fit '
			)}
			ref={cardRef}
		>
			<div
				className={cn(
					'flex flex-col ',
					!showFullText && 'h-[205px] xl:h-[210px] 2xl:h-[230px]'
				)}
			>
				<h4 className="max-h-[46px] text-start text-black dynamic-medium line-clamp-2 overflow-hidden">
					{documentName.length > 80 ? (
						<TooltipProvider delayDuration={50}>
							<Tooltip>
								<TooltipTrigger>
									<h4 className="text-start text-black font-bold dynamic-medium line-clamp-2">
										{documentName}
									</h4>
								</TooltipTrigger>
								<TooltipContent
									className="bg-white text-black dynamic-small max-w-[450px] px-3 py-2 rounded-lg border-none shadow-none drop-shadow-effect"
									align="start"
								>
									{documentName}
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					) : (
						<h4 className="text-start text-black font-bold dynamic-medium line-clamp-2">
							{documentName}
						</h4>
					)}
				</h4>
				{!!documentCard.publicationDate && (
					<p
						ref={textRef}
						className={`h-fit dynamic-small text-black opacity-50 font-normal pt-1`}
					>
						Date: {convertTimestamp(documentCard.publicationDate, true)}
					</p>
				)}
				<p
					ref={textRef}
					className={`flex h-fit dynamic-text text-black font-normal py-1`}
				>
					<span className="font-bold">Category</span>:{' '}
					{categories?.length ? (
						<>
							{categories
								?.slice(0, 3)
								?.map((tag) => (
									<Badge className="dynamic-small font-normal bg-white border-secondary text-secondary mx-1 py-1 px-2 leading-none">
										{tag}
									</Badge>
								))}
							{categories.length > 3 && (
								<TooltipProvider delayDuration={50}>
									<Tooltip>
										<TooltipTrigger>
											<p className="dynamic-badge content-center text-center w-5 h-5 bg-secondary text-white rounded-full">
												{categories.length - 3}+
											</p>
										</TooltipTrigger>
										<TooltipContent
											className="bg-white text-[#6D7D86] text-xs 2xl:text-sm max-w-[350px] px-3 2xl:py-1 rounded-lg border-none shadow-none drop-shadow-effect"
											align="end"
										>
											{categories.slice(3).join(', ')}
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							)}
						</>
					) : (
						' No category found'
					)}
				</p>
				<p
					ref={textRef}
					className={`flex h-fit dynamic-text text-black font-normal py-1`}
				>
					<span className="font-bold">Locations</span>:{' '}
					{documentCard.geographicLocations?.length ? (
						<>
							{locations
								?.slice(0, 3)
								?.map((location, index) => (
									<p
										className={`dynamic-text font-bold text-primary px-2 ${index === 3 || index === documentCard.geographicLocations.length - 1 ? '' : 'border-l border-blackSecondary'}`}
									>
										{location}
									</p>
								))}

							{locations.length > 3 && (
								<TooltipProvider delayDuration={50}>
									<Tooltip>
										<TooltipTrigger>
											<p className="dynamic-badge content-center text-center w-5 h-5 bg-secondary text-white rounded-full">
												{locations.length - 3}+
											</p>
										</TooltipTrigger>
										<TooltipContent
											className="bg-white text-[#6D7D86] text-xs 2xl:text-sm max-w-[350px] px-3 2xl:py-1 rounded-lg border-none shadow-none drop-shadow-effect"
											align="end"
										>
											{locations.slice(3).join(', ')}
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							)}
						</>
					) : (
						' No location found'
					)}
				</p>
				<p
					ref={textRef}
					className={`h-fit text-sm xl:text-base text-black font-normal ${
						!showFullText ? styles.limitedText : ''
					}`}
				>
					{documentCard.summary}
				</p>

				{documentCard.summary?.length <
				(textRef?.current?.offsetWidth || 300) / 2.5 ? (
					<></>
				) : (
					<div>
						{!showFullText ? (
							<TextButton
								className="h-fit 2xl:h-fit w-fit p-0 text-sm xl:text-sm 2xl:text-base mt-1 font-bold"
								onClick={() => setShowFullText(true)}
							>
								Read More
							</TextButton>
						) : (
							<TextButton
								className="h-fit 2xl:h-fit w-fit p-0 text-sm xl:text-sm 2xl:text-base mt-1 font-normal"
								onClick={() => setShowFullText(false)}
							>
								Less
							</TextButton>
						)}
					</div>
				)}
				{/* <div className="flex items-center justify-between mt-4">
				<SecondaryButton
					disabled={!documentCard.documentLink}
					onClick={handlePDF}
				>
					Read Document
				</SecondaryButton>
				<PrimaryButton
					disabled={!documentCard.documentLink || documentCard.isImported}
					onClick={handleImport}
				>
					{documentCard.isImported ? (
						<>
							<CheckCircle2 size={16} /> Imported
						</>
					) : (
						'Import Document'
					)}
				</PrimaryButton>
			</div> */}
			</div>

			<div className={cn(styles.btnContainer)}>
				<div
					className="flex items-center gap-2 right-6 border-t pt-3 mt-3 border-blackSecondary hover-element"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<button
						className="flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
						onClick={handlePDF}
					>
						<BookOpenIcon className="w-4 2xl:w-5" color={'var(--primary)'} />
						Read
					</button>
					<button
						className={cn(
							'flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 cursor-pointer',
							!documentCard.documentLink || documentCard.isImported
								? 'opacity-50'
								: 'hover:bg-lightBackground'
						)}
						disabled={!documentCard.documentLink || documentCard.isImported}
						onClick={handleImport}
					>
						{documentCard.isImported ? (
							<>
								<CheckCircle2
									className="w-4 2xl:w-5"
									color={'var(--primary)'}
								/>{' '}
								Imported
							</>
						) : (
							<>
								<Replace className="w-4 2xl:w-5" color={'var(--primary)'} />{' '}
								Import
							</>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

export default DecDocumentCard;
