import { SVGProps } from 'react';

const Line = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="67"
			height="67"
			viewBox="0 0 67 67"
			fill="none"
		>
			<path
				d="M3.62109 2.63623V62.9055H63.0648"
				stroke="#BDBDBD"
				strokeWidth="1.65444"
			/>
			<path
				d="M11.8789 55.4751L25.7953 34.0093L36.857 44.3721L58.9385 16.2588"
				stroke="#E861C9"
				strokeWidth="2.47682"
			/>
		</svg>
	);
};

export default Line;
