import TableCard from '@/components/TableCard/TableCard';
import { columns as solicitationColumns } from '@/components/Tables/SolicitationTable/columns';

import {
	getAllProposals,
	getAllSolicitations,
	getDocumentById,
	getTeams,
	removeSolicitation,
} from '@/lib/functions/apiCalls';
import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { clearPageState, openDialog } from '@/redux/slices/pageSlice';
import { useNavigate } from 'react-router';
import {
	ProposalTableFilters,
	RFP,
	SortingType,
	Team,
} from '@/lib/types/apiTypes';
import { setSolicitation } from '@/redux/slices/solicitationSlice';
import { clearProposal, updateStep } from '@/redux/slices/proposalSlice';
import { getColumns } from '@/components/Tables/ProposalTable/columns';
import customToast from '@/components/CustomToast/CustomToast';
import useAuth from '@/lib/hooks/useAuth';
import { iCSlideIn } from '@/constants/variants';
import { motion } from 'framer-motion';
import { PaginationState } from '@tanstack/react-table';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import ProposalFiltersDrawer from '@/components/Drawers/ProposalFiltersDrawer/ProposalFiltersDrawer';
import { resetTemplateBuilder } from '@/redux/slices/TemplateBuilder/templateSlice';
import {
	setCurrentStep,
	setJourneyType,
	setUnsolicitation,
} from '@/redux/slices/unsolicitedSlice';
import { clearEnhanceSlice } from '@/redux/slices/enhanceSlice';

interface ProjectBodyProps {}

const ProjectBody: FC<ProjectBodyProps> = () => {
	const uploadedFiles = useSelector(
		(root: RootState) => root.solicitation.files
	);
	const { resetActiveSection } = useActiveSection();
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isProcessing, setIsProcessing] = useState(false);
	const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
	const [proposalPagination, setProposalPagination] = useState<PaginationState>(
		{
			pageIndex: 0,
			pageSize: 5,
		}
	);
	const [proposalSortOrder, setProposalSortOrder] =
		useState<SortingType>('DESC');
	const { isGuest } = useAuth();
	const [proposalFilter, setProposalFilter] = useState<ProposalTableFilters>({
		assignedUsers: [],
		searchTerm: '',
	});
	const { assignedUsers, createdOn, searchTerm } = proposalFilter;

	// Local state for solicitations
	const [localSolicitations, setLocalSolicitations] = useState<any[]>([]);
	const {
		data: solicitations,
		isLoading: isSolicitationLoading,
		isError: isSolicitationError,
	} = useQuery({
		queryKey: 'solicitations',
		queryFn: () => getAllSolicitations(),
		refetchInterval: () => (isProcessing ? 30000 : false),
		refetchOnWindowFocus: false,
	});

	const {
		data: proposalData,
		isLoading: isProposalLoading,
		isRefetching: isProposalRefetching,
		isFetchedAfterMount: isFetchedAfterMount,
		isError: isProposalError,
		refetch: refetchProposal,
	} = useQuery({
		queryKey: 'proposals',
		queryFn: () =>
			getAllProposals({
				pageNumber: proposalPagination.pageIndex + 1,
				pageSize: proposalPagination.pageSize,
				sortOrder: proposalSortOrder,
				assignedUsers: assignedUsers,
				searchTerm: searchTerm,
				...(createdOn ? { createdOn: createdOn } : {}),
			}),
		refetchOnWindowFocus: false,
	});

	const {
		data: teamsData,
		isLoading: isTeamsLoading,
		isError: isTeamsError,
	} = useQuery({
		queryKey: 'teams',
		queryFn: () => getTeams(),
		refetchOnWindowFocus: false,
	});

	// Update local state when global state changes

	useEffect(() => {
		const combinedSolicitations = [
			...uploadedFiles,
			...((solicitations?.data as any)?.solicitations ?? []),
		];

		// Update local state
		setLocalSolicitations(combinedSolicitations);

		// Check for processing status
		const processing = combinedSolicitations.some(
			(solicitation) => solicitation.status === 'processing'
		);
		setIsProcessing(processing);
	}, [solicitations, uploadedFiles]);

	useEffect(() => {
		refetchProposal();
	}, [
		proposalPagination.pageIndex,
		proposalPagination.pageSize,
		proposalSortOrder,
		refetchProposal,
	]);

	useEffect(() => {
		if (proposalPagination.pageIndex > 0) {
			setProposalPagination({
				pageIndex: 0,
				pageSize: proposalPagination.pageSize,
			});
		} else {
			refetchProposal();
		}
	}, [refetchProposal, assignedUsers, createdOn, searchTerm]);

	const handleCreateProposal = async (id: number) => {
		if (id) {
			const response = await getDocumentById(id);
			if (response.statusCode === 200) {
				const rfpResponse = response.data as RFP;
				dispatch(clearProposal());
				dispatch(resetTemplateBuilder());
				resetActiveSection();
				dispatch(clearEnhanceSlice());
				dispatch(setSolicitation(rfpResponse));
				dispatch(clearPageState());
				resetActiveSection();
				if (rfpResponse.category === 'questionnaire') {
					dispatch(updateStep(2));
				}
				navigate('/create');
			}
		}
	};

	const handleCreateManualSolicitation = async (solicitation) => {
		if (solicitation) {
			dispatch(openDialog({ type: 'multiStepUnsolicited' }));
			dispatch(setUnsolicitation(solicitation));
			dispatch(setCurrentStep(1));
			if (solicitation.category === 'questionnaire') {
				dispatch(setJourneyType('questionnaire'));
			}
		}
	};

	const proposals = useMemo(
		() => (proposalData?.data as any)?.proposals,
		[proposalData?.data]
	);
	const proposalTotalPages = useMemo(
		() => (proposalData?.data as any)?.totalPages,
		[proposalData?.data]
	);
	const proposalTotalItems = useMemo(
		() => (proposalData?.data as any)?.totalItems,
		[proposalData?.data]
	);

	const clearProposalFilters = () => {
		setProposalFilter({
			assignedUsers: [],
			searchTerm: searchTerm,
		});
	};

	const setProposalFilters = (filters: ProposalTableFilters) => {
		setProposalFilter({
			assignedUsers: filters.assignedUsers,
			createdOn: filters.createdOn,
			searchTerm: searchTerm,
		});
	};

	const setProposalFiltersSearch = (searchTerm: string) => {
		setProposalFilter({
			assignedUsers: assignedUsers,
			createdOn: createdOn,
			searchTerm: searchTerm,
		});
	};

	if (
		isSolicitationLoading ||
		isProposalLoading ||
		!isFetchedAfterMount ||
		isTeamsLoading
	)
		return <LoaderTransparent />;

	if (isSolicitationError || isProposalError || isTeamsError)
		return <div>Error</div>;

	const handleRemove = async () => {
		try {
			const response = await removeSolicitation({
				documentIds: selectedRowIds.map((id) => Number(id)),
			});
			if (response.statusCode === 201) {
				customToast.success({
					title: `Solicitation removed successfully`,
				});
				queryClient.invalidateQueries(['solicitations']);
				setSelectedRowIds([]);
			}
		} catch (err) {
			console.log(err);
			customToast.error({
				title: 'Something went wrong',
			});
		}
	};

	return (
		<motion.div
			className="flex flex-col gap-10"
			variants={iCSlideIn}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			{!isGuest && (
				<TableCard
					type="solicitation"
					columns={solicitationColumns(
						handleCreateProposal,
						handleCreateManualSolicitation,
						setSelectedRowIds,
						selectedRowIds
					)}
					data={localSolicitations}
					title="Solicitations"
					isRemovedDisabled={selectedRowIds.length === 0}
					handleRemove={handleRemove}
				/>
			)}
			<TableCard
				type="proposal"
				columns={getColumns({})}
				data={proposals}
				title="Proposals in Progress"
				isProposalFetching={isProposalRefetching}
				proposalTotalItems={proposalTotalItems}
				proposalTotalPages={proposalTotalPages}
				proposalPagination={proposalPagination}
				setProposalPagination={setProposalPagination}
				proposalSortOrder={proposalSortOrder}
				setProposalSortOrder={(sortOrder: SortingType) => {
					setProposalPagination({ ...proposalPagination, pageIndex: 0 });
					setProposalSortOrder(sortOrder);
				}}
				setFiltersSearch={setProposalFiltersSearch}
				filtersPanel={
					<ProposalFiltersDrawer
						proposalFilters={proposalFilter}
						appliedFiltersCount={
							(assignedUsers?.length || 0) + (createdOn ? 1 : 0)
						}
						onApply={setProposalFilters}
						onClear={clearProposalFilters}
					/>
				}
				teams={(teamsData?.data as any)?.teams as Team[]}
			/>
		</motion.div>
	);
};

export default ProjectBody;
