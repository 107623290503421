import { FC, useState } from 'react';
import styles from './AssistanceTab.module.css';
import CollapseBox from '../CollapseBox/CollapseBox';
import Suggestion from '../ui/icons/new/Suggestion';
import SuggestionWrapper from '@/containers/SuggestionWrapper/SuggestionWrapper';
import History from '../ui/icons/new/History';
import HistoryWrapper from '@/containers/HistoryWrapper/HistoryWrapper';
import EnhanceWrapper from '@/containers/EnhanceWrapper/EnhanceWrapper';
import Brainstorm from '../ui/icons/new/Brainstorm';

import { cn } from '@/lib/utils';

interface AssistanceTabProps {
	mode?: string;
}

const AssistanceTab: FC<AssistanceTabProps> = ({ mode }) => {
	const [expandedBox, setExpandedBox] = useState<string | null>(
		mode === 'logical-frame' ? 'enhance' : 'Suggestions'
	);

	const handleSetExpanded = (title: string) => {
		if (expandedBox === title) {
			// setExpandedBox(null); // Collapse if the same box is clicked again
		} else {
			setExpandedBox(title);
		}
	};

	return (
		<div className={cn(styles.tab)}>
			{mode !== 'logical-frame' && (
				<CollapseBox
					title="Suggestions"
					icon={<Suggestion />}
					isExpanded={expandedBox === 'Suggestions'}
					setIsExpanded={() => handleSetExpanded('Suggestions')}
					tourClass="suggestions"
				>
					<SuggestionWrapper />
				</CollapseBox>
			)}

			<CollapseBox
				title="Lets Enhance"
				icon={<Brainstorm />}
				isExpanded={expandedBox === 'enhance'}
				setIsExpanded={() => handleSetExpanded('enhance')}
				tourClass="enhance"
			>
				<EnhanceWrapper mode={mode} />
			</CollapseBox>
			{mode !== 'logical-frame' && (
				<CollapseBox
					title="History"
					icon={<History />}
					isExpanded={expandedBox === 'History'}
					setIsExpanded={() => handleSetExpanded('History')}
					tourClass="history"
				>
					<HistoryWrapper />
				</CollapseBox>
			)}
		</div>
	);
};

export default AssistanceTab;
