import PDFViewer from '@/components/PDFViewer/PDFViewer';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { RootState } from '@/redux/store/store';
import { DialogTitle } from '@radix-ui/react-dialog';
import { useSelector } from 'react-redux';

const PDFDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'viewPDF'
	);
	const pdfData = useSelector((root: RootState) => root.page.dialog?.pdf);
	return (
		<Dialog open={isOpen}>
			<DialogContent className="h-[600px] 2xl:h-[800px] max-w-[1000px] 2xl:max-w-[1260px] bg-white p-6 gap-3">
				<DialogHeader>
					<DialogTitle className="flex items-center font-bold dynamic-large text-secondary">
						Document Viewer
					</DialogTitle>
				</DialogHeader>
				<div className="h-auto overflow-hidden">
					<PDFViewer pdfData={pdfData as ArrayBuffer} />
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default PDFDialog;
