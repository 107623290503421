import { FC } from 'react';
import styles from './EssentialDocumentsCard.module.css';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import AISmall from '../ui/icons/new/AISmall';
import { Separator } from '../ui/separator';
import EssentialDocumentListitem from '../EssentialDocumentListItem/EssentialDocumentListitem';
import { cn } from '@/lib/utils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import EmptyEssential from '../ui/icons/new/EmptyEssential';
import { FileUp } from 'lucide-react';

interface EssentialDocumentsCardProps {}

const EssentialDocumentsCard: FC<EssentialDocumentsCardProps> = () => {
	const dispatch = useAppDispatch();
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	return (
		<div className={styles.card}>
			<div className="flex items-center justify-between formative-documents">
				<h4 className="font-bold text-black dynamic-large">
					Essential Documents
				</h4>
				<div className="flex items-center justify-between gap-4">
					<SecondaryButton
						onClick={() =>
							dispatch(
								openDialog({
									type: 'addDocuments',
									addDocumentType: 'attachFormativeDocument',
								})
							)
						}
					>
						<AISmall className=" group/icon-hover:fill-white" />
						Search in your library
					</SecondaryButton>
					<SecondaryButton
						onClick={() =>
							dispatch(
								openDialog({
									type: 'uploadDocument',
									uploadDocumentType: 'attachFormativeDocument',
								})
							)
						}
					>
						<FileUp size={16} />
						Upload a new document
					</SecondaryButton>
				</div>
			</div>
			<Separator
				className={cn(
					'bg-[#C6DBFB]',
					proposal?.formativeDocuments?.length === 0 ? 'mt-6' : 'my-6'
				)}
			/>
			{proposal?.formativeDocuments?.length === 0 ? (
				<div className="flex flex-col items-center gap-4 justify-center min-h-[410px]">
					<EmptyEssential />
					<p className="flex font-bold text-black items-center justify-center">
						You have not added any essential documents yet.
						<a
							onClick={() =>
								dispatch(
									openDialog({
										type: 'addDocuments',
										addDocumentType: 'attachFormativeDocument',
									})
								)
							}
							className="cursor-pointer text-primary hover:text-secondary normal-case pl-0.5"
						>
							Click to add documents
						</a>
					</p>
				</div>
			) : (
				<div className="h-[390px] overflow-y-scroll pr-3">
					<ul className="group/list">
						{proposal?.formativeDocuments?.map((document) => (
							<EssentialDocumentListitem
								key={document.id}
								document={document}
							/>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default EssentialDocumentsCard;
