import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	useReactTable,
	getSortedRowModel,
	getPaginationRowModel,
	PaginationState,
	Updater,
} from '@tanstack/react-table';

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronsLeftIcon,
	ChevronsRightIcon,
} from 'lucide-react';
import { SortingType } from '@/lib/types/apiTypes';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';

interface AuditLogsTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	totalPages: number;
	pagination: PaginationState;
	setPagination: (pagination: Updater<PaginationState>) => void;
	sortOrder: SortingType;
	setSortOrder: (sortOrder: SortingType) => void;
	currentPage: number;
}

export function AuditLogsTable<TData, TValue>({
	columns,
	data,
	totalPages,
	pagination,
	setPagination,
	sortOrder,
	setSortOrder,
	currentPage,
}: AuditLogsTableProps<TData, TValue>) {
	const handleSorting = () => {
		setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');
	};

	const table = useReactTable({
		data,
		columns,
		initialState: {
			pagination: {
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
			},
		},
		pageCount: totalPages,
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: handleSorting,
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,
		manualPagination: true,
	});

	return (
		<div>
			<Table>
				<TableHeader className=" bg-blackSecondary">
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id} className="p-4 text-lg text-black">
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody className="overflow-y-auto text-base text-black bg-white">
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow
								className="transition hover:bg-lightBackground"
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<div className="flex items-center justify-between px-2 mt-4">
				<div className="flex items-center gap-4">
					<div className="flex items-center space-x-2">
						<p className="text-sm font-medium text-black">Rows per page</p>
						<Select
							value={`${pagination.pageSize}`}
							onValueChange={(value) => {
								setPagination({
									pageIndex: 0,
									pageSize: Number(value),
								});
							}}
						>
							<SelectTrigger className="h-8 w-[70px] px-2">
								<SelectValue placeholder={pagination.pageSize} />
							</SelectTrigger>
							<SelectContent side="top" className="bg-white">
								{[8, 15, 25, 50, 100].map((pageSize) => (
									<SelectItem key={pageSize} value={`${pageSize}`}>
										{pageSize}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
					<div className="flex items-center justify-center text-xs text-black">
						Page {currentPage} of {totalPages}
					</div>
				</div>
				<div className="flex items-center space-x-2">
					<Button
						className="hidden w-8 h-8 p-0 lg:flex"
						onClick={() => table.setPageIndex(0)}
						disabled={!pagination.pageIndex}
					>
						<span className="sr-only">Go to first page</span>
						<ChevronsLeftIcon className="w-4 h-4 text-white" />
					</Button>
					<Button
						// variant={'white'}
						className="w-8 h-8 p-0"
						onClick={() => table.previousPage()}
						disabled={!pagination.pageIndex}
					>
						<span className="sr-only">Go to previous page</span>
						<ChevronLeftIcon className="w-4 h-4 text-white" />
					</Button>
					<Button
						// variant={'white'}
						className="w-8 h-8 p-0"
						onClick={() => table.nextPage()}
						disabled={pagination.pageIndex >= totalPages - 1}
					>
						<span className="sr-only">Go to next page</span>
						<ChevronRightIcon className="w-4 h-4 text-white" />
					</Button>
					<Button
						// variant={'white'}
						className="hidden w-8 h-8 p-0 lg:flex "
						onClick={() => table.setPageIndex(table.getPageCount() - 1)}
						disabled={pagination.pageIndex >= totalPages - 1}
					>
						<span className="sr-only">Go to last page</span>
						<ChevronsRightIcon className="w-4 h-4 text-white" />
					</Button>
				</div>
			</div>
		</div>
	);
}
