import { SVGProps } from 'react';

const Document = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="20"
			viewBox="0 0 16 20"
			fill="none"
			{...props}
		>
			<path
				d="M4.32006 1.00289H13.9519C14.1672 1.00289 14.3736 1.08838 14.5257 1.24057C14.6779 1.39275 14.7634 1.59916 14.7634 1.81438V18.1885C14.7634 18.4037 14.6779 18.6101 14.5257 18.7623C14.3736 18.9145 14.1672 19 13.9519 19H2.19431C1.97909 19 1.77268 18.9145 1.6205 18.7623C1.46831 18.6101 1.38282 18.4037 1.38282 18.1885V3.93653C1.38248 3.82939 1.40333 3.72325 1.44419 3.62422C1.48504 3.52518 1.54508 3.4352 1.62085 3.35946L3.743 1.23732C3.81879 1.16168 3.9088 1.10177 4.00783 1.06104C4.10687 1.02031 4.21298 0.999571 4.32006 1.00001V1.00289Z"
				stroke="#5D9BFD"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.5957 3.95436H4.16074C4.21214 3.95445 4.26306 3.94441 4.31058 3.92481C4.35809 3.9052 4.40128 3.87641 4.43766 3.8401C4.47404 3.80379 4.5029 3.76065 4.52259 3.71317C4.54229 3.66569 4.55242 3.6148 4.55242 3.5634V1.01855"
				stroke="#5D9BFD"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Document;
