import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { RootState } from '@/redux/store/store';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';

import { useSelector } from 'react-redux';
import { Badge } from '@/components/ui/badge';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { useMemo } from 'react';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { Pencil, Trash2 } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { cn } from '@/lib/utils';

const ComplianceRuleDetails = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'viewComplianceRuleDetail'
	);
	const sectionRules = useSelector(
		(root: RootState) => root.compliance.sectionsRules
	);
	const ruleId = useSelector(
		(root: RootState) => root.page.dialog?.complianceRuleId
	);
	const { activeSection } = useActiveSection();

	const dispatch = useAppDispatch();

	const currentRule = useMemo(() => {
		if (!ruleId || !sectionRules || !activeSection.sectionId) return null;
		return sectionRules[activeSection.sectionId as number]?.find(
			(rule) => rule.id === ruleId
		);
	}, [sectionRules, activeSection.sectionId, ruleId]);

	const references = useMemo(() => {
		const referenceString =
			currentRule?.sections[0]?.sectionCompliance?.reference;
		return typeof referenceString === 'string'
			? referenceString.split(':')
			: [];
	}, [currentRule]);

	const defaultValue = ['item-1', 'item-2'];

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[1050px] p-6 pr-6 rounded-2xl shadow-main bg-white">
				<DialogHeader>
					<p className="font-bold dynamic-large text-secondary">Tell me more</p>
				</DialogHeader>
				<div
					className={cn(
						currentRule?.sections[0]?.sectionCompliance?.reference &&
							references?.length > 0
							? 'h-[500px] pr-4 overflow-y-scroll'
							: ''
					)}
				>
					<Accordion type="multiple" defaultValue={defaultValue}>
						{references.length > 0 &&
							currentRule?.sections[0].sectionCompliance.status ===
								'addressed' && (
								<AccordionItem value="item-1">
									<AccordionTrigger className="py-2 font-bold text-black dynamic-medium">
										Section Statement(s)
									</AccordionTrigger>
									<AccordionContent className="pb-3 text-black ">
										<p>
											The following text addresses the selected compliance
											requirement.
										</p>
										<ul className="flex flex-col gap-1 mt-3 ml-4 list-disc">
											{references?.map((reference) => (
												<li key={reference}>{reference}</li>
											))}
										</ul>
									</AccordionContent>
								</AccordionItem>
							)}

						<AccordionItem value="item-2" className="mt-2">
							<AccordionTrigger className="py-2 font-bold text-black dynamic-medium">
								Compliance Detail
							</AccordionTrigger>
							<AccordionContent className="pb-3 text-black ">
								<h2 className="mb-2 font-bold">Solicitation Requirements</h2>
								<p>{currentRule?.statement}</p>
								<h2 className="my-2 font-bold">Solicitation Section</h2>
								<p>{currentRule?.solicitationReference}</p>
								<h2 className="mt-2 mb-2 font-bold">Proposal Section</h2>
								<div className="flex flex-wrap gap-2">
									{currentRule?.sections.map((section) => (
										<Badge
											key={section.id}
											className="font-medium bg-white border-primary text-primary"
										>
											{section.name}
										</Badge>
									))}
								</div>
								<h2 className="mt-3 mb-1 font-bold">Explanation</h2>
								<p>{currentRule?.explanation}</p>
								<div className="flex items-center justify-end gap-3 mt-4">
									<SecondaryButton
										onClick={() =>
											dispatch(
												openDialog({
													type: 'deleteMatrixRule',
													complianceRuleId: currentRule?.id,
													complianceMode: 'section',
												})
											)
										}
									>
										<Trash2 size={16} />
										Delete
									</SecondaryButton>
									<SecondaryButton
										onClick={() =>
											dispatch(
												openDialog({
													type: 'editMatrixRule',
													complianceRuleId: currentRule?.id,
													complianceMode: 'section',
												})
											)
										}
									>
										<Pencil size={16} />
										Edit
									</SecondaryButton>
								</div>
							</AccordionContent>
						</AccordionItem>
					</Accordion>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ComplianceRuleDetails;
