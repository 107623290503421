import { SVGProps } from 'react';

const Pie = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="67"
			height="67"
			viewBox="0 0 67 67"
			fill="none"
		>
			<circle cx="34.9117" cy="33.5963" r="27.6578" fill="#A8CAFF" />
			<path
				d="M62.5696 33.5963C62.5696 27.542 60.583 21.655 56.9148 16.8386C53.2465 12.0221 48.0993 8.54238 42.2627 6.93325C36.4262 5.32413 30.223 5.67454 24.6048 7.93072C18.9866 10.1869 14.264 14.2242 11.1615 19.4231C8.05896 24.622 6.74804 30.6953 7.42985 36.7111C8.11167 42.7268 10.7485 48.3527 14.9359 52.7254C19.1232 57.0981 24.6296 59.976 30.6102 60.9176C36.5908 61.8592 42.7152 60.8124 48.0435 57.9379L34.9117 33.5963H62.5696Z"
				fill="#E861C9"
			/>
			<path
				d="M62.5696 33.5963C62.5696 28.1261 60.9475 22.7788 57.9084 18.2304C54.8693 13.6821 50.5497 10.1372 45.4959 8.0438C40.4421 5.95045 34.8811 5.40273 29.516 6.46991C24.1509 7.5371 19.2227 10.1713 15.3547 14.0393L34.9117 33.5963H62.5696Z"
				fill="#5D9BFD"
			/>
		</svg>
	);
};

export default Pie;
