import ProposalCreationBody from '@/components/Proposal/ProposalCreationBody/ProposalCreationBody';
import ProposalCreationFooter from '@/components/Proposal/ProposalCreationFooter/ProposalCreationFooter';
import { motion, AnimatePresence } from 'framer-motion';
import ProposalCreationHeader from '@/components/Proposal/ProposalCreationHeader/ProposalCreationHeader';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { ErrorResponse, RFP, Template } from '@/lib/types/apiTypes';
import { useTour } from '@/lib/hooks/useTour';
import { ProposalCreationStepsMap } from '@/constants/Onboarding/types';
import Lottie from 'lottie-react';
import LoadingLogo from '@/assets/lotties/solicitation_ingestion_v1.json';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { getDocumentFromSource } from '@/lib/functions/apiCalls';
import { openDialog } from '@/redux/slices/pageSlice';
import { handleApiError, handleGeneralError } from '@/lib/functions/funcUtils';
import QuestionBuilder from '@/components/QuestionBuilder/QuestionBuilder';

interface ProposalCreationProps {}

const ProposalCreation: FC<ProposalCreationProps> = () => {
	const dispatch = useAppDispatch();
	const currentStep = useSelector(
		(root: RootState) => root.proposal.currentStep
	);
	const rfp = useSelector((root: RootState) => root.solicitation.rfp);

	const selectedTemplate = useSelector(
		(root: RootState) => root.proposal.selectedTemplate
	);
	const pageTransitionLoad = useSelector(
		(state: RootState) => state.proposal.pageTransitionLoad
	);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);

	const handleViewRFP = async () => {
		dispatch(processStart());
		try {
			const response = await getDocumentFromSource(
				rfp?.sourceLink ? rfp?.sourceLink : (proposal?.rfpLink as string)
			);
			if (response) {
				// setPdfData(response as ArrayBuffer);
				dispatch(openDialog({ type: 'viewPDF', pdf: response as ArrayBuffer }));
				dispatch(processSuccess());
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};

	useTour(ProposalCreationStepsMap[currentStep]);

	const fadeIn = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	const renderContent = () => {
		switch (currentStep) {
			case 1:
				return (
					<motion.div
						key="setup"
						variants={fadeIn}
						initial="hidden"
						animate="visible"
						exit="exit"
					>
						<div className="flex flex-col items-start justify-center w-full min-h-[calc(100vh-120px)]">
							<ProposalCreationBody
								type="setup"
								currentStep={currentStep}
								rfp={rfp as RFP}
								viewRFP={handleViewRFP}
							/>
							<ProposalCreationFooter type="setup" rfp={rfp as RFP} />
						</div>
					</motion.div>
				);
			case 2:
				{
					return rfp?.category === 'questionnaire' ? (
						<motion.div
							key="template"
							variants={fadeIn}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<QuestionBuilder />
						</motion.div>
					) : (
						<motion.div
							key="template"
							variants={fadeIn}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<div className="flex flex-col items-start justify-center w-full min-h-[calc(100vh-120px)]">
								<ProposalCreationBody
									type="template"
									currentStep={currentStep}
									template={selectedTemplate as Template}
									pageTransitionLoad={pageTransitionLoad}
									rfp={rfp as RFP}
								/>
								<ProposalCreationFooter
									type="template"
									template={selectedTemplate as Template}
									agency={rfp?.metaData.agency}
									pageTransitionLoad={pageTransitionLoad}
									rfp={rfp as RFP}
								/>
							</div>
						</motion.div>
					);
				}
				break;
			case 3:
				return (
					<motion.div
						key="tone"
						variants={fadeIn}
						initial="hidden"
						animate="visible"
						exit="exit"
					>
						<div className="flex flex-col items-start justify-center w-full min-h-[calc(100vh-120px)]">
							<ProposalCreationBody
								type="tone"
								currentStep={currentStep}
								pageTransitionLoad={pageTransitionLoad}
							/>
						</div>
					</motion.div>
				);
			case 4:
				return (
					<motion.div
						key="approach"
						variants={fadeIn}
						initial="hidden"
						animate="visible"
						exit="exit"
					>
						<div className="flex flex-col items-start py-8 justify-center w-full min-h-[calc(100vh-120px)]">
							<ProposalCreationBody
								type="approach"
								currentStep={currentStep}
								pageTransitionLoad={pageTransitionLoad}
							/>
							<ProposalCreationFooter
								type="approach"
								pageTransitionLoad={pageTransitionLoad}
							/>
						</div>
					</motion.div>
				);
			case 5:
				return (
					<div className="flex items-center justify-center w-full min-h-[calc(100vh-120px)]">
						<Lottie
							className="block animation-lottie"
							animationData={LoadingLogo}
							loop={true}
							autoPlay={true}
						/>
					</div>
				);
			// case 5:
			// 	return (
			// 		<motion.div
			// 			key="name"
			// 			variants={fadeIn}
			// 			initial="hidden"
			// 			animate="visible"
			// 			exit="exit"
			// 		>
			// 			<div className="flex flex-col items-start justify-center w-full min-h-[calc(100vh-120px)]">
			// 				<ProposalCreationBody
			// 					type="name"
			// 					currentStep={currentStep}
			// 					pageTransitionLoad={pageTransitionLoad}
			// 				/>
			// 				<ProposalCreationFooter type="name" />
			// 			</div>
			// 		</motion.div>
			// 	);
			default:
				return null;
		}
	};

	return (
		<div className="flex flex-col bg-[#f5f5f5] rounded-tl-[30px] h-[calc(100vh-8px)] pt-5 overflow-y-hidden hidden-scroll px-8 relative">
			<ProposalCreationHeader
				source={rfp?.sourceLink as string}
				agency={rfp?.metaData.agency || ''}
				title={rfp?.metaData?.title || ''}
				viewRFP={handleViewRFP}
			/>
			<div className="relative h-8 z-10 w-full bg-gradient-to-b from-[#f5f5f5] to-[#f5f5f500]"></div>
			<div className="h-full overflow-y-auto hidden-scroll mt-[-1.6rem]">
				<AnimatePresence mode="wait">{renderContent()}</AnimatePresence>
			</div>
		</div>
	);
};

export default ProposalCreation;
