import styles from './ToneOfVoicePage.module.css';
import { cn } from '@/lib/utils';
import { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { ApiResponse, ErrorResponse, ToneOfVoice } from '@/lib/types/apiTypes';
import {
	getOrganizationInfo,
	getToneOfVoiceFromProposal,
} from '@/lib/functions/apiCalls';
import {
	handleGeneralError,
	handleApiError,
	handleUnexpectedError,
	ErrorHandle,
	getToken,
	handleFileRejections,
} from '@/lib/functions/funcUtils';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import DocumentUploadIcon from '@/components/ui/icons/DocumentUploadIcon';
import FileUpload from '@/components/FileUpload/FileUpload';
import { openDialog } from '@/redux/slices/pageSlice';
import ToneOfVoiceView from '@/components/ToneOfVoiceView/ToneOfVoiceView';
import { motion, useAnimation } from 'framer-motion';
import AutoFetchDocument from '@/components/ui/icons/new/AutoFetchDocument';
import { iCSlideInDelay } from '@/constants/variants';

interface ToneOfVoicePageProps {
	exiting?: boolean;
}

const toneOfVoiceSampleData: ToneOfVoice = {
	tone: [
		{
			name: 'Analytical',
			percentage: 20,
		},
		{
			name: 'Authoritative',
			percentage: 20,
		},
		{
			name: 'Concise',
			percentage: 20,
		},
		{
			name: 'Emotive',
			percentage: 20,
		},
	],
	sampleText: 'Sample text.',
	writingStyle: {
		name: 'collaborative',
		exampleText: 'We the organization and our partners',
	},
	citationFormat: 'APA (American Psychological Association)',
};

const ToneOfVoicePage: FC<ToneOfVoicePageProps> = ({ exiting }) => {
	const dispatch = useAppDispatch();
	const token = getToken();
	const [organizationId, setOrganzationId] = useState<number>(-1);
	const [toneOfVoiceData, setToneOfVoiceData] = useState<ToneOfVoice>(
		toneOfVoiceSampleData
	);
	const [propsoal, setProposal] = useState<File>();
	const [uploadedDocumentTone, setUploadedDocumentTone] = useState<
		ToneOfVoice | undefined
	>();

	const onSave = (updatedTone: ToneOfVoice) => {
		setToneOfVoiceData(updatedTone);
	};

	const handleFiles = useCallback(async (files: File[]) => {
		if (!files.length) return;
		try {
			dispatch(processStart());
			const response: ApiResponse = await getToneOfVoiceFromProposal(files[0]);
			if (response.statusCode >= 400 && response.statusCode <= 599) {
				throw response; // Throw the response if status is 400s or 500s
			}
			if (response.statusCode === 201) {
				const toneOfVoiceData = (response?.data as any).autoFetchResults
					.toneOfVoice as ToneOfVoice;
				setUploadedDocumentTone(toneOfVoiceData);
				setProposal(files[0]);
			}
			dispatch(processSuccess());
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			} else {
				handleUnexpectedError();
				dispatch(processSuccess());
			}
		}
	}, []);

	const openEditTone = (showDocumentTone: boolean = false) => {
		dispatch(
			openDialog({
				type: 'changeTone',
				changeToneProps: {
					organizationId: organizationId,
					type: 'organization',
					onSave: onSave,
					toneOfVoice:
						showDocumentTone && uploadedDocumentTone
							? uploadedDocumentTone
							: toneOfVoiceData,
					uploadedDocumentTone: uploadedDocumentTone,
				},
			})
		);
	};

	useEffect(() => {
		if (token) {
			dispatch(processStart());
			getOrganizationInfo(token)
				.then((response) => {
					const organization = response?.data as any;
					const toneOfVoiceData = (response?.data as any).settings
						.toneOfVoice as ToneOfVoice;
					setOrganzationId(organization.id);
					setToneOfVoiceData(toneOfVoiceData);
					dispatch(processSuccess());
				})
				.catch((error) => {
					ErrorHandle(dispatch, error);
				});
		}
	}, [token]);

	useEffect(() => {
		if (uploadedDocumentTone) {
			openEditTone(true);
		}
	}, [uploadedDocumentTone]);

	const control = useAnimation();

	const startExitingAnimation = useCallback(
		(exiting) => {
			if (exiting === true) {
				control.start({ opacity: 0, transition: { duration: 0.25 } });
			}
		},
		[control]
	);

	useEffect(() => {
		if (exiting) {
			startExitingAnimation(exiting);
		}
	}, [exiting, startExitingAnimation]);

	return (
		<div className={cn(styles.wrapper, 'hidden-scroll')}>
			<motion.div
				className="grid w-full grid-cols-12 gap-5 h-fit"
				animate={control}
			>
				<motion.div
					className="flex flex-col col-span-6 gap-6 p-6 bg-white h-fit rounded-2xl shadow-main"
					variants={iCSlideInDelay}
					initial="initial"
					animate="animate"
					exit="exit"
				>
					<FileUpload
						onDropAccepted={handleFiles}
						type="custom"
						customLayout={
							<div
								className={
									'flex flex-col h-full gap-2 py-6 px-3 rounded-lg border border-[#D3DDE2] text-center cursor-pointer justify-center'
								}
							>
								<div className="flex flex-col gap-2">
									<div className="flex items-center justify-center">
										<AutoFetchDocument className="w-28" />
									</div>
									<div className="flex items-center justify-center">
										<DocumentUploadIcon className="h-5 2xl:h-6" />
										<h6 className="font-bold uppercase text-primary">
											{uploadedDocumentTone
												? 'Click to replace the Proposal'
												: 'Click to Import A Previous Proposal to determine tone of voice'}
										</h6>
									</div>
								</div>
								<p className="max-w-lg mx-auto text-black whitespace-pre-line">
									You can upload a document here to determine your
									organization’s tone of voice. We will use our AACE system to
									come up with a tone of voice, which you can edit using the
									edit tone of voice button on the right.
								</p>
							</div>
						}
						dropText="Drop the proposal here ..."
						className="h-full"
						onDropRejected={(fileRejections) =>
							handleFileRejections({ fileRejections: fileRejections })
						}
					/>
					{propsoal && (
						<div className="flex flex-col p-6 w-full rounded-[30px] shadow-main gap-2">
							<a className="text-primaryDark">{propsoal.name}</a>
						</div>
					)}
				</motion.div>
				<motion.div
					className="flex flex-col col-span-6 p-6 bg-white h-fit rounded-2xl shadow-main"
					variants={iCSlideInDelay}
					initial="initial"
					animate="animate"
					exit="exit"
				>
					<ToneOfVoiceView
						type="organization"
						onEditTone={() => openEditTone()}
						toneOfVoice={toneOfVoiceData}
					/>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default ToneOfVoicePage;
