import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { getDocumentById } from '@/lib/functions/apiCalls';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { RFP } from '@/lib/types/apiTypes';
import { closeDialog, setLastSection } from '@/redux/slices/pageSlice';
import { clearProposal, updateStep } from '@/redux/slices/proposalSlice';
import { setSolicitation } from '@/redux/slices/solicitationSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const SolicitationExistsDialog = () => {
	const isOpen = useAppSelector(
		(root: RootState) => root.page.dialog?.type === 'solicitationExists'
	);

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const solicitationID = useSelector(
		(root: RootState) => root.page.dialog?.solicitationID
	);

	const handleNavigate = async () => {
		if (solicitationID) {
			const response = await getDocumentById(solicitationID);
			if (response.statusCode === 200) {
				const rfpResponse = response.data as RFP;
				dispatch(clearProposal());
				dispatch(setSolicitation(rfpResponse));
				dispatch(setLastSection({ proposalId: null, sectionId: null }));
				if (rfpResponse.category === 'questionnaire') {
					dispatch(updateStep(2));
				}
				navigate('/create');
				dispatch(closeDialog());
			}
		}
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[450px] p-6 bg-white">
				<DialogHeader className="font-bold text-secondary dynamic-large">
					Existing Proposal Detected
				</DialogHeader>
				<p className=" text-[#6D7D86]">
					Looks like you already have a proposal underway for this solicitation.
					Would you like to start a new one?
				</p>
				<div className="flex items-center justify-end gap-3">
					<SecondaryButton onClick={() => dispatch(closeDialog())}>
						No
					</SecondaryButton>
					<PrimaryButton onClick={handleNavigate}>Yes</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default SolicitationExistsDialog;
