import customToast from '@/components/CustomToast/CustomToast';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { PortableMultiSelect } from '@/components/ui/shared/PortableMultiSelect/PortableMultiSelect';
import { Textarea } from '@/components/ui/textarea';
import { addNewComplianceMatrixRule } from '@/lib/functions/apiCalls';
import { transformSectionsRecursively } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import useProposalId from '@/lib/hooks/useProposalId';
import { fetchComplianceMatrix } from '@/redux/requests/compliance';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

interface AddNewMatrixRuleProps {
	requirement: string;
	solicitationReference: string;
	explanation: string;
	sectionIds: { label: string; value: number }[];
}

const AddNewMatrixRule = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'addMatrixRule'
	);
	const sections = useSelector(
		(root: RootState) => root.proposal.proposal?.sections
	);
	const [loading, setLoading] = useState(false);
	const form = useForm<AddNewMatrixRuleProps>();
	const dispatch = useAppDispatch();
	const { register, handleSubmit, reset, control, watch } = form;

	const requirement = watch('requirement');
	const solicitationReference = watch('solicitationReference');
	const explanation = watch('explanation');
	const sectionIds = watch('sectionIds');
	const proposalId = useProposalId();
	const complianceId = useSelector(
		(root: RootState) => root.compliance.complianceMatrix?.id
	);
	const { activeSection } = useActiveSection();
	const location = useLocation();

	const isWritePage = location.pathname.endsWith('/write');

	useEffect(() => {
		reset({
			explanation: '',
			requirement: '',
			solicitationReference: '',
			sectionIds: [],
		});
	}, [reset]);

	useEffect(() => {
		if (!isWritePage || !activeSection) return;

		if (activeSection.sectionId && activeSection.sectionName) {
			reset({
				sectionIds: [
					{
						label: activeSection.sectionName,
						value: activeSection.sectionId,
					},
				],
			});
		}
	}, [activeSection, isWritePage, reset]);

	const transformSection =
		useMemo(() => transformSectionsRecursively(sections || []), [sections]) ||
		[];
	const onSubmit = async (data: AddNewMatrixRuleProps) => {
		if (!proposalId || !complianceId) return;
		try {
			setLoading(true);
			const response = await addNewComplianceMatrixRule(
				proposalId,
				complianceId,
				{
					explanation: data.explanation,
					statement: data.requirement,
					solicitationReference: data.solicitationReference,
					sectionRef: {
						addIds: data.sectionIds.map((section) => section.value),
					},
				}
			);
			if (response.statusCode === 201) {
				dispatch(fetchComplianceMatrix(proposalId));
				setLoading(false);
				customToast.success({
					title: 'Matrix Rule added successfully',
				});
				dispatch(closeDialog());
				reset();
			}
		} catch (error) {
			console.log(error);
			customToast.error({
				title: 'Error adding Matrix Rule',
			});
			setLoading(false);
		}
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[700px] p-6 rounded-3xl shadow-main bg-white"
				hideCloseBtn
			>
				<DialogHeader>
					<DialogTitle className="font-bold dynamic-large text-secondary">
						Add Matrix Rule
					</DialogTitle>
				</DialogHeader>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="w-full mt-3 overflow-y-auto max-h-[460px] 2xl:max-h-[600px] pb-px pr-3 2xl:pr-0"
				>
					<Label
						className="block mb-2 font-bold text-black dynamic-medium"
						htmlFor="requirement"
					>
						Solicitation Requirement
					</Label>
					<Input
						className="w-full h-12 bg-white rounded-xl"
						{...register('requirement', { maxLength: 600 })}
						id="requirement"
						placeholder="Enter Requirement"
					/>
					<div className="flex justify-end mt-1 text-gray-400 dynamic-small">
						{requirement?.length || 0}/600
					</div>
					<Label
						className="block mt-4 mb-2 font-bold text-black dynamic-medium"
						htmlFor="solicitationSection"
					>
						Solicitation Section
					</Label>
					<Input
						className="h-12 bg-white rounded-xl"
						{...register('solicitationReference', { maxLength: 500 })}
						id="solicitationSection"
						placeholder="Enter Solicitation Section"
					/>
					<div className="flex justify-end mt-1 text-gray-400 dynamic-small">
						{solicitationReference?.length || 0}/500
					</div>
					<Label
						className="block mt-4 mb-2 font-bold text-black dynamic-medium"
						htmlFor="explanation"
					>
						Explanation
					</Label>
					<Textarea
						className="h-40 p-3 bg-white resize-none rounded-xl"
						{...register('explanation', { maxLength: 1000 })}
						id="explanation"
						placeholder="Enter Explanation"
					/>
					<div className="flex justify-end mt-1 text-gray-400 dynamic-small">
						{explanation?.length || 0}/1000
					</div>
					<Label className="block mt-4 mb-2 font-bold text-black dynamic-medium">
						Proposal Section
					</Label>
					<Controller
						name="sectionIds"
						control={control}
						render={({ field }) => (
							<PortableMultiSelect
								options={transformSection}
								value={field.value?.map((v) => ({
									label: v.label,
									value: v.value.toString(),
								}))}
								onSelectedValuesChange={(selected) =>
									field.onChange(
										selected.map((s) => ({
											label: s.label,
											value: parseInt(s.value),
										}))
									)
								}
							/>
						)}
					/>
					<div className="relative flex items-center justify-end gap-3 mt-4">
						<SecondaryButton
							type="button"
							onClick={() => {
								dispatch(closeDialog());
								reset({
									explanation: '',
									requirement: '',
									solicitationReference: '',
									sectionIds: [],
								});
							}}
						>
							Cancel
						</SecondaryButton>
						<PrimaryButton
							type="submit"
							disabled={
								!requirement ||
								!solicitationReference ||
								!explanation ||
								!sectionIds ||
								requirement?.length > 600 ||
								solicitationReference?.length > 500 ||
								explanation?.length > 1000 ||
								loading
							}
						>
							{loading ? 'Adding...' : 'Add Rule'}
						</PrimaryButton>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default AddNewMatrixRule;
