export enum Page {
	Dashboard,
	Solicitation,
	ProposalDashboard,
	Design,
	Write,
	OrganizationSettings,
	ProposalCreationStep1,
	ProposalCreationStep2,
	ProposalCreationStep3,
	ProposalCreationStep4,
	TemplateBuilder,
	TemplateBuilderEdit,
	UserPage,
	Document,
}

export const ProposalCreationStepsMap = {
	1: Page.ProposalCreationStep1,
	2: Page.ProposalCreationStep2,
	3: Page.ProposalCreationStep3,
	4: Page.ProposalCreationStep4,
};
