import { FC } from 'react';
import DashboardProposalListItem from '../DashboardProposalListItem/DashboardProposalListItem';
import { ProposalTableProps } from '@/lib/types/constants';
import { Team } from '@/lib/types/apiTypes';

interface DashboardProposalsListProps {
	cards: ProposalTableProps[];
	teams: Team[];
}

const DashboardProposalsList: FC<DashboardProposalsListProps> = ({
	cards,
	teams,
}) => {
	return (
		<div className="max-h-[640px] overflow-y-scroll light-scroll2">
			<div className="flex flex-col gap-3 pr-4">
				<div className="sticky -mt-4 flex h-4 -top-0.5 z-10 w-full bg-gradient-to-b from-[#ffffff] to-[#ffffff00]"></div>
				{cards.map((card, index) => (
					<DashboardProposalListItem key={index} card={card} teams={teams} />
				))}
			</div>
		</div>
	);
};

export default DashboardProposalsList;
