import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

const useLogicalFrameEnabled = () => {
	return (
		useSelector(
			(state: RootState) => state.auth.user?.organization?.isLogicalFrameEnabled
		) || false
	);
};

export default useLogicalFrameEnabled;
