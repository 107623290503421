import { FC } from 'react';
import Solicited from '@/components/ui/icons/Solicited';
import Unsolicited from '@/components/ui/icons/Unsolicited';
import QuestionnaireIcon from '@/components/ui/icons/new/Dashboard/QuestionnaireIcon';
import { DocumentJourneyTypes } from '@/lib/types/apiTypes';

interface ProposalCategoryProps {
	category?: DocumentJourneyTypes;
	type?: 'main' | 'table';
}

const ProposalCategory: FC<ProposalCategoryProps> = ({ category, type }) => {
	return (
		<>
			{category === 'solicitation' ? (
				<div className="flex items-center gap-2">
					<Solicited />
					<p className="font-medium uppercase text-secondary">solicited</p>
				</div>
			) : category === 'un-solicited' ? (
				<div className="flex items-center gap-2">
					<Unsolicited
						color={type === 'main' ? 'var(--secondary)' : 'var(--primary)'}
					/>
					<p
						className={`font-medium uppercase ${type === 'main' ? 'text-secondary' : 'text-primary'}`}
					>
						unsolicited
					</p>
				</div>
			) : category === 'questionnaire' ? (
				<div className="flex items-center gap-2">
					<QuestionnaireIcon
						color={type === 'main' ? 'var(--secondary)' : 'var(--primary)'}
					/>
					<p
						className={`font-medium uppercase ${type === 'main' ? 'text-secondary' : 'text-primary'}`}
					>
						q&a
					</p>
				</div>
			) : (
				<p className="flex justify-center w-28">--</p>
			)}
		</>
	);
};

export default ProposalCategory;
