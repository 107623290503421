import { Step } from 'intro.js-react';
export const ProposalCreationStepTwo: Step[] = [
	{
		element: '.template-selection',
		title: 'Template selection',
		intro:
			'Welcome to the template selection screen. This section allows you to select a template for your proposal. You can select other templates from your template library or build your own in the template builder.',
		position: 'top',
	},
	{
		element: '.template-outline',
		title: 'Template outline',
		intro: 'You can see the structure of the selected template right here',
		position: 'top',
	},
	{
		element: '.choose-template',
		title: 'Change template',
		intro: 'You can change the selected template by clicking here.',
		position: 'left',
	},
	{
		element: '.template-selection',
		title: 'Finalize template',
		intro:
			'Click here to finalize your template and start creating your proposal.',
		position: 'left',
	},
];
