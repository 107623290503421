import { FC, useEffect, useState } from 'react';
import { ToneOfVoice } from '@/lib/types/apiTypes';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { tovMap } from '@/constants/organizationalData';

ChartJS.register(ArcElement, Tooltip);

const options = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		r: {
			min: -20,
			max: 100,
			ticks: {
				display: false,
				stepSize: 20,
			},
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			callbacks: {
				label: (context) => {
					return `${tovMap?.[context?.label]?.[context.formattedValue]?.name}`;
				},
			},
		},
	},
};

interface ToneChartData {
	labels: string[];
	datasets: [
		{
			label: string;
			data: number[];
			backgroundColor: string[];
			borderColor: string[];
			borderWidth: 1;
		},
	];
}

interface ToneOfVoiceChartProps {
	toneOfVoice: ToneOfVoice | undefined;
}

const ToneOfVoiceChart: FC<ToneOfVoiceChartProps> = ({ toneOfVoice }) => {
	const [toneChartData, setToneChartData] = useState<ToneChartData>();

	const formatToneChartData = (toneOfVoice: ToneOfVoice): ToneChartData => {
		return {
			labels: toneOfVoice.tone.map((tone) => tone.name),
			datasets: [
				{
					label: 'Tone of voice',
					data: toneOfVoice.tone.map((tone) => tone.percentage) || [],
					backgroundColor: ['#C05EE2', '#5D9BFD', '#6279E6', '#E861C9'],
					borderColor: ['#C05EE2', '#5D9BFD', '#6279E6', '#E861C9'],
					borderWidth: 1,
				},
			],
		};
	};
	useEffect(() => {
		if (toneOfVoice) {
			const chartData = formatToneChartData(toneOfVoice);
			setToneChartData(chartData);
		}
	}, [toneOfVoice]);

	return (
		<div className="grid grid-cols-11 gap-3 ">
			<div className="flex flex-col col-span-4 gap-3 writing-style">
				<div className="flex flex-col justify-center flex-1 px-4 border rounded-xl ">
					<h3 className="font-bold text-black dynamic-medium">
						Style of Writing
					</h3>
					{toneOfVoice?.writingStyle.name === 'collaborative' ? (
						<p className=" text-secondary dynamic-small">
							Collaborative tone of voice
						</p>
					) : (
						<p className="text-secondary dynamic-small">
							First person tone of voice
						</p>
					)}
				</div>
				<div className="flex flex-col justify-center flex-1 px-4 border rounded-xl">
					<h3 className="font-bold text-black dynamic-medium">
						Citation Style
					</h3>
					<p className="text-secondary dynamic-small">
						{toneOfVoice?.citationFormat || 'Chicago'}
					</p>
				</div>
			</div>
			<div className="flex flex-col flex-1 col-span-7 tone-graph">
				<div className="flex flex-col gap-2 px-4 py-2 border rounded-xl">
					<div className="flex flex-wrap justify-between ">
						{toneChartData?.labels.map((label, index) => (
							<div
								key={label}
								className="flex items-center text-black dynamic-small gap-0.5"
							>
								<div
									className="w-2 h-2 rounded-full"
									style={{
										backgroundColor: `${toneChartData.datasets[0].backgroundColor[index]}`,
									}}
								></div>
								{label}
							</div>
						))}
					</div>
					<div className="flex">
						{toneChartData && (
							<PolarArea data={toneChartData} options={options} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ToneOfVoiceChart;
