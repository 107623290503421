import { AxiosError } from 'axios';
import api from '../api';
import {
	ApiResponse,
	ChangePasswordPayload,
	InvitedUserInfoPayload,
	LoginDomainPayload,
	LoginPayload,
	NoteApiPayload,
	OrganizationPayload,
	ProposalRequest,
	ProposalResponse,
	Query,
	QuestionResponse,
	Section,
	Suggestion,
	ToneOfVoicePayload,
	UploadDocumentPayload,
	UserProfilePayload,
	InviteUserPayload,
	UpdateUserPayload,
	GenerateSampleTextPayload,
	ProposalAction,
	AuditLogsPayload,
	UserStatus,
	ForgotPasswordPayload,
	ResetPasswordPayload,
	ChartsResponse,
	CreatePDFPayload,
	DiscoverPaginateResponse,
	ManualSolicitationPayload,
	RegionResponse,
	CategoriesResponse,
	AgencyResponse,
	SearchQuery,
	TypeResponse,
	ProposalTablePayload,
	DocumentsPayload,
	SortFilter,
	TemplatesPayload,
	DocumentType,
	SolicitionTableFilters as SolicitionTablePayload,
	OrganizationProfilePayload,
	ManageTeamPayload,
	FindDocumentsPayload,
	User,
} from '../types/apiTypes';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	constructGetQueryForAuditLogs,
	createFormDataFromJSON,
	flattenObjectValuesToArray,
} from './funcUtils';
import { SectionBuilder } from '../types/TemplateBuilder/templateTypes';

/**
 * Logs in a user.
 *
 * @param {LoginPayload} payload - The login payload containing the user's credentials.
 * @return {Promise<ApiResponse>} The response from the login API.
 */
export const login = async (
	payload: LoginPayload,
	twoFactorToken?: string
): Promise<ApiResponse> => {
	try {
		const response = twoFactorToken
			? await api.post<ApiResponse>('/api/auth/login', payload, {
					headers: {
						'2Factor-Token': twoFactorToken,
					},
				})
			: await api.post<ApiResponse>('/api/auth/login', payload);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

/**
 * Log in with SSO.
 *
 * @param {LoginDomainPayload} payload - The login payload containing the user's credentials.
 * @return {Promise<ApiResponse>} The response from the login API.
 */
export const loginWithSSO = async (
	payload: LoginDomainPayload
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/auth/sso?org=${payload.domain}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

/**
 * Send Two Factor Email
 *
 * @return {Promise<ApiResponse>} The response from the login API.
 */
export const sendTwoFactorEmail = async (
	email: string
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>('/api/users/email', {
			email: email,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

/**
 * Forgot Password
 *
 * @param {ForgotPasswordPayload} payload - The login payload containing the user's credentials.
 * @return {Promise<ApiResponse>} The response from the login API.
 */
export const forgotPassword = async (
	payload: ForgotPasswordPayload
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			'/api/auth/forgot-password',
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

/**
 * User info
 *
 * @param {InvitedUserInfoPayload} payload - The login payload containing the user's info.
 * @return {Promise<ApiResponse>} The response from the login API.
 */
export const invitedUserInfoSubmit = async (
	token: string,
	payload: InvitedUserInfoPayload
) => {
	//  Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/auth/sign-up?token=${token}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const uploadDocumentInOrganization = async (
	payload: UploadDocumentPayload
): Promise<ApiResponse> => {
	try {
		const formData = createFormDataFromJSON(payload);

		const response = await api.postForm<ApiResponse>(
			'/api/document/ingest',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

/**
 * Retrieves a document from the specified source link.
 *
 * @param {string} sourceLink - The link to the source of the document.
 * @return {Promise<any>} A Promise that resolves with the retrieved document data.
 */
export const getDocumentFromSource = async (sourceLink: string) => {
	try {
		const response = await api.get<ApiResponse>('/api/document/file', {
			params: { url: sourceLink },
			responseType: 'arraybuffer',
		});
		return response;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

/**
 * Retrieves all templates from the API.
 *
 * @return {Promise<Template[]>} An array of Template objects.
 */
export const getAllTemplates = async (payload: TemplatesPayload) => {
	const response = await api.get(`/api/v2/template`, {
		params: payload,
	});
	return response.data.data;
};

/**
 * Creates a proposal from a template.
 *
 * @param {ProposalRequest} payload - The payload containing the proposal request.
 * @return {Promise<ProposalResponse>} - A promise that resolves with the proposal response.
 */
export const createProposalFromTemplate = async (
	payload: ProposalRequest
): Promise<ProposalResponse> => {
	const response = await api.post('/api/proposal', payload);
	return response.data.data.proposal;
};

export const getProposalById = async (id: number): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/proposal/${id}`);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const resolveQuestionFromNofo = async (
	proposalId: number,
	questionId: number
): Promise<QuestionResponse> => {
	const response = await api.get(
		`/api/proposal/${proposalId}/question/${questionId}/resolve`
	);
	return response.data.data;
};

export const answerSpecificQuestion = async (
	proposalId: number,
	questionId: number,
	answer: string
) => {
	const response = await api.patch(
		`/api/proposal/${proposalId}/question/${questionId}`,
		{ answer }
	);
	return response.data.data;
};

export const updateProposal = async (proposalId: number, name: string) => {
	const response = await api.patch(`/api/proposal/${proposalId}`, {
		name: name,
	});
	return response.data.data;
};

export const updateProposalJourneyStatus = async (
	proposalId: number,
	status: boolean
) => {
	try {
		const response = await api.patch(`/api/proposal/${proposalId}`, {
			isJourneyComplete: status,
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};

export const updateProposalTone = async (
	proposalId: number,
	toneOfVoicePayload: ToneOfVoicePayload
) => {
	const response = await api.patch(`/api/proposal/${proposalId}`, {
		...toneOfVoicePayload,
	});
	return response.data.data;
};

export const updateProposalOrganizaitonProfile = async (
	proposalId: number,
	organizationProfileId: number
) => {
	const response = await api.patch(`/api/proposal/${proposalId}`, {
		organizationProfileId: organizationProfileId,
	});
	return response.data.data;
};

export const attachDocumentToSection = async (
	proposalId: number,
	sectionId: number,
	documentId: number,
	category?: string
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/attach-document`,
			{
				document_id: documentId, // Sending documentId in the request body
				category: category,
			}
		);

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const createCommentOnSpecificSection = async (
	proposalId: number,
	sectionId: number,
	content: string[],
	parentId?: number
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/comment`,
			{
				content: content,
				parentId: parentId,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateCommentOnSpecificSection = async (
	proposalId: number,
	sectionId: number,
	commentId: number,
	payload: { content?: string[]; isResolved?: boolean }
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/comment/${commentId}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const deleteCommentOnSpecificSection = async (
	proposalId: number,
	sectionId: number,
	commentId: number,
	payload: { content?: string; isResolved?: boolean }
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/comment/${commentId}`,
			{
				payload,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getCommentBySection = async (
	proposalId: number,
	sectionId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/comment`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getCommentById = async (
	proposalId: number,
	sectionId: number,
	commentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/comment/${commentId}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const createNoteOnSpecificSection = async (
	proposalId: number,
	sectionId: number,
	payload: NoteApiPayload
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/notes`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateNoteOnSpecificSection = async (
	proposalId: number,
	sectionId: number,
	noteId: number,
	payload: {
		content?: string;
		sectionId?: number;
	}
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/notes/${noteId}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const detachDocumentFromSection = async (
	proposalId: number,
	sectionId: number,
	documentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/detach-document`,
			{
				document_id: documentId,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const findDocuments = async (
	queryText: string,
	payload?: FindDocumentsPayload
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(`/api/document/find`, {
			query: queryText,
			...(payload && payload),
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const deleteACommentFromASection = async (
	proposalId: number,
	sectionId: number,
	commentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.delete<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/comment/${commentId}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const deleteANoteFromASection = async (
	proposalId: number,
	sectionId: number,
	noteId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.delete<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/notes/${noteId}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const writeASectionWithMagic = async (
	proposalId: number,
	sectionId: number,
	payload: { magicType: string; suggestion?: Suggestion; query?: string }
): Promise<ApiResponse> => {
	const response = await api.post(
		`/api/proposal/${proposalId}/section/${sectionId}/magic`,
		payload
	);
	return response.data;
};

export const updateSectionState = async (
	proposalId: number,
	sectionId: number,
	payload: {
		content?: string;
		editorState?: string;
		isComplete?: boolean;
		wordCount?: number;
		name?: string;
		alias?: string;
		resetCompliance?: boolean;
	}
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateSection = async (
	proposalId: number,
	sectionId: number,
	payload: Section
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const brainstormWithAI = async (
	proposalId: number,
	sectionId: number,
	payload: Query
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/brainstorm`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const generateSuggestionForASection = async (
	proposalId: number,
	sectionId: number,
	query: string
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/section/${sectionId}/suggestions`,
			{
				query,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getDocumentById = async (
	documentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/document/${documentId}`);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const exportProposal = async (
	proposalId: number,
	type?: 'pdf' | 'docx' | 'print',
	logframe?: boolean
): Promise<ArrayBuffer | undefined> => {
	try {
		let url = `/api/proposal/${proposalId}/export`;
		if (type) {
			url += `?type=${type}&logframe=${logframe}`;
		}
		const response = await api.get<ArrayBuffer>(url, {
			responseType: 'blob', // Set responseType to 'blob'
			headers: {
				'Content-Type':
					'application/pdf;application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			},
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
	}
};
export const exportDocumentBreakdown = async (
	proposalId: number,
	type?: string
): Promise<ArrayBuffer | undefined> => {
	try {
		const response = await api.get<ArrayBuffer>(
			`/api/document/${proposalId}/breakdown`,
			{
				responseType: 'blob', // Set responseType to 'blob'
				headers: {
					'Content-Type': 'application/pdf',
				},
				params: {
					...(type && { type: type }),
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
	}
};

export const getAllOrganizationDocuments = async (
	payload: DocumentsPayload
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/v2/document`, {
			params: payload,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getAllProposals = async (
	pagination?: ProposalTablePayload
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/proposal`, {
			params: pagination,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getAllProposalsByAction = async (
	action: string
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/proposal?action=${action}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};
export const getAllSolicitations = async (
	payload?: SolicitionTablePayload
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/organization/solicitations`,
			{
				params: payload,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getSSOUserInfo = async (token: string): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/users/getme`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getUserDetails = async (): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/users/getme`);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const useGetUserDetails = (enabled: boolean = false) => {
	return useQuery<User, any>({
		queryFn: async () => {
			return api.get<ApiResponse>(`/api/users/getme`).then((res) => {
				return res.data.data as User;
			});
		},
		queryKey: 'userDetails',
		refetchOnWindowFocus: false,
		enabled: enabled,
	});
};

export const updateComplianceMatrix = async (
	proposalId: number,
	complianceId: number,
	ruleId: number,
	payload: {
		statement: string;
		explanation: string;
		solicitationReference: string;
		sectionRef: {
			addIds: number[];
			removeIds: number[];
		};
	}
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/proposal/${proposalId}/complianceMatrix/${complianceId}/rule/${ruleId}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const deleteComplianceMatrixRule = async (
	proposalId: number,
	complianceId: number,
	ruleId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.delete<ApiResponse>(
			`/api/proposal/${proposalId}/complianceMatrix/${complianceId}/rule/${ruleId}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const triggerComplianceAssociation = async (
	proposalId: number,
	complianceId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/proposal/${proposalId}/complianceMatrix/${complianceId}/trigger`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const addNewComplianceMatrixRule = async (
	proposalId: number,
	complianceId: number,
	payload: {
		statement: string;
		explanation: string;
		solicitationReference: string;
		sectionRef: {
			addIds: number[];
		};
	}
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/complianceMatrix/${complianceId}/rule`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getSectionComplianceRules = async (
	proposalId: number,
	sectionId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/proposal/${proposalId}/complianceMatrix/section/${sectionId}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getProposalCompliance = async (
	proposalId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/proposal/${proposalId}/complianceMatrix`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const runComplianceMatrix = async (
	proposalId: number,
	complianceId: number,
	sectionId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/proposal/${proposalId}/complianceMatrix/${complianceId}/section/${sectionId}/run-compliance`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getOrganizationInfo = async (
	token: string
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/organization`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const createOrganizationProfile = async (
	organizationProfile: OrganizationProfilePayload
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/organization-profile`,
			organizationProfile
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getOrganizationProfiles = async (): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/organization-profile`);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const deleteOrganizationProfile = async (
	organizationProfileId: number
): Promise<ApiResponse> => {
	const response = await api.delete<ApiResponse>(
		`/api/organization-profile/${organizationProfileId}`
	);
	return response.data;
};

export const updateUserProfile = async (
	userId: number,
	userProfile: UserProfilePayload,
	profileImage: File | null
) => {
	const response = await api.patch(
		`/api/users/${userId}`,
		{
			...userProfile,
			...(profileImage && { file: profileImage }),
		},
		profileImage !== null
			? {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			: {}
	);
	return response.data;
};

export const updateUserTour = async (userId: number, onboarding: string[]) => {
	try {
		const response = await api.patch(
			`/api/users/${userId}`,
			{
				onboarding,
			},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);

		return response.data;
	} catch (error) {
		console.log(error);
	}
};

export const updateUser = async (
	userId: number,
	updateUserPayload: UpdateUserPayload
) => {
	const response = await api.patch(`/api/users/${userId}`, {
		...updateUserPayload,
	});
	return response.data;
};

export const deleteUser = async (userId: number) => {
	const response = await api.delete(`/api/users/${userId}`);
	return response.data;
};

export const changePassword = async (
	userId: number,
	changePassword: ChangePasswordPayload
) => {
	try {
		const response = await api.patch(`/api/users/${userId}`, {
			...changePassword,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const changePhone = async (
	userId: number,
	changePhonePayload: {
		telephoneNumber: string;
		isTwoFactorEnabled?: boolean;
	},
	twoFactorToken: string
) => {
	const response = await api.patch(
		`/api/users/${userId}`,
		{
			...changePhonePayload,
		},
		{
			headers: {
				'2Factor-Token': twoFactorToken,
			},
		}
	);
	return response.data;
};

export const resetPassword = async (
	token: string,
	resetPassword: ResetPasswordPayload
) => {
	try {
		const response = await api.post(`/api/auth/reset-password?token=${token}`, {
			...resetPassword,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateOrganization = async (
	organizationId: number,
	organizationPayload: OrganizationPayload
) => {
	const response = await api.patch(`/api/organization/${organizationId}`, {
		...organizationPayload,
	});
	return response.data;
};

export const updateOrganizationalToneOfVoice = async (
	organizationId: number,
	toneOfVoicePayload: ToneOfVoicePayload
) => {
	const response = await api.patch(`/api/organization/${organizationId}`, {
		...toneOfVoicePayload,
	});
	return response.data;
};

export const updateOrganizationProfile = async (
	organizationProfileId: number,
	organizationProfilePayload: OrganizationProfilePayload
) => {
	const response = await api.patch(
		`/api/organization-profile/${organizationProfileId}`,
		{
			...organizationProfilePayload,
		}
	);
	return response.data;
};

export const getOrganizationInfoFromProfile = async (
	organizationProfile: File
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			'/api/ai/auto-fetch?type=org-info',
			{
				file: organizationProfile,
			},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getToneOfVoiceFromProposal = async (
	proposalFile: File
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			'/api/ai/auto-fetch?type=tov',
			{
				file: proposalFile,
			},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const generateSampleText = async (
	generateSampleTextPayload: GenerateSampleTextPayload
) => {
	const response = await api.post(`/api/ai/generate-sample`, {
		...generateSampleTextPayload,
	});
	return response.data;
};

export const getUsers = async (
	token: string,
	status?: UserStatus
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/users${status ? '?status=' + status : ''}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const inviteUser = async (inviteUser: InviteUserPayload) => {
	const response = await api.post(`/api/auth/invite-user`, {
		...inviteUser,
	});
	return response.data;
};

/**
 * Retrieves all suggested names for proposal from the API.
 *
 * @return {Promise<string[]>} An array of Template objects.
 */
export const generateProposalSuggestedNames = async (
	id: number
): Promise<ApiResponse> => {
	const response = await api.get(`/api/proposal/${id}/generate-name`);
	return response.data;
};

export const attachFormativeDocument = async (
	proposalId: number,
	documentId: number,
	category?: string
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/attach-document`,
			{
				document_id: documentId, // Sending documentId in the request body
				category: category,
			}
		);

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const detachFormativeDocument = async (
	proposalId: number,
	documentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/detach-document`,
			{
				document_id: documentId, // Sending documentId in the request body
			}
		);

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const manageProposalAssignment = async (
	proposalId: number,
	action: 'assign' | 'unassign',
	payload: {
		userId: number;
	}
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/manage-assignment?action=${action}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const managedAssignedProposals = async (
	action: ProposalAction,
	payload: {
		proposalIds: number[];
	}
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/manage-assigned-proposals?action=${action}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const removeSolicitation = async (payload: {
	documentIds: number[];
}): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/organization/solicitations/remove`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getAuditLogs = async (
	token: string,
	payload: AuditLogsPayload
): Promise<ApiResponse> => {
	try {
		const auditQuery = constructGetQueryForAuditLogs(payload);

		const response = await api.get<ApiResponse>(
			`/api/audit-logs?pageNumber=${payload.pageNumber + 1}&pageSize=${
				payload.pageSize
			}${auditQuery}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const exportAuditLogs = async (
	payload: AuditLogsPayload
): Promise<ArrayBuffer | undefined> => {
	try {
		const auditQuery = constructGetQueryForAuditLogs(payload);

		const response = await api.get<ArrayBuffer>(
			`/api/audit-logs/download?pageSize=100000${auditQuery}`,
			{
				responseType: 'blob', // Set responseType to 'blob'
				headers: {
					'Content-Type': 'application/csv',
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
	}
};

export const getPendingRequests = async (): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/invitation`);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const cancelInvite = async (inviteId: number): Promise<ApiResponse> => {
	try {
		const response = await api.delete<ApiResponse>(
			`/api/invitation/${inviteId}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getChartsRecommendation = async (
	file: File
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/ai/charts/recommend`,
			{
				file,
			},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const generateCharts = async (
	file: File,
	chart: string,
	plotSuggestion: string
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/ai/charts/generate`,
			{
				file,
				chart,
				plotSuggestion,
			},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const createAChart = async (
	proposalId: number,
	imageUrl: string,
	config: ChartsResponse
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/proposal/${proposalId}/charts`,
			{
				imageUrl,
				config,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getAllChartsOfAProposal = async (
	proposalId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(
			`/api/proposal/${proposalId}/charts`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

// DISCOVER PAGE API CALLS
export const useGetDiscoverOpportunitiesQuery = ({
	limit = 10,
	page = 1,
	action = 'default',
	sort = 'latest',
	onSuccess,
}: {
	limit?: number;
	page?: number;
	action?: 'matched' | 'favourite' | 'default';
	sort?: SortFilter;
	onSuccess?: () => void;
}) => {
	const filters = useSelector((state: any) => state.discover.filters);
	const categories = flattenObjectValuesToArray(filters.category);
	return useQuery<any, any>({
		queryKey: ['discoverOpportunities', action, filters, page, limit, sort],
		queryFn: () => {
			const params = {
				limit,
				page,
				action,
				...filters,
				category: categories,
				sort,
			};
			return api.get<ApiResponse>(`/api/discover`, {
				params,
			});
		},
		refetchOnWindowFocus: false,
		onSuccess: onSuccess,
		select(data) {
			return data.data.data;
		},
	});
};

export const useGetRegionsQuery = () => {
	return useQuery<RegionResponse, any>({
		queryKey: ['discover', 'regions'],
		queryFn: async () => {
			return api
				.get<ApiResponse>(`/api/discover/regions`)
				.then((res) => res.data.data as RegionResponse);
		},
	});
};

export const useGetCategoriesQuery = () => {
	return useQuery<CategoriesResponse, any>({
		queryKey: ['discover', 'categories'],
		queryFn: async () => {
			return api
				.get<ApiResponse>(`/api/discover/categories`, {
					params: { action: 'grouped' },
				})
				.then((res) => res.data.data as CategoriesResponse);
		},
		staleTime: 300000,
	});
};

export const useGetAgenciesQuery = () => {
	return useQuery<AgencyResponse, any>({
		queryKey: ['discover', 'agencies'],
		queryFn: async () => {
			return api
				.get<ApiResponse>(`/api/discover/agencies`)
				.then((res) => res.data.data as AgencyResponse);
		},
		staleTime: 300000,
	});
};

export const useToggleFavouriteOpportunityMutation = () => {
	const client = useQueryClient();
	return useMutation({
		mutationFn: ({ id }: { id: string }) => {
			return api.post<ApiResponse>(`/api/discover/${id}/toggle-favourite`);
		},
		onSuccess() {
			client.invalidateQueries(['discoverOpportunities']);
		},
	});
};

export const useSaveSearchMutation = () => {
	const client = useQueryClient();
	return useMutation({
		mutationFn: (payload: { label: string; query: string }) => {
			return api.post<ApiResponse>(`/api/discover/search-queries`, payload);
		},
		onSuccess() {
			client.invalidateQueries(['savedSearches']);
		},
	});
};

export const useGetSavedSearchesQuery = () => {
	return useQuery<DiscoverPaginateResponse<SearchQuery[]>, any>({
		queryKey: ['savedSearches'],
		queryFn: async () => {
			return api
				.get<ApiResponse>(`/api/discover/search-queries`)
				.then(
					(res) => res.data.data as DiscoverPaginateResponse<SearchQuery[]>
				);
		},
		staleTime: 300000,
	});
};

export const useDeleteSavedSearchMutation = () => {
	const client = useQueryClient();
	return useMutation({
		mutationFn: (id: string) => {
			return api.delete<ApiResponse>(`/api/discover/search-queries/${id}`);
		},
		onSuccess() {
			client.invalidateQueries(['savedSearches']);
		},
	});
};

export const useGetFundingTypesQuery = () => {
	return useQuery<TypeResponse, any>({
		queryKey: ['discover', 'fundingTypes'],
		queryFn: async () => {
			return api
				.get<ApiResponse>(`/api/discover/types`)
				.then((res) => res.data.data as TypeResponse);
		},
		staleTime: 300000,
	});
};

export const getMatchedOpportunity = async (
	token: string,
	data: {
		title: string;
		summary: string;
		eligibility: string;
	},
	callback: (data: {
		status: 'success' | 'error';
		message: string;
		data: string;
	}) => void
) => {
	try {
		const response = await fetch(
			`${import.meta.env.VITE_SERVER_URL}/api/ai/recommendation`,
			{
				headers: {
					Authorization: `Bearer ${token}` || 'Bearer',
					'Content-Type': 'application/json',
					Accept: '*/*',
					'Accept-Encoding': 'gzip, deflate, br',
					Connection: 'keep-alive',
				},
				method: 'POST',
				body: JSON.stringify(data),
			}
		);

		// Check if the response is OK
		if (!response.ok) {
			throw new Error('Error fetching matched opportunity!');
		}

		// Create a ReadableStream from the response body
		const reader = response.body?.getReader();

		// Define a function to continuously read chunks of text from the stream
		const readStream = async () => {
			const condition = true;
			while (condition) {
				if (!reader) return;
				const { done, value } = await reader.read();

				if (done) {
					// Stream has ended
					return;
				}

				// Convert the chunk of data to text
				const textChunk = new TextDecoder().decode(value);

				// Extract the tokens from the text
				let dataArray = textChunk.split('\n\n');
				dataArray = dataArray.filter((item) => item !== '');
				const tokens = dataArray.filter((item) => item.includes('token'));
				const parsedTokens = tokens.map((item) => {
					const parsedString = JSON.parse(
						item.trim().replace('data: ', '').replace(/null$/, '')
					);
					return parsedString?.data?.token || '';
				});
				const result = parsedTokens.join(' ');

				// Pass the tokens to the callback function
				callback({
					status: 'success',
					message: 'Data fetched successfully',
					data: result,
				});
			}
		};
		// Start reading from the stream
		await readStream();
	} catch (error) {
		callback({
			status: 'error',
			message: String(error),
			data: '',
		});
		console.error('Fetch error:', error);
	}
};

export const createDocument = async (
	payload: ManualSolicitationPayload
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(`/api/document`, payload);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateDocument = async (
	payload: ManualSolicitationPayload,
	documentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/document/${documentId}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateDocumentName = async (
	documentName: string,
	documentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/document/${documentId}`,
			{
				alias: documentName,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const exportManualSolicitation = async (
	content: CreatePDFPayload,
	type: DocumentType = 'pdf'
): Promise<ArrayBuffer | undefined> => {
	try {
		const response = await api.post<ArrayBuffer>(
			`/api/document/export?type=${type}`,
			content,
			{
				responseType: 'blob',
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
	}
};

export const getProposalsStats = async (): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>('/api/proposal/stats');
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const deleteDocumentById = async (
	documentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.delete<ApiResponse>(
			`/api/document/${documentId}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getUsersStats = async () => {
	const response = await api.get(`/api/users/stats`);
	return response.data;
};

export const getTeams = async (): Promise<ApiResponse> => {
	const response = await api.get(`/api/teams`);
	return response.data;
};

export const createTeam = async (
	payload: ManageTeamPayload
): Promise<ApiResponse> => {
	const response = await api.post(`/api/teams`, {
		...payload,
	});
	return response.data;
};

export const updateTeam = async (
	payload: ManageTeamPayload,
	teamId: number
): Promise<ApiResponse> => {
	const response = await api.patch(`/api/teams/${teamId}`, {
		...payload,
	});
	return response.data;
};

export const deleteTeam = async (teamId: number): Promise<ApiResponse> => {
	const response = await api.delete(`/api/teams/${teamId}`);
	return response.data;
};

export const updateProposalAssignment = async (
	payload: {
		userIds: number[];
		teamIds?: number[];
	},
	proposalId: number
) => {
	const response = await api.post<ApiResponse>(
		`/api/proposal/${proposalId}/manage-assignment`,
		payload
	);
	return response.data;
};

export const sendOTP = async (payload: {
	email?: string;
	telephoneNumber?: string;
}) => {
	const response = await api.post<ApiResponse>(`/api/auth/send-otp`, payload);
	return response.data;
};

export const verifyOTP = async (payload: {
	otp: string;
	otpEncodedToken: string;
}) => {
	const response = await api.post<ApiResponse>(`/api/auth/verify-otp`, payload);
	return response.data;
};

export const sendFeedbackEmail = async (payload: {
	email: string;
	feedback: string;
}): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/users/email?action=feedback`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const sendInquiryEmail = async (payload: {
	email: string;
}): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/users/email?action=inquiry`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const editProposalTemplate = async (
	sections: SectionBuilder[],
	deletedSections: string[],
	proposalId: number
): Promise<ApiResponse> => {
	const response = await api.post<ApiResponse>(
		`/api/proposal/${proposalId}/edit-proposal-template`,
		{
			deletedSections,
			sections,
		}
	);
	return response.data;
};

export const getAllSectionsOfProposalTemplate = async (
	proposalId: number
): Promise<ApiResponse> => {
	const response = await api.get<ApiResponse>(
		`/api/proposal/${proposalId}/section`
	);
	return response.data;
};

export const createClipboard = async (
	proposalId: number,
	text: string
): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(`/api/clipboard`, {
			proposalId: proposalId,
			text: text,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getClipboards = async (
	proposalId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/clipboard`, {
			params: { proposalId: proposalId },
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateClipboard = async (
	clipboardId: number,
	text: string
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/clipboard/${clipboardId}`,
			{
				text: text,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const deleteClipboard = async (
	clipboardId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.delete<ApiResponse>(
			`/api/clipboard/${clipboardId}`
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const createKnowledgeBaseOfJourney = async (
	documentId: number,
	payload: {
		file: File[];
		knowledgeUrl?: string;
	}
): Promise<ApiResponse> => {
	try {
		// Create formData
		const formData = new FormData();

		// Append files to formData
		payload.file.forEach((file) => {
			formData.append('file', file);
		});

		// Append knowledgeUrl if it exists
		if (payload.knowledgeUrl) {
			formData.append('knowledgeUrl', payload.knowledgeUrl);
		}

		// Send formData via POST
		const response = await api.post<ApiResponse>(
			`/api/document/${documentId}/knowledge-base`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);

		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const createFolder = async (payload: {
	parentId?: number;
	name: string;
}): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(`/api/folder`, payload);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateFolder = async (
	folderId: number,
	payload: {
		name?: string;
		status?: 'active' | 'inactive';
	}
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/folder/${folderId}`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getFolders = async (payload: {
	parentId?: number;
}): Promise<ApiResponse> => {
	try {
		const response = await api.get<ApiResponse>(`/api/folder`, {
			params: payload,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const moveFolders = async (payload: {
	parentId?: number | null;
	folderIds: number[];
}): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(`/api/folder/move`, payload);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const moveDocuments = async (payload: {
	folderId?: number;
	documentIds: number[];
}): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(
			`/api/document/move-document`,
			payload
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const getDecDocuments = async (payload: {
	query: string;
	organizationId: string;
	geographicLocations: string[];
	publicationStartDate?: string;
	publicationEndDate?: string;
}): Promise<ApiResponse> => {
	try {
		const response = await api.post<ApiResponse>(`/api/dec/search`, payload);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const importExternalDocument = async (sourceLink: string) => {
	try {
		const response = await api.get<ApiResponse>('/api/dec/pdf-download', {
			params: { url: sourceLink },
			responseType: 'arraybuffer',
		});
		return response;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};

export const updateDocumentCategory = async (
	category: string,
	documentId: number
): Promise<ApiResponse> => {
	try {
		const response = await api.patch<ApiResponse>(
			`/api/document/${documentId}`,
			{
				category,
			}
		);
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			return error.response?.data;
		}
		return {
			statusCode: 500, // Internal Server Error or another suitable error code
			message: 'Unexpected error occurred',
			data: {},
		};
	}
};
