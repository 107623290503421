import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import { useEffect, useState } from 'react';
import PrivateTemplateBody from '@/components/TemplateBuilder/TemplateBody/PrivateTemplateBody';
import TemplateBody from '@/components/TemplateBuilder/TemplateBody/TemplateBody';
import {
	PrivateTemplatePagination,
	PreBuildTemplatePagination,
} from '@/components/TemplateBuilder/TemplateDnd/TemplatePagination';
import TemplateHeader from '@/components/TemplateBuilder/TemplateHeader/TemplateHeader';
import {
	handleGeneralError,
	handleApiError,
	handleUnexpectedError,
} from '@/lib/functions/funcUtils';
import {
	fetchAllTemplates,
	getTagByOrganization,
} from '@/lib/functions/TemplateBuilder/templateCalls';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import {
	TagField,
	TemplateLibrary as TemplateType,
} from '@/lib/types/TemplateBuilder/templateTypes';
import {
	resetTemplateBuilder,
	setPrivateTemplateData,
	setTemplateData,
	setTemplateTagOptions,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { RootState } from '@/redux/store/store';
import { useQuery } from 'react-query';
import { ErrorResponse } from '@/lib/types/apiTypes';
import { defaultTemplateTagOptions } from '@/constants/templateBuilderData';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import { motion } from 'framer-motion';
import { iCSlideInDelay } from '@/constants/variants';
import { Page } from '@/constants/Onboarding/types';
import { useTour } from '@/lib/hooks/useTour';
import { clearProposal } from '@/redux/slices/proposalSlice';

const TemplateBuilder = () => {
	/**
	 * check user authentication
	 */
	const auth = useAppSelector((root: RootState) => root.auth.isAuthenticated);
	const orgIdentifier = useOrganizationId();
	const dispatch = useAppDispatch();

	/**
	 * template pagination and filter setup
	 */
	const [templateCurrentPage, setTemplateCurrentPage] = useState(1);

	const [templateHasNextPage, setTemplateNextPage] = useState(false);

	const [templateHasPrevPage, setTemplatePreviousPage] = useState(false);

	const [templateTotalPages, setTemplateTotalPages] = useState(0);

	const [templateSorting, setTemplateSorting] = useState<string>('desc');

	const [templatePageSize, setTemplatePageSize] = useState<string>('10');

	const [templateTagSearch, setTemplateTagSearch] = useState<
		string[] | undefined
	>();

	// private template
	const [privateTemplateCurrentPage, setPrivateTemplateCurrentPage] =
		useState(1);
	const [privateTemplateHasNextPage, setPrivateTemplateNextPage] =
		useState(false);
	const [privateTemplateTotalPages, setPrivateTemplateTotalPages] = useState(0);

	const [privateTemplateHasPrevPage, setPrivateTemplatePreviousPage] =
		useState(false);
	const [privateTemplateSorting, setPrivateTemplateSorting] =
		useState<string>('desc');
	const [privateTemplatePageSize, setPrivateTemplatePageSize] =
		useState<string>('3');

	const [templateSearch, setTemplateSearch] = useState<string | undefined>();

	const [templatePageSizeLoading, setTemplatePageSizeLoading] =
		useState<boolean>(false);
	const [privateTemplatePageSizeLoading, setPrivateTemplatePageSizeLoading] =
		useState<boolean>(false);
	/**
	 * template tag filter
	 */

	const templateTagFilter = useAppSelector(
		(root: RootState) => root.templates.templateTagFilter
	);
	// organization identifier
	const organizationIdentifier = useOrganizationId();

	useTour(Page.TemplateBuilder);
	/**
	 * fetch templates
	 */

	const templateQuery = useQuery(
		['templates'],
		async () => {
			const param = {
				pageNumber: templateCurrentPage,
				pageSize: templatePageSize,
				sort: templateSorting || 'desc',
				search: templateSearch,
				isLive: 'true',
				organizationIdentifier: null,
				tag: templateTagSearch,
				filter: 'pre-built',
			};
			const { data } = await fetchAllTemplates(param);
			setTemplatePageSizeLoading(false);
			setTemplateCurrentPage(data.currentPage);
			setTemplateNextPage(data.hasNextPage);
			setTemplatePreviousPage(data.hasPrevPage);
			setTemplateTotalPages(data.totalPages);
			return data.templates;
		},
		{
			cacheTime: 0,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: auth,
			onSuccess: (data: TemplateType[]) => {
				// Invalidate and refetch the data query when the mutation is successful
				dispatch(setTemplateData(data));
			},
			onError: (error: any) => {
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
		}
	);
	/**
	 * private templates
	 */
	const privateTemplateQuery = useQuery(
		['privatetemplates'],
		async () => {
			const param = {
				pageNumber: privateTemplateCurrentPage,
				sort: privateTemplateSorting || 'desc',
				search: templateSearch,
				pageSize: privateTemplatePageSize,
				isLive: 'all',
				organizationIdentifier: organizationIdentifier,
				tag: templateTagSearch,
				filter: 'organization',
			};
			const { data } = await fetchAllTemplates(param);
			setPrivateTemplatePageSizeLoading(false);
			setPrivateTemplateCurrentPage(data.currentPage);
			setPrivateTemplateNextPage(data.hasNextPage);
			setPrivateTemplatePreviousPage(data.hasPrevPage);
			setPrivateTemplateTotalPages(data.totalPages);
			return data.templates;
		},
		{
			cacheTime: 0,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: auth,
			onSuccess: (data: TemplateType[]) => {
				// Invalidate and refetch the data query when the mutation is successful
				dispatch(setPrivateTemplateData(data));
			},
			onError: (error: any) => {
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
		}
	);

	useEffect(() => {
		templateQuery.refetch();
	}, [
		templateCurrentPage,
		templateSorting,
		templateSearch,
		templatePageSize,
		templateTagSearch,
	]);
	useEffect(() => {
		privateTemplateQuery.refetch();
	}, [
		privateTemplateCurrentPage,
		privateTemplateSorting,
		templateSearch,
		privateTemplatePageSize,
		templateTagSearch,
	]);

	useEffect(() => {
		dispatch(resetTemplateBuilder());
		dispatch(clearProposal());
	}, [dispatch]);
	/**
	 * handle template filter ,search and sorting
	 */
	const handleTemplateSearch = (value: string) => {
		setTemplateSearch(value);
		setTemplateCurrentPage(1);
		setPrivateTemplateCurrentPage(1);
	};
	useEffect(() => {
		setTemplateTagSearch(templateTagFilter);
		setTemplateCurrentPage(1);
		setPrivateTemplateCurrentPage(1);
	}, [templateTagFilter]);
	const handleTemplateSorting = (value: string) => {
		setTemplateSorting(value);
		setTemplateCurrentPage(1);
	};
	const handlePrivateTemplateSorting = (value: string) => {
		setPrivateTemplateSorting(value);
		setPrivateTemplateCurrentPage(1);
	};
	const handleTemplatePageSize = (value: string) => {
		setTemplatePageSize(value);
		setTemplatePageSizeLoading(true);
		setTemplateCurrentPage(1);
	};
	const handlePrivateTemplatePageSize = (value: string) => {
		setPrivateTemplatePageSize(value);
		setPrivateTemplatePageSizeLoading(true);
		setPrivateTemplateCurrentPage(1);
	};
	/**
	 * fetch tags
	 */
	const templateTagQuery = useQuery(
		['template_tags'],
		async () => {
			const { data } = await getTagByOrganization(`templates_${orgIdentifier}`);

			return data;
		},
		{
			cacheTime: 0,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: auth,
			onSuccess: (data: any) => {
				const response = data.tags as TagField;
				const newArray: any = Object.assign([], defaultTemplateTagOptions);
				if (response) {
					response.tags.length > 0 &&
						response.tags.forEach((v) => {
							if (!defaultTemplateTagOptions.some((item) => item.value === v)) {
								const dbTags = {
									organizationIdentifier: response.organizationIdentifier,
									label: v,
									value: v,
								};
								newArray.push(dbTags);
							}
						});
				}

				dispatch(setTemplateTagOptions(newArray));
			},
			onError: (error: any) => {
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
		}
	);
	return (
		<div className="flex flex-col bg-[#f5f5f5] rounded-tl-[30px] h-screen overflow-hidden">
			<>
				{((!templatePageSizeLoading && templateQuery.isFetching) ||
					(!privateTemplatePageSizeLoading &&
						privateTemplateQuery.isFetching) ||
					(!templatePageSizeLoading && templateQuery.isLoading) ||
					(!privateTemplatePageSizeLoading && privateTemplateQuery.isLoading) ||
					templateTagQuery.isLoading) && <LoaderTransparent />}
				<TemplateHeader
					templateSearch={templateSearch}
					handleTemplateSearch={handleTemplateSearch}
				/>

				<motion.div
					className="flex flex-col overflow-y-auto hidden-scroll h-max"
					variants={iCSlideInDelay}
					initial="initial"
					animate="animate"
					exit="exit"
				>
					<div className="flex flex-col gap-5">
						<PrivateTemplateBody
							privateTemplatesData={privateTemplateQuery.data}
							handlePrivateTemplateSorting={handlePrivateTemplateSorting}
							privateTemplatePageSizeLoading={privateTemplatePageSizeLoading}
							privateTemplatePageSize={privateTemplatePageSize}
						/>
						<PrivateTemplatePagination
							setPrivateTemplateCurrentPage={setPrivateTemplateCurrentPage}
							privateTemplateCurrentPage={privateTemplateCurrentPage}
							privateTemplateHasPrevPage={privateTemplateHasPrevPage}
							privateTemplateHasNextPage={privateTemplateHasNextPage}
							privateTemplateTotalPages={privateTemplateTotalPages}
							handlePrivateTemplatePageSize={handlePrivateTemplatePageSize}
							privateTemplatePageSize={privateTemplatePageSize}
						/>
					</div>
					<div className="flex flex-col mb-20">
						<TemplateBody
							templatesData={templateQuery.data}
							handleTemplateSorting={handleTemplateSorting}
							templatePageSizeLoading={templatePageSizeLoading}
							templatePageSize={templatePageSize}
						/>
						<PreBuildTemplatePagination
							templateCurrentPage={templateCurrentPage}
							setTemplateCurrentPage={setTemplateCurrentPage}
							templateHasNextPage={templateHasNextPage}
							templateHasPrevPage={templateHasPrevPage}
							templateTotalPages={templateTotalPages}
							handleTemplatePageSize={handleTemplatePageSize}
							templatePageSize={templatePageSize}
						/>
					</div>
				</motion.div>
			</>
		</div>
	);
};

export default TemplateBuilder;
