import { useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';

function useDebounce<T extends (...args: any[]) => void>(
	fn: T,
	delay: number
): (...args: Parameters<T>) => void {
	// Use a ref to store the function
	const fnRef = useRef(fn);
	fnRef.current = fn;

	// Set up the debounced function
	// eslint-disable-next-line
	const debouncedFn = useCallback(
		debounce((...args: Parameters<T>) => {
			fnRef.current(...args);
		}, delay),
		[delay]
	);

	// Clean up the debounced function on component unmount
	useEffect(() => {
		return () => {
			debouncedFn.cancel();
		};
	}, [debouncedFn]);

	return debouncedFn;
}

export default useDebounce;
