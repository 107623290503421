import { FC, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import { resetTemplateBuilder } from '@/redux/slices/TemplateBuilder/templateSlice';
import TemplateBuilder from '@/pages/TemplateBuilder/TemplateBuilder';
import { RootState } from '@/redux/store/store';
import TemplateSetUp from '@/components/TemplateBuilder/TemplateSetUp/TemplateSetUp';
import { useSelector } from 'react-redux';
import { clearProposal } from '@/redux/slices/proposalSlice';

interface AppPaneProps {
	defaultTab: 'templateLibrary' | 'newTemplate' | 'editTemplate';
}

const TemplatePane: FC<AppPaneProps> = ({ defaultTab }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [selectedTab, setSelectedTab] = useState(defaultTab);
	const templateMode = useSelector(
		(state: RootState) => state.templates.templateMode
	);
	const editTemplateId = useAppSelector(
		(root: RootState) => root.templates.editTemplateId
	);

	const handleTabChange = useCallback(
		(newTabIndex) => {
			setTimeout(() => {
				setSelectedTab(newTabIndex);
				if (newTabIndex === 'templateLibrary') {
					dispatch(resetTemplateBuilder());
					dispatch(clearProposal());
				}
			}, 250);
		},
		[dispatch]
	);

	useEffect(() => {
		handleTabChange(defaultTab);
	}, [defaultTab, handleTabChange]);

	return (
		<AppTabs
			defaultTab={defaultTab}
			selectedTab={selectedTab}
			handleTabChange={handleTabChange}
			type="main"
			tabs={[
				{
					label: 'Template Library',
					value: 'templateLibrary',
					content: <TemplateBuilder />,
					onClick: () => navigate(`/templates`),
				},

				{
					label: templateMode === 'edit' ? 'Edit Template' : 'New Template',
					value: 'newTemplate',
					content: <TemplateSetUp />,
					onClick: () =>
						templateMode === 'edit'
							? navigate(`/template/edit/${editTemplateId}`)
							: navigate(`/template/new`),
				},
			]}
		/>
	);
};

export default TemplatePane;
