import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

const NotificationDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'notification'
	);
	const notification = useSelector(
		(root: RootState) => root.page.dialog?.notificationObj
	);
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] xl:max-w-[600px] p-6 rounded-2xl bg-white">
				<DialogHeader className="font-bold text-secondary dynamic-large">
					{notification?.title}
				</DialogHeader>
				<p className=" text-[#6D7D86]">{notification?.description}</p>
				<div className="flex items-center justify-end gap-3 mt-2">
					{!notification?.hideCancel && (
						<SecondaryButton
							onClick={() => {
								notification?.onCancel && notification?.onCancel();
								dispatch(closeDialog());
							}}
						>
							{`${notification?.cancelText || 'No'}`}
						</SecondaryButton>
					)}
					{!notification?.hideConfirm && (
						<PrimaryButton
							onClick={() => {
								notification?.onConfirm && notification?.onConfirm();
								dispatch(closeDialog());
							}}
						>
							{`${notification?.confirmText || 'Yes'}`}
						</PrimaryButton>
					)}
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default NotificationDialog;
