const Ready = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM2.41957 10C2.41957 14.1866 5.81345 17.5804 10 17.5804C14.1866 17.5804 17.5804 14.1866 17.5804 10C17.5804 5.81345 14.1866 2.41957 10 2.41957C5.81345 2.41957 2.41957 5.81345 2.41957 10Z"
				fill="#84CE53"
			/>
			<path
				d="M5.55469 11.0539L7.07161 13.3331L14.4436 8.88867"
				stroke="#84CE53"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Ready;
