import styles from './ErrorPage.module.css';
import { cn } from '@/lib/utils';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useErrorBoundary } from 'react-error-boundary';
import FailureIcon from '@/components/ui/icons/FailureIcon';
import { Home } from 'lucide-react';
import { useNavigate } from 'react-router';
import customToast from '@/components/CustomToast/CustomToast';
const ErrorPage = () => {
	const { resetBoundary } = useErrorBoundary();
	const navigate = useNavigate();

	return (
		<div
			className={cn(styles.container, 'flex h-screen w-screen')}
			role="alert"
		>
			<div className="flex flex-col bg-white w-[700px] 2xl:w-[800px] gap-4 p-6 m-auto rounded-3xl items-center">
				<FailureIcon />
				<div className="flex flex-col items-center">
					<h2 className="font-bold text-black dynamic-xl-large">Oops!</h2>
					<h2 className="font-bold text-black dynamic-xl-large">
						Something went wrong.
					</h2>
				</div>
				<div className="flex flex-col items-center">
					<p className="text-black dynamic-medium text-center">
						Sorry there was an issue logging you in. This could be due to a
						browser issue. Please clear your browser cache and cookies. If that
						doesn't work you can try to disable extensions like ad blockers and
						try again. If you are still having issues please use the chat button
						below to get in touch with our team.
					</p>
				</div>
				<PrimaryButton
					className="flex items-center"
					onClick={() => {
						customToast.dismiss();
						resetBoundary();
						navigate('/');
					}}
				>
					<Home size={18} />
					Go to Home
				</PrimaryButton>
			</div>
		</div>
	);
};
export default ErrorPage;
