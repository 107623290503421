const EditCircled = () => {
	return (
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_7218_16948"
				maskUnits="userSpaceOnUse"
				x="0"
				y="-1"
				width="21"
				height="21"
			>
				<rect
					x="0.511719"
					y="-0.000976562"
					width="20"
					height="20"
					fill="#D9D9D9"
				/>
			</mask>
			<g mask="url(#mask0_7218_16948)">
				<path
					d="M20.5117 9.99902C20.5117 15.5219 16.0346 19.999 10.5117 19.999C4.98887 19.999 0.511719 15.5219 0.511719 9.99902C0.511719 4.47618 4.98887 -0.000976562 10.5117 -0.000976562C16.0346 -0.000976562 20.5117 4.47618 20.5117 9.99902ZM3.01172 9.99902C3.01172 14.1412 6.36958 17.499 10.5117 17.499C14.6539 17.499 18.0117 14.1412 18.0117 9.99902C18.0117 5.85689 14.6539 2.49902 10.5117 2.49902C6.36958 2.49902 3.01172 5.85689 3.01172 9.99902Z"
					fill="#6279E6"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.51172 14.329V11.4829L11.3563 6.39238C11.4878 6.25355 11.6339 6.15328 11.7947 6.09158C11.9554 6.02988 12.1162 5.99902 12.277 5.99902C12.4523 5.99902 12.6204 6.03373 12.7811 6.10315C12.9419 6.17257 13.088 6.27669 13.2196 6.41552L14.0306 7.27166C14.1476 7.4105 14.2389 7.56475 14.3047 7.73444C14.3704 7.90413 14.4033 8.07381 14.4033 8.2435C14.4033 8.41318 14.3741 8.58672 14.3156 8.76412C14.2572 8.94152 14.1622 9.09963 14.0306 9.23847L9.20801 14.329H6.51172ZM7.82698 12.9413H8.65998L12.2112 9.2389L11.8166 8.79926L11.4001 8.38276L7.82698 12.062V12.9413Z"
					fill="#6279E6"
				/>
			</g>
		</svg>
	);
};

export default EditCircled;
