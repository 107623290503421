import { Checkbox } from '@/components/ui/checkbox';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { MDXProvider } from '@mdx-js/react';
import TermsMDX from '@/components/mdx/terms.mdx';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { closeDialog } from '@/redux/slices/pageSlice';
import { handleLoginResponse } from '@/lib/functions/funcUtils';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';

const TrialTermsDialog = () => {
	const isOpen = useAppSelector(
		(state) => state.page.dialog?.type === 'trialTerms'
	);
	const loginResponse = useAppSelector(
		(state) => state.page.dialog?.loginResponse
	);
	const trialBannerDelayDate = useSelector(
		(root: RootState) => root.auth.trialBannerDelayDate
	);
	const [checked, setChecked] = useState(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	return (
		<Dialog open={isOpen}>
			<DialogContent
				hideCloseBtn
				className="max-w-[1000px] p-6 rounded-2xl shadow-main bg-white"
			>
				<DialogHeader>
					<DialogTitle className="font-bold mb-2 dynamic-large text-secondary">
						EULA and Terms of Service
					</DialogTitle>
					<DialogDescription>
						<p className="text-black">
							Please carefully read the following agreement, which governs your
							use of our Software as a Service (SaaS) application. To continue,
							you must confirm that you have read, understood, and agree to be
							bound by the terms and conditions outlined in this agreement. If
							you do not agree to these terms, please refrain from using our
							services.
						</p>
						<div className="mdx-wrapper rounded-xl my-4 border border-[#D3DDE2] bg-[#eaf1fc] p-4 h-[350px] overflow-y-scroll">
							<MDXProvider>
								<TermsMDX />
							</MDXProvider>
						</div>
						<div className="items-top flex space-x-3 items-center">
							<Checkbox
								checked={checked}
								onCheckedChange={() => setChecked(!checked)}
								id="terms1"
								className="border-black data-[state=checked]:text-white"
							/>
							<div className="grid gap-1.5">
								<label
									htmlFor="terms1"
									className="text-sm font-medium text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70 leading-4"
								>
									I have read, understood, and agree to the terms and conditions
									of the End User License Agreement and Terms of Service. I have
									the authority from my company to sign this agreement.
								</label>
							</div>
						</div>
						<div className="flex items-center justify-end">
							<PrimaryButton
								disabled={!checked}
								onClick={() => {
									if (!loginResponse) return;
									handleLoginResponse(
										dispatch,
										navigate,
										loginResponse,
										trialBannerDelayDate
									);
									navigate('/');
									dispatch(closeDialog());
								}}
							>
								Completed
							</PrimaryButton>
						</div>
					</DialogDescription>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default TrialTermsDialog;
