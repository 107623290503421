import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { FC, useEffect, useState } from 'react';
import styles from './TrialEndDialog.module.css';
import { sendFeedbackEmail, sendInquiryEmail } from '@/lib/functions/apiCalls';
import { closeDialog, openDialog } from '@/redux/slices/pageSlice';
import { Textarea } from '@/components/ui/textarea';
import customToast from '@/components/CustomToast/CustomToast';
import { useNavigate } from 'react-router';
import { clearState, handleResponse } from '@/lib/functions/funcUtils';
import DialogLoader from '@/components/Loader/DialogLoader/DailogLoader';
import { BadgePercentIcon } from 'lucide-react';

interface TrialEndDialogProps {}

const TrialEndDialog: FC<TrialEndDialogProps> = () => {
	const isOpen = useAppSelector(
		(state) => state.page.dialog?.type === 'trialEnd'
	);

	const [showFeedback, setShowFeedback] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [showLogout, setShowLogout] = useState(false);
	const [showMessage, setShowMessage] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const organization = useAppSelector((state) => state.auth.user?.organization);
	const email = useAppSelector((root) => root.auth.user?.email);

	const plan = organization?.plan;
	const remainingDays = organization?.daysUntilTrialEnds;

	useEffect(() => {
		const checkPlanCompleted = async () => {
			if (remainingDays && remainingDays <= 0) {
				dispatch(openDialog({ type: 'trialEnd' }));
			}
		};
		if (plan?.type === 'trial') checkPlanCompleted();
	}, [plan, remainingDays, dispatch]);

	const handleSendFeedback = async () => {
		if (!feedback || !email) return;

		try {
			setIsLoading(true);
			const response = await sendFeedbackEmail({
				email: email,
				feedback: feedback,
			});
			handleResponse(response, (response.data as string) || response.message);
			setShowMessage((response.data as string) || response.message);
			setShowLogout(true);
			setIsLoading(false);
		} catch (error: unknown) {
			customToast.error({
				title: 'Something went wrong',
			});
			setIsLoading(false);
		}
	};

	const handleSendInquiry = async () => {
		if (!email) return;
		try {
			setIsLoading(true);
			const response = await sendInquiryEmail({
				email: email,
			});
			handleResponse(response, (response.data as string) || response.message);
			setShowMessage((response.data as string) || response.message);
			setShowLogout(true);
			setShowFeedback(true);
			setIsLoading(false);
		} catch (error: unknown) {
			customToast.error({
				title: 'Something went wrong',
			});
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			navigate('');
			setFeedback('');
			setShowMessage('');
			setShowLogout(false);
			setShowFeedback(false);
			setIsLoading(false);
		}
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent
				hideCloseBtn
				className="max-w-[800px] p-8 rounded-2xl shadow-main bg-white text-black"
			>
				{/* Lottie Here */}
				<div>
					<h2 className={styles.heading}>
						Your Trial Has Ended – Unlock the Full Power of Grant Assistant
					</h2>
					<p className="my-3">
						We’re excited to invite you to continue using Grant Assistant with a
						full subscription! With our full version, you’ll enjoy unlimited
						proposals, unlimited document uploads, and access to all our
						advanced AI features to craft persuasive and winning proposals.
					</p>
				</div>
				<div>
					<h2 className={styles.heading}>
						Special Offer: Save 10% on Your Subscription!
					</h2>
					<p className="my-3">
						To thank you for trying Grant Assistant, we’d like to offer you an
						exclusive 10% discount on your first year.
					</p>
				</div>
				<div className="flex items-center gap-4">
					{!showFeedback && (
						<SecondaryButton onClick={() => setShowFeedback(true)}>
							No Thanks
						</SecondaryButton>
					)}
					<PrimaryButton onClick={handleSendInquiry}>
						<BadgePercentIcon /> GET MY 10% DISCOUNT
					</PrimaryButton>
				</div>
				{showFeedback && (
					<>
						<Separator className=" border border-b-0 border-[#D3DDE2]" />
						{showLogout ? (
							<div className="flex flex-col items-start gap-3">
								<p className="text-black">{showMessage}</p>
								<PrimaryButton
									onClick={() => {
										clearState(dispatch);
										dispatch(closeDialog());
										navigate('/login');
									}}
								>
									Logout
								</PrimaryButton>
							</div>
						) : (
							<div className="flex flex-col gap-4">
								<div>
									<h3 className="font-bold">
										NOT INTERESTED? We'd Love Your Feedback
									</h3>
									<p className="mt-2">
										We’re sorry to hear you’re not interested in continuing.
										Could you please share why?
									</p>
								</div>

								<div className="flex flex-col items-end gap-3">
									<Textarea
										value={feedback}
										onChange={(e) => setFeedback(e.target.value)}
										placeholder="Enter your feedback"
										className="p-4 rounded-2xl resize-none dynamic-text border-[#d3dde2] bg-white focus-visible:ring-0
											 focus-visible:shadow-none focus-visible:ring-offset-0 min-h-[150px] hidden-scroll"
									/>
									<SecondaryButton
										disabled={!feedback}
										onClick={handleSendFeedback}
									>
										Send Feedback
									</SecondaryButton>
								</div>
							</div>
						)}
					</>
				)}
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default TrialEndDialog;
