import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { FC, ReactNode, useEffect, useState } from 'react';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import LoaderPanel from '@/components/Loader/LoaderPanel/LoaderPanel';
import { Input } from '@/components/ui/input';
import { Search, X } from 'lucide-react';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import FallbackAvatar from '@/components/FallbackAvatar/FallbackAvatar';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { updateProposalAssignment } from '@/lib/functions/apiCalls';
import { Team, User } from '@/lib/types/apiTypes';
import { useQueryClient } from 'react-query';
import { fetchProposalById } from '@/redux/slices/proposalSlice';

interface ProposalAssignmentDrawerProps {
	users: User[];
	teams: Team[];
	triggerButton: ReactNode;
	proposalId: number;
	assignedUsers?: number[];
	assignedTeams?: number[];
	createdBy?: number;
	fetchProposalOnAssignment?: boolean;
}

const ProposalAssignmentDrawer: FC<ProposalAssignmentDrawerProps> = ({
	users,
	teams,
	triggerButton,
	proposalId,
	assignedUsers,
	assignedTeams,
	createdBy,
	fetchProposalOnAssignment = false,
}) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const [open, setOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedUsers, setSelectedUsers] = useState<number[]>(
		assignedUsers || []
	);
	const [selectedTeams, setSelectedTeams] = useState<number[]>(
		assignedTeams || []
	);
	const [selectedTeamMembers, setSelectedTeamMembers] = useState<number[]>([]);
	const [searchTeamQuery, setSearchTeamQuery] = useState<string>('');
	const [searchUserQuery, setSearchUserQuery] = useState<string>('');

	const filteredTeams = teams?.filter((team) =>
		team.name.toLowerCase().includes(searchTeamQuery.toLowerCase())
	);

	const filteredUsers = users.filter((user) =>
		user.name.toLowerCase().includes(searchUserQuery.toLowerCase())
	);

	const getTeamMemberIds = (teamId) => {
		const selectedTeam = teams.find((team) => team.id === teamId);
		return selectedTeam
			? selectedTeam.teamMembers.map((teamMember) => teamMember.id)
			: [];
	};
	const getSelectedTeamMembers = (selectedTeams, selectedUsers) => {
		return selectedTeams.reduce(
			(updatedSelectedUsers, selectedTeamId) => {
				const teamMemberIds = getTeamMemberIds(selectedTeamId);
				return Array.from(new Set([...updatedSelectedUsers, ...teamMemberIds]));
			},
			[...selectedUsers]
		);
	};

	const updateSelectedUsers = (selectedUsers: number[]) => {
		if (createdBy && !selectedUsers.includes(createdBy)) {
			selectedUsers.push(createdBy);
		}
		setSelectedUsers(selectedUsers);
	};

	const handleClearTeams = () => {
		const teamMembers = getSelectedTeamMembers(selectedTeams, []);
		const updatedSelectedUsers = selectedUsers.filter(
			(selectedUser) => !teamMembers.includes(selectedUser)
		);

		setSelectedTeams([]);
		setSelectedTeamMembers([]);
		updateSelectedUsers(updatedSelectedUsers);
	};

	const handleClearUsers = () => {
		const updatedSelectedUsers = getSelectedTeamMembers(selectedTeams, [
			createdBy,
		]);
		updateSelectedUsers(updatedSelectedUsers);
	};

	const handleTeamSelection = (teamId) => {
		const updatedSelectedTeams = [...selectedTeams, teamId];
		const updatedSelectedUsers = getSelectedTeamMembers(
			updatedSelectedTeams,
			selectedUsers
		);

		setSelectedTeams(updatedSelectedTeams);
		updateSelectedUsers(updatedSelectedUsers);
	};

	const handleTeamDeselection = (teamId) => {
		const updatedSelectedTeams = selectedTeams.filter((id) => id !== teamId);
		const teamMemberIds = getTeamMemberIds(teamId);
		const filteredUsers = selectedUsers.filter(
			(selectedUser) => !teamMemberIds.includes(selectedUser)
		);
		const updatedSelectedUsers = getSelectedTeamMembers(
			updatedSelectedTeams,
			filteredUsers
		);

		setSelectedTeams(updatedSelectedTeams);
		updateSelectedUsers(updatedSelectedUsers);
	};

	const toggleSelectedTeams = (teamId) => {
		if (selectedTeams.includes(teamId)) {
			handleTeamDeselection(teamId);
		} else {
			handleTeamSelection(teamId);
		}
	};

	const toggleSelectedUsers = (userId) => {
		if (selectedTeamMembers.includes(userId) || createdBy === userId) return;
		if (selectedUsers.includes(userId)) {
			updateSelectedUsers(selectedUsers.filter((id) => id !== userId));
		} else {
			updateSelectedUsers([...selectedUsers, userId]);
		}
	};

	const handleProposalAssignment = async () => {
		try {
			setIsLoading(true);
			const respone = await updateProposalAssignment(
				{
					userIds: selectedUsers,
					teamIds: selectedTeams,
				},
				proposalId
			);
			handleResponse(respone, 'Proposal has been assigned successfully.');
			if (fetchProposalOnAssignment) {
				dispatch(fetchProposalById(proposalId));
			} else {
				queryClient.invalidateQueries('proposals');
			}
			setIsLoading(false);
			setOpen(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleDrawerOpen = () => {
		setSearchUserQuery('');
		setSearchTeamQuery('');
		updateSelectedUsers(assignedUsers || []);
		setSelectedTeams(assignedTeams || []);
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
		setIsLoading(false);
	};

	useEffect(() => {
		const teamMembers = getSelectedTeamMembers(selectedTeams, []);
		setSelectedTeamMembers(teamMembers);
	}, [selectedTeams]);

	return (
		<Sheet
			open={open}
			onOpenChange={(open) => {
				open ? handleDrawerOpen() : handleDrawerClose();
			}}
		>
			<SheetTrigger className="relative flex gap-8 z-10">
				{triggerButton && triggerButton}
			</SheetTrigger>
			<SheetContent className="flex flex-col !max-w-[400px] p-0 bg-white gap-0">
				<div className="flex items-center w-full justify-between pr-12">
					<h2 className="flex items-center font-bold text-black w-full justify-between pl-6 py-3 dynamic-large ">
						Assign
					</h2>
				</div>
				<div className="flex flex-col flex-1 gap-6 pb-2 px-6 overflow-hidden">
					<div className="flex flex-col h-48 xl:h-56 2xl:h-72 pb-3 gap-4 overflow-hidden border-b border-[#D3DDE2]">
						<div className="flex flex-col gap-3">
							<div className="flex items-center justify-between">
								<Label className="dynamic-medium">Teams</Label>
								<TextButton
									onClick={handleClearTeams}
									className="text-secondary hover:text-primary dynamic-text font-medium"
								>
									Clear All
								</TextButton>
							</div>
							<div className="relative w-full">
								<Input
									value={searchTeamQuery}
									onChange={(event) => {
										setSearchTeamQuery(event.target.value);
									}}
									placeholder="Search teams"
									className="bg-white cursor-caret border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
								/>
								<div className="absolute right-3 top-3.5">
									{searchTeamQuery !== '' ? (
										<X
											onClick={() => {
												setSearchTeamQuery('');
											}}
											className="cursor-pointer"
											color="#5D6F7999"
											size={16}
										/>
									) : (
										<Search color="#5D9BFD" size={16} />
									)}
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-4 overflow-y-auto">
							{filteredTeams?.length ? (
								filteredTeams.map((team, index) => (
									<div
										onClick={() => toggleSelectedTeams(team.id)}
										key={index}
										className="flex items-center gap-2 cursor-pointer"
									>
										<Checkbox
											id={String(team.id)}
											className="text-white"
											checked={selectedTeams.includes(team.id)}
											onCheckedChange={() => toggleSelectedTeams(team.id)}
										/>
										<p className="text-base text-black">{team.name}</p>
									</div>
								))
							) : (
								<p className="p-3 text-center text-black">No team found</p>
							)}
						</div>
					</div>
					<div className="flex flex-col flex-1 max-h-fit gap-2 overflow-hidden">
						<div className="flex flex-col gap-3 pb-2">
							<div className="flex items-center justify-between">
								<Label className="dynamic-medium">Users</Label>
								<TextButton
									onClick={handleClearUsers}
									className="text-secondary hover:text-primary dynamic-text font-medium"
								>
									Clear All
								</TextButton>
							</div>
							<div className="relative w-full">
								<Input
									value={searchUserQuery}
									onChange={(event) => {
										setSearchUserQuery(event.target.value);
									}}
									placeholder="Search users"
									className="bg-white cursor-caret border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
								/>
								<div className="absolute right-3 top-3.5">
									{searchUserQuery !== '' ? (
										<X
											onClick={() => {
												setSearchUserQuery('');
											}}
											className="cursor-pointer"
											color="#5D6F7999"
											size={16}
										/>
									) : (
										<Search color="#5D9BFD" size={16} />
									)}
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-4 overflow-y-auto">
							{filteredUsers?.length ? (
								filteredUsers.map((user, index) => (
									<div
										onClick={() => toggleSelectedUsers(user.id)}
										key={index}
										className={`flex items-center gap-3 ${selectedTeamMembers.includes(user.id) || createdBy === user.id ? 'cursor-ga opacity-50' : 'cursor-pointer'}`}
									>
										<Checkbox
											id={String(user.id)}
											className="text-white"
											checked={selectedUsers.includes(user.id)}
											onCheckedChange={() => toggleSelectedUsers(user.id)}
										/>
										<Avatar>
											<AvatarImage
												height={50}
												width={50}
												src={user?.profilePicture || ''}
											/>
											<FallbackAvatar
												user={user}
												className="dynamic-medium w-10 h-10"
											></FallbackAvatar>
										</Avatar>
										<p className="text-base text-black">{user.name}</p>
									</div>
								))
							) : (
								<p className="p-3 text-center text-black">No user found</p>
							)}
						</div>
						{!selectedUsers?.length && (
							<p className="dynamic-text font-medium text-lightRed">
								Please select at least one assignee.
							</p>
						)}
					</div>
				</div>
				<div className="flex flex-col justify-center w-full px-6 py-4">
					<PrimaryButton
						disabled={!selectedUsers?.length}
						onClick={handleProposalAssignment}
					>
						Assign
					</PrimaryButton>
				</div>
				{isLoading && <LoaderPanel />}
			</SheetContent>
		</Sheet>
	);
};

export default ProposalAssignmentDrawer;
