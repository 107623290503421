const UnselectDocument = ({ className = '', ...props }) => {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="mask0_4694_17789"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#5D9BFD" />
			</mask>
			<g mask="url(#mask0_4694_17789)">
				<path
					d="M15.575 1.575L20.425 6.425C20.6083 6.60833 20.75 6.82083 20.85 7.0625C20.95 7.30417 21 7.55833 21 7.825V15.75C21 16.0833 20.8958 16.3292 20.6875 16.4875C20.4792 16.6458 20.25 16.725 20 16.725C19.75 16.725 19.5208 16.6417 19.3125 16.475C19.1042 16.3083 19 16.0583 19 15.725V8H15.5C15.0833 8 14.7292 7.85417 14.4375 7.5625C14.1458 7.27083 14 6.91667 14 6.5V3H7C6.71667 3 6.47917 2.90417 6.2875 2.7125C6.09583 2.52083 6 2.28333 6 2C6 1.71667 6.09583 1.47917 6.2875 1.2875C6.47917 1.09583 6.71667 1 7 1H14.175C14.4417 1 14.6958 1.05 14.9375 1.15C15.1792 1.25 15.3917 1.39167 15.575 1.575ZM8 17H14.2L8 10.8V17ZM16.2 19H8C7.45 19 6.97917 18.8042 6.5875 18.4125C6.19583 18.0208 6 17.55 6 17V8.8L1.4 4.2C1.21667 4.01667 1.125 3.78333 1.125 3.5C1.125 3.21667 1.21667 2.98333 1.4 2.8C1.58333 2.61667 1.81667 2.525 2.1 2.525C2.38333 2.525 2.61667 2.61667 2.8 2.8L21.2 21.2C21.3833 21.3833 21.475 21.6167 21.475 21.9C21.475 22.1833 21.3833 22.4167 21.2 22.6C21.0167 22.7833 20.7833 22.875 20.5 22.875C20.2167 22.875 19.9833 22.7833 19.8 22.6L16.2 19ZM4 23C3.45 23 2.97917 22.8042 2.5875 22.4125C2.19583 22.0208 2 21.55 2 21V9C2 8.71667 2.09583 8.47917 2.2875 8.2875C2.47917 8.09583 2.71667 8 3 8C3.28333 8 3.52083 8.09583 3.7125 8.2875C3.90417 8.47917 4 8.71667 4 9V21H15C15.2833 21 15.5208 21.0958 15.7125 21.2875C15.9042 21.4792 16 21.7167 16 22C16 22.2833 15.9042 22.5208 15.7125 22.7125C15.5208 22.9042 15.2833 23 15 23H4Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default UnselectDocument;
