import { FC, useState } from 'react';
import styles from './EnhanceAIMessage.module.css';
import { cn } from '@/lib/utils';
import { renderTextWithLineBreaks } from '@/lib/functions/editor';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import Lottie from 'lottie-react';
import LottieLoader1 from '@/assets/lotties/lets_enhance_set1.json';
import LottieLoader2 from '@/assets/lotties/lets_enhance_set2.json';
import LottieLoader3 from '@/assets/lotties/lets_enhance_set3.json';
import { Table } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';

interface EnhanceAIMessageProps {
	content: string;
	loading: boolean;
	type: string;
}

const EnhanceAIMessage: FC<EnhanceAIMessageProps> = ({
	loading,
	content,
	type,
}) => {
	const [isReadMore, setIsReadMore] = useState(true);
	const dispatch = useAppDispatch();

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const MAX_LENGTH = 300;

	return (
		<>
			{loading ? (
				<div className={cn(styles.message, '')}>
					<Lottie
						className="m-auto w-44 animation-lottie"
						animationData={LottieLoader1}
						loop={true}
						autoPlay={true}
					/>
					<Lottie
						className="m-auto w-44 animation-lottie"
						animationData={LottieLoader2}
						loop={true}
						autoPlay={true}
					/>
					<Lottie
						className="m-auto w-44 animation-lottie"
						animationData={LottieLoader3}
						loop={true}
						autoPlay={true}
					/>
				</div>
			) : (
				<div className={cn(styles.message, 'text-black')}>
					{type === 'table' ? (
						<p
							className="flex items-center gap-2 font-bold cursor-pointer text-secondary animated-hover-effect"
							onClick={() =>
								dispatch(openDialog({ type: 'viewTable', tableCode: content }))
							}
						>
							<Table size={16} />
							View Table
						</p>
					) : (
						<>
							{content ? (
								isReadMore ? (
									`${content.slice(0, MAX_LENGTH)}...`
								) : (
									renderTextWithLineBreaks(content)
								)
							) : (
								<p className="font-medium text-secondary">
									No response from AI
								</p>
							)}
							{content.length > MAX_LENGTH && (
								<TextButton
									onClick={toggleReadMore}
									className="p-0 mt-2 h-fit 2xl:h-fit w-fit"
								>
									{isReadMore ? 'Read More' : 'Read Less'}
								</TextButton>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
};

export default EnhanceAIMessage;
