import { SVGProps } from 'react';

const AutoFetchDocument = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="146"
			height="139"
			viewBox="0 0 146 139"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_9216_14858)">
				<path
					d="M86.5738 1.96094L13.1838 2.05094C7.50383 2.05094 2.92383 6.65094 2.92383 12.3209L3.05383 117.011C3.05383 122.681 7.66383 127.261 13.3338 127.261L86.7138 127.171C92.3938 127.171 96.9738 122.571 96.9738 116.901L96.8438 12.2109C96.8438 6.54094 92.2338 1.96094 86.5638 1.96094H86.5738Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M97.734 11.1995L24.344 11.2895C18.664 11.2895 14.084 15.8895 14.084 21.5595L14.214 126.249C14.214 131.919 18.824 136.499 24.494 136.499L97.884 136.409C103.564 136.409 108.144 131.809 108.144 126.129L108.014 21.4395C108.014 15.7695 103.404 11.1895 97.734 11.1895V11.1995Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M69.1445 28.3594H112.744C129.494 28.3594 143.064 41.2394 143.064 57.1294C143.064 73.0194 129.484 85.8994 112.744 85.8994H77.2045L60.3245 107.839L63.9345 85.4694C49.6745 83.1294 38.8145 71.3394 38.8145 57.1294C38.8145 41.2394 52.3945 28.3594 69.1345 28.3594H69.1445Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M63.9746 43.9805H117.905"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M63.9746 56.7207H117.905"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M63.9746 69.4707H101.965"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_9216_14858">
					<rect
						width="144.05"
						height="138.47"
						fill="white"
						transform="translate(0.974609)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default AutoFetchDocument;
