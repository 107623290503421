import { SVGProps } from 'react';

export const Template = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="27"
			height="27"
			viewBox="0 0 27 27"
			fill="none"
		>
			<path
				d="M26.9998 2.60327V24.4012C27.0031 24.7437 26.938 25.0835 26.8082 25.4006C26.6785 25.7176 26.4868 26.0056 26.2444 26.2476C26.0019 26.4896 25.7136 26.6808 25.3963 26.81C25.079 26.9392 24.7391 27.0037 24.3965 26.9998H2.60348C2.26067 27.0025 1.92077 26.9369 1.60356 26.8069C1.28636 26.6769 0.998214 26.4851 0.75591 26.2426C0.513607 26.0001 0.321996 25.7118 0.192245 25.3945C0.0624942 25.0772 -0.00280106 24.7373 0.000164659 24.3945V2.60327C-0.00371421 2.26035 0.0609736 1.92011 0.190429 1.60253C0.319884 1.28496 0.511497 0.99645 0.754014 0.753952C0.996531 0.511455 1.28506 0.319858 1.60266 0.190413C1.92026 0.0609687 2.26053 -0.00371391 2.60348 0.000164646H24.4032C24.7457 -0.00312224 25.0853 0.0620141 25.4023 0.191752C25.7193 0.32149 26.0071 0.513217 26.249 0.755684C26.4908 0.998152 26.6818 1.28648 26.8108 1.60374C26.9397 1.92101 27.004 2.26083 26.9998 2.60327ZM24.7528 24.3945V2.60327C24.7508 2.51117 24.7133 2.4234 24.6482 2.35826C24.5831 2.29312 24.4953 2.25565 24.4032 2.25366H23.8464L17.199 6.74953L13.5044 3.74338L9.81548 6.74953L3.16467 2.2481H2.60793C2.51583 2.25008 2.42805 2.28755 2.36291 2.35269C2.29776 2.41783 2.26029 2.5056 2.2583 2.5977V24.3945C2.26029 24.4866 2.29776 24.5744 2.36291 24.6395C2.42805 24.7047 2.51583 24.7421 2.60793 24.7441H24.4032C24.4959 24.7441 24.5848 24.7073 24.6504 24.6417C24.716 24.5762 24.7528 24.4872 24.7528 24.3945ZM9.05275 9.28026V11.507H4.62222V9.28026H9.05275ZM9.05275 13.7639V16.0118H4.62222V13.7639H9.05275ZM9.72084 4.88683L12.9755 2.25366H5.83368L9.72084 4.88683ZM22.3811 9.28026V11.507H10.5649V9.28026H22.3811ZM22.3811 13.7639V16.0118H10.5649V13.7639H22.3811ZM17.2791 4.88794L21.1663 2.25478H14.03L17.2791 4.88794ZM22.3811 18.2597V20.5077H16.3906V18.2597H22.3811Z"
				fill="#5D9BFD"
			/>
		</svg>
	);
};
