import { FC, ReactNode } from 'react';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';

interface CustomTooltipProps {
	triggerNode: ReactNode;
	text?: string;
}

const CustomTooltip: FC<CustomTooltipProps> = ({ triggerNode, text }) => {
	return (
		<TooltipProvider delayDuration={50}>
			<Tooltip>
				<TooltipTrigger>{triggerNode}</TooltipTrigger>
				<TooltipContent className="bg-white max-w-[600px] font-normal text-black dynamic-text p-3 rounded-xl border-none shadow-none drop-shadow-effect">
					{text}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export default CustomTooltip;
