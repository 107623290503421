const Login = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="29"
			height="36"
			viewBox="0 0 29 36"
			fill="none"
		>
			<path
				d="M23.1742 35.7372H5.81117C4.26995 35.7372 2.79186 35.1249 1.70205 34.0351C0.612247 32.9453 0 31.4672 0 29.926L0 5.81117C0.00102624 4.27027 0.613602 2.79277 1.70319 1.70319C2.79277 0.613602 4.27027 0.00102624 5.81117 0L23.1742 0C24.7151 0.00102624 26.1926 0.613602 27.2822 1.70319C28.3717 2.79277 28.9843 4.27027 28.9854 5.81117V14.982C28.9854 15.1496 28.9523 15.3155 28.8882 15.4703C28.8241 15.6252 28.7301 15.7658 28.6116 15.8843C28.4931 16.0028 28.3524 16.0968 28.1976 16.161C28.0427 16.2251 27.8768 16.2581 27.7092 16.2581C27.5416 16.2581 27.3757 16.2251 27.2209 16.161C27.066 16.0968 26.9254 16.0028 26.8069 15.8843C26.6884 15.7658 26.5944 15.6252 26.5302 15.4703C26.4661 15.3155 26.4331 15.1496 26.4331 14.982V5.81117C26.4312 4.94866 26.0878 4.12199 25.4779 3.5121C24.868 2.90221 24.0413 2.55876 23.1788 2.55692H5.81117C4.94859 2.55855 4.12181 2.90194 3.51187 3.51187C2.90194 4.12181 2.55855 4.94859 2.55692 5.81117V29.9299C2.55835 30.7925 2.90167 31.6194 3.51165 32.2294C4.12163 32.8394 4.94853 33.1827 5.81117 33.1841H23.1742C24.0368 33.1825 24.8635 32.8391 25.4735 32.2292C26.0834 31.6192 26.4268 30.7924 26.4284 29.9299V27.6565C26.4284 27.3181 26.5629 26.9935 26.8022 26.7542C27.0415 26.5148 27.3661 26.3804 27.7046 26.3804C28.043 26.3804 28.3676 26.5148 28.6069 26.7542C28.8463 26.9935 28.9807 27.3181 28.9807 27.6565V29.9299C28.9807 31.4711 28.3685 32.9492 27.2787 34.039C26.1888 35.1288 24.7107 35.741 23.1695 35.741"
				fill="white"
			/>
			<path
				d="M25.9804 22.1306H9.87257C9.53412 22.1306 9.20953 21.9961 8.97021 21.7568C8.73088 21.5175 8.59644 21.1929 8.59644 20.8545C8.59644 20.516 8.73088 20.1914 8.97021 19.9521C9.20953 19.7128 9.53412 19.5783 9.87257 19.5783H22.9369L19.2952 15.8755C19.0669 15.6327 18.9427 15.3103 18.949 14.9771C18.9553 14.6439 19.0916 14.3265 19.3289 14.0925C19.5662 13.8586 19.8856 13.7267 20.2188 13.7251C20.552 13.7235 20.8727 13.8524 21.1122 14.0841L26.8885 19.9572C27.064 20.1368 27.1827 20.3641 27.2297 20.6107C27.2767 20.8574 27.2499 21.1124 27.1528 21.3439C27.0556 21.5754 26.8924 21.7732 26.6834 21.9124C26.4745 22.0517 26.2291 22.1262 25.978 22.1267"
				fill="white"
			/>
			<path
				d="M20.2058 27.8076C19.9526 27.8077 19.7051 27.7323 19.4948 27.5912C19.2846 27.4501 19.1211 27.2496 19.0252 27.0153C18.9293 26.7809 18.9053 26.5234 18.9563 26.2753C19.0073 26.0273 19.131 25.8001 19.3116 25.6226L25.0864 19.9455C25.2048 19.8235 25.3465 19.7263 25.503 19.6598C25.6596 19.5933 25.8278 19.5588 25.9979 19.5582C26.168 19.5577 26.3365 19.5911 26.4934 19.6566C26.6504 19.7221 26.7927 19.8183 26.9119 19.9396C27.0312 20.0609 27.125 20.2047 27.1878 20.3628C27.2507 20.5208 27.2813 20.6898 27.2779 20.8599C27.2745 21.0299 27.2372 21.1976 27.168 21.353C27.0989 21.5084 26.9994 21.6484 26.8754 21.7648L21.0991 27.4411C20.8608 27.6761 20.5396 27.8077 20.205 27.8076"
				fill="white"
			/>
			<path
				d="M20.8215 9.54272C20.4831 9.54272 20.1585 9.40827 19.9192 9.16895C19.6799 8.92963 19.5454 8.60504 19.5454 8.26659V1.27613C19.5454 0.937682 19.6799 0.613092 19.9192 0.373771C20.1585 0.134449 20.4831 0 20.8215 0C21.16 0 21.4846 0.134449 21.7239 0.373771C21.9632 0.613092 22.0977 0.937682 22.0977 1.27613V6.97341L27.6873 6.8874C28.0257 6.88216 28.3524 7.01158 28.5954 7.2472C28.8384 7.48282 28.9779 7.80533 28.9831 8.14378C28.9884 8.48223 28.859 8.8089 28.6233 9.05193C28.3877 9.29495 28.0652 9.43443 27.7268 9.43967L20.8417 9.54504L20.8215 9.54272Z"
				fill="white"
			/>
		</svg>
	);
};

export default Login;
