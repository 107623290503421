import {
	getAllSections,
	getTagByOrganization,
	getTemplateById,
} from '@/lib/functions/TemplateBuilder/templateCalls';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import useOrganizationId from '../useOrganizationId';
import { RootState } from '@/redux/store/store';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
	TagField,
	TemplateLibrary,
	TreeInterface,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { defaultSectionTagOptions } from '@/constants/templateBuilderData';
import { setSectionTagOptions } from '@/redux/slices/TemplateBuilder/sectionSlice';
import {
	handleApiError,
	handleGeneralError,
	handleUnexpectedError,
} from '@/lib/functions/funcUtils';
import { ErrorResponse } from '@/lib/types/apiTypes';
import {
	buildTemplate,
	setLoading,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { templateTreeMapper } from '@/lib/functions/TemplateBuilder/templateUtils';
import { UseFormSetValue } from 'react-hook-form';
import useTemplateId from '../useTemplateId';
import { useLocation } from 'react-router';

interface TemplateQueryProps {
	setValue?: UseFormSetValue<TemplateLibrary>;
	setTreeData?: (value: React.SetStateAction<any[]>) => void;
	sectionSearch?: string | undefined;
	sectionTagFilter?: string | undefined;
	setAvailableSectionsTree?: (
		value: React.SetStateAction<TreeInterface[]>
	) => void;
}

export const useTemplateQueries = ({
	setValue,
	setTreeData,
	sectionSearch,
	sectionTagFilter,
	setAvailableSectionsTree,
}: TemplateQueryProps = {}) => {
	const orgIdentifier = useOrganizationId();
	const auth = useAppSelector((root: RootState) => root.auth.isAuthenticated);
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const templateBuilderData = useAppSelector(
		(root: RootState) => root.templates.templateBuilder
	);

	const templateBuilderAccess = useAppSelector(
		(root: RootState) => root.auth.user?.templateBuilderAccess
	);

	const templateID = useTemplateId();

	const location = useLocation();

	const isTemplateMode = location.pathname.includes('template');

	const sectionTagQuery = useQuery(
		['section_tags'],
		async () => {
			const { data } = await getTagByOrganization(`sections_${orgIdentifier}`);

			return data;
		},
		{
			cacheTime: 0,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: auth && templateBuilderAccess,
			onSuccess: (data: any) => {
				const response = data.tags as TagField;
				const newArray: any = Object.assign([], defaultSectionTagOptions);
				if (response) {
					response.tags.length > 0 &&
						response.tags.forEach((v) => {
							if (!defaultSectionTagOptions.some((item) => item.value === v)) {
								const dbTags = {
									organizationIdentifier: response.organizationIdentifier,
									label: v,
									value: v,
								};
								newArray.push(dbTags);
							}
						});
				}
				dispatch(setSectionTagOptions(newArray));
			},
			onError: (error: any) => {
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
		}
	);

	const templateByIdQuery = useQuery(
		['templateById', templateID],
		async () => {
			const { data } = await getTemplateById(String(templateID));
			return data;
		},
		{
			cacheTime: 0,
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled: templateID !== undefined && isTemplateMode,
			onSuccess: (data: TemplateLibrary) => {
				if (!setValue || !setTreeData) return;
				if (templateBuilderData) {
					setValue('name', templateBuilderData.name);
					setValue('description', templateBuilderData.description);
					setValue('tags', templateBuilderData.tags);
					setValue(
						'organizationIdentifier',
						templateBuilderData.organizationIdentifier
					);
					data.name = templateBuilderData.name;
					data.description = templateBuilderData.description;
					data.tags = templateBuilderData.tags;
				}
				dispatch(buildTemplate(data));
				setTreeData(data.sections);
				queryClient.invalidateQueries('availableSections');
			},
			onError: (error: any) => {
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
			onSettled: () => {
				dispatch(setLoading(false));
			},
		}
	);

	const sectionQuery = useInfiniteQuery(
		['availableSections', sectionSearch || '', sectionTagFilter || ''],
		async ({ pageParam = 1 }) => {
			const param = {
				pageSize: 10,
				pageNumber: pageParam,
				isLive: 'true',
				publishType: 'template',
				search: sectionSearch,
				tag:
					sectionTagFilter === 'organization'
						? []
						: ([sectionTagFilter] as string[]),
				organizationIdentifier: orgIdentifier,
				filter: sectionTagFilter === 'organization' ? 'organization' : 'all',
			};
			const { data } = await getAllSections(param);
			return {
				sections: data.sections,
				totalItems: data.totalItems,
				totalPages: data.totalPages,
				hasNextPage: data.hasNextPage,
				currentPage: data.currentPage,
			};
		},
		{
			getNextPageParam: (lastPage) => {
				// Return the next page number if there is a next page
				if (lastPage.hasNextPage) {
					return lastPage.currentPage + 1;
				}
				return undefined; // Otherwise, stop fetching more pages
			},
			refetchOnWindowFocus: false,
			enabled: auth && templateBuilderAccess,
			onSuccess: (data) => {
				if (data.pages) {
					const allSections = (data.pages as any).flatMap(
						(page: any) => page.sections
					);
					const treeData = templateTreeMapper(allSections);
					setAvailableSectionsTree && setAvailableSectionsTree(treeData);
				}
			},
			onError: (error: any) => {
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
			onSettled: () => {
				dispatch(setLoading(false));
			},
		}
	);

	return { sectionTagQuery, templateByIdQuery, sectionQuery };
};
