import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	fetchProposalById,
	pageTransitionStart,
	updateStep,
} from '@/redux/slices/proposalSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { motion } from 'framer-motion';
import {
	prInputSlideIn,
	prInputSlideInDelay,
	prContainerExit,
} from '@/constants/variants';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import {
	ApiResponse,
	OrganizationProfile,
	ToneOfVoice,
} from '@/lib/types/apiTypes';
import ToneOfVoiceView from '@/components/ToneOfVoiceView/ToneOfVoiceView';
import FormativeDocuments from '@/components/EssentialDocuments/EssentialDocuments';
import { openDialog } from '@/redux/slices/pageSlice';
import { Skeleton } from '@/components/ui/skeleton';
import {
	getOrganizationProfiles,
	updateProposalOrganizaitonProfile,
} from '@/lib/functions/apiCalls';
import OrganizationProfileSelection from '@/components/OrganizationProfileSelection/OrganizationProfileSelection';
import { ErrorHandle } from '@/lib/functions/funcUtils';
import LoaderPanel from '@/components/Loader/LoaderPanel/LoaderPanel';

interface ToneViewProps {
	currentStep: number;
}

const ToneView: FC<ToneViewProps> = ({ currentStep }) => {
	const dispatch = useAppDispatch();
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const toneOfVoice = (proposal as any)?.settings?.toneOfVoice as ToneOfVoice;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadingProfiles, setLoadingProfiles] = useState<boolean>(false);
	const [toneOfVoiceData, setToneOfVoiceData] = useState<ToneOfVoice>();
	const [organizationProfiles, setOrganizationProfiles] = useState<
		OrganizationProfile[]
	>([]);
	const [organizationProfileId, setOrganizationProfileId] = useState<string>();

	const getProfilesData = async () => {
		try {
			setLoadingProfiles(true);
			const response: ApiResponse = await getOrganizationProfiles();
			const organizationProfiles = response?.data as OrganizationProfile[];
			setOrganizationProfiles(organizationProfiles);
			setOrganizationProfileId(String(organizationProfiles[0]?.id));
			setLoadingProfiles(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setLoadingProfiles(false);
		}
	};

	useEffect(() => {
		getProfilesData();
	}, []);
	useEffect(() => {
		if (toneOfVoice) {
			setToneOfVoiceData(toneOfVoice);
		}
	}, [toneOfVoice]);

	const updateProposalOrganizationProfile = async () => {
		try {
			if (
				!proposal?.id ||
				organizationProfileId === proposal.organizationProfileId
			) {
				return;
			}
			setIsLoading(true);
			await updateProposalOrganizaitonProfile(
				proposal?.id,
				Number(organizationProfileId)
			);
			dispatch(fetchProposalById(proposal?.id));
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleClick = async () => {
		await updateProposalOrganizationProfile();
		dispatch(pageTransitionStart());
		dispatch(updateStep(currentStep + 1));
	};

	const onEditTone = () => {
		dispatch(
			openDialog({
				type: 'changeTone',
				changeToneProps: {
					type: 'proposal',
					toneOfVoice: toneOfVoice,
					proposalId: proposal?.id,
				},
			})
		);
	};

	return (
		<motion.div
			className="max-w-[1400px] mx-auto mt-6 mb-10 flex flex-col items-center justify-center gap-4"
			key="prContainer"
			variants={prContainerExit}
			exit="exit"
		>
			<h2 className="font-bold text-black dynamic-xl-large">
				Confirm <span className="text-secondary">proposal settings</span>
			</h2>
			<div className="flex flex-col w-full text-left bg-white rounded-2xl p-6 gap-2 shadow-main">
				<h2 className="font-bold text-black dynamic-large">
					Organization Profile
				</h2>
				<div className="grid w-full grid-cols-12 gap-4">
					<p className="text-black col-span-3">
						This is the organization profile that will be used for this
						proposal. If your administrator has created additional profiles,
						please select the one you wish to use for this proposal.
					</p>
					<div className="flex col-span-9 rounded-xl border border-[#D3DDE2]">
						<OrganizationProfileSelection
							isLoading={loadingProfiles}
							organizationProfiles={organizationProfiles}
							organizationProfile={
								organizationProfileId
									? organizationProfiles?.find(
											(organizationProfile) =>
												String(organizationProfile.id) === organizationProfileId
										)
									: organizationProfiles[0]
							}
							setOrganizationProfile={setOrganizationProfileId}
						/>
					</div>
				</div>
			</div>
			<div className="grid w-full grid-cols-2 gap-4">
				<div className="flex flex-col col-span-1 gap-5">
					<motion.div
						className="flex flex-col col-span-6 bg-white h-full rounded-2xl "
						key="prInput"
						variants={prInputSlideIn}
						initial="initial"
						animate="animate"
					>
						<FormativeDocuments />
					</motion.div>
				</div>
				<div className="flex flex-col col-span-1 gap-5">
					<motion.div
						className="flex flex-col col-span-6 gap-6 p-6 bg-white h-full rounded-2xl shadow-main"
						key="prInput"
						variants={prInputSlideIn}
						initial="initial"
						animate="animate"
					>
						{toneOfVoiceData ? (
							<ToneOfVoiceView
								type="proposal"
								onEditTone={onEditTone}
								toneOfVoice={toneOfVoiceData}
							/>
						) : (
							<div className="flex flex-col gap-6 tone-of-voice">
								<div className="flex flex-col pb-6 border-b border-[#D3DDE2] gap-4 ">
									<div className="flex flex-col gap-2">
										<Skeleton className="!rounded-main h-5 w-4/12 bg-slate-300" />
										<Skeleton className="!rounded-main h-3.5 w-8/12 bg-slate-300" />
									</div>
									<div className="grid grid-cols-11 gap-3 ">
										<div className="flex flex-col col-span-4 gap-3 writing-style">
											<div className="flex flex-col justify-center flex-1 py-6 px-4 gap-2 border rounded-xl ">
												<Skeleton className="!rounded-main h-5 w-4/12 bg-slate-300" />
												<Skeleton className="!rounded-main h-3.5 w-8/12 bg-slate-300" />
											</div>
											<div className="flex flex-col justify-center flex-1 py-6 px-4 gap-2 border rounded-xl">
												<Skeleton className="!rounded-main h-5 w-4/12 bg-slate-300" />
												<Skeleton className="!rounded-main h-3.5 w-8/12 bg-slate-300" />
											</div>
										</div>
										<div className="flex flex-col flex-1 col-span-7 p-4 border rounded-xl tone-graph">
											<Skeleton className="h-full w-full bg-slate-300" />
										</div>
									</div>
								</div>
								<div className="tone-sample flex flex-col col-span-2 p-4 border-[#D3DDE2] border rounded-2xl hidden-scroll max-h-[285px] overflow-auto gap-4">
									<Skeleton className="!rounded-main h-5 w-3/12 bg-slate-300" />
									<div className="flex flex-col gap-2">
										<Skeleton className="!rounded-main h-3.5 w-full bg-slate-300" />
										<Skeleton className="!rounded-main h-3.5 w-full bg-slate-300" />
										<Skeleton className="!rounded-main h-3.5 w-full bg-slate-300" />
										<Skeleton className="!rounded-main h-3.5 w-full bg-slate-300" />
										<Skeleton className="!rounded-main h-3.5 w-2/12 bg-slate-300" />
									</div>
								</div>
								<div className="flex col-span-2">
									<Skeleton className="rounded-lg h-10 2xl:h-11 w-40 bg-slate-300" />
								</div>
							</div>
						)}
					</motion.div>
				</div>
			</div>
			<motion.div
				key="prInputDelay"
				variants={prInputSlideInDelay}
				initial="initial"
				animate="animate"
				className="flex self-end"
			>
				<PrimaryButton onClick={handleClick}>Continue</PrimaryButton>
			</motion.div>
			{isLoading && <LoaderPanel />}
		</motion.div>
	);
};

export default ToneView;
