import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import styles from './CategoryDropdown.module.css';
import { cn } from '@/lib/utils';
import { Info } from 'lucide-react';

interface CategoryDropdownProps {
	showAll?: boolean;
	selectedCategory?: string;
	setSelectedCategory: (selectedCategory: string) => void;
	className?: string;
	disabled?: boolean;
}

const CategoryDropdown = ({
	showAll,
	selectedCategory,
	setSelectedCategory,
	className,
	disabled = false,
}: CategoryDropdownProps) => {
	return (
		<Select
			disabled={disabled}
			value={selectedCategory}
			onValueChange={(value) => setSelectedCategory(value)}
		>
			<SelectTrigger
				className={cn(
					styles.select__trigger,
					'h-12 rounded-xl dynamic-small border border-[#D3DDE2] bg-white text-black px-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] min-w-[200px] 2xl:min-w-[220px] w-fit',
					className
				)}
			>
				<SelectValue placeholder="Select Category" />
			</SelectTrigger>
			<SelectContent className="py-2 bg-white rounded-2xl overflow-visible">
				<div className="overflow-x-visible overflow-y-auto max-h-48 pr-1 mr-1">
					{showAll && (
						<SelectItem
							className="text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
							value="none"
						>
							All
						</SelectItem>
					)}
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary select-item__tooltip'
						)}
						value="Past Performance"
					>
						<div className="flex justify-between w-full gap-1">
							Past Performance
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Information showcasing the historical data and outcomes of
											previous projects or grants.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Research"
					>
						<div className="flex justify-between w-full gap-1">
							Research
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Detailed findings and analyses related to current grant
											opportunities.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Capture Notes"
					>
						<div className="flex justify-between w-full gap-1">
							Capture Notes
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Key observations and information captured during the grant
											preparation process.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Intelligence"
					>
						<div className="flex justify-between w-full gap-1">
							Intelligence
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Strategic information that can influence the grant
											application's direction and success.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Additional Notes"
					>
						<div className="flex justify-between w-full gap-1">
							Additional Notes
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Any supplementary notes or miscellaneous information
											relevant to the grant.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Previous Proposal"
					>
						<div className="flex justify-between w-full gap-1">
							Previous Proposal
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Documentation from earlier grant proposals submitted for
											reference.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Concept Note"
					>
						<div className="flex justify-between w-full gap-1">
							Concept Note
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Initial ideas or frameworks, providing a foundation and
											context for a full proposal.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Donor Guidelines"
					>
						<div className="flex justify-between w-full gap-1">
							Donor Guidelines
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Documents that outline specific guidelines, eligibility
											criteria, or requirements from the donor.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Monitoring and Evaluation"
					>
						<div className="flex justify-between w-full gap-1">
							Monitoring and Evaluation
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Documents detailing M&E plans, including indicators,
											tools, and methodologies; showcasing a robust plan for
											tracking and evaluating the project’s progress and impact.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Case Studies"
					>
						<div className="flex justify-between w-full gap-1">
							Case Studies
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											Documentation of previous successful projects that are
											similar to the proposed one, supporting the organization’s
											capacity to deliver successful outcomes.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
					<SelectItem
						className={cn(
							styles.select__item,
							'text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary'
						)}
						value="Capability Statement"
					>
						<div className="flex justify-between w-full gap-1">
							Capability Statement
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<Info className="h-4 text-secondary" />
									</TooltipTrigger>
									<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
										<p>
											A concise document highlighting the organization’s key
											services, experiences, and competencies that align with
											the proposal’s focus areas.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					</SelectItem>
				</div>
			</SelectContent>
		</Select>
	);
};

export default CategoryDropdown;
