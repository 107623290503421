import { FC } from 'react';
import styles from './MainCard.module.css';
import { Section } from '@/lib/types/apiTypes';
import SectionCard from '../SectionCard/SectionCard';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { mCEaseInDelay, mCSlideIn } from '@/constants/variants';

interface MainCardProps {
	section: Section;
	topLevelHeadingNumber: number;
	parentRef?: any;
	scrollParentRef?: any;
	topRoundedNone?: boolean;
}

const MainCard: FC<MainCardProps> = ({
	section,
	topLevelHeadingNumber,
	parentRef,
	scrollParentRef,
	topRoundedNone,
}) => {
	const renderSection = (sec: Section, level: number, prefix: string) => {
		let headingPrefix = prefix;
		if (level === 0) {
			headingPrefix = String(topLevelHeadingNumber);
		}

		return (
			<motion.div
				key={sec.id}
				variants={mCEaseInDelay}
				initial="initial"
				animate="animate"
			>
				<SectionCard
					scrollParentRef={scrollParentRef}
					parentRef={parentRef}
					headingPrefix={headingPrefix}
					title={sec.alias || sec.name}
					description={sec.description}
					wordCount={`${sec.wordCount}`}
					id={sec.id}
					expectedOutput={sec.expectedOutput}
					editorState={sec.editorState as string}
					resolved={sec.isComplete}
					headingLevel={(level + 1) as 1 | 2 | 3}
					content={sec.content as string}
					user={
						sec.generationHistory.length > 0
							? sec.generationHistory[0].user
							: null
					}
					type={sec.expectedOutput}
				/>
				{sec.children.map((child, idx) =>
					renderSection(child, level + 1, `${headingPrefix}.${idx + 1}`)
				)}
			</motion.div>
		);
	};

	return (
		<motion.div
			className="relative mr-2"
			key="mCSlideIn"
			variants={mCSlideIn}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			<div
				className={cn(styles.card, topRoundedNone ? 'rounded-t-none' : 'pt-0')}
			>
				<div className={cn(styles.inner_card, 'light-scroll2')}>
					{renderSection(section, 0, '')}
				</div>
			</div>
		</motion.div>
	);
};

export default MainCard;
