import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	useReactTable,
	getSortedRowModel,
	getPaginationRowModel,
	PaginationState,
	Updater,
} from '@tanstack/react-table';

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronsLeftIcon,
	ChevronsRightIcon,
} from 'lucide-react';
import { SortingType } from '@/lib/types/apiTypes';

interface ProposalTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	totalPages?: number;
	pagination?: PaginationState;
	setPagination?: (pagination: Updater<PaginationState>) => void;
	sortOrder?: SortingType;
	setSortOrder?: (sortOrder: SortingType) => void;
}

export function ProposalTable<TData, TValue>({
	columns,
	data,
	totalPages,
	pagination,
	setPagination,
	sortOrder,
	setSortOrder,
}: ProposalTableProps<TData, TValue>) {
	const handleSorting = () => {
		setSortOrder && setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');
	};

	const [rowSelection, setRowSelection] = useState({});
	const pageIndex = pagination?.pageIndex || 0;
	const pageSize = pagination?.pageSize || 5;
	const table = useReactTable({
		data,
		columns,
		initialState: {
			pagination: {
				pageIndex: pageIndex,
				pageSize: pageSize,
			},
		},
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: handleSorting,
		getSortedRowModel: getSortedRowModel(),
		state: {
			rowSelection,
		},
		pageCount: totalPages || 0,
		getPaginationRowModel: getPaginationRowModel(),
		onRowSelectionChange: setRowSelection,
		onPaginationChange: setPagination,
		manualPagination: true,
	});

	return (
		<div className="overflow-auto rounded-xl">
			<Table>
				<TableHeader className="bg-blackSecondary">
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead
										key={header.id}
										className="px-8 py-3 text-lg text-black"
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody className="overflow-y-auto text-base text-black bg-white">
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow
								className="px-8 py-3 transition hover:bg-lightBackground"
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id} className="px-8 py-3">
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<div className="flex items-center justify-start gap-4 px-2 mt-4 text-black">
				<div className="flex items-center gap-4">
					<div className="flex items-center space-x-2">
						<p className="text-sm font-medium">Rows per page</p>
						<Select
							value={`${pageSize}`}
							onValueChange={(value) => {
								table.setPageSize(Number(value));
							}}
						>
							<SelectTrigger className="h-8 w-[70px] ">
								<SelectValue placeholder={pageSize} />
							</SelectTrigger>
							<SelectContent side="top" className="bg-white">
								{[5, 10, 20, 25].map((pageSize) => (
									<SelectItem key={pageSize} value={`${pageSize}`}>
										{pageSize}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
					<div className="flex items-center justify-center text-xs ">
						Page {pageIndex + 1} of {table.getPageCount()}
					</div>
				</div>
				<div className="flex items-center space-x-2 ">
					<Button
						className="hidden w-8 h-8 p-0 lg:flex hover:bg-secondary"
						onClick={() => table.setPageIndex(0)}
						disabled={!pageIndex}
					>
						<span className="sr-only">Go to first page</span>
						<ChevronsLeftIcon className="w-4 h-4 text-white" />
					</Button>
					<Button
						// variant={'white'}
						className="w-8 h-8 p-0 hover:bg-secondary"
						onClick={() => table.previousPage()}
						disabled={!pageIndex}
					>
						<span className="sr-only">Go to previous page</span>
						<ChevronLeftIcon className="w-4 h-4 text-white" />
					</Button>
					<Button
						// variant={'white'}
						className="w-8 h-8 p-0 hover:bg-secondary"
						onClick={() => table.nextPage()}
						disabled={!totalPages || pageIndex >= totalPages - 1}
					>
						<span className="sr-only">Go to next page</span>
						<ChevronRightIcon className="w-4 h-4 text-white" />
					</Button>
					<Button
						// variant={'white'}
						className="hidden w-8 h-8 p-0 lg:flex hover:bg-secondary"
						onClick={() => table.setPageIndex(table.getPageCount() - 1)}
						disabled={!totalPages || pageIndex >= totalPages - 1}
					>
						<span className="sr-only">Go to last page</span>
						<ChevronsRightIcon className="w-4 h-4 text-white" />
					</Button>
				</div>
			</div>
		</div>
	);
}
