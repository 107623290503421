import { FC } from 'react';
import styles from './UserMessage.module.css';

interface UserMessageProps {
	text: string;
}

const UserMessage: FC<UserMessageProps> = ({ text }) => {
	return (
		<div className={styles.card + ' my-2 break-words overflow-hidden'}>
			<p className="text-[#6d7d86] text-left">{text}</p>
		</div>
	);
};

export default UserMessage;
