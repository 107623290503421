import { NodeData, NodeProps } from '@/lib/types/graphTypes';
import styles from './GrandChildNode.module.css';
import { cn } from '@/lib/utils';
import Tick from '@/components/ui/icons/new/Tick';
import SectionEditPopover from '@/components/SectionEditPopover/SectionEditPopover';
import { useState } from 'react';
import { cropText } from '@/lib/functions/funcUtils';

type GrandchildNodeProps = NodeProps & {
	id: string;
	onClick?: (id: string) => void; // Ensure onClick is correctly typed here as well
	section?: NodeData;
	editTemplate?: boolean;
};
const GrandchildNode: React.FC<GrandchildNodeProps> = ({
	data,
	onClick,
	id,
	isSelected,
	section,
	editTemplate,
}) => {
	const [isHovered, setIsHovered] = useState(false);
	return (
		<div
			className={cn(
				styles.grandchild,
				isSelected ? 'bg-secondary text-white' : 'cursor-pointer'
			)}
			onClick={() => onClick && onClick(id)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div className="flex items-center justify-between w-full">
				{isSelected ? (
					<div>{data?.alias}</div>
				) : (
					<div>{cropText(data?.alias as string, 30)}</div>
				)}
				<div className="flex items-center gap-2">
					<div>{data.isComplete && <Tick />}</div>
					{editTemplate && isHovered && (
						<div className="flex items-center gap-2">
							<p className="flex items-center gap-2 font-semibold dynamic-small">
								{data.wordCount} words
							</p>
							<SectionEditPopover
								isSelected={isSelected}
								section={section as NodeData}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default GrandchildNode;
