import { SVGProps } from 'react';
const DiscoverIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 30 38"
		{...props}
	>
		<g fill="#fff" clipPath="url(#a)">
			<path d="M23.98 37.14H6.01c-1.59 0-3.12-.64-4.25-1.77S0 32.71 0 31.1V6.04c0-1.6.63-3.14 1.76-4.27S4.42 0 6.01 0h17.97c1.59 0 3.12.64 4.25 1.77s1.76 2.67 1.76 4.27v25.07c0 1.6-.64 3.14-1.76 4.27a6.02 6.02 0 0 1-4.25 1.77M6.01 2.65a3.378 3.378 0 0 0-3.37 3.38V31.1a3.383 3.383 0 0 0 3.37 3.38h17.97a3.378 3.378 0 0 0 3.37-3.38V6.04a3.383 3.383 0 0 0-3.37-3.38H6.01v-.01z" />
			<path d="M22.44 9.78c-.25-.43-.6-.77-1.05-1.04-.23-.14-.25-.47-.02-.6.44-.24.8-.56 1.05-.97.13-.22.45-.22.58 0 .25.43.61.75 1.04.98.22.12.25.45.03.59-.41.26-.75.61-1.02 1.05-.15.23-.47.22-.61-.02v.01zM22.112 14.59c-.98.64-1.83 1.52-2.52 2.62-.08.13-.18.23-.29.33.08.57.14 1.16.14 1.82v1.22c-.02 2.26-.52 3.99-1.5 5.2s-2.98 1.82-4.83 1.82h-2.5c-.22 0-.39-.17-.39-.39V12.62c0-.22.17-.39.39-.39h2.54c.24 0 .49.02.73.04.13-.16.27-.31.46-.41 1.03-.59 1.84-1.33 2.41-2.19-1.08-.38-2.24-.58-3.5-.58h-5.52c-.62 0-1.13.51-1.13 1.13v17.37c0 1.72 1.4 3.12 3.12 3.12h3.58c1.89 0 3.59-.45 5.09-1.31s2.67-2.07 3.5-3.64c.83-1.56 1.24-3.35 1.24-5.36v-1c0-1.78-.35-3.38-1.02-4.82v.01z" />
			<path d="M18.29 17c-.25 0-.48-.13-.61-.36-.68-1.16-1.65-2.11-2.86-2.84a.67.67 0 0 1 .01-1.17c1.19-.68 2.13-1.55 2.77-2.57.13-.21.34-.33.58-.33s.44.12.57.33c.66 1.11 1.59 1.97 2.83 2.65.19.1.31.3.32.53 0 .24-.1.45-.29.58-1.08.7-2.01 1.67-2.77 2.87-.12.19-.32.3-.54.3l-.01.01z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h29.99v37.14H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default DiscoverIcon;
