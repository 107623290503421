import customToast from '@/components/CustomToast/CustomToast';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import Delete from '@/components/ui/icons/Delete';
import DotMenu from '@/components/ui/icons/DotMenu';
import Clone from '@/components/ui/icons/new/Clone';
import InfoNew from '@/components/ui/icons/new/TemplateBuilder/InfoNew';
import Pencil from '@/components/ui/icons/Pencil';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import {
	handleApiError,
	handleGeneralError,
	handleUnexpectedError,
} from '@/lib/functions/funcUtils';
import { deleteSection } from '@/lib/functions/TemplateBuilder/templateCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { ErrorResponse } from '@/lib/types/apiTypes';
import { openDialog } from '@/redux/slices/pageSlice';
import { setEditSectionData } from '@/redux/slices/TemplateBuilder/sectionSlice';
import { setLoading } from '@/redux/slices/TemplateBuilder/templateSlice';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface GlobalSectionEditDropdownProps {
	rowInfo: any;
	visible: any;
}

const GlobalSectionEditDropdown: FC<GlobalSectionEditDropdownProps> = ({
	rowInfo,
	visible,
}) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const deleteMutation = useMutation((id: string) => deleteSection(id), {
		onSuccess: () => {
			customToast.success({
				title: 'Section deleted successfully',
			});
			// Invalidate and refetch the data query when the mutation is successful
			queryClient.invalidateQueries('availableSections');
		},
		onError: (error: any) => {
			if (error instanceof Error) {
				handleGeneralError(error);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
			} else {
				handleUnexpectedError();
			}
		},
		onSettled: () => {
			dispatch(setLoading(false));
		},
	});
	const OnDeleteSection = (id: string | undefined) => {
		if (id) {
			deleteMutation.mutate(id);
		}
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					className={`p-0 pb-1 ml-4 border-0 my-auto bg-transparent invisible group-hover/item:visible`}
				>
					<DotMenu className="w-4 h-4" fill="#5D9BFD" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				className="py-2 bg-white rounded-2xl"
				sideOffset={-5}
				alignOffset={-5}
				align="end"
			>
				<DropdownMenuCheckboxItem className="text-primary px-4 py-2 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary">
					<TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger
								type="button"
								className="flex flex-row items-center px-2 m-0 bg-transparent hover:bg-transparent animated-hover-effect"
							>
								<InfoNew />
								<h2 className="px-4 font-bold uppercase text-primary">Info</h2>
							</TooltipTrigger>
							<TooltipContent
								align="start"
								side="right"
								className="bg-white overflow-y-auto text-wrap max-w-md break-words text-[#5D6F79]
												dynamic-small py-4 px-4 rounded-xl border-none shadow-none drop-shadow-effect"
							>
								<span className="dnd_tooltip_description">
									{rowInfo.node.description}
								</span>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</DropdownMenuCheckboxItem>
				<DropdownMenuCheckboxItem className="text-primary py-0 px-3.5 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary uppercase">
					<Button
						className="px-2 m-0 bg-transparent hover:bg-transparent animated-hover-effect"
						title="Duplicate"
						onClick={() => {
							const newData = {
								...rowInfo.node,
								name: rowInfo.node.name + ' copy',
								alias: rowInfo.node.alias + ' copy',
							};
							dispatch(setEditSectionData(newData));
							dispatch(
								openDialog({
									type: 'createTemplateSection',
									editSectionID: rowInfo.node._id,
									duplicateSection: true,
									sectionCreatedByUser: true,
									sectionDialogStep: '3',
								})
							);
						}}
					>
						<Clone />{' '}
						<h2 className="px-4 font-bold uppercase text-primary">Copy</h2>
					</Button>
				</DropdownMenuCheckboxItem>
				<DropdownMenuCheckboxItem
					className={`text-primary px-4 py-0 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary uppercase ${
						visible ? 'visible' : 'hidden'
					}`}
				>
					<Button
						onClick={() => {
							dispatch(setEditSectionData(rowInfo.node));
							dispatch(
								openDialog({
									type: 'createTemplateSection',
									editSectionID: rowInfo.node._id,
									sectionCreatedByUser: true,
									sectionDialogStep: '3',
								})
							);
						}}
						className="px-1 m-0 bg-transparent hover:bg-transparent animated-hover-effect"
					>
						<Pencil />{' '}
						<h2 className="px-4 font-bold uppercase text-primary">Edit</h2>
					</Button>
				</DropdownMenuCheckboxItem>
				<DropdownMenuCheckboxItem
					className={`text-primary pl-4 py-0 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-white uppercase ${
						visible ? 'visible' : 'hidden'
					}`}
				>
					<Button
						onClick={() => {
							dispatch(
								openDialog({
									type: 'notification',
									notificationObj: {
										title: 'Remove Section',
										description:
											'Are you sure you want to remove this section ?',
										onConfirm: () => {
											OnDeleteSection(rowInfo.node._id);
										},
									},
								})
							);
						}}
						className="px-1 m-0 bg-transparent hover:bg-transparent animated-hover-effect"
					>
						<Delete fill={'#FF388C'} />{' '}
						<h2 className="px-4 text-[#FF388C] font-bold uppercase">Delete</h2>
					</Button>
				</DropdownMenuCheckboxItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default GlobalSectionEditDropdown;
