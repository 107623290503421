import { useSelector } from 'react-redux';
import styles from './VisualsWrapper.module.css';
import { RootState } from '@/redux/store/store';
import { cropText } from '@/lib/functions/funcUtils';
import { FC } from 'react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import WriteModeSkeleton from '@/components/WriteModeSkeleton/WriteModeSkeleton';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import AIWhite from '@/components/ui/icons/new/AIWhite';
import ChartImageItem from '@/components/ChartImageItem/ChartImageItem';
import VisualEmpty from '@/components/ui/icons/new/VisualEmpty';
import { Skeleton } from '@/components/ui/skeleton';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

interface VisualsWrapperProps {}

const VisualsWrapper: FC<VisualsWrapperProps> = () => {
	const proposalCharts = useSelector(
		(root: RootState) => root.chart.proposalCharts
	);
	const isStreaming = useSelector((root: RootState) => root.page.isStreaming);
	const dispatch = useAppDispatch();
	const loading = useSelector((root: RootState) => root.chart.loading);

	const { activeSection } = useActiveSection();

	if (isStreaming) return <WriteModeSkeleton />;

	const renderCharts = () => {
		if (loading) {
			return (
				<div className="flex flex-col gap-3 mt-4">
					<div className="p-4 bg-white rounded-xl">
						<Skeleton className="h-[190px] w-full rounded-xl bg-[#F5F5F5]" />
						<div className="flex items-center justify-between mt-3">
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
						</div>
					</div>
					<div className="p-4 bg-white rounded-xl">
						<Skeleton className="h-[190px] w-full rounded-xl bg-[#F5F5F5]" />
						<div className="flex items-center justify-between mt-3">
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
						</div>
					</div>
					<div className="p-4 bg-white rounded-xl">
						<Skeleton className="h-[190px] w-full rounded-xl bg-[#F5F5F5]" />
						<div className="flex items-center justify-between mt-3">
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
							<Skeleton className="h-4 w-[100px] bg-[#F5F5F5]" />
						</div>
					</div>
				</div>
			);
		} else {
			return proposalCharts.map((item) => (
				<ChartImageItem item={item} key={item.id} />
			));
		}
	};

	return (
		<div className={styles.container}>
			<div className=" bg-[#EAF1FC] h-screen pb-24 overflow-y-scroll light-scroll">
				{activeSection.sectionName && (
					<div className="sticky z-40 top-0 p-4 bg-[#EAF1FC] gap-2 flex justify-between items-center">
						<h3 className=" text-[#5D9BFD] font-bold flex items-center gap-3 flex-1 dynamic-medium">
							<span className="pr-6 border-r-2 border-[#0000] border-separater-pink">
								{activeSection.prefix}
							</span>
							{cropText(activeSection?.sectionName as string, 16)}
						</h3>
						<PrimaryButton
							onClick={() => dispatch(openDialog({ type: 'openVisuals' }))}
						>
							<AIWhite /> Create Visual
						</PrimaryButton>
					</div>
				)}
				{proposalCharts && proposalCharts.length > 0 ? (
					<div className="flex flex-col gap-4 px-4 py-4">{renderCharts()}</div>
				) : (
					<div className="flex items-center justify-center h-[calc(100vh-200px)] gap-2 flex-col">
						<VisualEmpty />
						<p className="text-[#5D6F79] font-bold max-w-72 text-center">
							No Visuals found.You can add a visualization by accessing the{' '}
							<span className="font-bold text-secondary">
								Create Visual Button
							</span>{' '}
							feature in the editor bar
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default VisualsWrapper;
