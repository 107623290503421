import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const NameEditorDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'nameEditor'
	);
	const nameEditor = useSelector(
		(root: RootState) => root.page.dialog?.nameEditorObj
	);
	const [name, setName] = useState(nameEditor?.existingName || '');

	useEffect(() => {
		if (isOpen) {
			setName(nameEditor?.existingName || '');
		}
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] p-6 bg-white rounded-xl gap-3">
				<DialogHeader className="font-bold text-secondary dynamic-large mb-1">
					{nameEditor?.title}
				</DialogHeader>
				{nameEditor?.description && (
					<p className=" text-[#6D7D86]">{nameEditor?.description}</p>
				)}
				<div className="flex flex-col">
					{nameEditor?.label && (
						<label className="block mb-1 font-bold text-black">
							{nameEditor?.label}
						</label>
					)}
					<Input
						id="label"
						type="text"
						placeholder={nameEditor?.placeholder || 'Enter Name'}
						className="w-full bg-white rounded-lg h-11"
						value={name}
						maxLength={nameEditor?.maxLength || 50}
						onChange={(e) => setName(e.target.value)}
					/>
					<p className="mt-1 text-right text-black dynamic-badge">
						{name?.length}/{nameEditor?.maxLength || 50}
					</p>
				</div>
				<div className="flex items-center justify-end gap-3">
					<PrimaryButton
						disabled={
							name === '' ||
							name?.length > (nameEditor?.maxLength || 50) ||
							name === nameEditor?.existingName
						}
						onClick={() => {
							nameEditor?.onConfirm && nameEditor?.onConfirm(name);
							dispatch(closeDialog());
						}}
					>
						{`${nameEditor?.confirmText || 'Update'}`}
					</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default NameEditorDialog;
