import { SVGProps } from 'react';

const EmptyWidgetImage = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="147"
			height="113"
			viewBox="0 0 147 113"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_14971_15372)">
				<path
					d="M134.266 27.5078H13.1525C11.5346 27.5078 10.2188 28.8183 10.2188 30.4415V102.393C10.2188 104.011 11.5292 105.327 13.1525 105.327H134.266C135.883 105.327 137.199 104.016 137.199 102.393V30.4415C137.199 28.8237 135.889 27.5078 134.266 27.5078Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M142.863 104.744H4.13626C2.46447 104.744 1.10547 106.098 1.10547 107.775V109.166C1.10547 110.838 2.46447 112.197 4.13626 112.197H142.868C144.54 112.197 145.899 110.838 145.899 109.166V107.775C145.899 106.103 144.54 104.744 142.868 104.744H142.863Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.6992 105.904H132.359"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M70.8794 38.2285H20.1594C18.2592 38.2285 16.7188 39.7689 16.7188 41.6692V91.165C16.7188 93.0652 18.2592 94.6056 20.1594 94.6056H70.8794C72.7796 94.6056 74.32 93.0652 74.32 91.165V41.6692C74.32 39.7689 72.7796 38.2285 70.8794 38.2285Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M25.8906 47.3105H63.6677"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M25.8906 56.8984H65.1184"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M25.8906 66.3848H65.1184"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M25.8906 75.9684L45.0407 75.8066"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M119.738 68.4395L106.822 68.4556C105.825 68.4556 105.016 69.2646 105.016 70.2676L105.037 88.7113C105.037 89.7089 105.846 90.5179 106.849 90.5179L119.765 90.5017C120.763 90.5017 121.572 89.6928 121.572 88.6897L121.55 70.2461C121.55 69.2484 120.741 68.4395 119.738 68.4395Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M107.66 73.46L115.005 73.4492L107.66 73.46Z" fill="white" />
				<path
					d="M107.66 73.46L115.005 73.4492"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M107.66 76.4972L117.621 76.4648L107.66 76.4972Z"
					fill="white"
				/>
				<path
					d="M107.66 76.4972L117.621 76.4648"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M107.66 79.4958L116.736 79.4473L107.66 79.4958Z"
					fill="white"
				/>
				<path
					d="M107.66 79.4958L116.736 79.4473"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M81.097 79.0749C87.6227 79.0749 92.9128 73.7848 92.9128 67.2591C92.9128 60.7335 87.6227 55.4434 81.097 55.4434C74.5714 55.4434 69.2812 60.7335 69.2812 67.2591C69.2812 73.7848 74.5714 79.0749 81.097 79.0749Z"
					fill="white"
					stroke="#E861C9"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M74.3203 67.2598L79.9828 73.4238"
					stroke="#E861C9"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M79.9828 61.0957L74.3203 67.2598H87.8726" fill="white" />
				<path
					d="M79.9828 61.0957L74.3203 67.2598H87.8726"
					stroke="#E861C9"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M136.38 9.15039H104.772C100.698 9.15039 97.3945 12.4534 97.3945 16.5278V85.7561C97.3945 89.8306 100.698 93.1336 104.772 93.1336H136.38C140.454 93.1336 143.757 89.8306 143.757 85.7561V16.5278C143.757 12.4534 140.454 9.15039 136.38 9.15039Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M131.155 47.6289H114.032C111.745 47.6289 109.891 49.4832 109.891 51.7706V54.3916C109.891 56.679 111.745 58.5333 114.032 58.5333H131.155C133.442 58.5333 135.296 56.679 135.296 54.3916V51.7706C135.296 49.4832 133.442 47.6289 131.155 47.6289Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M115.883 51.2637H129.764"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M115.883 54.8984H126.696"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M125.383 64.3242L112.467 64.3404C111.469 64.3404 110.66 65.1493 110.66 66.1524L110.682 84.596C110.682 85.5937 111.491 86.4026 112.494 86.4026L125.41 86.3865C126.407 86.3865 127.216 85.5775 127.216 84.5745L127.195 66.1308C127.195 65.1331 126.386 64.3242 125.383 64.3242Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M113.305 69.3448L120.65 69.334L113.305 69.3448Z"
					fill="#EAF1FC"
				/>
				<path
					d="M113.305 69.3448L120.65 69.334"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M113.305 72.3761L123.265 72.3438L113.305 72.3761Z"
					fill="#EAF1FC"
				/>
				<path
					d="M113.305 72.3761L123.265 72.3438"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M113.305 75.3806L122.381 75.332L113.305 75.3806Z"
					fill="#EAF1FC"
				/>
				<path
					d="M113.305 75.3806L122.381 75.332"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M131.011 21.0586H113.506C111.138 21.0586 109.219 22.9781 109.219 25.3459V37.5446C109.219 39.9124 111.138 41.8319 113.506 41.8319H131.011C133.379 41.8319 135.299 39.9124 135.299 37.5446V25.3459C135.299 22.9781 133.379 21.0586 131.011 21.0586Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M135.3 24.359H129.848V21.0586" fill="#EAF1FC" />
				<path
					d="M135.3 24.359H129.848V21.0586"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M120.579 16.8198C124.814 16.8198 128.247 13.3864 128.247 9.15109C128.247 4.9158 124.814 1.48242 120.579 1.48242C116.344 1.48242 112.91 4.9158 112.91 9.15109C112.91 13.3864 116.344 16.8198 120.579 16.8198Z"
					fill="white"
					stroke="#5D9BFD"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M120.578 13.551L124.58 9.87305"
					stroke="#5D9BFD"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M116.578 9.87323L120.58 13.5512V4.75" fill="white" />
				<path
					d="M116.578 9.87323L120.58 13.5512V4.75"
					stroke="#5D9BFD"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M67.0308 84.4629L69.7003 85.919L67.0308 87.3805L65.5748 90.0445L64.1187 87.3805L61.4492 85.919L64.1187 84.4629L65.5748 81.7988L67.0308 84.4629Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M58.1641 74.0917L62.721 76.5832L58.1641 79.0801L55.6672 83.637L53.1757 79.0801L48.6133 76.5832L53.1757 74.0917L55.6672 69.5293L58.1641 74.0917Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.61786"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14971_15372">
					<rect
						width="146.406"
						height="112.328"
						fill="white"
						transform="translate(0.296875 0.671875)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default EmptyWidgetImage;
