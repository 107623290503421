import { Dialog, DialogContent } from '@/components/ui/dialog';

import {
	handleApiError,
	handleGeneralError,
	handleUnexpectedError,
} from '@/lib/functions/funcUtils';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';

import { closeDialog, openDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import customToast from '@/components/CustomToast/CustomToast';
import { Tag } from '@/components/ui/shared/TagInput/tag-input';
import { AxiosError } from 'axios';
import { X } from 'lucide-react';
import {
	AISectionRequest,
	SectionAutoComplete,
	SectionBuilder,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { ErrorResponse } from '@/lib/types/apiTypes';
import {
	autoCompelteSection,
	createSection,
} from '@/lib/functions/TemplateBuilder/templateCalls';
import {
	extractNames,
	extractTableHeading,
	solicitationAssociativeArrayConversion,
	tagAssociativeArray,
	tagComponentAssociativeArray,
} from '@/lib/functions/TemplateBuilder/templateUtils';

import { Form } from '@/components/ui/form';
import { SectionStep1, SectionStep2, SectionStep3 } from './SectionDialogSteps';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import { defaultAiSection } from '@/constants/templateBuilderData';
import LoaderPanel from '@/components/Loader/LoaderPanel/LoaderPanel';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import { useTemplateMutations } from '@/lib/hooks/templateBuilder/useTemplateMutations';

const SectionDialog = () => {
	const queryClient = useQueryClient();

	const orgIdentifier = useOrganizationId();
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(
		(state: RootState) => state.page.dialog?.type === 'createTemplateSection'
	);
	const editSectionID = useAppSelector(
		(state: RootState) => state.page.dialog?.editSectionID
	);

	const duplicateSection = useAppSelector(
		(state: RootState) => state.page.dialog?.duplicateSection
	);
	const sectionStep = useAppSelector(
		(state: RootState) => state.page.dialog?.sectionDialogStep
	);
	const sectionCreatedByUser = useAppSelector(
		(state: RootState) => state.page.dialog?.sectionCreatedByUser
	);
	const editSectionData = useAppSelector(
		(state: RootState) => state.sections.editSection
	);
	const sectionTagOptions = useAppSelector(
		(root: RootState) => root.sections.sectionTagOptions
	);
	const [showTableHeading, setTableHeadingPreview] = useState(false);
	const [tags, setTags] = useState<any>([]);
	const [tableHeadings, setTableHeadings] = useState<Tag[]>([]);
	const [solicitationsArray, setSolicitations] = useState<any>([]);
	const [aiSectionData, setAiSectionData] =
		useState<SectionAutoComplete>(defaultAiSection);

	const form = useForm<SectionBuilder>({
		defaultValues: {
			name: editSectionData?.name || '',
			level: editSectionData?.level || 0,
			order: editSectionData?.order || 1,
			organizationIdentifier:
				editSectionData?.organizationIdentifier || orgIdentifier,
			isLive: editSectionData?.isLive || false,
			type: editSectionData?.type || 'default',
			defaultContext: editSectionData?.defaultContext || '',
			specialContext: editSectionData?.specialContext || '',
			prerequisites: editSectionData?.prerequisites || [],
			children: editSectionData?.children || [],
			alias: editSectionData?.alias || '',
			wordCount: editSectionData?.wordCount,
			requiredRfpSections: editSectionData?.requiredRfpSections || [],
			questions: editSectionData?.questions || [],
			description: editSectionData?.description || '',
			systemName: editSectionData?.systemName || '',
			expectedOutput: editSectionData?.expectedOutput || 'text',
			tableHeadings: editSectionData?.tableHeadings || '',
			tags: editSectionData?.tags || [],
		},
	});
	const aiSectionForm = useForm<AISectionRequest>({
		defaultValues: {
			name: '',
			description: '',
		},
	});
	const {
		handleSubmit,
		formState: { errors },
		reset,
		watch,
		setValue,
	} = form;

	const {
		handleSubmit: aihandleSubmit,
		// formState: aiErrors,
		reset: aiReset,
		watch: aiWatch,
		// setValue: aiSetValue,
	} = aiSectionForm;

	const DialogClosed = () => {
		form.reset();
		aiReset();
		dispatch(closeDialog());
	};
	const mutation = useMutation((data: SectionBuilder) => createSection(data), {
		onSuccess: () => {
			dispatch(closeDialog());
			customToast.success({
				title: 'Section created successfully',
			});
			reset();
		},
		onError: (error: any) => {
			const axiosError = error as AxiosError;
			if (axiosError && axiosError.response) {
				const apiError = axiosError.response.data as ErrorResponse;
				handleApiError(apiError);
			} else if (error instanceof Error) {
				handleGeneralError(error);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
			} else {
				handleUnexpectedError();
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries('section_tags');
			// Invalidate and refetch the data query when the mutation is successfull
			sectionCreatedByUser
				? queryClient.invalidateQueries('availableSections')
				: queryClient.invalidateQueries('sections');
		},
	});

	const { updateSectionMutation, createUpdateTagMutation } =
		useTemplateMutations({ reset });
	const filterTags = (values: any) => {
		const tagValues =
			values.every((element) => typeof element !== 'string') &&
			values.filter((v) => !('organizationIdentifier' in v));
		const defaultTagOptions = sectionTagOptions.filter(
			(v) => v.organizationIdentifier !== undefined
		);
		if (tagValues && tagValues.length > 0) {
			const finalTags = tagValues.concat(defaultTagOptions);

			const data = {
				organizationIdentifier: `sections_${orgIdentifier}`,
				tags: extractNames(finalTags),
			};

			return data;
		} else {
			return null;
		}
	};
	const onSubmit = async (data: SectionBuilder) => {
		try {
			if (
				orgIdentifier === 'undefined' ||
				orgIdentifier === undefined ||
				orgIdentifier === 'null' ||
				orgIdentifier === null
			) {
				customToast.error({
					title: 'Organization Identifier is missing. Please contact support.',
				});
				return;
			}
			data.level = 0;
			data.alias = data.name;
			data.type = 'default';
			data.systemName = '';
			data.wordCount = data.wordCount && Number(data.wordCount);
			data.questions[0].order = 1;
			data.questions[0].rfpSections = [];
			data.questions[0].heading = data.name;
			data.organizationIdentifier = orgIdentifier;
			data.isLive = true;
			(data.specialContext as string | null) =
				data.specialContext !== '' ? data.specialContext : null;
			if (!data.tableHeadings && data.expectedOutput === 'table') {
				customToast.error({
					title: 'Table Headings can not be empty',
				});
				return;
			} else {
				data.tableHeadings =
					data.expectedOutput === 'table'
						? data.tableHeadings && typeof data.tableHeadings === 'string'
							? data.tableHeadings
							: extractTableHeading(data.tableHeadings).join(',')
						: null;
			}
			if (data.requiredRfpSections && data.requiredRfpSections.length > 0) {
				data.requiredRfpSections = data.requiredRfpSections.every(
					(element) => typeof element === 'string'
				)
					? data.requiredRfpSections
					: extractNames(data.requiredRfpSections);
			}

			const updatedTags = filterTags(data.tags);

			data.tags = data?.tags?.every((element) => typeof element === 'string')
				? data.tags
				: extractNames(data.tags);
			if (updatedTags && updatedTags.tags && updatedTags.tags.length > 0) {
				createUpdateTagMutation.mutate(updatedTags);
			}
			if (editSectionID && !duplicateSection) {
				data._id = editSectionID;
				updateSectionMutation.mutate(data);
			} else {
				mutation.mutate(data);
			}
		} catch (error: unknown) {
			const axiosError = error as AxiosError;
			if (axiosError && axiosError.response) {
				const apiError = axiosError.response.data as ErrorResponse;
				handleApiError(apiError);
			} else if (error instanceof Error) {
				handleGeneralError(error);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
			} else {
				handleUnexpectedError();
			}
		}
	};
	useEffect(() => {
		aiSectionForm.reset();
		if (editSectionID !== undefined && editSectionID !== null) {
			form.reset();
			setValue('tags', editSectionData?.tags);
			setValue('name', editSectionData?.name || '');
			if (editSectionData?.wordCount) {
				setValue('wordCount', editSectionData?.wordCount);
			}
			setValue('defaultContext', editSectionData?.defaultContext || '');
			setValue('specialContext', editSectionData?.specialContext || '');
			setValue('alias', editSectionData?.alias || '');
			setValue('description', editSectionData?.description || '');
			setValue('expectedOutput', editSectionData?.expectedOutput || '');
			setValue('questions.0.text', editSectionData?.questions[0].text || '');
			setValue(
				'questions.0.description',
				editSectionData?.questions[0].description || ''
			);
			setValue(
				'questions.0.proTip',
				editSectionData?.questions[0].proTip || ''
			);
			setValue('isLive', editSectionData?.isLive || false);
			setValue('tableHeadings', editSectionData?.tableHeadings);
			const tagsArray = tagAssociativeArray(editSectionData?.tags);
			setTags(tagsArray);
			setValue(
				'requiredRfpSections',
				editSectionData?.requiredRfpSections || []
			);
			setSolicitations(
				solicitationAssociativeArrayConversion(
					editSectionData?.requiredRfpSections
				)
			);
			if (editSectionData?.tableHeadings) {
				setTableHeadingPreview(true);
				const splitTableHeadings = tagComponentAssociativeArray(
					editSectionData.tableHeadings.split(',')
				);
				setTableHeadings(splitTableHeadings);
			} else {
				setTableHeadingPreview(false);
			}
		} else {
			setTags([]);
			setSolicitations([]);
			setTableHeadings([]);
			setTableHeadingPreview(false);
		}
	}, [editSectionID, form, aiSectionForm]);

	/**
	 * ai autocomplete section form
	 */
	const aiSectionMutation = useMutation(
		(data: any) => autoCompelteSection(data),
		{
			onSuccess: (response: any) => {
				const responseData = response.data['autoCompleteResults'];
				if (responseData) {
					/** set form values  */
					const wordCount = aiSectionForm.getValues('wordCount');
					const expectedOutput = aiSectionForm.getValues('expectedOutput');
					const tableHeadings = aiSectionForm.getValues('tableHeadings');
					if (tableHeadings) {
						responseData.tableHeadings =
							tableHeadings && typeof tableHeadings === 'string'
								? tableHeadings
								: extractTableHeading(tableHeadings).join(',');
					} else if (responseData.tableHeadings) {
						responseData.tableHeadings =
							responseData.tableHeadings &&
							typeof responseData.tableHeadings === 'string'
								? responseData.tableHeadings
								: extractTableHeading(responseData.tableHeadings).join(',');
					}
					if (expectedOutput) {
						responseData.expectedOutput = expectedOutput;
					}
					if (wordCount) {
						responseData.wordCount = wordCount;
					}
					setAiSectionData(responseData);
					setValue('tags', responseData.tags);
					setValue('name', responseData.name);
					setValue(
						'defaultContext',
						responseData.defaultContext
							.replace(/\n/g, '\n')
							.replace(/["']/g, '')
					);
					setValue(
						'specialContext',
						responseData.specialContext
							? responseData.specialContext
									.replace(/\n/g, '\n')
									.replace(/["']/g, '')
							: ''
					);
					setValue('alias', responseData.alias);
					setValue(
						'description',
						responseData.description.replace(/\n/g, '\n').replace(/["']/g, '')
					);
					setValue(
						'expectedOutput',
						expectedOutput ? expectedOutput : responseData.expectedOutput
					);
					setValue(
						'questions.0.text',
						responseData.questions[0].text
							.replace(/\n/g, '\n')
							.replace(/["']/g, '')
					);
					setValue(
						'questions.0.description',
						responseData.questions[0].description
							.replace(/\n/g, '\n')
							.replace(/["']/g, '')
					);
					setValue(
						'questions.0.proTip',
						responseData.questions[0].proTip
							.replace(/\n/g, '\n')
							.replace(/["']/g, '')
					);
					setValue('isLive', true);
					setValue('requiredRfpSections', responseData.solicitation);
					setValue('wordCount', wordCount ? wordCount : responseData.wordCount);
					if (tableHeadings) {
						setValue('tableHeadings', tableHeadings);
					} else if (responseData.tableHeadings) {
						setValue('tableHeadings', responseData.tableHeadings);
					}

					const tagsArray = tagAssociativeArray(responseData.tags);
					setTags(tagsArray);

					setSolicitations(
						solicitationAssociativeArrayConversion(responseData.solicitation)
					);
					dispatch(
						openDialog({
							type: 'createTemplateSection',
							editSectionID: null,
							sectionCreatedByUser: true,
							sectionDialogStep: '2',
						})
					);
				} else {
					console.log('section ai response failed.');
				}
			},
			onError: (error: any) => {
				const axiosError = error as AxiosError;
				if (axiosError && axiosError.response) {
					const apiError = axiosError.response.data as ErrorResponse;
					handleApiError(apiError);
				} else if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
			onSettled: () => {},
		}
	);
	const onAiSubmit = async (data: AISectionRequest) => {
		try {
			const requestData = {
				name: data.name,
				description: data.description,
			};
			aiSectionMutation.mutate(requestData);
		} catch (error: unknown) {
			const axiosError = error as AxiosError;
			if (axiosError && axiosError.response) {
				const apiError = axiosError.response.data as ErrorResponse;
				handleApiError(apiError);
			} else if (error instanceof Error) {
				handleGeneralError(error);
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
			} else {
				handleUnexpectedError();
			}
		}
	};
	if (
		mutation.isLoading ||
		updateSectionMutation.isLoading ||
		createUpdateTagMutation.isLoading
	) {
		return <LoaderTransparent />;
	}
	return (
		<div className="relative">
			<Dialog open={isOpen}>
				<DialogContent
					hideCloseBtn={true}
					className={`flex flex-col mb-0 h-max overflow-hidden max-w-[90%] 
					${sectionStep && sectionStep === '1' ? '2xl:max-w-[750px] lg:h-[720px] 2xl:h-[740px]' : '2xl:max-w-[1200px]'}
					 bg-white p-6`}
				>
					{aiSectionMutation.isLoading && <LoaderPanel />}

					{((sectionStep && sectionStep === '1') ||
						(sectionStep && sectionStep === '3')) && (
						<div className="flex items-center justify-between">
							<h2 className="font-bold capitalize dynamic-xl-large text-secondary">
								{editSectionData && !duplicateSection
									? 'Update Section'
									: 'Create new section'}
							</h2>
							<X
								onClick={() => {
									DialogClosed();
								}}
								className="w-10 h-10 p-2 bg-white rounded-full cursor-pointer stroke-primary"
							/>
						</div>
					)}

					{sectionStep && sectionStep === '3' && (
						<p className="text-black dynamic-text">
							<b>All fields are required.</b> Please make sure you fill them
							before selecting the create new section option. Select the default
							sections the system will pull from your solicitation.
						</p>
					)}
					{sectionStep && sectionStep === '1' && (
						<p className="text-black dynamic-text">
							Please provide a <b>name</b> and a comprehensive{' '}
							<b>description of this section</b>
						</p>
					)}
					{sectionStep && sectionStep === '1' && (
						<div className="flex flex-col h-full min-h-0 overflow-y-scroll pr-1.5">
							<Form {...aiSectionForm}>
								<form className="w-full" onSubmit={aihandleSubmit(onAiSubmit)}>
									{/* step 1 */}

									<SectionStep1
										form={form}
										aiSectionForm={aiSectionForm}
										aiWatch={aiWatch}
										editSectionData={editSectionData}
										setTableHeadings={setTableHeadings}
										setTableHeadingPreview={setTableHeadingPreview}
										showTableHeading={showTableHeading}
										tableHeadings={tableHeadings}
										sectionLoading={aiSectionMutation.isLoading}
									/>
								</form>
							</Form>
						</div>
					)}
					{((sectionStep && sectionStep === '2') ||
						(sectionStep && sectionStep === '3')) && (
						<Form {...form}>
							<form className="w-full" onSubmit={handleSubmit(onSubmit)}>
								{/* step 2 */}
								{sectionStep && sectionStep === '2' && (
									<SectionStep2
										form={form}
										aiSectionForm={aiSectionForm}
										aiSectionData={aiSectionData}
										setAiSectionData={setAiSectionData}
									/>
								)}
								{sectionStep && sectionStep === '3' && (
									<div className="flex flex-col h-[calc(100vh-400px)] overflow-y-scroll pr-4">
										{/* step 3 */}
										<SectionStep3
											form={form}
											watch={watch}
											errors={errors}
											editSectionData={editSectionData}
											aiSectionData={aiSectionData}
											setTableHeadings={setTableHeadings}
											setTableHeadingPreview={setTableHeadingPreview}
											showTableHeading={showTableHeading}
											tableHeadings={tableHeadings}
											setTags={setTags}
											tags={tags}
											setSolicitations={setSolicitations}
											solicitationsArray={solicitationsArray}
										/>
									</div>
								)}
							</form>
						</Form>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
};
export default SectionDialog;
