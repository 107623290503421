import { RootState } from '@/redux/store/store';
import { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import DesignMode from '@/pages/DesignMode/DesignMode';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { setLastVisitPage } from '@/redux/slices/pageSlice';
import WriteMode from '@/pages/WriteMode/WriteMode';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import ProposalDashboard from '../ProposalDashboard/ProposalDashboard';

interface AppPaneProps {
	defaultTab: string;
}

const AppPane: FC<AppPaneProps> = ({ defaultTab }) => {
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const navigate = useNavigate();
	const lastSection = useSelector((state: RootState) => state.page.lastSection);
	const dispatch = useAppDispatch();
	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);

	const [selectedTab, setSelectedTab] = useState(defaultTab);

	const navigateToTab = useCallback(
		(newTab: string) => {
			if (!proposal || !lastSection?.sectionId || !lastSection?.proposalId)
				return;

			let path = '';

			switch (newTab) {
				case 'design':
					path = `/proposal/${proposal.id}/section/${lastSection.sectionId}/design`;
					dispatch(setLastVisitPage({ page: 'design' }));
					break;
				case 'write':
					path = `/proposal/${proposal.id}/section/${lastSection.sectionId}/write`;
					dispatch(setLastVisitPage({ page: 'write' }));
					break;
				case 'dashboard':
					path = `/proposal/${proposal.id}`;
					dispatch(setLastVisitPage({ page: 'dashboard' }));
					break;
				default:
					break;
			}

			if (path) {
				navigate(path);
			}
		},
		[proposal, lastSection, dispatch, navigate]
	);

	const handleTabChange = useCallback(
		(newTab: string) => {
			setSelectedTab(newTab);
			navigateToTab(newTab);
		},
		[navigateToTab]
	);

	useEffect(() => {
		if (!isStreaming) {
			handleTabChange(defaultTab);
		}
	}, [isStreaming, defaultTab, handleTabChange]);

	return (
		<AppTabs
			defaultTab={defaultTab}
			selectedTab={selectedTab}
			handleTabChange={handleTabChange}
			type="main"
			tabs={[
				{
					label: 'Dashboard',
					value: 'dashboard',
					content: <ProposalDashboard exiting={selectedTab !== 'dashboard'} />,
					onClick: () => handleTabChange('dashboard'),
				},
				{
					label: 'Design',
					value: 'design',
					content: <DesignMode exiting={selectedTab !== 'design'} />,
					onClick: () => handleTabChange('design'),
				},
				{
					label: 'Write',
					value: 'write',
					content: <WriteMode exiting={selectedTab !== 'write'} />,
					onClick: () => handleTabChange('write'),
				},
			]}
		/>
	);
};

export default AppPane;
