import {
	Page,
	Text,
	View,
	Document,
	Font,
	StyleSheet,
} from '@react-pdf/renderer';
import { FC } from 'react';
import FontRobotoRegular from '/fonts/Roboto-Regular.ttf';
import FontRobotoBold from '/fonts/Roboto-Bold.ttf';

Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: FontRobotoRegular,
		},
		{
			src: FontRobotoBold,
			fontWeight: 'bold',
		},
	],
});

interface SectionDetailsProps {
	sectionNames: string[];
}

// Create styles
const styles = StyleSheet.create({
	page: {
		padding: 24,
		flexDirection: 'column',
		backgroundColor: '#EEF2F9',
		fontFamily: 'Roboto',
		color: '#5D6F79',
	},
	title: {
		fontSize: 14,
		fontWeight: 'bold',
	},
	infoSection: {
		marginTop: 12,
		padding: 12,
		borderRadius: 12,
		backgroundColor: '#FFFFFF',
	},
	section: {
		marginTop: 12,
		padding: 12,
		borderRadius: 12,
		minHeight: 100,
		backgroundColor: '#FFFFFF',
	},
	text: {
		fontSize: 12,
	},
	secondaryFont: {
		color: '#E861C9',
	},
});

// Create Document Component
const SectionDetails: FC<SectionDetailsProps> = ({ sectionNames }) => {
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Text style={styles.title}>Required Sections</Text>
				<View style={styles.infoSection}>
					<Text style={styles.text}>
						<Text style={styles.secondaryFont}>Please note:</Text>
						These are sections identified in the solicitation that are required
						by the funder. Please compare your template (on the right) with
						sections we have identified. It is a good practice to include
						sections in your proposal identified in the solicitation
						requirement.
					</Text>
				</View>
				<View style={styles.section}>
					{sectionNames.map((sectionName, index) => (
						<Text style={styles.text} key={index}>
							{sectionNames.length === 1 && sectionName === 'NONE'
								? 'It appears that the solicitation does not provide specific information about the template sections.'
								: sectionName}
						</Text>
					))}
				</View>
			</Page>
		</Document>
	);
};

export default SectionDetails;
