import { FC, useEffect, useMemo, useState } from 'react';
import ProjectHeader from '@/components/Project/ProjectHeader/ProjectHeader';
import { getColumns } from '@/components/Tables/FavoriteTable/columns';
import {
	getAllProposalsByAction,
	managedAssignedProposals,
} from '@/lib/functions/apiCalls';
import { useQuery, useQueryClient } from 'react-query';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import TableHeader from '@/components/TableHeader/TableHeader';
import { FavoriteTable } from '@/components/Tables/FavoriteTable/FavoriteTable';
import customToast from '@/components/CustomToast/CustomToast';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { clearPageState, setLastVisitPage } from '@/redux/slices/pageSlice';
import { clearProposal } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useNavigate } from 'react-router';
import { motion, useAnimation } from 'framer-motion';
import { iCSlideIn } from '@/constants/variants';
import { resetChartData } from '@/redux/slices/chartSlice';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

interface FavoritePageProps {
	exiting?: boolean;
}

const FavoritePage: FC<FavoritePageProps> = ({ exiting }) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const activeProposal = useAppSelector(
		(root: RootState) => root.proposal.proposal
	);
	const navigate = useNavigate();
	const fetchProposalByFavorite = () => getAllProposalsByAction('favourite');
	const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
	const {
		data: proposalData,
		isLoading: isProposalLoading,
		isError: isProposalError,
	} = useQuery('favouriteProposals', fetchProposalByFavorite);
	const favouriteProposal = (proposalData?.data as any)?.proposals;
	const { resetActiveSection } = useActiveSection();

	const exitControl = useAnimation();

	const hideAnimation = useMemo(() => {
		return { opacity: 0, transition: { duration: 0.25 } };
	}, []);

	useEffect(() => {
		if (exiting === true) {
			exitControl.start(hideAnimation);
		}
	}, [exiting, exitControl]);

	if (isProposalLoading) return <LoaderTransparent />;

	if (isProposalError) return <div>Error</div>;

	const handleNavigate = (id) => {
		dispatch(clearProposal());
		dispatch(resetChartData());
		dispatch(clearPageState());
		resetActiveSection();
		if (!activeProposal || activeProposal?.id !== id) {
			navigate(`/proposal/${id}`);
			dispatch(setLastVisitPage({ page: 'dashboard' }));
		}
	};

	const handleUnfavorite = async () => {
		try {
			const response = await managedAssignedProposals('unfavourite', {
				proposalIds: selectedRowIds.map((id) => Number(id)),
			});
			if (response.statusCode === 201) {
				customToast.success({
					title: `Proposals  UnFavourited successfully`,
				});
				queryClient.invalidateQueries(['favouriteProposals']);
			}
			setSelectedRowIds([]);
		} catch (err) {
			console.log(err);
			customToast.error({
				title: 'Something went wrong',
			});
		}
	};

	return (
		<>
			<ProjectHeader type="favorites" />
			<motion.div animate={exitControl}>
				<motion.div
					variants={iCSlideIn}
					initial="initial"
					animate="animate"
					exit="exit"
					className="px-12 py-4"
				>
					<div className="px-12 py-4">
						<TableHeader
							count={favouriteProposal?.length}
							title={'Favorites'}
							onUnFavorite={handleUnfavorite}
							isFavDisabled={selectedRowIds.length === 0}
						/>
						<div className="mx-auto max-w-[95%]">
							<FavoriteTable
								columns={getColumns(
									handleNavigate,
									setSelectedRowIds,
									selectedRowIds
								)}
								data={favouriteProposal}
								isFavDisabled={selectedRowIds.length === 0}
							/>
						</div>
					</div>
				</motion.div>
			</motion.div>
		</>
	);
};

export default FavoritePage;
