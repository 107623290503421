import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {
	authReducer,
	proposalReducer,
	questionReducer,
	pageReducer,
	brainStormReducer,
	suggestionReducer,
	solicitationReducer,
	complianceMatrixReducer,
	chartReducer,
	sectionReducer,
	templateReducer,
	tagReducer,
	discoverReducer,
	tourReducer,
	enhanceReducer,
	unsolicitedReducer,
} from '../slices';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: [
		'brainStorm',
		'suggestion',
		'question',
		'compliance',
		'chart',
		'discover',
		'tags',
		'manualSolicitation',
		'tour',
	],
};

const rootReducer = combineReducers({
	auth: authReducer,
	proposal: proposalReducer,
	question: questionReducer,
	page: pageReducer,
	brainStorm: brainStormReducer,
	suggestion: suggestionReducer,
	solicitation: solicitationReducer,
	compliance: complianceMatrixReducer,
	chart: chartReducer,
	sections: sectionReducer,
	templates: templateReducer,
	tags: tagReducer,
	discover: discoverReducer,
	tour: tourReducer,
	enhance: enhanceReducer,
	unsolicited: unsolicitedReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
// export const api = createApi(store);
