# EULA AND TRIAL AGREEMENT TERMS OF SERVICE

## Grant Assistant Trial Agreement

This Grant Assistant Trial Agreement (“Agreement”) is between Grant Assistant Inc. (“GAI”) and the individual or entity accepting this Agreement (“Customer” or “you” or “your”) and sets out the terms under which you will evaluate GAI’s certain products and offerings on a no-charge basis.

This Agreement is subject to and incorporates the below terms and conditions.

By accepting this Agreement you represent and warrant as applicable that you:
- have full legal authority to bind your organization (e.g. employer) to this Agreement; and
- you understand and agree to this Agreement on behalf of such organization.

## Terms and Conditions

The parties agree as follows:

### 1. Definitions

- **Customer Data**: Any data, information, or material that Customer or Customer’s Users may disclose or submit to GAI or the Trial Services in the course of using the Trial Services.
- **Intellectual Property Rights**: Unpatented inventions, patent applications, patents, design rights, copyrights, trademarks, service marks, trade names, domain name rights, mask work rights, know-how, and other trade secret rights, and all other intellectual property rights, derivatives thereof, and forms of protection of a similar nature anywhere in the world.
- **Sensitive Personal Data**: Personally-identifiable information that consists of: (i) government-issued identification number of any kind; (ii) health, genetic, biometric record or data of any kind; (iii) personal financial or bank account number, credit or debit card number with or without any required security code, access code, or any personal identification number or password that would permit access to the individual's financial account; (iv) any employee or human resources records; and/or (v) any consumer or household data of any kind.
- **Trial Period**: The 28-day period (or other period as determined by GAI or mutually agreed to by the parties) that the Customer is authorized to access and use the Trial Services commencing on the first day that the Trial Services are activated by GAI for Customer’s use.
- **Trial Services**: Access to a designated set of GAI’s proprietary technology (including SaaS, open-source software, source-available software, products, processes, algorithms, user interfaces, know-how, techniques, designs, and other tangible or intangible technical material or information) and functionality for the purpose of enabling Customer’s evaluation during the Trial Period.
- **User(s)**: Customer’s employees, representatives, consultants, contractors, or agents who are authorized to use the Trial Services and have been supplied temporary user identifications and passwords by GAI.

### 2. Overview; License Grant

#### 2.1 Overview
This Agreement will be effective for the duration of the Trial Period unless such Trial Period is extended by mutual agreement of the parties. The terms and conditions of this Agreement shall govern the Trial Services to be provided by GAI during the Trial Period.

#### 2.2 License Grant
Subject to the terms and conditions of this Agreement, GAI hereby grants Customer a non-exclusive, non-transferable, non-assignable limited license to use the Trial Services during the Trial Period solely and strictly for purposes of its own internal evaluation of the GAI Services and not for any commercial or competitive purpose. For clarity, Customer may not use the Trial Services for any production purposes without GAI’s prior written consent and must abide by any use limits set forth in the Trial Services interface or as otherwise communicated by GAI. All rights not expressly granted to Customer are reserved by GAI.

### 3. Use Restrictions
Customer will not:
- (a) Sell, rent, lease, license, distribute, provide direct access to, sublicense, or otherwise make available the Trial Services to a third-party or in a service bureau or outsourcing offering;
- (b) Reverse engineer, decompile, disassemble, or otherwise seek to obtain the source code or non-public APIs to the Trial Services;
- (c) Remove or obscure any proprietary or other notices contained in the Trial Services;
- (d) Copy, reproduce, or modify the Trial Services;
- (e) Breach, circumvent, disable, or tamper with the security of the Trial Services;
- (f) Introduce into the Trial Services any software virus, worm, “back door,” Trojan Horse, or similar harmful code;
- (g) Access or use the Trial Services to monitor the availability, performance, or functionality of the Trial Services for any competitive purposes or for the purpose of building a competitive product or service; or
- (h) Use the Trial Services to process Sensitive Personal Data (without GAI’s prior written consent) or to send spam or engage in other unlawful communications or to process infringing or otherwise unlawful or unauthorized Customer Data. Additionally, and for clarity, creating multiple accounts for the purposes of avoiding any use limits or to refrain from converting to an enterprise subscription is a material violation of this Agreement.

### 4. Trial Period; Enterprise Subscription
Customer acknowledges and agrees that at the end of the Trial Period (including any mutually agreed upon extension), Customer’s access to the Trial Services will be automatically terminated with or without notice unless Customer elects to convert the Trial Services to an enterprise license on a paid subscription basis. Customer agrees to contact GAI at least two (2) business days prior to the end of the Trial Period if Customer wishes to continue using the Trial Services beyond the initial Trial Period. In the event Customer wishes to enter into an enterprise license, Customer will be required to (a) agree to a separate master services agreement as provided by GAI and (b) execute an order form detailing the Services, duration, and pricing applicable to the use of the Services.

### 5. Customer’s Responsibilities
Customer is responsible for all activity occurring under Customer’s designated User accounts and shall comply with all applicable laws and regulations in connection with Customer’s use of the Trial Services, including but not limited to those related to data privacy, the transmission of technical or personal data, and export control laws and regulations. Customer shall:
- (i) Notify GAI immediately of any unauthorized use of any password or account or any other known or suspected breach of security with respect to the Trial Services;
- (ii) Report to GAI immediately and use reasonable efforts to stop immediately any copying or distribution or misuse of the Trial Services that becomes known or suspected by Customer or Customer’s Users; and
- (iii) Not impersonate another GAI user or provide false identity information to gain access to or use the Trial Services.

### 6. Intellectual Property Ownership
GAI alone shall own all right, title, and interest, including all related Intellectual Property Rights, in and to the Trial Services, including to all enhancements, enhancement requests, suggestions, modifications, extensions, and/or derivative works thereof. This Agreement is not a sale and does not convey to Customer any rights of ownership in or related to the Trial Services or the Intellectual Property Rights owned by GAI. The GAI name, the GAI logo, and the product names associated with the Trial Services are trademarks of GAI and no right or license is granted to use them.

### 7. Suspension and Termination.
GAI reserves the right to suspend or terminate this Agreement and the Trial Services with or without cause at any time with or without notice. Customer may terminate the Trial Services with or without cause at any time by providing a written notice to GAI at [support@grantassistant.ai](mailto:support@grantassistant.ai).

### 8. Disclaimer of Warranty
THE TRIAL SERVICES ARE PROVIDED “AS IS” AND GAI MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, AND GAI SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE OR ANY IMPLIED WARRANTIES ARISING OUT OF THE COURSE OF DEALING OR THE USAGE OF TRADE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. GAI DOES NOT WARRANT THAT THE TRIAL SERVICES ARE OR WILL BE ERROR-FREE.

### 9. Limitation of Liability
IN NO EVENT SHALL GAI BE LIABLE FOR ANY DAMAGES OF WHATEVER NATURE AS A RESULT OF THIS AGREEMENT OR THE TRIAL SERVICES, INCLUDING BUT NOT LIMITED TO ANY DIRECT, INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL, OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF REVENUE, PROFITS, USE, OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF OR IN ANY WAY CONNECTED WITH THIS AGREEMENT OR USE OF THE SERVICES, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE TRIAL SERVICES, ANY INTERRUPTION, INACCURACY, ERROR, OR OMISSION, REGARDLESS OF CAUSE, EVEN IF GAI HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

### 10. Assignment
Customer may not assign this Agreement to any third party except upon GAI’s prior written consent, which consent not to be unreasonably withheld. Any purported assignment in violation of this Section shall be void. This Agreement and each and all the provisions hereof bind and benefit the parties and their respective heirs, executors, administrators, legal representatives, successors, and assigns.

### 11. Confidentiality; Proprietary Rights; Data and Information Security

#### 11.1 Existing MNDA (if applicable)
To the extent applicable, any confidential information shared under this Agreement is subject to the existing mutual non-disclosure agreement (“MNDA”) between the parties and shall prevail over Section 12.2 below. If there is no existing MNDA, each party agrees that Sections 11.2, 11.3, 11.4, 11.5, and 11.6 below shall be applicable.

#### 11.2 Proprietary Information
Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose non-public information that the Disclosing Party considers confidential or proprietary, including business, technical, or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party). Proprietary Information of Company includes non-public information regarding features, functionality, and performance of the Service. Proprietary Information of Customer includes but is not limited to non-public data provided by Customer to Company to enable the provision of the Services (“Customer Data”). The Receiving Party agrees to:
- (i) Safeguard the Proprietary Information from unauthorized use, access, or disclosure using at least the same degree of care it uses to protect its similarly sensitive information, and in no event less than a reasonable degree of care;
- (ii) Not access or use Proprietary Information other than as necessary in the performance of the Services or as otherwise permitted herein;
- (iii) Not divulge to any third person any such Proprietary Information other than to its and its affiliates' employees, officers, directors, legal advisors, contractors, and agents (collectively “Representatives”) who:
  - (a) Have a need to know such Proprietary Information for purposes of the Receiving Party exercising its rights or performing its obligations under this Agreement; and
  - (b) Have been informed of the confidential nature of the Proprietary Information and are bound by confidentiality obligations at least as protective of the Proprietary Information as the terms herein. The Receiving Party shall be responsible and liable for any of its Representatives' breach of this Section 12.2.

The Disclosing Party agrees that the foregoing shall not apply with respect to any information after five (5) years following the disclosure thereof or any information that the Receiving Party can document:
- (a) Is or becomes generally available to the public, or
- (b) Was in its possession or known by it prior to receipt from the Disclosing Party, or
- (c) Was rightfully disclosed to it without restriction by a third party, or
- (d) Was independently developed without the use of any Proprietary Information of the Disclosing Party, or
- (e) Is required to be disclosed by law. Notwithstanding the foregoing, the Receiving Party's obligations under this Section with respect to any Proprietary Information that constitutes a trade secret under any applicable law will continue until such time, if ever, that such Proprietary Information ceases to qualify for trade secret protection other than as a result of any act or omission of the Receiving Party.

#### 11.3 Legal Disclosure
If the Receiving Party is required by law, rule, or regulation, or judicial or administrative order to disclose any Proprietary Information of the other party, they shall give the Disclosing Party prompt written notice of such requirement so that an appropriate protective order or other relief may be sought by the Disclosing Party.

#### 11.4 Customer Data
The Trial Services may allow for Customer to enter a limited amount of Customer Data. To the extent Customer enters any Customer Data into the Trial Services, Customer, not GAI, shall have sole responsibility for the accuracy, quality, integrity, legality, and intellectual property ownership or right to use all Customer Data, and GAI shall not be responsible or liable for the deletion, correction, destruction, damage, or loss of such Customer Data. GAI’s use of Customer Data shall be limited to the purpose of providing the Trial Services to the Customer. To the extent Customer enters Customer Data into the Trial Services, Customer agrees and acknowledges that:
- (i) GAI is not obligated to retain any Customer Data after termination or expiration of the Trial Period, and
- (ii) GAI may delete Customer Data after the end of the Trial Period without further obligation or liability to the Customer.

#### 11.5 Customer's Ownership
Customer shall own all right, title, and interest in and to the Customer Data, as well as any data that is based on or derived from the Customer Data as part of the Services, as well as all intellectual property rights related to any of the foregoing. Company shall own and retain all right, title, and interest in and to:
- (a) The Services and Software, all improvements, enhancements, or modifications thereto;
- (b) Any software applications, inventions, or other technology developed in connection with implementation Services (“Implementation Services”) or support; and
- (c) All intellectual property rights related to any of the foregoing.

#### 11.6 Data Use
Notwithstanding anything to the contrary, Company shall have the right to collect and analyze data and other information relating to the provision, use, and performance of various aspects of the Services and related systems and technologies (including without limitation information concerning Customer Data and data derived therefrom), and Company will be free (during and after the Term hereof) to:
- (i) Use such information and data to improve and enhance the Services and for other development, diagnostic, and corrective purposes in connection with the Services and other Company offerings; and
- (ii) Disclose such data solely in aggregate or other de-identified form in connection with its business. No rights or licenses are granted except as expressly set forth herein.

### 12. General/Notices
This Agreement shall be governed by Delaware law and controlling United States federal law without regard to the choice or conflicts of law provisions of any jurisdiction, and any disputes, actions, claims, or causes of action arising out of or in connection with this Agreement or the Trial Services shall be subject to the exclusive jurisdiction of the state and federal courts located in the state of Delaware. If any provision of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed as nearly as possible to reflect the intentions of the invalid or unenforceable provision(s), with all other provisions remaining in full force and effect. No joint venture, partnership, employment, or agency relationship exists between Customer and GAI because of this Agreement or use of the Trial Services. The failure of either party to enforce any right or provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by such party in writing. This Agreement (including any other documents referenced therein) comprises the entire agreement between Customer and GAI regarding the subject matter contained herein and supersedes all prior or contemporaneous negotiations, discussions, or agreements, whether written or oral, between the parties regarding such subject matter. All notices from Customer to GAI may be made by emailing [contact@grantassistant.ai](mailto:contact@grantassistant.ai), and GAI may give notice by emailing the Customer contact(s) who registered for the Trial Services.

## Final Agreement Acceptance
Before you proceed please carefully read the following agreement which governs your use of our Software as a Service (SaaS) application. To continue you must confirm that you have read, understood, and agree to be bound by the terms and conditions outlined in this agreement. If you do not agree to these terms, please refrain from using our services.

