import { FC, forwardRef } from 'react';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { cn } from '@/lib/utils';
import { OTPInput } from '@/components/ui/shared/OTPInput/OTPInput';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';

const formSchema = z.object({
	code: z.string().min(6, {
		message: 'Please enter six digit code',
	}),
});

interface OTPFormProps {
	title?: string;
	value?: string;
	className?: string;
	disabled?: boolean;
	submitLabel?: string;
	onSubmit: (code: string) => void;
	cancelLabel?: string;
	onCancel?: () => void;
	handleResend?: () => void;
	disableResend?: boolean;
}

export const OTPForm: FC<OTPFormProps> = forwardRef<
	HTMLFormElement,
	OTPFormProps
>(
	(
		{
			title = 'Code',
			value = '',
			className = '',
			disabled = false,
			submitLabel = 'Submit',
			onSubmit,
			cancelLabel = 'Cancel',
			onCancel,
			handleResend,
			disableResend = false,
		}: OTPFormProps,
		ref
	) => {
		const form = useForm<z.infer<typeof formSchema>>({
			resolver: zodResolver(formSchema),
			defaultValues: {
				code: value || '',
			},
		});
		const formSubmit = (data: z.infer<typeof formSchema>) => {
			onSubmit(data.code);
		};

		return (
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(formSubmit)}
					className={cn('flex flex-col w-full space-y-4', className)}
					ref={ref}
				>
					<FormField
						control={form.control}
						name="code"
						render={({ field }) => (
							<FormItem className="flex flex-col w-full space-y-4">
								<div className="flex flex-col w-full h-auto gap-4">
									<Label className="dynamic-large text-left uppercase">
										{title}
									</Label>
									<FormControl className="w-full">
										<OTPInput disabled={disabled} {...field} />
									</FormControl>
									<FormMessage />
									{handleResend && (
										<div className="flex text-black">
											Didn’t get the code.{' '}
											<TextButton
												className="h-auto 2xl:h-auto py-0"
												type="button"
												onClick={handleResend}
												disabled={disableResend}
											>
												RESEND CODE
											</TextButton>
										</div>
									)}
								</div>
							</FormItem>
						)}
					/>

					<div className="flex justify-end">
						{onCancel && (
							<SecondaryButton
								className="flex w-auto"
								type="button"
								onClick={onCancel}
							>
								{cancelLabel}
							</SecondaryButton>
						)}
						<PrimaryButton className="flex w-auto" type="submit">
							{submitLabel}
						</PrimaryButton>
					</div>
				</form>
			</Form>
		);
	}
);
