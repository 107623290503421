import { useMutation, useQueryClient } from 'react-query';
import {
	createTemplate,
	createUpdateTag,
	updateSection,
	updateTemplate,
} from '../../functions/TemplateBuilder/templateCalls';
import {
	SectionBuilder,
	TagField,
	TemplateLibrary,
} from '../../types/TemplateBuilder/templateTypes';
import customToast from '@/components/CustomToast/CustomToast';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useNavigate } from 'react-router';
import {
	buildTemplate,
	toggleIsTemplateSetup,
	toggleProposalTemplateEdit,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import {
	closeDialog,
	closePanel,
	setIntermediatePage,
} from '@/redux/slices/pageSlice';
import {
	handleApiError,
	handleGeneralError,
	handleUnexpectedError,
} from '../../functions/funcUtils';
import { ErrorResponse } from '../../types/apiTypes';

import { AxiosError } from 'axios';
import { IntermediatePageProps } from '@/lib/types/constants';
import { RootState } from '@/redux/store/store';

interface TemplateMutationProps {
	reset?: () => void;
	intermediatePage?: IntermediatePageProps | null;
}

export const useTemplateMutations = ({
	reset,
	intermediatePage,
}: TemplateMutationProps = {}) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const sectionCreatedByUser = useAppSelector(
		(state: RootState) => state.page.dialog?.sectionCreatedByUser
	);

	const isTemplateSetup = useAppSelector(
		(state: RootState) => state.templates.isTemplateSetup
	);

	const createTemplateMutation = useMutation(
		(data: TemplateLibrary) => createTemplate(data),
		{
			onSuccess: (data) => {
				customToast.success({
					title: 'Template created successfully',
				});
				dispatch(buildTemplate(null));
				if (isTemplateSetup) {
					dispatch(closePanel());
					dispatch(toggleIsTemplateSetup(false));
					dispatch(toggleProposalTemplateEdit(false));
				} else {
					reset && reset();
					if (!intermediatePage) {
						navigate('/templates');
					} else {
						dispatch(
							setIntermediatePage({
								...intermediatePage,
								state: {
									...intermediatePage.state,
									selectedTemplate:
										(data as any)?.data ||
										intermediatePage?.state?.selectedTemplate,
								},
							})
						);
						intermediatePage.onCompletion &&
							intermediatePage.onCompletion(intermediatePage.state);
					}
				}
			},
			onError: (error: any) => {
				if (!isTemplateSetup) {
					dispatch(buildTemplate(null));
				}

				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries('privatetemplates');
			},
		}
	);

	const updateTemplateMutation = useMutation(
		(data: TemplateLibrary) => updateTemplate(data, data._id),
		{
			onSuccess: (data) => {
				customToast.success({
					title: 'Template updated successfully',
				});
				reset && reset();
				dispatch(buildTemplate(null));
				if (!intermediatePage) {
					navigate('/templates');
				} else {
					dispatch(
						setIntermediatePage({
							...intermediatePage,
							state: {
								...intermediatePage.state,
								selectedTemplate:
									(data as any)?.data ||
									intermediatePage?.state?.selectedTemplate,
							},
						})
					);
					intermediatePage.onCompletion &&
						intermediatePage.onCompletion(intermediatePage.state);
				}
			},
			onError: (error: any) => {
				dispatch(buildTemplate(null));
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries('privatetemplates');
			},
		}
	);

	const createUpdateTagMutation = useMutation(
		(data: TagField) => createUpdateTag(data),
		{
			onSuccess: () => {
				customToast.success({
					title: 'Tag updated successfully',
				});
			},
			onError: (error: any) => {
				if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries('template_tags');
			},
		}
	);

	const updateSectionMutation = useMutation(
		(updatedData: SectionBuilder) =>
			updateSection(updatedData, updatedData._id),
		{
			onSuccess: () => {
				dispatch(closeDialog());
				customToast.success({
					title: 'Section updated successfully',
				});
				reset && reset();
			},
			onError: (error: any) => {
				const axiosError = error as AxiosError;
				if (axiosError && axiosError.response) {
					const apiError = axiosError.response.data as ErrorResponse;
					handleApiError(apiError);
				} else if (error instanceof Error) {
					handleGeneralError(error);
				} else if (
					typeof error === 'object' &&
					error !== null &&
					'message' in error
				) {
					const apiError = error as ErrorResponse;
					handleApiError(apiError);
				} else {
					handleUnexpectedError();
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries('section_tags');
				// Invalidate and refetch the data query when the mutation is successful
				sectionCreatedByUser
					? queryClient.invalidateQueries('availableSections')
					: queryClient.invalidateQueries('sections');
			},
		}
	);

	return {
		createTemplateMutation,
		updateTemplateMutation,
		createUpdateTagMutation,
		updateSectionMutation,
	};
};
