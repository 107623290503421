import { FormControl, FormField, FormItem } from '@/components/ui/form';
import AiIcon from '@/components/ui/icons/AiIcon';
import { Textarea } from '@/components/ui/textarea';
import { getToken, typeWithInterval } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import useSuggestionHandler from '@/lib/hooks/useSuggestionHandler';
import { UnsolicitedQuestions } from '@/lib/types/constants';
import { cn } from '@/lib/utils';
import { addQuestions } from '@/redux/slices/unsolicitedSlice';
import { RootState } from '@/redux/store/store';
import { FC, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface FunderPrioritiesProps {
	form: UseFormReturn<any, any, undefined>;
	answerStreaming: boolean;
	setAnswerStreaming: React.Dispatch<React.SetStateAction<boolean>>;
}

const FunderPriorities: FC<FunderPrioritiesProps> = ({
	form,
	answerStreaming,
	setAnswerStreaming,
}) => {
	const token = getToken();

	const question = useSelector((root: RootState) => root.unsolicited.questions);

	const [answer, setAnswer] = useState<string>(
		(question && question['question1']) || ''
	);
	const unsolicitation = useSelector(
		(root: RootState) => root.unsolicited.unsolicitation
	);

	const answerRef = useRef<HTMLTextAreaElement>(null);
	const [intervalId, setIntervalId] = useState<ReturnType<
		typeof setInterval
	> | null>(null);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			addQuestions({
				question1: answer,
			})
		);
		form.setValue('question1', answer);
		form.trigger('question1');
	}, [answer, dispatch]);

	const [handleSuggestResponse] = useSuggestionHandler({
		token,
		documentId: unsolicitation?.id,
		questionId: 1,
		onInit: () => {
			setAnswerStreaming(true);
			setAnswer('');

			const interval = typeWithInterval(
				answerRef,
				'Hold on tight while I write this response',
				10
			);
			setIntervalId(interval);
		},
		onStart: () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
			setAnswer('');
		},
		onMessage: (message: string) => {
			setAnswer((prev) => {
				const updatedAnswer = prev + message;
				form.setValue('question1', updatedAnswer); // Update form state with streamed data
				return updatedAnswer;
			});

			if (answerRef.current) {
				answerRef.current.scrollTop = answerRef.current.scrollHeight;
			}

			form.trigger('question1');

			// trigger('question1');
		},
		onEnd: () => {
			setAnswerStreaming(false);
			setAnswer((prevAnswer) => {
				form.setValue('question1', prevAnswer || ''); // Ensure final answer is saved in form
				return prevAnswer || '';
			});
			form.trigger('question1');
			// trigger('question1');
		},
		onError: () => {
			setAnswerStreaming(false);
		},
	});

	return (
		<div className="my-4 2xl:my-6">
			<div className="relative">
				<div className="my-3 pb-2 hover:bg-[#F0F0F0] border border-[rgba(93, 155, 253, 0.30)] rounded-xl">
					<FormField
						control={form.control}
						name="question1"
						rules={{ required: 'This field is required' }}
						render={({ field }) => {
							return (
								<FormItem className="flex flex-col w-full overflow-hidden">
									<label className="mt-3 ml-3 font-bold text-black ">
										{UnsolicitedQuestions.one}{' '}
										<span className="font-semibold text-secondary">
											(required)
										</span>
									</label>

									<FormControl>
										<Textarea
											{...field}
											className={cn(
												'text-black bg-transparent border-0 animated-container-transition resize-none placeholder:text-[rgba(135,133,133,0.7)] placeholder:text-base px-4 pt-4 text-sm  scroll-smooth h-auto min-h-72'
											)}
											ref={answerRef}
											value={answer}
											onChange={(e) => {
												setAnswer(e.target.value);
												form.setValue('question1', e.target.value);
												form.trigger('question1');
											}}
											placeholder="Please describe your organization in some detail. Keep in mind that the AI model will look at this description, your area of work and your organization name when creating content for you."
										></Textarea>
									</FormControl>
								</FormItem>
							);
						}}
					/>
					<div className="flex justify-end w-full px-5 py-2">
						<button
							className={cn(
								'flex dynamic-small text-primary gap-1 leading-normal items-center font-bold suggest-response',
								answerStreaming
									? 'cursor-ga opacity-50'
									: 'cursor-pointer animated-hover-effect'
							)}
							disabled={answerStreaming}
							onClick={handleSuggestResponse}
						>
							<AiIcon className="h-5" />
							SUGGEST A RESPONSE TO THIS
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FunderPriorities;
