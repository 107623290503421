import { FC } from 'react';
import ProposalInfoCard from '../ProposalInfoCard/ProposalInfoCard';
import ProposalApproachCard from '../ProposalApproachCard/ProposalApproachCard';
import ProposalSummary from '../ProposalSummary/ProposalSummary';
import { RFP, Template } from '@/lib/types/apiTypes';
import { motion } from 'framer-motion';
import { prFooterSlideInDelay } from '@/constants/variants';

interface ProposalCreationFooterProps {
	type: 'setup' | 'template' | 'approach' | 'name';
	rfp?: RFP;
	template?: Template;
	agency?: string;
	pageTransitionLoad?: boolean;
}

const ProposalCreationFooter: FC<ProposalCreationFooterProps> = ({
	type,
	rfp,
	pageTransitionLoad = true,
}) => {
	switch (type) {
		case 'setup':
			return (
				<div className="relative grid w-full max-w-[1200px] 2xl:max-w-[1400px] grid-cols-11 mt-10 px-4 gap-4 mx-auto overflow-hidden">
					<motion.div
						className="col-span-6"
						key="prFooterDelay0"
						variants={prFooterSlideInDelay}
						animate="animate"
						exit="exit"
						initial="initial"
					>
						<ProposalSummary
							summary={rfp?.metaData.summary as string}
							source={rfp?.sourceLink as string}
						/>
					</motion.div>
					<motion.div
						className="col-span-5"
						key="prFooterDelay1"
						variants={prFooterSlideInDelay}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						<ProposalInfoCard data={rfp as RFP} />
					</motion.div>
				</div>
			);
		// case 'template':
		// 	return (
		// 		<div className="relative grid w-full max-w-[1400px] grid-cols-12 mt-6 gap-4 mx-auto overflow-hidden">
		// 			<motion.div
		// 				className="col-span-12"
		// 				key="prFooterDelay1"
		// 				variants={prFooterSlideInDelay}
		// 				initial="initial"
		// 				animate="animate"
		// 				exit="exit"
		// 			>
		// 				<TemplateFooter
		// 					selectedTemplate={template}
		// 					pageTransitionLoad={pageTransitionLoad}
		// 				/>
		// 			</motion.div>
		// 		</div>
		// 	);
		case 'approach':
			return (
				<div className="relative w-full max-w-screen-xl mx-auto overflow-hidden ">
					<motion.div
						key="prFooterDelay3"
						variants={prFooterSlideInDelay}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						<ProposalApproachCard pageTransitionLoad={pageTransitionLoad} />
					</motion.div>
				</div>
			);
		// case 'name':
		// 	return <></>;
		default:
			return null;
	}
};

export default ProposalCreationFooter;
