import LottieLoader from '@/assets/lotties/loading_v1.json';
import Lottie from 'lottie-react';

interface DialogLoader {
	isLoading: boolean;
}

const DialogLoader = ({ isLoading }) => {
	return (
		<>
			{isLoading && (
				<div className="flex h-full w-full fixed justify-center items-center top-0 left-0 bg-[#ffffffD9] z-50 rounded-2xl">
					<Lottie
						className="w-64"
						animationData={LottieLoader}
						loop={true}
						autoPlay={true}
					/>
				</div>
			)}
		</>
	);
};

export default DialogLoader;
