import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	useReactTable,
	SortingState,
	getSortedRowModel,
} from '@tanstack/react-table';

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useEffect, useState } from 'react';

interface SolicitationTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	isRemovedDisabled?: boolean;
}

export function SolicitationTable<TData, TValue>({
	columns,
	data,
	isRemovedDisabled,
}: SolicitationTableProps<TData, TValue>) {
	const [sorting, setSorting] = useState<SortingState>([]);
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		state: {
			sorting,
		},
	});

	useEffect(() => {
		if (isRemovedDisabled) {
			table.resetRowSelection();
		}
	}, [isRemovedDisabled]);

	return (
		<div className="relative max-h-[300px] overflow-auto">
			<Table
				parentClassName="max-h-[300px] hidden-scroll"
				className="rounded-tl-xl"
			>
				<TableHeader className="sticky top-0 z-10 rounded-tl-xl rounded-tr-xl bg-blackSecondary">
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead
										key={header.id}
										className="px-8 py-3 text-lg text-black"
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody
					className="overflow-y-auto text-base text-black bg-white"
					style={{ maxHeight: '500px' }}
				>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow
								key={row.id}
								className="hover:bg-lightBackground"
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id} className="px-8 py-3">
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								Please start a new proposal by clicking the buttons above.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</div>
	);
}
