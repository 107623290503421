import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';

import {
	Dropdown,
	SectionBuilder,
	TemplateSetupInterface,
	TreeInterface,
} from '@/lib/types/TemplateBuilder/templateTypes';

import {
	changeNodeDesign,
	extractNames,
	getNodeKey,
	hasDuplicateInSectionArray,
	parseSectionsToLabelValue,
	getSelectedPrerequisites,
	solicitationAssociativeArrayConversion,
} from '@/lib/functions/TemplateBuilder/templateUtils';
import {
	addDeletedSection,
	buildTemplate,
	setEditTemplateId,
	setLoading,
	setTemplateMode,
	toggleProposalTemplateEdit,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import customToast from '@/components/CustomToast/CustomToast';
import { RootState } from '@/redux/store/store';
import {
	changeNodeAtPath,
	getNodeAtPath,
	removeNodeAtPath,
} from '@nosferatu500/react-sortable-tree';
import LoaderPanel from '@/components/Loader/LoaderPanel/LoaderPanel';
import TemplateSectionFilters from '../TemplateDesigner/TemplateSectionFilters/TemplateSectionFilters';
import TemplateDesignerHeader from '../TemplateDesigner/TemplateDesignerHeader/TemplateDesignerHeader';
import TemplateGlobalSections from '../TemplateDesigner/TemplateGlobalSections/TemplateGlobalSections';
import { useTemplateQueries } from '@/lib/hooks/templateBuilder/useTemplateQueries';
import TemplateDesignerDnd from '../TemplateDesigner/TemplateDesignerDnd/TemplateDesignerDnd';
import TemplateSectionViewer from '../TemplateDesigner/TemplateSectionViewer/TemplateSectionViewer';
import useTemplateId from '@/lib/hooks/useTemplateId';
import useProposalCategory from '@/lib/hooks/useProposalCategory';
import { Tag } from '@/components/ui/shared/TagInput/tag-input';

const TemplateDragAndDrop: FC<TemplateSetupInterface> = ({
	setValue,
	form,
	onSubmit,
}) => {
	const templateBuilderData = useAppSelector(
		(root: RootState) => root.templates.templateBuilder
	);

	const templateId = useTemplateId();
	const proposalCategory = useProposalCategory();

	const wordCountRef = useRef<HTMLInputElement | null>(null);

	const [tags, setTags] = useState<Dropdown[]>([]);

	const [expectedOutput, setExpectedOutput] = useState<string | null>(null);

	const [tableHeadings, setTableHeadings] = useState<Tag[]>([]);

	const [prerequisitesOptions, setPrerequisitesOptions] = useState<Dropdown[]>(
		[]
	);

	const [solicitationSections, setSolicitationSections] = useState<Dropdown[]>(
		[]
	);

	const [treeData, setTreeData] = useState<any[]>([]);

	const [nodePath, setNodePath] = useState<number[]>([]);

	const [selectedSection, setSelectedSection] =
		useState<SectionBuilder | null>();

	const [showSection, toggleSection] = useState(false);

	const [sectionSearch, setSectionSearch] = useState<string | undefined>();

	const dispatch = useAppDispatch();

	const isNodeDragging = useAppSelector(
		(root: RootState) => root.templates.isNodeDragging
	);

	const [availableSectionsTree, setAvailableSectionsTree] = useState<
		TreeInterface[]
	>([]);

	const [sectionTagFilter, setSectionTagFilter] = useState<
		string | undefined
	>();

	const { templateByIdQuery, sectionQuery } = useTemplateQueries({
		setValue,
		setTreeData,
		sectionSearch,
		sectionTagFilter,
		setAvailableSectionsTree,
	});

	useEffect(() => {
		if (templateId) {
			dispatch(setTemplateMode('edit'));
			dispatch(setEditTemplateId(String(templateId)));
		}
	}, [templateId, dispatch]);

	const selectedNodeData = (node: SectionBuilder) => {
		setSelectedSection(undefined);
		if (wordCountRef.current) {
			wordCountRef.current.value = node.wordCount.toString();
		}

		toggleSection(true);

		if (node.requiredRfpSections && node.requiredRfpSections.length > 0) {
			const sol = solicitationAssociativeArrayConversion(
				node.requiredRfpSections
			);
			setSolicitationSections(sol);
		} else {
			setSolicitationSections([]);
		}
		setSelectedSection(node);
	};

	useEffect(() => {
		if (templateBuilderData && templateBuilderData.sections) {
			setTreeData(templateBuilderData.sections);
			setPrerequisiteDefaultOptions();
		}
	}, [templateBuilderData, selectedSection]);

	const setPrerequisiteDefaultOptions = () => {
		const prerequisiteMap = parseSectionsToLabelValue(
			templateBuilderData?.sections
		);
		setPrerequisitesOptions(prerequisiteMap.length > 0 ? prerequisiteMap : []);
	};

	useEffect(() => {
		if (selectedSection) {
			const setPrereq = getSelectedPrerequisites(
				prerequisitesOptions,
				selectedSection.prerequisites
			);
			if (wordCountRef.current) {
				wordCountRef.current.value = selectedSection.wordCount.toString();
			}

			setTags(setPrereq);
			setExpectedOutput(selectedSection.expectedOutput);
			setTableHeadings(
				selectedSection.tableHeadings
					?.split(',')
					.map((heading, index) => ({ id: String(index), text: heading })) || []
			);
		}
	}, [selectedSection]);

	const buildTemplateData = (updatedTemplateBuilder: any) => {
		dispatch(buildTemplate(updatedTemplateBuilder));
	};

	const updateSectionName = (updatedName: string) => {
		const nodeExists = getNodeAtPath({
			treeData,
			path: nodePath,
			getNodeKey,
		});

		if (!nodeExists) {
			return;
		}

		if (templateBuilderData && nodeExists) {
			const updatedData = changeNodeAtPath({
				treeData,
				path: nodePath,
				getNodeKey,
				newNode: (oldNode) => ({
					...oldNode.node,
					alias: updatedName,
					isPristine: !oldNode.node.isNew ? false : oldNode.node.isPristine,
				}),
			});

			setTreeData(updatedData);

			const updatedTemplateBuilder = {
				...templateBuilderData,
				sections: updatedData,
			};
			if (selectedSection) {
				setSelectedSection((prev) => {
					if (prev) {
						return {
							...prev,
							alias: updatedName,
							isPristine: !prev.isNew ? false : prev.isPristine,
						};
					}
					return prev; // Handle null or undefined case safely
				});
			}
			dispatch(toggleProposalTemplateEdit(true));
			buildTemplateData(updatedTemplateBuilder);
		}
	};

	const updateTemplatePrerequisites = (newTags: Dropdown[]) => {
		const nodeExists = getNodeAtPath({
			treeData,
			path: nodePath,
			getNodeKey,
		});

		if (!nodeExists) {
			setTags([]);
			return;
		}

		if (templateBuilderData && nodeExists) {
			const updatedPrerequisites =
				newTags.length > 0 ? extractNames(newTags) : [];
			const updatedData = changeNodeAtPath({
				treeData,
				path: nodePath,
				getNodeKey,
				newNode: (oldNode) => ({
					...oldNode.node,
					prerequisites: updatedPrerequisites,
					isPristine: !oldNode.node.isNew ? false : oldNode.node.isPristine,
				}),
			});

			setTreeData(updatedData);

			const updatedTemplateBuilder = {
				...templateBuilderData,
				sections: updatedData,
			};

			buildTemplateData(updatedTemplateBuilder);
			setTags(newTags);
			dispatch(toggleProposalTemplateEdit(true));
		}
	};

	const updateExpectedOutput = (expectedOutput) => {
		const nodeExists = getNodeAtPath({
			treeData,
			path: nodePath,
			getNodeKey,
		});

		if (!nodeExists) {
			setExpectedOutput('text');
			return;
		}

		if (templateBuilderData && nodeExists) {
			const updatedExpectedOutput = expectedOutput;
			const updatedData = changeNodeAtPath({
				treeData,
				path: nodePath,
				getNodeKey,
				newNode: (oldNode) => ({
					...oldNode.node,
					expectedOutput: updatedExpectedOutput,
					isPristine: !oldNode.node.isNew ? false : oldNode.node.isPristine,
				}),
			});

			setTreeData(updatedData);

			const updatedTemplateBuilder = {
				...templateBuilderData,
				sections: updatedData,
			};

			buildTemplateData(updatedTemplateBuilder);
			setExpectedOutput(expectedOutput);
			dispatch(toggleProposalTemplateEdit(true));
		}
	};

	const updateTableHeadings = (tableHeadings) => {
		const nodeExists = getNodeAtPath({
			treeData,
			path: nodePath,
			getNodeKey,
		});

		if (!nodeExists) {
			setTableHeadings([]);
			return;
		}

		if (templateBuilderData && nodeExists) {
			const updatedTableHeadings =
				tableHeadings.length > 0
					? tableHeadings.map((heading) => heading.text).join(',')
					: [];
			const updatedData = changeNodeAtPath({
				treeData,
				path: nodePath,
				getNodeKey,
				newNode: (oldNode) => ({
					...oldNode.node,
					tableHeadings: updatedTableHeadings,
					isPristine: !oldNode.node.isNew ? false : oldNode.node.isPristine,
				}),
			});

			setTreeData(updatedData);

			const updatedTemplateBuilder = {
				...templateBuilderData,
				sections: updatedData,
			};

			buildTemplateData(updatedTemplateBuilder);
			setTableHeadings(tableHeadings);
			dispatch(toggleProposalTemplateEdit(true));
		}
	};
	/**
	 * solicitation sections on change method
	 */
	const updateTemplateSolicitationSections = (
		newSolicitationSection: [Dropdown, ...Dropdown[]]
	) => {
		const nodeExists = getNodeAtPath({
			treeData,
			path: nodePath,
			getNodeKey,
		});

		if (!nodeExists) {
			setSolicitationSections([]);
			return;
		}

		if (templateBuilderData && nodeExists) {
			const updateSections =
				newSolicitationSection.length > 0
					? extractNames(newSolicitationSection)
					: [];
			const updatedData = changeNodeAtPath({
				treeData,
				path: nodePath,
				getNodeKey,
				newNode: (oldNode) => ({
					...oldNode.node,
					requiredRfpSections: updateSections,
					isPristine: !oldNode.node.isNew ? false : oldNode.node.isPristine,
				}),
			});

			setTreeData(updatedData);

			const updatedTemplateBuilder = {
				...templateBuilderData,
				sections: updatedData,
			};

			buildTemplateData(updatedTemplateBuilder);
			dispatch(toggleProposalTemplateEdit(true));
		}
	};

	const updateSections = (wordCount: any) => {
		const nodeExists = getNodeAtPath({
			treeData,
			path: nodePath,
			getNodeKey,
		});
		if (!nodeExists) {
			return;
		}

		if (templateBuilderData && nodeExists) {
			const updatedData = changeNodeAtPath({
				treeData:
					treeData &&
					treeData.length > 0 &&
					Object.keys(treeData[0]).length !== 0
						? treeData
						: templateBuilderData?.sections,
				path: nodePath,
				getNodeKey,
				newNode: (oldNode) => ({
					...oldNode.node,
					...(wordCount && {
						wordCount: Number(wordCount) < 100 ? 100 : Number(wordCount),
						isPristine: !oldNode.node.isNew ? false : oldNode.node.isPristine,
					}),
				}),
			});
			setTreeData(updatedData);

			const updatedTemplateBuilder = {
				...templateBuilderData,
				sections: updatedData,
			};

			buildTemplateData(updatedTemplateBuilder);
			dispatch(toggleProposalTemplateEdit(true));
		}
	};

	const dragState = ({ node }) => {
		if (templateBuilderData && node && templateBuilderData.sections) {
			const isDuplicate = hasDuplicateInSectionArray(
				templateBuilderData.sections,
				node.systemName
			);
			if (isDuplicate) {
				return false;
			} else {
				return true;
			}
		}
		return true;
	};

	const removeNodeFromTree = (path: number[]) => {
		changeNodeDesign(selectedSection, true);
		toggleSection(false);
		setSelectedSection(undefined);

		const nodeToRemove = getNodeAtPath({
			treeData,
			path,
			getNodeKey,
		});

		if (!nodeToRemove) {
			customToast.error({
				title: 'No node found at the given path',
			});
			return;
		}

		if (nodeToRemove && nodeToRemove.node.systemName) {
			// Add systemName to deletedSections array in Redux
			dispatch(addDeletedSection(nodeToRemove.node.systemName));
		}

		const updatedTree = removeNodeAtPath({
			treeData,
			path,
			getNodeKey,
		});

		setTreeData(updatedTree);

		const updatedTemplateBuilder = {
			...templateBuilderData,
			sections: updatedTree,
		};
		buildTemplateData(updatedTemplateBuilder);
		dispatch(toggleProposalTemplateEdit(true));
	};

	useEffect(() => {
		if (templateByIdQuery.isLoading || templateByIdQuery.isFetching) {
			dispatch(setLoading(true));
		}
	}, [sectionQuery, templateByIdQuery, dispatch]);

	const handleSearchChange = (value: string) => {
		setSectionSearch(value);
	};

	return (
		<>
			<TemplateDesignerHeader
				treeData={treeData}
				changeNodeDesign={changeNodeDesign}
				setSelectedSection={setSelectedSection}
				toggleSection={toggleSection}
				form={form}
				onSubmit={onSubmit}
				selectedSection={selectedSection}
			/>
			{/* available sections */}
			<div className="grid grid-cols-12 gap-4">
				{proposalCategory !== 'questionnaire' && (
					<div className="relative flex flex-col col-span-3 gap-3 px-4 pt-4 bg-white rounded-2xl shadow-main">
						<TemplateSectionFilters
							handleSearchChange={handleSearchChange}
							sectionSearch={sectionSearch}
							setSectionTagFilter={setSectionTagFilter}
						/>
						{!sectionQuery.isFetchedAfterMount && sectionQuery.isFetching ? (
							<LoaderPanel />
						) : (
							<>
								{(sectionQuery.isFetching || sectionQuery.isLoading) && (
									<LoaderPanel />
								)}
								<div className="h-full py-4">
									<TemplateGlobalSections
										availableSectionsTree={availableSectionsTree}
										dragState={dragState}
										sectionQuery={sectionQuery}
									/>
								</div>
							</>
						)}
					</div>
				)}

				<TemplateDesignerDnd
					isNodeDragging={isNodeDragging}
					removeNodeFromTree={removeNodeFromTree}
					selectedNodeData={selectedNodeData}
					selectedSection={selectedSection}
					setNodePath={setNodePath}
					setSelectedSection={setSelectedSection}
					setTreeData={setTreeData}
					toggleSection={toggleSection}
					treeData={treeData}
				/>

				<TemplateSectionViewer
					selectedSection={selectedSection}
					setSolicitationSections={setSolicitationSections}
					showSection={showSection}
					solicitationSections={solicitationSections}
					tagOptions={prerequisitesOptions}
					tags={tags}
					updateSections={updateSections}
					updateTemplatePrerequisites={updateTemplatePrerequisites}
					updateTemplateSolicitationSections={
						updateTemplateSolicitationSections
					}
					wordCountRef={wordCountRef}
					updateSectionName={updateSectionName}
					expectedOutput={expectedOutput || 'null'}
					updateExpectedOutput={updateExpectedOutput}
					tableHeadings={tableHeadings}
					updateTableHeadings={updateTableHeadings}
				/>
			</div>
		</>
	);
};
export default TemplateDragAndDrop;
