import { SVGProps } from 'react';

const SolicitationDashboardIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="52"
			height="52"
			viewBox="0 0 52 52"
			fill="none"
		>
			<g clipPath="url(#clip0_14466_18063)">
				<path
					d="M40 0H12C5.37258 0 0 5.37258 0 12V40C0 46.6274 5.37258 52 12 52H40C46.6274 52 52 46.6274 52 40V12C52 5.37258 46.6274 0 40 0Z"
					fill="#5D9BFD"
				/>
				<path
					d="M31.1869 38.1159H18.7169C17.6069 38.1159 16.5469 37.6759 15.7669 36.8859C14.9869 36.0959 14.5469 35.0359 14.5469 33.9259V16.5259C14.5469 15.4159 14.9869 14.3459 15.7669 13.5659C16.5469 12.7759 17.6069 12.3359 18.7169 12.3359H31.1869C32.2969 12.3359 33.3569 12.7759 34.1369 13.5659C34.9169 14.3559 35.3569 15.4159 35.3569 16.5259V33.9359C35.3569 35.0459 34.9169 36.1159 34.1369 36.8959C33.3569 37.6859 32.2969 38.1259 31.1869 38.1259M18.7169 14.1759C18.0969 14.1759 17.5069 14.4259 17.0669 14.8659C16.6269 15.3059 16.3769 15.9059 16.3769 16.5259V33.9359C16.3769 34.5559 16.6269 35.1559 17.0669 35.5959C17.5069 36.0359 18.0969 36.2859 18.7169 36.2859H31.1869C31.8069 36.2859 32.3969 36.0359 32.8369 35.5959C33.2769 35.1559 33.5269 34.5559 33.5269 33.9359V16.5259C33.5269 15.9059 33.2769 15.3059 32.8369 14.8659C32.3969 14.4259 31.8069 14.1759 31.1869 14.1759H18.7169Z"
					fill="white"
				/>
				<path
					d="M30.0353 18.8244H19.8653C19.7453 18.8244 19.6253 18.8044 19.5153 18.7544C19.4053 18.7044 19.3053 18.6444 19.2153 18.5544C19.1253 18.4644 19.0653 18.3644 19.0153 18.2544C18.9653 18.1444 18.9453 18.0244 18.9453 17.9044C18.9453 17.7844 18.9653 17.6644 19.0153 17.5544C19.0653 17.4444 19.1253 17.3444 19.2153 17.2544C19.3053 17.1644 19.4053 17.1044 19.5153 17.0544C19.6253 17.0044 19.7453 16.9844 19.8653 16.9844H30.0353C30.1553 16.9844 30.2753 17.0044 30.3853 17.0544C30.4953 17.1044 30.5953 17.1644 30.6853 17.2544C30.7753 17.3444 30.8353 17.4444 30.8853 17.5544C30.9353 17.6644 30.9553 17.7844 30.9553 17.9044C30.9553 18.0244 30.9353 18.1444 30.8853 18.2544C30.8353 18.3644 30.7753 18.4644 30.6853 18.5544C30.5953 18.6444 30.4953 18.7044 30.3853 18.7544C30.2753 18.8044 30.1553 18.8244 30.0353 18.8244Z"
					fill="white"
				/>
				<path
					d="M30.0353 23.0666H19.8653C19.7453 23.0666 19.6253 23.0466 19.5153 22.9966C19.4053 22.9466 19.3053 22.8866 19.2153 22.7966C19.1253 22.7066 19.0653 22.6066 19.0153 22.4966C18.9653 22.3866 18.9453 22.2666 18.9453 22.1466C18.9453 22.0266 18.9653 21.9066 19.0153 21.7966C19.0653 21.6866 19.1253 21.5866 19.2153 21.4966C19.3053 21.4066 19.4053 21.3466 19.5153 21.2966C19.6253 21.2466 19.7453 21.2266 19.8653 21.2266H30.0353C30.1553 21.2266 30.2753 21.2466 30.3853 21.2966C30.4953 21.3466 30.5953 21.4066 30.6853 21.4966C30.7753 21.5866 30.8353 21.6866 30.8853 21.7966C30.9353 21.9066 30.9553 22.0266 30.9553 22.1466C30.9553 22.2666 30.9353 22.3866 30.8853 22.4966C30.8353 22.6066 30.7753 22.7066 30.6853 22.7966C30.5953 22.8866 30.4953 22.9466 30.3853 22.9966C30.2753 23.0466 30.1553 23.0666 30.0353 23.0666Z"
					fill="white"
				/>
				<path
					d="M30.0353 27.8869H19.8653C19.7453 27.8869 19.6253 27.8669 19.5153 27.8169C19.4053 27.7669 19.3053 27.7069 19.2153 27.6169C19.1253 27.5269 19.0653 27.4269 19.0153 27.3169C18.9653 27.2069 18.9453 27.0869 18.9453 26.9669C18.9453 26.8469 18.9653 26.7269 19.0153 26.6169C19.0653 26.5069 19.1253 26.4069 19.2153 26.3169C19.3053 26.2269 19.4053 26.1669 19.5153 26.1169C19.6253 26.0669 19.7453 26.0469 19.8653 26.0469H30.0353C30.1553 26.0469 30.2753 26.0669 30.3853 26.1169C30.4953 26.1669 30.5953 26.2269 30.6853 26.3169C30.7753 26.4069 30.8353 26.5069 30.8853 26.6169C30.9353 26.7269 30.9553 26.8469 30.9553 26.9669C30.9553 27.0869 30.9353 27.2069 30.8853 27.3169C30.8353 27.4269 30.7753 27.5269 30.6853 27.6169C30.5953 27.7069 30.4953 27.7669 30.3853 27.8169C30.2753 27.8669 30.1553 27.8869 30.0353 27.8869Z"
					fill="white"
				/>
				<path
					d="M34.2275 43.6347C28.5575 43.6347 23.9375 39.0247 23.9375 33.3447C23.9375 27.6647 28.5475 23.0547 34.2275 23.0547C39.9075 23.0547 44.5175 27.6647 44.5175 33.3447C44.5175 39.0247 39.9075 43.6347 34.2275 43.6347Z"
					fill="white"
				/>
				<path
					d="M34.2253 41.8881C38.9363 41.8881 42.7553 38.0691 42.7553 33.3581C42.7553 28.6471 38.9363 24.8281 34.2253 24.8281C29.5143 24.8281 25.6953 28.6471 25.6953 33.3581C25.6953 38.0691 29.5143 41.8881 34.2253 41.8881Z"
					fill="#5D9BFD"
				/>
				<path
					d="M34.0697 30.6728V30.1628C34.0697 29.7928 33.8597 29.4628 33.5197 29.3028C33.1697 29.1328 32.7697 29.1828 32.4597 29.4328L29.5397 31.8728C29.3097 32.0528 29.1797 32.3328 29.1797 32.6228C29.1797 32.9128 29.3097 33.1828 29.5297 33.3628L32.4697 35.8128C32.7697 36.0528 33.1697 36.1028 33.5197 35.9328C33.8597 35.7728 34.0697 35.4428 34.0697 35.0728V34.6028C36.0697 34.7828 37.0097 36.0328 37.6097 37.1328C37.7397 37.3728 37.9697 37.5128 38.2297 37.5128C38.2897 37.5128 38.3497 37.5128 38.4097 37.4928C38.7297 37.4128 38.9597 37.1228 38.9597 36.7928V35.9528C38.9597 33.2028 36.8097 30.9328 34.0697 30.6928V30.6728ZM33.5897 33.5928C33.3197 33.5928 33.0997 33.8128 33.0997 34.0828V35.0528L30.1697 32.6328L33.0997 30.1828V31.1828C33.1097 31.4428 33.3297 31.6428 33.5897 31.6428C35.9897 31.6428 37.9397 33.5328 37.9897 35.8628C37.3097 34.8928 35.9997 33.6028 33.5897 33.6028V33.5928Z"
					fill="white"
				/>
				<path
					d="M13.1066 32.8834C12.7366 32.3734 12.5166 31.7534 12.5166 31.0834V13.9034C12.5166 12.1934 13.9066 10.8134 15.6066 10.8134H26.8466C27.4266 10.8134 27.9566 10.9834 28.4266 11.2634H30.9466C30.0766 9.91344 28.5666 9.02344 26.8466 9.02344H15.6066C12.9166 9.02344 10.7266 11.2134 10.7266 13.9134V31.0934C10.7266 32.8734 11.6866 34.4234 13.1066 35.2834V32.8934V32.8834Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14466_18063">
					<rect width="52" height="52" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SolicitationDashboardIcon;
