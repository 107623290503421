import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { updateProposal } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { RootState } from '@/redux/store/store';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { closeDialog } from '@/redux/slices/pageSlice';
import { Input } from '@/components/ui/input';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { cn } from '@/lib/utils';

const ChangeProposalNameDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'changeProposalName'
	);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const [proposalName, setProposalName] = useState(proposal?.name || '');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleProposalUpdate = async (proposalName) => {
		try {
			setIsLoading(true);
			const response = await updateProposal(
				proposal?.id as number,
				proposalName
			);
			handleResponse(response, 'Proposal name has been updated successfully.');
			dispatch(fetchProposalById(Number(proposal?.id)));
			dispatch(closeDialog());
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			setIsLoading(false);
			setProposalName(proposal?.name || '');
		}
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] p-7 bg-white rounded-xl">
				<DialogHeader className="font-bold text-secondary dynamic-medium">
					Change Proposal Name
				</DialogHeader>
				<div className="flex flex-col gap-2">
					<div className="rounded-xl shadow-main">
						<Input
							tabIndex={-1}
							placeholder={'What would you like to name this proposal?'}
							onChange={(e) => setProposalName(e.target.value)}
							value={proposalName}
							className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-lightBackground disabled:bg-[#F5F5F5]"
						/>
					</div>
					<p
						className={cn(
							'flex w-full dynamic-badge justify-end self-center font-medium leading-normal',
							proposalName?.length > 250
								? 'text-[#FF82A0]'
								: 'text-black opacity-50'
						)}
					>
						{proposalName?.length || 0}/250 Char
					</p>
				</div>
				<div className="flex items-center justify-end">
					<PrimaryButton
						disabled={
							!proposalName ||
							(proposal?.name || '') === proposalName ||
							proposalName?.length > 250
						}
						onClick={() => handleProposalUpdate(proposalName)}
					>
						{isLoading ? 'Updating...' : 'Update'}
					</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeProposalNameDialog;
