import ProjectBody from '@/components/Project/ProjectBody/ProjectBody';
import ProjectHeader from '@/components/Project/ProjectHeader/ProjectHeader';
import { cn } from '@/lib/utils';
import { FC, useEffect, useMemo } from 'react';
import styles from './ProjectPage.module.css';
import { motion, useAnimation } from 'framer-motion';
import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';

interface ProjectPageProps {
	exiting?: boolean;
}

const ProjectPage: FC<ProjectPageProps> = ({ exiting }) => {
	const exitControl = useAnimation();

	useTour(Page.Solicitation);

	const hideAnimation = useMemo(() => {
		return { opacity: 0, transition: { duration: 0.25 } };
	}, []);

	useEffect(() => {
		if (exiting === true) {
			exitControl.start(hideAnimation);
		}
	}, [exiting, exitControl]);

	return (
		<div
			className={cn(
				styles.wrapper,
				'rounded-tl-[30px] overflow-y-scroll light-scroll bg-background'
			)}
		>
			<ProjectHeader type="dashboard" />
			<motion.div className="px-6 pt-0 pb-4" animate={exitControl}>
				<ProjectBody />
			</motion.div>
		</div>
	);
};

export default ProjectPage;
