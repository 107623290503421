import { MoreVertical } from 'lucide-react';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import Info from '@/components/ui/icons/new/SectionNode/Info';
import WordCount from '@/components/ui/icons/new/SectionNode/WordCount';
import Rename from '@/components/ui/icons/new/SectionNode/Rename';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { NodeData } from '@/lib/types/graphTypes';

interface SectionEditPopoverProps {
	isSelected: boolean;
	section: NodeData;
}
const SectionEditPopover: React.FC<SectionEditPopoverProps> = ({
	isSelected,
	section,
}) => {
	const dispatch = useAppDispatch();
	return (
		<Popover>
			<PopoverTrigger
				asChild
				onClick={(e) => e.stopPropagation()}
				className={isSelected ? 'text-white' : 'text-primary '}
			>
				<MoreVertical className="animated-hover-effect" />
			</PopoverTrigger>
			<PopoverContent
				side="right"
				align="start"
				className="p-3 bg-white border-none shadow-lg w-68 rounded-xl"
			>
				<ul>
					<li
						onClick={() =>
							dispatch(
								openDialog({ type: 'viewSectionInfo', section: section })
							)
						}
						className="flex items-center gap-3 p-3 hover:bg-[#E3EEFF] rounded-lg"
					>
						<Info />
						<p className="font-bold uppercase text-primary">Info</p>
					</li>
					<li
						onClick={() =>
							dispatch(
								openDialog({
									type: 'changeSectionWordCount',
									section: section,
								})
							)
						}
						className="flex items-center gap-3 p-3 hover:bg-[#E3EEFF] rounded-lg"
					>
						<WordCount />
						<p className="font-bold uppercase text-primary">
							{' '}
							Change wordcount
						</p>
					</li>
					<li
						onClick={() =>
							dispatch(openDialog({ type: 'renameSection', section: section }))
						}
						className="flex items-center gap-3 p-3 hover:bg-[#E3EEFF] rounded-lg"
					>
						<Rename />
						<p className="font-bold uppercase text-primary">Rename Section</p>
					</li>
				</ul>
			</PopoverContent>
		</Popover>
	);
};

export default SectionEditPopover;
