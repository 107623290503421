import styles from './DatePicker.module.css';
import { cn } from '@/lib/utils';
import { FC } from 'react';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { CalendarDays as CalendarIcon } from 'lucide-react';
import { SelectSingleEventHandler } from 'react-day-picker';
import TextButton from '../Button/TextButton/TextButton';

interface DatePickerProps {
	date?: string;
	setDate: SelectSingleEventHandler;
	placeholder?: string;
	handleClear?: () => void;
	setToday?: () => void;
	className?: string;
}

const DatePicker: FC<DatePickerProps> = ({
	date,
	setDate,
	placeholder,
	handleClear,
	setToday,
	className,
}) => {
	return (
		<Popover>
			<PopoverTrigger
				className={cn(
					'flex items-center w-full justify-between bg-white hover:bg-lightBackground border border-[#D3DDE2] px-4 rounded-xl h-12',
					!date && 'text-muted-foreground',
					className
				)}
			>
				{date ? (
					format(date, 'dd LLL y')
				) : (
					<span className="text-[#6D7D86] opacity-80">
						{placeholder ? placeholder : 'Pick a date range'}
					</span>
				)}
				<CalendarIcon className="ml-4 h-6 w-6 text-primary" />
			</PopoverTrigger>
			<PopoverContent
				className="w-auto p-0 shadow-main bg-white wilrounded-xl"
				align="end"
			>
				<Calendar
					initialFocus
					mode="single"
					selected={date ? new Date(date) : undefined}
					onSelect={setDate}
					className={styles.calendar}
				/>
				<div className="grid grid-flow-col">
					{handleClear && date && (
						<TextButton
							className="justify-self-start px-6 pb-2 pt-0"
							onClick={handleClear}
						>
							Clear
						</TextButton>
					)}
					{setToday && (
						<TextButton
							className="justify-self-end px-6 pb-2 pt-0"
							onClick={setToday}
						>
							Today
						</TextButton>
					)}
				</div>
			</PopoverContent>
		</Popover>
	);
};

export default DatePicker;
