import { FC } from 'react';
import { cn } from '@/lib/utils';
import { colorClasses } from '@/lib/types/constants';

interface FallbackAvatarProps {
	user: {
		id: number;
		name?: string;
		email: string;
	} | null;
	className?: string;
}

const FallbackAvatar: FC<FallbackAvatarProps> = ({ user, className }) => {
	return (
		<div
			className={cn(
				'flex items-center justify-center text-white h-full w-full border border-white rounded-full uppercase',
				colorClasses[(user?.id || 0) % colorClasses.length],
				className
			)}
		>
			{user?.name ? user.name?.trim()[0] : user?.email[0] || ''}
		</div>
	);
};

export default FallbackAvatar;
