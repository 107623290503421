import { SVGProps } from 'react';

const EmptyEssential = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="132"
			height="141"
			viewBox="0 0 132 141"
			fill="none"
		>
			<g clipPath="url(#clip0_10391_21038)">
				<path
					d="M23.2572 2.79688L92.5772 2.89687C98.6972 2.89687 103.657 7.87687 103.657 13.9969L103.517 127.127C103.517 133.247 98.5372 138.207 92.4172 138.207L13.1972 138.107C7.07719 138.107 2.11719 133.127 2.11719 127.007L2.25719 22.0569L23.2572 2.79688Z"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M71.7459 42.3281H17.0859"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M71.7459 59.0781H17.0859"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M71.7459 75.8359H17.0859"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M52.8859 92.5859H17.0859"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M23.2578 2.79688V13.9369C23.2578 18.7569 19.3578 22.6569 14.5378 22.6569H2.25781L23.2578 2.79688Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M92.2763 124.967C112.915 124.967 129.646 108.235 129.646 87.5966C129.646 66.9577 112.915 50.2266 92.2763 50.2266C71.6374 50.2266 54.9062 66.9577 54.9062 87.5966C54.9062 108.235 71.6374 124.967 92.2763 124.967Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M120.098 113.516L129.888 123.296"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M89.1758 105.425C89.1758 104.355 89.5158 103.445 90.1858 102.695C90.8558 101.945 91.7758 101.575 92.9458 101.575C94.1158 101.575 95.0858 101.955 95.7458 102.695C96.4058 103.445 96.7358 104.355 96.7358 105.425C96.7358 106.495 96.4058 107.405 95.7458 108.155C95.0858 108.905 94.1558 109.275 92.9458 109.275C91.7358 109.275 90.8558 108.895 90.1858 108.155C89.5158 107.405 89.1758 106.495 89.1758 105.425ZM96.4358 69.3855L95.8758 93.3755C95.8358 94.9855 94.5258 96.2655 92.9158 96.2655C91.3058 96.2655 89.9958 94.9855 89.9558 93.3755L89.3658 69.3855C89.3258 67.7255 90.6658 66.3555 92.3258 66.3555H93.4758C95.1358 66.3555 96.4758 67.7255 96.4358 69.3855Z"
					fill="#AFC1DB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10391_21038">
					<rect
						width="131.69"
						height="139.33"
						fill="white"
						transform="translate(0.15625 0.835938)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default EmptyEssential;
