import HistoryCard from '@/components/HistoryCard/HistoryCard';
import { findSection } from '@/lib/functions/funcUtils';
import { Section } from '@/lib/types/apiTypes';
import { cn } from '@/lib/utils';
import { RootState } from '@/redux/store/store';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './HistoryWrapper.module.css';
import HistoryIntro from '@/components/HistoryIntro/HistoryIntro';
import WriteModeSkeleton from '@/components/WriteModeSkeleton/WriteModeSkeleton';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

interface HistoryWrapperProps {}

const HistoryWrapper: FC<HistoryWrapperProps> = () => {
	const { activeSection } = useActiveSection();
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const section = useMemo(
		() =>
			findSection(
				proposal?.sections || [],
				String(activeSection.sectionId)
			) as Section,
		[proposal?.sections, activeSection.sectionId]
	);
	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);

	if (isStreaming) return <WriteModeSkeleton />;

	return section?.generationHistory?.length > 0 ? (
		<div className={cn(styles.wrapper, 'light-scroll')}>
			<div className="flex flex-col gap-4 p-5">
				{section?.generationHistory.map((history, index) => (
					<HistoryCard
						key={index}
						timestamp={history.timestamp}
						text={history.text}
						user={history.user}
						index={index}
					/>
				))}
			</div>
		</div>
	) : (
		<div className="p-5">
			<HistoryIntro />
		</div>
	);
};

export default HistoryWrapper;
