import { SVGProps } from 'react';

const Menu = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<rect width="32" height="32" rx="10" fill="#E3EEFF" />
			<mask
				id="mask0_3257_4354"
				maskUnits="userSpaceOnUse"
				x="4"
				y="4"
				width="24"
				height="24"
			>
				<rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_3257_4354)">
				<path
					d="M11 13V11H25V13H11ZM11 17V15H25V17H11ZM11 21V19H25V21H11ZM8 13C7.71667 13 7.47917 12.9042 7.2875 12.7125C7.09583 12.5208 7 12.2833 7 12C7 11.7167 7.09583 11.4792 7.2875 11.2875C7.47917 11.0958 7.71667 11 8 11C8.28333 11 8.52083 11.0958 8.7125 11.2875C8.90417 11.4792 9 11.7167 9 12C9 12.2833 8.90417 12.5208 8.7125 12.7125C8.52083 12.9042 8.28333 13 8 13ZM8 17C7.71667 17 7.47917 16.9042 7.2875 16.7125C7.09583 16.5208 7 16.2833 7 16C7 15.7167 7.09583 15.4792 7.2875 15.2875C7.47917 15.0958 7.71667 15 8 15C8.28333 15 8.52083 15.0958 8.7125 15.2875C8.90417 15.4792 9 15.7167 9 16C9 16.2833 8.90417 16.5208 8.7125 16.7125C8.52083 16.9042 8.28333 17 8 17ZM8 21C7.71667 21 7.47917 20.9042 7.2875 20.7125C7.09583 20.5208 7 20.2833 7 20C7 19.7167 7.09583 19.4792 7.2875 19.2875C7.47917 19.0958 7.71667 19 8 19C8.28333 19 8.52083 19.0958 8.7125 19.2875C8.90417 19.4792 9 19.7167 9 20C9 20.2833 8.90417 20.5208 8.7125 20.7125C8.52083 20.9042 8.28333 21 8 21Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Menu;
