import { FC } from 'react';

interface ChatProps {
	className?: string;
}

const Chat: FC<ChatProps> = ({ className }) => {
	return (
		<svg
			className={className}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="32" height="32" rx="8" fill="#E3EEFF" />
			<path
				d="M18.3147 21.9875L18.3517 22.0213C19.4676 23.0243 20.8655 23.6592 22.3551 23.8395C22.3825 23.8436 22.4104 23.8414 22.4368 23.8329C22.4631 23.8245 22.4872 23.8101 22.5071 23.7908C22.526 23.772 22.5403 23.7492 22.549 23.724C22.5577 23.6988 22.5606 23.672 22.5573 23.6455C22.5541 23.6191 22.5448 23.5937 22.5302 23.5714C22.5156 23.5491 22.4962 23.5304 22.4733 23.5168C21.7125 23.0791 21.0793 22.4503 20.6362 21.6927L20.5622 21.5628L20.7025 21.516C23.3532 20.6316 25 18.7432 25 16.5873C25 15.0113 24.0539 13.5106 22.4025 12.4709L22.3661 12.4352C20.9726 10.3163 18.1946 9 15.1172 9C10.6417 9 7 11.7852 7 15.21C7 17.399 8.44162 19.377 10.8553 20.5004L11.0092 20.5725L10.8904 20.6933C10.5988 20.9933 10.2692 21.2537 9.90986 21.468C9.7289 21.5766 9.58849 21.7415 9.51014 21.9374C9.43179 22.1334 9.41978 22.3496 9.47598 22.5531C9.53217 22.7565 9.65348 22.9359 9.82131 23.0639C9.98915 23.1918 10.1943 23.2612 10.4053 23.2616C10.4514 23.2617 10.4974 23.2584 10.543 23.2518C12.0184 23.0577 13.4144 22.4703 14.5847 21.5511L14.6353 21.5115L14.6964 21.5316C15.8451 21.9094 17.0551 22.0659 18.2621 21.9927L18.3147 21.9875ZM10.0728 22.3959L10.5923 22.1018C11.3545 21.652 11.9534 20.9709 12.3021 20.1576C9.59164 19.3186 7.84937 17.3906 7.84937 15.2165C7.84937 12.2592 11.1112 9.85264 15.1224 9.85264C19.1336 9.85264 22.3954 12.2592 22.3954 15.2165C22.3954 18.1737 19.1336 20.5822 15.1224 20.5822C14.9107 20.5822 14.6873 20.5744 14.4555 20.5576C13.4284 21.5573 12.094 22.181 10.6683 22.3278L10.0728 22.3959Z"
				fill="#5D9BFD"
			/>
		</svg>
	);
};

export default Chat;
