import { FC } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Pdf from '@/components/ui/icons/new/Pdf';
import { Skeleton } from '@/components/ui/skeleton';
import SectionDetails from '@/components/ExportDocuments/SectionDetails/SectionDetails';
import { Template } from '@/lib/types/apiTypes';
import useAuth from '@/lib/hooks/useAuth';

interface TemplateSectionDetailsProps {
	handleEditTemplate: (template: Template) => void;
	selectedTemplate: Template;
	sectionNames: string[];
	pageTransitionLoad?: boolean;
}

const TemplateSectionDetails: FC<TemplateSectionDetailsProps> = ({
	handleEditTemplate,
	selectedTemplate,
	sectionNames,
	pageTransitionLoad = false,
}) => {
	const { hasTemplateBuilderAccess } = useAuth();

	return (
		<div className="flex flex-col w-full p-6 gap-4 h-full rounded-xl border border-[rgba(185,212,255,0.4)] bg-[#EEF2F9]">
			{pageTransitionLoad ? (
				<ComponentSkeleton />
			) : (
				<>
					<div className="flex items-center justify-between w-full">
						<h4 className="font-bold text-black dynamic-medium">
							Required Sections
						</h4>
						<PDFDownloadLink
							document={<SectionDetails sectionNames={sectionNames || []} />}
							fileName="Matching Report.pdf"
						>
							<div className="flex items-center h-6 cursor-pointer text-primary dynamic-text hover:scale-105 animated-hover-effect">
								<Pdf /> Download as PDF
							</div>
						</PDFDownloadLink>
					</div>
					<div className="inline p-4 text-black bg-white border dynamic-text rounded-xl border-black-secondary">
						<span className="font-bold text-secondary">Please note:</span> These
						are sections identified in the solicitation that are required by the
						funder. Please compare your template (on the right) with sections we
						have identified. It is a good practice to include sections in your
						proposal identified in the solicitation requirement.{' '}
						{hasTemplateBuilderAccess && (
							<>
								Press{' '}
								<span
									className="text-primary hover:text-secondary cursor-pointer uppercase"
									onClick={() => handleEditTemplate(selectedTemplate)}
								>
									Edit This Template
								</span>{' '}
								to edit your template if needed.
							</>
						)}
					</div>
					<div className="flex flex-col flex-grow p-4 overflow-y-auto bg-white border rounded-xl border-black-secondary ">
						{sectionNames?.map((sectionName, index) => (
							<p className="text-black dynamic-text" key={index}>
								{sectionNames.length === 1 && sectionName === 'NONE'
									? 'It appears that the solicitation does not provide specific information about the template sections.'
									: sectionName}
							</p>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default TemplateSectionDetails;

const ComponentSkeleton = () => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between w-full">
				<Skeleton className="w-3/12 h-6 bg-slate-300" />
				<Skeleton className="w-2/12 h-6 bg-slate-300" />
			</div>
			<div className="flex flex-col gap-4 pr-2 overflow-y-auto">
				<div className="flex flex-col gap-1 p-4 bg-white border rounded-xl border-black-secondary">
					<Skeleton className="w-full h-4 bg-slate-300" />
					<Skeleton className="w-full h-4 bg-slate-300" />
					<Skeleton className="w-full h-4 bg-slate-300" />
					<Skeleton className="w-4/12 h-4 bg-slate-300" />
				</div>
				<div className="flex flex-col gap-1 p-4 bg-white border rounded-xl border-black-secondary">
					{[...Array(10)].map((subId, subIndex) => (
						<Skeleton
							key={`${subId}-${subIndex}`}
							className="w-3/12 h-4 bg-slate-300"
						/>
					))}
				</div>
			</div>
		</div>
	);
};
