import { useAppDispatch } from '@/lib/hooks/hooks';
import { FC, useEffect, useRef, useState } from 'react';
import { FileX2, PenBox, SaveIcon } from 'lucide-react';
import { deleteClipboard, updateClipboard } from '@/lib/functions/apiCalls';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { Clipboard } from '@/lib/types/apiTypes';
import { cn } from '@/lib/utils';
import styles from './ClipboardIdea.module.css';
import { useQueryClient } from 'react-query';

interface ClipboardIdeaProps {
	clipboard: Clipboard;
	isSelected: boolean;
	toggleSelection: () => void;
	handleGetClipboards: () => void;
	setIsLoading: (isLoading: boolean) => void;
}

const ideaCharLimit = 500;

const ClipboardIdea: FC<ClipboardIdeaProps> = ({
	clipboard,
	isSelected,
	toggleSelection,
	handleGetClipboards,
	setIsLoading,
}) => {
	const dispatch = useAppDispatch();

	const ideaRef = useRef<HTMLTextAreaElement>(null);

	const queryClient = useQueryClient();

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [content, setContent] = useState(clipboard.text || '');
	const [charCount, setCharCount] = useState(0);

	const handleUpdateClipboard = async () => {
		try {
			if (!clipboard?.id) return;
			setIsLoading(true);
			const response = await updateClipboard(clipboard.id, content);
			handleResponse(response, 'Clipboard has been updated successfully');
			handleGetClipboards();
			setIsEditing(false);
		} catch (error) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
			setIsEditing(false);
		}
	};

	const handleDeleteClipboard = async (clipboardId) => {
		try {
			setIsLoading(true);
			const response = await deleteClipboard(clipboardId);
			handleResponse(response, 'Clipboard has been deleted successfully');
			handleGetClipboards();
			queryClient.invalidateQueries('clipboards');
		} catch (error) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newText = e.target.value;
		setContent(newText);
		setCharCount(newText.length);
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
		const pasteText = e.clipboardData?.getData('text/plain');
		if (pasteText) {
			const newText = content + pasteText;
			setContent(newText);
			setCharCount(newText.length);
			e.preventDefault();
		}
	};

	const handleKeyPress = async (
		e: React.KeyboardEvent<HTMLTextAreaElement>
	) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			// Prevents triggering when Shift+Enter is pressed
			e.preventDefault(); // Prevents a newline from being added to the textarea
			if (content.trim() == '' || charCount > ideaCharLimit) return; // Prevents save if content is empty
			await handleUpdateClipboard();
		}
	};

	useEffect(() => {
		if (isEditing && ideaRef.current) {
			// Focus on the textarea when editing is enabled
			ideaRef.current?.setSelectionRange(
				ideaRef.current?.value.length,
				ideaRef.current?.value.length
			);
			ideaRef.current?.focus();
			setCharCount(ideaRef.current?.value.length);
		}
	}, [isEditing]);

	return (
		<div
			className={cn(
				styles.boxContainer,
				'flex flex-col p-4 border rounded-xl bg-lightBackground cursor-pointer',
				isSelected ? 'border-primary' : 'border-lightBackground'
			)}
			key={clipboard.id}
			onClick={() => {
				!isEditing && toggleSelection();
			}}
		>
			{isEditing ? (
				<>
					<textarea
						value={content}
						onChange={handleContentChange}
						placeholder="Add Clipboard"
						ref={ideaRef}
						className={styles.textarea + ' dynamic-text text-black'}
						onKeyDown={handleKeyPress}
						onPaste={handlePaste}
					/>
					<div
						className={`flex ${charCount > ideaCharLimit ? 'justify-between' : 'justify-end'} gap-2 mt-2`}
					>
						{charCount > ideaCharLimit && (
							<p className="text-red-500 uppercase dynamic-small">
								Limit exceeded
							</p>
						)}
						<p
							className={cn(
								'text-black text-right dynamic-small',
								charCount > ideaCharLimit ? 'text-red-500' : ''
							)}
						>
							{charCount}/{ideaCharLimit} characters
						</p>
					</div>
				</>
			) : (
				<p className="dynamic-text text-black">{clipboard.text}</p>
			)}
			<div className={cn(styles.btnContainer)}>
				<div className="w-full h-2 my-3 border-b border-blackSecondary"></div>
				<div className="flex h-7">
					{!isEditing ? (
						<button
							onClick={(e) => {
								setIsEditing(true);
								e.stopPropagation();
							}}
							className="flex font-medium items-center justify-center w-1/2 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-slate-300/50 cursor-pointer"
						>
							<PenBox size={18} /> Edit
						</button>
					) : (
						<button
							onClick={(e) => {
								handleUpdateClipboard();
								e.stopPropagation();
							}}
							disabled={content.length === 0 || content.length > ideaCharLimit}
							className={`flex font-medium items-center justify-center w-1/2 text-primary gap-1 2xl:gap-2 rounded-md py-1 cursor-pointer ${content.length === 0 || content.length > ideaCharLimit ? 'opacity-50' : 'hover:bg-slate-300/50 '}`}
						>
							<SaveIcon size={18} /> Save
						</button>
					)}
					<button
						onClick={(e) => {
							handleDeleteClipboard(clipboard.id);
							e.stopPropagation();
						}}
						className="flex font-medium items-center justify-center w-1/2 text-lightRed gap-1 2xl:gap-2 rounded-md py-1 hover:bg-slate-300/50 cursor-pointer"
					>
						<FileX2 size={18} />
						Remove
					</button>
				</div>
			</div>
		</div>
	);
};

export default ClipboardIdea;
