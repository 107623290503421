import BrainstormDrawer from './BrainstormDrawer/BrainstormDrawer';
import CommentsDrawer from './CommentsDrawer/CommentsDrawer';
import ClipboardIdeaDrawer from './ClipboardIdeaDrawer/ClipboardIdeaDrawer';

const Drawers = () => {
	return (
		<>
			<BrainstormDrawer />
			<CommentsDrawer />
			<ClipboardIdeaDrawer />
		</>
	);
};

export default Drawers;
