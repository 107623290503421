export const expertise = [
	{
		label: 'Agriculture',
		value: 'Agriculture',
		children: [
			{
				label: 'Crop Production',
				value: 'Crop Production',
			},
			{
				label: 'Livestock Management',
				value: 'Livestock Management',
			},
			{
				label: 'Agricultural Extension Services',
				value: 'Agricultural Extension Services',
			},
			{
				label: 'Agribusiness Development',
				value: 'Agribusiness Development',
			},
			{
				label: 'Agricultural Training and Capacity Building',
				value: 'Agricultural Training and Capacity Building',
			},
		],
	},
	{
		label: 'Economic Recovery and Market Systems',
		value: 'Economic Recovery and Market Systems',
		children: [
			{
				label: 'Small and Medium Enterprise Support',
				value: 'Small and Medium Enterprise Support',
			},
			{
				label: 'Microfinance and Financial Inclusion',
				value: 'Microfinance and Financial Inclusion',
			},
			{
				label: 'Vocational Training and Workforce Development',
				value: 'Vocational Training and Workforce Development',
			},
			{
				label: 'Trade and Market Access',
				value: 'Trade and Market Access',
			},
			{
				label: 'Entrepreneurship and Business Skills Training',
				value: 'Entrepreneurship and Business Skills Training',
			},
		],
	},
	{
		label: 'Food Assistance',
		value: 'Food Assistance',
		children: [
			{
				label: 'Emergency Food Distribution',
				value: 'Emergency Food Distribution',
			},
			{
				label: 'School Feeding Programs',
				value: 'School Feeding Programs',
			},
			{
				label: 'Food-for-Work Initiatives',
				value: 'Food-for-Work Initiatives',
			},
			{
				label: 'Nutritional Support Programs',
				value: 'Nutritional Support Programs',
			},
			{
				label: 'Training in Food Security and Sustainable Practices',
				value: 'Training in Food Security and Sustainable Practices',
			},
		],
	},
	{
		label: 'Health',
		value: 'Health',
		children: [
			{
				label: 'Maternal and Child Health',
				value: 'Maternal and Child Health',
			},
			{
				label: 'Infectious Disease Control',
				value: 'Infectious Disease Control',
			},
			{
				label: 'Health System Strengthening',
				value: 'Health System Strengthening',
			},
			{
				label: 'Mental Health and Psychosocial Support',
				value: 'Mental Health and Psychosocial Support',
			},
			{
				label: 'Health Worker Training and Capacity Building',
				value: 'Health Worker Training and Capacity Building',
			},
		],
	},
	{
		label: 'Humanitarian Coordination, Information Management, and Assessments',
		value: 'Humanitarian Coordination, Information Management, and Assessments',
		children: [
			{
				label: 'Disaster Response Coordination',
				value: 'Disaster Response Coordination',
			},
			{
				label: 'Data Collection and Analysis',
				value: 'Data Collection and Analysis',
			},
			{
				label: 'Needs Assessment and Surveys',
				value: 'Needs Assessment and Surveys',
			},
			{
				label: 'Reporting and Information Dissemination',
				value: 'Reporting and Information Dissemination',
			},
			{
				label: 'Training in Humanitarian Response and Coordination',
				value: 'Training in Humanitarian Response and Coordination',
			},
		],
	},
	{
		label: 'Logistics',
		value: 'Logistics',
		children: [
			{
				label: 'Supply Chain Management',
				value: 'Supply Chain Management',
			},
			{
				label: 'Transportation and Distribution',
				value: 'Transportation and Distribution',
			},
			{
				label: 'Warehousing and Inventory Management',
				value: 'Warehousing and Inventory Management',
			},
			{
				label: 'Procurement and Sourcing',
				value: 'Procurement and Sourcing',
			},
			{
				label: 'Training in Logistics and Supply Chain Management',
				value: 'Training in Logistics and Supply Chain Management',
			},
		],
	},
	{
		label: 'Monitoring, Evaluation, and Learning (MEL)',
		value: 'Monitoring, Evaluation, and Learning (MEL)',
		children: [
			{
				label: 'Impact Evaluation',
				value: 'Impact Evaluation',
			},
			{
				label: 'Program Monitoring',
				value: 'Program Monitoring',
			},
			{
				label: 'Data Management and Analysis',
				value: 'Data Management and Analysis',
			},
			{
				label: 'Learning and Capacity Building',
				value: 'Learning and Capacity Building',
			},
			{
				label: 'Training in Monitoring and Evaluation Techniques',
				value: 'Training in Monitoring and Evaluation Techniques',
			},
		],
	},
	{
		label: 'Multipurpose Cash Assistance',
		value: 'Multipurpose Cash Assistance',
		children: [
			{
				label: 'Cash for Work',
				value: 'Cash for Work',
			},
			{
				label: 'Conditional and Unconditional Cash Transfers',
				value: 'Conditional and Unconditional Cash Transfers',
			},
			{
				label: 'Cash-Based Interventions in Market Systems',
				value: 'Cash-Based Interventions in Market Systems',
			},
			{
				label: 'Electronic Cash Transfer Systems',
				value: 'Electronic Cash Transfer Systems',
			},
			{
				label: 'Financial Literacy and Management Training',
				value: 'Financial Literacy and Management Training',
			},
		],
	},
	{
		label: 'Natural and Technological Hazards',
		value: 'Natural and Technological Hazards',
		children: [
			{
				label: 'Disaster Risk Reduction',
				value: 'Disaster Risk Reduction',
			},
			{
				label: 'Emergency Preparedness and Response',
				value: 'Emergency Preparedness and Response',
			},
			{
				label: 'Climate Change Adaptation',
				value: 'Climate Change Adaptation',
			},
			{
				label: 'Technological Hazard Mitigation',
				value: 'Technological Hazard Mitigation',
			},
			{
				label: 'Disaster Preparedness and Response Training',
				value: 'Disaster Preparedness and Response Training',
			},
		],
	},
	{
		label: 'Nutrition',
		value: 'Nutrition',
		children: [
			{
				label: 'Infant and Young Child Feeding',
				value: 'Infant and Young Child Feeding',
			},
			{
				label: 'Community Nutrition Programs',
				value: 'Community Nutrition Programs',
			},
			{
				label: 'Therapeutic and Supplementary Feeding',
				value: 'Therapeutic and Supplementary Feeding',
			},
			{
				label: 'Community Nutrition Worker Training',
				value: 'Community Nutrition Worker Training',
			},
			{
				label: 'Nutrition Education and Behavior Change',
				value: 'Nutrition Education and Behavior Change',
			},
		],
	},
	{
		label: 'Protection',
		value: 'Protection',
		children: [
			{
				label: 'Child Protection',
				value: 'Child Protection',
			},
			{
				label: 'Gender-Based Violence Prevention and Response',
				value: 'Gender-Based Violence Prevention and Response',
			},
			{
				label: 'Legal Assistance and Advocacy',
				value: 'Legal Assistance and Advocacy',
			},
			{
				label: 'Protection of Displaced Populations',
				value: 'Protection of Displaced Populations',
			},
			{
				label: 'Training in Protection Principles and Practices',
				value: 'Training in Protection Principles and Practices',
			},
		],
	},
	{
		label: 'Shelter and Settlements',
		value: 'Shelter and Settlements',
		children: [
			{
				label: 'Emergency Shelter Provision',
				value: 'Emergency Shelter Provision',
			},
			{
				label: 'Reconstruction and Rehabilitation',
				value: 'Reconstruction and Rehabilitation',
			},
			{
				label: 'Settlement Planning and Management',
				value: 'Settlement Planning and Management',
			},
			{
				label: 'Non-Food Item Distribution',
				value: 'Non-Food Item Distribution',
			},
			{
				label: 'Training in Shelter Construction and Management',
				value: 'Training in Shelter Construction and Management',
			},
		],
	},
	{
		label: 'Water, Sanitation, and Hygiene (WASH)',
		value: 'Water, Sanitation, and Hygiene (WASH)',
		children: [
			{
				label: 'Water Supply Systems',
				value: 'Water Supply Systems',
			},
			{
				label: 'Sanitation Infrastructure Development',
				value: 'Sanitation Infrastructure Development',
			},
			{
				label: 'Hygiene Promotion and Education',
				value: 'Hygiene Promotion and Education',
			},
			{
				label: 'WASH in Schools and Health Facilities',
				value: 'WASH in Schools and Health Facilities',
			},
			{
				label: 'WASH Training and Community Mobilization',
				value: 'WASH Training and Community Mobilization',
			},
		],
	},
];

export const regionsOfWork = [
	{
		label: 'Africa',
		value: 'Africa',
	},
	{
		label: 'Asia',
		value: 'Asia',
	},
	{
		label: 'Middle East',
		value: 'Middle East',
	},
	{
		label: 'Europe',
		value: 'Europe',
	},
	{
		label: 'North and Central America',
		value: 'North and Central America',
	},
	{
		label: 'South America',
		value: 'South America',
	},
	{
		label: 'Oceana',
		value: 'Oceana',
	},
];

export const countriesAndTerritories = [
	{
		label: 'Algeria',
		value: 'Algeria',
	},
	{
		label: 'Angola',
		value: 'Angola',
	},
	{
		label: 'Benin',
		value: 'Benin',
	},
	{
		label: 'Botswana',
		value: 'Botswana',
	},
	{
		label: 'Burkina Faso',
		value: 'Burkina Faso',
	},
	{
		label: 'Burundi',
		value: 'Burundi',
	},
	{
		label: 'Cameroon',
		value: 'Cameroon',
	},
	{
		label: 'Cape Verde',
		value: 'Cape Verde',
	},
	{
		label: 'Central African Republic',
		value: 'Central African Republic',
	},
	{
		label: 'Chad',
		value: 'Chad',
	},
	{
		label: 'Comoros',
		value: 'Comoros',
	},
	{
		label: 'Congo',
		value: 'Congo',
	},
	{
		label: "Cote d'Ivoire",
		value: "Cote d'Ivoire",
	},
	{
		label: 'Democratic Republic of Congo',
		value: 'Democratic Republic of Congo',
	},
	{
		label: 'Djibouti',
		value: 'Djibouti',
	},
	{
		label: 'Egypt',
		value: 'Egypt',
	},
	{
		label: 'Equatorial Guinea',
		value: 'Equatorial Guinea',
	},
	{
		label: 'Eritrea',
		value: 'Eritrea',
	},
	{
		label: 'Eswatini',
		value: 'Eswatini',
	},
	{
		label: 'Ethiopia',
		value: 'Ethiopia',
	},
	// {
	// 	label: 'Ethiopia (former)',
	// 	value: 'Ethiopia (former)',
	// },
	{
		label: 'Gabon',
		value: 'Gabon',
	},
	{
		label: 'Gambia',
		value: 'Gambia',
	},
	{
		label: 'Ghana',
		value: 'Ghana',
	},
	{
		label: 'Guinea',
		value: 'Guinea',
	},
	{
		label: 'Guinea-Bissau',
		value: 'Guinea-Bissau',
	},
	{
		label: 'Kenya',
		value: 'Kenya',
	},
	{
		label: 'Lesotho',
		value: 'Lesotho',
	},
	{
		label: 'Liberia',
		value: 'Liberia',
	},
	{
		label: 'Libya',
		value: 'Libya',
	},
	{
		label: 'Madagascar',
		value: 'Madagascar',
	},
	{
		label: 'Malawi',
		value: 'Malawi',
	},
	{
		label: 'Mali',
		value: 'Mali',
	},
	{
		label: 'Mauritania',
		value: 'Mauritania',
	},
	{
		label: 'Mauritius',
		value: 'Mauritius',
	},
	{
		label: 'Mayotte',
		value: 'Mayotte',
	},
	{
		label: 'Morocco',
		value: 'Morocco',
	},
	{
		label: 'Mozambique',
		value: 'Mozambique',
	},
	{
		label: 'Namibia',
		value: 'Namibia',
	},
	{
		label: 'Niger',
		value: 'Niger',
	},
	{
		label: 'Nigeria',
		value: 'Nigeria',
	},
	{
		label: 'Reunion',
		value: 'Reunion',
	},
	{
		label: 'Rwanda',
		value: 'Rwanda',
	},
	{
		label: 'Saint Helena',
		value: 'Saint Helena',
	},
	{
		label: 'Sao Tome and Principe',
		value: 'Sao Tome and Principe',
	},
	{
		label: 'Senegal',
		value: 'Senegal',
	},
	{
		label: 'Seychelles',
		value: 'Seychelles',
	},
	{
		label: 'Sierra Leone',
		value: 'Sierra Leone',
	},
	{
		label: 'Somalia',
		value: 'Somalia',
	},
	{
		label: 'Somaliland',
		value: 'Somaliland',
	},
	{
		label: 'South Africa',
		value: 'South Africa',
	},
	{
		label: 'South Sudan',
		value: 'South Sudan',
	},
	{
		label: 'Sudan',
		value: 'Sudan',
	},
	{
		label: 'Tanzania',
		value: 'Tanzania',
	},
	{
		label: 'Togo',
		value: 'Togo',
	},
	{
		label: 'Tunisia',
		value: 'Tunisia',
	},
	{
		label: 'Uganda',
		value: 'Uganda',
	},
	{
		label: 'Western Sahara',
		value: 'Western Sahara',
	},
	{
		label: 'Zambia',
		value: 'Zambia',
	},
	{
		label: 'Zanzibar',
		value: 'Zanzibar',
	},
	{
		label: 'Zimbabwe',
		value: 'Zimbabwe',
	},
	{
		label: 'Abkhazia',
		value: 'Abkhazia',
	},
	{
		label: 'Afghanistan',
		value: 'Afghanistan',
	},
	// {
	// 	label: 'Akrotiri and Dhekelia',
	// 	value: 'Akrotiri and Dhekelia',
	// },
	{
		label: 'Armenia',
		value: 'Armenia',
	},
	{
		label: 'Azerbaijan',
		value: 'Azerbaijan',
	},
	{
		label: 'Bahrain',
		value: 'Bahrain',
	},
	{
		label: 'Bangladesh',
		value: 'Bangladesh',
	},
	{
		label: 'Bhutan',
		value: 'Bhutan',
	},
	// {
	// 	label: 'British Indian Ocean Territory',
	// 	value: 'British Indian Ocean Territory',
	// },
	{
		label: 'Brunei',
		value: 'Brunei',
	},
	{
		label: 'Cambodia',
		value: 'Cambodia',
	},
	{
		label: 'China',
		value: 'China',
	},
	{
		label: 'Christmas Island',
		value: 'Christmas Island',
	},
	{
		label: 'Cocos Islands',
		value: 'Cocos Islands',
	},
	{
		label: 'East Timor',
		value: 'East Timor',
	},
	{
		label: 'Georgia',
		value: 'Georgia',
	},
	{
		label: 'Hong Kong',
		value: 'Hong Kong',
	},
	{
		label: 'India',
		value: 'India',
	},
	{
		label: 'Indonesia',
		value: 'Indonesia',
	},
	{
		label: 'Iran',
		value: 'Iran',
	},
	{
		label: 'Iraq',
		value: 'Iraq',
	},
	{
		label: 'Israel',
		value: 'Israel',
	},
	{
		label: 'Japan',
		value: 'Japan',
	},
	{
		label: 'Jordan',
		value: 'Jordan',
	},
	{
		label: 'Kazakhstan',
		value: 'Kazakhstan',
	},
	// {
	// 	label: 'Korea (former)',
	// 	value: 'Korea (former)',
	// },
	{
		label: 'Kuwait',
		value: 'Kuwait',
	},
	{
		label: 'Kyrgyzstan',
		value: 'Kyrgyzstan',
	},
	{
		label: 'Laos',
		value: 'Laos',
	},
	{
		label: 'Lebanon',
		value: 'Lebanon',
	},
	// {
	// 	label: 'Macao',
	// 	value: 'Macao',
	// },
	{
		label: 'Malaysia',
		value: 'Malaysia',
	},
	{
		label: 'Maldives',
		value: 'Maldives',
	},
	{
		label: 'Mongolia',
		value: 'Mongolia',
	},
	{
		label: 'Myanmar',
		value: 'Myanmar',
	},
	{
		label: 'Nagorno-Karabakh',
		value: 'Nagorno-Karabakh',
	},
	{
		label: 'Nepal',
		value: 'Nepal',
	},
	{
		label: 'North Korea',
		value: 'North Korea',
	},
	{
		label: 'Northern Cyprus',
		value: 'Northern Cyprus',
	},
	{
		label: 'Oman',
		value: 'Oman',
	},
	{
		label: 'Pakistan',
		value: 'Pakistan',
	},
	{
		label: 'Palestine',
		value: 'Palestine',
	},
	{
		label: 'Philippines',
		value: 'Philippines',
	},
	{
		label: 'Qatar',
		value: 'Qatar',
	},
	{
		label: 'Republic of Vietnam',
		value: 'Republic of Vietnam',
	},
	{
		label: 'Saudi Arabia',
		value: 'Saudi Arabia',
	},
	{
		label: 'Singapore',
		value: 'Singapore',
	},
	{
		label: 'South Korea',
		value: 'South Korea',
	},
	{
		label: 'South Ossetia',
		value: 'South Ossetia',
	},
	{
		label: 'Sri Lanka',
		value: 'Sri Lanka',
	},
	{
		label: 'Syria',
		value: 'Syria',
	},
	{
		label: 'Taiwan',
		value: 'Taiwan',
	},
	{
		label: 'Tajikistan',
		value: 'Tajikistan',
	},
	{
		label: 'Thailand',
		value: 'Thailand',
	},
	{
		label: 'Turkey',
		value: 'Turkey',
	},
	{
		label: 'Turkmenistan',
		value: 'Turkmenistan',
	},
	{
		label: 'United Arab Emirates',
		value: 'United Arab Emirates',
	},
	{
		label: 'Uzbekistan',
		value: 'Uzbekistan',
	},
	{
		label: 'Vietnam',
		value: 'Vietnam',
	},
	{
		label: 'Yemen',
		value: 'Yemen',
	},
	// {
	// 	label: 'Yemen Arab Republic',
	// 	value: 'Yemen Arab Republic',
	// },
	// {
	// 	label: "Yemen People's Republic",
	// 	value: "Yemen People's Republic",
	// },
	// {
	// 	label: 'Aland Islands',
	// 	value: 'Aland Islands',
	// },
	{
		label: 'Albania',
		value: 'Albania',
	},
	// {
	// 	label: 'Andorra',
	// 	value: 'Andorra',
	// },
	{
		label: 'Austria',
		value: 'Austria',
	},
	// {
	// 	label: 'Austria-Hungary',
	// 	value: 'Austria-Hungary',
	// },
	// {
	// 	label: 'Baden',
	// 	value: 'Baden',
	// },
	// {
	// 	label: 'Bavaria',
	// 	value: 'Bavaria',
	// },
	{
		label: 'Belarus',
		value: 'Belarus',
	},
	{
		label: 'Belgium',
		value: 'Belgium',
	},
	{
		label: 'Bosnia and Herzegovina',
		value: 'Bosnia and Herzegovina',
	},
	{
		label: 'Bulgaria',
		value: 'Bulgaria',
	},
	// {
	// 	label: 'Channel Islands',
	// 	value: 'Channel Islands',
	// },
	{
		label: 'Croatia',
		value: 'Croatia',
	},
	{
		label: 'Cyprus',
		value: 'Cyprus',
	},
	{
		label: 'Czechia',
		value: 'Czechia',
	},
	// {
	// 	label: 'Czechoslovakia',
	// 	value: 'Czechoslovakia',
	// },
	{
		label: 'Denmark',
		value: 'Denmark',
	},
	// {
	// 	label: 'East Germany',
	// 	value: 'East Germany',
	// },
	{
		label: 'Estonia',
		value: 'Estonia',
	},
	{
		label: 'Faroe Islands',
		value: 'Faroe Islands',
	},
	{
		label: 'Finland',
		value: 'Finland',
	},
	{
		label: 'France',
		value: 'France',
	},
	{
		label: 'Germany',
		value: 'Germany',
	},
	{
		label: 'Gibraltar',
		value: 'Gibraltar',
	},
	{
		label: 'Greece',
		value: 'Greece',
	},
	// {
	// 	label: 'Guernsey',
	// 	value: 'Guernsey',
	// },
	// {
	// 	label: 'Hanover',
	// 	value: 'Hanover',
	// },
	{
		label: 'Hesse Electoral',
		value: 'Hesse Electoral',
	},
	// {
	// 	label: 'Hesse Grand Ducal',
	// 	value: 'Hesse Grand Ducal',
	// },
	{
		label: 'Hungary',
		value: 'Hungary',
	},
	{
		label: 'Iceland',
		value: 'Iceland',
	},
	{
		label: 'Ireland',
		value: 'Ireland',
	},
	// {
	// 	label: 'Isle of Man',
	// 	value: 'Isle of Man',
	// },
	{
		label: 'Italy',
		value: 'Italy',
	},
	// {
	// 	label: 'Jersey',
	// 	value: 'Jersey',
	// },
	{
		label: 'Kosovo',
		value: 'Kosovo',
	},
	{
		label: 'Latvia',
		value: 'Latvia',
	},
	{
		label: 'Liechtenstein',
		value: 'Liechtenstein',
	},
	{
		label: 'Lithuania',
		value: 'Lithuania',
	},
	{
		label: 'Luxembourg',
		value: 'Luxembourg',
	},
	{
		label: 'Malta',
		value: 'Malta',
	},
	// {
	// 	label: 'Mecklenburg Schwerin',
	// 	value: 'Mecklenburg Schwerin',
	// },
	// {
	// 	label: 'Modena',
	// 	value: 'Modena',
	// },
	{
		label: 'Moldova',
		value: 'Moldova',
	},
	{
		label: 'Monaco',
		value: 'Monaco',
	},
	{
		label: 'Montenegro',
		value: 'Montenegro',
	},
	{
		label: 'Netherlands',
		value: 'Netherlands',
	},
	{
		label: 'North Macedonia',
		value: 'North Macedonia',
	},
	{
		label: 'Norway',
		value: 'Norway',
	},
	{
		label: 'Parma',
		value: 'Parma',
	},
	{
		label: 'Poland',
		value: 'Poland',
	},
	{
		label: 'Portugal',
		value: 'Portugal',
	},
	{
		label: 'Romania',
		value: 'Romania',
	},
	{
		label: 'Russia',
		value: 'Russia',
	},
	// {
	// 	label: 'San Marino',
	// 	value: 'San Marino',
	// },
	// {
	// 	label: 'Saxony',
	// 	value: 'Saxony',
	// },
	{
		label: 'Serbia',
		value: 'Serbia',
	},
	{
		label: 'Serbia and Montenegro',
		value: 'Serbia and Montenegro',
	},
	// {
	// 	label: 'Serbia excluding Kosovo',
	// 	value: 'Serbia excluding Kosovo',
	// },
	{
		label: 'Slovakia',
		value: 'Slovakia',
	},
	{
		label: 'Slovenia',
		value: 'Slovenia',
	},
	{
		label: 'Spain',
		value: 'Spain',
	},
	// {
	// 	label: 'Svalbard and Jan Mayen',
	// 	value: 'Svalbard and Jan Mayen',
	// },
	{
		label: 'Sweden',
		value: 'Sweden',
	},
	{
		label: 'Switzerland',
		value: 'Switzerland',
	},
	{
		label: 'Transnistria',
		value: 'Transnistria',
	},
	// {
	// 	label: 'Tuscany',
	// 	value: 'Tuscany',
	// },
	// {
	// 	label: 'USSR',
	// 	value: 'USSR',
	// },
	{
		label: 'Ukraine',
		value: 'Ukraine',
	},
	{
		label: 'United Kingdom',
		value: 'United Kingdom',
	},
	// {
	// 	label: 'Vatican',
	// 	value: 'Vatican',
	// },
	// {
	// 	label: 'West Germany',
	// 	value: 'West Germany',
	// },
	// {
	// 	label: 'Wuerttemburg',
	// 	value: 'Wuerttemburg',
	// },
	// {
	// 	label: 'Yugoslavia',
	// 	value: 'Yugoslavia',
	// },
	{
		label: 'Anguilla',
		value: 'Anguilla',
	},
	{
		label: 'Antigua and Barbuda',
		value: 'Antigua and Barbuda',
	},
	{
		label: 'Aruba',
		value: 'Aruba',
	},
	{
		label: 'Bahamas',
		value: 'Bahamas',
	},
	{
		label: 'Barbados',
		value: 'Barbados',
	},
	{
		label: 'Belize',
		value: 'Belize',
	},
	{
		label: 'Bermuda',
		value: 'Bermuda',
	},
	{
		label: 'Bonaire Sint Eustatius and Saba',
		value: 'Bonaire Sint Eustatius and Saba',
	},
	{
		label: 'British Virgin Islands',
		value: 'British Virgin Islands',
	},
	{
		label: 'Canada',
		value: 'Canada',
	},
	{
		label: 'Cayman Islands',
		value: 'Cayman Islands',
	},
	{
		label: 'Costa Rica',
		value: 'Costa Rica',
	},
	{
		label: 'Cuba',
		value: 'Cuba',
	},
	{
		label: 'Curacao',
		value: 'Curacao',
	},
	{
		label: 'Dominica',
		value: 'Dominica',
	},
	{
		label: 'Dominican Republic',
		value: 'Dominican Republic',
	},
	{
		label: 'El Salvador',
		value: 'El Salvador',
	},
	{
		label: 'Greenland',
		value: 'Greenland',
	},
	{
		label: 'Grenada',
		value: 'Grenada',
	},
	{
		label: 'Guadeloupe',
		value: 'Guadeloupe',
	},
	{
		label: 'Guatemala',
		value: 'Guatemala',
	},
	{
		label: 'Haiti',
		value: 'Haiti',
	},
	{
		label: 'Honduras',
		value: 'Honduras',
	},
	{
		label: 'Jamaica',
		value: 'Jamaica',
	},
	{
		label: 'Martinique',
		value: 'Martinique',
	},
	{
		label: 'Mexico',
		value: 'Mexico',
	},
	{
		label: 'Montserrat',
		value: 'Montserrat',
	},
	{
		label: 'Netherlands Antilles',
		value: 'Netherlands Antilles',
	},
	{
		label: 'Nicaragua',
		value: 'Nicaragua',
	},
	{
		label: 'Panama',
		value: 'Panama',
	},
	{
		label: 'Puerto Rico',
		value: 'Puerto Rico',
	},
	{
		label: 'Saint Barthelemy',
		value: 'Saint Barthelemy',
	},
	{
		label: 'Saint Kitts and Nevis',
		value: 'Saint Kitts and Nevis',
	},
	{
		label: 'Saint Lucia',
		value: 'Saint Lucia',
	},
	// {
	// 	label: 'Saint Martin (French part)',
	// 	value: 'Saint Martin (French part)',
	// },
	{
		label: 'Saint Pierre and Miquelon',
		value: 'Saint Pierre and Miquelon',
	},
	{
		label: 'Saint Vincent and the Grenadines',
		value: 'Saint Vincent and the Grenadines',
	},
	{
		label: 'Sint Maarten (Dutch part)',
		value: 'Sint Maarten (Dutch part)',
	},
	{
		label: 'Trinidad and Tobago',
		value: 'Trinidad and Tobago',
	},
	{
		label: 'Turks and Caicos Islands',
		value: 'Turks and Caicos Islands',
	},
	{
		label: 'United States',
		value: 'United States',
	},
	{
		label: 'United States Virgin Islands',
		value: 'United States Virgin Islands',
	},
	{
		label: 'Argentina',
		value: 'Argentina',
	},
	{
		label: 'Bolivia',
		value: 'Bolivia',
	},
	{
		label: 'Brazil',
		value: 'Brazil',
	},
	{
		label: 'Chile',
		value: 'Chile',
	},
	{
		label: 'Colombia',
		value: 'Colombia',
	},
	{
		label: 'Ecuador',
		value: 'Ecuador',
	},
	{
		label: 'Falkland Islands',
		value: 'Falkland Islands',
	},
	{
		label: 'French Guiana',
		value: 'French Guiana',
	},
	{
		label: 'Guyana',
		value: 'Guyana',
	},
	{
		label: 'Paraguay',
		value: 'Paraguay',
	},
	{
		label: 'Peru',
		value: 'Peru',
	},
	// {
	// 	label: 'South Georgia and the South Sandwich Islands',
	// 	value: 'South Georgia and the South Sandwich Islands',
	// },
	{
		label: 'Suriname',
		value: 'Suriname',
	},
	{
		label: 'Uruguay',
		value: 'Uruguay',
	},
	{
		label: 'Venezuela',
		value: 'Venezuela',
	},
	{
		label: 'American Samoa',
		value: 'American Samoa',
	},
	{
		label: 'Australia',
		value: 'Australia',
	},
	{
		label: 'Cook Islands',
		value: 'Cook Islands',
	},
	{
		label: 'Fiji',
		value: 'Fiji',
	},
	{
		label: 'French Polynesia',
		value: 'French Polynesia',
	},
	{
		label: 'Guam',
		value: 'Guam',
	},
	{
		label: 'Kiribati',
		value: 'Kiribati',
	},
	{
		label: 'Marshall Islands',
		value: 'Marshall Islands',
	},
	{
		label: 'Micronesia (country)',
		value: 'Micronesia (country)',
	},
	{
		label: 'Nauru',
		value: 'Nauru',
	},
	{
		label: 'New Caledonia',
		value: 'New Caledonia',
	},
	{
		label: 'New Zealand',
		value: 'New Zealand',
	},
	{
		label: 'Niue',
		value: 'Niue',
	},
	// {
	// 	label: 'Norfolk Island',
	// 	value: 'Norfolk Island',
	// },
	// {
	// 	label: 'Northern Mariana Islands',
	// 	value: 'Northern Mariana Islands',
	// },
	{
		label: 'Palau',
		value: 'Palau',
	},
	{
		label: 'Papua New Guinea',
		value: 'Papua New Guinea',
	},
	{
		label: 'Pitcairn',
		value: 'Pitcairn',
	},
	{
		label: 'Samoa',
		value: 'Samoa',
	},
	{
		label: 'Solomon Islands',
		value: 'Solomon Islands',
	},
	// {
	// 	label: 'Tokelau',
	// 	value: 'Tokelau',
	// },
	{
		label: 'Tonga',
		value: 'Tonga',
	},
	{
		label: 'Tuvalu',
		value: 'Tuvalu',
	},
	{
		label: 'United States Minor Outlying Islands',
		value: 'United States Minor Outlying Islands',
	},
	{
		label: 'Vanuatu',
		value: 'Vanuatu',
	},
	// {
	// 	label: 'Wallis and Futuna',
	// 	value: 'Wallis and Futuna',
	// },
];

export const organizationSize = [
	{
		label: '0-50',
		value: '0-50',
	},
	{
		label: '50-500',
		value: '50-500',
	},
	{
		label: '500-5000',
		value: '500-5000',
	},
	{
		label: '5000+',
		value: '5000+',
	},
];

export const annualRevenue = [
	{
		label: '$1 Million - $10 Million',
		value: '$1 Million - $10 Million',
	},
	{
		label: '$10 Million - $50 Million',
		value: '$10 Million - $50 Million',
	},
	{
		label: '$50 Million - $300 Million',
		value: '$50 Million - $300 Million',
	},
	{
		label: '$300 million+',
		value: '$300 million+',
	},
];

export const tovMap = {
	Analytical: {
		'0': {
			name: 'Descriptive Observation',
			description:
				'Primarily descriptive, straightforward, focusing on information presentation.',
		},
		'20': {
			name: 'Selective Insight',
			description:
				'Offers insights with a focus on significant aspects, engaging and semi-analytical.',
		},
		'40': {
			name: 'Focused Examination',
			description:
				'Moderately analytical, targets key aspects with clear and concise analysis.',
		},
		'60': {
			name: 'Comprehensive Assessment',
			description:
				'Thorough evaluation from multiple perspectives, well-reasoned and balanced.',
		},
		'80': {
			name: 'Rigorous Investigation',
			description:
				'Deep, methodical analysis, probing for patterns and connections, highly precise.',
		},
		'100': {
			name: 'Meticulous Scrutiny',
			description:
				'Extremely detailed analysis, breaking down complex ideas systematically.',
		},
	},
	Authoritative: {
		'0': {
			name: 'Passive Observation',
			description:
				'Minimal authority, neutral tone, presenting observations without strong stances.',
		},
		'20': {
			name: 'Suggestive Guidance',
			description:
				'Slight authority, offers advice and leaves room for interpretation, advisory tone.',
		},
		'40': {
			name: 'Informed Opinion',
			description:
				'Moderate authority, presenting informed opinions, assertive yet open for discussion.',
		},
		'60': {
			name: 'Credible Professionalism',
			description:
				'Professional, logical, using industry terminology, inspires trust and objectivity.',
		},
		'80': {
			name: 'Confident Expertise',
			description:
				'High authority with deep knowledge, persuasive and backed by credible evidence.',
		},
		'100': {
			name: 'Commanding Authority',
			description:
				'Extremely authoritative, assertive, presenting ideas as definitive facts.',
		},
	},
	Concise: {
		'0': {
			name: 'Loquacious Verbosity',
			description:
				'Extremely verbose, using many words and complex sentences, often repetitive.',
		},
		'20': {
			name: 'Expansive Exposition',
			description:
				'Verbose and expansive, providing extensive context and descriptions.',
		},
		'40': {
			name: 'Detailed Elaboration',
			description:
				'Comprehensive and detailed, offering in-depth explanations and perspectives.',
		},
		'60': {
			name: 'Economical Expression',
			description:
				'Concise and economical, avoids redundancy, focusing on core messages.',
		},
		'80': {
			name: 'Succinct Precision',
			description:
				'Highly concise and precise, using carefully chosen words for efficient communication.',
		},
		'100': {
			name: 'Laser-Focused Brevity',
			description:
				'Extremely concise, using the fewest words for essential messages, very direct.',
		},
	},
	Emotive: {
		'0': {
			name: 'Objective Detachment',
			description:
				'Emotionally neutral, objective, focusing on data without bias.',
		},
		'20': {
			name: 'Persuasive Appeal',
			description:
				'Uses emotional appeals to persuade, combining logic with emotive language.',
		},
		'40': {
			name: 'Engaging Narrative',
			description:
				'Draws readers in emotionally with stories and anecdotes, compelling and relatable.',
		},
		'60': {
			name: 'Vivid Imagery',
			description:
				'Employs vivid, sensory-rich imagery to evoke emotions, uses descriptive details.',
		},
		'80': {
			name: 'Heartfelt Sincerity',
			description:
				'Genuine emotions, expresses personal beliefs and values with warmth and empathy.',
		},
		'100': {
			name: 'Enthusiastic Expression',
			description:
				'Intensely emotive, uses powerful language to stir emotions, fervent and possibly confrontational.',
		},
	},
};
