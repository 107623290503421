const Pencil = () => {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_6707_12100"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="21"
			>
				<rect y="0.5" width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_6707_12100)">
				<path
					d="M4.16667 16.3333H5.35417L13.5 8.1875L12.3125 7L4.16667 15.1458V16.3333ZM2.5 18V14.4583L13.5 3.47917C13.6667 3.32639 13.8507 3.20833 14.0521 3.125C14.2535 3.04167 14.4653 3 14.6875 3C14.9097 3 15.125 3.04167 15.3333 3.125C15.5417 3.20833 15.7222 3.33333 15.875 3.5L17.0208 4.66667C17.1875 4.81944 17.309 5 17.3854 5.20833C17.4618 5.41667 17.5 5.625 17.5 5.83333C17.5 6.05556 17.4618 6.26736 17.3854 6.46875C17.309 6.67014 17.1875 6.85417 17.0208 7.02083L6.04167 18H2.5ZM12.8958 7.60417L12.3125 7L13.5 8.1875L12.8958 7.60417Z"
					fill="#5D9BFD"
				/>
			</g>
		</svg>
	);
};

export default Pencil;
