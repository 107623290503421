import { FC, useState } from 'react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import CommentComponent from '../CommentComponent/CommentComponent';
import CommentBox from '../CommentBox/CommentBox';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { createCommentOnSpecificSection } from '@/lib/functions/apiCalls';
import { Comment } from '@/lib/types/apiTypes';
import { motion } from 'framer-motion';
import { mCSlideIn } from '@/constants/variants';
import styles from './CommentCard.module.css';
import { cn } from '@/lib/utils';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';

interface CommentCardProps {
	proposalId: number;
	sectionId: number;
	comment: Comment;
	alias: string;
	fetchComments: (countUpdated?: boolean) => void;
	setLoading: (loading: boolean) => void;
}

const CommentCard: FC<CommentCardProps> = ({
	proposalId,
	sectionId,
	comment,
	alias,
	fetchComments,
	setLoading,
}) => {
	const dispatch = useAppDispatch();
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const createCommentReply = async (commentReply, commentId) => {
		try {
			setLoading(true);
			const response = await createCommentOnSpecificSection(
				Number(proposalId),
				Number(sectionId),
				[commentReply],
				Number(commentId)
			);
			handleResponse(response);
			setLoading(false);
			fetchComments(true);
		} catch (error: unknown) {
			setLoading(false);
			ErrorHandle(dispatch, error);
		}
	};

	return (
		<motion.div
			key={alias || comment.id}
			className={cn('flex flex-col p-3 border-y border-y-[#D3DDE2]')}
			variants={mCSlideIn}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			<CommentComponent
				proposalId={proposalId}
				sectionId={sectionId}
				comment={comment}
				type={'comment'}
				alias={`comment-${comment.id}`}
				fetchComments={fetchComments}
				setEditing={(isEditing) => setIsEditing(isEditing)}
				setLoading={setLoading}
			/>
			{!!comment?.replies?.length && (
				<div className="">
					<TextButton
						onClick={() => setIsExpanded(!isExpanded)}
						className="h-auto 2xl:h-auto p-0"
					>
						{isExpanded
							? 'Close Replies'
							: `See All Replies (${comment.replies.length})`}
					</TextButton>
				</div>
			)}
			<div
				className={cn(
					styles.transition,
					isExpanded ? 'max-h-[5000px] opacity-1' : 'max-h-0 opacity-0 z-[-1]'
				)}
			>
				{comment?.replies?.map((reply, index) => (
					<CommentComponent
						proposalId={proposalId}
						sectionId={sectionId}
						comment={reply}
						type={'commentReply'}
						alias={`commentReply-${index}`}
						fetchComments={fetchComments}
						setEditing={(isEditing) => setIsEditing(isEditing)}
						setLoading={setLoading}
					/>
				))}
			</div>
			<CommentBox
				onSubmit={(commentReply) =>
					createCommentReply(commentReply, comment.id)
				}
				clearOnSubmit={true}
				placeholder="Reply here"
				className={cn(
					'p-0',
					styles.transition,
					!isEditing && (isExpanded || !comment?.replies?.length)
						? 'max-h-[5000px] opacity-1'
						: 'max-h-0 opacity-0 z-[-1]'
				)}
			></CommentBox>
		</motion.div>
	);
};

export default CommentCard;
