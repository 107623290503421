import { SVGProps } from 'react';

const Heart = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<mask
				id="mask0_817_9896"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_817_9896)">
				<path
					d="M12 20.3254C11.7667 20.3254 11.5292 20.2837 11.2875 20.2004C11.0458 20.1171 10.8333 19.9837 10.65 19.8004L8.925 18.2254C7.15833 16.6087 5.5625 15.0046 4.1375 13.4129C2.7125 11.8212 2 10.0671 2 8.15039C2 6.58372 2.525 5.27539 3.575 4.22539C4.625 3.17539 5.93333 2.65039 7.5 2.65039C8.38333 2.65039 9.21667 2.83789 10 3.21289C10.7833 3.58789 11.45 4.10039 12 4.75039C12.55 4.10039 13.2167 3.58789 14 3.21289C14.7833 2.83789 15.6167 2.65039 16.5 2.65039C18.0667 2.65039 19.375 3.17539 20.425 4.22539C21.475 5.27539 22 6.58372 22 8.15039C22 10.0671 21.2917 11.8254 19.875 13.4254C18.4583 15.0254 16.85 16.6337 15.05 18.2504L13.35 19.8004C13.1667 19.9837 12.9542 20.1171 12.7125 20.2004C12.4708 20.2837 12.2333 20.3254 12 20.3254ZM11.05 6.75039C10.5667 6.06706 10.05 5.54622 9.5 5.18789C8.95 4.82956 8.28333 4.65039 7.5 4.65039C6.5 4.65039 5.66667 4.98372 5 5.65039C4.33333 6.31706 4 7.15039 4 8.15039C4 9.01706 4.30833 9.93789 4.925 10.9129C5.54167 11.8879 6.27917 12.8337 7.1375 13.7504C7.99583 14.6671 8.87917 15.5254 9.7875 16.3254C10.6958 17.1254 11.4333 17.7837 12 18.3004C12.5667 17.7837 13.3042 17.1254 14.2125 16.3254C15.1208 15.5254 16.0042 14.6671 16.8625 13.7504C17.7208 12.8337 18.4583 11.8879 19.075 10.9129C19.6917 9.93789 20 9.01706 20 8.15039C20 7.15039 19.6667 6.31706 19 5.65039C18.3333 4.98372 17.5 4.65039 16.5 4.65039C15.7167 4.65039 15.05 4.82956 14.5 5.18789C13.95 5.54622 13.4333 6.06706 12.95 6.75039C12.8333 6.91706 12.6917 7.04206 12.525 7.12539C12.3583 7.20872 12.1833 7.25039 12 7.25039C11.8167 7.25039 11.6417 7.20872 11.475 7.12539C11.3083 7.04206 11.1667 6.91706 11.05 6.75039Z"
					fill="#D3DDE2"
				/>
			</g>
		</svg>
	);
};

export default Heart;
