const Organization = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="29"
			height="34"
			viewBox="0 0 29 34"
			fill="none"
		>
			<path
				d="M26.8898 7.03189C26.8898 4.36655 24.5966 2.20586 21.7679 2.20586C18.9391 2.20586 16.646 4.36655 16.646 7.03189V19.097C16.646 21.7623 18.9391 23.923 21.7679 23.923C24.5966 23.923 26.8898 21.7623 26.8898 19.097V7.03189Z"
				stroke="white"
				strokeWidth="2.56094"
			/>
			<path
				d="M11.5243 7.03189C11.5243 4.36655 9.23113 2.20586 6.4024 2.20586C3.57366 2.20586 1.28052 4.36655 1.28052 7.03189V27.1404C1.28052 29.8057 3.57366 31.9664 6.4024 31.9664C9.23113 31.9664 11.5243 29.8057 11.5243 27.1404V7.03189Z"
				stroke="white"
				strokeWidth="2.56094"
			/>
		</svg>
	);
};

export default Organization;
