import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	ComplianceMatrix,
	ComplianceMatrixPayload,
	ComplianceRule,
} from '@/lib/types/apiTypes';
import {
	deleteComplianceMatrixRule,
	getProposalCompliance,
	getSectionComplianceRules,
	runComplianceMatrix,
	triggerComplianceAssociation,
	updateComplianceMatrix,
} from '@/lib/functions/apiCalls';
import customToast from '@/components/CustomToast/CustomToast';
import { fetchProposalById } from '@/redux/slices/proposalSlice';

// Async thunk for fetching compliance matrix
export const fetchComplianceMatrix = createAsyncThunk(
	'matrix/fetchComplianceMatrix',
	async (proposalId: number, { rejectWithValue }) => {
		try {
			const response = await getProposalCompliance(proposalId);
			const matrix = (response.data as any)
				.complianceMatrix as ComplianceMatrix;

			// Check if the matrix is null
			if (!matrix) {
				return rejectWithValue('Error fetching compliance matrix');
			}

			// Check if the matrix is processing
			if (matrix.status === 'processing') {
				return { ...matrix, loading: true };
			}

			if (matrix.status === 'errored') {
				return { ...matrix, loading: false };
			}

			return { ...matrix, loading: false };
		} catch (error) {
			return rejectWithValue('Error fetching compliance matrix');
		}
	}
);

export const runAndFetchComplianceMatrix = createAsyncThunk(
	'matrix/runAndFetchComplianceMatrix',
	async (
		{
			proposalId: proposalId,
			sectionId,
			complianceId,
		}: { proposalId: number; sectionId: number; complianceId: number },
		{ dispatch, rejectWithValue }
	) => {
		try {
			await runComplianceMatrix(proposalId, complianceId, sectionId);
			dispatch(fetchProposalById(proposalId));
			dispatch(fetchSectionRules({ proposalId, sectionId }));

			customToast.success({
				title: 'Compliance matrix run successfully',
			});
		} catch (error) {
			customToast.error({
				title: 'Error running compliance matrix',
			});
			return rejectWithValue('Error running compliance matrix');
		}
	}
);

export const fetchSectionRules = createAsyncThunk(
	'matrix/fetchSectionRules',
	async (
		{ proposalId, sectionId }: { proposalId: number; sectionId: number },
		{ rejectWithValue }
	) => {
		try {
			const response = await getSectionComplianceRules(proposalId, sectionId);
			const rules = response.data as ComplianceRule[];
			// eslint-disable-next-line
			//@ts-ignore
			return { sectionId, rules: rules.sectionComplianceRules };
		} catch (error) {
			return rejectWithValue('Error fetching section rules');
		}
	}
);

export const updateMatrix = createAsyncThunk(
	'matrix/updateMatrix',
	async (
		{
			proposalId,
			complianceId,
			ruleId,
			payload,
			mode,
			sectionId,
		}: {
			proposalId: number;
			complianceId: number;
			ruleId: number;
			payload: ComplianceMatrixPayload;
			mode?: 'section' | 'proposal';
			sectionId?: number;
		},
		{ dispatch, rejectWithValue }
	) => {
		try {
			const response = await updateComplianceMatrix(
				proposalId,
				complianceId,
				ruleId,
				payload
			);
			if (response.statusCode === 200) {
				customToast.success({
					title: response.message,
				});

				if (mode === 'section') {
					if (!sectionId) return;
					dispatch(fetchSectionRules({ proposalId, sectionId: sectionId }));
				} else {
					dispatch(fetchComplianceMatrix(proposalId));
				}
			}

			return { loading: false };
		} catch (error) {
			return rejectWithValue('Error updating compliance matrix');
		}
	}
);

export const pollComplianceMatrix = createAsyncThunk<
	ComplianceMatrix,
	{ proposalId: number; maxRetries?: number; interval?: number },
	{ rejectValue: string }
>(
	'matrix/pollComplianceMatrix',
	async (
		{ proposalId, maxRetries = 3, interval = 30000 },
		{ rejectWithValue }
	) => {
		let retries = 0;

		const poll = async (): Promise<ComplianceMatrix> => {
			try {
				const response = await getProposalCompliance(proposalId);
				const matrix = (response.data as any)
					.complianceMatrix as ComplianceMatrix;

				if (matrix.status === 'stagnant' || matrix.status === 'errored') {
					return matrix;
				} else if (retries >= maxRetries) {
					// eslint-disable-next-line
					//@ts-ignore
					return rejectWithValue('Polling timed out');
				} else {
					retries++;
					await new Promise((resolve) => setTimeout(resolve, interval));
					return poll();
				}
			} catch (error) {
				// eslint-disable-next-line
				//@ts-ignore
				return rejectWithValue('Error fetching compliance matrix');
			}
		};

		try {
			const result = await poll();
			return result;
		} catch (error) {
			// eslint-disable-next-line
			//@ts-ignore
			return rejectWithValue(error.message);
		}
	}
);

export const triggerCompliance = createAsyncThunk(
	'matrix/triggerCompliance',
	async (
		{ proposalId, complianceId }: { proposalId: number; complianceId: number },
		{ dispatch, rejectWithValue }
	) => {
		try {
			const response = await triggerComplianceAssociation(
				proposalId,
				complianceId
			);
			if (response.statusCode === 200) {
				dispatch(pollComplianceMatrix({ proposalId }));
			}
		} catch (error) {
			return rejectWithValue('Error triggering compliance matrix');
		}
	}
);

export const deleteMatrixRule = createAsyncThunk(
	'matrix/deleteMatrixRule',
	async (
		{
			proposalId,
			complianceId,
			ruleId,
			sectionId,
			mode,
		}: {
			proposalId: number;
			complianceId: number;
			ruleId: number;
			sectionId?: number;
			mode?: 'section' | 'proposal';
		},
		{ dispatch, rejectWithValue }
	) => {
		try {
			const response = await deleteComplianceMatrixRule(
				proposalId,
				complianceId,
				ruleId
			);
			if (response.statusCode === 200) {
				customToast.success({
					title: 'Compliance matrix rule deleted successfully',
				});
				// Fetch the updated matrix after a successful update
				if (mode === 'section' && sectionId) {
					dispatch(fetchSectionRules({ proposalId, sectionId: sectionId }));
				} else {
					dispatch(fetchComplianceMatrix(proposalId));
				}
			}
			return { loading: false };
		} catch (error) {
			return rejectWithValue('Error deleting compliance matrix rule');
		}
	}
);
