import { FC } from 'react';
import Home from './Home';

import Setting from './Setting';
import Price from './Price';
import Organization from './Organization';
import Login from './Login';
import Info from './Info';
import File from './File';
import Documents from './Documents';
import Logout from './Logout';
import Active from './Active';
import TemplateBuilderIcon from './TemplateBuilderIcon';
import DiscoverIcon from './Discover';
import Help from './new/Help';

interface IconsProps {
	type: string;
}

const Icons: FC<IconsProps> = ({ type }) => {
	switch (type) {
		case 'home':
			return <Home />;
		case 'doc':
			return <Documents />;
		case 'discover':
			return <DiscoverIcon className="w-[18px]" />;
		case 'file':
			return <File />;
		case 'info':
			return <Info />;
		case 'login':
			return <Login />;
		case 'org':
			return <Organization />;
		case 'dollar':
			return <Price />;
		case 'setting':
			return <Setting />;
		case 'logout':
			return <Logout />;
		case 'proposal':
			return <Active />;
		case 'template':
			return <TemplateBuilderIcon />;
		case 'help':
			return <Help />;
		default:
			<></>;
	}
};

export default Icons;
