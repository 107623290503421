import { updateUserTour } from '@/lib/functions/apiCalls';
import { Page } from './types';
import store from '@/redux/store/store';
import { updateProfile } from '@/redux/slices/authSlice';
import { User } from '@/lib/types/apiTypes';

export async function checkTourCompleted(page: Page, user?: User) {
	try {
		if (!user) return false;

		return Page[page] === undefined
			? true
			: user.onboarding.includes(Page[page]);
	} catch (error) {
		console.log(error);
	}
}

export async function markTourCompleted(page: Page, user?: User) {
	try {
		if (!user) return;
		if (!user.onboarding.includes(Page[page])) {
			const updatedOnboarding = [...user.onboarding, Page[page]];
			const updatedUser: User = { ...user, onboarding: updatedOnboarding };
			await updateUserTour(user.id, updatedOnboarding);
			store.dispatch(updateProfile({ user: updatedUser }));
		}
	} catch (error) {
		console.log(error);
	}
}
