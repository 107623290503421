import customToast from '@/components/CustomToast/CustomToast';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { deleteDocumentById } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

const DeleteDocument = () => {
	const dispatch = useAppDispatch();
	const isOpen = useSelector(
		(state: any) => state.page.dialog?.type === 'deleteDocument'
	);
	const documentId = useSelector((state: any) => state.page.dialog?.documentId);
	const documentName = useSelector(
		(state: any) => state.page.dialog?.documentName
	);
	const queryClient = useQueryClient();

	const handleDelete = async () => {
		if (!documentId) return;
		const response = await deleteDocumentById(documentId);
		if (response) {
			customToast.success({
				title: 'Document deleted successfully',
			});
		}
		queryClient.invalidateQueries('documents');
		dispatch(closeDialog());
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[400px] p-6 bg-white rounded-xl">
				<DialogHeader className="font-bold text-secondary dynamic-large">
					Delete Document
				</DialogHeader>
				<p className="text-black">
					<p className="mb-2">
						You are deleting{' '}
						<span className="mx-1 font-bold text-secondary">
							{documentName}
						</span>
					</p>
					This action will also permanently remove the document from the
					proposals it is attached to. Consequently, any related write-ups will
					no longer include content from this document.
				</p>
				<div className="flex items-center justify-end">
					<PrimaryButton onClick={handleDelete}>Delete</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteDocument;
