import { SVGProps } from 'react';

const Edit = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
		>
			<path
				d="M2 16.8647H3.425L13.2 7.08975L11.775 5.66475L2 15.4397V16.8647ZM0 18.8647V14.6147L13.2 1.43975C13.4 1.25641 13.6208 1.11475 13.8625 1.01475C14.1042 0.914746 14.3583 0.864746 14.625 0.864746C14.8917 0.864746 15.15 0.914746 15.4 1.01475C15.65 1.11475 15.8667 1.26475 16.05 1.46475L17.425 2.86475C17.625 3.04808 17.7708 3.26475 17.8625 3.51475C17.9542 3.76475 18 4.01475 18 4.26475C18 4.53141 17.9542 4.78558 17.8625 5.02725C17.7708 5.26891 17.625 5.48975 17.425 5.68975L4.25 18.8647H0ZM12.475 6.38975L11.775 5.66475L13.2 7.08975L12.475 6.38975Z"
				fill="#6279E6"
			/>
		</svg>
	);
};

export default Edit;
