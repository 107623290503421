import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { FC, useEffect, useState, useRef } from 'react';
import styles from './ApproachView.module.css';
// import DotsIndicator from '@/components/DotsIndicator/DotsIndicator';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	answerSpecificQuestion,
	updateProposalJourneyStatus,
} from '@/lib/functions/apiCalls';
import { nextNofo, setCurrentStepQuestion } from '@/redux/slices/questionSlice';
import {
	fetchProposalById,
	pageTransitionEnd,
	pageTransitionStart,
	updateStep,
} from '@/redux/slices/proposalSlice';
import { motion } from 'framer-motion';
import {
	prTextSlideIn,
	prInputSlideIn,
	prInputSlideInDelay,
	prContainerExit,
} from '@/constants/variants';
import { LottieRefCurrentProps } from 'lottie-react';
import CustomEditor from '@/components/CustomEditor/CustomEditor';
import {
	getToken,
	getWordCount,
	typeWithInterval,
} from '@/lib/functions/funcUtils';
import { Question } from '@/lib/types/apiTypes';
import { Skeleton } from '@/components/ui/skeleton';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import AiIcon from '@/components/ui/icons/AiIcon';
import React from 'react';
import useResponseHandler from '@/lib/hooks/useResponseHandler';
import { useNavigate } from 'react-router';
import { openDialog } from '@/redux/slices/pageSlice';
import useLogicalFrameEnabled from '@/lib/hooks/useLogicalFrameEnabled';

interface ApproachViewProps {
	currentStep: number;
	currentNofo: number;
	currentQuestion: number;
	setCurrentQuestion: (question: number) => void;
	pageTransitionLoad: boolean;
}

const ApproachView: FC<ApproachViewProps> = ({
	currentStep,
	currentNofo,
	currentQuestion,
	setCurrentQuestion,
	pageTransitionLoad = true,
}) => {
	const badgeLottieRef = useRef<LottieRefCurrentProps>(null);
	const dispatch = useAppDispatch();
	const questions = useSelector((root: RootState) => root.question.questions);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const isLogicalFrameEnabled = useLogicalFrameEnabled();
	const [currentAnswer, setCurrentAnswer] = useState('');
	const [answerStreaming, setAnswerStreaming] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [intervalId, setIntervalId] = useState<ReturnType<
		typeof setInterval
	> | null>(null);
	const questionStreaming = useSelector(
		(root: RootState) => root.question.questionStreaming
	);

	const [questionText, setQuestionText] = useState('');
	const navigate = useNavigate();

	const dataRef = useRef<string | null>('');
	const answerRef = useRef<HTMLDivElement>(null);
	const token = getToken();

	const saveAnswerAndGetNextQuestion = async () => {
		try {
			// If it's NOT the last question, update the current question
			if (currentQuestion < questions.length - 1) {
				setCurrentQuestion(currentQuestion + 1);
			}
			await answerSpecificQuestion(
				proposal?.id as number,
				questions[currentQuestion].id,
				currentAnswer
			);

			// Check if it is the last question
			if (currentQuestion === questions.length - 1) {
				// Call the API here
				// call dialog
				await updateProposalJourneyStatus(proposal?.id as number, true);
				await fetchProposalById(proposal?.id as number);
				if (isLogicalFrameEnabled) {
					dispatch(openDialog({ type: 'openLogicalFrame' }));
				} else {
					navigate(`/proposal/${proposal?.id}`);
				}
			} else {
				// Only fetch the next question if it's not the last one.
				dispatch(
					setCurrentStepQuestion(
						proposal?.questions[currentQuestion + 1] as Question
					)
				);
				dispatch(nextNofo());
			}
			setCurrentAnswer('');
		} catch (error) {
			if (error instanceof Error) {
				console.error('Error creating proposal:', error.message);
				setIsLoading(false);
			} else {
				console.error('An unexpected error occurred:', error);
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		// Disable button if streaming, otherwise enable it based on the answer's content
		setIsButtonDisabled(questionStreaming || currentAnswer.trim() === '');
	}, [currentAnswer]);

	useEffect(() => {
		const token = getToken();
		if (proposal === null || token === null) {
			return;
		}

		const question = proposal.questions.find(
			(quesiton) => quesiton?.id === currentNofo
		);
		if (question?.text) {
			setQuestionText(question?.text);
		}
		dispatch(pageTransitionEnd());
	}, [currentNofo, proposal, dispatch]);

	const handleNextClick = async () => {
		setQuestionText('');
		dataRef.current = '';
		if (currentQuestion < questions.length - 1) {
			dispatch(pageTransitionStart());
		} else {
			dispatch(updateStep(currentStep + 1));
			dispatch(pageTransitionStart());
		}
		setIsButtonDisabled(true);
		await saveAnswerAndGetNextQuestion();
		if (answerRef.current) {
			answerRef.current.textContent = '';
		}
		setIsLoading(true);
		if (currentQuestion < questions.length - 1) {
			badgeLottieRef?.current?.goToAndPlay(0);
		}
		try {
			dispatch(pageTransitionEnd());
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			dispatch(pageTransitionEnd());
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (currentAnswer.trim() === '') {
			setIsButtonDisabled(true);
		} else {
			setIsButtonDisabled(false);
		}
	}, [currentAnswer]);

	useEffect(() => {
		if (!questions?.[0]?.id) {
			navigate('/solicitations');
		}
	}, [questions, navigate]);

	const handleInputChange = (e: React.FormEvent<HTMLDivElement>) => {
		const newAnswer = e.currentTarget.textContent || '';
		setCurrentAnswer(newAnswer);

		// Update the button's disabled state based on the new answer and streaming status
		setIsButtonDisabled(questionStreaming || newAnswer.trim() === '');
	};

	const [handleSuggestResponse] = useResponseHandler({
		token,
		proposalId: proposal?.id,
		questionId: currentNofo,
		onInit: () => {
			setAnswerStreaming(true);
			if (answerRef?.current) {
				answerRef.current.textContent = '';
			}

			const interval = typeWithInterval(
				answerRef,
				'Hold on tight while I write this response',
				10
			);
			setIntervalId(interval);
		},
		onStart: () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
			if (answerRef?.current) {
				answerRef.current.textContent = '';
				setCurrentAnswer(answerRef?.current?.textContent || '');
			}
		},
		onMessage: (message: string) => {
			if (answerRef?.current) {
				answerRef.current.textContent += message;
				setCurrentAnswer(answerRef?.current?.textContent || '');
			}
		},
		onEnd: () => {
			setAnswerStreaming(false);
			setCurrentAnswer(answerRef?.current?.textContent || '');
		},
		onError: () => {
			setAnswerStreaming(false);
			setCurrentAnswer(answerRef?.current?.textContent || '');
		},
	});

	const wordCount = getWordCount(answerRef.current?.textContent || '');
	const isWordCountValid = wordCount > 300;
	const [isLoading, setIsLoading] = useState(false);
	const questionParts = questionText?.split(':');
	return (
		<motion.div
			className="max-w-[1400px] w-full  mx-auto  flex flex-col items-center justify-center gap-5"
			key="prContainer"
			variants={prContainerExit}
			exit="exit"
		>
			<motion.div
				className="flex items-center justify-center gap-4"
				key="prText"
				variants={prTextSlideIn}
				initial="initial"
				animate="animate"
			>
				<h2 className="font-bold text-center text-black dynamic-xl-large">
					Tell us about your approach and win themes.
				</h2>

				<Badge className="px-4 py-1 text-white uppercase dynamic-small bg-secondary">
					Question {currentQuestion + 1}/{questions.length}
				</Badge>
			</motion.div>

			{pageTransitionLoad || isLoading ? (
				<div className="rounded-[24px] flex flex-col gap-4 items-start justify-between w-full">
					<Skeleton className="h-[57.5px] w-full rounded-xl bg-slate-300" />
					<Skeleton className="absolute m-3 h-[33px] w-6/12 px-12 rounded-xl bg-slate-50" />
					<Skeleton className="absolute w-10 h-10 mb-16 mr-4 right-4 rounded-xl bg-slate-50" />
				</div>
			) : (
				<motion.div
					className="flex flex-col items-center justify-between w-full gap-4 px-4 rounded-xl"
					key="prInput"
					variants={prInputSlideIn}
					initial="initial"
					animate="animate"
				>
					<div className="flex items-center justify-between w-full mt-4">
						<h2 className={'px-2  dynamic-medium font-bold text-black'}>
							{questionParts?.length === 2 ? (
								<>
									<span className="text-secondary">{questionParts[0]}:</span>
									{questionParts[1]}
								</>
							) : (
								questionText
							)}
						</h2>
					</div>
					<div className="flex items-center justify-between w-full bg-white rounded-xl shadow-main">
						<CustomEditor
							forwardedRef={answerRef}
							placeholder={
								answerStreaming
									? ''
									: 'Please provide a response to this question here'
							}
							onChange={(e) => handleInputChange(e)}
							isEditable={!pageTransitionLoad && !isLoading}
							className={cn(
								'p-4 bg-transparent z-10 w-full h-full text-black font-normal min-h-[52px] whitespace-pre-wrap',
								answerStreaming ? 'pointer-events-none' : ''
							)}
						/>
						<div
							className={cn(
								styles.box,
								'relative py-2 has-tooltip flex gap-2 border-l border-[#E3EEFF] items-center',
								answerStreaming ? 'cursor-ga opacity-50' : 'cursor-pointer'
							)}
						>
							<button
								className={cn(
									' w-72 h-full flex justify-center items-center text-primary font-bold hover:scale-105',
									answerStreaming ? 'pointer-events-none' : ''
								)}
								onClick={handleSuggestResponse}
							>
								<p className="flex items-center gap-2 suggest-response">
									<AiIcon className="w-5 h-5" /> SUGGEST A RESPONSE TO THIS
								</p>
							</button>
						</div>
					</div>
				</motion.div>
			)}
			<motion.div
				key="prInputDelay"
				variants={prInputSlideInDelay}
				initial="initial"
				animate="animate"
			>
				<p
					className={cn(
						'mb-2 dynamic-small text-center',
						isWordCountValid
							? 'text-[#FF82A0]'
							: 'text-[rgba(135,133,133,0.45)]'
					)}
				>
					{Math.abs(wordCount - 300)} word(s){' '}
					{isWordCountValid ? 'exceeds the limit' : 'left'}
				</p>

				<PrimaryButton
					onClick={handleNextClick}
					disabled={
						isButtonDisabled ||
						questionStreaming ||
						answerStreaming ||
						isWordCountValid
					}
				>
					Next
				</PrimaryButton>
			</motion.div>
		</motion.div>
	);
};

export default ApproachView;
