import { FC } from 'react';
import { Folder } from '@/lib/types/apiTypes';
import { ChevronLeft } from 'lucide-react';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cropText } from '@/lib/functions/funcUtils';

interface FoldersPathProps {
	foldersPath: Folder[];
	openFolder: (folderId: number | null) => void;
}

const FoldersPath: FC<FoldersPathProps> = ({ foldersPath, openFolder }) => {
	const fullFoldersPath = [
		{
			id: -1,
			name: 'Main Drive',
			status: 'active',
		},
	].concat(foldersPath);
	return (
		<div className="flex min-h-9">
			{fullFoldersPath.length > 0 && (
				<>
					{fullFoldersPath.length > 1 && (
						<div
							className="flex bg-primary hover:bg-secondary text-white rounded-md h-9 w-9 cursor-pointer justify-center items-center mr-4"
							onClick={() =>
								!fullFoldersPath[fullFoldersPath.length - 2]?.id ||
								fullFoldersPath[fullFoldersPath.length - 2]?.id === -1
									? openFolder(null)
									: openFolder(fullFoldersPath[fullFoldersPath.length - 2].id)
							}
						>
							<ChevronLeft size={16} />
						</div>
					)}
					{fullFoldersPath.map((folder, index) => (
						<div className="flex" key={index}>
							<div
								className="font-bold content-center dynamic-large text-primary w-max"
								onClick={() => {
									fullFoldersPath.length - 1 !== index
										? folder.id === -1
											? openFolder(null)
											: openFolder(folder.id)
										: null;
								}}
							>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger
											className={`dynamic-large ${fullFoldersPath.length - 1 !== index ? 'text-primary cursor-pointer hover:text-secondary' : 'text-secondary'}`}
										>
											{cropText(folder.name, 15)}
										</TooltipTrigger>
										{folder.name.length > 15 && (
											<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
												{folder.name}
											</TooltipContent>
										)}
									</Tooltip>
								</TooltipProvider>
							</div>
							{fullFoldersPath.length - 1 !== index && (
								<div
									className={`font-bold content-center dynamic-large px-2 ${index === fullFoldersPath.length - 1 ? 'text-secondary' : 'text-primary'}`}
								>
									/
								</div>
							)}
						</div>
					))}
				</>
			)}
		</div>
	);
};

export default FoldersPath;
