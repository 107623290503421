import { Skeleton } from '@/components/ui/skeleton';
import styles from './TemplateCard.module.css';
import { Button } from '@/components/ui/button';

const TemplateCardSkeleton = () => {
	return (
		<div className={styles.cardSkeleton + ' shadow-main'}>
			<h4 className=" text-black font-bold leading-9 mb-4 min-h-[50px] xl:min-h-[80px] 2xl:min-h-[100px]">
				<Skeleton className="w-full h-8 mb-5 bg-slate-300" />
				<Skeleton className="w-3/12 h-8 mb-5 bg-slate-300" />
			</h4>
			<div className="flex flex-row gap-2 text-[#e861c9] font-bold mb-2">
				<Skeleton className="w-2/12 h-6 bg-slate-300 rounded-full" />
				<Skeleton className="w-2/12 h-6 bg-slate-300 rounded-full" />
			</div>
			<div className="bg-[#E9E9E9] h-[1px] border-none my-3"></div>
			<div className="text-xs font-normal text-black xl:text-sm 2xl:text-base">
				<Skeleton className="h-3.5 w-full bg-slate-300 mb-3" />
				<Skeleton className="h-3.5 w-full bg-slate-300 mb-3" />
				<Skeleton className="h-3.5 w-full bg-slate-300 mb-3" />
				<Skeleton className="h-3.5 w-6/12 bg-slate-300 mb-3" />
			</div>
			<div className="flex items-center justify-between mt-5">
				<Button className="bg-white text-white !rounded-main py-0 px-0 text-sm xl:text-base 2xl:text-lg font-medium">
					<Skeleton className="!rounded-main h-10 w-48 bg-slate-300" />
				</Button>
			</div>
		</div>
	);
};

export default TemplateCardSkeleton;
