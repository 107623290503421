import { FC, useState } from 'react';
import TextButton from '../../ui/shared/Button/TextButton/TextButton';
import UserProfile from '../../UserProfile/UserProfile';
import { Comment } from '@/lib/types/apiTypes';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
} from '@/components/ui/select';
import DotMenu from '../../ui/icons/DotMenu';
import CommentBox from '../CommentBox/CommentBox';
import useUser from '@/lib/hooks/useUser';
import {
	deleteACommentFromASection,
	updateCommentOnSpecificSection,
} from '@/lib/functions/apiCalls';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import Tick from '@/components/ui/icons/new/Tick';
import useAuth from '@/lib/hooks/useAuth';

interface CommentComponentProps {
	proposalId: number;
	sectionId: number;
	comment: Comment;
	type: 'comment' | 'commentReply';
	alias: string;
	fetchComments: (countUpdated?: boolean) => void;
	setLoading: (loading: boolean) => void;
	setEditing?: (isEditing: boolean) => void;
}

const CommentComponent: FC<CommentComponentProps> = ({
	comment,
	proposalId,
	sectionId,
	type,
	alias,
	fetchComments,
	setLoading,
	setEditing,
}) => {
	const dispatch = useAppDispatch();
	const [isEditable, setIsEditable] = useState<boolean>(false);
	const user = useUser();
	const { isAdmin } = useAuth();

	const setEditable = (editable) => {
		setIsEditable(editable);
		setEditing && setEditing(editable);
	};

	const updateComment = async (commentId, payload) => {
		try {
			setLoading(true);
			const response = await updateCommentOnSpecificSection(
				Number(proposalId),
				Number(sectionId),
				Number(commentId),
				payload
			);
			if (payload.content) {
				handleResponse(response, 'Comment has been updated.');
			} else {
				handleResponse(
					response,
					`Comment marked as ${payload.isResolved ? 'resolved' : 'unresolved'}.`
				);
			}
			fetchComments();
			setEditable(false);
		} catch (error: unknown) {
			setLoading(false);
			ErrorHandle(dispatch, error);
		}
	};

	const deleteComment = async (commentId) => {
		try {
			setLoading(true);
			const response = await deleteACommentFromASection(
				Number(proposalId),
				Number(sectionId),
				Number(commentId)
			);
			handleResponse(response, 'Comment has been deleted successfully.');
			fetchComments(true);
		} catch (error: unknown) {
			setLoading(false);
			ErrorHandle(dispatch, error);
		}
	};

	const isUserCreator = user?.id === comment.createdBy;

	return (
		<div
			className="flex flex-col gap-2 2xl:gap-3 py-2"
			key={alias || comment.id}
		>
			<div className="flex justify-between">
				<UserProfile
					name={comment.user?.name || 'Deleted User'}
					profilePicture={comment.user?.profilePicture}
					designation={comment.user?.designation}
				/>
				<div className="flex">
					{(isUserCreator || (isAdmin && !comment?.user?.name)) && (
						<>
							{type === 'comment' && isUserCreator && (
								<TextButton
									className="item-center gap-2"
									onClick={() =>
										updateComment(comment.id, {
											isResolved: !comment.isResolved,
										})
									}
								>
									{comment.isResolved ? (
										<>
											<Tick /> Resolved
										</>
									) : (
										<>Mark Resolved</>
									)}
								</TextButton>
							)}
							<Select
								value={''}
								onValueChange={(value) => {
									switch (value) {
										case 'edit':
											setEditable(true);
											break;
										case 'delete':
											deleteComment(comment.id);
											break;
									}
								}}
							>
								<SelectTrigger className="p-0 pb-1 my-auto ml-4 bg-white border-0 cursor-pointer [&>*:nth-child(2)]:hidden">
									<DotMenu className="h-4 w-4" fill="#5D9BFD" />
								</SelectTrigger>
								<SelectContent className="bg-white rounded-2xl py-2">
									{isUserCreator && (
										<SelectItem
											className="text-black px-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary capitalize"
											value="edit"
										>
											Edit
										</SelectItem>
									)}
									<SelectItem
										className="text-black pl-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#ff388c] hover:text-white capitalize"
										value="delete"
									>
										Delete
									</SelectItem>
								</SelectContent>
							</Select>
						</>
					)}
				</div>
			</div>
			<div className="flex">
				{isEditable ? (
					<CommentBox
						defaultValue={comment.content && comment.content[0]}
						onSubmit={(commentText) =>
							updateComment(comment.id, { content: [commentText] })
						}
						className="p-0 w-full"
					/>
				) : (
					<p className="text-black text-base break-words overflow-hidden">
						{comment.content && comment.content[0]}
					</p>
				)}
			</div>
		</div>
	);
};

export default CommentComponent;
