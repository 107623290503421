import { Sheet, SheetContent } from '@/components/ui/sheet';
import useProposalId from '@/lib/hooks/useProposalId';
import Chat from '@/components/ui/icons/Chat';
import CommentsList from '@/components/Comment/CommentsList/CommentsList';
import CommentBox from '@/components/Comment/CommentBox/CommentBox';
import {
	createCommentOnSpecificSection,
	getCommentBySection,
} from '@/lib/functions/apiCalls';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { FC, useEffect, useState } from 'react';
import LoadingComment from '@/assets/lotties/loading-comment.json';
import LoadingLogo from '@/assets/lotties/logo_loading_v1.json';
import Lottie from 'lottie-react';
import { Comment } from '@/lib/types/apiTypes';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';

interface CommentsDrawerProps {}

const CommentsDrawer: FC<CommentsDrawerProps> = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.drawer?.type === 'viewComments'
	);
	const dispatch = useAppDispatch();
	const [commentFetched, setCommentFetched] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isProposalDirty, setIsProposalDirty] = useState<boolean>(false);
	const [comments, setComments] = useState<Comment[] | null>(null);

	const { activeSection } = useActiveSection();
	const proposalId = useProposalId() as number;

	const nodeId = activeSection.sectionId;

	const fetchCommentsForSection = async (countUpdated = false) => {
		try {
			setIsLoading(true);
			const response = await getCommentBySection(
				Number(proposalId),
				Number(nodeId)
			);
			handleResponse(response);
			const comments = (response.data as any).comments;
			setComments(comments);
			setIsLoading(false);
			setCommentFetched(true);
			if (countUpdated) {
				setIsProposalDirty(true);
			}
		} catch (error: unknown) {
			setIsLoading(false);
			ErrorHandle(dispatch, error);
		}
	};

	const createComment = async (comment) => {
		try {
			setIsLoading(true);
			const response = await createCommentOnSpecificSection(
				Number(proposalId),
				Number(nodeId),
				[comment]
			);
			handleResponse(response, 'Comment has been added.');
			fetchCommentsForSection(true);
		} catch (error: unknown) {
			setIsLoading(false);
			ErrorHandle(dispatch, error);
		}
	};

	const handleDrawerOpen = () => {
		if (proposalId && nodeId) {
			setCommentFetched(false);
			fetchCommentsForSection();
		}
		setIsProposalDirty(false);
		setComments(null);
	};

	const handleDrawerClose = () => {
		if (isProposalDirty) {
			dispatch(fetchProposalById(proposalId));
		}
	};

	useEffect(() => {
		if (isOpen) {
			handleDrawerOpen();
		} else {
			handleDrawerClose();
		}
	}, [isOpen]);

	return (
		<Sheet open={isOpen}>
			<SheetContent className="flex flex-col 2xl:w-[23%] !max-w-[450px] p-0 bg-white gap-0">
				{commentFetched && isLoading && (
					<>
						<div className="absolute z-20 flex flex-col w-full h-full bg-transparent"></div>
						<div className="relative flex flex-col">
							<Lottie
								className="absolute top-[-2.4rem] xl:top-[-3.7rem] right-[-2rem] animation-lottie w-80 m-auto"
								animationData={LoadingComment}
								loop={true}
								autoPlay={true}
							/>
						</div>
					</>
				)}
				<div>
					<h2 className="border-b border-[#D3DDE2] dynamic-large flex items-center gap-2 font-bold text-secondary p-4">
						<Chat className="h-6" /> Comments
					</h2>
				</div>
				{commentFetched === false ? (
					<div className="flex flex-col items-center justify-center h-full gap-4">
						<Lottie
							className="block animation-lottie max-h-24 max-w-24"
							animationData={LoadingLogo}
							loop={true}
							autoPlay={true}
						/>
					</div>
				) : (
					nodeId && (
						<CommentsList
							proposalId={proposalId}
							sectionId={Number(nodeId)}
							comments={comments}
							fetchComments={fetchCommentsForSection}
							setLoading={(isLoading) => setIsLoading(isLoading)}
						></CommentsList>
					)
				)}
				<div>
					<CommentBox
						clearOnSubmit={true}
						className="w-full rounded-t-3xl shadow-main"
						onSubmit={(comment) => createComment(comment)}
					></CommentBox>
				</div>
			</SheetContent>
		</Sheet>
	);
};

export default CommentsDrawer;
