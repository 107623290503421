import { SVGProps } from 'react';

const AIWhiteNew = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="22"
			height="31"
			viewBox="0 0 22 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.3486 27.0251H13.1816C13.0076 27.0251 12.8408 26.956 12.7177 26.833C12.5947 26.71 12.5256 26.5431 12.5256 26.3691C12.5256 26.1951 12.5947 26.0283 12.7177 25.9053C12.8408 25.7822 13.0076 25.7131 13.1816 25.7131H14.3496C14.949 25.7123 15.5237 25.4739 15.9475 25.05C16.3713 24.6262 16.6098 24.0515 16.6106 23.4521V9.83912C16.6101 9.23954 16.3717 8.66465 15.9479 8.24059C15.524 7.81652 14.9492 7.57792 14.3496 7.57713H13.1816C13.0934 7.58057 13.0053 7.56617 12.9228 7.53478C12.8403 7.50339 12.7649 7.45566 12.7013 7.39445C12.6376 7.33325 12.587 7.25982 12.5524 7.17857C12.5178 7.09732 12.5 7.00993 12.5 6.92163C12.5 6.83332 12.5178 6.74593 12.5524 6.66468C12.587 6.58343 12.6376 6.51 12.7013 6.4488C12.7649 6.38759 12.8403 6.33986 12.9228 6.30847C13.0053 6.27708 13.0934 6.26268 13.1816 6.26613H14.3496C15.2969 6.26718 16.2051 6.64396 16.8749 7.3138C17.5448 7.98364 17.9215 8.89183 17.9226 9.83912V23.4521C17.9215 24.3994 17.5448 25.3076 16.8749 25.9774C16.2051 26.6473 15.2959 27.0241 14.3486 27.0251Z"
				fill="white"
			/>
			<path
				d="M21.3435 27.0735H20.1765C19.2292 27.0724 18.321 26.6956 17.6512 26.0258C16.9814 25.3559 16.6046 24.4477 16.6035 23.5005V9.88745C16.6046 8.94016 16.9814 8.03197 17.6512 7.36213C18.321 6.69229 19.2292 6.31551 20.1765 6.31445H21.3445C21.5185 6.31445 21.6854 6.38357 21.8084 6.50659C21.9314 6.62961 22.0005 6.79647 22.0005 6.97045C22.0005 7.14444 21.9314 7.31129 21.8084 7.43432C21.6854 7.55734 21.5185 7.62645 21.3445 7.62645H20.1765C19.5771 7.62725 19.0025 7.86571 18.5786 8.28956C18.1548 8.71341 17.9163 9.28804 17.9155 9.88745V23.5005C17.916 24.1 18.1544 24.6749 18.5783 25.099C19.0022 25.5231 19.5769 25.7617 20.1765 25.7625H21.3445C21.5185 25.7625 21.6854 25.8316 21.8084 25.9546C21.9314 26.0776 22.0005 26.2445 22.0005 26.4185C22.0005 26.5924 21.9314 26.7593 21.8084 26.8823C21.6854 27.0053 21.5185 27.0745 21.3445 27.0745"
				fill="white"
			/>
			<path
				d="M5.19214 23.23C4.37947 23.23 3.65114 23.0767 3.00714 22.77C2.37847 22.448 1.88014 22.0187 1.51214 21.482C1.15947 20.9453 0.983141 20.3397 0.983141 19.665C0.983141 18.837 1.19781 18.1853 1.62714 17.71C2.05647 17.2193 2.75414 16.8667 3.72014 16.652C4.68614 16.4373 5.98181 16.33 7.60714 16.33H8.75714V17.986H7.63014C6.67947 17.986 5.92047 18.032 5.35314 18.124C4.78581 18.216 4.37947 18.377 4.13414 18.607C3.90414 18.8217 3.78914 19.1283 3.78914 19.527C3.78914 20.033 3.96547 20.447 4.31814 20.769C4.67081 21.091 5.16147 21.252 5.79014 21.252C6.29614 21.252 6.74081 21.137 7.12414 20.907C7.52281 20.6617 7.83714 20.332 8.06714 19.918C8.29714 19.504 8.41214 19.0287 8.41214 18.492V15.847C8.41214 15.0803 8.24347 14.5283 7.90614 14.191C7.56881 13.8537 7.00147 13.685 6.20414 13.685C5.75947 13.685 5.27647 13.7387 4.75514 13.846C4.24914 13.9533 3.71247 14.1373 3.14514 14.398C2.85381 14.536 2.59314 14.5743 2.36314 14.513C2.14847 14.4517 1.97981 14.329 1.85714 14.145C1.73447 13.9457 1.67314 13.731 1.67314 13.501C1.67314 13.271 1.73447 13.0487 1.85714 12.834C1.97981 12.604 2.18681 12.4353 2.47814 12.328C3.18347 12.0367 3.85814 11.8297 4.50214 11.707C5.16147 11.5843 5.75947 11.523 6.29614 11.523C7.40014 11.523 8.30481 11.6917 9.01014 12.029C9.73081 12.3663 10.2675 12.88 10.6201 13.57C10.9728 14.2447 11.1491 15.1187 11.1491 16.192V21.712C11.1491 22.1873 11.0341 22.5553 10.8041 22.816C10.5741 23.0613 10.2445 23.184 9.81514 23.184C9.38581 23.184 9.04847 23.0613 8.80314 22.816C8.57314 22.5553 8.45814 22.1873 8.45814 21.712V20.608H8.64214C8.53481 21.1447 8.32014 21.6123 7.99814 22.011C7.69147 22.3943 7.30047 22.6933 6.82514 22.908C6.34981 23.1227 5.80547 23.23 5.19214 23.23Z"
				fill="white"
			/>
		</svg>
	);
};

export default AIWhiteNew;
