import { Sheet, SheetContent } from '@/components/ui/sheet';
import { FC } from 'react';
import BrainstormWrapper from '@/containers/BrainstormWrapper/BrainstormWrapper';
import Brainstorm from '@/components/ui/icons/new/Brainstorm';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

interface BrainstormDrawerProps {}

const BrainstormDrawer: FC<BrainstormDrawerProps> = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.drawer?.type === 'viewBrainstorm'
	);

	return (
		<Sheet open={isOpen}>
			<SheetContent className="flex flex-col 2xl:w-[23%] !max-w-[450px] p-0 bg-white gap-0">
				<div className="text-primary font-bold flex items-center justify-between gap-2 px-5 py-4 border-b border-[#C6DBFB]">
					<div className="flex items-center gap-2 dynamic-medium ">
						<Brainstorm /> Let's Brainstorm
					</div>
				</div>
				<BrainstormWrapper />
			</SheetContent>
		</Sheet>
	);
};

export default BrainstormDrawer;
