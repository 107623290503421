import { SVGProps } from 'react';

const Histogram = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="67"
			height="67"
			viewBox="0 0 67 67"
			fill="none"
		>
			<rect
				x="11.7148"
				y="35.9937"
				width="9.90728"
				height="21.766"
				fill="#5D9BFD"
			/>
			<rect
				x="27.4023"
				y="24.0225"
				width="9.90728"
				height="33.7373"
				fill="#A8CAFF"
			/>
			<rect
				x="43.0898"
				y="9.87451"
				width="9.90728"
				height="47.8852"
				fill="#E861C9"
			/>
			<rect
				x="4.28125"
				y="62.7134"
				width="58.6181"
				height="1.65121"
				fill="#BDBDBD"
			/>
			<rect
				x="5.93359"
				y="3.26953"
				width="60.2693"
				height="1.65121"
				transform="rotate(90 5.93359 3.26953)"
				fill="#BDBDBD"
			/>
		</svg>
	);
};

export default Histogram;
