import { SolicitationProps } from '@/lib/types/constants';
import { RFP } from '@/lib/types/apiTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProposalState {
	isLoading: boolean;
	rfp: RFP | null;
	files: SolicitationProps[];
	error: string | null;
}

const initialState: ProposalState = {
	isLoading: false,
	rfp: null,
	files: [],
	error: null,
};

const solicitationSlice = createSlice({
	name: 'proposal',
	initialState,
	reducers: {
		clearSolicitation(state) {
			state.rfp = null;
			state.files = [];
			state.error = null;
		},
		setFiles(state, action: PayloadAction<SolicitationProps>) {
			state.files.push(action.payload);
		},
		clearFiles(state) {
			state.files = [];
		},
		setSolicitation(state, action: PayloadAction<RFP>) {
			state.rfp = action.payload;
		},
	},
});

export const { clearSolicitation, setFiles, clearFiles, setSolicitation } =
	solicitationSlice.actions;
export default solicitationSlice.reducer;
