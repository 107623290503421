import DashboardActionCards from '@/components/Dashboard/DashboardActionCards/DashboardActionCards';
import DashboardProposalCard from '@/components/Dashboard/DashboardProposalCard/DashboardProposalCard';
import DashboardResourcesCard from '@/components/Dashboard/DashboardResourcesCard/DashboardResourcesCard';
import DashboardSolicitationCard from '@/components/Dashboard/DashboardSolicitationCard/DashboardSolicitationCard';
import DashboardStatsCard from '@/components/Dashboard/DashboardStatsCard/DashboardStatsCard';
import { RootState } from '@/redux/store/store';
import styles from './Dashboard.module.css';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { cn } from '@/lib/utils';
import { useQuery } from 'react-query';
import { getAllProposals, getTeams } from '@/lib/functions/apiCalls';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import { motion } from 'framer-motion';
import { containerDivFadeIn, iCSlideIn } from '@/constants/variants';
import VideoLightBox from '@/components/VideoLightBox/VideoLightBox';
import Play from '@/components/ui/icons/new/Play';

import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';
import { Team } from '@/lib/types/apiTypes';
import useAuth from '@/lib/hooks/useAuth';

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
	const user = useSelector((root: RootState) => root.auth.user);
	const trialEnded = useSelector(
		(root: RootState) => root.page.dialog?.type === 'trialEnd'
	);
	const [isOpen, setIsOpen] = useState(false);
	useTour(Page.Dashboard);
	const {
		data: proposalData,
		isLoading,
		isError,
	} = useQuery({
		queryKey: 'proposals',
		queryFn: () =>
			getAllProposals({
				pageNumber: 1,
				pageSize: 10,
				sortOrder: 'DESC',
			}),
		refetchOnWindowFocus: false,
	});
	const {
		data: teamsData,
		isLoading: isTeamsLoading,
		isError: isTeamsError,
	} = useQuery({
		queryKey: 'teams',
		queryFn: () => getTeams(),
		refetchOnWindowFocus: false,
	});

	const { isGuest } = useAuth();

	const proposals = useMemo(
		() => (proposalData?.data as any)?.proposals,
		[proposalData?.data]
	);

	// Animation Handling

	if (trialEnded || !proposals)
		return (
			<div
				className={cn(
					styles.container,
					'rounded-tl-[30px] p-6 min-h-screen bg-background light-scroll'
				)}
			></div>
		);

	if (isLoading || isTeamsLoading)
		return (
			<div
				className={cn(
					styles.container,
					'rounded-tl-[30px] p-6 min-h-screen bg-background light-scroll'
				)}
			>
				<LoaderTransparent />
			</div>
		);

	if (isError || isTeamsError)
		return (
			<div
				className={cn(
					styles.container,
					'rounded-tl-[30px] p-6 min-h-screen bg-background light-scroll'
				)}
			>
				Error
			</div>
		);

	return (
		<>
			<div
				className={cn(
					styles.container,
					'rounded-tl-[30px] p-6 min-h-screen bg-background light-scroll'
				)}
			>
				<div>
					<motion.div
						className="grid grid-cols-12 gap-4"
						variants={containerDivFadeIn}
						initial="initial"
						animate="animate"
					>
						<motion.div
							className="col-span-6 2xl:col-span-7 selector1"
							variants={iCSlideIn}
							initial="initial"
							animate="animate"
						>
							<div className="flex items-end justify-between mb-6">
								<div>
									<h1 className="font-bold text-black dynamic-xl-large ">
										Welcome to Grant Assistant,{' '}
										<span className="capitalize text-secondary">
											{user?.name}
										</span>{' '}
									</h1>
									<p className="mt-1 text-black ">
										This is the dashboard. Please select the section you wish to
										go to from the menu below.
									</p>
								</div>
							</div>
							<DashboardSolicitationCard />
							{!isGuest ? <DashboardActionCards /> : null}
							<DashboardResourcesCard setIsOpen={setIsOpen} />
						</motion.div>
						<motion.div
							className="col-span-6 2xl:col-span-5"
							variants={iCSlideIn}
							initial="initial"
							animate="animate"
						>
							{proposals.length > 0 ? (
								<>
									<DashboardStatsCard />
									<DashboardProposalCard
										cards={proposals}
										teams={((teamsData?.data as any)?.teams as Team[]) || []}
									/>
								</>
							) : (
								<motion.div
									className={cn(
										styles.intro_video_wrapper,
										' shadow-main video-container'
									)}
									variants={iCSlideIn}
									initial="initial"
									animate="animate"
								>
									{/* New Design */}
									<Play onClick={() => setIsOpen(true)} />
									<div className={styles.intro_video}>
										<h2 className="mb-1 font-bold dynamic-large">
											Getting Started
										</h2>
										<p>
											Here is a helpful video that can guide you through Grant
											Assistant. You can also watch this and other videos in the
											help section of the platform
										</p>
									</div>
								</motion.div>
							)}
						</motion.div>
					</motion.div>
				</div>
			</div>
			<VideoLightBox
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				url="https://vimeo.com/950656953/6e25a30438"
			/>
		</>
	);
};

export default Dashboard;
