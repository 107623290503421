import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { FC } from 'react';
import styles from './AppTabs.module.css';
import { cn } from '@/lib/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';

type TabType = {
	label: string;
	value: string;
	content: React.ReactNode;
	onClick?: () => void;
	tourClass?: string;
};

interface AppTabsProps {
	defaultTab: string;
	selectedTab: string;
	handleTabChange: (val: string) => void;
	type: 'main' | 'secondary' | 'drawer';
	tabs: TabType[];
}

const AppTabs: FC<AppTabsProps> = ({
	defaultTab,
	selectedTab,
	handleTabChange,
	tabs,
	type,
}) => {
	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);
	return (
		<Tabs
			defaultValue={defaultTab}
			value={selectedTab}
			onValueChange={handleTabChange}
		>
			<TabsList
				className={cn(
					`relative mt-1 p-0 h-9`,
					type === 'main'
						? cn(styles.main, 'dashboard-tabs')
						: type === 'secondary'
							? styles.secondary
							: styles.drawer
				)}
			>
				{tabs.map((tab) => (
					<TabsTrigger
						className={cn(
							styles.tabs,
							'focus-visible:ring-0 focus-visible:ring-none focus-visible:ring-offset-0'
						)}
						key={tab.value}
						value={tab.value}
						onClick={tab.onClick}
						disabled={isStreaming}
					>
						<p className={tab.tourClass}>{tab.label}</p>
					</TabsTrigger>
				))}
			</TabsList>
			{tabs.map((tab) => (
				<TabsContent
					key={tab.value}
					value={tab.value}
					className={cn(
						styles.tabContent,
						'mt-0 focus-visible:ring-0 focus-visible:ring-none focus-visible:ring-offset-0'
					)}
				>
					{tab.content}
				</TabsContent>
			))}
		</Tabs>
	);
};

export default AppTabs;
