import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import FileUpload from '@/components/FileUpload/FileUpload';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { uploadDocumentInOrganization } from '@/lib/functions/apiCalls';
import {
	handleApiError,
	handleFileRejections,
	handleGeneralError,
	handleResponse,
	handleUnexpectedError,
	// showToast,
} from '@/lib/functions/funcUtils';
import { ErrorResponse, UploadDocumentPayload } from '@/lib/types/apiTypes';
import { docName, docType } from '@/lib/types/constants';
import { clearProposal } from '@/redux/slices/proposalSlice';
import { v4 as uuidv4 } from 'uuid';

import { useSelector } from 'react-redux';
import { clearFiles, setFiles } from '@/redux/slices/solicitationSlice';
import { useQueryClient } from 'react-query';
import DocumentUploadUpward from '@/components/ui/icons/new/DocumentUploadUpward';
// import {
// 	Accordion,
// 	AccordionContent,
// 	AccordionItem,
// 	AccordionTrigger,
// } from '@/components/ui/accordion';
import { useState } from 'react';
import { cn } from '@/lib/utils';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import UnselectDocument from '@/components/ui/icons/new/UnselectDocument';
// import { UploadedDocumentsTable } from '@/components/Tables/UploadedDocumentsTable/UploadedDocumentsTable';
// import { columns } from '@/components/Tables/UploadedDocumentsTable/columns';
// import { FileWithPath } from 'react-dropzone';
// import { cloneDeep } from 'lodash';

const SolicitationDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'uploadSolicitation'
	);
	const [solicitationFiles, setSolicitationFiles] = useState<File[] | null>(
		null
	);
	const [additionalFiles, setAdditionalFiles] = useState<File[] | null>(null);
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const processFileUpload = async (file: File) => {
		const payload: UploadDocumentPayload = {
			file: file,
			name: docName,
			type: docType,
			additionalResources: additionalFiles,
			category: 'solicitation',
		};

		try {
			dispatch(clearProposal());
			const response = await uploadDocumentInOrganization(payload);
			handleResponse(response);
			return null; // Return null if successful
		} catch (error: unknown) {
			// Handle errors and return them
			if (error instanceof Error) {
				handleGeneralError(error);
				return error.message;
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				return apiError.message;
			} else {
				handleUnexpectedError();
				return 'Unknown error occurred';
			}
		}
	};

	const handleSolicitationFiles = async (files: File[]) => {
		if (!files.length) return;
		setSolicitationFiles(files);
	};

	// const handleAdditionalFiles = async (files: File[]) => {
	// 	if (!files.length) return;
	// 	const file: FileWithPath = files[0];
	// 	if (
	// 		additionalFiles &&
	// 		additionalFiles.find(
	// 			(addtionalFile: FileWithPath) => addtionalFile.path === file.path
	// 		)
	// 	) {
	// 		showToast('error', 'Selected document has already been uploaded.');
	// 	} else {
	// 		additionalFiles?.push(file);
	// 		setAdditionalFiles(additionalFiles ? cloneDeep(additionalFiles) : files);
	// 	}
	// };

	// const handleDetachDocument = (documentPath: string) => {
	// 	if (!additionalFiles) return;
	// 	const fileIndex = additionalFiles.findIndex(
	// 		(addtionalFile: FileWithPath) => addtionalFile.path === documentPath
	// 	);
	// 	additionalFiles.splice(fileIndex, 1);
	// 	setAdditionalFiles(cloneDeep(additionalFiles));
	// };

	const startSolicitationProcess = async () => {
		if (!solicitationFiles?.length) return;

		clearDialog();
		// Process each file
		await Promise.all(
			solicitationFiles.map(async (file) => {
				dispatch(
					setFiles({
						id: uuidv4(),
						metaData: {
							agency: 'N/A',
						},
						name: file.name,
						status: 'processing',
						createdAt: new Date().toISOString(),
					})
				);

				await processFileUpload(file);
				setSolicitationFiles(null);
			})
		);
		queryClient.invalidateQueries('solicitations');
		dispatch(clearFiles());
	};

	const clearDialog = () => {
		dispatch(closeDialog());
		setSolicitationFiles(null);
		setAdditionalFiles(null);
	};

	const renderUploadLayout = (disabled?) => {
		return (
			<div
				className={cn(
					'flex h-12 2xl:h-16 p-3 2xl:p-4 bg-[#EAF1FC] justify-center rounded-2xl border border-[#D3DDE2] gap-3 2xl:gap-4',
					disabled ? 'opacity-50 cursor-ga' : 'cursor-pointer '
				)}
			>
				<DocumentUploadUpward
					className="self-center w-6 h-8"
					stroke="#5D9BFD"
					fill="transparent"
				/>
				<p className="self-center text-primary">
					click here to upload a file
					<span className="text-black"> or </span>drag and drop a file here
				</p>
			</div>
		);
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[550px] shadow-none bg-white"
				setModalOpen={() => clearDialog()}
			>
				<Card className="flex flex-col gap-3 bg-white border-none shadow-none 2xl:gap-6">
					<CardHeader className="p-0 text-left">
						<h1 className="font-bold text-left text-black dynamic-large">
							Please upload the solicitation file{' '}
							<span className="text-secondary">(such as an RFP)</span> Only
						</h1>
						<p className="mt-0 text-black">Import a single pdf or docx file</p>
					</CardHeader>
					<CardContent className="flex flex-col gap-4 p-0 2xl:gap-6">
						<div className="flex flex-col gap-2 2xl:gap-3">
							{!solicitationFiles?.length ? (
								<FileUpload
									onDropRejected={(fileRejections) =>
										handleFileRejections({ fileRejections: fileRejections })
									}
									onDropAccepted={handleSolicitationFiles}
									type="custom"
									customLayout={renderUploadLayout()}
								/>
							) : (
								<div className="flex h-12 2xl:h-16 p-3 2xl:p-4 bg-[#EAF1FC] justify-center rounded-2xl border border-[#D3DDE2] cursor-pointer gap-4">
									<p className="self-center text-primary">
										{solicitationFiles[0].name}
									</p>
									<UnselectDocument
										onClick={() => setSolicitationFiles(null)}
										className="w-5 h-5 cursor-pointer"
									/>
								</div>
							)}
						</div>
						{/* <div className="border-y border-[#D3DDE2] py-4 2xl:py-6">
							<Accordion type="single" collapsible>
								<AccordionItem
									value="additional-doc"
									className="flex flex-col gap-2 border-none 2xl:gap-3"
								>
									<AccordionTrigger className="p-0 hover:no-underline">
										<h3 className="font-bold text-left text-black dynamic-large">
											Add any additional files provided by your funder.
											<span className="text-secondary">(optional)</span>
										</h3>
									</AccordionTrigger>
									<AccordionContent>
										<div className="flex flex-col gap-2 2xl:gap-3">
											<div className="text-black whitespace-pre-wrap">
												<p>
													<span className="font-bold">
														Note: Please only Include files that are provided by
														the funder/Client.
													</span>
												</p>
												<p>
													<span className="font-bold">Do not</span> provide any
													files here that are to help you write this proposal.
												</p>
												<p>
													<span className="font-bold">Do not</span> include
													files such as templates provided by the funder/client
													Including these files can degrade the production
													experience
												</p>
											</div>
											{!additionalFiles?.length ||
											additionalFiles.length < 3 ? (
												<FileUpload
													onDropRejected={(fileRejections) =>
														handleFileRejections({
															fileRejections: fileRejections,
															fileSize: '2 MB',
														})
													}
													onDropAccepted={handleAdditionalFiles}
													type="custom"
													customLayout={renderUploadLayout()}
													maxSize={2000000}
												/>
											) : (
												<>{renderUploadLayout(true)}</>
											)}
											{!!additionalFiles?.length &&
												additionalFiles.length > 0 && (
													<UploadedDocumentsTable
														columns={columns(handleDetachDocument)}
														data={(additionalFiles as any) || []}
													/>
												)}
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</div> */}
						<PrimaryButton
							className="self-center w-fit"
							onClick={startSolicitationProcess}
							disabled={!solicitationFiles?.length}
						>
							Start processing solicitation (5-10 minutes)
						</PrimaryButton>
					</CardContent>
				</Card>
			</DialogContent>
		</Dialog>
	);
};

export default SolicitationDialog;
