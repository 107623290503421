import { DragLayerMonitor, useDragLayer } from 'react-dnd';

interface CustomDragLayerProps {
	screenWidth: number;
}
const CustomDragLayer: React.FC<CustomDragLayerProps> = ({ screenWidth }) => {
	const { isDragging, currentOffset, item } = useDragLayer(
		(monitor: DragLayerMonitor) => ({
			isDragging: monitor.isDragging(),
			currentOffset: monitor.getSourceClientOffset(),
			item: monitor.getItem(),
		})
	);
	const screenInnerWidth = window.innerWidth;
	return isDragging && currentOffset ? (
		<div
			className="rounded-full font-bold dynamic-medium text-primary max-w-[650px] min-w-[300px] 
		flex justify-between items-center bg-white border border-[#d3dde2] p-[15px] opacity-100 z-[100] pointer-events-none"
			style={{
				position: 'fixed',
				top:
					screenInnerWidth >= screenWidth
						? currentOffset.y - 80
						: currentOffset.y,
				left:
					screenInnerWidth >= screenWidth
						? currentOffset.x - 90
						: currentOffset.x - 20,
			}}
		>
			{item.node?.name} {/* Access node data from the item */}
		</div>
	) : null;
};

export default CustomDragLayer;
