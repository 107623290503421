const Delete = ({ fill = '#5D6F79', ...props }) => {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="mask0_6282_15475"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="21"
				height="21"
			>
				<rect x="0.332031" y="0.5" width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_6282_15475)">
				<path
					d="M6.16406 18C5.70573 18 5.31337 17.8368 4.98698 17.5104C4.66059 17.184 4.4974 16.7917 4.4974 16.3333V5.5H3.66406V3.83333H7.83073V3H12.8307V3.83333H16.9974V5.5H16.1641V16.3333C16.1641 16.7917 16.0009 17.184 15.6745 17.5104C15.3481 17.8368 14.9557 18 14.4974 18H6.16406ZM14.4974 5.5H6.16406V16.3333H14.4974V5.5ZM7.83073 14.6667H9.4974V7.16667H7.83073V14.6667ZM11.1641 14.6667H12.8307V7.16667H11.1641V14.6667Z"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default Delete;
