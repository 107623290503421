import { SVGProps } from 'react';

const DeleteCross = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
		>
			<mask
				id="mask0_6707_11990"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="18"
				height="19"
			>
				<rect y="0.5" width="18" height="18" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_6707_11990)">
				<path
					d="M4.8 14.75L3.75 13.7L7.95 9.5L3.75 5.3L4.8 4.25L9 8.45L13.2 4.25L14.25 5.3L10.05 9.5L14.25 13.7L13.2 14.75L9 10.55L4.8 14.75Z"
					fill={props.color ? props.color : '#5D9BFD'}
				/>
			</g>
		</svg>
	);
};

export default DeleteCross;
