const EditIcon = ({ color = '#5D9BFD', className = 'h-6' }) => {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 33 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_2774_520"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="34"
				height="33"
			>
				<path d="M0 -0.000133514H33.0001V33H0V-0.000133514Z" fill="white" />
			</mask>
			<g mask="url(#mask0_2774_520)">
				<path
					d="M17.7247 6.25185H3.54493C2.12103 6.25185 0.966797 7.40608 0.966797 8.82998V29.4551C0.966797 30.879 2.12103 32.0332 3.54493 32.0332H24.17C25.5939 32.0332 26.7482 30.879 26.7482 29.4551V15.2753"
					stroke="#5D9BFD"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M31.6558 4.99119L17.0717 19.5753L12.5142 20.4868L13.4257 15.9293L28.0098 1.34513C28.5132 0.841748 29.3294 0.841748 29.8328 1.34513L31.6558 3.16819C32.1592 3.67157 32.1592 4.48775 31.6558 4.99119Z"
					stroke="#5D9BFD"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.1868 3.16699L29.8328 6.81299"
					stroke={color}
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};

export default EditIcon;
