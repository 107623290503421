import { Input } from '../../ui/input';
import { useForm, useWatch } from 'react-hook-form';
import {
	getOrganizationProfiles,
	updateOrganization,
	updateOrganizationProfile,
} from '@/lib/functions/apiCalls';
import { useNavigate } from 'react-router';
import {
	Organization,
	OrganizationPayload,
	OrganizationProfile,
	OrganizationProfilePayload,
} from '@/lib/types/apiTypes';
import styles from './OrganizationSetupForm.module.css';
import {
	ErrorHandle,
	getToken,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { Label } from '../../ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '../../ui/form';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import SecondaryButton from '../../ui/shared/Button/SecondaryButton/SecondaryButton';
import { FC, useCallback, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { Textarea } from '../../ui/textarea';
import {
	Multiselect,
	SelectItemProp,
} from '../../ui/shared/Multiselect/Multiselect';
import {
	expertise,
	regionsOfWork,
	countriesAndTerritories,
	organizationSize,
	annualRevenue,
} from '@/constants/organizationalData';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';

interface FormData {
	// Organization Profile
	name: string;
	websiteUrl: string;
	description: string;
	areaOfWork: string;
	// Organization Expertise and Size
	expertise: SelectItemProp[];
	regionsOfWork: SelectItemProp[];
	countriesAndTerritories: SelectItemProp[];
	organizationSize: string;
	annualRevenue: string;
	// Additional Organization Information
	address: string;
	country: string;
	city: string;
	state: string;
	zip: string | number;
}

const basicFormSchema = z.object({
	name: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
	description: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
});

const detailFormSchema = z.object({
	address: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
	expertise: z
		.array(z.object({ value: z.string(), label: z.string() }))
		.min(1, {
			message: 'Please select an option.',
		}),
	regionsOfWork: z
		.array(z.object({ value: z.string(), label: z.string() }))
		.min(1, {
			message: 'Please select an option.',
		}),
});

const getFormSchema = (step: number) => {
	switch (step) {
		case 2:
			return detailFormSchema;
		default:
			return basicFormSchema;
	}
};

interface OrganizationSetupFormProps {
	setupStep: number;
	setSetupStep: (setupStep: number) => void;
	organizationId: number;
	organization: Organization | null;
	setOrganization: (organization: Organization) => void;
	setIsLoading: (isLoading: boolean) => void;
}

const OrganizationSetupForm: FC<OrganizationSetupFormProps> = ({
	setupStep,
	setSetupStep,
	organizationId,
	organization,
	setOrganization,
	setIsLoading,
}) => {
	const navigate = useNavigate();
	const token = getToken();
	const dispatch = useAppDispatch();
	if (token === null) {
		navigate('/login');
	}

	const formSchema = getFormSchema(setupStep);

	const form = useForm<FormData>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: '',
			websiteUrl: '',
			description: '',
			areaOfWork: '',
			// Organization Expertise and Size
			expertise: [],
			regionsOfWork: [],
			countriesAndTerritories: [],
			organizationSize: '',
			annualRevenue: '',
			// Additional Organization Information
			address: '',
			country: '',
			city: '',
			state: '',
			zip: '',
		},
	});

	const formatOrganizationProfilePayload = (
		organization: OrganizationPayload
	): OrganizationProfilePayload => {
		return {
			name: organization.name,
			description: organization.description || null,
			expertise: organization.expertise || [],
			regionsOfWork: organization.regionsOfWork || [],
			countriesAndTerritories: organization.countriesAndTerritories || [],
			areaOfWork: organization.areaOfWork || null,
			organizationSize: organization.organizationSize || null,
		};
	};

	const formatOrganizationSetupPayload = (
		formData: FormData,
		currentStep: number
	): OrganizationPayload => {
		return {
			name: formData.name,
			websiteUrl: formData.websiteUrl || null,
			description: formData.description || null,
			address: formData.address || null,
			country: formData.country || null,
			city: formData.city || null,
			state: formData.state || null,
			zip:
				typeof formData.zip === 'number'
					? formData.zip
					: parseInt(formData.zip) || null,
			expertise: formData.expertise.map((item) => item.value) || [],
			regionsOfWork: formData.regionsOfWork.map((item) => item.value) || [],
			countriesAndTerritories:
				formData.countriesAndTerritories.map((item) => item.value) || [],
			areaOfWork: formData.areaOfWork || null,
			organizationSize: formData.organizationSize || null,
			annualRevenue: formData.annualRevenue || null,
			initialSetupStep: currentStep,
		};
	};

	const handleOrganizationProfileUpdate = async (
		organizationPayload: OrganizationPayload
	) => {
		try {
			const orgProfileResponse = await getOrganizationProfiles();
			handleResponse(orgProfileResponse);
			const organizationProfiles =
				orgProfileResponse?.data as OrganizationProfile[];
			if (organizationProfiles.length === 1) {
				const organizationProfilePayload =
					formatOrganizationProfilePayload(organizationPayload);
				const response = await updateOrganizationProfile(
					organizationProfiles[0].id,
					organizationProfilePayload
				);
				handleResponse(response);
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const onSubmit = async () => {
		try {
			if (!token) return;
			setIsLoading(true);
			const currentStep = setupStep + 1;
			const organizationPayload: OrganizationPayload =
				formatOrganizationSetupPayload(form.getValues(), currentStep);
			setSetupStep(currentStep);
			handleOrganizationProfileUpdate(organizationPayload);
			const response = await updateOrganization(
				organizationId,
				organizationPayload
			);
			handleResponse(response);
			setOrganization(response.data as Organization);
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const designationFormValue = useWatch({
		control: form.control,
		name: 'description',
	});
	const areOfWorkFormValue = useWatch({
		control: form.control,
		name: 'areaOfWork',
	});

	const setFormValues = useCallback(
		(organization: OrganizationPayload) => {
			form.setValue('name', organization.name);
			form.setValue('websiteUrl', organization.websiteUrl || '');
			form.setValue('description', organization.description || '');
			form.setValue('address', organization.address || '');
			form.setValue('country', organization.country || '');
			form.setValue('city', organization.city || '');
			form.setValue('state', organization.state || '');
			form.setValue('zip', organization.zip || '');
			form.setValue(
				'expertise',
				organization.expertise?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue(
				'regionsOfWork',
				organization.regionsOfWork?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue(
				'countriesAndTerritories',
				organization.countriesAndTerritories?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue('areaOfWork', organization.areaOfWork || '');
			form.setValue('organizationSize', organization.organizationSize || '');
			form.setValue('annualRevenue', organization.annualRevenue || '');
		},
		[form]
	);

	useEffect(() => {
		if (organization) {
			setFormValues(organization);
		}
	}, [organization]);

	return (
		<div className="flex flex-col gap-6">
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(onSubmit)}
					className="flex flex-col gap-6 w-[950px]"
				>
					<div
						className={cn(
							'flex flex-col gap-6',
							setupStep === 1 ? '' : 'hidden'
						)}
					>
						<div className="flex flex-col gap-4">
							<h2 className="font-bold text-black dynamic-large">
								Please describe your organization
							</h2>
							<p className="text-black dynamic-text">
								Please provide us a few details about your organization
							</p>
						</div>
						<div className="grid grid-cols-11 gap-4">
							<div className="flex flex-col col-span-6 gap-4">
								<FormField
									control={form.control}
									name="name"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">Organization Name*</Label>
												<FormControl>
													<Input
														className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
														placeholder="Enter the full name and acronym for your organization"
														type="text"
														{...field}
													></Input>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
								<FormField
									control={form.control}
									name="websiteUrl"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">Website</Label>
												<FormControl>
													<Input
														className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
														placeholder="Please enter your full website"
														type="text"
														{...field}
													></Input>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
								<FormField
									control={form.control}
									name="areaOfWork"
									render={({ field }) => {
										return (
											<FormItem>
												<div className="flex justify-between">
													<Label className="font-bold">Area Of Work</Label>
													<p
														className={cn(
															'text-base font-medium leading-normal',
															field?.value?.length > 250
																? 'text-[#FF82A0]'
																: 'text-black opacity-50'
														)}
													>
														{field?.value?.length || 0}/250 characters
													</p>
												</div>
												<FormControl>
													<Input
														className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
														placeholder="Please describe the type of work you do"
														type="text"
														{...field}
													></Input>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="flex col-span-5">
								<FormField
									control={form.control}
									name="description"
									render={({ field }) => {
										return (
											<FormItem className="flex flex-col w-full overflow-hidden">
												<div className="flex justify-between">
													<Label className="font-bold">
														Describe your Organization*
													</Label>
													<p
														className={cn(
															'text-base font-medium leading-normal',
															field?.value?.length > 1200
																? 'text-[#FF82A0]'
																: 'text-black opacity-50'
														)}
													>
														{field?.value?.length || 0}/1200 characters
													</p>
												</div>
												<FormControl>
													<Textarea
														{...field}
														className={cn(
															'bg-white border-[#D3DDE2] rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] h-56 resize-none'
														)}
														placeholder="Please describe your organization in some detail. Keep in mind that the AI model will look at this description, your area of work and your organization name when creating content for you."
													></Textarea>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
						</div>
					</div>
					<div
						className={cn(
							'flex flex-col gap-6',
							setupStep === 2 ? '' : 'hidden'
						)}
					>
						<div className="flex flex-col gap-4">
							<h2 className="font-bold text-black dynamic-large">
								Organization Expertise and Size
							</h2>
							<p className="text-black dynamic-text">
								Please define your organizational expertise and size
							</p>
						</div>
						<div className="grid grid-cols-12 gap-4">
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="expertise"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">Expertise*</Label>
												<FormControl>
													<Multiselect
														{...field}
														title="Select expertise"
														hasChildren={true}
														options={expertise}
														onSelectedValuesChange={field.onChange}
													></Multiselect>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="regionsOfWork"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">
													Which region do you work?*
												</Label>
												<FormControl>
													<Multiselect
														{...field}
														title="Select regions"
														options={regionsOfWork}
														onSelectedValuesChange={field.onChange}
													></Multiselect>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="countriesAndTerritories"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">
													Which countries and territories you work?
												</Label>
												<FormControl>
													<Multiselect
														{...field}
														title="Select countries and territories"
														options={countriesAndTerritories}
														onSelectedValuesChange={field.onChange}
													></Multiselect>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-4">
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="organizationSize"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>Organization Size</Label>
												<FormControl>
													<Select
														onValueChange={field.onChange}
														value={field.value}
													>
														<FormControl>
															<SelectTrigger
																className={cn(
																	'h-14 rounded-2xl text-sm xl:text-base border border-[#D3DDE2] bg-white text-black px-4 py-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
																)}
															>
																<SelectValue placeholder="Select organization size" />
															</SelectTrigger>
														</FormControl>
														<SelectContent className="py-2 bg-white rounded-2xl">
															{organizationSize.map((item, index) => {
																return (
																	<SelectItem
																		className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
																		key={index}
																		value={item.value}
																	>
																		{item.label}
																	</SelectItem>
																);
															})}
														</SelectContent>
													</Select>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="annualRevenue"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>Annual Revenue</Label>
												<FormControl>
													<Select
														onValueChange={field.onChange}
														value={field.value}
													>
														<FormControl>
															<SelectTrigger
																className={cn(
																	'h-14 rounded-2xl text-sm xl:text-base border border-[#D3DDE2] bg-white text-black px-4 py-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
																)}
															>
																<SelectValue placeholder="Select annual revenue" />
															</SelectTrigger>
														</FormControl>
														<SelectContent className="py-2 bg-white rounded-2xl">
															{annualRevenue.map((item, index) => {
																return (
																	<SelectItem
																		className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
																		key={index}
																		value={item.value}
																	>
																		{item.label}
																	</SelectItem>
																);
															})}
														</SelectContent>
													</Select>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
						</div>
						<div className="flex flex-col gap-4 border-t border-[#D3DDE2] pt-6">
							<h2 className="font-bold text-black dynamic-large">
								Additional Organization Information
							</h2>
							<p className="text-black dynamic-text">
								Please provide us with additional information regarding your
								company
							</p>
						</div>
						<div className="grid grid-cols-12 gap-4">
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="address"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>Address*</Label>
												<FormControl>
													<Input
														className={cn(
															'bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
														)}
														placeholder="Enter full address"
														{...field}
													></Input>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="city"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>City</Label>
												<FormControl>
													<Input
														className={cn(
															'bg-white border-[#D3DDE2] h-12 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
														)}
														placeholder="Enter city"
														{...field}
													></Input>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="state"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>State or Province</Label>
												<FormControl>
													<Input
														className={cn(
															'bg-white border-[#D3DDE2] h-12 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
														)}
														placeholder="Enter state or province"
														{...field}
													></Input>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="country"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>Country</Label>
												<FormControl>
													<Input
														className={cn(
															'bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
														)}
														placeholder="Enter country"
														{...field}
													></Input>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="zip"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>Zip or PO Box</Label>
												<FormControl>
													<Input
														className={cn(
															styles.number__input,
															'bg-white border-[#D3DDE2] h-12 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
														)}
														type="number"
														placeholder="Enter zip or po box"
														{...field}
													></Input>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="flex justify-end w-full gap-4 mx-auto">
						{setupStep !== 1 && (
							<SecondaryButton
								onClick={() => setSetupStep(setupStep ? setupStep - 1 : 1)}
								type="button"
							>
								Back
							</SecondaryButton>
						)}
						<PrimaryButton
							disabled={
								designationFormValue?.length > 1200 ||
								areOfWorkFormValue?.length > 250
							}
							type="submit"
						>
							Save & Continue
						</PrimaryButton>
					</div>
				</form>
			</Form>
		</div>
	);
};

export default OrganizationSetupForm;
