import FileUploadIcon from '@/components/ui/icons/FileUploadIcon';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import styles from './ProjectHeader.module.css';
import { cn } from '@/lib/utils';
import useAuth from '@/lib/hooks/useAuth';
import { FC, useState } from 'react';
import { FileEditIcon } from 'lucide-react';
import { resetForm, setJourneyType } from '@/redux/slices/unsolicitedSlice';
import QuestionnaireIcon from '@/components/ui/icons/new/Dashboard/QuestionnaireIcon';

interface ProjectHeaderProps {
	type: 'dashboard' | 'favorites' | 'archives';
}

const ProjectHeader: FC<ProjectHeaderProps> = ({ type }) => {
	const user = useSelector((root: RootState) => root.auth.user);
	const { isGuest } = useAuth();
	const [isQuestionHover, setIsQuestionHover] = useState(false);

	const dispatch = useAppDispatch();

	const renderButton = () => {
		if (!isGuest) {
			return (
				<div className="flex gap-4 solicitation-button-wrapper">
					<PrimaryButton
						onClick={() => dispatch(openDialog({ type: 'uploadSolicitation' }))}
					>
						<FileUploadIcon />
						Start Solicited Proposal
					</PrimaryButton>
					<SecondaryButton
						className={styles.button}
						onClick={() => {
							dispatch(openDialog({ type: 'multiStepUnsolicited' }));
							dispatch(resetForm());
						}}
					>
						<FileEditIcon />
						Start UnSolicited Proposal
					</SecondaryButton>
					<SecondaryButton
						className={styles.button}
						onMouseEnter={() => setIsQuestionHover(true)}
						onMouseLeave={() => setIsQuestionHover(false)}
						onClick={() => {
							dispatch(openDialog({ type: 'multiStepUnsolicited' }));
							dispatch(resetForm());
							dispatch(setJourneyType('questionnaire'));
						}}
					>
						<QuestionnaireIcon mode={isQuestionHover ? 'hovered' : ''} />
						Start Questionnaire
					</SecondaryButton>
				</div>
			);
		}
	};
	return (
		<div
			className={cn(
				styles.header,
				'flex items-center justify-between py-5 px-6'
			)}
		>
			<h2 className="text-black dynamic-xl-large">
				Welcome back, <span className="font-bold">{user?.name}</span>
			</h2>
			{type === 'dashboard' ? (
				renderButton()
			) : (
				<div className="h-10 2xl:h-11"></div>
			)}
		</div>
	);
};

export default ProjectHeader;
