import { FC, useEffect, useRef, useState } from 'react';
import styles from './DesignCard.module.css';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { ErrorResponse, RFP, ToneOfVoice } from '@/lib/types/apiTypes';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { openDialog } from '@/redux/slices/pageSlice';
import { handleApiError, handleGeneralError } from '@/lib/functions/funcUtils';
import { cn } from '@/lib/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { getDocumentById } from '@/lib/functions/apiCalls';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import ToneOfVoiceChart from '../ToneOfVoiceChart/ToneOfVoiceChart';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';

interface DesignCardProps {
	title: string;
	responseTo?: string;
	text: string | null;
	edit?: boolean;
	comments?: boolean;
	pdfLink?: string;
	type: 'approach' | 'rfp' | 'voice';
	tourClass: string;
}

const DesignCard: FC<DesignCardProps> = ({ title, text, type, tourClass }) => {
	const cardRef = useRef<HTMLDivElement | null>(null);
	const textRef = useRef<HTMLParagraphElement>(null);
	const dispatch = useAppDispatch();
	const [showReadMore, setShowReadMore] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const toneOfVoice = (proposal as any)?.settings?.toneOfVoice as ToneOfVoice;

	useEffect(() => {
		if (textRef.current) {
			const height = textRef.current.offsetHeight;
			if (height > 150) {
				setShowReadMore(true);
			} else {
				setShowReadMore(false);
			}
		}
	}, [text, textRef]);

	const handleApproach = async () => {
		dispatch(processStart());
		try {
			dispatch(openDialog({ type: 'viewApproach' }));
			dispatch(processSuccess());
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};

	const handleTone = async () => {
		dispatch(processStart());
		try {
			dispatch(
				openDialog({
					type: 'changeTone',
					changeToneProps: {
						type: 'proposal',
						toneOfVoice: toneOfVoice,
						proposalId: proposal?.id,
					},
				})
			);
			dispatch(processSuccess());
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};

	const handleBreakDown = async () => {
		if (proposal?.documentId) {
			const response = await getDocumentById(proposal.documentId);
			if (response.statusCode >= 400 && response.statusCode <= 599) {
				throw response; // Throw the response if status is 400s or 500s
			}
			if (response.statusCode === 200) {
				dispatch(
					openDialog({
						type: 'viewSolicitationBrief',
						rfp: response.data as RFP,
						viewProposalType: 'proposal',
					})
				);
			}
		}
	};

	const renderButton = () => {
		switch (type) {
			case 'approach':
				return (
					<div className="flex flex-wrap justify-between w-full gap-4 mt-2">
						<PrimaryButton
							type="button"
							onClick={handleApproach}
							className="revisit-approach-button"
						>
							Edit your Value Proposition
						</PrimaryButton>
					</div>
				);
			case 'rfp':
				return (
					<PrimaryButton onClick={handleBreakDown}>
						View Solicitation Brief
					</PrimaryButton>
				);
			case 'voice':
				return (
					<PrimaryButton onClick={handleTone}>Change Settings</PrimaryButton>
				);
		}
	};

	const toggleText = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div
			className={cn(
				styles.card,
				'flex flex-col justify-between shadow-main',
				tourClass
			)}
			ref={cardRef}
		>
			<div className="flex flex-col gap-2">
				<h2 className="dynamic-large font-bold text-[#6D7D86]">{title}</h2>
				{type === 'voice' ? (
					<ToneOfVoiceChart toneOfVoice={proposal?.settings?.toneOfVoice} />
				) : (
					<>
						<div
							className={`${styles.textContainer}  ${
								isExpanded
									? styles.expanded
									: showReadMore
										? styles.collapsed
										: ''
							}`}
						>
							<p
								ref={textRef}
								className={`text-base text-[#6D7D86] font-normal mt-3`}
							>
								{text}
							</p>
						</div>
						{showReadMore && (
							<TextButton
								onClick={toggleText}
								className="h-fit 2xl:h-fit w-fit p-0 mt-2 font-bold text-start read-more-button text-primary"
							>
								{isExpanded ? (
									<span className="font-normal">Less</span>
								) : (
									'Read More'
								)}
							</TextButton>
						)}
					</>
				)}
			</div>
			<div className="flex items-center justify-start mt-2">
				{renderButton()}
			</div>
		</div>
	);
};

export default DesignCard;
