import InfoNew from '@/components/ui/icons/new/TemplateBuilder/InfoNew';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { openDialog } from '@/redux/slices/pageSlice';
import { setEditSectionData } from '@/redux/slices/TemplateBuilder/sectionSlice';
import { PlusIcon } from 'lucide-react';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import AvailableSectionsFilter from '../../TemplateDnd/AvailableSectionsFilter';

interface TemplateSectionFiltersProps {
	handleSearchChange: (value: string) => void;
	sectionSearch: string | undefined;
	setSectionTagFilter: (value: string | undefined) => void;
}

const TemplateSectionFilters: FC<TemplateSectionFiltersProps> = ({
	handleSearchChange,
	sectionSearch,
	setSectionTagFilter,
}) => {
	const dispatch = useDispatch();
	return (
		<div className="flex flex-col justify-between gap-1">
			<div className="flex flex-row items-center gap-2">
				<h2 className="font-bold text-black dynamic-xl-large">Sections</h2>

				<TooltipProvider delayDuration={50}>
					<Tooltip>
						<TooltipTrigger type="button">
							<InfoNew />
						</TooltipTrigger>
						<TooltipContent
							align="start"
							side="bottom"
							className="bg-white overflow-y-auto text-wrap max-w-sm break-words text-[#5D6F79] 
													dynamic-small py-4 px-4 rounded-xl border-none shadow-none drop-shadow-effect"
						>
							Here are the sections you can find and then drag and drop in the
							Template Assembler to create a template.
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
			<div className="flex flex-row">
				<span className=" text-[#5D6F79]">
					Here are the sections you can find and then drag and drop in the
					Template Assembler to create a template.
				</span>
			</div>
			<div className="flex flex-row items-center">
				<TextButton
					type="button"
					className="create-section"
					onClick={() => {
						dispatch(setEditSectionData(null));
						dispatch(
							openDialog({
								type: 'createTemplateSection',
								editSectionID: null,
								sectionCreatedByUser: true,
								sectionDialogStep: '1',
							})
						);
					}}
				>
					<PlusIcon size={18} /> Create NEW SECTION
				</TextButton>
			</div>
			<div className="bg-[#E9E9E9] h-[1px] border-none mb-3"></div>
			<AvailableSectionsFilter
				handleSearchChange={handleSearchChange}
				sectionSearch={sectionSearch}
				setSectionTagFilter={setSectionTagFilter}
				// handleOrganizationFilter={handleOrganizationFilter}
			/>
		</div>
	);
};

export default TemplateSectionFilters;
