import { SVGProps } from 'react';

const Bubble = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="67"
			height="67"
			viewBox="0 0 67 67"
			fill="none"
		>
			<path
				d="M3.79297 2.81934V63.0887H63.2367"
				stroke="#BDBDBD"
				strokeWidth="1.65444"
			/>
			<circle cx="46.1054" cy="51.7367" r="5.16004" fill="#BA59A4" />
			<circle cx="18.6531" cy="41.6228" r="7.43046" fill="#4385ED" />
			<circle cx="18.3559" cy="41.3256" r="7.13325" fill="#5D9BFD" />
			<circle cx="45.899" cy="51.5303" r="4.95364" fill="#E861C9" />
			<circle cx="35.5793" cy="23.0466" r="11.1457" fill="#1CDBF3" />
			<circle cx="35.1665" cy="22.6338" r="10.7329" fill="#A8CAFF" />
		</svg>
	);
};

export default Bubble;
