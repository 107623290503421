import AiIcon from '@/components/ui/icons/AiIcon';
import { Input } from '@/components/ui/input';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { FC, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import useSuggestionHandler from '@/lib/hooks/useSuggestionHandler';
import { getToken, typeWithInterval } from '@/lib/functions/funcUtils';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { FormControl, FormField, FormItem } from '@/components/ui/form';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { addQuestions } from '@/redux/slices/unsolicitedSlice';
import { UnsolicitedQuestions } from '@/lib/types/constants';

interface ProblemAndImpactProps {
	form: UseFormReturn<any, any, undefined>;
	answerStreaming: boolean;
	setAnswerStreaming: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProblemAndImpact: FC<ProblemAndImpactProps> = ({
	form,
	answerStreaming,
	setAnswerStreaming,
}) => {
	const question = useSelector((root: RootState) => root.unsolicited.questions);
	// State for answer2 and answer3
	const [answer2, setAnswer2] = useState<string>(question['question2'] || '');
	const [answer3, setAnswer3] = useState<string>(question['question3'] || '');
	const [answer4, setAnswer4] = useState<string>(question['question4'] || '');
	const [answer5, setAnswer5] = useState<string>(
		question['question5'] || 'month'
	);
	const answer2Ref = useRef<HTMLTextAreaElement>(null);
	const answer3Ref = useRef<HTMLTextAreaElement>(null);
	const [intervalId2, setIntervalId2] = useState<ReturnType<
		typeof setInterval
	> | null>(null);
	const [intervalId3, setIntervalId3] = useState<ReturnType<
		typeof setInterval
	> | null>(null);
	const unsolicitation = useSelector(
		(root: RootState) => root.unsolicited.unsolicitation
	);
	const token = getToken();

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			addQuestions({
				question2: answer2,
				question3: answer3,
				question4: answer4,
				question5: answer5,
			})
		);
	}, [answer2, answer3, answer4, answer5, dispatch]);

	useEffect(() => {
		form.setValue('question2', answer2);
		form.setValue('question3', answer3);
		form.setValue('question4', answer4);
		form.setValue('question5', answer5);
		form.trigger('question2');
	}, [answer4, answer2, answer3, answer5, form]);

	const [handleSuggestResponse2] = useSuggestionHandler({
		token,
		documentId: unsolicitation?.id,
		questionId: 2,
		onInit: () => {
			setAnswerStreaming(true);
			setAnswer2('');

			const interval = typeWithInterval(
				answer2Ref,
				'Hold on tight while I write this response',
				10
			);
			setIntervalId2(interval);
		},
		onStart: () => {
			if (intervalId2) {
				clearInterval(intervalId2);
			}
			setAnswer2('');
		},
		onMessage: (message: string) => {
			setAnswer2((prev) => {
				const updatedAnswer = prev + message;
				form.setValue('question2', updatedAnswer); // Update form state with streamed data
				return updatedAnswer;
			});
			if (answer2Ref.current) {
				answer2Ref.current.scrollTop = answer2Ref.current.scrollHeight;
			}
		},
		onEnd: () => {
			setAnswerStreaming(false);
			setAnswer2((prevAnswer) => {
				form.setValue('question2', prevAnswer || ''); // Ensure final answer is saved in form
				return prevAnswer || '';
			});
			form.trigger('question2');
		},
		onError: () => {
			setAnswerStreaming(false);
		},
	});

	// Suggestion handler for question 3
	const [handleSuggestResponse3] = useSuggestionHandler({
		token,
		documentId: unsolicitation?.id,
		questionId: 3,
		onInit: () => {
			setAnswerStreaming(true);
			setAnswer3('');
			const interval = typeWithInterval(
				answer3Ref,
				'Hold on tight while I write this response',
				10
			);
			setIntervalId3(interval);
		},
		onStart: () => {
			if (intervalId3) clearInterval(intervalId3);
			setAnswer3('');
		},
		onMessage: (message: string) => {
			setAnswer3((prev) => {
				const updatedAnswer = prev + message;
				form.setValue('question3', updatedAnswer); // Update form state with streamed data
				return updatedAnswer;
			});
			if (answer3Ref.current) {
				answer3Ref.current.scrollTop = answer3Ref.current.scrollHeight;
			}
		},
		onEnd: () => {
			setAnswerStreaming(false);
			setAnswer3((prevAnswer) => {
				form.setValue('question3', prevAnswer || ''); // Ensure final answer is saved in form
				return prevAnswer || '';
			});
			form.trigger('question3');
		},
		onError: () => setAnswerStreaming(false),
	});

	return (
		<div className="my-4 2xl:my-6">
			<div className="relative">
				<div className="my-3 pb-2 hover:bg-[#F0F0F0] border border-[rgba(93, 155, 253, 0.30)] rounded-xl">
					<FormField
						control={form.control}
						name="question2"
						rules={{ required: 'This field is required' }}
						render={({ field }) => {
							return (
								<FormItem className="flex flex-col w-full overflow-hidden">
									<label className="py-3 mx-4 font-bold text-black border-b ">
										{UnsolicitedQuestions.two}
										<span className="ml-2 font-semibold text-secondary">
											(required)
										</span>
									</label>

									<FormControl>
										<Textarea
											{...field}
											className={cn(
												'text-black bg-transparent border-0 animated-container-transition resize-none placeholder:text-[rgba(135,133,133,0.7)] placeholder:text-base px-4  text-sm  scroll-smooth h-auto min-h-20 2xl:min-h-32'
											)}
											ref={answer2Ref}
											value={answer2}
											onChange={(e) => {
												setAnswer2(e.target.value);
												form.setValue('question2', e.target.value);
												form.trigger('question2');
											}}
											placeholder="Please describe your organization in some detail. Keep in mind that the AI model will look at this description, your area of work and your organization name when creating content for you."
										></Textarea>
									</FormControl>
								</FormItem>
							);
						}}
					/>
					<div className="flex justify-end w-full px-5 py-2">
						<button
							className={cn(
								'flex dynamic-small text-primary gap-1 leading-normal items-center font-bold suggest-response',
								answerStreaming
									? 'cursor-ga opacity-50'
									: 'cursor-pointer animated-hover-effect'
							)}
							disabled={answerStreaming}
							onClick={handleSuggestResponse2}
						>
							<AiIcon className="h-5" />
							SUGGEST A RESPONSE TO THIS
						</button>
					</div>
				</div>
			</div>

			<div className="relative">
				<div className="my-3 pb-2 hover:bg-[#F0F0F0] border border-[rgba(93, 155, 253, 0.30)] rounded-xl">
					<FormField
						control={form.control}
						name="question3"
						rules={{ required: 'This field is required' }}
						render={({ field }) => {
							return (
								<FormItem className="flex flex-col w-full overflow-hidden">
									<label className="py-3 mx-4 font-bold text-black border-b ">
										{UnsolicitedQuestions.three}
										<span className="ml-2 font-semibold text-secondary">
											(required)
										</span>
									</label>

									<FormControl>
										<Textarea
											{...field}
											className={cn(
												'text-black bg-transparent border-0 animated-container-transition resize-none placeholder:text-[rgba(135,133,133,0.7)] placeholder:text-base px-4  text-sm  scroll-smooth h-auto min-h-20 2xl:min-h-32'
											)}
											ref={answer3Ref}
											value={answer3}
											onChange={(e) => {
												setAnswer3(e.target.value);
												form.setValue('question3', e.target.value);
												form.trigger('question3');
											}}
											placeholder="Please describe your organization in some detail. Keep in mind that the AI model will look at this description, your area of work and your organization name when creating content for you."
										></Textarea>
									</FormControl>
								</FormItem>
							);
						}}
					/>
					<div className="flex justify-end w-full px-5 py-2">
						<button
							className={cn(
								'flex dynamic-small text-primary gap-1 leading-normal items-center font-bold suggest-response',
								answerStreaming
									? 'cursor-ga opacity-50'
									: 'cursor-pointer animated-hover-effect'
							)}
							disabled={answerStreaming}
							onClick={handleSuggestResponse3}
						>
							<AiIcon className="h-5" />
							SUGGEST A RESPONSE TO THIS
						</button>
					</div>
				</div>
			</div>
			<label className="font-bold text-black ">
				{UnsolicitedQuestions.forth}{' '}
				<span className="ml-1 font-semibold text-secondary">(required)</span>
			</label>
			<div className="flex items-center justify-start gap-2 mt-2 max-w-96">
				<FormField
					control={form.control}
					name="question4"
					rules={{ required: 'This field is required' }}
					render={({ field }) => {
						return (
							<FormItem className="flex flex-col w-full overflow-hidden">
								<FormControl>
									<Input
										{...field}
										value={answer4}
										type="number"
										className="p-4 max-w-44 rounded-xl border border-[#D3DDE2] bg-white h-12 my-2"
										placeholder="Enter"
										onChange={(e) => {
											form.setValue('question4', e.target.value);
											setAnswer4(e.target.value);
											form.trigger('question4');
										}}
									/>
								</FormControl>
							</FormItem>
						);
					}}
				/>

				<FormField
					control={form.control}
					name="question5"
					render={({ field }) => {
						return (
							<FormItem className="flex flex-col w-full overflow-hidden">
								<FormControl>
									<Select
										{...field}
										defaultValue={answer5}
										onValueChange={(value) => {
											form.setValue('question5', value);
											setAnswer5(value);
										}}
									>
										<SelectTrigger className="max-w-44 p-4 rounded-xl border border-[#D3DDE2] bg-white h-12">
											<SelectValue placeholder="Month" />
										</SelectTrigger>
										<SelectContent className="bg-white">
											<SelectItem
												value="month"
												className="text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
											>
												Months
											</SelectItem>
											<SelectItem
												value="year"
												className="text-black dynamic-small py-2 pr-0.5 cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
											>
												Years
											</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						);
					}}
				/>
				{form.formState.errors.root?.message && (
					<p className="text-red-500">{form.formState.errors.root?.message}</p>
				)}
			</div>
		</div>
	);
};

export default ProblemAndImpact;
