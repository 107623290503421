import { SVGProps } from 'react';

const Document = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
		>
			<path
				d="M12.9582 0.5H5.04179C2.25729 0.5 0 2.63459 0 5.26775V13.7323C0 16.3654 2.25729 18.5 5.04179 18.5H12.9582C15.7427 18.5 18 16.3654 18 13.7323V5.26775C18 2.63459 15.7427 0.5 12.9582 0.5Z"
				fill="#E861C9"
			/>
			<path
				d="M13.396 7.85599L4.86793 7.85554C4.17608 7.8555 3.61526 8.41809 3.6153 9.11211L3.61554 13.619C3.61558 14.313 4.17646 14.8757 4.8683 14.8757L13.3964 14.8761C14.0882 14.8762 14.649 14.3136 14.649 13.6196L14.6488 9.1127C14.6487 8.41868 14.0878 7.85603 13.396 7.85599Z"
				stroke="white"
			/>
			<path
				d="M6.69981 3.85354L4.18011 3.89638C3.79587 3.90291 3.48969 4.22068 3.49624 4.60614L3.50683 5.22925C3.51339 5.61471 3.83019 5.92189 4.21444 5.91535L6.73413 5.87252C7.11837 5.86599 7.42456 5.54822 7.418 5.16276L7.40741 4.53965C7.40086 4.15419 7.08405 3.84701 6.69981 3.85354Z"
				stroke="white"
			/>
		</svg>
	);
};

export default Document;
