import {
	Sheet,
	SheetContent,
	SheetOverlay,
	SheetTrigger,
} from '@/components/ui/sheet';
import { useState } from 'react';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import AssistanceTab from '@/components/AssistanceTab/AssistanceTab';
import AIWhite from '@/components/ui/icons/new/AIWhite';

const LogicalFrameDrawer = () => {
	const [selectedTab, setSelectedTab] = useState('assistant');

	const isLogicalFrame = location.pathname.includes('/logical-frame');

	return (
		<Sheet>
			<SheetTrigger className="fixed z-50 top-2 right-4">
				<div className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-transparent border border-white rounded-lg animated-hover-effect animated-hover-color-16 ">
					<AIWhite /> Assistant
				</div>
			</SheetTrigger>
			<SheetOverlay>
				<SheetContent className="!max-w-[450px] p-0 bg-[#f1f3f5]">
					<AppTabs
						defaultTab="assistant"
						type="drawer"
						handleTabChange={(val: string) => {
							setSelectedTab(val);
						}}
						selectedTab={selectedTab}
						tabs={[
							{
								label: 'Assistance',
								value: 'assistant',
								content: (
									<AssistanceTab mode={isLogicalFrame ? 'logical-frame' : ''} />
								),
							},
						]}
					/>
				</SheetContent>
			</SheetOverlay>
		</Sheet>
	);
};

export default LogicalFrameDrawer;
