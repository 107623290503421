import { RootState } from '@/redux/store/store';
import { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import DocumentPage from './../../pages/DocumentPage/DocumentPage';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';

interface DocumentsPaneProps {
	defaultTab: 'documents' | 'pastproposals' | 'lists' | 'resumes';
}

const DocumentsPane: FC<DocumentsPaneProps> = ({ defaultTab }) => {
	const navigate = useNavigate();
	const isStreaming = useSelector((root: RootState) => root.page.isStreaming);

	const [selectedTab, setSelectedTab] = useState(defaultTab);
	const [dirtyTab, setDirtyTab] = useState(defaultTab);

	const handleTabChange = useCallback(
		(newTabIndex) => {
			setDirtyTab(newTabIndex);

			setTimeout(() => {
				setSelectedTab(newTabIndex);
				if (newTabIndex === 'documents') {
					navigate(`/document`);
				} else {
					navigate(`/document/${newTabIndex}`);
				}
			}, 250);
		},
		[navigate]
	);

	useEffect(() => {
		if (isStreaming) return;
		handleTabChange(defaultTab);
	}, [defaultTab]);

	return (
		<AppTabs
			defaultTab={defaultTab}
			selectedTab={selectedTab}
			handleTabChange={handleTabChange}
			type="main"
			tabs={[
				{
					label: 'Documents',
					value: 'documents',
					content: <DocumentPage exiting={dirtyTab !== 'documents'} />,
				},
				// {
				// 	label: 'Past Proposals',
				// 	value: 'pastproposals',
				// 	content: <DocumentPage exiting={dirtyTab !== 'pastproposals'} />,
				// },
				// {
				// 	label: 'Lists',
				// 	value: 'lists',
				// 	content: <DocumentPage exiting={dirtyTab !== 'lists'} />,
				// },
				// {
				// 	label: 'Resumes',
				// 	value: 'resumes',
				// 	content: <DocumentPage exiting={dirtyTab !== 'resumes'} />,
				// },
			]}
		/>
	);
};

export default DocumentsPane;
