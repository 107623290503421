import { SVGProps } from 'react';

const Matrix = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="31"
			height="28"
			viewBox="0 0 31 28"
			fill="none"
		>
			<g clipPath="url(#clip0_4500_9694)">
				<path
					d="M18.3534 27.7339H4.11344C1.85344 27.7339 0.0234375 25.8939 0.0234375 23.6439V4.54386C0.0234375 2.28386 1.86344 0.453857 4.11344 0.453857H18.3534C20.6134 0.453857 22.4434 2.28386 22.4434 4.54386V23.6439C22.4434 25.9039 20.6034 27.7339 18.3534 27.7339ZM4.11344 1.95386C2.68344 1.95386 1.52344 3.11386 1.52344 4.54386V23.6439C1.52344 25.0739 2.68344 26.2339 4.11344 26.2339H18.3534C19.7834 26.2339 20.9434 25.0739 20.9434 23.6439V4.54386C20.9434 3.11386 19.7834 1.95386 18.3534 1.95386H4.11344Z"
					fill="#5D9BFD"
				/>
				<path
					d="M18.1525 6.9939H4.3125C3.9025 6.9939 3.5625 6.6539 3.5625 6.2439C3.5625 5.8339 3.9025 5.4939 4.3125 5.4939H18.1525C18.5625 5.4939 18.9025 5.8339 18.9025 6.2439C18.9025 6.6539 18.5625 6.9939 18.1525 6.9939Z"
					fill="#5D9BFD"
				/>
				<path
					d="M12.3425 12.2239H4.3125C3.9025 12.2239 3.5625 11.8839 3.5625 11.4739C3.5625 11.0639 3.9025 10.7239 4.3125 10.7239H12.3425C12.7525 10.7239 13.0925 11.0639 13.0925 11.4739C13.0925 11.8839 12.7525 12.2239 12.3425 12.2239Z"
					fill="#5D9BFD"
				/>
				<path
					d="M11.2325 17.4639H4.3125C3.9025 17.4639 3.5625 17.1239 3.5625 16.7139C3.5625 16.3039 3.9025 15.9639 4.3125 15.9639H11.2325C11.6425 15.9639 11.9825 16.3039 11.9825 16.7139C11.9825 17.1239 11.6425 17.4639 11.2325 17.4639Z"
					fill="#5D9BFD"
				/>
				<path
					d="M11.9142 22.7039H4.32422C3.91422 22.7039 3.57422 22.3639 3.57422 21.9539C3.57422 21.5439 3.91422 21.2039 4.32422 21.2039H11.9142C12.3242 21.2039 12.6642 21.5439 12.6642 21.9539C12.6642 22.3639 12.3242 22.7039 11.9142 22.7039Z"
					fill="#5D9BFD"
				/>
				<path
					d="M21.6931 25.2439C16.8731 25.2439 12.9531 21.3239 12.9531 16.5039C12.9531 11.6839 16.8731 7.76392 21.6931 7.76392C26.5131 7.76392 30.4331 11.6839 30.4331 16.5039C30.4331 21.3239 26.5131 25.2439 21.6931 25.2439Z"
					fill="#5D9BFD"
				/>
				<path
					d="M21.6931 9.27393C17.7031 9.27393 14.4531 12.5239 14.4531 16.5139C14.4531 20.5039 17.7031 23.7539 21.6931 23.7539C25.6831 23.7539 28.9331 20.5039 28.9331 16.5139C28.9331 12.5239 25.6831 9.27393 21.6931 9.27393Z"
					fill="white"
				/>
				<path
					d="M20.5925 20.4239C20.3725 20.4239 20.1525 20.3239 20.0125 20.1439L16.9225 16.3039C16.6625 15.9839 16.7125 15.5139 17.0325 15.2539C17.3525 14.9939 17.8225 15.0439 18.0825 15.3639L20.7125 18.6339L26.6625 14.0739C26.9925 13.8239 27.4625 13.8839 27.7125 14.2139C27.9625 14.5439 27.9025 15.0139 27.5725 15.2639L21.0425 20.2639C20.9025 20.3639 20.7425 20.4139 20.5825 20.4139L20.5925 20.4239Z"
					fill="#5D9BFD"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4500_9694">
					<rect
						width="30.41"
						height="27.28"
						fill="white"
						transform="translate(0.0234375 0.453857)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Matrix;
