import { SVGProps } from 'react';

const History = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<mask
				id="mask0_5278_19986"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="25"
				height="24"
			>
				<rect x="0.0195312" width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_5278_19986)">
				<path
					d="M12.0195 21C9.71953 21 7.71536 20.2375 6.00703 18.7125C4.2987 17.1875 3.31953 15.2833 3.06953 13H5.11953C5.35286 14.7333 6.1237 16.1667 7.43203 17.3C8.74036 18.4333 10.2695 19 12.0195 19C13.9695 19 15.6237 18.3208 16.982 16.9625C18.3404 15.6042 19.0195 13.95 19.0195 12C19.0195 10.05 18.3404 8.39583 16.982 7.0375C15.6237 5.67917 13.9695 5 12.0195 5C10.8695 5 9.79453 5.26667 8.79453 5.8C7.79453 6.33333 6.95286 7.06667 6.26953 8H9.01953V10H3.01953V4H5.01953V6.35C5.86953 5.28333 6.90703 4.45833 8.13203 3.875C9.35703 3.29167 10.6529 3 12.0195 3C13.2695 3 14.4404 3.2375 15.532 3.7125C16.6237 4.1875 17.5737 4.82917 18.382 5.6375C19.1904 6.44583 19.832 7.39583 20.307 8.4875C20.782 9.57917 21.0195 10.75 21.0195 12C21.0195 13.25 20.782 14.4208 20.307 15.5125C19.832 16.6042 19.1904 17.5542 18.382 18.3625C17.5737 19.1708 16.6237 19.8125 15.532 20.2875C14.4404 20.7625 13.2695 21 12.0195 21Z"
					fill="#617BE8"
				/>
				<path
					d="M11.0195 12.4L14.8195 16.2L16.2195 14.8L13.0195 11.6V7H11.0195V12.4Z"
					fill="#E861C9"
				/>
			</g>
		</svg>
	);
};

export default History;
