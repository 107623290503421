import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { updateDocumentCategory } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { RootState } from '@/redux/store/store';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { closeDialog } from '@/redux/slices/pageSlice';
import { useQueryClient } from 'react-query';
import CategoryDropdown from '@/components/CategoryDropdown/CategoryDropdown';

const ChangeDocumentCategoryDialog = () => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'changeDocumentCategory'
	);
	const documentId = useSelector(
		(root: RootState) => root.page.dialog?.documentId
	);
	const documentCategory = useSelector(
		(root: RootState) => root.page.dialog?.documentCategory
	);

	const [selectedCategory, setSelectedCategory] = useState<string>('Research');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleDocumentUpdate = async (documentCategory: string) => {
		try {
			if (!documentId) return;
			setIsLoading(true);
			const response = await updateDocumentCategory(
				documentCategory,
				documentId
			);
			handleResponse(
				response,
				'Document category has been updated successfully.'
			);
			queryClient.invalidateQueries('documents');
			dispatch(closeDialog());
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			setIsLoading(false);
			setSelectedCategory(documentCategory || 'Research');
		}
	}, [isOpen]);

	return (
		<Dialog open={isOpen}>
			<DialogContent className="max-w-[500px] p-7 bg-white rounded-xl">
				<DialogHeader className="font-bold text-secondary dynamic-medium">
					Change Document Category
				</DialogHeader>
				<div className="flex flex-col gap-3">
					<label className="font-bold text-black">Category</label>
					<CategoryDropdown
						className="h-11 w-full border border-primary"
						selectedCategory={selectedCategory}
						setSelectedCategory={setSelectedCategory}
					/>
				</div>
				<div className="flex items-center justify-end">
					<PrimaryButton
						disabled={isLoading || !selectedCategory}
						onClick={() => handleDocumentUpdate(selectedCategory)}
					>
						{isLoading ? 'Updating...' : 'Update'}
					</PrimaryButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeDocumentCategoryDialog;
