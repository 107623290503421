import { SVGProps } from 'react';

const TemplateBuilderIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="23"
			viewBox="0 0 18 23"
			fill="none"
		>
			<g clipPath="url(#clip0_8310_16314)">
				<path
					d="M17.0167 13.104L16.3987 12.294C16.2067 12.042 15.8467 11.994 15.5947 12.186L9.45671 16.872C9.34271 16.956 9.25271 17.07 9.18671 17.196L8.67071 18.186C8.44871 18.618 8.82671 19.11 9.30071 19.008L10.3927 18.768C10.5307 18.738 10.6627 18.678 10.7767 18.588L16.9147 13.902C17.1667 13.71 17.2147 13.35 17.0227 13.098L17.0167 13.104Z"
					stroke="white"
					strokeWidth="1.75"
				/>
				<path
					d="M14.7316 1.45703H3.15156C2.15745 1.45703 1.35156 2.26292 1.35156 3.25703V8.40503C1.35156 9.39914 2.15745 10.205 3.15156 10.205H14.7316C15.7257 10.205 16.5316 9.39914 16.5316 8.40503V3.25703C16.5316 2.26292 15.7257 1.45703 14.7316 1.45703Z"
					stroke="white"
					strokeWidth="2"
				/>
				<path
					d="M16.5316 17.6933V19.9133C16.5316 20.7833 15.8236 21.4913 14.9536 21.4913H2.92956C2.05956 21.4913 1.35156 20.7833 1.35156 19.9133V14.4413C1.35156 13.5713 2.05956 12.8633 2.92956 12.8633H9.40956"
					stroke="white"
					strokeWidth="2"
				/>
				<path d="M4.89062 4.35547H13.2306" stroke="white" strokeWidth="2" />
				<path d="M4.89062 7.2832H11.1966" stroke="white" strokeWidth="2" />
			</g>
			<defs>
				<clipPath id="clip0_8310_16314">
					<rect
						width="17.28"
						height="21.534"
						fill="white"
						transform="translate(0.601562 0.707031)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default TemplateBuilderIcon;
