import { SVGProps } from 'react';

const Save = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
		>
			<path
				d="M18 4.86475V16.8647C18 17.4147 17.8042 17.8856 17.4125 18.2772C17.0208 18.6689 16.55 18.8647 16 18.8647H2C1.45 18.8647 0.979167 18.6689 0.5875 18.2772C0.195833 17.8856 0 17.4147 0 16.8647V2.86475C0 2.31475 0.195833 1.84391 0.5875 1.45225C0.979167 1.06058 1.45 0.864746 2 0.864746H14L18 4.86475ZM16 5.71475L13.15 2.86475H2V16.8647H16V5.71475ZM9 15.8647C9.83333 15.8647 10.5417 15.5731 11.125 14.9897C11.7083 14.4064 12 13.6981 12 12.8647C12 12.0314 11.7083 11.3231 11.125 10.7397C10.5417 10.1564 9.83333 9.86475 9 9.86475C8.16667 9.86475 7.45833 10.1564 6.875 10.7397C6.29167 11.3231 6 12.0314 6 12.8647C6 13.6981 6.29167 14.4064 6.875 14.9897C7.45833 15.5731 8.16667 15.8647 9 15.8647ZM3 7.86475H12V3.86475H3V7.86475Z"
				fill="#6279E6"
			/>
		</svg>
	);
};

export default Save;
