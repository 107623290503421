import { Step } from 'intro.js-react';
export const ProposalCreationStepFour: Step[] = [
	{
		element: '.win-themes',
		title: 'Win themes',
		intro: (
			<div className="flex flex-col gap-1 text-black">
				<p>
					You will be presented with a series of 4 questions that will help us
					determine your approach and win themes.
				</p>
				<p>
					You can easily change these later in the system, so don’t worry if
					they are not perfect right now.
				</p>
			</div>
		),
		position: 'right',
	},
	{
		element: '.guidelines',
		title: 'Guidance',
		intro:
			'This section will guide you on how to approach this question while keeping the solicitation as context.',
		position: 'top',
	},
	{
		element: '.suggest-response',
		title: 'Suggest a response',
		intro:
			'If you are unsure how to answer this question, click here. The system will generate a response based on the solicitation, any attached document, and your organization’s general information.',
		position: 'left',
	},
];
