// authSlice.ts
import { FocusAreaCategoryFilter } from '@/lib/types/apiTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

export interface DiscoverFilters {
	title: string;
	category: FocusAreaCategoryFilter;
	countries: string[];
	fundingAmount: {
		lte: number;
		gte: number;
	};
	funder: string[];
	type: string;
	// sort: 'latest' | 'oldest' | '';
	action: 'matched' | 'favourite' | 'default';
}

interface DiscoverState {
	filters: Partial<DiscoverFilters>;
	areFilterApplied: boolean;
}

const initialFilters: DiscoverFilters = {
	title: '',
	category: {},
	countries: [],
	fundingAmount: {
		lte: 0,
		gte: 0,
	},
	funder: [],
	type: '',
	// sort: '',
	action: 'default',
};

const initialState: DiscoverState = {
	filters: {},
	areFilterApplied: false,
};

const discoverSlice = createSlice({
	name: 'discover',
	initialState,
	reducers: {
		setFilters: (state, action: PayloadAction<Partial<DiscoverFilters>>) => {
			const filters = Object.assign(state.filters, action.payload);
			state.filters = Object.entries(filters)
				.filter(([key, value]) => !isEqual(value, initialFilters[key]))
				.reduce((obj, [key, value]) => {
					obj[key] = value;
					return obj;
				}, {});

			if (Object.keys(state.filters).length > 0) {
				state.areFilterApplied = true;
			} else {
				state.areFilterApplied = false;
			}
		},
		clearFilters: (state) => {
			state.filters = {};
			state.areFilterApplied = false;
		},
	},
});

export const { setFilters, clearFilters } = discoverSlice.actions;
export default discoverSlice.reducer;
