import styles from './AuditLogsPage.module.css';
import { cn } from '@/lib/utils';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AuditLogsTable } from '@/components/Tables/AuditLogsTable/AuditLogsTable';
import { columns } from '@/components/Tables/AuditLogsTable/column';
import { AuditLogsTableProps, UsersTableProps } from '@/lib/types/constants';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	ApiResponse,
	AuditLogsPayload,
	SortingType,
} from '@/lib/types/apiTypes';
import {
	getAuditLogs,
	exportAuditLogs,
	getUsers,
} from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	getToken,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { motion, useAnimation } from 'framer-motion';
import TableHeader from '@/components/TableHeader/TableHeader';
import { ChevronDown } from 'lucide-react';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import {
	Multiselect,
	SelectItemProp,
} from '@/components/ui/shared/Multiselect/Multiselect';
import DateRangePicker from '@/components/ui/shared/DateRangePicker/DateRangePicker';
import { DateRange } from 'react-day-picker';
import IconButton from '@/components/ui/shared/Button/IconButton/IconButton';
import CSV from '@/components/ui/icons/new/CSV';
import { PaginationState } from '@tanstack/react-table';

const categories = [
	'Authentication',
	'User Management',
	'Discover Service',
	'Audit Logs',
	'Organization Management',
	'Content Management',
];
const statuses = [
	'Success',
	'Created',
	'Accepted',
	'No Content',
	'Bad Request',
	'Unauthorized',
	'Payment Required',
	'Forbidden',
	'Not Found',
	'Method Not Allowed',
	'Not Acceptable',
	'Request Timeout',
	'Conflict',
	'Too Many Requests',
	'Internal Server Error',
	'Not Implemented',
	'Bad Gateway',
	'Service Unavailable',
	'Gateway Timeout',
];

interface AuditLogsProps {
	exiting?: boolean;
}

const AuditLogs: FC<AuditLogsProps> = ({ exiting }) => {
	const dispatch = useAppDispatch();
	const [auditLogsData, setAuditLogsData] = useState<AuditLogsTableProps[]>([]);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [filterPopover, setFilterPopover] = useState(false);
	const [dateFilterPopover, setDateFilterPopover] = useState(false);
	const [date, setDate] = useState<DateRange | undefined>();
	const [appliedDate, setAppliedDate] = useState<DateRange | undefined>();
	const [users, setUsers] = useState<SelectItemProp[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<SelectItemProp[]>([]);
	const [appliedUsers, setAppliedUsers] = useState<SelectItemProp[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<SelectItemProp[]>(
		[]
	);
	const [appliedCategory, setAppliedCategory] = useState<SelectItemProp[]>([]);
	const [selectedStatus, setSelectedStatus] = useState<SelectItemProp[]>([]);
	const [appliedStatus, setAppliedStatus] = useState<SelectItemProp[]>([]);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 8,
	});
	const [sortOrder, setSortOrder] = useState<SortingType>('DESC');
	const [currentPage, setCurrentPage] = useState<number>(1);

	const setUsersList = (usersData: UsersTableProps[]) => {
		const userList: SelectItemProp[] = [];
		usersData?.length &&
			usersData.forEach((dataItem) => {
				if (!userList.find((userItem) => userItem.value === dataItem.email)) {
					userList.push({ value: dataItem.email, label: dataItem.email });
				}
			});
		setUsers(userList);
	};

	const loadUsers = async () => {
		try {
			const token = getToken();
			dispatch(processStart());
			const response: ApiResponse = await getUsers(token, 'active');
			handleResponse(response);
			const usersData = response?.data as any;
			setUsersList(usersData);
			dispatch(processSuccess());
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const loadAuditLogsData = async (payload: AuditLogsPayload) => {
		try {
			const token = getToken();
			dispatch(processStart());
			const response: ApiResponse = await getAuditLogs(token, payload);
			handleResponse(response);
			const auditData = response?.data as any;
			setAuditLogsData(auditData.data);
			setTotalRecords(auditData.totalRecords);
			setTotalPages(auditData.totalPages);
			setCurrentPage(auditData.currentPage);
			dispatch(processSuccess());
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const handleLogsExport = async () => {
		try {
			dispatch(processStart());
			const payload = getFormattedPayload();
			const response = await exportAuditLogs(payload);
			const blob = new Blob([response as ArrayBuffer], {
				type: 'application/csv',
			});
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = 'logs.csv';
			link.click();

			dispatch(processSuccess());
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const convertSelectItemToString = (list?: SelectItemProp[]): string[] => {
		return list ? list.map((item) => item.value) : [];
	};
	const convertStringToSelectItem = (list?: string[]): SelectItemProp[] => {
		return list
			? list.map((item) => {
					return { value: item, label: item };
				})
			: [];
	};

	const handleFilters = () => {
		const pageIndex = 0;
		setAppliedUsers(selectedUsers);
		setAppliedCategory(selectedCategory);
		setAppliedStatus(selectedStatus);
		setFilterPopover(false);
		setPagination({ pageIndex: pageIndex, pageSize: pagination.pageSize });
		loadAuditLogsData({
			pageNumber: pagination.pageIndex,
			pageSize: pagination.pageSize,
			date: appliedDate,
			userEmails: convertSelectItemToString(selectedUsers),
			category: convertSelectItemToString(selectedCategory),
			status: convertSelectItemToString(selectedStatus),
			sortOrder: sortOrder,
		});
	};

	const handleDateRangeFilter = () => {
		const pageIndex = 0;
		setAppliedDate(date);
		setDateFilterPopover(false);
		setPagination({ pageIndex: pageIndex, pageSize: pagination.pageSize });
		loadAuditLogsData({
			pageNumber: pagination.pageIndex,
			pageSize: pagination.pageSize,
			date: date,
			userEmails: convertSelectItemToString(appliedUsers),
			category: convertSelectItemToString(appliedCategory),
			status: convertSelectItemToString(appliedStatus),
			sortOrder: sortOrder,
		});
	};

	const getFormattedPayload = (): AuditLogsPayload => {
		return {
			pageNumber: pagination.pageIndex,
			pageSize: pagination.pageSize,
			date: appliedDate,
			userEmails: convertSelectItemToString(appliedUsers),
			category: convertSelectItemToString(selectedCategory),
			status: convertSelectItemToString(selectedStatus),
			sortOrder: sortOrder,
		};
	};

	useEffect(() => {
		loadAuditLogsData(getFormattedPayload());
	}, [pagination.pageIndex, pagination.pageSize, sortOrder]);

	useEffect(() => {
		loadUsers();
	}, []);

	// Animations Handling

	const control = useAnimation();

	const startAnimationState = useMemo(() => {
		return {
			opacity: 0,
			scaleY: 0,
			translateY: '-50%',
		};
	}, []);

	const startOpeningAnimation = useMemo(() => {
		return {
			opacity: 1,
			scaleY: 1,
			translateY: 0,
			transition: {
				duration: 0.8,
				delay: 0.3,
				type: 'cubic-bezier',
				ease: [0.76, 0, 0.24, 1],
			},
		};
	}, []);

	const startExitingAnimation = useCallback(
		(exiting) => {
			if (exiting === true) {
				control.start({ opacity: 0, transition: { duration: 0.25 } });
			}
		},
		[control]
	);

	useEffect(() => {
		if (exiting) {
			startExitingAnimation(exiting);
		}
	}, [exiting, startExitingAnimation]);

	useEffect(() => {
		control.set(startAnimationState);
		control.start(startOpeningAnimation);
	}, [startOpeningAnimation]);

	return (
		<div className={cn(styles.wrapper, 'hidden-scroll')}>
			<motion.div
				className="flex flex-col w-full h-fit gap-6 p-6 rounded-xl"
				animate={control}
			>
				<div>
					<TableHeader
						count={totalRecords}
						title={'Audit Logs'}
						customLayout={
							<div className="flex items-center gap-4">
								<Popover open={filterPopover}>
									<PopoverTrigger
										className={cn(
											styles.button,
											'flex items-center justify-between h-10 2xl:h-11 px-5 min-w-[100px] font-bold dynamic-text text-primary bg-white border border-primary uppercase rounded-xl flex gap-2 hover:bg-primary hover:text-white animated-hover-effect'
										)}
										onClick={() =>
											setFilterPopover((popoverState) => !popoverState)
										}
									>
										Filter By
										<ChevronDown
											className="w-4 h-4 text-primary"
											strokeWidth={3}
										/>
									</PopoverTrigger>
									<PopoverContent
										className="flex flex-col w-[350px] gap-4 shadow-main bg-white rounded-xl p-4"
										align="center"
										onInteractOutside={() => {
											setSelectedUsers(appliedUsers);
											setSelectedCategory(appliedCategory);
											setSelectedStatus(appliedStatus);
											setFilterPopover(false);
										}}
									>
										<PrimaryButton onClick={handleFilters}>
											Filter
										</PrimaryButton>
										<Multiselect
											className="!h-12 !pt-2 text-primary"
											title="Select Email"
											options={users}
											canAddOption={false}
											value={selectedUsers}
											onSelectedValuesChange={(selectedUsers) =>
												setSelectedUsers(selectedUsers)
											}
										></Multiselect>
										<Multiselect
											className="!h-12 !pt-2 text-primary"
											title="Select Category"
											options={convertStringToSelectItem(categories)}
											canAddOption={false}
											value={selectedCategory}
											onSelectedValuesChange={(selectedCategory) =>
												setSelectedCategory(selectedCategory)
											}
										></Multiselect>
										<Multiselect
											className="!h-12 !pt-2 text-primary"
											title="Select Status"
											options={convertStringToSelectItem(statuses)}
											canAddOption={false}
											value={selectedStatus}
											onSelectedValuesChange={(selectedStatus) =>
												setSelectedStatus(selectedStatus)
											}
										></Multiselect>
									</PopoverContent>
								</Popover>
								<Popover open={dateFilterPopover}>
									<PopoverTrigger
										className={cn(
											styles.button,
											'flex items-center justify-between h-10 2xl:h-11 px-5 min-w-[100px] font-bold dynamic-text text-primary bg-white border border-primary uppercase rounded-xl flex gap-2 hover:bg-primary hover:text-white animated-hover-effect'
										)}
										onClick={() =>
											setDateFilterPopover((popoverState) => !popoverState)
										}
									>
										Filter By Date
										<ChevronDown
											className="w-4 h-4 text-primary"
											strokeWidth={3}
										/>
									</PopoverTrigger>
									<PopoverContent
										className="flex flex-col w-72 gap-4 shadow-main bg-white rounded-xl p-4"
										align="center"
										onInteractOutside={() => {
											setDateFilterPopover(false);
											setDate(appliedDate);
										}}
									>
										<PrimaryButton
											disabled={!!date?.from && !date?.to}
											onClick={handleDateRangeFilter}
										>
											Filter
										</PrimaryButton>
										<DateRangePicker
											date={date}
											setDate={setDate}
											toDate={new Date()}
										/>
									</PopoverContent>
								</Popover>
								<IconButton
									className={cn('p-0 text-primary')}
									type="button"
									onClick={() => handleLogsExport()}
								>
									<CSV className="h-8 text-primary" />
									Export CSV
								</IconButton>
							</div>
						}
					/>
					<div className="mx-auto max-w-[97%]">
						<AuditLogsTable
							columns={columns}
							data={auditLogsData}
							totalPages={totalPages}
							pagination={pagination}
							setPagination={setPagination}
							sortOrder={sortOrder}
							setSortOrder={setSortOrder}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default AuditLogs;
