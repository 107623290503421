import styles from './DateRangePicker.module.css';
import { cn } from '@/lib/utils';
import { FC } from 'react';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { CalendarDays as CalendarIcon } from 'lucide-react';

interface DateRangePickerProps {
	date?: DateRange;
	setDate: SelectRangeEventHandler;
	fromDate?: Date;
	toDate?: Date;
	label?: string;
	className?: string;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
	date,
	setDate,
	fromDate,
	toDate,
	label,
	className,
}) => {
	return (
		<Popover>
			<PopoverTrigger
				className={cn(
					'flex items-center justify-between border border-[#D3DDE2] px-4 rounded-xl h-12',
					!date && 'text-muted-foreground',
					className
				)}
			>
				{date?.from ? (
					date.to ? (
						<>
							{format(date.from, 'dd LLL y')} - {format(date.to, 'dd LLL y')}
						</>
					) : (
						format(date.from, 'dd LLL y')
					)
				) : (
					<span>{label || 'Pick a date'}</span>
				)}
				<CalendarIcon className="ml-4 h-6 w-6 text-primary" />
			</PopoverTrigger>
			<PopoverContent
				className="w-auto p-0 shadow-main bg-white rounded-xl"
				align="end"
			>
				<Calendar
					className={styles.calendar}
					initialFocus
					mode="range"
					defaultMonth={date?.from}
					selected={date}
					onSelect={setDate}
					numberOfMonths={2}
					fromDate={fromDate}
					toDate={toDate}
				/>
			</PopoverContent>
		</Popover>
	);
};

export default DateRangePicker;
