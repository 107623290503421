import { SVGProps } from 'react';

const EmptyEnhanceIllustration = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="330"
			height="220"
			viewBox="0 0 330 220"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_14597_26359)">
				<path
					d="M312.359 216.65H291.789C287.859 216.65 284.569 213.65 284.219 209.73L279.459 157.49H324.689L319.929 209.73C319.569 213.65 316.289 216.65 312.359 216.65Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M324.908 194.221H279.228L277.068 174.121H327.078L324.908 194.221Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.87"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M317.449 151.109H307.779V156.389H317.449V151.109Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M279.228 152.98H324.908L327.078 159.75H277.068L279.228 152.98Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.31"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M246.94 60.3594H22.3599C19.3599 60.3594 16.9199 62.7894 16.9199 65.7994V199.219C16.9199 202.219 19.3499 204.659 22.3599 204.659H246.94C249.94 204.659 252.38 202.229 252.38 199.219V65.7994C252.38 62.7994 249.95 60.3594 246.94 60.3594Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M264.85 203.58H7.61023C4.51023 203.58 1.99023 206.09 1.99023 209.2V211.78C1.99023 214.88 4.51023 217.4 7.61023 217.4H264.86C267.96 217.4 270.48 214.88 270.48 211.78V209.2C270.48 206.1 267.96 203.58 264.86 203.58H264.85Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M25.3418 206.82H245.372"
					stroke="#AFC1DB"
					strokeWidth="6.69"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M235.833 95.2793L170.153 95.3593C167.233 95.3593 164.873 97.7293 164.873 100.649L164.893 113.229C164.893 116.149 167.263 118.509 170.183 118.509L235.863 118.429C238.783 118.429 241.153 116.059 241.143 113.139V100.559C241.123 97.6393 238.753 95.2793 235.833 95.2793Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M176.607 104.08H225.847"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M176.529 110.391H202.159"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M235.833 165.311L170.153 165.391C167.233 165.391 164.873 167.761 164.873 170.681L164.893 183.261C164.893 186.181 167.263 188.541 170.183 188.541L235.863 188.461C238.783 188.461 241.153 186.091 241.143 183.171V170.591C241.123 167.671 238.753 165.311 235.833 165.311Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M176.607 174.1H194.727"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M176.529 180.41H220.329"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M234.891 126.949L150.271 127.049C146.511 127.049 143.471 130.109 143.471 133.869L143.491 150.089C143.491 153.849 146.551 156.899 150.311 156.889L234.931 156.789C238.691 156.789 241.731 153.729 241.731 149.969L241.711 133.749C241.711 129.989 238.651 126.939 234.891 126.949Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M156.4 136.701L228.83 136.641"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M157.131 146.759L209.911 146.449"
					stroke="#AFC1DB"
					strokeWidth="3.72"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M121.421 41.8906L44.761 41.9806C38.831 41.9806 34.031 46.8006 34.041 52.7206L34.171 162.191C34.171 168.121 38.991 172.921 44.911 172.911L121.571 172.821C127.501 172.821 132.301 168.001 132.291 162.081L132.161 52.6106C132.161 46.6806 127.341 41.8806 121.411 41.8906H121.421Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.92"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M49.709 71.6889L93.319 71.6289"
					stroke="#AFC1DB"
					strokeWidth="3.79"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M49.7285 89.7002L108.869 89.4902"
					stroke="#AFC1DB"
					strokeWidth="3.81"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M49.7285 107.51L103.619 107.23"
					stroke="#AFC1DB"
					strokeWidth="3.81"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M51.0127 54.4193L55.6527 56.9493L51.0127 59.4893L48.4827 64.1293L45.9427 59.4893L41.3027 56.9493L45.9427 54.4193L48.4827 49.7793L51.0127 54.4193Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.39"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M32.5277 31.8011L40.4577 36.1411L32.5277 40.4811L28.1877 48.4111L23.8577 40.4811L15.9277 36.1411L23.8577 31.8011L28.1877 23.8711L32.5277 31.8011Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M301.003 97.6504C304.303 98.8304 305.943 102.73 305.723 106.23C305.503 109.73 303.853 112.95 302.383 116.12C300.913 119.29 299.553 122.72 299.963 126.2C300.313 129.18 301.923 131.85 302.823 134.71C303.723 137.57 303.723 141.14 301.433 143.09"
					stroke="white"
					strokeWidth="3.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M314.351 113.301C316.441 115.371 315.691 118.911 314.331 121.511C312.971 124.111 311.081 126.721 311.301 129.651C311.451 131.651 312.591 133.431 313.211 135.341C313.831 137.251 313.751 139.721 312.081 140.831"
					stroke="white"
					strokeWidth="3.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M291.172 116.83C289.242 117.27 288.002 119.29 287.872 121.26C287.742 123.23 288.492 125.15 289.352 126.93C290.212 128.71 291.192 130.46 291.582 132.4C292.262 135.79 291.052 139.24 289.852 142.49"
					stroke="white"
					strokeWidth="3.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M57.0409 30.8005C65.2754 30.8005 71.9509 24.125 71.9509 15.8905C71.9509 7.6559 65.2754 0.980469 57.0409 0.980469C48.8063 0.980469 42.1309 7.6559 42.1309 15.8905C42.1309 24.125 48.8063 30.8005 57.0409 30.8005Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="1.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M51.4395 19.8203C51.4395 20.8103 52.3495 21.6803 53.7495 22.2203C54.6695 22.5803 55.8095 22.7903 57.0395 22.7903C58.2695 22.7903 59.4095 22.5803 60.3295 22.2203C61.7295 21.6803 62.6395 20.8103 62.6395 19.8203"
					fill="white"
				/>
				<path
					d="M51.4395 19.8203C51.4395 20.8103 52.3495 21.6803 53.7495 22.2203C54.6695 22.5803 55.8095 22.7903 57.0395 22.7903C58.2695 22.7903 59.4095 22.5803 60.3295 22.2203C61.7295 21.6803 62.6395 20.8103 62.6395 19.8203"
					stroke="#AFC1DB"
					strokeWidth="1.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M62.2087 15.8705C63.7661 15.8705 65.0287 14.0483 65.0287 11.8005C65.0287 9.55267 63.7661 7.73047 62.2087 7.73047C60.6512 7.73047 59.3887 9.55267 59.3887 11.8005C59.3887 14.0483 60.6512 15.8705 62.2087 15.8705Z"
					fill="#AFC1DB"
					stroke="white"
					strokeWidth="2.62"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M51.2009 15.8705C52.7583 15.8705 54.0209 14.0483 54.0209 11.8005C54.0209 9.55267 52.7583 7.73047 51.2009 7.73047C49.6434 7.73047 48.3809 9.55267 48.3809 11.8005C48.3809 14.0483 49.6434 15.8705 51.2009 15.8705Z"
					fill="#AFC1DB"
					stroke="white"
					strokeWidth="2.62"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14597_26359">
					<rect
						width="329.02"
						height="219.4"
						fill="white"
						transform="translate(-0.00976562)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default EmptyEnhanceIllustration;
