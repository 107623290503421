import { Input } from '../ui/input';
import { useForm } from 'react-hook-form';
import { resetPassword } from '@/lib/functions/apiCalls';
import { useNavigate } from 'react-router';
import { ResetPasswordPayload } from '@/lib/types/apiTypes';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { Label } from '../ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '../ui/form';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '@/lib/hooks/hooks';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import { FC } from 'react';

interface FormData {
	newPassword: string;
	confirmPassword: string;
}

const formSchema = z
	.object({
		newPassword: z.string().min(2, {
			message: 'Please enter correct information before saving.',
		}),
		confirmPassword: z.string(),
	})
	.refine((data) => data.newPassword === data.confirmPassword, {
		message: "Passwords don't match",
		path: ['confirmPassword'],
	});

interface ResetPasswordFormProps {
	setIsLoading: (isLoading: boolean) => void;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ setIsLoading }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const dispatch = useAppDispatch();
	if (token === null) {
		navigate('/login');
	}

	const form = useForm<FormData>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			newPassword: '',
			confirmPassword: '',
		},
	});

	const formatResetPasswordPayload = (
		formData: FormData
	): ResetPasswordPayload => {
		return {
			password: formData.newPassword,
		};
	};

	const onSubmit = async (formData: FormData) => {
		try {
			if (!token) return;
			setIsLoading(true);
			const resetPasswordPayload: ResetPasswordPayload =
				formatResetPasswordPayload(formData);
			const response = await resetPassword(token, resetPasswordPayload);
			handleResponse(response, 'Password has been updated successfully.');
			setIsLoading(false);
			navigate('/login');
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	return (
		<div className="flex flex-col gap-6 px-8">
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(onSubmit)}
					className="flex flex-col gap-5 w-[500px]"
				>
					<FormField
						control={form.control}
						name="newPassword"
						render={({ field }) => {
							return (
								<FormItem>
									<Label>New password</Label>
									<FormControl>
										<Input
											className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
											placeholder="Enter new password"
											type="password"
											{...field}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
					<FormField
						control={form.control}
						name="confirmPassword"
						render={({ field }) => {
							return (
								<FormItem>
									<Label>Confirm password</Label>
									<FormControl>
										<Input
											className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
											placeholder="Enter confirm password"
											type="password"
											{...field}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
					<div className="flex justify-center w-full gap-4 mx-auto">
						<SecondaryButton onClick={() => navigate('/login')} type="button">
							CANCEL
						</SecondaryButton>
						<PrimaryButton type="submit">RESET PASSWORD</PrimaryButton>
					</div>
				</form>
			</Form>
		</div>
	);
};

export default ResetPasswordForm;
