import { ChartsRecommendation } from '@/lib/types/apiTypes';
import { FC } from 'react';
import ChartIcons from '../ui/icons/charts/ChartIcons';
import { cn } from '@/lib/utils';

import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	setSelectedChart,
	setSelectedChartPlot,
} from '@/redux/slices/chartSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

interface ChartItemProps {
	item: ChartsRecommendation;
}

const ChartItem: FC<ChartItemProps> = ({ item }) => {
	const dispatch = useAppDispatch();
	const activeChart = useSelector(
		(root: RootState) => root.chart.selectedChart
	);

	return (
		<div
			onClick={() => {
				dispatch(setSelectedChart(item.chart));
				dispatch(setSelectedChartPlot(item.plotSuggestions[0]));
			}}
			className={cn(
				`p-4 min-h-[210px] border border-[#D3DDE2] rounded-xl hover:no-underline hover:bg-[#EAF1FC]`,
				activeChart === item.chart ? 'bg-[#EAF1FC] border-[#5D9BFD]' : ''
			)}
		>
			<ChartIcons type={item.chart} />
			<p className="capitalize font-bold text-[#5D9BFD] py-2 dynamic-medium">
				{item.chart} Chart
			</p>
			<p className="dynamic-small text-[#5D6F79]">{item.reason}</p>
		</div>
	);
};

export default ChartItem;
