import { FC } from 'react';
import styles from './DashboardActionCard.module.css';
import { cn } from '@/lib/utils';

interface DashboardActionCardProps {
	card: {
		icon: JSX.Element;
		title: string;
		description: JSX.Element;
		onClick?: () => void;
	};
	extraClass: string;
}

const DashboardActionCard: FC<DashboardActionCardProps> = ({
	card,
	extraClass,
}) => {
	return (
		<div
			className={cn(
				styles.card,
				'flex flex-col items-center justify-start cursor-pointer hover:bg-[#EAF1FC] shadow-main',
				extraClass
			)}
			onClick={card.onClick}
		>
			<div className="flex items-center justify-center gap-2 p-1 rounded-lg w-14 h-14 bg-primary">
				{card.icon}
			</div>
			<h1 className="py-2 font-bold text-primary dynamic-large">
				{card.title}
			</h1>
			{card.description}
		</div>
	);
};

export default DashboardActionCard;
