import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build/build/ckeditor';
import { FC, useCallback, useEffect, useRef } from 'react';
import { CKEditorsProps } from '@/lib/types/constants';
import { updateSectionState } from '@/lib/functions/apiCalls';
import {
	getCharacterCount,
	getCKEditorText,
	getWordCount,
} from '@/lib/functions/funcUtils';
import { editorConfiguration } from '@/lib/functions/editor';
import { useParams } from 'react-router';
import { useAppDispatch } from '@/lib/hooks/hooks';
import useDebounce from '@/lib/hooks/useDebounce';
import { fetchSectionRules } from '@/redux/requests/compliance';
import {
	resetActiveReferences,
	setComplianceLoading,
} from '@/redux/slices/complianceMatrixSlice';
import {
	conciseObservable,
	enhanceObservable,
	expandObservable,
	historyObservable,
	updateSectionObservable,
	visualObservable,
} from '@/lib/observables/observables';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

const CKEditors: FC<CKEditorsProps> = ({
	onWordCountChange,
	onCharacterCountChange,
	placeholder,
	editorState,
	setIsEditorFocused,
	editorRef,
	isStreaming,
	proposalId,
	sectionId,
	setIsSaving,
	onReady,
	stopAutoSave,
}) => {
	const dispatch = useAppDispatch();
	const { nodeId } = useParams();
	const previousContentRef = useRef(editorState);
	const { activeSection, updateActiveSectionContent } = useActiveSection();

	const debouncedAutosave = useDebounce(async (editor: any) => {
		if (isStreaming || activeSection.sectionId !== sectionId) return;
		dispatch(setComplianceLoading(true));
		setIsSaving(true);

		let htmlContent = editor.getData();
		const wordCount = getWordCount(htmlContent);
		if (wordCount > 999) {
			setIsSaving(false);
			dispatch(setComplianceLoading(false));
			return;
		}
		let modelText = '';
		const root = editor.model.document.getRoot();
		for (const child of root.getChildren()) {
			for (const item of child.getChildren()) {
				if (item.is('text')) {
					modelText += item.data + ' ';
				}
			}
		}

		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlContent, 'text/html');
		const elements = doc.querySelectorAll('.compliance-reference');

		elements.forEach((el) => {
			el.removeAttribute('data-id');
			el.removeAttribute('class');
			el.classList.remove('compliance-reference', 'compliance-color');
		});

		// Select all span elements
		const spanElements = doc.querySelectorAll('span');

		// Replace each span element with its innerHTML
		spanElements.forEach((span) => {
			const parent = span.parentNode;
			while (span.firstChild) {
				// eslint-disable-next-line
				//@ts-ignore
				parent.insertBefore(span.firstChild, span);
			}
			// eslint-disable-next-line
			//@ts-ignore
			parent.removeChild(span);
		});

		htmlContent = doc.body.innerHTML;

		const sectionResponse = await updateSectionState(
			proposalId,
			Number(sectionId),
			{
				content: modelText.trim(),
				editorState: htmlContent,
			}
		);

		if (sectionResponse.statusCode === 200) {
			dispatch(fetchSectionRules({ proposalId, sectionId }));
			dispatch(setComplianceLoading(false));
			setIsSaving(false);
			updateActiveSectionContent(modelText.trim(), htmlContent);
		}
	}, 2000);

	const handleEditorChange = useCallback(
		(editor) => {
			const newContent = editor?.getData();

			if (newContent !== previousContentRef.current) {
				dispatch(resetActiveReferences());
				previousContentRef.current = newContent;
				if (!isStreaming && !stopAutoSave) {
					debouncedAutosave(editor);
				}
			}
		},
		[debouncedAutosave, isStreaming, stopAutoSave, dispatch]
	);

	useEffect(() => {
		const expandSubscription = expandObservable.subscribe(() => {
			debouncedAutosave(editorRef.current);
		});
		const enhanceSubscription = enhanceObservable.subscribe(() => {
			debouncedAutosave(editorRef.current);
		});
		const conciseSubscription = conciseObservable.subscribe(() => {
			debouncedAutosave(editorRef.current);
		});

		const visualSubscription = visualObservable.subscribe(() => {
			debouncedAutosave(editorRef.current);
		});

		const historySubscription = historyObservable.subscribe(() => {
			debouncedAutosave(editorRef.current);
		});

		const updateSectionSubscription = updateSectionObservable.subscribe(() => {
			debouncedAutosave(editorRef.current);
		});

		return () => {
			expandSubscription.unsubscribe();
			enhanceSubscription.unsubscribe();
			conciseSubscription.unsubscribe();
			visualSubscription.unsubscribe();
			historySubscription.unsubscribe();
			updateSectionSubscription.unsubscribe();
		};
	}, [debouncedAutosave, editorRef]);

	return (
		<>
			{isStreaming && <div className="overlay cursor-lock"></div>}
			<CKEditor
				editor={BalloonEditor}
				data={editorState === null ? '' : editorState}
				config={{
					...editorConfiguration,
					placeholder: placeholder,
					htmlSupport: {
						allow: [
							{
								attributes: true,
								classes: true,
								styles: true,
							},
						],
					},
				}}
				disabled={isStreaming}
				onReady={(editor) => {
					if (sectionId === Number(nodeId)) {
						editor.focus();
					}

					if (editorRef) {
						editorRef.current = editor;
					}
					if (onReady) onReady();

					const viewDocument = editor.editing.view.document;
					viewDocument.on('keydown', () => {
						handleEditorChange(editor);
					});
					viewDocument.on('focus', () => {
						dispatch(resetActiveReferences());
					});
				}}
				onChange={() => {
					const editorText = getCKEditorText(editorRef.current);
					const wordCount = getWordCount(editorText);
					const charCount = getCharacterCount(editorText);
					onWordCountChange(wordCount);
					onCharacterCountChange && onCharacterCountChange(charCount);
					if (wordCount > 999) return;
				}}
				onFocus={() => {
					setIsEditorFocused(true);
				}}
				onBlur={() => setIsEditorFocused(false)}
			/>
		</>
	);
};

export default CKEditors;
