import { FC, ReactNode, useState } from 'react';
import styles from './TableHeader.module.css';
import { cn } from '@/lib/utils';
import DeleteBlue from '../ui/icons/new/DeleteBlue';
import FilledHeart from '../ui/icons/new/FilledHeart';
import Archive from '../ui/icons/new/Archive';
import useAuth from '@/lib/hooks/useAuth';
import { Search, X } from 'lucide-react';
import useDebounce from '@/lib/hooks/useDebounce';
import { Input } from '../ui/input';

interface TableHeaderProps {
	title: string;
	onDelete?: () => void;
	onFavorite?: () => void;
	onArchive?: () => void;
	onUnArchive?: () => void;
	onUnFavorite?: () => void;
	onSearchChange?: (searchTerm: string) => void;
	filtersPanel?: ReactNode;
	count?: number;
	isFavDisabled?: boolean;
	isArchiveDisabled?: boolean;
	isRemovedDisabled?: boolean;
	customLayout?: ReactNode;
}

const TableHeader: FC<TableHeaderProps> = ({
	title,
	count,
	onDelete,
	onFavorite,
	onArchive,
	onUnArchive,
	onUnFavorite,
	onSearchChange,
	filtersPanel,
	isFavDisabled,
	isArchiveDisabled,
	isRemovedDisabled,
	customLayout,
}) => {
	const isArchive = location.pathname.includes('archives');
	const isFavourite = location.pathname.includes('favorites');
	const { isAdmin } = useAuth();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const debouncedSearchChange = useDebounce((searchTerm) => {
		onSearchChange && onSearchChange(searchTerm);
	}, 600);

	const renderButtons = () => {
		if (onFavorite && onArchive) {
			return (
				<>
					<div
						onClick={onFavorite}
						className={cn(
							'flex items-center gap-2 dynamic-small font-medium uppercase cursor-pointer text-primary animated-hover-effect',
							isFavDisabled ? 'opacity-60 pointer-events-none' : ''
						)}
					>
						<FilledHeart />
						<p className="mt-[2px]">Favorite</p>
					</div>
					{isAdmin && (
						<div
							onClick={onArchive}
							className={cn(
								'flex items-center gap-2 dynamic-small font-medium uppercase cursor-pointer text-primary animated-hover-effect',
								isArchiveDisabled ? 'opacity-60 pointer-events-none' : ''
							)}
						>
							<Archive />
							<p className="mt-1">Archive</p>
						</div>
					)}
				</>
			);
		}
	};
	return (
		<div className={cn(styles.card, 'flex items-center justify-between')}>
			<div className="flex items-center gap-6">
				<h2 className="font-bold text-secondary dynamic-large">{title}</h2>
				<div className="pl-6 text-black border border-t-0 border-b-0 border-r-0 border-l-1 border-blackSecondary">
					<span className="font-bold">{count?.toLocaleString() || ''}</span>{' '}
					{title}
				</div>
			</div>
			<div className="flex items-center gap-8">
				{onSearchChange && (
					<div className="relative">
						<Input
							value={searchTerm}
							onChange={(event) => {
								setSearchTerm(event.target.value);
								debouncedSearchChange(event.target.value);
							}}
							placeholder="Search"
							className="bg-white border-[#D3DDE2] w-72 h-9 px-4 py-2 dynamic-small rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-12"
						/>
						<div className="absolute right-8 top-2.5"></div>
						<div className="absolute right-3 top-2.5">
							{searchTerm !== '' ? (
								<X
									onClick={() => {
										setSearchTerm('');
										debouncedSearchChange('');
									}}
									className="cursor-pointer"
									color="#5D6F7999"
									size={16}
								/>
							) : (
								<Search color="#5D9BFD" size={16} />
							)}
						</div>
					</div>
				)}
				{filtersPanel && filtersPanel}
				{onDelete && (
					<div
						onClick={onDelete}
						className={cn(
							'flex items-center gap-2 dynamic-small font-medium uppercase cursor-pointer text-primary animated-hover-effect',
							isRemovedDisabled ? 'opacity-60 pointer-events-none' : ''
						)}
					>
						<DeleteBlue />
						<p className="mt-1">remove</p>
					</div>
				)}
				{!isFavourite && !isArchive && renderButtons()}
				{onUnArchive && (
					<div
						onClick={onUnArchive}
						className={cn(
							'flex items-center gap-2 dynamic-small font-medium uppercase cursor-pointer text-primary animated-hover-effect',
							isArchiveDisabled ? 'opacity-60 pointer-events-none' : ''
						)}
					>
						<Archive />
						<p className="mt-1">Unarchive</p>
					</div>
				)}
				{onUnFavorite && (
					<div
						onClick={onUnFavorite}
						className={cn(
							'flex items-center gap-2 dynamic-small font-medium uppercase cursor-pointer text-primary animated-hover-effect',
							isFavDisabled ? 'opacity-60 pointer-events-none' : ''
						)}
					>
						<FilledHeart />
						<p className="mt-[2px]">UnFavorite</p>
					</div>
				)}
				{customLayout && <>{customLayout}</>}
			</div>
		</div>
	);
};

export default TableHeader;
