import { SVGProps } from 'react';

const Scatter = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="67"
			height="67"
			viewBox="0 0 67 67"
			fill="none"
		>
			<circle cx="25.718" cy="12.5808" r="5.36645" fill="#BA59A4" />
			<circle cx="43.0579" cy="33.2209" r="5.36645" fill="#1CDBF3" />
			<path
				d="M3.83984 3.08643V63.3557H63.2836"
				stroke="#BDBDBD"
				strokeWidth="1.65444"
			/>
			<circle cx="52.136" cy="17.5344" r="5.36645" fill="#7C8DFC" />
			<circle cx="25.3052" cy="12.168" r="4.95364" fill="#EB47C5" />
			<circle cx="42.6451" cy="32.8081" r="4.95364" fill="#A8CAFF" />
			<circle cx="42.6451" cy="32.8081" r="4.95364" fill="#A8CAFF" />
			<circle cx="15.6054" cy="51.1781" r="5.16004" fill="#BA59A4" />
			<circle cx="51.7232" cy="17.1216" r="4.95364" fill="#5D9BFD" />
			<circle cx="23.035" cy="36.3173" r="5.16004" fill="#4385ED" />
			<circle cx="22.8286" cy="36.1109" r="4.95364" fill="#5D9BFD" />
			<circle cx="15.399" cy="50.9717" r="4.95364" fill="#E861C9" />
		</svg>
	);
};

export default Scatter;
