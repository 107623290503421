import DocumentBody from '@/components/DocumentBody/DocumentBody';
import DocumentHeader from '@/components/DocumentHeader/DocumentHeader';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import ExternalDocumentsPanel from '@/components/Panels/ExternalDocumentsPanel/ExternalDocumentsPanel';
import {
	getAllOrganizationDocuments,
	getFolders,
} from '@/lib/functions/apiCalls';
import {
	ErrorHandle,
	handleResponse,
	showToast,
} from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	Document as DocumentType,
	Folder,
	SortingType,
} from '@/lib/types/apiTypes';
import { closePanel } from '@/redux/slices/pageSlice';
import { useAnimation } from 'framer-motion';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useQuery, useInfiniteQuery } from 'react-query';

import { Page } from '@/constants/Onboarding/types';
import { useTour } from '@/lib/hooks/useTour';

interface DocumentPageProps {
	exiting?: boolean;
}

const pageSize = 12;

const DocumentPage: FC<DocumentPageProps> = ({ exiting }) => {
	const dispatch = useAppDispatch();
	const control = useAnimation();

	useTour(Page.Document);

	const [sortOrder, setSortOrder] = useState<SortingType>('DESC');
	const [selectedCategory, setSelectedCategory] = useState<string>('none');
	const [folderId, setFolderId] = useState<number | null>(null);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [foldersPath, setFoldersPath] = useState<Folder[]>([]);
	const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);

	const fetchDocs = async (pageParams = 1) => {
		try {
			const response = await getAllOrganizationDocuments({
				pageNumber: pageParams,
				pageSize: pageSize,
				sortOrder: sortOrder,
				...(selectedCategory !== 'none' && { category: selectedCategory }),
				...(searchTerm !== '' && { search: searchTerm }),
				...(folderId !== null && { folderId: folderId }),
			});
			handleResponse(response);
			return {
				documents: (response?.data as { documents: DocumentType[] })?.documents,
				nextCursor: (response?.data as any).currentPage + 1,
				pageParams: {
					pageNumber: (response?.data as any).currentPage,
					totalRecords: (response?.data as any).totalRecords,
				},
			};
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const {
		data,
		fetchNextPage,
		isFetchingNextPage,
		isLoading,
		isFetching,
		isFetchedAfterMount,
	} = useInfiniteQuery({
		queryKey: ['documents', sortOrder, selectedCategory, searchTerm, folderId],
		queryFn: ({ pageParam }) => fetchDocs(pageParam),
		getNextPageParam: (lastPage) => lastPage?.nextCursor || 1,
		onSuccess: () => deselectAllDocuments(),
		refetchOnWindowFocus: false,
	});

	// formating data from pages to documents array
	const documents =
		(data?.pages?.map((page) => page?.documents).flat() as DocumentType[]) ||
		[];
	const documentsCount = data?.pages?.[0]?.pageParams?.totalRecords;

	const fetchFolders = async () => {
		try {
			const response = await getFolders({
				...(folderId !== null && { parentId: folderId }),
			});
			handleResponse(response);
			return (response?.data as any)?.folders as Folder[];
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	const { data: folders, isFetching: isFolderFetching } = useQuery({
		queryKey: ['folders', folderId],
		queryFn: () => fetchFolders(),
		refetchOnWindowFocus: false,
	});

	const handleOpenFolder = (folderId: number | null) => {
		const currentFoldersPath = cloneDeep(foldersPath);
		if (!folderId) {
			setFoldersPath([]);
		}
		// If opened from list of folders
		const folder = folders?.find((folderItem) => folderItem.id === folderId);
		if (folder) {
			currentFoldersPath.push(folder);
			setFoldersPath(currentFoldersPath);
		}
		// If opened from path of folders
		const folderIndex = foldersPath.findIndex(
			(folderItem) => folderItem.id === folderId
		);
		if (folderIndex > -1) {
			currentFoldersPath.splice(folderIndex + 1);
			setFoldersPath(currentFoldersPath);
		}
		setFolderId(folderId);
	};

	const selectAllDocuments = () => {
		const allDocuments = documents.map((document) => document.id);
		if (allDocuments.length > 20) {
			showToast(
				'warning',
				'You have exceeded the limit of selecting 20 documents at once. Only first 20 documents will be selected.'
			);
			allDocuments.splice(20);
			setSelectedDocuments(allDocuments);
		} else {
			setSelectedDocuments(allDocuments);
		}
	};

	const deselectAllDocuments = () => {
		setSelectedDocuments([]);
	};

	const toggleDocumentSelection = (documentId) => {
		if (selectedDocuments.includes(documentId)) {
			setSelectedDocuments(
				selectedDocuments.filter((documentItem) => documentItem !== documentId)
			);
		} else {
			selectedDocuments.push(documentId);
			if (selectedDocuments.length > 20) {
				showToast(
					'warning',
					'You have exceeded the limit of selecting 20 documents at once. Please deselect some documents.'
				);
			} else {
				setSelectedDocuments(cloneDeep(selectedDocuments));
			}
		}
	};

	const startOpeningAnimation = useCallback(() => {
		control.set({ opacity: 0, y: '400px' });
		control.start({
			opacity: 1,
			y: 0,
			transition: { duration: 0.2, delay: 0.3 },
		});
	}, [control]);

	const startExitingAnimation = useCallback(
		(exiting) => {
			if (exiting === true) {
				control.start({ opacity: 0, transition: { duration: 0.25 } });
			}
		},
		[control]
	);

	useEffect(() => {
		if (exiting) {
			startExitingAnimation(exiting);
		}
	}, [exiting, startExitingAnimation]);

	useEffect(() => {
		if (!isLoading) {
			startOpeningAnimation();
			dispatch(closePanel());
		}
	}, [isLoading, startOpeningAnimation]);

	return (
		<div className="bg-[#f5f5f5] rounded-tl-[30px] min-h-screen ">
			{!data?.pages?.[0]?.documents && isLoading && isFetchedAfterMount ? (
				<LoaderTransparent />
			) : (
				<>
					<DocumentHeader
						foldersPath={foldersPath}
						selectedCategory={selectedCategory}
						setSelectedCategory={setSelectedCategory}
						handleSearchChange={(searchTerm: string) =>
							setSearchTerm(searchTerm)
						}
						sortOrder={sortOrder}
						handleSorting={setSortOrder}
					/>
					<div className="flex flex-col h-[calc(100vh-208px)]">
						<DocumentBody
							fetchNextPage={fetchNextPage}
							documentsCount={documentsCount}
							documents={documents}
							folders={folders}
							isFetchingNextPage={isFetchingNextPage}
							showLoadMore={
								documentsCount >= pageSize &&
								documents.length >= pageSize &&
								documentsCount > documents.length
							}
							foldersPath={foldersPath}
							selectedDocuments={selectedDocuments}
							openFolder={handleOpenFolder}
							selectAllDocuments={selectAllDocuments}
							deselectAllDocuments={deselectAllDocuments}
							toggleDocumentSelection={toggleDocumentSelection}
						/>
					</div>
					<ExternalDocumentsPanel />
					{(isFetching || isFolderFetching) && <LoaderTransparent />}
				</>
			)}
		</div>
	);
};

export default DocumentPage;
