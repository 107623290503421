import { Input } from '../ui/input';
// import SocialButtons from '../SocialButtons/SocialButtons';
import styles from './LoginForm.module.css';
import { useForm } from 'react-hook-form';
import { login } from '@/lib/functions/apiCalls';
import {
	loginFailure,
	loginRequest,
	loginRequires2FA,
} from '@/redux/slices/authSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useNavigate } from 'react-router';
import {
	ApiResponse,
	ErrorResponse,
	LoginPayload,
	LoginResponse,
} from '@/lib/types/apiTypes';
import {
	handleApiError,
	handleGeneralError,
	handleUnexpectedError,
	handleLoginResponse,
} from '@/lib/functions/funcUtils';
import { Label } from '../ui/label';
import { cn } from '@/lib/utils';
import KeyIcon from '../ui/icons/KeyIcon';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '../ui/form';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { FC } from 'react';
import { LoginCreds } from '@/pages/Login/Login';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';

const formSchema = z.object({
	auth: z.string().optional(),
	email: z.string().trim().email({
		message: 'Please enter correct email address.',
	}),
	password: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
});

interface LoginFormProps {
	on2FALogin: (loginCreds: LoginCreds) => void;
}

const LoginForm: FC<LoginFormProps> = ({ on2FALogin }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const trialBannerDelayDate = useSelector(
		(root: RootState) => root.auth.trialBannerDelayDate
	);

	const form = useForm<LoginPayload>({
		resolver: zodResolver(formSchema),
		values: {
			auth: '',
			email: '',
			password: '',
		},
	});

	const onSubmit = async (data: LoginPayload) => {
		try {
			dispatch(loginRequest());
			const response: ApiResponse = await login({
				auth: 'basic',
				email: data.email,
				password: data.password,
			});
			if ((response as any).is2factorEnabled) {
				dispatch(loginRequires2FA());
				on2FALogin({
					email: data.email,
					password: data.password,
				});
			} else {
				if (response.statusCode >= 400 && response.statusCode <= 599) {
					throw response; // Throw the response if status is 400s or 500s
				}
				handleLoginResponse(
					dispatch,
					navigate,
					response.data as LoginResponse,
					trialBannerDelayDate
				);
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(loginFailure(error.message));
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(loginFailure(apiError.message));
			} else {
				handleUnexpectedError();
			}
		}
	};

	return (
		<div className="flex flex-col gap-6 px-8">
			{/* <Toaster position='top-center'/> */}
			<Form {...form}>
				<form
					className="flex flex-col gap-5 w-[500px]"
					onSubmit={form.handleSubmit(onSubmit)}
				>
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<Label>Email</Label>
									<FormControl>
										<Input
											className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
											placeholder="Enter your email"
											{...field}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => {
							return (
								<FormItem className="col-span-1">
									<div className="flex justify-between">
										<Label>Password</Label>
										<a
											className="text-base font-medium leading-normal text-primary cursor-pointer"
											onClick={() => navigate('?type=forgot-password')}
										>
											Forgot Password?
										</a>
									</div>
									<FormControl>
										<Input
											className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
											type="password"
											placeholder="Enter your password"
											autoComplete="true"
											{...field}
										></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							);
						}}
					/>

					{/* <SocialButtons /> */}
					<div className="flex justify-center">
						<PrimaryButton type="submit" className="w-[180px]">
							LOGIN
						</PrimaryButton>
					</div>
				</form>
			</Form>
			<div className={cn(styles.separator, 'flex gap-4')}>
				<p className={cn(styles.separator_text, 'text-black px-4')}>or</p>
			</div>
			<div className="flex justify-center">
				<PrimaryButton className="gap-2" onClick={() => navigate('?type=sso')}>
					<KeyIcon /> LOGIN WITH SSO
				</PrimaryButton>
			</div>
		</div>
	);
};

export default LoginForm;
