import { FC } from 'react';
import SuggestionListItem from '../SuggestionListItem/SuggestionListItem';
import { Section, Suggestion as SuggestionType } from '@/lib/types/apiTypes';
import { useActiveSection } from '@/lib/hooks/useActiveSection';

interface SuggestionListsProps {
	suggestions: SuggestionType[];
	section: Section;
}

const SuggestionLists: FC<SuggestionListsProps> = ({
	suggestions,
	section,
}) => {
	const { activeSection } = useActiveSection();
	return (
		<div className="flex flex-col">
			{suggestions?.length > 0 ? (
				<div className="px-6 pb-8 overflow-y-auto hidden-scroll">
					<div className="flex flex-col gap-4">
						{suggestions?.map((suggestion, index) => (
							<SuggestionListItem
								key={index}
								suggestion={suggestion}
								section={section}
								activeSection={activeSection}
								index={index}
							/>
						))}
					</div>
				</div>
			) : (
				<p className="px-6 text-[#6d7d86] mt-4 text-center">
					Looks like I am unable to suggest. This usually means that you might
					have not designed this section yet.
				</p>
			)}
		</div>
	);
};

export default SuggestionLists;
