import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const AdminRoute: React.FC = () => {
	const { isAdmin } = useAuth();

	if (isAdmin) {
		return <Outlet />; // This renders the child routes
	} else {
		return <Navigate to="/" />;
	}
};

export default AdminRoute;
