import { Page, ProposalCreationStepsMap } from '@/constants/Onboarding/types';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

export const useCurrentPage = () => {
	const location = useLocation();
	const page = location.pathname;
	const currentStep = useSelector(
		(state: RootState) => state.proposal.currentStep
	);

	switch (true) {
		case page === '/':
			return Page.Dashboard;
		case page === '/solicitations':
			return Page.Solicitation;
		case page.startsWith('/proposal'):
			return Page.ProposalDashboard;
		case page.endsWith('/design'):
			return Page.Design;
		case page.endsWith('/write'):
			return Page.Write;
		case page === '/manage/organization':
			return Page.OrganizationSettings;
		case page === '/create':
			return ProposalCreationStepsMap[currentStep];
		case page === '/template':
			return Page.TemplateBuilder;
		case page === '/manage/users':
			return Page.UserPage;
	}
};
