const DotMenu = ({ className, fill = 'white' }) => {
	return (
		<svg
			className={className}
			width="4"
			height="16"
			viewBox="0 0 4 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="1.89786"
				cy="1.89786"
				r="1.89786"
				transform="matrix(-1 0 0 1 3.89844 0.679688)"
				fill={fill}
			/>
			<circle
				cx="1.89786"
				cy="1.89786"
				r="1.89786"
				transform="matrix(-1 0 0 1 3.89844 6.10156)"
				fill={fill}
			/>
			<circle
				cx="1.89786"
				cy="1.89786"
				r="1.89786"
				transform="matrix(-1 0 0 1 3.89844 11.5254)"
				fill={fill}
			/>
		</svg>
	);
};

export default DotMenu;
