import { SVGProps } from 'react';

const TemplateAssemblerContent = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="131"
			height="133"
			viewBox="0 0 131 133"
			fill="none"
		>
			<g clipPath="url(#clip0_6690_12075)">
				<path
					d="M92.8613 2.75H12.8613C7.33848 2.75 2.86133 7.22715 2.86133 12.75V92.75C2.86133 98.2729 7.33848 102.75 12.8613 102.75H92.8613C98.3842 102.75 102.861 98.2729 102.861 92.75V12.75C102.861 7.22715 98.3842 2.75 92.8613 2.75Z"
					fill="#EAF1FC"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M15.7812 42.4395H88.6513"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M15.7812 62.6992H41.3913"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M22.8618 87.3193C26.1866 87.3193 28.8818 84.624 28.8818 81.2993C28.8818 77.9745 26.1866 75.2793 22.8618 75.2793C19.537 75.2793 16.8418 77.9745 16.8418 81.2993C16.8418 84.624 19.537 87.3193 22.8618 87.3193Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M15.7812 22.1797H88.6513"
					stroke="#AFC1DB"
					strokeWidth="3.94"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M88.852 118.211L79.612 84.8908C79.222 83.5008 80.502 82.2108 81.902 82.5908L115.362 91.7308C116.742 92.1108 117.202 93.8308 116.192 94.8408L111.382 99.6508C110.652 100.381 110.652 101.561 111.382 102.281L127.562 118.461C128.292 119.191 128.292 120.371 127.562 121.091L118.992 129.661C118.262 130.391 117.082 130.391 116.362 129.661L100.112 113.411C99.382 112.681 98.192 112.681 97.462 113.421L91.982 119.001C90.972 120.021 89.242 119.581 88.852 118.191V118.211Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M49.1914 75.3691L69.3414 83.8991"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M53.1523 53.8809L74.8523 76.7509"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M76.3418 52.75L82.7518 72.14"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6690_12075">
					<rect
						width="129.22"
						height="131.44"
						fill="white"
						transform="translate(0.890625 0.779297)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default TemplateAssemblerContent;
