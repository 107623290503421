import styles from './ExternalDocumentsPanel.module.css';
import { cn } from '@/lib/utils';
import { useCallback, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { X } from 'lucide-react';
import { closePanel } from '@/redux/slices/pageSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
// import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import DecDocumentsPanel from '@/components/DecDocuments/DecDocumentsPanel/DecDocumentsPanel';

const ExternalDocumentsPanel = () => {
	const dispatch = useAppDispatch();
	const open = useSelector(
		(root: RootState) => root.page.panel?.type === 'externalDocuments'
	);

	// const [selectedTab, setSelectedTab] = useState('dec');

	// Animation Controls

	const externalDocumentsControls = useAnimation();

	const startOpeningAnimation = useCallback(() => {
		externalDocumentsControls.set({ opacity: 0, y: '100%' });
		externalDocumentsControls.start({
			opacity: 1,
			y: 0,
			transition: {
				delay: 0,
				duration: 0.7,
				type: 'cubic-bezier',
				ease: [0.76, 0, 0.24, 1],
			},
		});
	}, [externalDocumentsControls]);

	useEffect(() => {
		startOpeningAnimation();
	}, [startOpeningAnimation]);
	return (
		<>
			<div
				className={cn(
					styles.panel,
					open ? styles.open : '',
					'h-[calc(100vh-50px)] overflow-hidden p-5 bg-background'
				)}
			>
				<motion.div animate={externalDocumentsControls}>
					<div className="flex items-center gap-3 cursor-pointer top-0 right-0 pb-2">
						<X
							onClick={() => {
								dispatch(closePanel());
							}}
							className="w-8 h-8 p-2 rounded-full text-primary bg-lightBackground"
						/>
					</div>
					<DecDocumentsPanel />
					{/* <AppTabs
						defaultTab={'dec'}
						selectedTab={selectedTab}
						handleTabChange={(tab) => setSelectedTab(tab)}
						type="drawer"
						tabs={[
							{
								label: 'USAID Dec',
								value: 'dec',
								content: <DecDocumentsPanel />,
							},
						]}
					/> */}
				</motion.div>
			</div>
			<div className={cn(styles.overlay, open ? 'block' : 'hidden')}></div>
		</>
	);
};

export default ExternalDocumentsPanel;
