import { SVGProps } from 'react';

const Home = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M12.9582 0H5.04179C2.25729 0 0 2.13459 0 4.76775V13.2323C0 15.8654 2.25729 18 5.04179 18H12.9582C15.7427 18 18 15.8654 18 13.2323V4.76775C18 2.13459 15.7427 0 12.9582 0Z"
				fill="#E861C9"
			/>
			<path
				d="M3.70703 7.22216L9.03123 3.32031L14.3554 7.22216V13.3536C14.3554 13.5 14.3248 13.645 14.2654 13.7803C14.2059 13.9155 14.1187 14.0384 14.0089 14.1419C13.899 14.2455 13.7686 14.3276 13.625 14.3836C13.4815 14.4396 13.3276 14.4685 13.1723 14.4685H4.89019C4.73481 14.4685 4.58096 14.4396 4.43741 14.3836C4.29387 14.3276 4.16344 14.2455 4.05357 14.1419C3.9437 14.0384 3.85655 13.9155 3.79709 13.7803C3.73763 13.645 3.70703 13.5 3.70703 13.3536V7.22216Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.20898 13.8074V9.60742H10.809V13.8074"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Home;
