const Documents = ({ className = 'h-9', fill = '#5D9BFD' }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="31"
			height="37"
			viewBox="0 0 31 37"
			fill="none"
		>
			<path
				d="M24.7266 36.9984H6.76062C5.1669 36.9971 3.63884 36.363 2.51191 35.2355C1.38499 34.108 0.751308 32.5791 0.75 30.9845L0.75 6.01779C0.75 4.42281 1.38326 2.89316 2.51047 1.76533C3.63768 0.63751 5.1665 0.00390625 6.76062 0.00390625L24.7266 0.00390625C26.3203 0.00521525 27.8484 0.63924 28.9753 1.76678C30.1022 2.89432 30.7359 4.42321 30.7372 6.01779V30.9861C30.7359 32.5807 30.1022 34.1096 28.9753 35.2371C27.8484 36.3647 26.3203 36.9987 24.7266 37M6.76062 2.6459C5.86729 2.64742 5.01099 3.00316 4.37931 3.63519C3.74763 4.26721 3.39208 5.12398 3.39055 6.01779V30.9861C3.3923 31.8798 3.74794 32.7364 4.3796 33.3682C5.01125 34 5.86743 34.3557 6.76062 34.3572H24.7266C25.6194 34.3552 26.475 33.9995 27.1063 33.3679C27.7375 32.7363 28.0931 31.8802 28.095 30.987V6.01779C28.0935 5.12426 27.7382 4.26774 27.1068 3.63577C26.4755 3.00379 25.6196 2.64786 24.7266 2.6459H6.76062Z"
				fill={fill}
			/>
			<path
				d="M22.294 9.88049C22.121 9.88049 21.9496 9.84639 21.7898 9.78015C21.63 9.71391 21.4848 9.61682 21.3624 9.49442C21.2401 9.37202 21.143 9.22672 21.0768 9.06679C21.0106 8.90687 20.9766 8.73547 20.9766 8.56238V1.321C20.9766 0.970646 21.1157 0.634645 21.3633 0.386911C21.6109 0.139176 21.9467 0 22.2968 0C22.647 0 22.9828 0.139176 23.2304 0.386911C23.478 0.634645 23.6171 0.970646 23.6171 1.321V7.21872L29.4005 7.13058H29.4211C29.5944 7.12922 29.7664 7.16205 29.9271 7.22719C30.0878 7.29233 30.2341 7.38849 30.3577 7.5102C30.4812 7.63191 30.5796 7.77678 30.6472 7.93653C30.7148 8.09629 30.7503 8.2678 30.7516 8.44127C30.753 8.61475 30.7202 8.78679 30.6551 8.94758C30.59 9.10837 30.4939 9.25475 30.3722 9.37837C30.2506 9.502 30.1058 9.60044 29.9461 9.66807C29.7864 9.73571 29.615 9.77121 29.4417 9.77257L22.3104 9.88049H22.294Z"
				fill={fill}
			/>
			<path
				d="M17.0303 15.4084H8.12887C7.77871 15.4084 7.44289 15.2692 7.19529 15.0214C6.94769 14.7735 6.80859 14.4374 6.80859 14.087C6.80859 13.7365 6.94769 13.4005 7.19529 13.1526C7.44289 12.9048 7.77871 12.7656 8.12887 12.7656H17.0303C17.3805 12.7656 17.7163 12.9048 17.9639 13.1526C18.2115 13.4005 18.3506 13.7365 18.3506 14.087C18.3506 14.4374 18.2115 14.7735 17.9639 15.0214C17.7163 15.2692 17.3805 15.4084 17.0303 15.4084Z"
				fill={fill}
			/>
			<path
				d="M23.1327 22.5671H8.13082C7.95744 22.5671 7.78576 22.5329 7.62558 22.4665C7.46539 22.4001 7.31985 22.3027 7.19725 22.18C7.07465 22.0573 6.9774 21.9117 6.91105 21.7514C6.8447 21.591 6.81055 21.4192 6.81055 21.2457C6.81055 21.0722 6.8447 20.9003 6.91105 20.74C6.9774 20.5797 7.07465 20.434 7.19725 20.3113C7.31985 20.1886 7.46539 20.0913 7.62558 20.0249C7.78576 19.9585 7.95744 19.9243 8.13082 19.9243H23.1327C23.306 19.9243 23.4777 19.9585 23.6379 20.0249C23.7981 20.0913 23.9436 20.1886 24.0662 20.3113C24.1888 20.434 24.2861 20.5797 24.3524 20.74C24.4188 20.9003 24.4529 21.0722 24.4529 21.2457C24.4529 21.4192 24.4188 21.591 24.3524 21.7514C24.2861 21.9117 24.1888 22.0573 24.0662 22.18C23.9436 22.3027 23.7981 22.4001 23.6379 22.4665C23.4777 22.5329 23.306 22.5671 23.1327 22.5671Z"
				fill={fill}
			/>
			<path
				d="M23.1327 29.7189H8.13082C7.78066 29.7189 7.44485 29.5797 7.19725 29.3319C6.94965 29.0841 6.81055 28.748 6.81055 28.3975C6.81055 28.0471 6.94965 27.711 7.19725 27.4632C7.44485 27.2154 7.78066 27.0762 8.13082 27.0762H23.1327C23.4828 27.0762 23.8186 27.2154 24.0662 27.4632C24.3138 27.711 24.4529 28.0471 24.4529 28.3975C24.4529 28.748 24.3138 29.0841 24.0662 29.3319C23.8186 29.5797 23.4828 29.7189 23.1327 29.7189Z"
				fill={fill}
			/>
		</svg>
	);
};

export default Documents;
