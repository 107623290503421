import { FC, useCallback, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import * as z from 'zod';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { Info } from 'lucide-react';
import Slider from '@/components/ui/shared/Slider/Slider';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import {
	GenerateSampleTextPayload,
	ToneOfVoice,
	ToneOfVoiceEditProps,
	ToneOfVoiceFormData,
} from '@/lib/types/apiTypes';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import { generateSampleText } from '@/lib/functions/apiCalls';
import { ErrorHandle } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { cn } from '@/lib/utils';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import { cloneDeep, isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { tovMap } from '@/constants/organizationalData';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';

const collaborative = 'collaborative';
const collaborativeExampleText = 'We the organization and our partners';

const firstPerson = 'first_person';
const firstPersonExampleText = 'We the organization';

const defaultCitationFormat = 'APA (American Psychological Association)';

const sampleText = 'Sample text.';
const initialSampleText =
	'Click Generate Sample to create a sample text based on the tone of voice.';

const formSchema = z.object({
	analytical: z.number(),
	authoritative: z.number(),
	concise: z.number(),
	emotive: z.number(),
	sampleText: z.string().optional(),
	writingStyle: z.object({
		name: z.string(),
		exampleText: z.string(),
	}),
	citationFormat: z.string(),
});

const ToneOfVoiceEdit: FC<ToneOfVoiceEditProps> = ({
	toneOfVoice,
	type,
	uploadedDocumentTone,
	setIsLoading,
	onSubmit,
	onCancel,
	initialSetup,
}) => {
	const dispatch = useAppDispatch();
	const form = useForm<ToneOfVoiceFormData>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			analytical: 20,
			authoritative: 20,
			concise: 20,
			emotive: 20,
			sampleText: sampleText,
			writingStyle: {
				name: collaborative,
				exampleText: collaborativeExampleText,
			},
			citationFormat: defaultCitationFormat,
		},
	});
	const isTransparentLoading = useSelector(
		(state: RootState) => state.proposal.isTransparentLoading
	);
	const [isChanged, setIsChanged] = useState(false);
	const [generateSampleTov, setGenerateSampleTov] =
		useState<ToneOfVoiceFormData>();
	const [isLoaded, setIsLoaded] = useState(initialSetup || false);

	const setFormValues = useCallback(
		(toneOfVoice: ToneOfVoice) => {
			toneOfVoice.tone.forEach((toneItem) => {
				switch (toneItem.name) {
					case 'Analytical':
						form.setValue('analytical', toneItem.percentage);
						break;
					case 'Authoritative':
						form.setValue('authoritative', toneItem.percentage);
						break;
					case 'Concise':
						form.setValue('concise', toneItem.percentage);
						break;
					case 'Emotive':
						form.setValue('emotive', toneItem.percentage);
						break;
				}
			});
			form.setValue('sampleText', toneOfVoice.sampleText || '');
			form.setValue(
				'writingStyle.name',
				toneOfVoice.writingStyle.name || collaborative
			);
			form.setValue(
				'writingStyle.exampleText',
				toneOfVoice.writingStyle.exampleText || collaborativeExampleText
			);
			form.setValue(
				'citationFormat',
				toneOfVoice.citationFormat || defaultCitationFormat
			);
		},
		[form]
	);

	const setFormSampleText = useCallback(
		(sampleText: string) => {
			form.setValue('sampleText', sampleText || '');
		},
		[form]
	);

	const formatGenerateSampleText = (
		formData: ToneOfVoiceFormData
	): GenerateSampleTextPayload => {
		return {
			toneOfVoice: {
				tone: [
					{
						name: 'Analytical',
						percentage: formData.analytical,
					},
					{
						name: 'Authoritative',
						percentage: formData.authoritative,
					},
					{
						name: 'Concise',
						percentage: formData.concise,
					},
					{
						name: 'Emotive',
						percentage: formData.emotive,
					},
				],
				sampleText: formData.sampleText || sampleText,
				citationFormat: formData.citationFormat || null,
				writingStyle: {
					name: formData.writingStyle.name || null,
					exampleText: formData.writingStyle.exampleText || null,
				},
			},
		};
	};

	const setLoading = (isLoading: boolean) => {
		setIsLoading && setIsLoading(isLoading);
	};
	``;

	const onGenerateSampleText = async () => {
		try {
			setLoading(true);
			const formData = form.getValues();
			const payload = formatGenerateSampleText(formData);
			const resp = await generateSampleText(payload);
			setFormSampleText(resp.data.sampleText);
			setGenerateSampleTov({ ...formData, sampleText: resp.data.sampleText });
			setIsChanged(false);
			setLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setLoading(false);
		}
	};

	useWatch(form);

	const formData = form.getValues();

	const setDocumentTone = () => {
		if (uploadedDocumentTone) {
			setFormValues(cloneDeep(uploadedDocumentTone));
		}
	};

	const isTovEqual = (sampleTov, formTov, propertyToIgnore): boolean => {
		delete sampleTov[propertyToIgnore];
		delete formTov[propertyToIgnore];
		return isEqual(sampleTov, formTov);
	};

	useEffect(() => {
		if (!isLoaded) return;

		if (
			formData.sampleText &&
			(formData.sampleText === sampleText ||
				formData.sampleText === initialSampleText)
		) {
			setIsChanged(true);
		} else {
			setIsChanged(!isTovEqual(generateSampleTov, formData, 'citationFormat'));
		}
	}, [formData]);

	useEffect(() => {
		if (toneOfVoice) {
			setFormValues(toneOfVoice);
			setGenerateSampleTov(cloneDeep(form.getValues()));
			setIsLoaded(true);
		}
	}, [setFormValues, toneOfVoice]);

	useEffect(() => {
		switch (formData.writingStyle.name) {
			case collaborative:
				form.setValue('writingStyle.exampleText', collaborativeExampleText);
				break;
			case firstPerson:
				form.setValue('writingStyle.exampleText', firstPersonExampleText);
				break;
		}
	}, [form, formData.writingStyle.name]);

	return (
		<Form {...form}>
			<form
				onSubmit={onSubmit && form.handleSubmit(onSubmit)}
				className="flex flex-col gap-6"
			>
				<div className="grid pb-6 border-b border-[#D3DDE2] grid-cols-2 gap-10">
					<div className="flex flex-col col-span-1 gap-4">
						<div className="flex justify-between">
							<div className="flex flex-col items-start gap-3">
								<h3
									className={cn(
										'col-span-2 text-black',
										type === 'organizationSetup' ? '' : 'font-bold'
									)}
								>
									Set tone of voice of your{' '}
									{type === 'proposal' ? 'proposal' : 'organization'}
								</h3>
							</div>
						</div>
						<FormField
							control={form.control}
							name="analytical"
							render={({ field: { value, onChange } }) => {
								return (
									<FormItem className="col-span-1">
										<div className="flex flex-col">
											<div className="flex justify-between">
												<Label className="font-bold leading-loose">
													Analytical
												</Label>
												<div className="flex gap-1">
													<h3 className="self-center font-medium text-secondary">
														{tovMap.Analytical?.[value]?.name}
													</h3>
													<TooltipProvider delayDuration={50}>
														<Tooltip>
															<TooltipTrigger
																autoFocus={false}
																tabIndex={-1}
																type="button"
															>
																<Info className="w-4 cursor-pointer text-secondary" />
															</TooltipTrigger>
															<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
																<p>{tovMap.Analytical?.[value]?.description}</p>
															</TooltipContent>
														</Tooltip>
													</TooltipProvider>
												</div>
											</div>
											<p className="text-black dynamic-small">
												Define if you want the system to be more descriptive in
												its writing or analytical in how it writes.
											</p>
										</div>
										<FormControl>
											<Slider
												min={0}
												max={100}
												step={20}
												showSteps={true}
												value={[value]}
												onValueChange={(value) => onChange(value[0])}
											></Slider>
										</FormControl>
									</FormItem>
								);
							}}
						/>
						<FormField
							control={form.control}
							name="authoritative"
							render={({ field: { value, onChange } }) => {
								return (
									<FormItem className="col-span-1">
										<div className="flex flex-col">
											<div className="flex justify-between">
												<Label className="font-bold leading-loose">
													Authoritative
												</Label>
												<div className="flex gap-1">
													<h3 className="self-center font-medium text-secondary">
														{tovMap.Authoritative?.[value]?.name}
													</h3>
													<TooltipProvider delayDuration={50}>
														<Tooltip>
															<TooltipTrigger
																autoFocus={false}
																tabIndex={-1}
																type="button"
															>
																<Info className="w-4 cursor-pointer text-secondary" />
															</TooltipTrigger>
															<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
																<p>
																	{tovMap.Authoritative?.[value]?.description}
																</p>
															</TooltipContent>
														</Tooltip>
													</TooltipProvider>
												</div>
											</div>
											<p className="text-black dynamic-small">
												Set your tone of writing to be that of an external
												observer all the way to an expert in the subject with a
												high level of authority.
											</p>
										</div>
										<FormControl>
											<Slider
												min={0}
												max={100}
												step={20}
												showSteps={true}
												value={[value]}
												onValueChange={(value) => onChange(value[0])}
											></Slider>
										</FormControl>
									</FormItem>
								);
							}}
						/>
						<FormField
							control={form.control}
							name="concise"
							render={({ field: { value, onChange } }) => {
								return (
									<FormItem className="col-span-1">
										<div className="flex flex-col">
											<div className="flex justify-between">
												<Label className="font-bold leading-loose">
													Concise
												</Label>
												<div className="flex gap-1">
													<h3 className="self-center font-medium text-secondary">
														{tovMap.Concise?.[value]?.name}
													</h3>
													<TooltipProvider delayDuration={50}>
														<Tooltip>
															<TooltipTrigger
																autoFocus={false}
																tabIndex={-1}
																type="button"
															>
																<Info className="w-4 cursor-pointer text-secondary" />
															</TooltipTrigger>
															<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
																<p>{tovMap.Concise?.[value]?.description}</p>
															</TooltipContent>
														</Tooltip>
													</TooltipProvider>
												</div>
											</div>
											<p className="text-black dynamic-small">
												Go from being verbose, using complex sentences all the
												way to briefly and succinctly expressing information.
											</p>
										</div>
										<FormControl>
											<Slider
												min={0}
												max={100}
												step={20}
												showSteps={true}
												value={[value]}
												onValueChange={(value) => onChange(value[0])}
											></Slider>
										</FormControl>
									</FormItem>
								);
							}}
						/>
						<FormField
							control={form.control}
							name="emotive"
							render={({ field: { value, onChange } }) => {
								return (
									<FormItem className="col-span-1">
										<div className="flex flex-col">
											<div className="flex justify-between">
												<Label className="font-bold leading-loose">
													Emotive
												</Label>
												<div className="flex gap-1">
													<h3 className="self-center font-medium text-secondary">
														{tovMap.Emotive?.[value]?.name}
													</h3>
													<TooltipProvider delayDuration={50}>
														<Tooltip>
															<TooltipTrigger
																autoFocus={false}
																tabIndex={-1}
																type="button"
															>
																<Info className="w-4 cursor-pointer text-secondary" />
															</TooltipTrigger>
															<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
																<p>{tovMap.Emotive?.[value]?.description}</p>
															</TooltipContent>
														</Tooltip>
													</TooltipProvider>
												</div>
											</div>
											<p className="text-black dynamic-small">
												You can use a detached tone or move up the spectrum to
												using highly emotive language making an impassioned
												appeal.
											</p>
										</div>
										<FormControl>
											<Slider
												min={0}
												max={100}
												step={20}
												showSteps={true}
												value={[value]}
												onValueChange={(value) => onChange(value[0])}
											></Slider>
										</FormControl>
									</FormItem>
								);
							}}
						/>
					</div>
					<div>
						<div className={cn('flex flex-col gap-4')}>
							<h3 className="font-bold text-black">
								Choose a <span className="text-secondary">collaborative</span>{' '}
								or <span className="text-secondary">first person</span> tone of
								voice for this{' '}
								{type === 'proposal' ? 'proposal' : 'organization'}
							</h3>

							<p className="text-black">Select one of them.</p>

							<FormField
								control={form.control}
								name="writingStyle.name"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<RadioGroup
												value={field.value}
												onValueChange={field.onChange}
												className="flex items-center gap-4"
											>
												<FormItem className="flex items-center space-x-3 space-y-0 cursor-pointer">
													<FormControl>
														<RadioGroupItem
															className="min-h-4 min-w-4"
															value={collaborative}
														/>
													</FormControl>
													<div
														className={cn(
															'flex flex-col h-full gap-3 border border-[#D3DDE2] p-4 rounded-xl hover:border-[#5D9BFD] hover:bg-[#EAF1FC]',
															field.value === collaborative
																? 'bg-[#EAF1FC] border-[#5D9BFD]'
																: ''
														)}
														onClick={() => field.onChange(collaborative)}
													>
														<Label>Collaborative tone of voice</Label>
														<p className="text-base text-secondary">
															“{collaborativeExampleText}”
														</p>
													</div>
												</FormItem>
												<FormItem className="flex items-center flex-1 space-x-3 space-y-0 cursor-pointer">
													<FormControl>
														<RadioGroupItem
															className="min-h-4 min-w-4"
															value={firstPerson}
														/>
													</FormControl>
													<div
														className={cn(
															'flex flex-col h-full gap-3 border w-full border-[#D3DDE2] p-4 rounded-xl hover:border-[#5D9BFD] hover:bg-[#EAF1FC]',
															field.value === firstPerson
																? 'bg-[#EAF1FC] border-[#5D9BFD]'
																: ''
														)}
														onClick={() => field.onChange(firstPerson)}
													>
														<Label>First person tone of voice</Label>
														<p className="text-base text-secondary">
															“{firstPersonExampleText}”
														</p>
													</div>
												</FormItem>
											</RadioGroup>
										</FormControl>
									</FormItem>
								)}
							/>
							<div className={cn('flex items-center col-span-2 gap-4')}>
								<div className="flex flex-col gap-4">
									<h3 className="font-bold text-black">Citation Style</h3>
								</div>
								<div className="flex">
									<FormField
										control={form.control}
										name="citationFormat"
										render={({ field: { value, onChange } }) => {
											return (
												<FormItem>
													<FormControl>
														<Select onValueChange={onChange} value={value}>
															<SelectTrigger className="min-w-[300px] h-12 bg-white border-[#5D9BFD] dynamic-small  font-medium rounded-xl  px-4">
																<SelectValue placeholder="Select Role" />
															</SelectTrigger>
															<SelectContent className="py-2 bg-white rounded-2xl">
																<SelectItem
																	className="text-black dynamic-small py-2 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
																	value="APA (American Psychological Association)"
																>
																	APA (American Psychological Association)
																</SelectItem>
																<SelectItem
																	className="text-black dynamic-small cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
																	value="Chicago"
																>
																	Chicago
																</SelectItem>
																<SelectItem
																	className="text-black dynamic-small cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
																	value="MLA (Modern Language Association)"
																>
																	MLA (Modern Language Association)
																</SelectItem>
															</SelectContent>
														</Select>
													</FormControl>
												</FormItem>
											);
										}}
									/>
								</div>
							</div>
							<div className="flex flex-col col-span-2 p-4 border-[#D3DDE2] border rounded-xl">
								<h3 className="mb-2 font-bold text-black dynamic-medium">
									Tone of voice sample
								</h3>
								<p className="text-black dynamic-small">
									{formData.sampleText}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className={cn('grid grid-flow-col items-center')}>
					{uploadedDocumentTone && (
						<TextButton
							className="flex h-8 justify-self-start"
							type="button"
							onClick={setDocumentTone}
						>
							RESET to default
						</TextButton>
					)}
					{isChanged && (
						<p
							className={cn(
								'dynamic-text font-medium text-lightRed',
								uploadedDocumentTone ? 'px-6' : ''
							)}
						>
							Please generate a sample to ensure that you are satisfied with
							your settings.
						</p>
					)}
					<div className="flex items-center gap-3 justify-self-end">
						{onCancel && (
							<SecondaryButton onClick={onCancel} type="button">
								Back
							</SecondaryButton>
						)}
						<SecondaryButton
							type="button"
							disabled={isTransparentLoading || !isChanged}
							onClick={() => onGenerateSampleText()}
						>
							GENERATE SAMPLE
						</SecondaryButton>
						<PrimaryButton
							type="submit"
							disabled={isTransparentLoading || isChanged}
						>
							{type === 'organizationSetup' ? 'Complete' : 'Save'} Settings
						</PrimaryButton>
					</div>
				</div>
			</form>
		</Form>
	);
};

export default ToneOfVoiceEdit;
