import { FC, useRef } from 'react';
import { Step, Steps } from 'intro.js-react';
interface TourContainerProps {
	enabled: boolean;
	steps: Step[];
	initialStep: number;
	onExit: () => void;
}

const options = {
	// showBullets: false,
	highlightClass: 'custom-highlight',
	tooltipClass: 'custom-tooltip',
};

const TourContainer: FC<TourContainerProps> = ({
	enabled,
	steps,
	initialStep,
	onExit,
}) => {
	const introRef = useRef(null);
	return (
		<div ref={introRef}>
			<Steps
				options={options}
				enabled={enabled}
				steps={steps}
				initialStep={initialStep}
				onExit={onExit}
			/>
		</div>
	);
};

export default TourContainer;
