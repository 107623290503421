import { ColumnDef } from '@tanstack/react-table';
import Delete from '@/components/ui/icons/Delete';
import { UploadedDocumentsTableProps } from '@/lib/types/constants';

export const columns = (
	handleDetachDocument: (path: string) => void
): ColumnDef<UploadedDocumentsTableProps>[] => [
	{
		accessorKey: 'name',
		header: ({ column }) => {
			return (
				<h5
					key={column.id}
					className="p-0 text-black bg-transparent text-heading6 xl:text-heading5"
				>
					Uploaded Documents
				</h5>
			);
		},
		cell: ({ row }) => {
			return (
				<a className="text-sm text-primary line-clamp-2">{row.original.name}</a>
			);
		},
	},
	{
		accessorKey: 'path',
		header: () => <></>,
		cell: ({ row }) => {
			return (
				<a className="flex justify-end bg-transparent">
					<Delete
						className="cursor-pointer animated-hover-effect"
						onClick={() => handleDetachDocument(row.original.path)}
						fill={'#FF388C'}
					/>
				</a>
			);
		},
	},
];
