import { FC, useState } from 'react';
import styles from './Document.module.css';
import { Search, X } from 'lucide-react';
import { Input } from '../ui/input';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { containerDivFadeIn } from '@/constants/variants';
import useDebounce from '@/lib/hooks/useDebounce';
import CategoryDropdown from '../CategoryDropdown/CategoryDropdown';
// import TextButton from '../ui/shared/Button/TextButton/TextButton';
// import Ai from '@/components/ui/icons/new/AI';
// import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';
import { Folder, SortingType } from '@/lib/types/apiTypes';
import DocumentActions from '../DocumentActions/DocumentActions';

interface DocumentHeaderProps {
	foldersPath: Folder[];
	selectedCategory: string;
	setSelectedCategory: (selectedCategory: string) => void;
	handleSearchChange: (searchTerm: string) => void;
	sortOrder?: SortingType;
	handleSorting?: (sortOrder: SortingType) => void;
}

const DocumentHeader: FC<DocumentHeaderProps> = ({
	foldersPath,
	selectedCategory,
	setSelectedCategory,
	handleSearchChange,
	sortOrder,
	handleSorting,
}) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const debouncedSearchChange = useDebounce(handleSearchChange, 600);

	return (
		<div className="z-20 px-8 pt-5">
			<motion.div
				className={cn(
					styles.header,
					'px-5 py-4 flex justify-between items-center document-control-container'
				)}
				key="containerDivFadeIn"
				variants={containerDivFadeIn}
				initial="initial"
				animate="animate"
			>
				<div className="flex items-center justify-start gap-4">
					<div className="relative">
						<Input
							value={searchTerm}
							onChange={(event) => {
								setSearchTerm(event.target.value);
								debouncedSearchChange(event.target.value);
							}}
							placeholder="Search Document Name"
							className="bg-white border-[#D3DDE2] w-72 2xl:w-96 h-11 p-4 dynamic-small rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] pr-12"
						/>
						<div className="absolute right-8 top-3.5">
							{searchTerm !== '' && (
								<X
									onClick={() => {
										setSearchTerm('');
										debouncedSearchChange('');
									}}
									className="cursor-pointer"
									color="#5D6F79"
									size={16}
								/>
							)}
						</div>
						<div className="absolute right-3 top-3.5">
							<Search color="#5D9BFD" size={16} />
						</div>
					</div>
					<CategoryDropdown
						className="h-11 !w-40 dynamic-small border border-primary px-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
						showAll={true}
						selectedCategory={selectedCategory}
						setSelectedCategory={setSelectedCategory}
					/>
					<Select
						value={sortOrder}
						onValueChange={(sortOrder: SortingType) => {
							handleSorting && handleSorting(sortOrder);
						}}
					>
						<SelectTrigger className="h-13 w-40 rounded-xl dynamic-small border border-primary bg-white text-black px-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]">
							<SelectValue placeholder="Sort by" />
						</SelectTrigger>
						<SelectContent className="py-2 bg-white rounded-xl">
							<SelectItem
								className="text-black dynamic-small py-2 cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
								value="DESC"
							>
								Sort by latest
							</SelectItem>
							<SelectItem
								className="text-black dynamic-small cursor-pointer rounded-lg py-2 hover:bg-[#E3EEFF] hover:text-primary"
								value="ASC"
							>
								Sort by oldest
							</SelectItem>
						</SelectContent>
					</Select>
				</div>
				<div className="flex justify-end gap-4">
					<DocumentActions folder={foldersPath?.[foldersPath?.length - 1]} />
				</div>
			</motion.div>
		</div>
	);
};

export default DocumentHeader;
