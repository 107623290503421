import { RootState } from '@/redux/store/store';
import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AccountPage from '@/pages/AccountPage/AccountPage';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';

interface AccountPaneProps {
	defaultTab: 'account';
}

const AccountPane: FC<AccountPaneProps> = ({ defaultTab }) => {
	const isStreaming = useSelector((root: RootState) => root.page.isStreaming);

	const [selectedTab, setSelectedTab] = useState(defaultTab);

	const handleTabChange = (newTabIndex) => {
		setSelectedTab(newTabIndex);
	};

	useEffect(() => {
		if (isStreaming) return;
		// if (isStreaming) return;
		handleTabChange(defaultTab);
	}, [defaultTab]);

	return (
		<AppTabs
			defaultTab={defaultTab}
			selectedTab={selectedTab}
			handleTabChange={handleTabChange}
			type="main"
			tabs={[
				{
					label: 'Account',
					value: 'account',
					content: <AccountPage />,
				},
			]}
		/>
	);
};

export default AccountPane;
