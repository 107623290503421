import { SVGProps } from 'react';

const AddDocument = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="138"
			height="128"
			viewBox="0 0 138 128"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="2.5" y="2" width="103" height="124" rx="14" fill="#EAF1FC" />
			<rect
				x="2.5"
				y="2"
				width="103"
				height="124"
				rx="14"
				stroke="#5D9BFD"
				strokeWidth="4"
			/>
			<rect x="16.5" y="16" width="75" height="2" rx="1" fill="#5D9BFD" />
			<rect x="16.5" y="29.4297" width="75" height="2" rx="1" fill="#5D9BFD" />
			<rect x="16.5" y="42.8555" width="75" height="2" rx="1" fill="#5D9BFD" />
			<rect x="16.5" y="56.2852" width="75" height="2" rx="1" fill="#5D9BFD" />
			<rect x="16.5" y="69.7148" width="75" height="2" rx="1" fill="#5D9BFD" />
			<rect x="16.5" y="83.1445" width="75" height="2" rx="1" fill="#5D9BFD" />
			<rect x="16.5" y="96.5703" width="75" height="2" rx="1" fill="#5D9BFD" />
			<rect x="16.5" y="110" width="75" height="2" rx="1" fill="#5D9BFD" />
			<path
				d="M108.3 111.1C123.377 111.1 135.6 98.8774 135.6 83.8C135.6 68.7226 123.377 56.5 108.3 56.5C93.2226 56.5 81 68.7226 81 83.8C81 98.8774 93.2226 111.1 108.3 111.1Z"
				fill="#EAF1FC"
				stroke="#5D9BFD"
				strokeWidth="4.11"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M117.74 81.3992H110.7V74.3592C110.7 73.0292 109.62 71.9492 108.29 71.9492C106.96 71.9492 105.88 73.0292 105.88 74.3592V81.3992H98.8397C97.5097 81.3992 96.4297 82.4792 96.4297 83.8092C96.4297 85.1392 97.5097 86.2192 98.8397 86.2192H105.88V93.2592C105.88 94.5892 106.96 95.6692 108.29 95.6692C109.62 95.6692 110.7 94.5892 110.7 93.2592V86.2192H117.74C119.07 86.2192 120.15 85.1392 120.15 83.8092C120.15 82.4792 119.07 81.3992 117.74 81.3992Z"
				fill="#5D9BFD"
			/>
		</svg>
	);
};

export default AddDocument;
