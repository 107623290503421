import { Input } from '../../ui/input';
import { useForm, useWatch } from 'react-hook-form';
import { OrganizationProfilePayload } from '@/lib/types/apiTypes';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { Label } from '../../ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '../../ui/form';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { useAppDispatch } from '@/lib/hooks/hooks';
import SecondaryButton from '../../ui/shared/Button/SecondaryButton/SecondaryButton';
import { FC, useCallback, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { Textarea } from '../../ui/textarea';
import {
	Multiselect,
	SelectItemProp,
} from '../../ui/shared/Multiselect/Multiselect';
import {
	expertise,
	regionsOfWork,
	countriesAndTerritories,
	organizationSize,
} from '@/constants/organizationalData';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { createOrganizationProfile } from '@/lib/functions/apiCalls';
import { closeDialog } from '@/redux/slices/pageSlice';

interface FormData {
	// Organization Profile
	name: string;
	description: string;
	areaOfWork: string;
	// Organization Expertise and Size
	expertise: SelectItemProp[];
	regionsOfWork: SelectItemProp[];
	countriesAndTerritories: SelectItemProp[];
	organizationSize: string;
}

const basicFormSchema = z.object({
	name: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
	areaOfWork: z.string().optional(),
	description: z.string().min(2, {
		message: 'Please enter correct information.',
	}),
});

const detailFormSchema = z.object({
	expertise: z
		.array(z.object({ value: z.string(), label: z.string() }))
		.min(1, {
			message: 'Please select an option.',
		}),
	regionsOfWork: z
		.array(z.object({ value: z.string(), label: z.string() }))
		.min(1, {
			message: 'Please select an option.',
		}),
	countriesAndTerritories: z
		.array(z.object({ value: z.string(), label: z.string() }))
		.optional(),
	organizationSize: z.string().optional(),
});

const getFormSchema = (step: number) => {
	switch (step) {
		case 2:
			return detailFormSchema;
		default:
			return basicFormSchema;
	}
};

interface OrganizationProfileSetupFormProps {
	setupStep: number;
	setSetupStep: (setupStep: number) => void;
	organizationProfile?: OrganizationProfilePayload;
	setOrganizationProfile: (
		organizationProfile: OrganizationProfilePayload
	) => void;
	setIsLoading: (isLoading: boolean) => void;
}

const OrganizationProfileSetupForm: FC<OrganizationProfileSetupFormProps> = ({
	setupStep,
	setSetupStep,
	organizationProfile,
	setOrganizationProfile,
	setIsLoading,
}) => {
	const dispatch = useAppDispatch();
	const [organizationProfileState, setOrganizationProfileState] =
		useState(organizationProfile);

	const formSchema = getFormSchema(setupStep);

	const form = useForm<FormData>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			// Basic Information
			name: '',
			areaOfWork: '',
			description: '',
			// Organization Expertise and Size
			expertise: [],
			regionsOfWork: [],
			countriesAndTerritories: [],
			organizationSize: '',
		},
	});

	const formatOrganizationProfileSetupPayload = (
		formData: FormData
	): OrganizationProfilePayload => {
		return {
			name: formData.name,
			areaOfWork: formData.areaOfWork || null,
			description: formData.description || null,
			...(setupStep === 2
				? {
						expertise: formData.expertise.map((item) => item.value) || [],
						regionsOfWork:
							formData.regionsOfWork.map((item) => item.value) || [],
						countriesAndTerritories:
							formData.countriesAndTerritories.map((item) => item.value) || [],
						organizationSize: formData.organizationSize || null,
					}
				: {
						expertise: [],
						regionsOfWork: [],
						countriesAndTerritories: [],
						organizationSize: null,
					}),
		};
	};

	const onSubmit = async (formData: FormData) => {
		try {
			if (setupStep === 1) {
				const organizationProfileData: OrganizationProfilePayload =
					formatOrganizationProfileSetupPayload(formData);
				setOrganizationProfile(organizationProfileData);
				setSetupStep(2);
				return;
			}
			setIsLoading(true);
			const organizationProfile = {
				...organizationProfileState,
				...formData,
			} as FormData;
			const organizationProfilePayload: OrganizationProfilePayload =
				formatOrganizationProfileSetupPayload(organizationProfile);
			const response = await createOrganizationProfile(
				organizationProfilePayload
			);
			handleResponse(
				response,
				'Organization Profile has been created successfully.'
			);
			setIsLoading(false);
			dispatch(closeDialog());
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const designationFormValue = useWatch({
		control: form.control,
		name: 'description',
	});
	const areOfWorkFormValue = useWatch({
		control: form.control,
		name: 'areaOfWork',
	});

	const setFormValues = useCallback(
		(organization: OrganizationProfilePayload) => {
			form.setValue('name', organization.name);
			form.setValue('areaOfWork', organization.areaOfWork || '');
			form.setValue('description', organization.description || '');
			form.setValue(
				'expertise',
				organization.expertise?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue(
				'regionsOfWork',
				organization.regionsOfWork?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue(
				'countriesAndTerritories',
				organization.countriesAndTerritories?.map((value) => {
					return { value: value, label: value };
				}) || []
			);
			form.setValue('organizationSize', organization.organizationSize || '');
		},
		[form]
	);

	const clearFormValues = useCallback(() => {
		form.setValue('name', '');
		form.setValue('areaOfWork', '');
		form.setValue('description', '');
		form.setValue('expertise', []);
		form.setValue('regionsOfWork', []);
		form.setValue('countriesAndTerritories', []);
		form.setValue('organizationSize', '');
	}, [form]);

	useEffect(() => {
		if (organizationProfile) {
			setFormValues(organizationProfile);
			setOrganizationProfileState(organizationProfile);
		} else {
			clearFormValues();
			setOrganizationProfileState(undefined);
		}
	}, [organizationProfile]);

	return (
		<div className="flex flex-col gap-6">
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(onSubmit)}
					className="flex flex-col gap-6 w-full"
				>
					<div
						className={cn(
							'flex flex-col gap-6',
							setupStep === 1 ? '' : 'hidden'
						)}
					>
						<div className="flex flex-col gap-4">
							<h2 className="font-bold text-black dynamic-large">
								Please describe your organization
							</h2>
							<p className="text-black dynamic-text">
								Please provide us a few details about your organization
							</p>
						</div>
						<div className="grid grid-cols-11 gap-4">
							<div className="flex flex-col col-span-5 gap-4">
								<FormField
									control={form.control}
									name="name"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">Organization Name*</Label>
												<FormControl>
													<Input
														className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
														placeholder="Enter the full name and acronym for your organization"
														type="text"
														{...field}
													></Input>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
								<FormField
									control={form.control}
									name="areaOfWork"
									render={({ field }) => {
										return (
											<FormItem>
												<div className="flex justify-between">
													<Label className="font-bold">Area Of Work</Label>
													<p
														className={cn(
															'text-base font-medium leading-normal',
															field?.value?.length > 250
																? 'text-[#FF82A0]'
																: 'text-black opacity-50'
														)}
													>
														{field?.value?.length || 0}/250 characters
													</p>
												</div>
												<FormControl>
													<Input
														className="bg-white border-[#D3DDE2] h-12 p-4 rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
														placeholder="Please describe the type of work you do"
														type="text"
														{...field}
													></Input>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="flex col-span-6">
								<FormField
									control={form.control}
									name="description"
									render={({ field }) => {
										return (
											<FormItem className="flex flex-col w-full overflow-hidden">
												<div className="flex justify-between">
													<Label className="font-bold">
														Describe your Organization*
													</Label>
													<p
														className={cn(
															'text-base font-medium leading-normal',
															field?.value?.length > 1200
																? 'text-[#FF82A0]'
																: 'text-black opacity-50'
														)}
													>
														{field?.value?.length || 0}/1200 characters
													</p>
												</div>
												<FormControl>
													<Textarea
														{...field}
														className={cn(
															'bg-white border-[#D3DDE2] rounded-xl hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5] h-36 resize-none'
														)}
														placeholder="Please describe your organization in some detail. Keep in mind that the AI model will look at this description, your area of work and your organization name when creating content for you."
													></Textarea>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
						</div>
					</div>
					<div
						className={cn(
							'flex flex-col gap-6 pb-6 border-b border-[#D3DDE2]',
							setupStep === 2 ? '' : 'hidden'
						)}
					>
						<div className="flex flex-col gap-4">
							<h2 className="font-bold text-black dynamic-large">
								Organization Expertise and Size
							</h2>
							<p className="text-black dynamic-text">
								Please define your organizational expertise and size
							</p>
						</div>
						<div className="grid grid-cols-12 gap-4">
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="expertise"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">Expertise*</Label>
												<FormControl>
													<Multiselect
														{...field}
														title="Select expertise"
														hasChildren={true}
														options={expertise}
														onSelectedValuesChange={field.onChange}
													></Multiselect>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="regionsOfWork"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">
													Which region do you work?*
												</Label>
												<FormControl>
													<Multiselect
														{...field}
														title="Select regions"
														options={regionsOfWork}
														onSelectedValuesChange={field.onChange}
													></Multiselect>
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="countriesAndTerritories"
									render={({ field }) => {
										return (
											<FormItem>
												<Label className="font-bold">
													Which countries and territories you work?
												</Label>
												<FormControl>
													<Multiselect
														{...field}
														title="Select countries and territories"
														options={countriesAndTerritories}
														onSelectedValuesChange={field.onChange}
													></Multiselect>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-4">
							<div className="col-span-4">
								<FormField
									control={form.control}
									name="organizationSize"
									render={({ field }) => {
										return (
											<FormItem>
												<Label>Organization Size</Label>
												<FormControl>
													<Select
														onValueChange={field.onChange}
														value={field.value}
													>
														<FormControl>
															<SelectTrigger
																className={cn(
																	'h-14 rounded-2xl text-sm xl:text-base border border-[#D3DDE2] bg-white text-black px-4 py-3 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]'
																)}
															>
																<SelectValue placeholder="Select organization size" />
															</SelectTrigger>
														</FormControl>
														<SelectContent className="py-2 bg-white rounded-2xl">
															{organizationSize.map((item, index) => {
																return (
																	<SelectItem
																		className="text-black text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
																		key={index}
																		value={item.value}
																	>
																		{item.label}
																	</SelectItem>
																);
															})}
														</SelectContent>
													</Select>
												</FormControl>
											</FormItem>
										);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="flex justify-end w-full gap-4 mx-auto">
						{setupStep !== 1 && (
							<SecondaryButton
								onClick={() => setSetupStep(setupStep ? setupStep - 1 : 1)}
								type="button"
							>
								Back
							</SecondaryButton>
						)}
						<PrimaryButton
							disabled={
								designationFormValue?.length > 1200 ||
								areOfWorkFormValue?.length > 250
							}
							type="submit"
						>
							Save & Continue
						</PrimaryButton>
					</div>
				</form>
			</Form>
		</div>
	);
};

export default OrganizationProfileSetupForm;
