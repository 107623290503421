import { FC } from 'react';
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from '@/components/ui/card';
import { getDocumentFromSource } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { handleApiError, handleGeneralError } from '@/lib/functions/funcUtils';
import { ErrorResponse } from '@/lib/types/apiTypes';
import { openDialog } from '@/redux/slices/pageSlice';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';

interface ProposalSummaryProps {
	summary: string;
	source: string;
}

const ProposalSummary: FC<ProposalSummaryProps> = ({ summary, source }) => {
	const dispatch = useAppDispatch();
	const handleClick = async () => {
		dispatch(processStart());
		try {
			const response = await getDocumentFromSource(source);
			if (response) {
				// setPdfData(response as ArrayBuffer);
				dispatch(openDialog({ type: 'viewPDF', pdf: response as ArrayBuffer }));
				dispatch(processSuccess());
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};

	return (
		<>
			<Card className="p-6 h-[320px]  bg-lightBackground rounded-xl border border-[rgba(185,212,255,0.40)]">
				<div className="flex flex-col h-full gap-4 pr-4 overflow-y-auto">
					<CardHeader className="p-0">
						<CardTitle className="text-black dynamic-medium">Summary</CardTitle>
					</CardHeader>
					<CardContent className="p-0 text-black">{summary}</CardContent>
					<CardFooter className="border border-[#cfdcef] border-l-0 border-r-0 border-b-0 p-0 py-4">
						<PrimaryButton
							className="read-rfp"
							disabled={!source}
							onClick={handleClick}
						>
							Read Solicitation
						</PrimaryButton>
					</CardFooter>
				</div>
			</Card>
		</>
	);
};

export default ProposalSummary;
