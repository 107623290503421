import { FC, ReactNode, useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import Upload from '../ui/icons/Upload';
import customToast from '@/components/CustomToast/CustomToast';
import DocumentLock from '../ui/icons/DocumentLock';
import { cn } from '@/lib/utils';
import FileUploadIcon from '../ui/icons/FileUploadIcon';
import DocumentUploadIcon from '../ui/icons/DocumentUploadIcon';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';

interface FileUploadProps {
	onDropAccepted: (files: File[]) => void;
	onDropRejected?: (fileRejections: FileRejection[]) => void;
	isUploadDisabled?: boolean;
	type?: 'default' | 'rfp' | 'documentDialog' | 'custom' | undefined;
	icon?: ReactNode;
	title?: string;
	infoText?: string;
	dropText?: string;
	className?: string;
	customLayout?: ReactNode;
	maxSize?: number;
	acceptedExtensions?: string[];
}

const FileUpload: FC<FileUploadProps> = ({
	onDropAccepted,
	onDropRejected,
	isUploadDisabled,
	type,
	icon,
	title,
	infoText,
	dropText,
	className,
	customLayout,
	maxSize,
	acceptedExtensions,
}) => {
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (isUploadDisabled) {
				customToast.error({ title: 'Please select a category first.' });
				return;
			}
			onDropAccepted(acceptedFiles);
		},
		[onDropAccepted, isUploadDisabled]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		onDropRejected: onDropRejected,
		accept: {
			extensions: acceptedExtensions || ['.pdf', '.docx'],
		},
		maxSize: maxSize || 20000000,
	});

	return (
		<div className={cn('relative', className)}>
			{isUploadDisabled && (
				<div className="absolute flex flex-col w-full rounded-3xl items-center justify-center gap-4 text-center cursor-lock min-h-[280px] z-10">
					<div className="flex flex-col gap-3 m-auto">
						<div className="flex items-center justify-center rounded-main bg-opacity-80">
							<DocumentLock />
						</div>
						<div className="flex justify-center">
							<span className="text-center text-black">
								Please select a category first
							</span>
						</div>
					</div>
				</div>
			)}
			<div
				className={cn(
					isUploadDisabled ? 'cursor-lock opacity-0' : '',
					'h-full'
				)}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				{isDragActive ? (
					<div className="flex items-center justify-center min-h-[260px]  text-[#909090]">
						{type === 'rfp'
							? 'Drop the RFP here ...'
							: dropText
								? dropText
								: 'Drop the document here ...'}
					</div>
				) : (
					<>
						{type === 'custom' ? (
							<>{customLayout && customLayout}</>
						) : type === 'rfp' ? (
							<div
								className={cn(
									'flex flex-col border border-[#d3dde2] rounded-3xl items-center justify-center gap-4 text-center cursor-pointer min-h-[250px]',
									isUploadDisabled ? ' cursor-lock opacity-0' : ''
								)}
							>
								<p className="mx-auto text-base text-black w-52">
									Drag and drop your file here <br /> or <br /> click to specify
									its location
								</p>
								<PrimaryButton>
									<FileUploadIcon /> Start Solicited Proposal
								</PrimaryButton>
							</div>
						) : type === 'default' ? (
							<div
								className={
									'flex flex-col h-full gap-2 py-6 px-3 rounded-lg border border-[#D3DDE2] text-center cursor-pointer justify-center'
								}
							>
								<div>
									<div className="flex items-center justify-center pb-2">
										{icon ? icon : <DocumentUploadIcon />}
									</div>
									<h6 className="font-bold text-primary">
										{title ? title : 'Click to Import A DOCUMENT'}
									</h6>
								</div>
								<p className="text-black whitespace-pre-line text-md">
									{infoText ? (
										infoText
									) : (
										<>
											Drag and drop your file here <br /> or <br /> click to
											specify its location
										</>
									)}
								</p>
							</div>
						) : type === 'documentDialog' ? (
							<div
								className={cn(
									'flex flex-col w-full rounded-3xl items-center justify-center gap-4 text-center cursor-pointer min-h-[280px]'
								)}
							>
								<div className="flex flex-col gap-3 m-auto">
									<div className="z-10 flex items-center justify-center rounded-main bg-opacity-80">
										<DocumentLock type="enabled" />
									</div>
									<div className="z-10 flex justify-center">
										<span className="text-center text-black">
											Click to Upload a New Document
										</span>
									</div>
								</div>
							</div>
						) : (
							<div
								className={cn(
									'flex flex-col border border-[#d3dde2] rounded-3xl items-center justify-center gap-4 text-center cursor-pointer min-h-[280px]',
									isUploadDisabled ? ' cursor-lock opacity-0' : ''
								)}
							>
								<h2 className="text-[#596c76] font-bold">Import a document</h2>
								<p className="mx-auto text-base text-black w-52">
									Drag and drop your file here <br /> or <br /> click to specify
									its location
								</p>
								<div className="flex items-center justify-center animated-container-transition hover:scale-105">
									<Upload />
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default FileUpload;
