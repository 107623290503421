import { FC, useState } from 'react';
import styles from './AiMessage.module.css';
import { ErrorHandle, handleResponse } from '@/lib/functions/funcUtils';
import { ClipboardList } from 'lucide-react';
import Add from '@/components/ui/icons/Add';
import useProposalId from '@/lib/hooks/useProposalId';
import {
	createClipboard,
	createNoteOnSpecificSection,
} from '@/lib/functions/apiCalls';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import { closeDrawer } from '@/redux/slices/pageSlice';
import { useQueryClient } from 'react-query';

interface AiMessageProps {
	text: string;
}

const AiMessage: FC<AiMessageProps> = ({ text }) => {
	const dispatch = useAppDispatch();
	const proposalId = useProposalId();
	const { activeSection } = useActiveSection();
	const queryClient = useQueryClient();
	const sectionId = activeSection.sectionId;
	const [isLoading, setIsLoading] = useState(false);

	const handleCreateIdea = async () => {
		if (!proposalId || !sectionId) return;
		try {
			setIsLoading(true);
			const response = await createNoteOnSpecificSection(
				proposalId,
				sectionId,
				{
					content: text,
					type: 'idea',
				}
			);

			handleResponse(
				response,
				'Response has been added to your section idea successfully'
			);
			setIsLoading(false);
			dispatch(fetchProposalById(proposalId));
			dispatch(closeDrawer());
			queryClient.invalidateQueries('clipboards');
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	const handleCreateClipboard = async () => {
		if (!proposalId) return;
		try {
			setIsLoading(true);
			const response = await createClipboard(proposalId, text);

			handleResponse(
				response,
				'Response has been copied to your clipboard successfully'
			);
			setIsLoading(false);
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
			setIsLoading(false);
		}
	};

	return (
		<div
			className={
				styles.card + ' flex flex-col my-2 gap-3 break-words overflow-hidden'
			}
		>
			<p className="text-[#6d7d86] text-left">{text}</p>
			<div className="flex justify-between">
				<div
					onClick={handleCreateIdea}
					className="flex items-center gap-1 xl:gap-2 cursor-pointer animated-hover-effect"
				>
					<Add />
					<p className="dynamic-small uppercase font-bold text-[#5d9bfd]">
						Add this in
					</p>
				</div>
				<ClipboardList
					onClick={handleCreateClipboard}
					className="h-5 text-primary cursor-pointer hover:scale-110"
				/>
			</div>
			{isLoading && <LoaderTransparent />}
		</div>
	);
};

export default AiMessage;
