import { FC } from 'react';
import styles from './ComplianceMatrixCard.module.css';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { RefreshCcw } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { ThreeDots } from 'react-loader-spinner';
import {
	fetchComplianceMatrix,
	triggerCompliance,
} from '@/redux/requests/compliance';
import { cn } from '@/lib/utils';

interface ComplianceMatrixCardProps {
	setPanelOpen: (open: boolean) => void;
}

const ComplianceMatrixCard: FC<ComplianceMatrixCardProps> = ({
	setPanelOpen,
}) => {
	const matrix = useSelector(
		(root: RootState) => root.compliance.complianceMatrix
	);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const dispatch = useAppDispatch();
	const loader = useSelector((root: RootState) => root.compliance.loading);
	return (
		<div className={cn(styles.card, 'compliance-card')}>
			{matrix?.status === 'errored' ? (
				<div className="flex items-center justify-between">
					<h2 className="font-bold text-black dynamic-large">
						Compliance Matrix
					</h2>
					{loader ? (
						<ThreeDots
							visible={true}
							height="50"
							width="50"
							color="#5D9BFD"
							radius=""
							ariaLabel="three-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
						/>
					) : (
						<p className="flex items-center gap-2 text-red-400">
							Something went wrong. retry now
							<RefreshCcw
								onClick={() => {
									dispatch(
										triggerCompliance({
											proposalId: Number(proposal?.id),
											complianceId: matrix.id,
										})
									);
								}}
								size={16}
							/>
						</p>
					)}
				</div>
			) : (
				<>
					<h2 className="font-bold text-black dynamic-large">
						Compliance Matrix
					</h2>
				</>
			)}
			<div className="flex items-center justify-start gap-2 mt-2 ">
				<p className="text-black">
					See the extracted compliance matrix for this solicitation
				</p>
				<PrimaryButton
					disabled={matrix?.status === 'errored'}
					className="shrink-0"
					onClick={() => {
						if (!matrix) {
							dispatch(fetchComplianceMatrix(Number(proposal?.id)));
						}
						setPanelOpen(true);
					}}
				>
					View Compliance Matrix
				</PrimaryButton>
			</div>
		</div>
	);
};

export default ComplianceMatrixCard;
