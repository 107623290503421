import styles from './TemplateSetUp.module.css';
import { cn } from '@/lib/utils';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
	SectionBuilder,
	TagField,
	TemplateSetup,
} from '@/lib/types/TemplateBuilder/templateTypes';
import {
	capitalizeFirstLetter,
	createUpdateTags,
	extractNames,
	updateTemplateBuilderLevels,
	wordCountValidation,
} from '@/lib/functions/TemplateBuilder/templateUtils';
import TemplateSetupIcon from '@/components/ui/icons/new/TemplateSetupIcon';
import { Multiselect } from '@/components/ui/shared/Multiselect/Multiselect';
import { Textarea } from '@/components/ui/textarea';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { Input } from '@/components/ui/input';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { RootState } from '@/redux/store/store';
import {
	buildTemplate,
	resetTemplateBuilder,
	setEditTemplateId,
	setTemplateSetupData,
	setTemplateTagOptions,
	toggleIsTemplateSetup,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { useQuery } from 'react-query';
import { getTagByOrganization } from '@/lib/functions/TemplateBuilder/templateCalls';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import {
	defaultTemplateTagOptions,
	templateQuestions,
} from '@/constants/templateBuilderData';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import customToast from '@/components/CustomToast/CustomToast';
import { useSelector } from 'react-redux';
import { useTemplateMutations } from '@/lib/hooks/templateBuilder/useTemplateMutations';
import {
	processStart,
	processSuccess,
	setProposal,
	setSortedNodes,
} from '@/redux/slices/proposalSlice';
import { editProposalTemplate } from '@/lib/functions/apiCalls';
import useProposalId from '@/lib/hooks/useProposalId';
import { fetchProposal, getWordCount } from '@/lib/functions/funcUtils';
import { Proposal } from '@/lib/types/apiTypes';
import { parseSectionsToNodes } from '@/lib/functions/designGraph';
import { setLastSection } from '@/redux/slices/pageSlice';

const TemplateSetUp = () => {
	const form = useForm<TemplateSetup>();
	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
	} = form;

	const name = watch('name');
	const description = watch('description');

	const dispatch = useAppDispatch();
	const auth = useAppSelector((root: RootState) => root.auth.isAuthenticated);
	const orgIdentifier = useOrganizationId();

	const templateBuilderData = useAppSelector(
		(root: RootState) => root.templates.templateBuilder
	);
	const templateTagOptions = useAppSelector(
		(root: RootState) => root.templates.templateTagOptions
	);
	const intermediatePage = useAppSelector(
		(root: RootState) => root.page.intermediatePage
	);

	const templateMode = useAppSelector(
		(root: RootState) => root.templates.templateMode
	);

	const isTemplateSetup = useSelector(
		(root: RootState) => root.templates.isTemplateSetup
	);

	const navigate = useNavigate();
	const [tags, setTags] = useState<any>([]);

	const templateTagQuery = useQuery(
		['template_tags'],
		async () => {
			const { data } = await getTagByOrganization(`templates_${orgIdentifier}`);
			return data;
		},
		{
			cacheTime: 0,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: auth,
			onSuccess: (data: any) => {
				const response = data.tags as TagField;
				const newArray: any = Object.assign([], defaultTemplateTagOptions);
				if (response) {
					response.tags.length > 0 &&
						response.tags.forEach((v) => {
							if (!defaultTemplateTagOptions.some((item) => item.value === v)) {
								const dbTags = {
									organizationIdentifier: response.organizationIdentifier,
									label: v,
									value: v,
								};
								newArray.push(dbTags);
							}
						});
				}

				dispatch(setTemplateTagOptions(newArray));
			},
			onError: () => {
				customToast.error({ title: 'Failed to fetch tags' });
			},
		}
	);

	useEffect(() => {
		if (templateBuilderData) {
			setValue(
				'name',
				templateMode === 'clone'
					? `${templateBuilderData.name}-copy`
					: templateBuilderData.name
			);
			setValue('description', templateBuilderData.description);
			const cTags: any = [];
			if (templateBuilderData.tags && templateBuilderData.tags.length > 0) {
				templateBuilderData.tags.forEach((value: string | []) => {
					if (value) {
						if (typeof value === 'string') {
							cTags.push({
								organizationIdentifier: undefined,
								value,
								label: capitalizeFirstLetter(value),
							});
						} else {
							cTags.push(value);
						}
					}
				});

				setTags(cTags);
				setValue('tags', cTags);
			}
		}
	}, [templateBuilderData, setValue]);

	const cancelChanges = () => {
		dispatch(resetTemplateBuilder());
		if (!intermediatePage) {
			navigate('/templates');
		} else {
			intermediatePage.onCancel &&
				intermediatePage.onCancel(intermediatePage.state);
		}
	};

	const handleSetup = (data: TemplateSetup) => {
		dispatch(
			setTemplateSetupData({
				name: data.name,
				description: data.description,
				tags: data.tags,
				isLive: false,
				organizationIdentifier: `${orgIdentifier}`,
			})
		);

		const updatedTemplateBuilder = {
			...templateBuilderData,
			...data,
			questions: templateBuilderData?.questions || [], // Ensure questions is an array
			sections: templateBuilderData?.sections || [], // Ensure sections is an array
		};

		if (templateMode === 'edit')
			dispatch(setEditTemplateId(templateBuilderData?._id));
		dispatch(buildTemplate(updatedTemplateBuilder));
		navigate('/template/design');
	};

	const {
		createTemplateMutation,
		updateTemplateMutation,
		createUpdateTagMutation,
	} = useTemplateMutations();

	const isTemplateMode = location.pathname.includes('template');
	const builder = useAppSelector((state: RootState) => state.templates);

	const proposalId = useProposalId();

	const handleUpdateProposalTemplate = async (
		updatedTreeData: SectionBuilder[]
	) => {
		try {
			dispatch(processStart());
			const response = await editProposalTemplate(
				updatedTreeData,
				builder.deleteSections,
				proposalId as number
			);

			if (response.statusCode === 201) {
				dispatch(processSuccess());
				const proposal = (await fetchProposal(Number(proposalId))) as Proposal;
				if (proposal) {
					dispatch(setSortedNodes(parseSectionsToNodes(proposal.sections)));
					dispatch(
						setLastSection({
							proposalId: proposal.id,
							sectionId: proposal.sections[0].id,
						})
					);
					navigate(
						`/proposal/${proposalId}/section/${proposal.sections[0].id}/design`
					);
					dispatch(setProposal(proposal));
				}
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<>
			{templateTagQuery.isLoading && <LoaderTransparent />}
			<div
				className={cn(
					`${isTemplateSetup ? '' : 'bg-[#f5f5f5]'} rounded-tl-[30px] h-[calc(100vh-40px)] overflow-y-scroll hidden-scroll flex flex-col justify-center items-center`
				)}
			>
				<form
					onSubmit={handleSubmit(handleSetup)}
					className={cn(
						styles.setUpCard,
						`flex flex-col gap-5 justify-center max-w-[1200px]`
					)}
				>
					<div className="grid items-center grid-cols-12 gap-7">
						<div className="flex items-center justify-end col-span-3">
							<TemplateSetupIcon />
						</div>
						<div className="col-span-9">
							<div className="flex flex-row mb-2">
								{isTemplateSetup ? (
									<h2 className="font-bold dynamic-xl-large text-secondary">
										{' '}
										Setting up a New Template
									</h2>
								) : (
									<h2 className="font-bold dynamic-xl-large text-secondary">
										{templateMode === 'edit'
											? 'Edit a Template'
											: 'Setting up a New Template'}
									</h2>
								)}
							</div>
							<div className="flex flex-col gap-2">
								<h2 className="font-bold text-black dynamic-medium">
									Template Name
								</h2>
								<div className="flex flex-row justify-between">
									<span className="text-black dynamic-small">
										Give your template a unique name.
									</span>
									<p
										className={cn(
											'text-base font-medium leading-normal',
											name?.length > 100
												? 'text-[#FF82A0]'
												: 'text-black opacity-50'
										)}
									>
										{name?.length || 0}/100 characters
									</p>
								</div>
							</div>

							<div className="flex flex-col w-full mt-2">
								<Controller
									name="name"
									control={control}
									rules={{
										required: 'Template Name is required',
										maxLength: {
											value: 100,
											message: 'Name should be under 100 characters.',
										},
									}}
									render={({ field }) => (
										<Input
											{...field}
											onChange={(e) => {
												field.onChange(e.target.value);
											}}
											placeholder="Give your template a name"
											className={`p-4 rounded-lg bg-white h-14 dynamic-text border`}
										/>
									)}
								/>
								{errors.name && (
									<p className="mt-1 text-red-500 dynamic-small">
										{errors.name.message}
									</p>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col justify-center gap-2">
						<h2 className="font-bold text-black dynamic-medium">Tags</h2>
						<span className="text-black dynamic-small">
							Please add tags that will make it easier for your team to find
							this template.
						</span>
						<div className="w-[920px]">
							<Controller
								name="tags"
								control={control}
								rules={{
									required: 'Tag is required',
								}}
								render={({ field }) => (
									<Multiselect
										title="Enter tags"
										options={templateTagOptions || []}
										value={tags}
										canAddOption={true}
										onSelectedValuesChange={(values: any) => {
											setTags(values);
											field.onChange(values);
										}}
									></Multiselect>
								)}
							/>

							{errors.tags && (
								<p className="mt-1 text-red-500 dynamic-small">
									{errors.tags.message}
								</p>
							)}
						</div>
					</div>
					<div className="flex flex-col justify-center gap-2">
						<div className="flex flex-col gap-2">
							<h2 className="font-bold text-black dynamic-medium">
								Description
							</h2>
							<div className="flex flex-row justify-between">
								<span className="text-black dynamic-small">
									Describe the purpose of this template so that your team knows
									how to use it.
								</span>
								<p
									className={cn(
										'text-base font-medium leading-normal',
										getWordCount(description) > 500
											? 'text-[#FF82A0]'
											: 'text-black opacity-50'
									)}
								>
									{getWordCount(description)}/500 words
								</p>
							</div>
						</div>

						<Controller
							name="description"
							control={control}
							rules={{
								required: 'Description is required',
								validate: (value) => wordCountValidation(value, 500),
							}}
							render={({ field }) => (
								<Textarea
									{...field}
									onChange={field.onChange}
									placeholder="Enter Description"
									className={`p-4 rounded-xl bg-white h-32 resize-none dynamic-text border`}
								/>
							)}
						/>

						{errors.description && (
							<p className="text-red-500 dynamic-small">
								{errors.description.message}
							</p>
						)}
					</div>
					{isTemplateSetup ? (
						<div className="flex flex-row justify-end mt-[5px] gap-4 bg-white">
							<SecondaryButton
								type="button"
								onClick={() => dispatch(toggleIsTemplateSetup(false))}
							>
								Back
							</SecondaryButton>
							<PrimaryButton
								type="button"
								disabled={
									name === '' || tags.length === 0 || description === ''
								}
								onClick={() => {
									if (templateBuilderData) {
										/** template builder tags */
										const tagsFilter = tags.every(
											(element) => typeof element === 'string'
										)
											? tags
											: extractNames(tags);

										if (
											orgIdentifier === 'undefined' ||
											orgIdentifier === undefined ||
											orgIdentifier === 'null' ||
											orgIdentifier === null
										) {
											customToast.error({
												title:
													'Organization Identifier is missing. Please contact support.',
											});
											return;
										}
										const updatedTreeData = updateTemplateBuilderLevels(
											templateBuilderData.sections,
											true
										);

										const updatedTemplateBuilder = {
											...templateBuilderData,
											sections: updatedTreeData,
											isLive: templateBuilderData.isLive || false,
											name: name,
											organizationIdentifier: orgIdentifier,
											description: description,
											tags: tagsFilter,
											questions: templateQuestions,
										};

										const updatedTags = createUpdateTags(
											updatedTemplateBuilder.tags,
											orgIdentifier,
											templateTagOptions
										);

										if (
											updatedTags &&
											updatedTags.tags &&
											updatedTags.tags.length > 0
										) {
											createUpdateTagMutation.mutate(updatedTags);
										}

										if (templateBuilderData._id) {
											updateTemplateMutation.mutate(updatedTemplateBuilder); //update template
										} else {
											const updatedProposalTreeData =
												updateTemplateBuilderLevels(
													templateBuilderData.sections,
													isTemplateMode
												);
											handleUpdateProposalTemplate(updatedProposalTreeData);
											updatedTemplateBuilder.isLive = true;
											createTemplateMutation.mutate(updatedTemplateBuilder); //create new template
										}
									}
								}}
							>
								Save Template
							</PrimaryButton>
						</div>
					) : (
						<div className="flex flex-row justify-end mt-[5px] gap-4 bg-white">
							<SecondaryButton type="button" onClick={cancelChanges}>
								Cancel
							</SecondaryButton>
							{templateMode === 'edit' ? (
								<PrimaryButton type="submit">Edit Template</PrimaryButton>
							) : (
								<PrimaryButton type="submit">
									Start building template
								</PrimaryButton>
							)}
						</div>
					)}
				</form>
			</div>
		</>
	);
};

export default TemplateSetUp;
