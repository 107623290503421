import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cropText } from '@/lib/functions/funcUtils';
import { RootState } from '@/redux/store/store';
import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import TemplateTags from '../../TemplateTags/TemplateTags';
import TemplateDesignerButtons from '../TemplateDesignerButtons/TemplateDesignerButtons';
import {
	SectionBuilder,
	TemplateLibrary,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { UseFormReturn } from 'react-hook-form';
import { X } from 'lucide-react';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { closePanel, openDialog } from '@/redux/slices/pageSlice';
import {
	buildTemplate,
	resetDeletedSections,
	toggleIsTemplateSetup,
} from '@/redux/slices/TemplateBuilder/templateSlice';

interface TemplateDesignerHeaderProps {
	treeData: any[];
	changeNodeDesign: (node: any, cancel?: boolean) => void;
	setSelectedSection: Dispatch<
		SetStateAction<SectionBuilder | null | undefined>
	>;
	toggleSection: Dispatch<SetStateAction<boolean>>;
	form: UseFormReturn<TemplateLibrary, any>;
	onSubmit: (data: TemplateLibrary) => Promise<void>;
	selectedSection: SectionBuilder | null | undefined;
}

const TemplateDesignerHeader: FC<TemplateDesignerHeaderProps> = ({
	treeData,
	changeNodeDesign,
	setSelectedSection,
	toggleSection,
	form,
	onSubmit,
	selectedSection,
}) => {
	const templateBuilderData = useSelector(
		(state: RootState) => state.templates.templateBuilder
	);

	const isTemplateMode = location.pathname.includes('template');

	const isProposalTemplateEdit = useAppSelector(
		(state: RootState) => state.templates.isProposalTemplateEdit
	);

	const dispatch = useAppDispatch();
	return (
		<div className="flex items-center justify-between p-5 mb-4 bg-white rounded-2xl shadow-main">
			<div className="flex flex-col items-start gap-2">
				<div className="flex gap-2">
					{!isTemplateMode && (
						<X
							onClick={() => {
								if (isProposalTemplateEdit) {
									dispatch(
										openDialog({
											type: 'notification',
											notificationObj: {
												title: 'Go Back',
												description:
													'You have unsaved changes to this template. Do you want to leave without saving?',
												onConfirm: () => {
													changeNodeDesign(selectedSection, true);
													setSelectedSection(undefined);
													toggleSection(false);
													dispatch(resetDeletedSections());
													dispatch(buildTemplate(null));
													dispatch(toggleIsTemplateSetup(false));
													dispatch(closePanel());
												},
											},
										})
									);
								} else {
									changeNodeDesign(selectedSection, true);
									setSelectedSection(undefined);
									toggleSection(false);
									dispatch(resetDeletedSections());
									dispatch(buildTemplate(null));
									dispatch(toggleIsTemplateSetup(false));
									dispatch(closePanel());
								}
							}}
							className="w-8 h-8 p-2 rounded-full text-primary bg-lightBackground"
						/>
					)}
					<TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger type="button">
								<h2 className="w-full overflow-hidden font-bold text-black dynamic-xl-large text-nowrap text-ellipsis">
									{templateBuilderData?.name && (
										<span className="inline-block">
											<span className="hidden md:inline 2xl:hidden">
												{cropText(templateBuilderData.name, 25)}
											</span>
											<span className="md:hidden 2xl:inline">
												{templateBuilderData.name}
											</span>
										</span>
									)}
								</h2>
							</TooltipTrigger>
							<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal visible">
								{templateBuilderData?.name}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<TemplateTags />
			</div>
			<TemplateDesignerButtons
				treeData={treeData}
				changeNodeDesign={changeNodeDesign}
				setSelectedSection={setSelectedSection}
				toggleSection={toggleSection}
				form={form}
				onSubmit={onSubmit}
				selectedSection={selectedSection}
			/>
		</div>
	);
};

export default TemplateDesignerHeader;
