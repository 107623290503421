import { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';
import { FileError, useDropzone } from 'react-dropzone';
import { showToast } from '@/lib/functions/funcUtils';
import styles from './AccountProfileImage.module.css';
import { X } from 'lucide-react';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import { User } from '@/lib/types/apiTypes';

export interface Props extends HTMLAttributes<HTMLDivElement> {
	className?: string;
	src: string;
	edit?: boolean;
	profileImage: File | null;
	profileImageUpdated?: (profileImage: File | null) => void;
	user: User | null;
}

export const AccountProfileImage: FC<Props> = ({
	src,
	className = '',
	edit = false,
	profileImageUpdated,
	profileImage = null,
	user,
	...props
}) => {
	const [previewImage, setPreviewImage] = useState<string | null>(src);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		const file = acceptedFiles[0];
		if (profileImageUpdated) {
			profileImageUpdated(file);
		}
		const fileReader = new FileReader();
		fileReader.onloadend = () => {
			const res = fileReader.result?.toString();
			setPreviewImage(res || '');
		};
		if (file && file.type.match('image.*')) {
			fileReader.readAsDataURL(file);
		}
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		onDropRejected(fileRejections) {
			const error: FileError = fileRejections?.[0]?.errors?.[0];
			if (error?.code) {
				const showError = (errorMessage) => {
					showToast('error', errorMessage);
				};
				switch (error?.code) {
					case 'file-too-large':
						showError(
							`The file you're trying to upload is too large. Please select a smaller file.`
						);
						break;
					case 'file-invalid-type':
						showError(
							`The file type you selected is not supported. Please choose a '.png', '.jpeg', or '.jpg' file.`
						);
						break;
				}
			}
		},
		accept: {
			extensions: ['.png', '.jpeg', '.jpg'],
		},
		maxSize: 1000000,
	});

	const handleDelete = (event: MouseEvent) => {
		setPreviewImage(null);
		profileImageUpdated && profileImageUpdated(null);
		event.stopPropagation();
	};

	useEffect(() => {
		if (!profileImage) {
			setPreviewImage(src);
		}
	}, [profileImage]);

	useEffect(() => {
		if (!profileImage) {
			setPreviewImage(src);
		}
	}, [edit]);

	return (
		<div
			{...(edit === true && { ...getRootProps() })}
			{...props}
			className={cn(
				className,
				'relative rounded-full h-40 w-40 ',
				edit === true ? 'cursor-pointer' : ''
			)}
		>
			{previewImage ? (
				<Avatar className={cn(styles.box, 'h-full w-full')}>
					<AvatarImage
						className="object-cover p-2 text-black rounded-full "
						src={previewImage}
					/>
					<AvatarFallback className="text-black">
						{user?.name || 'Profile Picture'}
					</AvatarFallback>
				</Avatar>
			) : (
				<Avatar className={cn(styles.box, 'h-full w-full')}>
					<AvatarImage
						className="object-cover p-2 text-black rounded-full "
						src={'/images/avatar-gray.svg'}
					/>
				</Avatar>
			)}
			{edit && (
				<>
					<input {...getInputProps()} />
					{!!previewImage && (
						<TextButton
							type="button"
							className="!absolute h-9 w-9 2xl:h-9 2xl:w-9 border-0 top-1.5 right-1.5 rounded-full bg-[#5D6F7933] p-0"
							onClick={handleDelete}
						>
							<X className="h-6 w-6" />
						</TextButton>
					)}
					<div
						className={cn(
							styles.file__field,
							'absolute h-9 w-9 bg-cover border-0 bottom-1.5 right-1.5 rounded-full'
						)}
					></div>
					{isDragActive && (
						<div className="absolute w-full h-full rounded-full top-0 bg-[#E3EEFF] opacity-80 flex items-center justify-center text-base xl:text-lg 2xl:text-xl text-[#909090]">
							Drop the Image here ...
						</div>
					)}
				</>
			)}
		</div>
	);
};
