import customToast from '@/components/CustomToast/CustomToast';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { updateSectionState } from '@/lib/functions/apiCalls';
import { handleResponse } from '@/lib/functions/funcUtils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import useProposalId from '@/lib/hooks/useProposalId';
import { closeDialog } from '@/redux/slices/pageSlice';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface SectionWordCountDialogProps {
	wordCount: number;
}

const SectionWordCountDialog = () => {
	const isOpen = useSelector(
		(root: RootState) => root.page.dialog?.type === 'changeSectionWordCount'
	);
	const [loader, setLoader] = useState(false);
	const form = useForm<SectionWordCountDialogProps>();
	const { register, handleSubmit, reset } = form;
	const section = useSelector((root: RootState) => root.page.dialog?.section);
	const [wordCount, setWordCount] = useState<number>(section?.wordCount || 0);
	const dispatch = useAppDispatch();
	const proposalId = useProposalId();

	useEffect(() => {
		if (section) {
			setWordCount(section?.wordCount || 0);
		}
	}, [section]);

	const handleClose = () => {
		setLoader(false);
		dispatch(closeDialog());
		reset();
	};

	const handleUpdateWordCount = async () => {
		try {
			if (!section || !proposalId) return;
			setLoader(true);
			const response = await updateSectionState(proposalId, section.id, {
				wordCount: wordCount,
			});
			handleResponse(response, 'Word Count updated successfully');
			dispatch(fetchProposalById(Number(proposalId)));
			setLoader(false);
			reset();
			dispatch(closeDialog());
		} catch (error) {
			setLoader(false);
			customToast.error({
				title: 'Error updating word count',
			});
		}
	};
	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="max-w-[500px] p-6 bg-white rounded-xl"
				hideCloseBtn
			>
				<DialogHeader className="font-bold text-secondary dynamic-large">
					Change Word Count
				</DialogHeader>
				<form onSubmit={handleSubmit(handleUpdateWordCount)}>
					<label
						htmlFor="wordCount"
						className="block mb-1 font-bold text-black"
					>
						Word Count
					</label>
					<Input
						{...register('wordCount')}
						id="wordCount"
						type="number"
						placeholder="Enter Word Count"
						className="w-full bg-white rounded-lg h-11"
						onChange={(e) => setWordCount(e.target.valueAsNumber)}
						value={wordCount}
					/>
					{(wordCount > 1000 || wordCount < 100) && (
						<p className="pt-2 dynamic-text font-medium text-lightRed">
							Word count must be between 100 and 1000
						</p>
					)}
					<div className="flex items-center justify-end gap-3 mt-4">
						<SecondaryButton type="button" onClick={handleClose}>
							Cancel
						</SecondaryButton>
						<PrimaryButton
							type="submit"
							disabled={
								!wordCount || wordCount > 1000 || wordCount < 100 || loader
							}
						>
							{loader ? 'Updating...' : 'Update Word Count'}
						</PrimaryButton>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default SectionWordCountDialog;
