import React from 'react';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../../input-otp';
import { REGEXP_ONLY_DIGITS } from 'input-otp';

interface OTPInputProps {
	pattern?: string;
	value: string;
	onChange: (value: string) => void;
}

const OTPInput = React.forwardRef<
	React.ElementRef<'div'>,
	React.ComponentPropsWithoutRef<'div'> & OTPInputProps
>(({ pattern, value, onChange }, ref) => {
	return (
		<div ref={ref}>
			<InputOTP
				maxLength={6}
				pattern={pattern || REGEXP_ONLY_DIGITS}
				value={value}
				onChange={onChange}
			>
				<div className="flex justify-between w-full font-medium dynamic-medium">
					<InputOTPGroup className="rounded-xl border border-[#D3DDE2]">
						<InputOTPSlot
							className="bg-white text-black border-none h-12 2xl:h-14 w-12 2xl:w-14 p-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
							index={0}
						/>
					</InputOTPGroup>
					<InputOTPGroup className="rounded-xl border border-[#D3DDE2]">
						<InputOTPSlot
							className="bg-white text-black border-none h-12 2xl:h-14 w-12 2xl:w-14 p-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
							index={1}
						/>
					</InputOTPGroup>
					<InputOTPGroup className="rounded-xl border border-[#D3DDE2]">
						<InputOTPSlot
							className="bg-white text-black border-none h-12 2xl:h-14 w-12 2xl:w-14 p-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
							index={2}
						/>
					</InputOTPGroup>
					<InputOTPGroup className="rounded-xl border border-[#D3DDE2]">
						<InputOTPSlot
							className="bg-white text-black border-none h-12 2xl:h-14 w-12 2xl:w-14 p-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
							index={3}
						/>
					</InputOTPGroup>
					<InputOTPGroup className="rounded-xl border border-[#D3DDE2]">
						<InputOTPSlot
							className="bg-white text-black border-none h-12 2xl:h-14 w-12 2xl:w-14 p-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
							index={4}
						/>
					</InputOTPGroup>
					<InputOTPGroup className="rounded-xl border border-[#D3DDE2]">
						<InputOTPSlot
							className="bg-white text-black border-none h-12 2xl:h-14 w-12 2xl:w-14 p-4 hover:bg-[#EAF1FC] disabled:bg-[#F5F5F5]"
							index={5}
						/>
					</InputOTPGroup>
				</div>
			</InputOTP>
		</div>
	);
});

OTPInput.displayName = 'OTPInput';

export { OTPInput };
