// proposalSlice.ts
import { getProposalById } from '@/lib/functions/apiCalls';
import { parseSectionsToNodes } from '@/lib/functions/designGraph';
import { findSection } from '@/lib/functions/funcUtils';
import {
	ClearSectionPayload,
	Proposal,
	ProposalResponseData,
	Template,
	WritePayload,
	ToneOfVoice,
} from '@/lib/types/apiTypes';
import { Node } from '@/lib/types/graphTypes';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProposalState {
	isTransparentLoading: boolean;
	isTransparentAnimatedLoading: boolean;
	transparentAnimatedType: string;
	pageTransitionLoad: boolean;
	isLoading: boolean;
	error: string | null;
	selectedTemplate: Template | null;
	templates: Template[] | null;
	proposal: Proposal | null;
	currentStep: number;
	sortedNodes: Node[];
	writerLoader: boolean;
}

const initialState: ProposalState = {
	isTransparentLoading: false,
	isTransparentAnimatedLoading: false,
	transparentAnimatedType: 'document',
	pageTransitionLoad: false,
	isLoading: false,
	error: null,
	selectedTemplate: null,
	templates: null,
	proposal: null,
	currentStep: 1,
	sortedNodes: [],
	writerLoader: false,
};

export const fetchProposalById = createAsyncThunk(
	'proposal/fetchProposalById',
	async (id: number, thunkAPI) => {
		try {
			const response = await getProposalById(id);
			if (response.statusCode === 200) {
				const proposal = (response.data as ProposalResponseData).proposal;
				return proposal;
			} else {
				return thunkAPI.rejectWithValue(response.message);
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Unexpected error occurred');
		}
	}
);

const proposalSlice = createSlice({
	name: 'proposal',
	initialState,
	reducers: {
		clearProposal(state) {
			state.proposal = null;
			state.currentStep = 1;
			state.selectedTemplate = null;
			state.sortedNodes = [];
			state.error = null;
		},
		setSortedNodes(state, action: PayloadAction<Node[]>) {
			state.sortedNodes = action.payload;
		},
		setTemplates(state, action: PayloadAction<Template[]>) {
			state.templates = action.payload;
		},
		setTemplate(state, action: PayloadAction<Template>) {
			state.selectedTemplate = action.payload;
		},
		setProposal(state, action: PayloadAction<Proposal>) {
			state.proposal = action.payload;
			state.sortedNodes = parseSectionsToNodes(action.payload?.sections);
		},
		updateStep(state, action: PayloadAction<number>) {
			state.currentStep = action.payload;
		},
		processStart(state) {
			state.isTransparentLoading = true;
		},
		processSuccess(state) {
			state.isTransparentLoading = false;
		},
		transparentAnimatedStart(state, action: PayloadAction<string>) {
			state.isTransparentAnimatedLoading = true;
			state.transparentAnimatedType = action.payload || 'document';
		},
		transparentAnimatedEnd(state) {
			state.isTransparentAnimatedLoading = false;
			state.transparentAnimatedType = 'document';
		},
		pageTransitionStart(state) {
			state.pageTransitionLoad = true;
		},
		pageTransitionEnd(state) {
			state.pageTransitionLoad = false;
		},
		setProposalTone(state, action: PayloadAction<ToneOfVoice>) {
			if (state?.proposal?.settings?.toneOfVoice) {
				state.proposal.settings.toneOfVoice = action.payload;
			}
		},
		updateSection(state, action: PayloadAction<WritePayload>) {
			const { editorState, content, sectionId } = action.payload;

			// If proposal or its sections are not set, return early.
			if (!state.proposal || !state.proposal.sections) return;

			// Try to find the section directly in the proposal's sections.
			const foundSection = findSection(
				state.proposal.sections,
				String(sectionId)
			);

			// If the section is found, update its content and editorState.
			if (foundSection) {
				foundSection.editorState = editorState;
				foundSection.content = content;
			}
		},
		clearSection(state, action: PayloadAction<ClearSectionPayload>) {
			const { sectionId, data } = action.payload;

			// If proposal or its sections are not set, return early.
			if (!state.proposal || !state.proposal.sections) return;

			const foundSection = findSection(
				state.proposal.sections,
				String(sectionId)
			);

			// If the section is found, update its content and editorState.
			if (foundSection) {
				foundSection.editorState = data;
				state.writerLoader = false;
			}
		},
		setWriterLoader(state, action: PayloadAction<boolean>) {
			state.writerLoader = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProposalById.pending, (state) => {
			state.isTransparentLoading = true;
			state.error = null;
		});
		builder.addCase(
			fetchProposalById.fulfilled,
			(state, action: PayloadAction<Proposal>) => {
				state.isTransparentLoading = false;
				state.error = null;
				state.proposal = action.payload;
				state.sortedNodes = parseSectionsToNodes(
					action.payload.sections.filter((s) => s.type !== 'logical-framework')
				);
			}
		);
		builder.addCase(fetchProposalById.rejected, (state, action) => {
			state.isTransparentLoading = false;
			state.error = action.payload as string;
		});
	},
});

export const {
	transparentAnimatedStart,
	transparentAnimatedEnd,
	setTemplates,
	setTemplate,
	setProposal,
	updateStep,
	processStart,
	processSuccess,
	pageTransitionStart,
	updateSection,
	pageTransitionEnd,
	clearSection,
	clearProposal,
	setSortedNodes,
	setProposalTone,
	setWriterLoader,
} = proposalSlice.actions;
export default proposalSlice.reducer;
